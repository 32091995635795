import {inject, Injectable} from '@angular/core';
import Links from '@models/links/links.model';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {
    DemandeurRechercheResultatsApiService
} from '@models/demandeurs/demandeur/recherches/recherche/resultats/demandeur-recherche-resultats.api.service';
import {
    ICDemandeurRechercheResultatsQueryParameters, ICDemandeurRechercheResultatsApi
} from '@models/demandeurs/demandeur/recherches/recherche/resultats/collection/demandeur-recherche-resultats.collection.interfaces';
import CDemandeurRechercheResultats
    from '@models/demandeurs/demandeur/recherches/recherche/resultats/collection/demandeur-recherche-resultats.collection.model';
import DemandeurRecherche from '@models/demandeurs/demandeur/recherches/recherche/demandeur-recherche.model';
import {ConditionConst, SortConst} from '@shared/constants';
import Filter from '@models/filters/filter/filter.model';
import {IFilterQueryParametersApi} from '@models/filters/filter/filter.interfaces';
import DemandeurRechercheResultat
    from '@models/demandeurs/demandeur/recherches/recherche/resultats/resultat/demandeur-recherche-resultat.model';
import {
    DemandeurRechercheResultatsFactory
} from '@models/demandeurs/demandeur/recherches/recherche/resultats/demandeur-recherche-resultats.factory';

@Injectable({providedIn: 'root'})
export class CDemandeurRechercheResultatsFactory {
    private _demandeurRechercheResultatsApiService = inject(DemandeurRechercheResultatsApiService);
    private _demandeurRechercheResultatsFactory = inject(DemandeurRechercheResultatsFactory);

    create(cDemandeurRechercheResultatsApi: ICDemandeurRechercheResultatsApi): CDemandeurRechercheResultats {
        const cDemandeurRechercheResultats = this.createVirgin();

        cDemandeurRechercheResultats.links = new Links(cDemandeurRechercheResultatsApi._links);
        cDemandeurRechercheResultats.page = cDemandeurRechercheResultatsApi.page;
        cDemandeurRechercheResultats.pages = cDemandeurRechercheResultatsApi.pages;
        cDemandeurRechercheResultats.perPage = cDemandeurRechercheResultatsApi.limit;
        cDemandeurRechercheResultats.total = cDemandeurRechercheResultatsApi.total;
        if (cDemandeurRechercheResultatsApi._embedded) {
            cDemandeurRechercheResultats.results = this._demandeurRechercheResultatsFactory.create(cDemandeurRechercheResultatsApi._embedded.items);
        }

        return cDemandeurRechercheResultats;
    }

    createQueryParameters(filterQueryParametersApi: IFilterQueryParametersApi, code?: string): ICDemandeurRechercheResultatsQueryParameters {
        const queryParameters = this.createVirginQueryParameters(code);

        queryParameters.interest = filterQueryParametersApi.interest ?? queryParameters.interest;
        queryParameters.status = filterQueryParametersApi.status ?? queryParameters.status;

        return queryParameters;
    }

    createVirgin(): CDemandeurRechercheResultats {
        const cDemandeurRechercheResultats = new CDemandeurRechercheResultats();

        cDemandeurRechercheResultats.links = new Links();

        return cDemandeurRechercheResultats;
    }

    createVirginQueryParameters(code?: string): ICDemandeurRechercheResultatsQueryParameters {
        const queryParameters: ICDemandeurRechercheResultatsQueryParameters = {tris: {status: SortConst.ASCENDING}};

        if (code === Filter.codes.WALLET_CURRENT) {
            queryParameters.interest = {
                condition: ConditionConst.NOT_IN,
                values: [
                    DemandeurRechercheResultat.interests.DISINTERESTED,
                    DemandeurRechercheResultat.interests.DISINTERESTED_EXCEPT_PRICE,
                ],
            };
            queryParameters.status = {condition: ConditionConst.IN, values: []};
        }

        return queryParameters;
    }

    getEtude$(queryParameters?: ICDemandeurRechercheResultatsQueryParameters): Observable<CDemandeurRechercheResultats> {
        return this._demandeurRechercheResultatsApiService.getEtudeCollection$(queryParameters)
            .pipe(map(cDemandeurRechercheResultatsApi => this.create(cDemandeurRechercheResultatsApi)));
    }

    getFromRecherche$(demandeurRecherche: DemandeurRecherche, queryParameters?: ICDemandeurRechercheResultatsQueryParameters): Observable<CDemandeurRechercheResultats> {
        if (queryParameters?.tris?.['status']) {
            queryParameters.tris.interest = queryParameters.tris.status;
            queryParameters.tris.currentEtude = queryParameters.tris.status;
        }

        return this._demandeurRechercheResultatsApiService.getRechercheCollection$(demandeurRecherche.uuid, queryParameters)
            .pipe(map(cDemandeurRechercheResultatsApi => this.create(cDemandeurRechercheResultatsApi)));
    }
}
