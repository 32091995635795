<div>
  @if (vente.isInteractive()) {
    @if (vente.interactivePremiereOffre > 0) {
      Première offre de
      <span class="tw:font-semibold tw:text-gray-700 tw:text-lg">
        {{ vente.interactivePremiereOffre | textCurrency }}
      </span>
    } @else {
      @if (vente.isInteractive()) {
        Première offre non communiquée
      }

      @if (vente.isSimple()) {
        Prix non communiqué
      }

      @if (vente.isViager()) {
        Bouquet non communiqué
      }
    }
  } @else if (ventePrice.priceHNI > 0) {
    <ng-container [ngTemplateOutlet]="labelPrice"/> de
    <span class="tw:font-semibold tw:text-gray-700 tw:text-lg">{{ ventePrice.priceHNI | textCurrency }}</span> HNI
  } @else {
    @if (vente.isInteractive()) {
      Première offre non communiquée
    }

    @if (vente.isSimple()) {
      Prix non communiqué
    }

    @if (vente.isViager()) {
      Bouquet non communiqué
    }
  }

  <!-- @todo Voir pour utiliser "vente.labelPriceNC" -->
</div>

<div>Charge {{ ventePrice.isTypeChargesNV() ? 'acquéreur' : 'vendeur' }}</div>

@if (ventePrice.honoraires > 0 && ((vente.interne && isPrive) || (ventePrice.isTypeChargesNV() && !vente.isInteractive()))) {
  <div>
    Dont <span class="tw:font-semibold tw:text-gray-700">{{ ventePrice.honoraires | textCurrency }}</span> d'honoraires
    de négociation (soit
    <span class="tw:font-semibold tw:text-gray-700">{{ ventePrice.pourcentageHonoraires | textCurrency:'%' }}</span>)
  </div>
}

@if (ventePrice.priceNV > 0 && !vente.isInteractive() && ((vente.interne && isPrive) || ventePrice.isTypeChargesNV())) {
  <div>
    <ng-container [ngTemplateOutlet]="labelPrice"/> Net Vendeur de
    <span class="tw:font-semibold tw:text-gray-700">{{ ventePrice.priceNV | textCurrency }}</span>
  </div>
}

@if (vente.interactivePrixReserve > 0 && vente.isInteractive() && vente.interne && isPrive) {
  <div>
    Prix de réserve Net Vendeur de
    <span class="tw:font-semibold tw:text-gray-700">{{ vente.interactivePrixReserve | textCurrency }}</span>
  </div>
}

@if (ventePrice.fraisActe > 0 && isPublic) {
  <div>
    <span class="tw:font-semibold tw:text-gray-700">{{ ventePrice.fraisActe | textCurrency }}</span> de frais d'acte
  </div>
}

@if (!vente.isInteractive() && isPrive && (ventePrice.priceTFC > 0 || vente.isSimple() && (vente.estimationMax || vente.estimationMin))) {
  <div class="tw:mt-2">
    @if (ventePrice.priceTFC > 0) {
      <div>
        <ng-container [ngTemplateOutlet]="labelPrice"/> Tous Frais Compris de
        <span class="tw:font-semibold tw:text-gray-700">{{ ventePrice.priceTFC | textCurrency }}</span>
      </div>
    }

    @if (vente.isSimple() && (vente.estimationMax || vente.estimationMin)) {
      <div>
        Estimation
        @if (vente.estimationMax && vente.estimationMin) {
          comprise entre <span class="tw:font-semibold tw:text-gray-700">{{ vente.estimationMin | textCurrency }}</span>
          et <span class="tw:font-semibold tw:text-gray-700">{{ vente.estimationMax | textCurrency }}</span>
        } @else {
          @if (vente.estimationMin) {
            à partir de <span class="tw:font-semibold tw:text-gray-700">{{ vente.estimationMin | textCurrency }}</span>
          }

          @if (vente.estimationMax) {
            jusqu'à <span class="tw:font-semibold tw:text-gray-700">{{ vente.estimationMax | textCurrency }}</span>
          }
        }
      </div>
    }
  </div>
}

@if (vente.isInteractive() && isPublic) {
  <div class="tw:mt-2">
    @if (vente.interactivePasOffre > 0) {
      <div>Pas de l'offre de {{ vente.interactivePasOffre | textCurrency }}</div>
    }

    @if (vente.interactiveDateFin) {
      <div>
        Date et heure de fin des offres le {{ vente.interactiveDateFin | date:'longDate' }} à
        {{ vente.interactiveDateFin | date: 'shortTime' }}
      </div>
    }

    @if (vente.interactiveSystemeEncheres) {
      <div>Système d'enchères {{ vente.interactiveSystemeEncheres.label }}</div>
    }
  </div>
}

@if (vente.isViager()) {
  <div class="tw:mt-2">
    <div>
      Rente {{ vente.viagerPeriodiciteRente.label }} {{ vente.viagerIndexation ? 'indexée' : '' }} de
      <span class="tw:font-semibold tw:text-gray-700">{{ vente.viagerRente | textCurrency }}</span>
    </div>

    <div>
      Valeur du bien de
      <span class="tw:font-semibold tw:text-gray-700">{{ vente.viagerValeurBien | textCurrency }}</span>
    </div>

    <!-- Supprimer les ! dans le cas de la suppression de `ul[class*=tw:]` dans style.scss -->
    @if (vente.viagerTetes && isPublic) {
      <ul class="tw:!list-disc tw:!list-inside">
        @for (viagerTete of vente.viagerTetes; track $index) {
          <li>{{ viagerTete.sexe.label }} de {{ viagerTete.age }} ans</li>
        }
      </ul>
    }
  </div>
}

<ng-template #labelPrice>
  @if (vente.isSimple()) {
    Prix
  }

  @if (vente.isViager()) {
    Bouquet
  }
</ng-template>
