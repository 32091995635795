export default `
<form name="$ctrl.editForm" ng-submit="$ctrl.send()" novalidate>
  <div class="modal-header">
    <button type="button" class="btn btn-default pull-right" ng-click="$dismiss()"
            title="Fermer sans envoyer le mail"><fa-icon [icon]="'times'"></fa-icon></button>

    <h3 class="modal-title"><fa-icon [icon]="['far', 'envelope']"></fa-icon> Rédaction d'un email</h3>
  </div>

  <div class="modal-body">
    <ul class="nav nav-pills margin-bottom-20">
      <li role="presentation" ng-class="{active: $ctrl.currentTab === tab.code}" ng-repeat="tab in $ctrl.tabs">
        <a ng-click="$ctrl.currentTab = tab.code">{{tab.label | translate}}</a>
      </li>
    </ul>

    <div ng-show="$ctrl.currentTab === 'edition'">
      <div class="row">
        <div class="col-sm-12 padding-10">
          <esk-select-recipient ng-model="$ctrl.email" placeholder="Ajouter un destinataire"
                                recipient-type="to" input-name="A :"></esk-select-recipient>

          <span class="pull-right" ng-click="$ctrl.moreRecipients = !$ctrl.moreRecipients">
            <span ng-if="$ctrl.moreRecipients"><fa-icon [icon]="'caret-down'"></fa-icon></span>

            <span ng-if="!$ctrl.moreRecipients"><fa-icon [icon]="'caret-left'"></fa-icon></span>

            <fa-icon [icon]="'users'"></fa-icon>
          </span>

        </div>

        <div class="col-sm-12 padding-10" ng-show="$ctrl.moreRecipients">
          <esk-select-recipient ng-model="$ctrl.email" placeholder="Ajouter une copie"
                                recipient-type="cc" input-name="Cc :"></esk-select-recipient>
        </div>

        <div class="col-sm-12 padding-10" ng-show="$ctrl.moreRecipients">
          <esk-select-recipient ng-model="$ctrl.email" placeholder="Ajouter une copie cachée"
                                recipient-type="bcc" input-name="Cci :"></esk-select-recipient>
        </div>
      </div>

      <hr>

      <!-- Objet -->
      <div class="form-group" ng-class="{'has-error': $ctrl.editForm.$submitted && $ctrl.editForm.subject.$invalid}">
        <input type="text" id="subject" name="subject" class="form-control" ng-model="$ctrl.email.subject" required
               placeholder="Objet">

        <div class="error text-small tw:block" ng-messages="$ctrl.editForm.subject.$error"
             ng-show="$ctrl.editForm.$submitted && $ctrl.editForm.subject.$invalid">
          <ng-messages-include src="messages.html"></ng-messages-include>
        </div>
      </div>

      <!-- Corps -->
      <div class="form-group" ng-class="{'has-error': $ctrl.editForm.$submitted && !$ctrl.email.message}">
        <app-ng2-tinymce (change-content)="$ctrl.email.message = $event" [content]="$ctrl.email.message"
                         [options]="$ctrl.tinymceOptions"></app-ng2-tinymce>

        <div class="error text-small tw:block" ng-messages="$ctrl.editForm.message.$error"
             ng-show="$ctrl.editForm.$submitted && !$ctrl.email.message">
          <ng-messages-include src="messages.html"></ng-messages-include>
        </div>
      </div>
    </div>

    <div ng-show="$ctrl.currentTab === 'attachments'" class="form-group">
      <esk-actions-attachments attachments="$ctrl.email.attachments" files-max-size="$ctrl.attachmentsMaxSize"
                               add-document="$ctrl.addAttachmentDocument" current-dossier="$ctrl.currentDossier"
                               remove="$ctrl.removeAttachment" add="$ctrl.addAttachment"></esk-actions-attachments>
    </div>

    <div ng-show="$ctrl.currentTab === 'dossiers'" class="form-group">
      <label>Dossiers</label>

      <esk-actions-dossiers dossiers="$ctrl.email.dossiers" current-dossier="$ctrl.currentDossier"
                            add="$ctrl.addDossier" add-contacts="$ctrl.addContacts"
                            remove="$ctrl.removeDossier"></esk-actions-dossiers>
    </div>
  </div>

  <div class="modal-footer">
    <div class="row">
      <div class="col-sm-8">
        <span ng-if="$ctrl.sending">
          <fa-icon [icon]="'spinner'" animation="spin"></fa-icon> {{'global.en_cours.SEND' | translate}}
        </span>

        <span ng-if="$ctrl.uploading">
          <fa-icon [icon]="'spinner'" animation="spin"></fa-icon> Envoi de pièces jointes en cours ...
        </span>

        <span class="text-warning" ng-if="!$ctrl.uploading && $ctrl.attachmentsSize > $ctrl.attachmentsMaxSize">
          Les pièces jointes sont trop volumineuses.<br>La taille totale des pièces jointes est de
          {{$ctrl.attachmentsSize / 1024 / 1024 | number:2}} / 10 Mo maximum.
        </span>
      </div>

      <div class="col-sm-4">
        <button ng-disabled="$ctrl.sending || $ctrl.uploading || $ctrl.attachmentsSize > $ctrl.attachmentsMaxSize"
                type="submit" class="btn btn-primary">
          <fa-icon [icon]="['far', 'envelope']"></fa-icon> Envoyer l'email
        </button>
      </div>
    </div>
  </div>
</form>
`;
