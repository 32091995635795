import {Component, inject, Input, output, ViewChild} from '@angular/core';
import {map, take, tap} from 'rxjs/operators';
import {NgModel} from '@angular/forms';
import {IFormSelectInputOptions, IFormSelectInputOptionsGroupValueReturn} from '@shared/form/form.interfaces';
import {AppFormSelectInputComponent} from '@shared/form/select/input/form.select-input.component';
import Participant from '@core/models/participants/participant/participant.model';
import {CParticipantsService} from '@models/participants/collection/participants.collection.service';

@Component({
    exportAs: 'participantsSelect',
    imports: [AppFormSelectInputComponent],
    selector: 'app-participants-select',
    templateUrl: 'participants-select.component.html',
})
export class AppParticipantsSelectComponent {
    static readonly initParticipantsSelectOptions: IFormSelectInputOptions = {
        bindLabel: 'convivialName',
        loadingText: 'Chargement des participants',
        name: 'participantsSelect',
        notFoundText: 'Aucun participant trouvé',
        placeholder: 'Rechercher un participant',
        groupBy: (participant: Participant): string => {
            if (participant.isPerson()) {
                return AppParticipantsSelectComponent.keys.PERSON;
            } else if (participant.isUser()) {
                return AppParticipantsSelectComponent.keys.USER;
            }

            return AppParticipantsSelectComponent.keys.AUTRE;
        },
        groupValue: (key: string, children: Participant[]): IFormSelectInputOptionsGroupValueReturn => {
            let labelPlural: string;
            let labelSingular: string;

            if (key === AppParticipantsSelectComponent.keys.PERSON) {
                labelPlural = 'Contacts';
                labelSingular = 'Contact';
            } else if (key === AppParticipantsSelectComponent.keys.USER) {
                labelPlural = 'Utilisateurs';
                labelSingular = 'Utilisateur';
            } else {
                labelPlural = 'Autres';
                labelSingular = 'Autre';
            }

            return {labelPlural, labelSingular, total: children.length};
        },
    };
    static readonly keys = {...{AUTRE: 'autre'}, ...Participant.types};
    readonly selected = output<Participant>();
    private _cParticipantsService = inject(CParticipantsService);
    private _formInput!: NgModel;
    private _loading = false;
    private _options: IFormSelectInputOptions = {...AppParticipantsSelectComponent.initParticipantsSelectOptions};
    private _participant!: Participant;
    private _participants: Participant[] = [];

    get formInput(): NgModel {
        return this._formInput;
    }

    get loading(): boolean {
        return this._loading;
    }

    get options(): IFormSelectInputOptions {
        return this._options;
    }

    @Input()
    set options(value: IFormSelectInputOptions) {
        this._options = {...AppParticipantsSelectComponent.initParticipantsSelectOptions, ...value};
    }

    get participant(): Participant {
        return this._participant;
    }

    @Input()
    set participant(value: Participant) {
        this._participant = value;
    }

    get participants(): Participant[] {
        return this._participants;
    }

    @ViewChild('participantsSelectInput')
    set participantsSelectInput(value: AppFormSelectInputComponent) {
        setTimeout(_ => this._formInput = value.formInput, 1);
    }

    onSelect(participant: unknown): void {
        this.selected.emit(participant as Participant);
    }

    search(keyword: string): void {
        this._participants = [];
        this._loading = true;
        this._cParticipantsService.search$(keyword).pipe(
            map(cParticipants => cParticipants.results),
            tap(participants => this._participants = participants),
            take(1),
        ).subscribe(_ => this._loading = false);
    }
}
