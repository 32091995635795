export default `
<div class="container-fluid container-fullw tw:bg-white">
  <div class="row">
    <div class="col-xs-6 col-sm-4 col-md-3 col-lg-2">
      <div class="shortcut">
        <div class="panel panel-white no-radius tw:text-center">
          <div class="panel-body">
            <button type="button" ng-click="$ctrl.duplicate()">
              <app-fa-shortcut [fa-stack]="{action_icon_name: 'copy', action_icon_transform: 'shrink-36 up-26 right-29', main_icon_name: 'key'}">
              </app-fa-shortcut>

              <h4>Dupliquer</h4>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="col-xs-6 col-sm-4 col-md-3 col-lg-2">
      <div class="shortcut">
        <div class="panel panel-white no-radius tw:text-center">
          <div class="panel-body">
            <button type="button" ng-click="$ctrl.createVente()">
              <app-fa-shortcut [fa-stack]="{action_icon_name: 'arrow-right-arrow-left', main_icon_name: 'house'}">
              </app-fa-shortcut>

              <h4>Transformer en vente</h4>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="col-xs-6 col-sm-4 col-md-3 col-lg-2">
      <div class="shortcut">
        <div class="panel panel-white no-radius tw:text-center">
          <div class="panel-body">
            <button type="button" ng-click="$ctrl.createEstimation()">
              <app-fa-shortcut [fa-stack]="{action_icon_name: 'arrow-right-arrow-left', main_icon_name: 'chart-line'}">
              </app-fa-shortcut>

              <h4>Transformer en estimation</h4>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="col-xs-6 col-sm-4 col-md-3 col-lg-2">
      <div class="shortcut">
        <div class="panel panel-white no-radius tw:text-center tw:relative">
          <div class="panel-body">
            <a ng-click="$ctrl.ng2RouterStateForNgService.navigateByUrl('/app/locations/' + $ctrl.location.id.toString() + '/bons-visite/' + $ctrl.BonvisiteStatutNEW + '/edit')">
              <app-fa-shortcut [fa-stack]="{action_icon_name: 'plus', action_icon_transform: 'shrink-36 up-26 right-32', main_icon_name: 'file-alt', main_icon_name_prefix: 'far'}">
              </app-fa-shortcut>

              <h4>Créer un bon de visite</h4>
            </a>
          </div>

          <div class="tw:absolute tw:right-0 tw:bottom-0 tw:text-xs">
            <a ng-click="$ctrl.ng2RouterStateForNgService.navigateByUrl('/app/locations/' + $ctrl.location.id.toString() + '/bons-visite/portefeuille')"
               class="tw:block tw:p-2">Voir la liste</a>
          </div>
        </div>
      </div>
    </div>

    <div class="col-xs-6 col-sm-4 col-md-3 col-lg-2">
      <div class="shortcut">
        <div class="panel panel-white no-radius tw:text-center">
          <div class="panel-body">
            <button type="button" ng-click="$ctrl.createEmail()">
              <app-fa-shortcut [fa-stack]="{action_icon_name: 'plus', action_icon_transform: 'shrink-36 up-26 right-32', main_icon_name: 'envelope', main_icon_name_prefix: 'far'}">
              </app-fa-shortcut>

              <h4>Envoyer un E-mail</h4>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row lifetime">
    <!-- Archivage -->
    <div ng-if="$ctrl.location.statut !== $ctrl.STATUT_ARCHIVE" class="col-sm-6">
      <div class="panel panel-white no-radius">
        <div class="panel-heading border-bottom">
          <h4 class="panel-title text-bold"><fa-icon [icon]="'archive'"></fa-icon> Archivage</h4>
        </div>

        <div class="panel-body text-info">
          <span>Ce bien en location est actif. Il n'est pas encore archivé.</span>

          <p class="text-muted">
            Vous pouvez procéder à son archivage en cliquant sur le lien ci-dessous.<br>
            Il sera retiré de votre portefeuille de biens en location.
          </p>
        </div>

        <div class="panel-footer">
          <button type="button" class="text-bold text-danger" ng-click="$ctrl.archive()">
            Archiver le bien en location ...
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="tw:text-right">
    Bien créé le <app-ng2-date [date]="$ctrl.location.bien.createdAt" format="shortDate"></app-ng2-date> |
    Dernière modification le <app-ng2-date [date]="$ctrl.location.bien.updatedAt" format="shortDate"></app-ng2-date>
  </div>
</div>
`;
