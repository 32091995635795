import {inject, Injectable} from '@angular/core';
import {CommuneFactory} from '@models/communes/commune/commune.factory';
import {ICommuneApi} from '@models/communes/commune/commune.interfaces';
import Commune from '@models/communes/commune/commune.model';

@Injectable({providedIn: 'root'})
export class CommunesFactory {
    private _communeFactory = inject(CommuneFactory);

    create(communeApis: ICommuneApi[] = []): Commune[] {
        return (communeApis || []).filter(communeApi => communeApi)
            .map(communeApi => this._communeFactory.create(communeApi));
    }
}
