@if (mustBeOpen$ | appLoadObs | async; as mustBeOpen) {
  <div class="tw:hidden tw:md:block tw:w-56 tw:bg-gray-100 tw:overflow-y-auto tw:h-full" #navigationLateral
       [@openClose]="skipAnimations || mustBeOpen.value"
       [ngClass]="mustBeOpen.value ? 'tw:!block tw:absolute tw:shadow-xl' : ''" (appClickAway)="close()">
    @for (section of sections; track section; let first = $first) {
      <div class="tw:mb-4">
        @if (!first) {
          <div class="tw:mx-2 tw:border-t tw:border-gray-200"></div>
        }

        @if (section.title) {
          <div class="tw:pt-4 tw:px-2 tw:pb-2 tw:text-xs tw:text-center tw:truncate">{{ section.title }}</div>
        }

        @for (menu of section.menus; track menu) {
          <div class="tw:flex tw:flex-col tw:gap-1 tw:text-sm" (click)="clickMenu($event)">
            <a [routerLink]="menu.route" #rlaMain="routerLinkActive"
               class="tw:group tw:w-full tw:flex tw:items-center tw:py-3 tw:px-4 tw:border-solid tw:border-l-4 tw:border-transparent tw:text-gray-600 tw:hover:bg-gray-50 tw:hover:text-gray-600 tw:focus:text-gray-600"
               [routerLinkActive]="menu.subMenus ? '' : 'tw:bg-gray-200 tw:border-blue-500 tw:hover:bg-gray-200'">
              <span class="tw:flex tw:justify-center tw:h-6 tw:w-6 tw:mr-2 tw:text-gray-400 tw:group-hover:text-gray-400 tw:shrink-0"
                    routerLinkActive="tw:text-blue-500 tw:group-hover:text-blue-500" [ngClass]="menu.colorIcon">
                @if (menu.code === menuCodes.DOCUMENTS) {
                  <span><fa-icon [icon]="['far', 'file']"/></span>
                }

                @if (menu.code === menuCodes.DOSSIERS) {
                  <span><fa-icon [icon]="'align-justify'"/></span>
                }

                @if (menu.code === menuCodes.ETUDE) {
                  <span><fa-icon [icon]="'building-columns'"/></span>
                }

                @if (menu.code === menuCodes.GED) {
                  <span><fa-icon [icon]="'file'"/></span>
                }

                @if (menu.code === menuCodes.LOCATION) {
                  <span><fa-icon [icon]="'key'"/></span>
                }

                @if (menu.code === menuCodes.NETWORK) {
                  <span><fa-icon [icon]="'network-wired'"/></span>
                }

                @if (menu.code === menuCodes.NOTESTIM) {
                  <app-noteo-logo-septeo class="tw:h-[80%]" label="Notestim"/>
                }

                @if (menu.code === menuCodes.OVERVIEW) {
                  <span><fa-icon [icon]="'eye'"/></span>
                }

                @if (menu.code === menuCodes.PASSERELLES) {
                  <span><fa-icon [icon]="'rss'"/></span>
                }

                @if (menu.code === menuCodes.SUIVI) {
                  <span><fa-icon [icon]="['far', 'calendar']"/></span>
                }

                @if (menu.code === menuCodes.VENTE) {
                  <span><fa-icon [icon]="'house'"/></span>
                }
              </span>

              <span [routerLinkActive]="menu.subMenus ? '' : 'tw:text-gray-900 tw:focus:text-gray-900'"
                    class="tw:flex-1" routerLinkActive="tw:font-medium">{{ menu.label }}</span>
            </a>

            @if (menu.subMenus) {
              <div [ngClass]="rlaMain.isActive ? 'tw:block' : 'tw:hidden'">
                @for (subMenu of menu.subMenus; track subMenu) {
                  <a class="tw:group tw:w-full tw:flex tw:items-center tw:py-3 tw:pr-4 tw:pl-12 tw:border-solid tw:border-l-4 tw:border-transparent tw:text-gray-600 tw:hover:bg-gray-50 tw:hover:text-gray-600 tw:focus:text-gray-600"
                     routerLinkActive="tw:bg-gray-200 tw:border-blue-500 tw:hover:bg-gray-200 tw:font-medium tw:text-gray-900 tw:hover:text-gray-900 tw:focus:text-gray-900"
                     [routerLink]="subMenu.route">{{ subMenu.label }}</a>
                }
              </div>
            }
          </div>
        }
      </div>
    }
  </div>
}
