@if (ventePrice.priceHNI > 0) {
  <div>
    <div class="tw:flex tw:items-center tw:justify-center tw:space-x-1 tw:w-fit tw:mx-auto tw:rounded-full tw:px-3 tw:py-1 tw:font-medium tw:bg-stone-200 tw:text-stone-500 tw:print:!bg-stone-200 tw:print:!text-stone-500 tw:whitespace-nowrap">
      <span [title]="priceHNITitle">
        {{ (vente.isInteractive() ? vente.interactivePremiereOffre : ventePrice.priceHNI) | textCurrency }} HNI
      </span>

      @if (options.enabledNV && options.inlineNV && ventePrice.isTypeChargesNV()) {
        <span [title]="priceNVTitle" class="tw:opacity-50">{{ ventePrice.priceNV | textCurrency }} NV</span>
      }

      @if (vente.isSimple()) {
        <app-vente-prix-history [vente]="vente" class="tw:contents"/>
      }
    </div>

    @if (options.enabledNV && !options.inlineNV && ventePrice.isTypeChargesNV()) {
      <div [title]="priceNVTitle"
           class="tw:mt-2 tw:text-center tw:text-stone-500 tw:opacity-50">{{ ventePrice.priceNV | textCurrency }} NV
      </div>
    }
  </div>
} @else {
  <div class="tw:w-fit tw:mx-auto tw:rounded-full tw:px-3 tw:py-1 tw:font-medium tw:bg-stone-200 tw:text-stone-500 tw:print:!bg-stone-200 tw:print:!text-stone-500 tw:whitespace-nowrap tw:first-letter:uppercase"
       [title]="vente.labelPriceNC[0].toUpperCase() + vente.labelPriceNC.slice(1)">
    {{ vente.labelPriceNC.replace('non communiquée', 'NC').replace('non communiqué', 'NC') }}
  </div>
}
