<div appProvideParentForm>
  @if (showPanelForBien('agencementInterieur')) {
    <fieldset>
      <legend>Agencement intérieur</legend>

      <div class="row">
        <!-- Pièces -->
        @if (showFieldForBien(FIELDS.surfaces)) {
          <div class="col-xs-12 col-md-6">
            @if (piecesReady$ | async) {
              @if (ngBienDossier.bien.pieces.length > 0) {
                <table class="table table-collection">
                  <thead>
                  <tr>
                    <th style="min-width: 20%">Type de pièce *</th>

                    <th style="min-width: 20%">Étage</th>

                    <th style="width: 100px">Surface</th>

                    <th style="width: 100px"></th>
                  </tr>
                  </thead>

                  <tbody>
                    @for (bienPiece of ngBienDossier.bien.pieces; track bienPiece; let idx = $index) {
                      <tr class="item"
                          [appCollectionSortable]="[AppLayoutBienEditConstructionPiecesName, ngBienDossier.bien.pieces, bienPiece.uuid]">
                        <td [appCollectionItemError]="[bienPiece, bienPieceTypePiece.formInput]">
                          <app-dictionary-select-items [name]="DICTIONARY_NAME_PIECE_TYPES"
                                                       #bienPieceTypePiece="dictionarySelect" class="tw:block tw:w-full"
                                                       [ngItem]="bienPiece.piece"
                                                       (selected)="onSelectPieceType(bienPiece, $event)"
                                                       [options]="{acceptNoResult: true, name: 'piece' + bienPiece.uuid, required: !!bienPiece.uuid}"/>
                        </td>

                        <td [appCollectionItemError]="[bienPiece, bienPieceEtage.formInput]">
                          <app-dictionary-select-items #bienPieceEtage="dictionarySelect" [ngItem]="bienPiece.etage"
                                                       class="tw:block tw:w-full" [name]="DICTIONARY_NAME_ETAGES"
                                                       [options]="{name: 'etage' + bienPiece.uuid}"
                                                       (selected)="onSelectPieceEtage(bienPiece, $event)"/>
                        </td>

                        <td [appCollectionItemError]="[bienPiece, surface]">
                          <span class="num-icon tw:md:!top-[34%] tw:lg:!top-1/3">m<sup>2</sup></span>

                          <input type="text" [name]="'surface' + bienPiece.uuid" #surface="ngModel"
                                 class="form-control number"
                                 [(ngModel)]="ngBienDossier.bien.pieces[idx].surface" (blur)="savePiece(bienPiece)">
                        </td>

                        <td class="other tw:text-center">
                          @if (!!bienPiece.uuid) {
                            @if (bienPiece._esk.loading) {
                              <fa-icon [icon]="'spinner'" animation="spin"/>
                            } @else {
                              <button type="button" class="btn btn-o btn-null" (click)="editPiece(bienPiece)">
                                <fa-icon [icon]="'edit'"/>
                              </button>

                              <button type="button" title="Supprimer la pièce" class="btn btn-o text-large"
                                      (click)="removePiece(bienPiece)"><fa-icon [icon]="'trash-alt'"/></button>
                            }
                          }
                        </td>
                      </tr>
                    }
                  </tbody>
                </table>
              }
            }

            @if (ngBienDossier.bien._esk.surfacePieces! > 0) {
              <div class="tw:w-full tw:pr-[100px]">
                <div class="text-right">
                  @if (ngBienDossier.bien._esk.surfacePiecesHabitables! > 0 || ngBienDossier.bien._esk.surfacePiecesNonHabitables! > 0) {
                    Surface
                    @if (ngBienDossier.bien._esk.surfacePiecesHabitables! > 0) {
                      habitable de {{ ngBienDossier.bien._esk.surfacePiecesHabitables! | textCurrency:'m²' }}
                    }
                    @if (ngBienDossier.bien._esk.surfacePiecesHabitables! > 0 && ngBienDossier.bien._esk.surfacePiecesNonHabitables! > 0) {
                      et
                    }
                    @if (ngBienDossier.bien._esk.surfacePiecesNonHabitables! > 0) {
                      non habitable de {{ ngBienDossier.bien._esk.surfacePiecesNonHabitables! | textCurrency:'m²' }}
                    }
                  } @else {
                    Surface totale de {{ ngBienDossier.bien._esk.surfacePieces! | textCurrency:'m²' }}
                  }
                </div>
              </div>
            }

            @if (ngBienDossier.bien.pieces.length <= 0) {
              <div class="tw:py-4 tw:text-center">Aucune pièce saisie</div>
            }

            <div class="tw:flex tw:justify-center tw:py-4">
              @if (ngBienDossier.uuid || ngBienDossier.bien.pieces.length === 0) {
                <button type="button" class="tw:btn-secondary-info" (click)="addNewPiece()">
                  <fa-icon [icon]="'plus'"/> <span>Ajouter une pièce</span>
                </button>
              } @else {
                Enregistrer votre bien une première fois afin de pouvoir saisir plus de pièces.
              }
            </div>
          </div>
        }

        <!-- Logements -->
        @if (showFieldForBien(FIELDS.logements)) {
          <div class="col-xs-12 col-md-6">
            @if (logementsReady$ | async) {
              @if (ngBienDossier.bien.logements.length > 0) {
                <table class="table table-collection">
                  <thead><tr><th>Type de logement *</th><th>Étage</th><th>Surface</th><th></th></tr></thead>

                  <tbody>
                    @for (bienLogement of ngBienDossier.bien.logements; track bienLogement; let idx = $index) {
                      <tr class="item"
                          [appCollectionSortable]="[AppLayoutBienEditConstructionLogementsName, ngBienDossier.bien.logements, bienLogement.uuid]">
                        <td class="col-xs-5" [appCollectionItemError]="[bienLogement, bienLogementTypeLogement.formInput]">
                          <app-dictionary-select-items #bienLogementTypeLogement="dictionarySelect"
                                                       [options]="{acceptNoResult: true, name: 'logement' + bienLogement.uuid, required: !!bienLogement.uuid}"
                                                       (selected)="onSelectLogementType(bienLogement, $event)"
                                                       [ngItem]="bienLogement.logement" [name]="DICTIONARY_NAME_LOGEMENTS"/>
                        </td>

                        <td class="col-xs-3" [appCollectionItemError]="[bienLogement, bienLogementEtage.formInput]">
                          <app-dictionary-select-items #bienLogementEtage="dictionarySelect" [ngItem]="bienLogement.etage"
                                                       [name]="DICTIONARY_NAME_ETAGES"
                                                       [options]="{name: 'etage' + bienLogement.uuid}"
                                                       (selected)="onSelectLogementEtage(bienLogement, $event)"/>
                        </td>

                        <td class="col-xs-2" [appCollectionItemError]="[bienLogement, surface]">
                          <span class="num-icon tw:md:!top-[34%] tw:lg:!top-[39%] tw:2xl:!top-1/3">m<sup>2</sup></span>

                          <input type="text" [name]="'surface' + bienLogement.uuid" #surface="ngModel"
                                 [(ngModel)]="ngBienDossier.bien.logements[idx].surface"
                                 class="form-control number" (blur)="saveLogement(bienLogement)">
                        </td>

                        <td class="col-xs-2 other tw:text-center">
                          @if (!!bienLogement.uuid) {
                            @if (bienLogement._esk.loading) {
                              <fa-icon [icon]="'spinner'" animation="spin"/>
                            } @else {
                              <button type="button" class="btn btn-o btn-null" (click)="editLogement(bienLogement)">
                                <fa-icon [icon]="'edit'"/>
                              </button>

                              <button type="button" title="Supprimer le logement" class="btn btn-o text-large"
                                      (click)="removeLogement(bienLogement)"><fa-icon [icon]="'trash-alt'"/></button>
                            }
                          }
                        </td>
                      </tr>
                    }
                  </tbody>
                </table>
              }
            }

            @if (ngBienDossier.bien._esk.surfaceLogements! > 0) {
              <div class="row">
                <div class="col-xs-10 text-right">
                  Surface totale de {{ ngBienDossier.bien._esk.surfaceLogements! | textCurrency:'m²' }}
                </div>
              </div>
            }

            @if (ngBienDossier.bien.logements.length <= 0) {
              <div class="tw:py-4 tw:text-center">Aucun logement saisi</div>
            }

            <div class="tw:flex tw:justify-center tw:py-4">
              @if (ngBienDossier.uuid || ngBienDossier.bien.logements.length === 0) {
                <button type="button" class="tw:btn-secondary-info" (click)="addNewLogement()">
                  <fa-icon [icon]="'plus'"/> <span>Ajouter un logement</span>
                </button>
              } @else {
                Enregistrer votre bien une première fois afin de pouvoir saisir plus de logements.
              }
            </div>
          </div>
        }

        <!-- Informations relatives aux pièces -->
        <div class="col-xs-12 col-md-6 padding-left-30">
          <div class="row">
            <!-- Cave -->
            @if (showFieldForBien(FIELDS.cave)) {
              <div class="col-xs-12 col-sm-4 col-md-6">
                <div class="form-group tw:!mb-0" [appBienEditConstraints]="cave.formInput">
                  <label>Cave</label>

                  <app-form-radio-ynn [model]="ngBienDossier.bien.option.cave"
                                      [options]="{genre: 'female', name: FIELDS.cave}"
                                      #cave="formRadioYnn" (selected)="ngBienDossier.bien.option.cave = $event"/>
                </div>

                <!-- Surface cave -->
                @if (ngBienDossier.bien.option.cave && showFieldForBien(FIELDS.surfaceCave)) {
                  <div [appFormError]="surfaceCave" class="form-group">
                    <div class="input-group input-group-number input-group-area">
                      <input pattern="^(?=.*[1-9])\d*(?:.\d*)?\s*$" [(ngModel)]="ngBienDossier.bien.option.surfaceCave"
                             #surfaceCave="ngModel" type="text" name="surfaceCave" class="form-control text-right"
                             title="Surface de la cave" placeholder="Surface">

                      <span class="input-group-addon">m<sup>2</sup></span>
                    </div>
                  </div>
                }
              </div>
            }

            <!-- Sous-sol -->
            @if (showFieldForBien(FIELDS.sousSol)) {
              <div class="col-xs-12 col-sm-4 col-md-6">
                <div class="form-group" [appBienEditConstraints]="sousSol.formInput">
                  <label>Sous-sol</label>

                  <app-form-radio-ynn [model]="ngBienDossier.bien.option.sousSol" [options]="{name: 'sousSol'}"
                                      #sousSol="formRadioYnn" (selected)="ngBienDossier.bien.option.sousSol = $event"/>
                </div>
              </div>
            }
          </div>

          <div class="row">
            <!-- Grenier -->
            @if (showFieldForBien(FIELDS.grenier)) {
              <div class="col-xs-12 col-sm-4 col-md-6">
                <div class="form-group" [appBienEditConstraints]="grenier.formInput">
                  <label>Grenier</label>

                  <app-form-radio-ynn [model]="ngBienDossier.bien.option.grenier" [options]="{name: 'grenier'}"
                                      #grenier="formRadioYnn" (selected)="ngBienDossier.bien.option.grenier = $event"/>
                </div>
              </div>
            }

            <!-- Combles -->
            @if (showFieldForBien(FIELDS.combles)) {
              <div class="col-xs-12 col-sm-4 col-md-6">
                <div class="form-group" [appBienEditConstraints]="combles.formInput">
                  <label>Combles</label>

                  <app-form-radio-ynn [model]="ngBienDossier.bien.option.comblesAmenageables"
                                      (selected)="ngBienDossier.bien.option.comblesAmenageables = $event"
                                      [options]="{name: FIELDS.combles}" #combles="formRadioYnn"/>
                </div>
              </div>
            }
          </div>

          <div class="row">
            <!-- Nombre de pièces -->
            @if (showFieldForBien(FIELDS.nombrePieces)) {
              <div class="col-xs-12 col-sm-4 col-md-6">
                <div class="form-group" [appBienEditConstraints]="nombrePieces">
                  <label for="bien-nombre-pieces">Nombre de pièces</label>

                  <input type="text" id="bien-nombre-pieces" class="form-control text-right" pattern="[0-9]+" min="0"
                         name="nombrePieces" #nombrePieces="ngModel"
                         [(ngModel)]="ngBienDossier.bien.option.nombrePieces">
                </div>
              </div>
            }

            <!-- Nombre de logements -->
            @if (showFieldForBien(FIELDS.nombreLogements)) {
              <div class="col-xs-12 col-sm-4 col-md-6">
                <div class="form-group" [appBienEditConstraints]="nombreLogements">
                  <label for="bien-nombre-logements">Nombre de logements</label>

                  <input type="text" id="bien-nombre-logements" class="form-control text-right" pattern="[0-9]+" min="0"
                         name="nombreLogements" #nombreLogements="ngModel"
                         [(ngModel)]="ngBienDossier.bien.option.nombreLogements">
                </div>
              </div>
            }

            <!-- Nombre de chambres -->
            @if (showFieldForBien(FIELDS.nombreChambres)) {
              <div class="col-xs-12 col-sm-4 col-md-6">
                <div class="form-group" [appBienEditConstraints]="nombreChambres">
                  <label for="bien-nombre-chambres">Nombre de chambres</label>

                  <input type="text" id="bien-nombre-chambres" class="form-control text-right" pattern="[0-9]+" min="0"
                         name="nombreChambres" #nombreChambres="ngModel"
                         [(ngModel)]="ngBienDossier.bien.option.nombreChambres">
                </div>
              </div>
            }

            <!-- Surface du séjour -->
            @if (showFieldForBien(FIELDS.surfaceSejour)) {
              <div class="col-xs-12 col-sm-4 col-md-6">
                <div class="form-group" [appBienEditConstraints]="surfaceSejour">
                  <label for="bien-surface-sejour">Surface séjour</label>

                  <div class="input-group input-group-number input-group-area">
                    <input pattern="^\d*(?:.\d*)?\s*$" [(ngModel)]="ngBienDossier.bien.option.surfaceSejour"
                           #surfaceSejour="ngModel" type="text" id="bien-surface-sejour" name="surfaceSejour"
                           class="form-control text-right">

                    <span class="input-group-addon">m<sup>2</sup></span>
                  </div>
                </div>
              </div>
            }

            <!-- Hauteur sous plafond -->
            @if (showFieldForBien(FIELDS.hauteurSousPlafond)) {
              <div class="col-xs-12 col-sm-4 col-md-6">
                <div class="form-group" [appBienEditConstraints]="hauteurSousPlafond">
                  <label for="bien-surface-sejour">Hauteur sous plafond</label>

                  <div class="input-group input-group-number input-group-area">
                    <input [(ngModel)]="ngBienDossier.bien.option.hauteurSousPlafond"
                           pattern="^\d*(?:.\d*)?\s*$" #hauteurSousPlafond="ngModel" type="text"
                           id="bien-hauteur-sous-plafond" name="hauteurSousPlafond" class="form-control text-right">

                    <span class="input-group-addon">m</span>
                  </div>
                </div>
              </div>
            }
          </div>
        </div>

        <div class="col-xs-12"><hr></div>

        <!-- Informations relatives aux équipements -->
        <div class="col-xs-12">
          <div class="row">
            <!-- Nombre de niveaux -->
            @if (showFieldForBien(FIELDS.nombreEtages)) {
              <div class="col-xs-12 col-sm-6 col-md-3">
                <div class="form-group" [appBienEditConstraints]="nombreEtages">
                  <label for="bien-nombre-etages">Nombre de niveaux</label>

                  <input type="text" id="bien-nombre-etages" class="form-control text-right" pattern="[0-9]+" min="0"
                         name="nombreEtages" #nombreEtages="ngModel"
                         [(ngModel)]="ngBienDossier.bien.option.nombreEtages">
                </div>
              </div>
            }

            <!-- Numéro de l'étage -->
            @if (showFieldForBien(FIELDS.numeroEtage)) {
              <div class="col-xs-12 col-sm-6 col-md-3">
                <div class="form-group" [appBienEditConstraints]="numeroEtage">
                  <label for="bien-numero-etage">Numéro étage</label>

                  <input type="text" id="bien-numero-etage" class="form-control text-right" pattern="[0-9]+"
                         name="numeroEtage" #numeroEtage="ngModel" [(ngModel)]="ngBienDossier.bien.option.numeroEtage">
                </div>
              </div>
            }

            <!-- Cuisine équipée -->
            @if (showFieldForBien(FIELDS.cuisineEquipee)) {
              <div class="col-xs-12 col-sm-6 col-md-3">
                <div class="form-group" [appBienEditConstraints]="cuisineEquipee.formInput">
                  <label>Cuisine équipée</label>

                  <app-form-radio-ynn [model]="ngBienDossier.bien.option.cuisineEquipee" #cuisineEquipee="formRadioYnn"
                                      [options]="{genre: 'female', name: FIELDS.cuisineEquipee}"
                                      (selected)="ngBienDossier.bien.option.cuisineEquipee = $event"/>
                </div>
              </div>
            }

            <!-- Ascenseur -->
            @if (showFieldForBien(FIELDS.ascenseur)) {
              <div class="col-xs-12 col-sm-6 col-md-3">
                <div class="form-group" [appBienEditConstraints]="ascenseur.formInput">
                  <label>Ascenseur</label>

                  <app-form-radio-ynn (selected)="ngBienDossier.bien.option.ascenseur = $event"
                                      #ascenseur="formRadioYnn" [options]="{name: FIELDS.ascenseur}"
                                      [model]="ngBienDossier.bien.option.ascenseur"/>
                </div>
              </div>
            }

            <!-- Dernier étage -->
            @if (showFieldForBien(FIELDS.dernierEtage)) {
              <div class="col-xs-12 col-sm-6 col-md-3">
                <div class="form-group" [appBienEditConstraints]="dernierEtage.formInput">
                  <label>Dernier étage</label>

                  <app-form-radio-ynn (selected)="ngBienDossier.bien.option.dernierEtage = $event"
                                      [options]="{name: FIELDS.dernierEtage}"
                                      [model]="ngBienDossier.bien.option.dernierEtage" #dernierEtage="formRadioYnn"/>
                </div>
              </div>
            }

            <!-- Connexions Internet -->
            @if (showFieldForBien(FIELDS.connexionsInternet)) {
              <div class="form-group col-xs-12 col-sm-6 col-md-3">
                <label>Connexion Internet</label>

                <app-dictionary-select-items [model]="bienConnexionsInternet"
                                             [name]="DICTIONARY_NAME_INTERNET_CONNEXIONS"
                                             [options]="{multiple: true, name: FIELDS.connexionsInternet}"
                                             (selected)="onSelectConnexionsInternet($event)"/>
              </div>
            }
          </div>

          <!-- Sanitaires -->
          @if (showFieldForBien(FIELDS.nombreWc) || showFieldForBien(FIELDS.nombreSallesBains) || showFieldForBien(FIELDS.nombreSallesEau)) {
            <div class="row">
              <!-- Nombre de WC -->
              @if (showFieldForBien(FIELDS.nombreWc)) {
                <div class="col-xs-12 col-sm-6 col-md-3">
                  <div class="form-group" [appFormError]="nombreWc">
                    <label for="bien-nombre-wc">Nombre de WC</label>

                    <input type="text" id="bien-nombre-wc" class="form-control text-right" pattern="[0-9]+" min="0"
                           name="nombreWc" #nombreWc="ngModel" [(ngModel)]="ngBienDossier.bien.option.nombreWc">
                  </div>
                </div>
              }

              <!-- Nombre de salles de bains -->
              @if (showFieldForBien(FIELDS.nombreSallesBains)) {
                <div class="col-xs-12 col-sm-6 col-md-3">
                  <div class="form-group" [appFormError]="nombreSallesBains">
                    <label for="bien-nombre-salle-bains">Nombre de salles de bains</label>

                    <input type="text" id="bien-nombre-salle-bains" class="form-control text-right" pattern="[0-9]+"
                           min="0" name="nombreSallesBains" #nombreSallesBains="ngModel"
                           [(ngModel)]="ngBienDossier.bien.option.nombreSallesBains">
                  </div>
                </div>
              }

              <!-- Nombre de salles d'eau -->
              @if (showFieldForBien(FIELDS.nombreSallesEau)) {
                <div class="col-xs-12 col-sm-6 col-md-3">
                  <div class="form-group" [appFormError]="nombreSallesEau">
                    <label for="bien-nombre-salle-eau">Nombre de salles d'eau</label>

                    <input type="text" id="bien-nombre-salle-eau" class="form-control text-right" pattern="[0-9]+"
                           min="0" name="nombreSallesEau" #nombreSallesEau="ngModel"
                           [(ngModel)]="ngBienDossier.bien.option.nombreSallesEau">
                  </div>
                </div>
              }

              @if (showFieldForBien(FIELDS.nombreDouches) || showFieldForBien(FIELDS.nombreBains)) {
                <div class="col-xs-12 col-sm-6 col-md-3 margin-top-20">
                  <button type="button" (click)="editSanitaires()" class="btn btn-o btn-primary">
                    <fa-icon [icon]="'tint'"/> <span>Précisions</span>
                  </button>
                </div>
              }
            </div>
          }

          @if (showFieldForBien(FIELDS.alarmeLocation) || showFieldForBien(FIELDS.alarmePleinePropriete) || showFieldForBien(FIELDS.detecteurFumee)) {
            <div class="row">
              <!-- Alarme en location -->
              @if (showFieldForBien(FIELDS.alarmeLocation)) {
                <div class="form-group col-xs-12 col-sm-6 col-md-3">
                  <label>Alarme en location</label>

                  <app-form-radio-ynn [model]="ngBienDossier.bien.option.alarmeLocation"
                                      [options]="{genre: 'female', name: FIELDS.alarmeLocation}"
                                      (selected)="ngBienDossier.bien.option.alarmeLocation = $event"/>
                </div>
              }

              <!-- Alarme en pleine propriété -->
              @if (showFieldForBien(FIELDS.alarmePleinePropriete)) {
                <div class="form-group col-xs-12 col-sm-6 col-md-3">
                  <label>Alarme en pleine propriété</label>

                  <app-form-radio-ynn [model]="ngBienDossier.bien.option.alarmePleinePropriete"
                                      (selected)="ngBienDossier.bien.option.alarmePleinePropriete = $event"
                                      [options]="{genre: 'female', name: FIELDS.alarmePleinePropriete}"/>
                </div>
              }

              <!-- Détecteur de fumée -->
              @if (showFieldForBien(FIELDS.detecteurFumee)) {
                <div class="form-group col-xs-12 col-sm-6 col-md-3">
                  <label>Détecteur de fumée</label>

                  <app-form-radio-ynn [model]="ngBienDossier.bien.option.detecteurFumee"
                                      (selected)="ngBienDossier.bien.option.detecteurFumee = $event"
                                      [options]="{name: FIELDS.detecteurFumee}"/>
                </div>
              }
            </div>
          }

          @if (showFieldForBien(FIELDS.cheminee)) {
            <div class="row">
              <!-- Cheminée -->
              @if (showFieldForBien(FIELDS.cheminee)) {
                <div class="form-group col-xs-12">
                  <label>Cheminée</label>

                  <app-form-radio-ynn [model]="ngBienDossier.bien.option.cheminee"
                                      [options]="{genre: 'female', name: FIELDS.cheminee}"
                                      (selected)="ngBienDossier.bien.option.cheminee = $event"/>
                </div>
              }

              @if (ngBienDossier.bien.option.cheminee) {
                <div>
                  <!-- Nombre de cheminée -->
                  @if (showFieldForBien(FIELDS.nombreCheminees)) {
                    <div class="col-xs-12 col-sm-6 col-md-3">
                      <div class="form-group" [appFormError]="nombreCheminees">
                        <label for="bien-nombre-cheminees">Nombre de cheminées</label>

                        <input type="text" id="bien-nombre-cheminees" class="form-control text-right" pattern="[0-9]+"
                               min="0" name="nombreCheminees" #nombreCheminees="ngModel"
                               [(ngModel)]="ngBienDossier.bien.option.nombreCheminees">
                      </div>
                    </div>
                  }

                  <!-- Cheminée d'ornement -->
                  @if (showFieldForBien(FIELDS.chemineeOrnement)) {
                    <div class="form-group col-xs-12 col-md-3">
                      <div class="checkbox clip-check check-primary checkbox-inline">
                        <input type="checkbox" id="bien-cheminee-ornement" name="chemineeOrnement"
                               [(ngModel)]="ngBienDossier.bien.option.chemineeOrnement">

                        <label for="bien-cheminee-ornement">Cheminée d’ornement</label>
                      </div>
                    </div>
                  }

                  <!-- Cheminée fonctionnelle -->
                  @if (showFieldForBien(FIELDS.chemineeFonctionnelle)) {
                    <div class="form-group col-xs-12 col-md-3">
                      <div class="checkbox clip-check check-primary checkbox-inline">
                        <input type="checkbox" id="bien-cheminee-fonctionnelle" name="chemineeFonctionnelle"
                               [(ngModel)]="ngBienDossier.bien.option.chemineeFonctionnelle">

                        <label for="bien-cheminee-fonctionnelle">Cheminée fonctionnelle</label>
                      </div>
                    </div>
                  }
                </div>
              }
            </div>
          }
        </div>
      </div>

      <textarea class="form-control" name="{{FIELDS.commentsAgencementInterieur}}"
                placeholder="Saisir les informations complémentaires à l'agencement intérieur"
                [(ngModel)]="ngBienDossier.bien.option.commentsAgencementInterieur" [minHeight]="100"></textarea>
    </fieldset>
  }

  <fieldset>
    <legend>Agencement extérieur</legend>

    <div class="row">
      @if (showFieldForBien(FIELDS.terrasse)) {
        <div class="col-xs-12 col-sm-6 col-md-3">
          <!-- Terrasse -->
          <div [appBienEditConstraints]="terrasse.formInput">
            <label>Terrasse</label>

            <app-form-radio-ynn [model]="ngBienDossier.bien.option.terrasse" #terrasse="formRadioYnn"
                                (selected)="ngBienDossier.bien.option.terrasse = $event"
                                [options]="{genre: 'female', name: 'terrasse'}"/>
          </div>

          <!-- Surface terrasse -->
          @if (ngBienDossier.bien.option.terrasse && showFieldForBien(FIELDS.surfaceTerrasse)) {
            <div [appFormError]="surfaceTerrasse" class="form-group">
              <div class="input-group input-group-number input-group-area">
                <input pattern="^(?=.*[1-9])\d*(?:.\d*)?\s*$" [(ngModel)]="ngBienDossier.bien.option.surfaceTerrasse"
                       #surfaceTerrasse="ngModel" type="text" name="surfaceTerrasse" class="form-control text-right"
                       title="Surface de la terrasse" placeholder="Surface">

                <span class="input-group-addon">m<sup>2</sup></span>
              </div>
            </div>
          }
        </div>
      }

      @if (showFieldForBien(FIELDS.balcon)) {
        <div class="col-xs-12 col-sm-6 col-md-3">
          <!-- Balcon -->
          <div [appBienEditConstraints]="balcon.formInput">
            <label>Balcon</label>

            <app-form-radio-ynn [model]="ngBienDossier.bien.option.balcon" [options]="{name: FIELDS.balcon}"
                                (selected)="ngBienDossier.bien.option.balcon = $event" #balcon="formRadioYnn"/>
          </div>

          <!-- Surface balcon -->
          @if (ngBienDossier.bien.option.balcon && showFieldForBien(FIELDS.surfaceBalcon)) {
            <div [appFormError]="surfaceBalcon" class="form-group">
              <div class="input-group input-group-number input-group-area">
                <input pattern="^(?=.*[1-9])\d*(?:.\d*)?\s*$" [(ngModel)]="ngBienDossier.bien.option.surfaceBalcon"
                       #surfaceBalcon="ngModel" type="text" name="{{FIELDS.surfaceBalcon}}"
                       class="form-control text-right" title="Surface du balcon" placeholder="Surface">

                <span class="input-group-addon">m<sup>2</sup></span>
              </div>
            </div>
          }
        </div>
      }

      @if (showFieldForBien(FIELDS.cour)) {
        <div class="col-xs-12 col-sm-6 col-md-3">
          <!-- Cour -->
          <div [appBienEditConstraints]="cour.formInput">
            <label>Cour</label>

            <app-form-radio-ynn [model]="ngBienDossier.bien.option.cour"
                                [options]="{genre: 'female', name: FIELDS.cour}"
                                #cour="formRadioYnn" (selected)="ngBienDossier.bien.option.cour = $event"/>
          </div>

          <!-- Surface cour -->
          @if (ngBienDossier.bien.option.cour && showFieldForBien(FIELDS.surfaceCour)) {
            <div [appFormError]="surfaceCour" class="form-group">
              <div class="input-group input-group-number input-group-area">
                <input pattern="^(?=.*[1-9])\d*(?:.\d*)?\s*$" [(ngModel)]="ngBienDossier.bien.option.surfaceCour"
                       #surfaceCour="ngModel" type="text" name="surfaceCour" class="form-control text-right"
                       title="Surface de la cour" placeholder="Surface">

                <span class="input-group-addon">m<sup>2</sup></span>
              </div>
            </div>
          }
        </div>
      }

      @if (showFieldForBien(FIELDS.annexe)) {
        <div class="col-xs-12 col-sm-6 col-md-3">
          <!-- Annexes -->
          <div [appBienEditConstraints]="annexes.formInput">
            <label>Annexe(s)</label>

            <app-form-radio-ynn [model]="ngBienDossier.bien.option.annexe"
                                [options]="{genre: 'female', name: FIELDS.annexe}"
                                #annexes="formRadioYnn" (selected)="ngBienDossier.bien.option.annexe = $event"/>
          </div>

          <!-- Surface annexes -->
          @if (ngBienDossier.bien.option.annexe && showFieldForBien(FIELDS.surfaceAnnexe)) {
            <div [appFormError]="surfaceAnnexe" class="form-group">
              <div class="input-group input-group-number input-group-area">
                <input pattern="^(?=.*[1-9])\d*(?:.\d*)?\s*$" [(ngModel)]="ngBienDossier.bien.option.surfaceAnnexe"
                       #surfaceAnnexe="ngModel" type="text" name="surfaceAnnexe" class="form-control text-right"
                       title="Surface des annexes" placeholder="Surface">

                <span class="input-group-addon">m<sup>2</sup></span>
              </div>
            </div>
          }
        </div>
      }
    </div>

    <div class="row">
      <!-- Véranda -->
      @if (showFieldForBien(FIELDS.veranda)) {
        <div class="form-group col-xs-12 col-sm-6 col-md-3">
          <label>Véranda</label>

          <app-form-radio-ynn [model]="ngBienDossier.bien.option.veranda" [options]="{genre: 'female', name: 'veranda'}"
                              (selected)="ngBienDossier.bien.option.veranda = $event"/>
        </div>
      }

      <!-- Piscine -->
      @if (showFieldForBien(FIELDS.piscine)) {
        <div class="form-group col-xs-12 col-sm-6 col-md-3">
          <label>Piscine</label>

          <app-form-radio-ynn [model]="ngBienDossier.bien.option.piscine" [options]="{genre: 'female', name: 'piscine'}"
                              (selected)="ngBienDossier.bien.option.piscine = $event"/>
        </div>
      }

      <!-- Garage -->
      @if (showFieldForBien(FIELDS.garage)) {
        <div class="col-xs-12 col-sm-6 col-md-3">
          <div [appBienEditConstraints]="garage.formInput" class="form-group tw:!mb-0">
            <label>Garage</label>

            <app-form-radio-ynn [model]="ngBienDossier.bien.option.garage" [options]="{name: 'garage'}"
                                #garage="formRadioYnn" (selected)="ngBienDossier.bien.option.garage = $event"/>
          </div>

          <!-- Surface garage -->
          @if (ngBienDossier.bien.option.garage && showFieldForBien(FIELDS.surfaceGarage)) {
            <div [appFormError]="surfaceGarage" class="form-group">
              <div class="input-group input-group-number input-group-area">
                <input pattern="^(?=.*[1-9])\d*(?:.\d*)?\s*$" [(ngModel)]="ngBienDossier.bien.option.surfaceGarage"
                       #surfaceGarage="ngModel" type="text" name="surfaceGarage" class="form-control text-right"
                       title="Surface du garage" placeholder="Surface">

                <span class="input-group-addon">m<sup>2</sup></span>
              </div>
            </div>
          }
        </div>
      }

      <!-- Place de parking -->
      @if (showFieldForBien(FIELDS.nombrePlacesParking)) {
        <div class="col-xs-12 col-sm-6 col-md-3">
          <div class="form-group" [appBienEditConstraints]="nombrePlacesParking">
            <label for="bien-nombre-places-parking">Place(s) de parking</label>

            <input type="text" id="bien-nombre-places-parking" class="form-control text-right" pattern="[0-9]+" min="0"
                   name="nombrePlacesParking" #nombrePlacesParking="ngModel"
                   [(ngModel)]="ngBienDossier.bien.option.nombrePlacesParking">
          </div>
        </div>
      }
    </div>

    <div class="row">
      <!-- Citerne gaz en location -->
      @if (showFieldForBien(FIELDS.citerneGazLocation)) {
        <div class="form-group col-xs-12 col-sm-6 col-md-3">
          <label>Citerne gaz en location</label>

          <app-form-radio-ynn [model]="ngBienDossier.bien.option.citerneGazLocation"
                              [options]="{genre: 'female', name: FIELDS.citerneGazLocation}"
                              (selected)="ngBienDossier.bien.option.citerneGazLocation = $event"/>
        </div>
      }

      <!-- Citerne gaz en pleine propriété -->
      @if (showFieldForBien(FIELDS.citerneGazPleinePropriete)) {
        <div class="form-group col-xs-12 col-sm-6 col-md-3">
          <label>Citerne gaz en pleine propriété</label>

          <app-form-radio-ynn [model]="ngBienDossier.bien.option.citerneGazPleinePropriete"
                              (selected)="ngBienDossier.bien.option.citerneGazPleinePropriete = $event"
                              [options]="{genre: 'female', name: FIELDS.citerneGazPleinePropriete}"/>
        </div>
      }

      <!-- Cuve à fuel aérienne -->
      @if (showFieldForBien(FIELDS.cuveFuelAerienne)) {
        <div class="form-group col-xs-12 col-sm-6 col-md-3">
          <label>Cuve à fuel aérienne</label>

          <app-form-radio-ynn [model]="ngBienDossier.bien.option.cuveFuelAerienne"
                              (selected)="ngBienDossier.bien.option.cuveFuelAerienne = $event"
                              [options]="{genre: 'female', name: FIELDS.cuveFuelAerienne}"/>
        </div>
      }

      <!-- Cuve à fuel enterrée -->
      @if (showFieldForBien(FIELDS.cuveFuelEnterree)) {
        <div class="form-group col-xs-12 col-sm-6 col-md-3">
          <label>Cuve à fuel enterrée</label>

          <app-form-radio-ynn [model]="ngBienDossier.bien.option.cuveFuelEnterree"
                              (selected)="ngBienDossier.bien.option.cuveFuelEnterree = $event"
                              [options]="{genre: 'female', name: FIELDS.cuveFuelEnterree}"/>
        </div>
      }

      <!-- Cuve inactive dégazée -->
      @if (showFieldForBien(FIELDS.cuveInactiveDegazee)) {
        <div class="form-group col-xs-12 col-sm-6 col-md-3">
          <label>Cuve inactive dégazée</label>

          <app-form-radio-ynn [model]="ngBienDossier.bien.option.cuveInactiveDegazee"
                              (selected)="ngBienDossier.bien.option.cuveInactiveDegazee = $event"
                              [options]="{genre: 'female', name: FIELDS.cuveInactiveDegazee}"/>
        </div>
      }

      <!-- Cuve inactive neutralisée -->
      @if (showFieldForBien(FIELDS.cuveInactiveNeutralizee)) {
        <div class="form-group col-xs-12 col-sm-6 col-md-3">
          <label>Cuve inactive neutralisée</label>

          <app-form-radio-ynn [model]="ngBienDossier.bien.option.cuveInactiveNeutralizee"
                              (selected)="ngBienDossier.bien.option.cuveInactiveNeutralizee = $event"
                              [options]="{genre: 'female', name: FIELDS.cuveInactiveNeutralizee}"/>
        </div>
      }
    </div>

    <textarea class="form-control" name="{{FIELDS.commentsAgencementExterieur}}"
              placeholder="Saisir les informations complémentaires à l'agencement extérieur"
              [(ngModel)]="ngBienDossier.bien.option.commentsAgencementExterieur" [minHeight]="100"></textarea>
  </fieldset>

  @if (showPanelForBien('batiment')) {
    <fieldset>
      <legend>Bâtiment</legend>

      <div class="row">
        <!-- Année de construction -->
        @if (showFieldForBien(FIELDS.anneeConstruction)) {
          <div class="col-xs-12 col-sm-6 col-md-3">
            <div class="form-group" [appBienEditConstraints]="anneeConstruction">
              <label for="bien-annee-construction">Année de construction</label>

              <input type="text" id="bien-annee-construction" class="form-control text-right" pattern="[1-9][0-9]{1,3}"
                     name="{{FIELDS.anneeConstruction}}" #anneeConstruction="ngModel"
                     [(ngModel)]="ngBienDossier.bien.option.anneeConstruction" (input)="onChangeAnneeConstruction()">
            </div>
          </div>
        }

        <!-- Époque de construction -->
        @if (showFieldForBien(FIELDS.epoqueConstruction)) {
          <div class="col-xs-12 col-sm-6 col-md-3">
            <div class="form-group" [appBienEditConstraints]="epoqueConstruction.formInput">
              <label>Époque de construction</label>

              <app-dictionary-select-items [model]="bienEpoqueConstruction" #epoqueConstruction="dictionarySelect"
                                           [options]="{name: 'epoqueConstruction'}"
                                           [name]="DICTIONARY_NAME_CONSTRUCTION_EPOQUES"
                                           (selected)="onSelectEpoqueConstruction($event)"/>
            </div>
          </div>
        }

        <!-- État général -->
        @if (showFieldForBien(FIELDS.etat)) {
          <div class="col-xs-12 col-sm-6 col-md-3">
            <div class="form-group" [appBienEditConstraints]="etat.formInput">
              <label>État général</label>

              <app-dictionary-select-items [model]="bienEtat" [name]="DICTIONARY_NAME_ETATS" #etat="dictionarySelect"
                                           [options]="{name: 'etat'}" (selected)="onSelectEtat($event)"/>
            </div>
          </div>
        }

        <!-- Nombre étages du bâtiment -->
        @if (showFieldForBien(FIELDS.nombreEtagesBatiment)) {
          <div class="col-xs-12 col-sm-6 col-md-3">
            <div class="form-group" [appBienEditConstraints]="nombreEtagesBatiment">
              <label for="bien-nombre-etage-batiment">Nombre d'étages du bâtiment</label>

              <input type="text" id="bien-nombre-etage-batiment" class="form-control text-right" pattern="[0-9]+"
                     min="0" name="nombreEtagesBatiment" #nombreEtagesBatiment="ngModel"
                     [(ngModel)]="ngBienDossier.bien.option.nombreEtagesBatiment">
            </div>
          </div>
        }
      </div>

      <div class="row">
        <!-- Aucun chauffage -->
        @if (showFieldForBien(FIELDS.aucunChauffage)) {
          <div class="form-group col-xs-12 col-sm-6 col-md-3">
            <div class="checkbox clip-check check-primary checkbox-inline">
              <input type="checkbox" id="bien-aucun-chauffage" name="{{FIELDS.aucunChauffage}}"
                     [(ngModel)]="ngBienDossier.bien.option.aucunChauffage">

              <label for="bien-aucun-chauffage">Aucun chauffage</label>
            </div>
          </div>
        }
        @if (!ngBienDossier.bien.option.aucunChauffage) {
          <div>
            <!-- Chauffage collectif ? -->
            @if (showFieldForBien(FIELDS.chauffageCollectif)) {
              <div class="form-group col-xs-12 col-sm-6 col-md-3">
                <div class="checkbox clip-check check-primary checkbox-inline">
                  <input type="checkbox" id="bien-chauffage-collectif" name="chauffageCollectif"
                         [(ngModel)]="ngBienDossier.bien.option.chauffageCollectif">

                  <label for="bien-chauffage-collectif">Chauffage collectif</label>
                </div>
              </div>
            }

            <!-- Chauffages -->
            @if (showFieldForBien(FIELDS.chauffages)) {
              <div class="col-xs-12 col-sm-6 col-md-3">
                <div class="form-group" [appBienEditConstraints]="chauffages.formInput">
                  <label>Nature(s) chauffage</label>

                  <app-dictionary-select-items [model]="bienChauffages" [name]="DICTIONARY_NAME_CHAUFFAGES"
                                               [options]="{multiple: true, name: FIELDS.chauffages}"
                                               #chauffages="dictionarySelect" (selected)="onSelectChauffages($event)"/>
                </div>
              </div>
            }
          </div>
        }
      </div>

      <div class="row">
        <!-- Matériaux de construction -->
        @if (showFieldForBien(FIELDS.materiauxConstruction)) {
          <div class="col-xs-12 col-sm-6 col-md-3">
            <div class="form-group" [appBienEditConstraints]="materiauxConstruction.formInput">
              <label>Matériaux de construction</label>

              <app-dictionary-select-items [model]="bienMateriauxConstruction" #materiauxConstruction="dictionarySelect"
                                           [name]="DICTIONARY_NAME_CONSTRUCTION_MATERIAUX"
                                           (selected)="onSelectMateriauxConstruction($event)"
                                           [options]="{multiple: true, name: 'materiauxConstruction'}"/>
            </div>
          </div>
        }

        <!-- Charpente -->
        @if (showFieldForBien(FIELDS.charpentes)) {
          <div class="form-group col-xs-12 col-sm-6 col-md-3">
            <label>Charpente</label>

            <app-dictionary-select-items [model]="bienCharpentes" [options]="{multiple: true, name: FIELDS.charpentes}"
                                         [name]="DICTIONARY_NAME_CHARPENTES" (selected)="onSelectCharpentes($event)"/>
          </div>
        }

        <!-- Matériaux de toiture -->
        @if (showFieldForBien(FIELDS.materiauxCouverture)) {
          <div class="col-xs-12 col-sm-6 col-md-3">
            <div class="form-group" [appBienEditConstraints]="materiauxCouverture.formInput">
              <label>Matériaux de toiture</label>

              <app-dictionary-select-items [model]="bienMateriauxCouverture" #materiauxCouverture="dictionarySelect"
                                           (selected)="onSelectMateriauxCouverture($event)"
                                           [name]="DICTIONARY_NAME_COUVERTURE_MATERIAUX"
                                           [options]="{multiple: true, name: 'materiauxCouverture'}"/>
            </div>
          </div>
        }

        <!-- Isolation -->
        @if (showFieldForBien(FIELDS.isolations)) {
          <div class="form-group col-xs-12 col-sm-6 col-md-3">
            <label>Isolation</label>

            <app-dictionary-select-items [model]="bienIsolations" [options]="{multiple: true, name: 'isolations'}"
                                         [name]="DICTIONARY_NAME_ISOLATIONS" (selected)="onSelectIsolations($event)"/>
          </div>
        }
      </div>

      <div class="row">
        <!-- Huisserie -->
        @if (showFieldForBien(FIELDS.huisseries)) {
          <div class="col-xs-12 col-sm-6 col-md-3">
            <div class="form-group" [appBienEditConstraints]="huisseries.formInput">
              <label>Huisseries</label>

              <app-dictionary-select-items #huisseries="dictionarySelect" (selected)="onSelectHuisseries($event)"
                                           [options]="{multiple: true, name: 'huisseries'}"
                                           [model]="bienHuisseries" [name]="DICTIONARY_NAME_HUISSERIES"/>
            </div>
          </div>
        }

        <!-- Volets -->
        @if (showFieldForBien(FIELDS.volets)) {
          <div class="form-group col-xs-12 col-sm-6 col-md-3">
            <label>Volets</label>

            <app-dictionary-select-items [model]="bienVolets" [options]="{multiple: true, name: 'volets'}"
                                         [name]="DICTIONARY_NAME_VOLETS" (selected)="onSelectVolets($event)"/>
          </div>
        }
      </div>

      @if (showFieldForBien(FIELDS.commentsBatiment)) {
        <textarea class="form-control" name="{{FIELDS.commentsBatiment}}"
                  placeholder="Saisir les informations complémentaires au(x) bâtiment(s)"
                  [(ngModel)]="ngBienDossier.bien.option.commentsBatiment" [minHeight]="100"></textarea>
      }
    </fieldset>
  }

  @if (showPanelForBien('viabilisation')) {
    <fieldset>
      <legend>Viabilisation</legend>

      <div class="row">
        <!-- Viabilisable -->
        @if (showFieldForBien(FIELDS.viabilisable)) {
          <div class="form-group col-xs-12 col-sm-6 col-md-3">
            <label>Viabilisable</label>

            <app-form-radio-ynn [model]="ngBienDossier.bien.option.viabilisable" [options]="{name: 'viabilisable'}"
                                (selected)="ngBienDossier.bien.option.viabilisable = $event"/>
          </div>
        }

        <!-- Eau -->
        @if (showFieldForBien(FIELDS.viabilisationEau)) {
          <div class="col-xs-12 col-sm-6 col-md-3">
            <div class="form-group" [appBienEditConstraints]="viabilisationEau.formInput">
              <label>Raccordé en Eau</label>

              <app-form-radio-ynn [model]="ngBienDossier.bien.option.viabilisationEau" #viabilisationEau="formRadioYnn"
                                  (selected)="ngBienDossier.bien.option.viabilisationEau = $event"
                                  [options]="{name: 'viabilisationEau'}"/>
            </div>
          </div>
        }

        <!-- Électricité -->
        @if (showFieldForBien(FIELDS.viabilisationElectricite)) {
          <div class="col-xs-12 col-sm-6 col-md-3">
            <div class="form-group" [appBienEditConstraints]="viabilisationElectricite.formInput">
              <label>Raccordé à l'Électricité</label>

              <app-form-radio-ynn [model]="ngBienDossier.bien.option.viabilisationElectricite"
                                  #viabilisationElectricite="formRadioYnn"
                                  [options]="{name: 'viabilisationElectricite'}"
                                  (selected)="ngBienDossier.bien.option.viabilisationElectricite = $event"/>
            </div>
          </div>
        }

        <!-- Télécom -->
        @if (showFieldForBien(FIELDS.viabilisationTelecom)) {
          <div class="col-xs-12 col-sm-6 col-md-3">
            <div class="form-group" [appBienEditConstraints]="viabilisationTelecom.formInput">
              <label>Raccordé en Télécom</label>

              <app-form-radio-ynn [model]="ngBienDossier.bien.option.viabilisationTelecom"
                                  #viabilisationTelecom="formRadioYnn" [options]="{name: 'viabilisationTelecom'}"
                                  (selected)="ngBienDossier.bien.option.viabilisationTelecom = $event"/>
            </div>
          </div>
        }

        <!-- Fibre optique -->
        @if (showFieldForBien(FIELDS.viabilisationFibre)) {
          <div class="col-xs-12 col-sm-6 col-md-3">
            <div class="form-group" [appBienEditConstraints]="viabilisationFibre.formInput">
              <label>Raccordé à la Fibre optique</label>

              <app-form-radio-ynn [model]="ngBienDossier.bien.option.viabilisationFibre"
                                  #viabilisationFibre="formRadioYnn" [options]="{name: 'viabilisationFibre'}"
                                  (selected)="ngBienDossier.bien.option.viabilisationFibre = $event"/>
            </div>
          </div>
        }

        <!-- Voirie -->
        @if (showFieldForBien(FIELDS.viabilisationVoirie)) {
          <div class="col-xs-12 col-sm-6 col-md-3">
            <div class="form-group" [appBienEditConstraints]="viabilisationVoirie.formInput">
              <label>Raccordé à la Voirie</label>

              <app-form-radio-ynn [model]="ngBienDossier.bien.option.viabilisationVoirie"
                                  #viabilisationVoirie="formRadioYnn" [options]="{name: 'viabilisationVoirie'}"
                                  (selected)="ngBienDossier.bien.option.viabilisationVoirie = $event"/>
            </div>
          </div>
        }

        <!-- Gaz -->
        @if (showFieldForBien(FIELDS.viabilisationGaz)) {
          <div class="col-xs-12 col-sm-6 col-md-3">
            <div class="form-group" [appBienEditConstraints]="viabilisationGaz.formInput">
              <label>Raccordé au Gaz</label>

              <app-form-radio-ynn [model]="ngBienDossier.bien.option.viabilisationGaz"
                                  #viabilisationGaz="formRadioYnn" [options]="{name: 'viabilisationGaz'}"
                                  (selected)="ngBienDossier.bien.option.viabilisationGaz = $event"/>
            </div>
          </div>
        }

        <!-- Aucun assainissement -->
        @if (showFieldForBien(FIELDS.aucunAssainissement)) {
          <div class="form-group col-xs-12 col-sm-6 col-md-3">
            <div class="checkbox clip-check check-primary margin-top-20">
              <input type="checkbox" id="bien-aucun-assainissement" name="{{FIELDS.aucunAssainissement}}"
                     [(ngModel)]="ngBienDossier.bien.option.aucunAssainissement">

              <label for="bien-aucun-assainissement">Aucun assainissement</label>
            </div>
          </div>
        }
      </div>

      @if (!ngBienDossier.bien.option.aucunAssainissement) {
        <div class="row">
          <!-- Assainissement non conforme -->
          @if (showFieldForBien(FIELDS.assainissementNonConforme)) {
            <div class="form-group col-xs-12 col-sm-6 col-md-3">
              <div class="checkbox clip-check check-primary checkbox-inline">
                <input type="checkbox" id="bien-assainissement-non-conforme" name="{{FIELDS.assainissementNonConforme}}"
                       [(ngModel)]="ngBienDossier.bien.option.assainissementNonConforme">

                <label for="bien-assainissement-non-conforme">Assainissement non conforme</label>
              </div>
            </div>
          }

          <!-- Assainissement -->
          @if (showFieldForBien(FIELDS.viabilisationAssainissement)) {
            <div class="col-xs-12 col-sm-6 col-md-3">
              <div class="form-group" [appBienEditConstraints]="viabilisationAssainissement.formInput">
                <label>Assainissement</label>

                <app-bien-edit-viabilisation-assainissement
                  [model]="ngBienDossier.bien.option.viabilisationAssainissement"
                  #viabilisationAssainissement="formViabilisationAssainissement"
                  (selected)="ngBienDossier.bien.option.viabilisationAssainissement = $event"/>
              </div>
            </div>
          }

          <!-- Assainissement raccordé  -->
          @if (ngBienDossier.bien.option.viabilisationAssainissement === 'collectif' && showFieldForBien(FIELDS.viabilisationAssainissementCollectifRaccorde)) {
            <div class="col-xs-12 col-sm-6 col-md-3">
              <div class="form-group" [appBienEditConstraints]="viabilisationAssainissementCollectifRaccorde.formInput">
                <label>Assainissement raccordé</label>

                <app-form-radio-ynn [model]="ngBienDossier.bien.option.viabilisationAssainissementCollectifRaccorde"
                                    #viabilisationAssainissementCollectifRaccorde="formRadioYnn"
                                    [options]="{name: 'viabilisationAssainissementCollectifRaccorde'}"
                                    (selected)="ngBienDossier.bien.option.viabilisationAssainissementCollectifRaccorde = $event"/>
              </div>
            </div>
          }

          <!-- Assainissements -->
          @if (showFieldForBien(FIELDS.assainissements)) {
            <div class="col-xs-12 col-sm-6 col-md-3">
              <div class="form-group" [appBienEditConstraints]="assainissements.formInput">
                <label>Assainissement(s)</label>

                <app-dictionary-select-items #assainissements="dictionarySelect" [name]="DICTIONARY_NAME_ASSAISSEMENTS"
                                             [options]="{multiple: true, name: FIELDS.assainissements}"
                                             [model]="bienAssainissements"
                                             (selected)="onSelectAssainissements($event)"/>
              </div>
            </div>
          }
        </div>
      }

      @if (showFieldForBien(FIELDS.viabilisationComments)) {
        <textarea class="form-control" name="viabilisationComments"
                  placeholder="Saisir les informations complémentaires à la viabilisation"
                  [(ngModel)]="ngBienDossier.bien.option.viabilisationComments" [minHeight]="100"></textarea>
      }
    </fieldset>
  }

  <fieldset>
    <legend>Terrain</legend>

    <div class="row">
      <!-- Surface cadastrale -->
      @if (showFieldForBien(FIELDS.surfaceCadastrale)) {
        <div class="col-xs-12 col-sm-4 col-lg-2 surface-cadastrale">
          <div class="form-group" [appBienEditConstraints]="surfaceCadastrale">
            <label for="bien-surface-cadastrale">
              Surface cadastrale

              <button type="button" title="Calculer la surface cadastrale grâce aux références cadastrales du bien"
                      (click)="ngBienDossier.bien.operateSurfaceCadastraleFromRefs(true)"
                      class="btn btn-default btn-null surface-cadastrale-operate">
                <fa-icon [icon]="'sync-alt'"/>
              </button>
            </label>

            <div class="input-group input-group-number input-group-area">
              <input type="text" id="bien-surface-cadastrale" class="form-control text-right" pattern="^(?:\d*.)?\d+$"
                     [(ngModel)]="ngBienDossier.bien.option.surfaceCadastrale" name="surfaceCadastrale"
                     #surfaceCadastrale="ngModel">

              <span class="input-group-addon">m<sup>2</sup></span>
            </div>
          </div>
        </div>
      }

      <!-- Surface bâtie au sol -->
      @if (showFieldForBien(FIELDS.surfaceBatie)) {
        <div class="col-xs-12 col-sm-4 col-lg-2">
          <div class="form-group" [appFormError]="surfaceBatie">
            <label for="bien-surface-batie">Surface bâtie au sol</label>

            <div class="input-group input-group-number input-group-area">
              <input type="text" id="bien-surface-batie" class="form-control text-right" pattern="^(?:\d*.)?\d+$"
                     [(ngModel)]="ngBienDossier.bien.option.surfaceBatie" name="surfaceBatie" #surfaceBatie="ngModel">

              <span class="input-group-addon">m<sup>2</sup></span>
            </div>
          </div>
        </div>
      }

      <!-- Surface non bâtie au sol -->
      @if (showFieldForBien(FIELDS.surfaceNonBatie)) {
        <div class="col-xs-12 col-sm-4 col-lg-2">
          <div class="form-group" [appBienEditConstraints]="surfaceNonBatie">
            <label for="bien-surface-non-batie">Surface non bâtie</label>

            <div class="input-group input-group-number input-group-area">
              <input type="text" id="bien-surface-non-batie" class="form-control text-right" pattern="^(?:\d*.)?\d+$"
                     [(ngModel)]="ngBienDossier.bien.option.surfaceNonBatie" name="surfaceNonBatie"
                     #surfaceNonBatie="ngModel">

              <span class="input-group-addon">m<sup>2</sup></span>
            </div>
          </div>
        </div>
      }

      <!-- Surface plancher -->
      @if (showFieldForBien(FIELDS.surfacePlancher)) {
        <div class="col-xs-12 col-sm-4 col-lg-2">
          <div class="form-group" [appFormError]="surfacePlancher">
            <label for="bien-surface-plancher">Surface plancher</label>

            <div class="input-group input-group-number input-group-area">
              <input type="text" id="bien-surface-plancher" class="form-control text-right" pattern="^(?:\d*.)?\d+$"
                     [(ngModel)]="ngBienDossier.bien.option.surfacePlancher" name="surfacePlancher"
                     #surfacePlancher="ngModel">

              <span class="input-group-addon">m<sup>2</sup></span>
            </div>
          </div>
        </div>
      }

      <!-- Longueur de la facade -->
      @if (showFieldForBien(FIELDS.longueurFacade)) {
        <div class="col-xs-12 col-sm-4 col-lg-2">
          <div class="form-group" [appBienEditConstraints]="longueurFacade">
            <label for="bien-longueur-facade">Longueur de façade</label>

            <div class="input-group input-group-number">
              <input type="text" id="bien-longueur-facade" class="form-control text-right" pattern="^(?:\d*.)?\d+$"
                     [(ngModel)]="ngBienDossier.bien.option.longueurFacade" name="longueurFacade"
                     #longueurFacade="ngModel">

              <span class="input-group-addon">m</span>
            </div>
          </div>
        </div>
      }
    </div>

    <div class="row">
      <!-- Zone inondable -->
      @if (showFieldForBien(FIELDS.zoneInondable)) {
        <div class="form-group col-xs-12 col-sm-6 col-md-3">
          <label>Zone inondable</label>

          <app-form-radio-ynn [model]="ngBienDossier.bien.option.zoneInondable"
                              (selected)="ngBienDossier.bien.option.zoneInondable = $event"
                              [options]="{genre: 'female', name: 'zoneInondable'}"/>
        </div>
      }

      <!-- Point d'eau (ville) -->
      @if (showFieldForBien(FIELDS.pointEauVille)) {
        <div class="form-group col-xs-12 col-sm-6 col-md-3">
          <label>Robinet, puits, ...</label>

          <app-form-radio-ynn [model]="ngBienDossier.bien.option.pointEauVille" [options]="{name: 'pointEauVille'}"
                              (selected)="ngBienDossier.bien.option.pointEauVille = $event"/>
        </div>
      }

      <!-- Point d'eau (campagne) -->
      @if (showFieldForBien(FIELDS.pointEauCampagne)) {
        <div class="form-group col-xs-12 col-sm-6 col-md-3">
          <label>Mare, étang, lac, ...</label>

          <app-form-radio-ynn [model]="ngBienDossier.bien.option.pointEauCampagne"
                              [options]="{name: 'pointEauCampagne'}"
                              (selected)="ngBienDossier.bien.option.pointEauCampagne = $event"/>
        </div>
      }

      <!-- Panneaux photovoltaïques -->
      @if (showFieldForBien(FIELDS.panneauxPhotovoltaiques)) {
        <div class="form-group col-xs-12 col-sm-6 col-md-3">
          <label>Panneaux photovoltaïques</label>

          <app-form-radio-ynn [model]="ngBienDossier.bien.option.panneauxPhotovoltaiques"
                              (selected)="ngBienDossier.bien.option.panneauxPhotovoltaiques = $event"
                              [options]="{name: 'panneauxPhotovoltaiques'}"/>
        </div>
      }

      <!-- Déclivité -->
      @if (showFieldForBien(FIELDS.declivite)) {
        <div class="col-xs-12 col-sm-6 col-md-3">
          <div class="checkbox clip-check check-primary checkbox-inline">
            <input type="checkbox" id="bien-declivite" name="{{FIELDS.declivite}}"
                   [(ngModel)]="ngBienDossier.bien.option.declivite">

            <label for="bien-declivite">Déclivité</label>
          </div>

          <!-- Degré de déclivité -->
          @if (ngBienDossier.bien.option.declivite && showFieldForBien(FIELDS.degreDeclivite)) {
            <div>
              <div class="form-group" [appFormError]="degreDeclivite">
                <div class="input-group">
                  <input type="text" class="form-control text-right" pattern="[0-9]+" min="0" #degreDeclivite="ngModel"
                         placeholder="Degré de déclivité" name="{{FIELDS.degreDeclivite}}"
                         [(ngModel)]="ngBienDossier.bien.option.degreDeclivite">

                  <span class="input-group-addon">°</span>
                </div>
              </div>
            </div>
          }
        </div>
      }
    </div>

    @if (showFieldForBien(FIELDS.clotures) || showFieldForBien(FIELDS.servitude)) {
      <div class="row">
        <!-- Clotures -->
        @if (showFieldForBien(FIELDS.clotures)) {
          <div class="col-xs-12 col-sm-6 col-md-3">
            <label>Clôture(s)</label>

            <app-dictionary-select-items [options]="{multiple: true, name: FIELDS.clotures}" [model]="bienClotures"
                                         (selected)="onSelectClotures($event)" [name]="DICTIONARY_NAME_CLOTURES"/>
          </div>
        }

        <!-- Servitudes -->
        @if (showFieldForBien(FIELDS.servitude)) {
          <div class="col-xs-12 col-sm-6 col-md-9 form-group">
            <label for="bien-servitude">Servitude(s) connue(s)</label>

            <textarea class="form-control" name="servitude" id="bien-servitude"
                      [(ngModel)]="ngBienDossier.bien.option.servitude" [minHeight]="100"></textarea>
          </div>
        }
      </div>
    }

    @if (showFieldForBien(FIELDS.commentsTerrain)) {
      <textarea class="form-control" name="{{FIELDS.commentsTerrain}}"
                placeholder="Saisir les informations complémentaires au terrain" [minHeight]="100"
                [(ngModel)]="ngBienDossier.bien.option.commentsTerrain"></textarea>
    }
  </fieldset>

  <fieldset class="accessibilite_environnement-edit">
    <legend>Accessibilité / Environnement</legend>

    <div class="row">
      <!-- Commodités -->
      @if (showFieldForBien(FIELDS.commodites)) {
        <div class="col-xs-12 col-sm-7 col-md-5">
          @if (commoditesReady$ | async) {
            @if (ngBienDossier.bien.commodites.length > 0) {
              <table class="table table-collection">
                <thead><tr><th>Type de commodité *</th><th>Distance</th><th></th></tr></thead>

                <tbody>
                  @for (bienCommodite of ngBienDossier.bien.commodites; track bienCommodite; let idx = $index) {
                    <tr class="item"
                        [appCollectionSortable]="[AppLayoutBienEditConstructionCommoditesName, ngBienDossier.bien.commodites, bienCommodite.uuid]">
                      <td class="col-xs-6" [appCollectionItemError]="[bienCommodite, bienCommoditeType.formInput]">
                        <app-dictionary-select-items #bienCommoditeType="dictionarySelect"
                                                     [name]="DICTIONARY_NAME_COMMODITES"
                                                     (selected)="onSelectCommoditeType(bienCommodite, $event)"
                                                     [options]="{name: 'commodite' + bienCommodite.uuid, required: !!bienCommodite.uuid}"
                                                     [ngItem]="bienCommodite.commodite"/>
                      </td>

                      <td class="col-xs-3" [appCollectionItemError]="[bienCommodite, distance]">
                        <span class="num-icon tw:md:!top-[34%] tw:lg:!top-[39%] tw:2xl:!top-1/3">m</span>

                        <input type="text" [name]="'distance' + bienCommodite.uuid" #distance="ngModel"
                               class="form-control number" [(ngModel)]="ngBienDossier.bien.commodites[idx].distance"
                               (blur)="saveCommodite(bienCommodite)">
                      </td>

                      <td class="col-xs-3 other tw:text-center">
                        @if (!!bienCommodite.uuid) {
                          @if (bienCommodite._esk.loading) {
                            <fa-icon [icon]="'spinner'" animation="spin"/>
                          } @else {
                            <button type="button" class="btn btn-o btn-null" (click)="editCommodite(bienCommodite)">
                              <fa-icon [icon]="'edit'"/>
                            </button>

                            <button type="button" title="Supprimer la commodité" class="btn btn-o text-large"
                                    (click)="removeCommodite(bienCommodite)"><fa-icon [icon]="'trash-alt'"/></button>
                          }
                        }
                      </td>
                    </tr>
                  }
                </tbody>
              </table>
            }
          }

          @if (ngBienDossier.bien.commodites.length <= 0) {
            <div class="tw:py-4 tw:text-center">Aucune commodité saisie</div>
          }

          <div class="tw:flex tw:justify-center tw:py-4">
            @if (ngBienDossier.uuid || ngBienDossier.bien.commodites.length === 0) {
              <button type="button" class="tw:btn-secondary-info" (click)="addNewCommodite()">
                <fa-icon [icon]="'plus'"/> <span>Ajouter une commodité</span>
              </button>
            } @else {
              Enregistrer votre bien une première fois afin de pouvoir saisir plus de commodités.
            }
          </div>
        </div>
      }

      <div class="col-xs-12 col-sm-5 col-md-7">
        <div class="row">
          <div class="col-xs-12 col-md-7">
            <!-- Pièce d'eau en RDC -->
            @if (showFieldForBien(FIELDS.pieceEauRdc)) {
              <div class="pull-left">
                <div class="form-group" [appBienEditConstraints]="pieceEauRdc.formInput">
                  <label>Pièce(s) d'eau au RDC</label>

                  <app-form-radio-ynn [model]="ngBienDossier.bien.option.pieceEauRdc"
                                      [options]="{genre: 'female', name: 'pieceEauRdc'}" #pieceEauRdc="formRadioYnn"
                                      (selected)="ngBienDossier.bien.option.pieceEauRdc = $event"/>
                </div>
              </div>
            }

            <!-- Chambre au RDC -->
            @if (showFieldForBien(FIELDS.chambreRdc)) {
              <div class="pull-left">
                <div class="form-group" [appBienEditConstraints]="chambreRdc.formInput">
                  <label>Chambre(s) au RDC</label>

                  <app-form-radio-ynn [model]="ngBienDossier.bien.option.chambreRdc"
                                      [options]="{genre: 'female', name: FIELDS.chambreRdc}" #chambreRdc="formRadioYnn"
                                      (selected)="ngBienDossier.bien.option.chambreRdc = $event"/>
                </div>
              </div>
            }

            <!-- Accès PMR -->
            @if (showFieldForBien(FIELDS.accesHandicape)) {
              <div class="pull-left form-group" title="Accès pour les Personnes à Mobilité Réduite">
                <label>Accès PMR</label>

                <app-form-radio-ynn [model]="ngBienDossier.bien.option.accesHandicape"
                                    [options]="{name: FIELDS.accesHandicape}"
                                    (selected)="ngBienDossier.bien.option.accesHandicape = $event"/>
              </div>
            }
          </div>

          @if (showFieldForBien(FIELDS.plainPied)) {
            <div class="col-xs-12 col-sm-11 col-md-5 margin-bottom-20">
              <div class="form-group width-200" [appBienEditConstraints]="plainPied.formInput">
                <label>Plain-pied</label>

                <app-dictionary-select-items [model]="bienPlainPied" [name]="DICTIONARY_NAME_PLAIN_PIEDS"
                                             #plainPied="dictionarySelect"
                                             [options]="{name: 'plainPied'}" (selected)="onSelectPlainPied($event)"/>
              </div>
            </div>
          }
        </div>
      </div>
    </div>

    @if (showFieldForBien(FIELDS.commentsAccessibiliteEtEnvironnement)) {
      <textarea class="form-control" name="{{FIELDS.commentsAccessibiliteEtEnvironnement}}" [minHeight]="100"
                placeholder="Saisir les informations complémentaires à l'accessibilité et à l'environnement"
                [(ngModel)]="ngBienDossier.bien.option.commentsAccessibiliteEtEnvironnement"></textarea>
    }
  </fieldset>

  <fieldset>
    <legend>Autres</legend>

    <div class="row">
      <!-- Exposition -->
      @if (showFieldForBien(FIELDS.exposition)) {
        <div class="form-group col-xs-12 col-sm-6 col-md-3">
          <label>Exposition</label>

          <app-dictionary-select-items [options]="{name: 'exposition'}" (selected)="onSelectExposition($event)"
                                       [model]="bienExposition" [name]="DICTIONARY_NAME_EXPOSITIONS"/>
        </div>
      }

      <!-- Mitoyenneté -->
      @if (showFieldForBien(FIELDS.mitoyennete)) {
        <div class="col-xs-12 col-sm-6 col-md-3">
          <div class="form-group" [appBienEditConstraints]="mitoyennete.formInput">
            <label>Mitoyenneté</label>

            <app-dictionary-select-items [model]="bienMitoyennete" [name]="DICTIONARY_NAME_MITOYENNETES"
                                         #mitoyennete="dictionarySelect" [options]="{name: 'mitoyennete'}"
                                         (selected)="onSelectMitoyennete($event)"/>
          </div>
        </div>
      }
    </div>

    <div class="row margin-bottom-20">
      <!-- Vues -->
      @if (showFieldForBien(FIELDS.vues)) {
        <div class="form-group col-xs-12 col-sm-6 col-md-3">
          <label>Vues</label>

          <app-dictionary-select-items [model]="bienVues" [name]="DICTIONARY_NAME_VUES"
                                       (selected)="onSelectVues($event)" [options]="{multiple: true, name: 'vues'}"/>
        </div>
      }

      <!-- Commentaires de la vue -->
      <div class="col-xs-12 col-sm-6 col-md-9">
        <textarea class="form-control" name="{{FIELDS.commentsVue}}" [minHeight]="100"
                  placeholder="Ex. : Vue sur la Loire" [(ngModel)]="ngBienDossier.bien.option.commentsVue"></textarea>
      </div>
    </div>

    <!-- Pièce d'eau en RDC -->
    @if (showFieldForBien(FIELDS.exceptionnel)) {
      <div class="row">
        <div class="form-group col-xs-12 col-sm-6 col-md-3">
          <label>Bien d'exception</label>

          <app-form-radio-ynn [model]="ngBienDossier.bien.option.exceptionnel"
                              [options]="{genre: 'male', name: 'exceptionnel'}" #exceptionnel="formRadioYnn"
                              (selected)="ngBienDossier.bien.option.exceptionnel = $event"/>
        </div>
      </div>
    }

    <!-- Commentaires sur la construction -->
    @if (showFieldForBien(FIELDS.commentsConstruction)) {
      <textarea class="form-control" name="{{FIELDS.commentsConstruction}}"
                [minHeight]="100" placeholder="Saisir d'autres informations complémentaires"
                [(ngModel)]="ngBienDossier.bien.option.commentsConstruction"></textarea>
    }
  </fieldset>
</div>
