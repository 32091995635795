@if (reference$ | async; as reference) {
  <div class="tw:bg-gray-50 tw:px-4 tw:py-6 tw:sm:px-6">
    <h2 class="tw:mr-8 tw:text-base tw:font-semibold tw:text-gray-900">
      Vendu le {{ reference.dateVente | date:'longDate' }}
    </h2>
  </div>

  <div class="tw:flex tw:flex-col tw:gap-8 tw:p-8 tw:divide-y tw:divide-gray-200 tw:text-sm">
    <div class="tw:flex tw:flex-col tw:gap-4 tw:text-base tw:text-gray-700">
      <div>
        <span class="tw:font-medium tw:text-gray-900">
          <app-dictionary-item-convivial-name [dictionaryName]="DICTIONARY_NAME_NATURES" [id]="reference.natureId"/>
        </span>
        à
        <span class="tw:font-medium tw:text-gray-900">
          <app-commune-convivial-name [linkCommune]="reference.linkCommune"/>
        </span>
      </div>

      <div>{{ reference.adresse }}</div>
    </div>

    @if (reference.epoqueId || reference.nombrePieces || reference.nombreChambres || reference.nombreEtages) {
      <div class="tw:flex tw:pt-8 tw:text-gray-700 tw:space-x-4 tw:sm:space-x-8">
        @if (reference.epoqueId) {
          <div>
            Construction
            <app-dictionary-item-convivial-name [dictionaryName]="DICTIONARY_NAME_CONSTRUCTION_EPOQUES"
                                                [id]="reference.epoqueId" class="tw:lowercase"/>
          </div>
        }

        @if (reference.nombrePieces) {
          <div [ngPlural]="reference.nombrePieces">
            {{ reference.nombrePieces }}
            <ng-template ngPluralCase="=1">Pièce</ng-template>
            <ng-template ngPluralCase="other">Pièces</ng-template>
          </div>
        }

        @if (reference.nombreChambres) {
          <div [ngPlural]="reference.nombreChambres">
            {{ reference.nombreChambres }}
            <ng-template ngPluralCase="=1">Chambre</ng-template>
            <ng-template ngPluralCase="other">Chambres</ng-template>
          </div>
        }

        @if (reference.nombreEtages) {
          <div [ngPlural]="reference.nombreEtages">
            {{ reference.nombreEtages }}
            <ng-template ngPluralCase="=1">Étage</ng-template>
            <ng-template ngPluralCase="other">Étages</ng-template>
          </div>
        }
      </div>
    }

    <div class="tw:flex tw:flex-col tw:gap-4 tw:pt-8">
      @if (reference.loyer) {
        <div class="tw:sm:grid tw:sm:grid-cols-3 tw:sm:gap-4">
          <div>Loyer</div>

          <div class="tw:sm:col-span-2 tw:mt-1 tw:sm:mt-0 tw:text-base tw:text-gray-700">
            {{ reference.loyer | textCurrency }}
          </div>
        </div>
      }

      @if (reference.distance > 0) {
        <div class="tw:sm:grid tw:sm:grid-cols-3 tw:sm:gap-4">
          <div>Distance</div>

          <div class="tw:sm:col-span-2 tw:mt-1 tw:sm:mt-0">
            <div class="tw:text-base tw:text-gray-700"> {{ reference.distance | textCurrency:'m' }}</div>

            <div>par rapport à l'adresse de votre bien</div>
          </div>
        </div>
      }

      @if (reference.surfaceCadastrale) {
        <div class="tw:sm:grid tw:sm:grid-cols-3 tw:sm:gap-4">
          <div>Surface cadastrale</div>

          <div class="tw:sm:col-span-2 tw:mt-1 tw:sm:mt-0 tw:text-base tw:text-gray-700">
            {{ reference.surfaceCadastrale | textCurrency:'m²' }}
          </div>
        </div>
      }

      @if (reference.superficie) {
        <div class="tw:sm:grid tw:sm:grid-cols-3 tw:sm:gap-4">
          <div>{{ superficieLabel }}</div>

          <div class="tw:sm:col-span-2 tw:mt-1 tw:sm:mt-0 tw:text-base tw:text-gray-700">
            {{ reference.superficie | textCurrency:'m²' }}
          </div>
        </div>
      }

      @if (reference.prixVente) {
        <div class="tw:sm:grid tw:sm:grid-cols-3 tw:sm:gap-4">
          <div>Prix de vente</div>

          <div class="tw:sm:col-span-2 tw:mt-1 tw:sm:mt-0">
            <div class="tw:text-base tw:text-gray-700">{{ reference.prixVente | textCurrency }}</div>

            <div>soit {{ reference.prixM2 | textCurrency }} du m²</div>
          </div>
        </div>
      }
    </div>
  </div>
} @else {
  <div class="tw:px-4 tw:py-6 tw:sm:px-6">
    <div class="tw:mb-10 tw:pr-12"><app-loader [classHeight]="'tw:h-6'"/></div>

    <app-loader [classHeight]="'tw:h-40'" class="tw:block"/>
  </div>
}
