import {inject, Injectable} from '@angular/core';
import {EstimationReferenceFactory} from '@models/estimations/estimation/references/reference/estimation-reference.factory';
import {IEstimationReferenceApi} from '@models/estimations/estimation/references/reference/estimation-reference.interfaces';
import EstimationReference from '@models/estimations/estimation/references/reference/estimation-reference.model';

@Injectable({providedIn: 'root'})
export class EstimationReferencesFactory {
    private _estimationReferenceFactory = inject(EstimationReferenceFactory);

    create(estimationReferenceApis: IEstimationReferenceApi[] = []): EstimationReference[] {
        return (estimationReferenceApis || []).filter(estimationReferenceApi => estimationReferenceApi)
            .map(estimationReferenceApi => this._estimationReferenceFactory.create(estimationReferenceApi));
    }
}
