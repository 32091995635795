<div class="tw:sm:flex tw:sm:items-start">
  <app-modal-status-icon [status]="WARNING_STATUS"/>

  <div class="tw:mt-3 tw:text-left tw:sm:mt-0 tw:sm:mx-4">
    <h3 class="tw:text-lg tw:text-center tw:leading-6 tw:font-medium tw:text-gray-900 tw:sm:text-left">
      Restauration d'un bien en {{dossierBien.dossierType}}
    </h3>

    <div class="tw:mt-2">
      <p class="tw:text-sm tw:text-gray-600">
        Voulez-vous remettre le bien "{{dossierBien.reference}}" en négociation ?
      </p>

      <div class="tw:text-left">
        Un nouveau bien en vente sera créé avec les mêmes informations hormis :
        <ul class="tw:ml-4">
          <li>- les références</li>
          <li>- les informations du mandat</li>
          <li>- la diffusion</li>
          <li>- les documents</li>
          <li>- le suivi</li>
        </ul>
      </div>
    </div>

    <div class="tw:mt-2">
      <div class="tw:flex tw:items-start">
        <div class="tw:flex tw:items-center tw:h-5 tw:mr-3">
          <input class="tw:input-checkbox" id="checkComments" name="checkComments" type="checkbox"
                 [(ngModel)]="keepProprietaires"/>
        </div>

        <label for="checkComments" class="tw:text-sm tw:text-gray-600">Conserver les propriétaires</label>
      </div>
    </div>
  </div>
</div>

<div class="tw:sm:flex tw:sm:flex-row-reverse tw:gap-4 tw:mt-5 tw:sm:mt-4">
  <button type="button" class="tw:btn-primary-warning" (click)="response(true)">Restaurer</button>

  <button type="button" class="tw:btn-secondary-default" (click)="response(false)">Annuler</button>
</div>
