export default `
<div ng-switch="$ctrl.loader" class="suivi-report">
  <h2 ng-switch-when="true" class="no-messages">
    <fa-icon [icon]="'spinner'" animation="spin"></fa-icon> {{'global.en_cours.LOAD' | translate}}
  </h2>

  <div ng-switch-default class="report-message">
    <div class="message-actions">
      <ng-switch on="$ctrl.generating">
        <div ng-switch-when="true" class="pull-right">
          <fa-icon [icon]="'spinner'" animation="spin"></fa-icon> Génération du document
        </div>

        <div ng-switch-default class="actions-list">
          <div class="btn-group pull-right">
            <button type="button" class="btn btn-default" ng-click="$ctrl.edit()">Modifier</button>

            <button type="button" class="btn btn-default" ng-click="$ctrl.send()">Envoyer</button>

            <button type="button" class="btn btn-default" ng-click="$ctrl.print()">Imprimer</button>

            <button type="button" class="btn btn-o btn-danger" ng-click="$ctrl.remove()">Supprimer</button>
          </div>
        </div>
      </ng-switch>
    </div>

    <div class="message-header">
      <div class="row">
        <div class="col-sm-6">
          <div class="tw:flex">
            <app-user-cartouche [link]="$ctrl.report._links.creator.href" class="tw:block tw:h-14 tw:mr-4"
                                [options]="{showConvivialName: false, showInitiales: false}"></app-user-cartouche>

            <div class="tw:flex-1">
              <div class="message-time tw:flex tw:flex-wrap">
                <div>
                  Créé le <app-ng2-date [date]="$ctrl.report.date" format="fullDate"></app-ng2-date> à
                  <app-ng2-date [date]="$ctrl.report.date" format="shortTime"></app-ng2-date>
                </div>

                <app-user-cartouche [link]="$ctrl.report._links.creator.href" [options]="{showPhoto: false}"
                                    class="tw:max-w-full tw:w-fit"></app-user-cartouche>
              </div>

              <div class="message-from" ng-if="$ctrl.report.email">
                {{$ctrl.report.from}} &lt;{{$ctrl.report.email}}&gt;
              </div>

              <div ng-if="!!$ctrl.report.participants && $ctrl.report.participants.length > 0">
                <fa-icon [icon]="'users'"></fa-icon>

                <span ng-repeat="reportParticipant in $ctrl.report.participants">
                  {{reportParticipant.person.convivialName}}<span ng-if="!$last">, </span><span ng-if="$last">.</span>
                </span>
              </div>

              <div><fa-icon [icon]="'tag'"></fa-icon> {{$ctrl.report.theme.label}}</div>
            </div>
          </div>
        </div>

        <div class="col-sm-6 text-right padding-15">
          <div class="btn-group">
            <button type="button" class="btn btn-o btn-azure" ng-click="$ctrl.edit('participants')">
              {{ $ctrl.report.participants.length }}
              Participant{{ $ctrl.report.participants.length > '1' ? 's' : '' }}
            </button>

            <button type="button" class="btn btn-o btn-azure" ng-click="$ctrl.edit('dossiers')">
              {{ $ctrl.report.dossiers.length }}
              Dossier{{ $ctrl.report.dossiers.length > '1' ? 's' : '' }}
            </button>

            <button type="button" class="btn btn-o btn-azure" ng-click="$ctrl.edit('attachments')">
              {{ $ctrl.report.attachments.length }}
              Pièce{{ $ctrl.report.attachments.length > '1' ? 's' : '' }}
              jointe{{ $ctrl.report.attachments.length > '1' ? 's' : '' }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="message-subject text-large" ng-bind-html="$ctrl.report.subject"></div>

    <div class="message-content" ng-bind-html="$ctrl.report.comments"></div>
  </div>
</div>
`;
