export default `
<div ng-if="!box.closed" class="row">
  <!-- Nouveau bien à vendre -->
  <div class="col-xs-6 col-sm-4 col-md-3 col-lg-2 shortcut">
    <div class="panel panel-white no-radius tw:text-center">
      <div class="panel-body">
        <a ng-click="$ctrl.ng2RouterStateForNgService.navigateByUrl('/app/ventes/item/edit/general')">
          <app-fa-shortcut [fa-stack]="{action_icon_name: 'plus', action_icon_transform: 'shrink-36 up-26 right-32', main_icon_name: 'house'}">
          </app-fa-shortcut>

          <h4>Nouveau bien à vendre</h4>
        </a>
      </div>
    </div>
  </div>

  <!-- Biens à vendre -->
  <div class="col-xs-6 col-sm-4 col-md-3 col-lg-2 shortcut">
    <div class="panel panel-white no-radius tw:text-center">
      <div class="panel-body">
        <a ng-click="$ctrl.ng2RouterStateForNgService.navigateByUrl('/app/ventes/portefeuille/current')">
          <app-fa-shortcut [fa-stack]="{main_icon_name: 'house'}"></app-fa-shortcut><h4>Biens à vendre</h4>
        </a>
      </div>
    </div>
  </div>

  <!-- Nouvelle demande -->
  <div class="col-xs-6 col-sm-4 col-md-3 col-lg-2 shortcut">
    <div class="panel panel-white no-radius tw:text-center">
      <div class="panel-body">
        <a ng-click="$ctrl.ng2RouterStateForNgService.navigateByUrl('/app/demandeurs/item/edit/general')">
          <app-fa-shortcut [fa-stack]="{action_icon_name: 'plus', action_icon_transform: 'shrink-36 up-26 right-32', main_icon_name: 'crosshairs'}">
          </app-fa-shortcut>

          <h4>Nouvelle demande</h4>
        </a>
      </div>
    </div>
  </div>

  <!-- Nouveau bon de visite -->
  <div class="col-xs-6 col-sm-4 col-md-3 col-lg-2 shortcut">
    <div class="panel panel-white no-radius tw:text-center">
      <div class="panel-body">
        <a ng-click="$ctrl.ng2RouterStateForNgService.navigateByUrl('/app/bons-visite/' + $ctrl.BonvisiteStatutNEW + '/edit')">
          <app-fa-shortcut [fa-stack]="{action_icon_name: 'plus', action_icon_transform: 'shrink-36 up-26 right-32', main_icon_name: 'file-alt', main_icon_name_prefix: 'far'}">
          </app-fa-shortcut>

          <h4>Bon de visite</h4>
        </a>
      </div>
    </div>
  </div>

  <!-- Nouvel email -->
  <div class="col-xs-6 col-sm-4 col-md-3 col-lg-2 shortcut">
    <div class="panel panel-white no-radius tw:text-center">
      <div class="panel-body">
        <button type="button" ng-click="$ctrl.createEmail()">
          <app-fa-shortcut [fa-stack]="{action_icon_name: 'plus', action_icon_transform: 'shrink-36 up-26 right-32', main_icon_name: 'envelope', main_icon_name_prefix: 'far'}">
          </app-fa-shortcut>

          <h4>Envoyer un E-mail</h4>
        </button>
      </div>
    </div>
  </div>

  <!-- Références archivées -->
  <div class="col-xs-6 col-sm-4 col-md-3 col-lg-2 shortcut">
    <div class="panel panel-white no-radius tw:text-center">
      <div class="panel-body">
        <a ng-click="$ctrl.ng2RouterStateForNgService.navigateByUrl('/app/estimations/references/criteres', {search: $ctrl.search})">
          <app-fa-shortcut [fa-stack]="{main_icon_name: 'search'}"></app-fa-shortcut><h4>Références archivées</h4>
        </a>
      </div>
    </div>
  </div>
</div>
`;
