import {inject, Injectable} from '@angular/core';
import {EtudeDossierFactory} from '@models/etude-dossiers/etude-dossier/etude-dossier.factory';
import {IEtudeDossierApi} from '@models/etude-dossiers/etude-dossier/etude-dossier.interfaces';
import EtudeDossier from '@models/etude-dossiers/etude-dossier/etude-dossier.model';

@Injectable({providedIn: 'root'})
export class EtudeDossiersFactory {
    private _etudeDossierFactory = inject(EtudeDossierFactory);

    create(etudeDossierApis: IEtudeDossierApi[] = []): EtudeDossier[] {
        return (etudeDossierApis || []).filter(etudeDossierApi => etudeDossierApi)
            .map(etudeDossierApi => this._etudeDossierFactory.create(etudeDossierApi));
    }
}
