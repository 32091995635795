import angularJS from '@shared/angularJS/global.ng';
import {IFormController, IModule} from 'angular';
import {NgForm} from '@angular/forms';
import {NgBienFieldsConstraints} from '@legacy/app/biens/biens';
import {NgBienDossier, NgVente} from '@legacy/app/managers/ressources';
import {DossierBienTypesConst} from '@models/dossiers/biens/dossier-biens.constants';

export default function getBienDossierCurrent(module: IModule): void {
    (function (angular) {
        'use strict';

        module.factory('BienDossierCurrent', Factory);

        /**
         * Manage current dossier bien
         *
         * @param BienFieldsConstraints
         */
        Factory.$inject = ['BienFieldsConstraints'];
        function Factory(bienFieldsConstraints: NgBienFieldsConstraints) {
            let dossier: NgBienDossier;
            const factory = {
                set: set,
                get: get,
                hasConstraints: hasConstraints,
                onChangeNature: onChangeNature,
                setFieldsValidity: setFieldsValidity,
                setFormValidity: setFormValidity,
                hasFieldsInvalid: hasFieldsInvalid,
            };

            return factory;

            /**
             * Set dossier
             *
             * @param _dossier_
             */
            function set(_dossier_: NgBienDossier) {
                dossier = _dossier_;
            }

            /**
             * Get dossier
             */
            function get() {
                return dossier;
            }

            /**
             * Has constraints for field
             *
             * @param field
             * @returns {boolean}
             */
            function hasConstraints(field: string) {
                if (dossier._esk.typeDossier !== DossierBienTypesConst.VENTE) return false;

                return bienFieldsConstraints.hasConstraintsForNature(field, dossier.bien.nature.code);
            }

            /**
             * On change nature
             */
            function onChangeNature() {
                if (dossier._esk.typeDossier !== DossierBienTypesConst.VENTE) return;

                bienFieldsConstraints.executeCallbacks();
            }

            /**
             * Set isValid on fields
             */
            function setFieldsValidity(forceSet: boolean) {
                bienFieldsConstraints.setTrueIsValid();
                if (!forceSet && !(dossier as NgVente).mandatPremiereDate) {
                    return;
                }

                angular.forEach(bienFieldsConstraints.get(), function (fieldConstraints, field) {
                    if (!factory.hasConstraints(field)) return;

                    bienFieldsConstraints.setIsValid(field, dossier);
                });
            }

            /**
             * Set form validity
             *
             * @param form
             * @param forceSetFieldsValidity
             */
            function setFormValidity(form: IFormController | NgForm, forceSetFieldsValidity: boolean) {
                if (!angular.isObject(form)) return;

                factory.setFieldsValidity(forceSetFieldsValidity);
                angular.forEach(bienFieldsConstraints.get(), function (fieldConstraints, field) {
                    if (form instanceof NgForm) {
                        form.controls[field]?.setErrors(fieldConstraints.isValid === true ? null : {custom: fieldConstraints.message});
                    } else {
                        if (!angular.isDefined(form[field])) return;

                        form[field].$setValidity('required', fieldConstraints.isValid === true);
                        form[field].message = fieldConstraints.message;
                    }
                });
            }

            /**
             * Has fields invalid
             *
             * @param form
             * @param forceSetFieldsValidity
             * @return boolean
             */
            function hasFieldsInvalid(form: IFormController | NgForm, forceSetFieldsValidity: boolean) {
                if (angular.isObject(form)) {
                    factory.setFormValidity(form, forceSetFieldsValidity);
                } else {
                    factory.setFieldsValidity(forceSetFieldsValidity);
                }

                return bienFieldsConstraints.getFieldsInvalid().length > 0;
            }
        }
    })(angularJS);
}
