<div (click)="openSlideOver()" class="tw:h-full tw:rounded-lg tw:bg-white tw:text-center tw:shadow-sm tw:hover:opacity-75 tw:hover:cursor-pointer">
  <div class="tw:flex tw:flex-col tw:gap-4 tw:p-8">
    <img class="tw:mx-auto tw:rounded-full" [ngSrc]="user.linkPhotoThumbnail" height="128" width="128">

    <div class="tw:text-sm tw:font-medium tw:text-gray-900">{{user.convivialName}}</div>

    <div class="tw:text-xs tw:font-medium">{{user.convivialProfils}}</div>

    <app-site-convivial-name [linkSite]="user.linkSite" class="tw:block tw:text-xs"/>
  </div>
</div>
