<div class="tw:flex tw:flex-col tw:gap-2">
  <h4 class="tw:px-3 tw:lg:px-4">Accessibilité / Environnement</h4>

  <div class="tw:bg-gray-100 tw:rounded-xl tw:flex tw:flex-col tw:gap-4 tw:p-3 tw:lg:p-4">
    <ul class="tw:grid tw:grid-cols-1 tw:sm:grid-cols-2 tw:xl:grid-cols-3 tw:gap-2 tw:text-sm tw:text-gray-700 tw:font-medium">
      @if (isCommoditesVisible) {
        @for (bienCommodite of dossierBien.bien.commodites; track bienCommodite) {
          <li>
            {{bienCommodite.commodite && bienCommodite.commodite.label}}

            @if (bienCommodite.distance > 0) {
              <span class="tw:text-gray-500 tw:text-xs tw:font-normal">à</span>
              {{ bienCommodite.distance | textCurrency:'m' }}
            }

            @if (bienCommodite.comments) {
              <div class="tw:text-gray-500 tw:font-normal">{{ bienCommodite.comments }}</div>
            }
          </li>
        }
      }

      @if (isPieceEauRdcVisible) {
        <li>Pièce(s) d'eau au rez-de-chaussée</li>
      }

      @if (isChambreRdcVisible) {
        <li>Chambre(s) au rez-de-chaussée</li>
      }

      @if (isAccesHandicapeVisible) {
        <li>Accès pour les Personnes à Mobilité Réduite</li>
      }

      @if (isPlainPiedVisible) {
        <li>{{ dossierBien.bien.plainPied.label }}</li>
      }

      @if (isVuesVisible) {
        <li>
          Vue{{ dossierBien.bien.vues.length > 1 ? 's' : '' }}
          @for (vue of dossierBien.bien.vues; track vue; let last = $last) {
            {{ vue.label }}{{ last ? '' : ',' }}
          }

          @if (dossierBien.bien.commentsVue) {
            <div class="tw:text-gray-500 tw:font-normal">{{ dossierBien.bien.commentsVue }}</div>
          }
        </li>
      }
    </ul>

    @if (isCommentsAccessibiliteEtEnvironnementVisible) {
      <div class="tw:text-sm tw:text-gray-700">{{ dossierBien.bien.commentsAccessibiliteEtEnvironnement }}</div>
    }
  </div>
</div>
