@if (hasRole$ | async) {
  <div class="container-fluid container-fullw tw:bg-white current-wallet">
    <div class="noty-container">
      <!-- Liste des procédures -->
      @if (cProcedures$ | async; as cProcedures) {
        <div>
          @if (!cProcedures || cProcedures.total === 0) {
            <h4 class="margin-top-30 tw:text-center">
              Aucune procédure de signature électronique n'a été créée pour le moment.
            </h4>
          }

          @if (cProcedures && cProcedures.total > 0) {
            <div>
              <div class="table-responsive">
                <table class="table table-hover">
                  <tbody>
                    @for (procedure of cProcedures.results; track procedure) {
                      <tr>
                        <td>
                          <h4 class="margin-right-10 margin-bottom-0 margin-left-10 btn-link">
                            @if (procedure.documentId) {
                              <app-document-convivial-name [id]="procedure.documentId"
                                                           (getDocument$)="getDocumentProcedure$($event, procedure)"
                                                           (selectDocument)="onSelectDocument($event, procedure)"
                                                           [options]="{withSelect: true}"/>
                            } @else if (procedure.linkMedia) {
                              <app-media-convivial-name [link]="procedure.linkMedia" [options]="{enabledIcon: false}"/>
                            }
                          </h4>
                        </td>

                        <td>
                          <div class="procedure-status">
                            <h4 class="margin-right-10 margin-bottom-0 text-bold">
                              {{ procedure.labelStatut | uppercase }}
                            </h4>

                            <div class="text-muted">
                              {{ procedure.updatedAt | date:'longDate' }} à
                              {{ procedure.updatedAt | date:'shortTime' }}
                            </div>
                          </div>
                        </td>

                        <td>
                          @if (procedure.launchedAt) {
                            Lancée le {{ procedure.launchedAt | date:'longDate' }} à
                            {{ procedure.launchedAt | date:'shortTime' }}
                          } @else {
                            Jamais lancée
                          }

                          @if (procedure.isDistanciel()) {
                            <span class="tw:bg-blue-100 tw:ml-2 tw:px-2 tw:py-0.5 tw:rounded-full tw:text-blue-800 tw:text-xs tw:font-medium">
                              À distance
                            </span>
                          }
                        </td>

                        <td width="75px" class="text-large">
                          @if (documents[procedure.uuid] && !procedure.isDraft()) {
                            <button type="button" class="btn-link" (click)="onAction(procedure)">
                              {{ procedure.isEditable() ? 'Éditer' : 'Voir' }}
                            </button>
                          }
                        </td>
                      </tr>
                    }
                  </tbody>
                </table>
              </div>
            </div>
          }
        </div>
      } @else {
        <app-loader [classHeight]="'tw:h-40'"/>
      }

      <app-load-more [collection$]="cProcedures$" (loadMore)="getMore()" [loading]="pendingGetMore"/>
    </div>
  </div>
}
