import {inject, Injectable} from '@angular/core';
import {
    CityscanOnsalePropertyFactory
} from '@models/cityscan-onsale-properties/onsale-property/cityscan-onsale-property.factory';
import {
    ICityscanOnsalePropertyApi
} from '@models/cityscan-onsale-properties/onsale-property/cityscan-onsale-property.interfaces';
import CityscanOnsaleProperty from '@models/cityscan-onsale-properties/onsale-property/cityscan-onsale-property.model';

@Injectable({providedIn: 'root'})
export class CityscanOnsalePropertiesFactory {
    private _cityscanOnsalePropertyFactory = inject(CityscanOnsalePropertyFactory);

    create(cityscanOnsalePropertiesApi: ICityscanOnsalePropertyApi[] = []): CityscanOnsaleProperty[] {
        return (cityscanOnsalePropertiesApi || []).filter(cityscanOnsalePropertyApi => cityscanOnsalePropertyApi)
            .map(cityscanOnsalePropertyApi => this._cityscanOnsalePropertyFactory.create(cityscanOnsalePropertyApi));
    }
}
