import {inject, Injectable} from '@angular/core';
import {from, Observable, of, Subject} from 'rxjs';
import {NgInjectorService} from '@shared/angularJS/injector.ng.service';
import {EstimationFactory} from '@models/estimations/estimation/estimation.factory';
import {NgEstimationManager} from '@legacy/app/managers/managers';
import Estimation from '@models/estimations/estimation/estimation.model';
import {ModalService} from '@shared/modal/modal.service';
import {map, switchMap, tap} from 'rxjs/operators';
import DateFormat from '@shared/date/date.format';
import {NgEstimation} from '@legacy/app/managers/ressources';
import {BienService} from '@models/bien/bien.service';

@Injectable({providedIn: 'root'})
export class EstimationService {
    private _bienService = inject(BienService);
    private _estimationFactory = inject(EstimationFactory);
    private _modalService = inject(ModalService);
    private _ngInjectorService = inject(NgInjectorService);
    private _needToBeSavedSource = new Subject<void>();
    private _needToBeSaved$ = this._needToBeSavedSource.asObservable();

    // Supprimer les injections des anciens manager
    private get ngEstimationManager(): NgEstimationManager {
        return this._ngInjectorService.getService('EstimationManager');
    }

    get needToBeSaved$(): Observable<void> {
        return this._needToBeSaved$;
    }

    askToBeSaved(): void {
        this._needToBeSavedSource.next();
    }

    clearRapportWithNgCurrent$(estimation: Estimation): Observable<boolean> {
        return this._modalService.openConfirmation$({
            buttonConfirmationLabel: 'Réinitialiser',
            comments: 'Réinitialiser ' + (estimation.isEvaluation() ? 'le rapport d\'évaluation' : 'l\'avis de valeur') + ' écrasera l\'existant et en créera un nouveau.',
            question: 'Voulez-vous réinitialiser ' + (estimation.isEvaluation() ? 'le rapport d\'évaluation' : 'l\'avis de valeur') + ' de votre bien ?',
            title: 'Réinitialisation ' + (estimation.isEvaluation() ? 'du rapport d\'évaluation' : 'de l\'avis de valeur'),
            status: ModalService.statuts.WARNING,
        }).pipe(switchMap(isAccepted => {
            if (!isAccepted) {
                return of(false);
            }

            estimation.rapportId = undefined!;
            this.ngEstimationManager.current.rapportId = undefined!;

            return from(this.ngEstimationManager.current.save()).pipe(map(_ => true));
        }));
    }

    /**
     * @deprecated, Utiliser getCurrentFromNg$()
     */
    getCurrentFromNg(): Estimation {
        if (!this.ngEstimationManager.current) {
            return undefined!;
        }

        return this._estimationFactory.ngCreate(this.ngEstimationManager.current);
    }

    // @todo Rechercher l'utilisation de this.ngEstimationManager.current et this.ngEstimationManager.current$ et utiliser cette méthode si besoin
    // @todo Faire la même chose pour les autres types de dossier
    getCurrentFromNg$(): Observable<Estimation> {
        return this.ngEstimationManager.current$.pipe(map(ngEstimation => {
            if (!ngEstimation) {
                return undefined!;
            }

            return this._estimationFactory.ngCreate(ngEstimation);
        }));
    }

    remettreRapport$(estimation: Estimation): Observable<boolean> {
        if (!estimation.isBrouillon()) {
            return of(true);
        }

        return this._modalService.openConfirmation$({
            buttonConfirmationLabel: 'Confirmer le changement de statut',
            comments: 'Votre bien en estimation est actuellement en "Ébauche".',
            question: 'Voulez-vous le passer dans le statut "Remis" ?',
            title: 'Remise ' + (estimation.isEvaluation() ? 'du rapport d\'évaluation' : 'de l\'avis de valeur'),
            status: ModalService.statuts.WARNING,
        }).pipe(switchMap(isConfirmed => {
            if (!isConfirmed) {
                return of(false);
            }

            return this._estimationFactory.remettreRapport$(estimation).pipe(
                tap(_ => {
                    this.ngEstimationManager.current.dateRemise = DateFormat.toDate(estimation.dateRemise);
                    this.ngEstimationManager.current.statut = estimation.statut;
                }),
                map(_ => true),
            );
        }));
    }

    save$(estimation: Estimation): Observable<Estimation> {
        const isNew = estimation.isNew();

        return this._estimationFactory.save$(estimation).pipe(switchMap(estimationSaved => {
            if (!isNew) {
                return of(estimationSaved);
            }

            return this._bienService.saveCollectionForNew$(estimation, estimationSaved).pipe(switchMap(() => this._estimationFactory.getByLink$(estimationSaved.linkSelf)));
        }));
    }

    saveAndUpdateCurrent$(estimationToSave: Estimation): Observable<Estimation> {
        return this.save$(estimationToSave).pipe(switchMap(estimation => from(this.ngEstimationManager.getOneById(estimation.id)).pipe(
            tap(ngEstimation => this.ngEstimationManager.emitCurrent(ngEstimation)),
            map(_ => estimation),
        )));
    }

    ngSave$(ngEstimation: NgEstimation): Observable<Estimation> {
        return this.save$(this._estimationFactory.ngCreate(ngEstimation));
    }
}
