export default `
<div class="container-fluid container-fluid-title"><h1>Références archivées</h1></div>

<div ng-switch="!!$ctrl.options.creatingMessage"
     class="estimations-references container-fluid container-fullw tw:bg-white">
  <p ng-switch-when="true" class="tw:text-center text-extra-large">
    <fa-icon [icon]="'spinner'" animation="spin"></fa-icon> {{$ctrl.options.creatingMessage | translate}}
  </p>

  <div ng-switch-default>
    <!-- Actions sur la liste -->
    <div class="row margin-bottom-10 hidden-print">
      <!-- Offset -->
      <div class="col-sm-5 col-md-4 col-lg-5"></div>

      <!-- Modes de vue -->
      <div class="col-sm-2 col-md-3 col-lg-3 tw:text-center">
        <app-dossiers-view-choice ng-show="$ctrl.ventes.totalItems > 0" [route]="$ctrl.filterRoute"
                                  [code]="$ctrl.filterCode" (changed)="$ctrl.changeDisplayMode($event)">
        </app-dossiers-view-choice>
      </div>

      <!-- Actions -->
      <div class="col-sm-5 col-md-5 col-lg-4 text-right">
        <button type="button" class="btn btn-primary"
                ng-click="$ctrl.ng2RouterStateForNgService.navigateByUrl('/app/estimations/references/criteres', {search: $ctrl.search})">
          <fa-icon [icon]="'sliders-h'"></fa-icon> <span class="hidden-xs">Critères de recherche</span>
        </button>
      </div>

      <!-- No results -->
      <h4 ng-if="$ctrl.ventes.totalItems <= 0" class="col-sm-12 margin-top-30 tw:text-center">
        {{"Aucune référence à afficher actuellement." | translate}}
      </h4>
    </div>

    <!-- Results -->
    <div ng-if="$ctrl.ventes.totalItems > 0 && !$ctrl.options.creatingMessage">
      <!-- Liste des ventes -->
      <esk-ventes-list ventes="$ctrl.ventes" options="$ctrl.options"></esk-ventes-list>

      <!-- Pagination -->
      <div class="row">
        <esk-pagination paginator="$ctrl.ventes" loader="$ctrl.options.loader" class="col-sm-12 tw:text-center"
                        on-change="$ctrl.onChangePagination" ng-hide="$ctrl.options.loader.updating"></esk-pagination>
      </div>
    </div>
  </div>
</div>
`;
