@if (cCityscanOnsaleProperties$ | async; as cCityscanOnsaleProperties) {
  <div class="tw:overflow-hidden">
    <table [appCollectionSelection]="{collection: cCityscanOnsaleProperties, listName: options.nameSelection!, options: {filterEnabled: options.filterEnabled!, initSelectAll: options.initSelectAll!, titleDisabled: options.titleDisabled!}}"
      id="referenceReferences" class="tw:table-head-separate-body">
      <thead>
        <tr>
          <th class="tw:hidden tw:md:table-cell tw:w-[5.5rem]"><div>Photo</div></th>

          <th [appCollectionColumnSort]="options.tris![CCITYSCAN_ONSALE_PROPERTIES_TRIS.DATE]" class="tw:w-40 tw:2xl:w-44"
            (changed)="changedTri.emit([CCITYSCAN_ONSALE_PROPERTIES_TRIS.DATE, $event])">
            <div>Publication</div>
          </th>

          <th><div>Adresse</div></th>

          <th class="tw:w-32 tw:2xl:w-36 tw:text-right">
            <div>
              <span class="tw:2xl:hidden">{{superficieLabel.replace('Surface', 'Surf.')}}</span>
              <span class="tw:hidden tw:2xl:inline">{{superficieLabel}}</span>
            </div>
          </th>

          <th class="tw:hidden tw:2xl:table-cell tw:w-40 tw:text-right">
            <div>
              <span class="tw:2xl:hidden">Surf. cadastrale</span>
              <span class="tw:hidden tw:2xl:inline">Surface cadastrale</span>
            </div>
          </th>

          <th class="tw:hidden tw:sm:table-cell tw:w-32 tw:2xl:w-36 tw:text-right"><div>Prix</div></th>

          <th class="tw:hidden tw:lg:table-cell tw:w-28 tw:2xl:w-32 tw:text-right">
            <div>
              <span class="tw:2xl:hidden">Prix m²</span>
              <span class="tw:hidden tw:2xl:inline">Prix au m²</span>
            </div>
          </th>

          <th class="tw:w-16 tw:lg:w-20 tw:print:hidden"><div><div class="tw:h-5"></div></div></th>
        </tr>
      </thead>

      <tbody>
        @for (onsaleProperty of cCityscanOnsaleProperties.results; track onsaleProperty) {
          <tr [ngClass]="isEnabled(onsaleProperty) ? '' : 'tw:opacity-75'">
            <td class="tw:table-head-separate-body-img tw:hidden tw:md:table-cell">
              <div class="tw:h-16 tw:w-16">
                <app-cityscan-onsale-property-photo [cityscanOnsaleProperty]="onsaleProperty"/>
              </div>
            </td>

            <td>{{onsaleProperty.publishedAt | date}}</td>

            <td>
              {{onsaleProperty.adresse}}
              <app-commune-convivial-name [linkCommune]="onsaleProperty.linkCommune" class="tw:block tw:text-gray-400"/>
            </td>

            <td class="tw:text-right">{{onsaleProperty.superficie | textCurrency:'m²'}}</td>

            <td class="tw:hidden tw:2xl:table-cell tw:text-right">
              {{onsaleProperty.surfaceCadastrale | textCurrency:'m²'}}
            </td>

            <td class="tw:hidden tw:sm:table-cell tw:text-right">{{onsaleProperty.prixVente | textCurrency}}</td>

            <td class="tw:hidden tw:lg:table-cell tw:text-right">
              {{onsaleProperty.prixM2 > 0 ? (onsaleProperty.prixM2 | textCurrency) : null}}
            </td>

            <td class="tw:table-head-separate-body-actions">
              <button class="tw:btn-secondary-info tw:bg-transparent tw:shadow-none tw:ring-0 tw:w-10" type="button"
                      #buttonElement (click)="openDropdown(buttonElement, onsaleProperty)">
                <fa-icon [icon]="'ellipsis-v'"/>
              </button>
            </td>
          </tr>
        }
      </tbody>
    </table>
  </div>
} @else {
  <app-loader [classHeight]="'tw:h-40'" class="tw:block"/>
}
