import {inject, Injectable} from '@angular/core';
import {Observable, switchMap} from 'rxjs';
import {ModelApiService} from '@models/model.api.service';
import {IEstimationApi} from '@models/estimations/estimation/estimation.interfaces';
import Estimation from '@models/estimations/estimation/estimation.model';
import {HttpOptions} from '@core/api/api.interfaces';

@Injectable({providedIn: 'root'})
export class EstimationsApiService {
    private _modelApiService = inject(ModelApiService);

    get$(uuid: string): Observable<IEstimationApi> {
        return this._modelApiService.get$(`/estimations/${uuid}`);
    }

    getByLink$(link: string): Observable<IEstimationApi> {
        return this._modelApiService.get$(link);
    }

    remettreRapport$(uuid: string): Observable<void> {
        return this._modelApiService.patch$('/estimations/' +  uuid + '/remettre');
    }

    save$(id: string, estimationApi: IEstimationApi, httpOptions?: HttpOptions): Observable<IEstimationApi> {
        if (id === Estimation.statuts.NEW) {
            return this._modelApiService.post$('/user/estimations', estimationApi, httpOptions);
        }

        return this._modelApiService.put$(`/estimations/${id}`, estimationApi, httpOptions).pipe(switchMap(_ => this.get$(id)));
    }
}
