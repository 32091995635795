@if (cOffreachats$ | async; as cOffreachats) {
  <div>
    @if (cOffreachats.total > 0) {
      <div class="tw:p-4 tw:md:px-0">
        <span class="tw:font-bold">{{ cOffreachats.total }}</span> {{ cOffreachats.total > 1 ? 'offres' : 'offre' }}
        d'achat
      </div>

      <div class="tw:overflow-hidden tw:mb-8">
        <table class="tw:table-head-separate-body">
          <thead>
          <tr>
            <th [class]="options.showDemandeur && options.showVente ? '': 'tw:xl:w-44'" class="tw:w-36">
              <div>Date <span class="tw:hidden tw:lg:inline-block">de l'</span>offre</div>
            </th>

            @if (options.showDemandeur) {
              <th><div>Demandeur</div></th>
            }

            @if (options.showVente) {
              <th><div>Bien en vente</div></th>
            }

            <th [class]="options.showDemandeur && options.showVente ? '' : 'tw:xl:w-44'" class="tw:w-36">
              <div>Montant <span class="tw:hidden tw:lg:inline-block">de l'</span>offre</div>
            </th>

            <th [class]="options.showDemandeur && options.showVente ? '' : 'tw:lg:w-32'" class="tw:w-24">
              <div>Statut</div>
            </th>

            <th class="tw:w-16 tw:lg:w-20 tw:print:hidden">
              <div><div class="tw:h-5"></div></div>
            </th>
          </tr>
          </thead>

          <tbody>
            @for (offreachat of cOffreachats.results; track offreachat) {
              <tr>
                <td>
                  @if (offreachat.dateOffre) {
                    <div class="tw:text-right">{{ offreachat.dateOffre | date:'mediumDate' }}</div>
                  }
                </td>

                @if (options.showDemandeur) {
                  <td>
                    <div>
                      @if (offreachat.linkDemandeur) {
                        <app-demandeur-view [link]="offreachat.linkDemandeur" class="tw:block tw:w-full tw:truncate"/>
                      }
                    </div>
                  </td>
                }

                @if (options.showVente) {
                  <td>
                    <div class="tw:flex tw:print:block tw:items-center tw:h-px tw:print:h-auto">
                      @if (offreachat.linkVente) {
                        <app-vente-view [link]="offreachat.linkVente" class="tw:block tw:w-full tw:truncate"/>
                      }
                    </div>
                  </td>
                }

                <td>
                  @if (offreachat.montant) {
                    <div class="tw:text-right">{{ offreachat.montant | textCurrency }}</div>
                  }
                </td>

                <td class="tw:text-center"><app-offreachat-statut [offreachat]="offreachat" class="tw:text-xs"/></td>

                <td class="tw:table-head-separate-body-actions">
                  <button class="tw:btn-secondary-info tw:bg-transparent tw:shadow-none tw:ring-0 tw:w-10" type="button"
                          #buttonElement (click)="click(buttonElement, offreachat)">
                    <fa-icon [icon]="'ellipsis-v'"/>
                  </button>
                </td>
              </tr>
            }
          </tbody>
        </table>
      </div>
    } @else {
      <div class="tw:my-4 tw:sm:my-8 tw:mx-auto tw:w-full tw:pt-6 tw:px-4 tw:text-center">
        <p class="tw:mx-auto tw:mb-4 tw:text-base tw:text-gray-500 tw:sm:text-lg tw:md:text-xl">
          Il n'y a aucune offre d'achat associée à votre dossier.
        </p>
      </div>
    }
  </div>
} @else {
  <app-loader [classHeight]="'tw:h-40'" class="tw:block"/>
}

<app-load-more [collection$]="cOffreachats$" (loadMore)="getMore()" [loading]="pendingGetMore"/>
