@if (user$ | async; as user) {
  @if (pending$ | appLoadObs | async; as pending) {
    <div [ngClass]="pending.value ? 'tw:waiting' : undefined!" class="tw:flex tw:gap-4">
      @if (user.hasRoleSignatureElectronique()) {
        <button type="button" class="tw:btn-primary-info" (click)="action(actions.SIGN)" [disabled]="pending.value">
          Signer
        </button>
      } @else {
        <button type="button" class="tw:btn-primary-info" (click)="action(actions.SEND)" [disabled]="pending.value">
          Envoyer
        </button>
      }

      <button type="button" class="tw:btn-secondary-info tw:w-10" #buttonElement (click)="clickDropdown(buttonElement)">
        <fa-icon [icon]="'ellipsis-v'"/>
      </button>
    </div>
  }
} @else {
  <app-loader [classHeight]="'tw:h-8'" class="tw:block tw:w-40"/>
}
