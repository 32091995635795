<div [@openClose]="isOpen" [hidden]="!isOpen" class="tw:absolute tw:z-[9999] tw:inset-0 tw:overflow-y-auto"
     aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div class="tw:flex tw:items-start tw:justify-center tw:relative tw:min-h-screen tw:text-center tw:sm:block tw:sm:p-0">
    <div class="tw:fixed tw:h-full tw:w-full tw:bg-gray-500/50 tw:transition-opacity tw:backdrop-blur-xs"
         (click)="close()" aria-hidden="true"></div>

    <div class="tw:absolute tw:left-1/2 tw:-translate-x-1/2 tw:inline-block tw:sm:max-w-[90%] tw:bg-white tw:rounded-lg tw:shadow-xl tw:text-left tw:transition-all"
         [ngClass]="{'tw:top-1 tw:sm:top-4 tw:lg:top-8': options.align === 'top',
                      'tw:top-1/2 tw:-translate-y-1/2': options.align === 'middle',
                      'tw:bottom-1 tw:sm:bottom-4 tw:lg:bottom-8': options.align === 'bottom',
                      'tw:p-4 tw:sm:p-6': options.withPadding}"
         [@openCloseModal]="isOpen">
      @if (options.allowClose) {
        <div class="tw:absolute tw:top-0 tw:right-0 tw:pt-4 tw:pr-4">
          @if (options.button === 'cross') {
            <button class="tw:bg-white tw:h-8 tw:w-8 tw:text-gray-400 tw:hover:text-gray-500" aria-label="Close"
                    (click)="close()" type="button">
              <span class="tw:text-2xl"><fa-icon [icon]="'times'"/></span>
            </button>
          }

          @if (options.button === 'ok') {
            <button class="tw:btn-secondary-info" aria-label="Close" (click)="close()" type="button">OK</button>
          }
        </div>
      }

      <ng-template #modalContent/>
    </div>
  </div>
</div>
