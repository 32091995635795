import {inject, Injectable} from '@angular/core';
import {NgBienDossier, NgEstimation, NgLocation, NgVente} from '@legacy/app/managers/ressources';
import ADossierBien from '@models/dossiers/biens/bien/dossier-bien.model.abstract';
import {Observable, of, switchMap} from 'rxjs';
import {EstimationService} from '@models/estimations/estimation/estimation.service';
import {NgEstimationManager, NgLocationManager, NgVenteManager} from '@legacy/app/managers/managers';
import {NgInjectorService} from '@shared/angularJS/injector.ng.service';
import DemandeurRechercheResultat
    from '@core/models/demandeurs/demandeur/recherches/recherche/resultats/resultat/demandeur-recherche-resultat.model';
import {LocationFactory} from '@models/locations/location/location.factory';
import {VenteFactory} from '@models/ventes/vente/vente.factory';
import {LocationService} from '@models/locations/location/location.service';
import {VenteService} from '@models/ventes/vente/vente.service';
import {DossierBienType} from '@models/dossiers/biens/dossier-biens.constants';
import {DossierBienTypesConst} from '@models/dossiers/biens/dossier-biens.constants';
import {DossierBienFactory} from '@models/dossiers/biens/bien/dossier-bien.factory';
import {EtudeService} from '@models/etudes/etude/etude.service';
import Location from '@models/locations/location/location.model';
import Vente from '@models/ventes/vente/vente.model';
import Estimation from '@models/estimations/estimation/estimation.model';
import {EstimationFactory} from '@models/estimations/estimation/estimation.factory';
import {BrowserOnlineService} from '@shared/browser/online/browser.online.service';
import {BienFactory} from '@models/bien/bien.factory';
import {catchError} from 'rxjs/operators';
import {HttpOptions} from '@core/api/api.interfaces';
import {ToasterService} from '@shared/toaster/toaster.service';

@Injectable({providedIn: 'root'})
export class DossierBienService {
    private _bienFactory = inject(BienFactory);
    private _browserOnlineService = inject(BrowserOnlineService);
    private _dossierBienFactory = inject(DossierBienFactory);
    private _estimationFactory = inject(EstimationFactory);
    private _estimationService = inject(EstimationService);
    private _etudeService = inject(EtudeService);
    private _locationFactory = inject(LocationFactory);
    private _locationService = inject(LocationService);
    private _ngInjectorService = inject(NgInjectorService);
    private _toasterService = inject(ToasterService);
    private _venteFactory = inject(VenteFactory);
    private _venteService = inject(VenteService);

    // Supprimer les injections des anciens manager
    private get ngEstimationManager(): NgEstimationManager {
        return this._ngInjectorService.getService('EstimationManager');
    }

    // Supprimer les injections des anciens manager
    private get ngLocationManager(): NgLocationManager {
        return this._ngInjectorService.getService('LocationManager');
    }

    // Supprimer les injections des anciens manager
    private get ngVenteManager(): NgVenteManager {
        return this._ngInjectorService.getService('VenteManager');
    }

    askToBeSaved(dossierBien: ADossierBien): void {
        if (dossierBien.isEstimation()) {
            this._estimationService.askToBeSaved();
        } else if (dossierBien.isLocation()) {
            this._locationService.askToBeSaved();
        } else if (dossierBien.isVente()) {
            this._venteService.askToBeSaved();
        }
    }

    getDescriptifFromNg$(ngBienDossier: NgBienDossier): Observable<string> {
        return this._browserOnlineService.checkAndPrevent$(false).pipe(switchMap(isOn => {
            if (!isOn) {
                return of(null as unknown as string);
            }

            return this.ngSave$(ngBienDossier, {headers: {handledStatusErrors: [422]}}).pipe(
                catchError(() => {
                    const dossierBien = this.ngCreate(ngBienDossier);

                    this._toasterService.warning(`Enregistrement incomplet du bien ${dossierBien.reference}`, `L'IA ne travaillera pas sur les dernières données du bien.`);

                    return of(dossierBien);
                }),
                switchMap(dossierBien => this._bienFactory.getDescriptif$(dossierBien.id)),
            );
        }));
    }

    getFromDemandeurRechercheResultat$(demandeurRechercheResultat: DemandeurRechercheResultat): Observable<ADossierBien> {
        if (demandeurRechercheResultat.hasLocation()) {
            return this._locationFactory.getByLink$(demandeurRechercheResultat.linkLocation);
        } else if (demandeurRechercheResultat.hasVente()) {
            return this._venteFactory.getByLink$(demandeurRechercheResultat.linkVente);
        }

        return of(undefined as unknown as ADossierBien);
    }

    getNgCurrent$(dossierBienType: DossierBienType): Observable<NgBienDossier> {
        if (dossierBienType === DossierBienTypesConst.ESTIMATION) {
            return this.ngEstimationManager.current$;
        } else if (dossierBienType === DossierBienTypesConst.LOCATION) {
            return this.ngLocationManager.current$;
        } else if (dossierBienType === DossierBienTypesConst.VENTE) {
            return this.ngVenteManager.current$;
        }

        return of(undefined as unknown as NgBienDossier);
    }

    getTitreFromNg$(ngBienDossier: NgBienDossier): Observable<string> {
        return this._browserOnlineService.checkAndPrevent$(false).pipe(switchMap(isOn => {
            if (!isOn) {
                return of(null as unknown as string);
            }

            return this.ngSave$(ngBienDossier, {headers: {handledStatusErrors: [422]}}).pipe(
                catchError(() => {
                    const dossierBien = this.ngCreate(ngBienDossier);

                    this._toasterService.warning(`Enregistrement incomplet du bien ${dossierBien.reference}`, `L'IA ne travaillera pas sur les dernières données du bien.`);

                    return of(dossierBien);
                }),
                switchMap(dossierBien => this._bienFactory.getTitre$(dossierBien.id)),
            );
        }));
    }

    openVirtualVisit$(dossierBien: ADossierBien): Observable<Window> {
        return this._etudeService.last$.pipe(switchMap(etude => this._dossierBienFactory.openVirtualVisit$(etude, dossierBien)));
    };

    save$(dossierBien: ADossierBien, httpOptions?: HttpOptions): Observable<ADossierBien> {
        if (dossierBien.isEstimation()) {
            return this._estimationFactory.save$(dossierBien as Estimation, httpOptions);
        } else if (dossierBien.isLocation()) {
            return this._locationFactory.save$(dossierBien as Location, httpOptions);
        } else if (dossierBien.isVente()) {
            return this._venteFactory.save$(dossierBien as Vente, httpOptions);
        }

        return of(dossierBien);
    }

    ngCreate(ngBienDossier: NgBienDossier): ADossierBien {
        if (ngBienDossier._esk.typeDossier === DossierBienTypesConst.ESTIMATION) {
            return this._estimationFactory.ngCreate(ngBienDossier as NgEstimation);
        } else if (ngBienDossier._esk.typeDossier === DossierBienTypesConst.LOCATION) {
            return this._locationFactory.ngCreate(ngBienDossier as NgLocation);
        } else if (ngBienDossier._esk.typeDossier === DossierBienTypesConst.VENTE) {
            return this._venteFactory.ngCreate(ngBienDossier as NgVente);
        }

        return null!;
    }

    ngSave$(ngBienDossier: NgBienDossier, httpOptions?: HttpOptions): Observable<ADossierBien> {
        return this.save$(this.ngCreate(ngBienDossier), httpOptions);
    }
}
