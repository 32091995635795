import {inject, Injectable} from '@angular/core';
import {TemplateBaliseFactory} from '@models/templates/balises/balise/template-balise.factory';
import {ITemplateBaliseApi} from '@models/templates/balises/balise/template-balise.interfaces';
import TemplateBalise from '@models/templates/balises/balise/template-balise.model';

@Injectable({providedIn: 'root'})
export class TemplateBalisesFactory {
    private _templateBaliseFactory = inject(TemplateBaliseFactory);

    create(templateBaliseApis: ITemplateBaliseApi[] = []): TemplateBalise[] {
        return (templateBaliseApis || []).filter(templateBaliseApi => templateBaliseApi)
            .map(templateBaliseApi => this._templateBaliseFactory.create(templateBaliseApi));
    }
}
