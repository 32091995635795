import angularJS from '@shared/angularJS/global.ng';
import {IModule} from 'angular';
import {
    NgDictionariesManager, NgEmailManager, NgLocationManager, NgLocationMandatManager, NgMediaManager, NgTemplateManager
} from '@legacy/app/managers/managers';
import {NgSoqModalesManager} from '@legacy/app/soqrate/soqrate';
import {TinymceService} from '@shared/tinymce/tinymce.service';
import Dictionary from '@models/dictionaries/dictionary/dictionary.model';
import {DictionaryFactory} from '@models/dictionaries/dictionary/dictionary.factory';
import {DossierBienTypesConst} from '@models/dossiers/biens/dossier-biens.constants';
import {firstValueFrom} from 'rxjs';
import {LocationMandatsService} from '@models/locations/location/mandats/location-mandats.service';
import {LocationMandatFactory} from '@models/locations/location/mandats/mandat/location-mandat.factory';
import {LocationService} from '@models/locations/location/location.service';

export default function getLocationMandatsCtrl(module: IModule): void {
    (function (angular) {
        'use strict';

        module.component('eskLocationMandatsCtrl', {
            controller: Controller,
            templateUrl: 'src/app/legacy/templates/locations/controllers/item/mandats.html',
        });

        /**
         * Controller for edit mandat
         *
         * @param LocationManager
         * @param DictionariesManager
         * @param LocationMandatManager
         * @param TemplateManager
         * @param SoqModalesManager
         * @param EmailManager
         * @param Ng2TinymceService
         * @param Ng2DictionaryFactory
         * @param Ng2LocationMandatsService
         * @param Ng2LocationMandatFactory
         * @param MediaManager
         * @param Ng2LocationService
         */
        Controller.$inject = ['LocationManager', 'DictionariesManager', 'LocationMandatManager', 'TemplateManager', 'SoqModalesManager', 'EmailManager', 'Ng2TinymceService', 'Ng2DictionaryFactory', 'Ng2LocationMandatsService', 'Ng2LocationMandatFactory', 'MediaManager', 'Ng2LocationService'];
        function Controller(this: any,
                            locationManager: NgLocationManager,
                            dictionariesManager: NgDictionariesManager,
                            locationMandatManager: NgLocationMandatManager,
                            templateManager: NgTemplateManager,
                            soqModalesManager: NgSoqModalesManager,
                            emailManager: NgEmailManager,
                            ng2TinymceService: TinymceService,
                            ng2DictionaryFactory: DictionaryFactory,
                            ng2LocationMandatsService: LocationMandatsService,
                            ng2LocationMandatFactory: LocationMandatFactory,
                            mediaManager: NgMediaManager,
                            ng2LocationService: LocationService) {
            const $ctrl = this;

            $ctrl.setDateOptions = setDateOptions;
            $ctrl.getLocationMandat = getLocationMandat;
            $ctrl.generatePDF = generatePDF;
            $ctrl.send = send;
            $ctrl.save = save;
            $ctrl.archive = archive;
            $ctrl.tinymceOptions = ng2TinymceService.completeOptions;
            $ctrl.typesMandatLocation = dictionariesManager.createDictionaryFromNg2(ng2DictionaryFactory.getByName(Dictionary.names.LOCATION_MANDAT_TYPES));
            $ctrl.mandat = {};
            $ctrl.options = {mandatDate: {}, mandatDateFin: {}};
            updateMandats();

            /**
             * Set options date
             */
            function setDateOptions() {
                $ctrl.options.mandatDate.maxDate = $ctrl.mandat.dateFin;
                $ctrl.options.mandatDateFin.minDate = $ctrl.mandat.date;
            }

            /**
             * Update mandat
             */
            function getLocationMandat() {
                $ctrl.initMandat = true;
                $ctrl.save()
                    .then(() => templateManager.getLocationMandat(locationManager.current, $ctrl.mandat))
                    .then(mandatContenu => $ctrl.mandat.contenu = mandatContenu)
                    .finally(() => $ctrl.initMandat = false);
            }

            /**
             * Generate mandat in PDF
             */
            function generatePDF() {
                $ctrl.loading = true;
                $ctrl.save()
                    .then(() => firstValueFrom(ng2LocationMandatsService.read$(ng2LocationService.getCurrentFromNg(), ng2LocationMandatFactory.ngCreate($ctrl.mandat))))
                    .finally(() => $ctrl.loading = false);
            }

            /**
             * Send PDF via email
             */
            function send() {
                $ctrl.loading = true;
                $ctrl.save()
                    .then(() => firstValueFrom(ng2LocationMandatsService.getMedia$(ng2LocationService.getCurrentFromNg(), ng2LocationMandatFactory.ngCreate($ctrl.mandat))))
                    .then(media => mediaManager.createFromNg2(media))
                    .then(ngMedia => soqModalesManager.open('EskModalEmail', {
                        resolve: {
                            email: emailManager.create({
                                recipients: locationManager.current.bien.contactsGroup.getRecipients(),
                                attachments: [{media: ngMedia}],
                                dossiers: [{
                                    typeId: locationManager.current.id,
                                    type: DossierBienTypesConst.LOCATION,
                                    dossier: locationManager.current,
                                }],
                                subject: 'Mandat de ' + $ctrl.mandat.type.label.toLowerCase() + ' du bien en location ' + locationManager.current.bien.reference,
                                message: locationManager.current.bien.contactsGroup.formuleIntroduction + ",<br><br>" +
                                    "Nous vous prions de trouver ci-joint, le mandat de " + $ctrl.mandat.type.label.toLowerCase() + " concernant le bien en location.<br><br>" +
                                    "Nous vous remercions de bien vouloir le compléter aux endroits indiqués et nous le retourner signé.<br><br>" +
                                    "Nous restons à votre entière disposition pour tous renseignements complémentaires.<br>" +
                                    "Nous vous prions d'agréer, " + locationManager.current.bien.contactsGroup.formulePolitesse + ", l'expression de nos salutations les plus distinguées.<br><br>" +
                                    emailManager.getCurrentUserSignature()
                            }),
                            currentDossier: locationManager.current,
                        }
                    })).finally(() => $ctrl.loading = false);
            }

            /**
             * Save Location
             *
             * @returns {*}
             */
            function save() {
                $ctrl.saving = true;

                return $ctrl.mandat?.save?.().finally(() => $ctrl.saving = false);
            }

            /**
             * Save Location
             *
             * @returns {*}
             */
            function archive() {
                $ctrl.archiving = true;
                $ctrl.mandat.enabled = false;

                return $ctrl.save().then(updateMandats).finally(() => $ctrl.archiving = false);
            }

            /**
             * Update mandat
             *
             * @returns {Promise}
             */
            function updateMandats() {
                $ctrl.loadingMandats = true;

                return locationMandatManager.getAll(locationManager.current.id).then(function (mandats) {
                    $ctrl.mandats = mandats.collection;
                    if (angular.isObject($ctrl.mandats[0]) && $ctrl.mandats[0].enabled) {
                        return locationMandatManager.getOneById(locationManager.current.id, $ctrl.mandats[0].id).then(function (mandat) {
                            $ctrl.mandat = mandat;
                        });
                    } else {
                        $ctrl.mandat = locationMandatManager.create({}, locationManager.current.id);
                        $ctrl.mandat.enabled = true;
                        $ctrl.getLocationMandat();

                        return;
                    }
                }).then(() => {
                    $ctrl.mandat.type = $ctrl.typesMandatLocation.find(item => item.uuid === $ctrl.mandat.type.uuid)
                }).finally(() => $ctrl.loadingMandats = false);
            }
        }
    })(angularJS);
}
