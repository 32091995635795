@if (offreachat$ | async; as offreachat) {
  <div class="tw:h-full tw:-mx-[15px] tw:sm:mx-0 tw:pt-8">
    @if (procedureAcquereur$ | async; as procedureAcquereur) {
      @if (offreachat.isSignatureAcquereur() && procedureAcquereur.isNewOrDraft()) {
        <app-offreachat-signature-edit [currentDossier]="currentDossier" [procedureAcquereur$]="procedureAcquereur$"
                                       [procedureVendeur$]="procedureVendeur$" [offreachat]="offreachat"
                                       [requestSaving$]="requestSignatairesSaving$" (saved)="formSaved()"
                                       class="tw:form-2col"/>
      } @else {
        <app-offreachat-signature-in-progress [offreachat]="offreachat" [procedureAcquereur$]="procedureAcquereur$"
                                              [currentDossier]="currentDossier" [procedureVendeur$]="procedureVendeur$"
                                              (procedureClosed)="procedureClosed()"/>
      }
    } @else {
      <app-loader [classHeight]="'tw:h-40'" class="tw:block tw:mb-8"/>
    }
  </div>
} @else {
  <app-loader [classHeight]="'tw:h-40'" class="tw:block tw:mb-8"/>
}
