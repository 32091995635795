<form #editForm="ngForm" class="tw:form-2col">
  @if (headerLoading) {
    <app-loader [classHeight]="'tw:h-40'" class="tw:block"/>

    <app-loader [classHeight]="'tw:h-40'" class="tw:block"/>

    <app-loader [classHeight]="'tw:h-40'" class="tw:block"/>
  } @else {
    @if (header.enabled) {
      <div class="tw:form-2col-part">
        <div class="tw:form-left-col">
          <h3 class="tw:form-left-col-title">Mise en page</h3>

          <p class="tw:form-left-col-explain">
            Par défaut, <app-noteo-icon-text-noty/> s'occupe de tout.<br><br>Si l'en-tête des documents éditables
            (mandat, avis de valeur, ...) n'est pas cohérent avec les couleurs de l'étude, vous pouvez le
            personnaliser.
          </p>
        </div>

        <div class="tw:form-right-col">
          @if (cTemplateHeaders$ | async; as cTemplateHeaders) {
            <div>
              <div class="tw:flex tw:pb-2 tw:text-sm">
                <label class="tw:block tw:text-gray-700">Choix du modèle de base</label>

                @if (!header.isCustom()) {
                  <button type="button" class="tw:text-blue-500 tw:hover:text-blue-600 tw:ml-8 tw:underline"
                          (click)="selectTemplateHeaderCustom()">Personnaliser le modèle</button>
                }
              </div>

              <app-form-select-image [model]="header.code" (selected)="selectTemplateHeader($event)"
                                     [options]="{selectionAccepted$: headerCodeValidation$.bind(this)}"
                                     [list]="cTemplateHeaders.results"/>
            </div>
          } @else {
            <app-loader [classHeight]="'tw:h-40'" class="tw:block"/>
          }
        </div>
      </div>
    } @else {
      <div class="tw:form-2col-part">
        <div class="tw:form-left-col">
          <h3 class="tw:form-left-col-title">Mise en page</h3>

          <p class="tw:form-left-col-explain">
            Par défaut, <app-noteo-icon-text-noty/> s'occupe de tout.
          </p>
        </div>

        <div class="tw:form-right-col">
          <p>
            L'en-tête des documents éditables (mandat, avis de valeur, ...) est personnalisé par Noteo suite à la
            demande de l'étude.<br><br>Si vous souhaitez pouvoir le modifier quand et comme vous le désirez, merci de
            <esk-contact type="support" class="tw:inline-block tw:lowercase"/>.
          </p>
        </div>
      </div>
    }

    @if (header.isCustom()) {
      <div class="tw:form-2col-part">
        <div class="tw:form-left-col">
          <h3 class="tw:form-left-col-title">Personnalisation de l'en-tête</h3>

          <p class="tw:form-left-col-explain">
            Personnalisez la forme ainsi que le contenu de votre en-tête afin de respecter la charte graphique de
            l'étude.
          </p>
        </div>

        <div class="tw:form-right-col tw:flex tw:flex-col tw:gap-8">
          <div>
            <label for="header-height" class="tw:block tw:text-sm tw:text-gray-700">Hauteur</label>

            <div class="tw:input-currency tw:mt-1">
              <input [(ngModel)]="header.customHeight" type="text" placeholder="Hauteur" min="0" name="headerHeight"
                     (input)="updateHeader()" pattern="[0-9]+" id="header-height" class="tw:input-number">

              <span>mm</span>
            </div>
          </div>

          <editor [(ngModel)]="header.customTemplate" [init]="editorOptions" [ngModelOptions]="{standalone: true}"
                  (ngModelChange)="updateHeader()" ngDefaultControl/>
        </div>
      </div>
    }

    @if (header.enabled) {
      <div class="tw:form-2col-part">
        <div class="tw:form-left-col">
          <h3 class="tw:form-left-col-title">Aperçu de l'en-tête</h3>

          <p class="tw:form-left-col-explain">
            Visualisez le rendu réel de l'en-tête utilisé sur les documents éditables.
          </p>
        </div>

        <div class="tw:form-right-col">
          @if (headerPreview$ | appLoadObs | async; as headerPreview) {
            <div>
              @if (headerPreview.value) {
                <div #preview class="tw:mx-auto tw:border tw:border-gray-200 tw:border-b-transparent preview-bottom">
                  <app-iframe [options]="headerPreviewOptions" [src]="headerPreview.value" class="tw:block"/>
                </div>
              } @else {
                <p>
                  Saisissez dans l'éditeur le contenu de votre en-tête.

                  @if (header422Error) {
                    <span class="tw:text-orange-300">
                      <br><br>
                      {{ 'Attention à ne pas utiliser "{{" dans l\'éditeur ce qui peut amener des erreurs techniques pour la visualisation et l\'utilisation de l\'en-tête.' }}
                    </span>
                  }
                </p>
              }
            </div>
          } @else {
            <app-loader [classHeight]="'tw:h-40'" class="tw:block"/>
          }
        </div>
      </div>
    }
  }
</form>
