export default `
<!-- Raison sociale -->
<div class="row">
  <div class="col-sm-8 form-group"
       ng-class="{'has-error': $ctrl.editForm.$submitted && $ctrl.editForm.entrepriseName.$invalid}">
    <label for="edit-contact-entreprise-raisonSociale">{{'Raison sociale'|translate}} *</label>

    <input soq-capitalize-input="1" type="text" name="entrepriseName" id="edit-contact-entreprise-raisonSociale"
           class="form-control" ng-model="$ctrl.contact.entreprise.getRaisonSociale" required
           ng-model-options="{getterSetter: true}" maxlength="140">

    <div class="help-block" ng-messages="$ctrl.editForm.entrepriseName.$error"
         ng-if="$ctrl.editForm.$submitted && $ctrl.editForm.entrepriseName.$invalid">
      <ng-messages-include src="messages.html"></ng-messages-include>
    </div>
  </div>

  <div class="col-sm-4 form-group"
       ng-class="{'has-error': $ctrl.editForm.$submitted && $ctrl.editForm.entrepriseSiret.$invalid}">
    <label for="edit-contact-entreprise-siret">{{'SIRET'|translate}}</label>

    <input type="text" name="entrepriseSiret" id="edit-contact-entreprise-siret" class="form-control"
           ng-model="$ctrl.contact.entreprise.siret" maxlength="14">

    <div class="help-block" ng-messages="$ctrl.editForm.entrepriseSiret.$error"
         ng-if="$ctrl.editForm.$submitted && $ctrl.editForm.entrepriseSiret.$invalid">
      <ng-messages-include src="messages.html"></ng-messages-include>
    </div>
  </div>
</div>

<!-- Téléphone, mobile, email -->
<div class="row">
  <div class="col-sm-4 form-group"
       ng-class="{'has-error': $ctrl.editForm.$submitted && !$ctrl.contact.entreprise.isPhoneValid($ctrl.contact.entreprise.telephone)}">
    <label for="edit-contact-entreprise-phone">{{'Téléphone' | translate}}</label>

    <input type="text" id="edit-contact-entreprise-phone" class="form-control"
           ng-model="$ctrl.contact.entreprise.telephone">

    <div class="error text-small tw:block" ng-messages="{pattern: true}"
         ng-if="$ctrl.editForm.$submitted && !$ctrl.contact.entreprise.isPhoneValid($ctrl.contact.entreprise.telephone)">
      <ng-messages-include src="messages.html"></ng-messages-include>
    </div>
  </div>

  <div class="col-sm-4 form-group"
       ng-class="{'has-error': $ctrl.editForm.$submitted && !$ctrl.contact.entreprise.isPhoneValid($ctrl.contact.entreprise.mobile)}">
    <label for="edit-contact-entreprise-mobile">{{'Mobile' | translate}}</label>

    <input type="text" id="edit-contact-entreprise-mobile" class="form-control"
           ng-model="$ctrl.contact.entreprise.mobile">

    <div class="error text-small tw:block" ng-messages="{pattern: true}"
         ng-if="$ctrl.editForm.$submitted && !$ctrl.contact.entreprise.isPhoneValid($ctrl.contact.entreprise.mobile)">
      <ng-messages-include src="messages.html"></ng-messages-include>
    </div>
  </div>

  <div class="col-sm-4 form-group"
       ng-class="{'has-error': ($ctrl.editForm.$submitted && $ctrl.editForm.entrepriseEmailAddress.$invalid) || $ctrl.contact.invalidEmailAddress}">
    <label for="edit-contact-entreprise-email">{{'Email'|translate}}</label>

    <input type="email" name="entrepriseEmailAddress" id="edit-contact-entreprise-email" class="form-control"
           ng-model="$ctrl.contact.entreprise.emailAddress">

    <div ng-if="$ctrl.contact.invalidEmailAddress" class="help-email">
      <a uib-popover="{{'mail.invalid_recipient.contact.HELP' | translate: '{type: \\'address\\'}'}}"
         popover-trigger="'outsideClick'" popover-placement="left-top" type="button" class="btn btn-o btn-null">
        <span class="badge badge-info">?</span>
      </a>
    </div>

    <div class="help-block" ng-messages="$ctrl.editForm.entrepriseEmailAddress.$error"
         ng-if="$ctrl.editForm.$submitted && $ctrl.editForm.entrepriseEmailAddress.$invalid">
      <ng-messages-include src="messages.html"></ng-messages-include>
    </div>
  </div>
</div>

<!-- Adresse -->
<div class="row">
  <div class="col-sm-8 form-group">
    <label>{{"Commune"|translate}}</label>

    <esk-select-commune ng-model="$ctrl.contact.entreprise.commune" name="commune"
                        on-select="$ctrl.onSelectCommune"
                        placeholder="Rechercher par commune ou par le code postal de celle-ci ...">
    </esk-select-commune>
  </div>

  <div ng-if="!!$ctrl.contact.entreprise.commune" class="col-sm-4 form-group"
       ng-class="{'has-error': $ctrl.editForm.$submitted && $ctrl.editForm.entrepriseCodePostal.$invalid}">
    <label for="edit-contact-entreprise-codePostal">{{"Code Postal"|translate}}</label>

    <input type="text" id="edit-contact-entreprise-codePostal" class="form-control" name="entrepriseCodePostal"
           ng-model="$ctrl.contact.entreprise.codePostal" maxlength="5" minlength="5">

    <div class="help-block" ng-messages="$ctrl.editForm.entrepriseCodePostal.$error"
         ng-if="$ctrl.editForm.$submitted && $ctrl.editForm.entrepriseCodePostal.$invalid">
      <ng-messages-include src="messages.html"></ng-messages-include>
    </div>
  </div>

  <div ng-if="!!$ctrl.contact.entreprise.commune" class="col-sm-12 form-group">
    <label for="edit-contact-entreprise-adresse">{{"Adresse"|translate}}</label>

    <textarea id="edit-contact-entreprise-adresse" class="form-control" ng-model="$ctrl.contact.entreprise.adresse">
    </textarea>
  </div>
</div>

<!-- Autres informations -->
<div class="panel panel-default">
  <div class="panel-body">
    <div class="margin-bottom-10">
      <button type="button" class="tw:btn-tertiary-info tw:p-2 tw:text-xs" ng-click="$ctrl.showAll = !$ctrl.showAll">
        <fa-icon ng-if="$ctrl.showAll" [icon]="'caret-down'"></fa-icon>

        <fa-icon ng-if="!$ctrl.showAll" [icon]="'caret-right'"></fa-icon>

        <span ng-if="$ctrl.showAll" >Masquer les autres renseignements</span>

        <span ng-if="!$ctrl.showAll" >Afficher les autres renseignements</span>
      </button>
    </div>

    <div ng-if="$ctrl.showAll">
      <!-- Forme juridique et capital -->
      <div class="row">
        <div class="col-sm-4 form-group">
          <label for="edit-contact-entreprise-formeJuridique">{{'Forme juridique'|translate}}</label>

          <input type="text" id="edit-contact-entreprise-formeJuridique" class="form-control"
                 ng-model="$ctrl.contact.entreprise.formeJuridique" maxlength="50">
        </div>

        <div class="col-sm-4 form-group">
          <label for="edit-contact-entreprise-capital">{{'Capital'|translate}}</label>

          <div class="input-group input-group-number input-group-currency">
            <input type="text" id="edit-contact-entreprise-capital" class="form-control"
                   ng-model="$ctrl.contact.entreprise.capital" soq-only-digits>

            <span class="input-group-addon">€</span>
          </div>
        </div>
      </div>

      <!-- Commentaires -->
      <div class="row">
        <div class="col-sm-12">
          <label for="edit-contact-entreprise-comment">{{'Commentaires confidentiels'|translate}}</label>

          <textarea id="edit-contact-entreprise-comment" ng-model="$ctrl.contact.entreprise.comments"
                    class="form-control"></textarea>
        </div>
      </div>
    </div>
  </div>
</div>
`;
