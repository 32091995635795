import {inject, Injectable} from '@angular/core';
import Links from '@models/links/links.model';
import {
    ICOffreachatsApi, ICOffreachatsQueryParameters
} from '@models/offreachats/collection/offreachats.collection.interfaces';
import COffreachats from '@models/offreachats/collection/offreachats.collection.model';
import {Observable} from 'rxjs';
import {OffreachatsApiService} from '@models/offreachats/offreachats.api.service';
import {map} from 'rxjs/operators';
import {OffreachatsFactory} from '@models/offreachats/offreachats.factory';

@Injectable({providedIn: 'root'})
export class COffreachatsFactory {
    private _offreachatsFactory = inject(OffreachatsFactory);
    private _offreachatsApiService = inject(OffreachatsApiService);

    create(cOffreachatsApi: ICOffreachatsApi): COffreachats {
        const cOffreachats = new COffreachats();

        cOffreachats.links = new Links(cOffreachatsApi._links);
        cOffreachats.page = cOffreachatsApi.page;
        cOffreachats.pages = cOffreachatsApi.pages;
        cOffreachats.perPage = cOffreachatsApi.limit;
        cOffreachats.total = cOffreachatsApi.total;
        if (cOffreachatsApi._embedded) {
            cOffreachats.results = this._offreachatsFactory.create(cOffreachatsApi._embedded.items);
        }

        return cOffreachats;
    }

    get$(queryParameters?: ICOffreachatsQueryParameters): Observable<COffreachats> {
        return this._offreachatsApiService.getEtudeCollection$(queryParameters).pipe(map(cOffreachatsApi => this.create(cOffreachatsApi)));
    }

    getNext$(cOffreachats: COffreachats): Observable<COffreachats> {
        return this._offreachatsApiService.getCollectionByLink$(cOffreachats.links.next).pipe(map(cOffreachatsApi => this.create(cOffreachatsApi)));
    }
}
