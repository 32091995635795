export default `
<div class="form-group">
  <esk-select-dossier types-dossier-enabled="$ctrl.typesDossierEnabled" on-select="$ctrl.add"
                      disabled="$ctrl.isDossierDisabled" options="$ctrl.options"></esk-select-dossier>
</div>

<table class="margin-top-15 table table-bordered table-hover">
  <thead>
  <tr>
    <th>Référence</th>

    <th ng-if="!!$ctrl.addContacts">
      Contacts
      <a uib-popover-template="'src/app/legacy/templates/eskimmo/components/actions/dossiers/add-contacts-help.html'"
         popover-trigger="'outsideClick'" popover-placement="right" class="btn btn-o btn-null">
        <span class="badge badge-info">?</span>
      </a>
    </th>

    <th>Type de dossier</th>

    <th></th>
  </tr>
  </thead>

  <tbody ng-switch="$ctrl.dossiers.length > 0">
  <tr ng-switch-when="false">
    <td colspan="{{!!$ctrl.addContacts ? '4' : '3'}}" class="tw:text-center text-muted">Aucun dossier rattaché</td>
  </tr>

  <tr ng-switch-default ng-repeat="dossier in $ctrl.dossiers">
    <td class="col-sm-5">
      <span ng-if="dossier.type === $ctrl.DossierBienTypesConst.ESTIMATION || dossier.type === $ctrl.DossierBienTypesConst.LOCATION || dossier.type === $ctrl.DossierBienTypesConst.VENTE">
        {{dossier.dossier.bien.titre}}<br>

        <p class="small text-muted">{{dossier.dossier.bien.reference}} : {{dossier.dossier.bien.contactsGroup.nom}}</p>
      </span>

      <span ng-if="dossier.type === $ctrl.DossierBienTypesConst.DEMANDEUR">
        {{dossier.dossier.contactsGroup.nom}}<br>

        <p class="small text-muted">{{dossier.dossier.reference}}</p>
      </span>

      <span ng-if="dossier.type !== $ctrl.DossierBienTypesConst.DEMANDEUR && dossier.type !== $ctrl.DossierBienTypesConst.ESTIMATION && dossier.type !== $ctrl.DossierBienTypesConst.LOCATION && dossier.type !== $ctrl.DossierBienTypesConst.VENTE">Autres</span>
    </td>

    <td ng-if="!!$ctrl.addContacts" class="col-sm-3 tw:text-center">
      <button type="button" class="tw:btn-tertiary-info tw:py-0 tw:px-2" ng-click="$ctrl.addContacts(dossier)">
        <fa-icon [icon]="'users'"></fa-icon><span>Ajouter aux destinataires</span>
      </button>
    </td>

    <td class="col-sm-2 tw:text-center text-capitalize">{{dossier.type}}</td>

    <td class="col-sm-2 tw:text-center"
        ng-switch="dossier.typeId === $ctrl.currentDossier.id && dossier.type === $ctrl.currentDossier._esk.typeDossier">
      <span ng-switch-when="true">Dossier actuel</span>

      <button ng-switch-default class="tw:btn-tertiary-info tw:py-0 tw:px-2" ng-click="$ctrl.remove(dossier)"
              type="button"><fa-icon [icon]="['far', 'file']"></fa-icon><span>Retirer</span></button>
    </td>
  </tr>
  </tbody>
</table>
`;
