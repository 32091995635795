@if (cUsers$ | async; as cUsers) {
  <div>
    @if (cUsers.total > 0) {
      <div class="tw:p-4 tw:md:px-0">
        <span class="tw:font-bold">{{ cUsers.total }}</span> {{ cUsers.total > 1 ? 'utilisateurs' : 'utilisateur' }}
      </div>

      <div class="tw:overflow-hidden tw:mb-8">
        <div class="tw:grid tw:grid-cols-1 tw:gap-y-4 tw:sm:grid-cols-2 tw:sm:gap-6 tw:md:grid-cols-3 tw:lg:grid-cols-4 tw:xl:grid-cols-5 tw:xl:gap-x-8">
          @for (user of cUsers.results; track user) {
            <app-user-card [user]="user"/>
          }
        </div>
      </div>
    } @else {
      <div class="tw:my-4 tw:sm:my-8 tw:mx-auto tw:w-full tw:pt-6 tw:px-4 tw:text-center">
        <p class="tw:mx-auto tw:mb-4 tw:text-base tw:text-gray-500 tw:sm:text-lg tw:md:text-xl">
          Il n'y a aucun utilisateur.
        </p>
      </div>
    }
  </div>
} @else {
  <app-loader [classHeight]="'tw:h-40'" class="tw:block"/>
}

<app-load-more [collection$]="cUsers$" (loadMore)="getMore()" [loading]="pendingGetMore"/>
