export default `
<soq-panel box="$ctrl.box">
  <content-slot>
    <div ng-switch="$ctrl.loading">
      <p ng-switch-when="true" class="tw:text-center text-extra-large">
        <fa-icon [icon]="'spinner'" animation="spin"></fa-icon> {{'global.en_cours.LOAD' | translate}}
      </p>

      <div ng-switch-default>
        <div class="canvas-container"><canvas soq-chart="$ctrl.pieVentesNatures"></canvas></div>

        <h4 class="text-bold">
          Total : {{$ctrl.pieVentesNatures.data.total}} {{$ctrl.pieVentesNatures.data.total > 1 ? 'biens' : 'bien'}}
          à vendre avec mandat
        </h4>

        <p class="text-right text-muted text-small">
          Mise à jour des données :
          <app-ng2-date [date]="$ctrl.pieVentesNatures.date" format="shortDate"></app-ng2-date> à
          <app-ng2-date [date]="$ctrl.pieVentesNatures.date" format="shortTime"></app-ng2-date>
        </p>
      </div>
    </div>
  </content-slot>
</soq-panel>
`;
