@if (cEtudeDossiers$ | async; as cEtudeDossiers) {
  <!-- @todo Utilité de ces class ? -->
  <div class="tw:flex tw:flex-col">
    <div class="tw:flex tw:items-center">
      <div class="tw:flex tw:font-bold tw:text-base">
        <app-noteo-icon-text-inot [label]="'  Depuis ' + INOT_LABEL + ' : Dossiers'"/>
      </div>

      <span [ngPlural]="cEtudeDossiers.total" class="tw:flex-1 tw:text-right">
        <ng-template ngPluralCase="=0">Aucun résultat</ng-template>
        <ng-template ngPluralCase="=1">1 résultat trouvé</ng-template>
        <ng-template ngPluralCase="other">{{ cEtudeDossiers.total }} résultats trouvés</ng-template>
      </span>
    </div>

    @if (cEtudeDossiers.total > 0) {
      <ul>
        @for (etudeDossier of cEtudeDossiers.results; track $index) {
          <li (click)="show(etudeDossier)">
            <div class="tw:bg-gray-50 tw:rounded-lg tw:p-2 tw:my-1 tw:flex tw:w-full tw:space-x-8"
                 [ngClass]="{'tw:cursor-pointer': etudeDossier !== dossierToDisplay && etudeDossier.qualifiedContacts.length, 'tw:bg-blue-50': etudeDossier === dossierToDisplay}">
              <div class="tw:flex-1 tw:flex tw:items-center tw:space-x-3 tw:truncate">
                <div class="tw:w-12 tw:text-xl">
                  <div class="tw:relative tw:aspect-square">
                    <div class="tw:flex tw:items-center tw:justify-center tw:h-full tw:w-full tw:px-1 tw:rounded-full tw:bg-white tw:text-zinc-500">
                      <fa-icon [icon]="'users'" size="xl"/>
                    </div>
                  </div>
                </div>

                <!-- @todo Utilité de ces class ? -->
                <div class="tw:flex tw:flex-col tw:truncate">
                  <span class="tw:text-base tw:font-semibold tw:truncate" [highlight]="keyword">
                    {{ etudeDossier.nom }}
                  </span>
                  <!-- Q: le type est en camelCase, on supprime ?  si oui, on va jusqu'au model ? -->
                  <!-- {{etudeDossier.type}} -->
                </div>
              </div>

              <div class="tw:w-24 tw:self-center tw:text-right tw:flex tw:items-center tw:space-x-3"
                   [ngClass]="{'tw:text-gray-300': !etudeDossier.qualifiedContacts.length || etudeDossier === dossierToDisplay }">
                <span [ngPlural]="etudeDossier.qualifiedContacts.length">
                  <ng-template ngPluralCase="=0">Aucun contact</ng-template>
                  <ng-template ngPluralCase="=1">1 contact</ng-template>
                  <ng-template ngPluralCase="other">{{ etudeDossier.qualifiedContacts.length }} contacts</ng-template>
                </span>

                @if (etudeDossier.qualifiedContacts.length) {
                  @if (dossierToDisplay !== etudeDossier) {
                    <span><fa-icon [icon]="'chevron-right'" size="lg"/></span>
                  }

                  @if (dossierToDisplay === etudeDossier) {
                    <span><fa-icon [icon]="'chevron-down'" size="lg"/></span>
                  }
                }
              </div>
            </div>

            <!-- Impossibilité d'ajouter une marge et un padding à un ul -> styles.scss -->
            @if (etudeDossier === dossierToDisplay) {
              <div class="tw:ml-4 tw:pl-4 tw:border-l-4 tw:border-blue-50">
                <ul>
                  @for (qualifiedContact of etudeDossier.qualifiedContacts; track $index) {
                    <li class="tw:bg-gray-50 tw:rounded-lg tw:p-2 tw:my-1 tw:flex tw:w-full tw:space-x-8">
                      <app-contact-card [options]="{enabledClick: false, highlight: keyword}"
                                        class="tw:block tw:flex-1 tw:sm:flex-none tw:sm:min-w-[15rem] tw:md:min-w-[20rem] tw:w-px"
                                        [contact]="qualifiedContact.contact"/>

                      <div class="tw:self-end tw:flex-1 tw:hidden tw:sm:block tw:w-px">
                        <div class="tw:truncate">
                          @if (qualifiedContact.contact.linkCommune) {
                            <span class="tw:font-semibold">
                              <app-commune-convivial-name [linkCommune]="qualifiedContact.contact.linkCommune"/>
                            </span>
                          }

                          {{ qualifiedContact.contact.codePostal }}
                        </div>

                        <div class="tw:line-clamp-2" [innerHTML]="qualifiedContact.contact.adresse | textNl2br"
                             [highlight]="keyword"></div>
                      </div>

                      <div class="tw:w-20 tw:self-center tw:text-center">
                        @if (isAlreadySelected(qualifiedContact.contact)) {
                          <span class="tw:font-semibold tw:text-green-500">
                            <fa-icon [icon]="'check'"/><span class="tw:hidden tw:sm:inline-block"> Ajouté</span>
                          </span>
                        } @else {
                          <button type="button" (click)="addContact(qualifiedContact.contact)"
                                  [disabled]="!enableAddingContact" class="tw:btn-secondary-info tw:disabled:waiting">
                            <fa-icon [icon]="'plus'" class="tw:sm:hidden"/>
                            <span class="tw:hidden tw:sm:inline-block">Ajouter</span>
                          </button>
                        }
                      </div>
                    </li>
                  }
                </ul>
              </div>
            }
          </li>
        }
      </ul>
    }

    <app-load-more [collection$]="cEtudeDossiers$" (loadMore)="getMore(cEtudeDossiers)" [loading]="pendingGetMore"
                   [disabled]="!enableAddingContact"/>
  </div>
} @else {
  <app-loader [classHeight]="'tw:h-20'" class="tw:block"/>
}
