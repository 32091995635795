export default `
<div class="container-fluid container-fluid-title"><h1>{{$ctrl.titre}}</h1></div>

<div class="container-fluid container-fullw tw:bg-white">
  <div class="checkbox clip-check check-primary padding-bottom-10 padding-left-20">
    <input type="checkbox" id="biens-adresse-precise" ng-model="$ctrl.box.config.preciseLocalisation">

    <label for="biens-adresse-precise">Afficher l'adresse précise des biens</label>
  </div>

  <esk-search-criteria ng-if="!!$ctrl.search" search="$ctrl.search"
                       update-search-localisations="$ctrl.updateSearchLocalisations"
                       options="{disableSecondaryCriteria: true, disabledFields: {
                            fieldsetSource: true,
                            fieldsetTypeTransaction: true,
                            fieldsetNatures: true,
                            fieldsetCession: true,
                            fieldsetConstruction: true,
                            searchByAdresse: true
                         }}">
  </esk-search-criteria>

  <button type="button" ng-click="$ctrl.launch()" class="btn btn-primary pull-right btn-save"
          ng-disabled="$ctrl.saving" ng-switch="$ctrl.saving">
    <span ng-switch-when="true">
      <fa-icon [icon]="'spinner'" animation="spin"></fa-icon> Enregistrement des critères
    </span>

    <span ng-switch-default><fa-icon [icon]="'check'"></fa-icon> Appliquer les critères</span>
  </button>
</div>
`;
