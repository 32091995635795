export default `
<div class="share-documents">
  <div class="modal-header">
    <button type="button" class="btn btn-default pull-right" ng-click="$dismiss()"
            title="Fermer sans prendre en compte les modifications"><fa-icon [icon]="'times'"></fa-icon></button>

    <h3 class="modal-title"><fa-icon [icon]="'share-alt'"></fa-icon> {{'bien.documents.share.TITLE' | translate}}</h3>
  </div>

  <div class="modal-body">
    <!-- Ajout de contacts -->
    <ng-switch on="$ctrl.options.typeAddContacts" class="share-add-contact">
      <!-- Recherche d'un contact existant -->
      <div ng-switch-default class="tw:flex tw:flex-col tw:sm:flex-row tw:gap-2 tw:items-end tw:sm:items-center">
        <esk-contacts-search contacts-selected="$ctrl.contacts.collection" on-select="$ctrl.addContact"
                             class="tw:flex-1 tw:w-full" options="$ctrl.options.contactsSearch">
        </esk-contacts-search>

        <button type="button" class="tw:btn-tertiary-info tw:pr-0" ng-click="$ctrl.changeTypeAddContacts('CONTACT_NEW')">
          <fa-icon [icon]="'plus'"></fa-icon> <span>Nouveau contact</span>
        </button>
      </div>

      <!-- Création d'un contact -->
      <div ng-switch-when="CONTACT_NEW" class="row add-contact-new">
        <form name="$ctrl.editForm" ng-submit="$ctrl.saveNewContact()" novalidate class="col-xs-12 col-sm-9 col-md-10">
          <div class="row">
            <div class="col-xs-12 col-sm-9 col-md-10 new-fields">
              <div class="row">
                <div class="col-xs-4">
                  <div class="form-group"
                       ng-class="{'has-error': $ctrl.editForm.$submitted && $ctrl.editForm.personNom.$invalid}">
                    <label for="edit-contact-person-nom">{{'Nom' | translate}} *</label>

                    <input soq-capitalize-input="1" type="text" name="personNom" id="edit-contact-person-nom"
                           class="form-control" ng-model="$ctrl.currentContact.person.getNom"
                           ng-model-options="{getterSetter: true}" required>

                    <div class="help-block" ng-messages="$ctrl.editForm.personNom.$error"
                         ng-if="$ctrl.editForm.$submitted && $ctrl.editForm.$invalid">
                      <ng-messages-include src="messages.html"></ng-messages-include>
                    </div>
                  </div>
                </div>

                <div class="col-xs-4">
                  <div class="form-group"
                       ng-class="{'has-error': $ctrl.editForm.$submitted && $ctrl.editForm.personPrenom.$invalid}">
                    <label for="edit-contact-person-prenom">{{'Prénom' | translate}} *</label>

                    <input soq-capitalize-input="1" type="text" name="personPrenom" id="edit-contact-person-prenom"
                           class="form-control" ng-model="$ctrl.currentContact.person.getPrenom"
                           ng-model-options="{getterSetter: true}" required>

                    <div class="help-block" ng-messages="$ctrl.editForm.personPrenom.$error"
                         ng-if="$ctrl.editForm.$submitted && $ctrl.editForm.$invalid">
                      <ng-messages-include src="messages.html"></ng-messages-include>
                    </div>
                  </div>
                </div>

                <div class="col-xs-4">
                  <div class="form-group"
                       ng-class="{'has-error': $ctrl.editForm.$submitted && $ctrl.editForm.personEmailAddress.$invalid}">
                    <label for="edit-contact-person-email">{{'Email' | translate}} *</label>

                    <input type="email" name="personEmailAddress" id="edit-contact-person-email" class="form-control"
                           ng-model="$ctrl.currentContact.person.emailAddress" required>

                    <div class="help-block" ng-messages="$ctrl.editForm.personEmailAddress.$error"
                         ng-if="$ctrl.editForm.$submitted && $ctrl.editForm.$invalid">
                      <ng-messages-include src="messages.html"></ng-messages-include>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <ng-switch on="$ctrl.options.loader.contactSaving" class="col-xs-12 col-sm-3 col-md-2">
              <p ng-switch-when="true">
                <fa-icon [icon]="'spinner'" animation="spin"></fa-icon> {{'global.en_cours.SAVE' | translate}}
              </p>

              <div ng-switch-default>
                <button class="btn btn-primary" type="submit">
                  <fa-icon [icon]="'check'"></fa-icon> Enregistrer
                </button>
              </div>
            </ng-switch>
          </div>
        </form>

        <div class="col-xs-12 col-sm-3 col-md-2">
          <button type="button" class="btn btn-link" ng-click="$ctrl.changeTypeAddContacts('CONTACT_EXISTING')">
            <fa-icon [icon]="'search'"></fa-icon> Contact existant
          </button>
        </div>
      </div>
    </ng-switch>

    <!-- Liste des contacts -->
    <div ng-if="$ctrl.contacts.totalItems > 0" class="row">
      <div class="col-xs-12">
        <hr>

        <esk-contacts-list contacts="$ctrl.contacts" unselect-all="$ctrl.unselectAll" options="$ctrl.options">
        </esk-contacts-list>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <p ng-if="$ctrl.documents.length > 1" class="pull-left tw:text-left"
       ng-bind-html="'bien.documents.share.MULTIPLE_MESSAGE' | translate:'{nbDocuments: ' + $ctrl.documents.length + '}'">
    </p>

    <button type="button" class="tw:btn-primary-info" ng-click="$ctrl.share()">
      <fa-icon [icon]="'share-alt'"></fa-icon> <span>{{'global.label.SHARE' | translate}}</span>
    </button>
  </div>
</div>
`;
