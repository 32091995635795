export default `
<div ng-if="!!$ctrl.currentEtude">
  <div class="row">
    <!-- Responsable du demandeur -->
    <div class="col-sm-6 col-md-3"
         ng-class="{'has-error': $ctrl.editForm.$submitted && $ctrl.editForm.responsableDossier.$invalid}">
      <div class="form-group">
        <label>Responsable dossier</label>

        <esk-select-user roles="$ctrl.usersRoles" etudes="[$ctrl.currentEtude.id]" is-required="true"
                         ng-model="$ctrl.demandeur.responsableDossier" name="responsableDossier"></esk-select-user>

        <div class="error text-small tw:block" ng-messages="$ctrl.editForm.responsableDossier.$error"
             ng-show="$ctrl.editForm.$submitted">
          <ng-messages-include src="messages.html"></ng-messages-include>
        </div>
      </div>
    </div>

    <!-- Notaire responsable -->
    <div class="col-sm-6 col-md-3" ng-class="{'has-error': $ctrl.editForm.$submitted && $ctrl.editForm.notaire.$invalid}">
      <div class="form-group">
        <label>Notaire</label>

        <esk-select-user roles="[$ctrl.roleNotaire]" etudes="[$ctrl.currentEtude.id]" name="notaire"
                         ng-model="$ctrl.demandeur.notaire"></esk-select-user>

        <div class="error text-small tw:block" ng-messages="$ctrl.editForm.notaire.$error"
             ng-show="$ctrl.editForm.$submitted">
          <ng-messages-include src="messages.html"></ng-messages-include>
        </div>
      </div>
    </div>

    <!-- Envoi automatique des biens correspondants -->
    <div ng-if="!$ctrl.options.envoiResults.hide" class="demandeur-envoi-auto-results">
      <div class="checkbox no-marge-padding clip-check check-purple">
        <input type="checkbox" id="demandeur-envoi-auto" ng-model="$ctrl.demandeur.accepteEnvoiAutomatique">

        <label for="demandeur-envoi-auto">Envoi automatique des biens correspondants</label>

        <a popover-trigger="'outsideClick'" popover-placement="right" class="btn btn-o btn-null"
           uib-popover-template="'src/app/legacy/templates/demandeurs/components/item/information.edit/envoiresults.help.html'">
          <span class="badge badge-info">?</span>
        </a>
      </div>
    </div>
  </div>

  <div class="row">
    <!-- Type de demandeur -->
    <div ng-if="!$ctrl.options.typeDemandeur.hide" class="col-sm-6 col-md-3">
      <div class="form-group">
        <label>Type de demandeur</label>

        <esk-select-dictionary ng-model="$ctrl.demandeur.type" dictionary="{{$ctrl.DEMANDEUR_TYPES}}">
        </esk-select-dictionary>
      </div>
    </div>

    <!-- Référence interne -->
    <div class="col-sm-6 col-md-3">
      <div class="form-group">
        <label for="bien-reference-interne">Référence interne</label>

        <input type="text" id="bien-reference-interne" class="form-control" ng-model="$ctrl.demandeur.referenceInterne">
      </div>
    </div>

    <!-- Référence Noty -->
    <div ng-if="!!$ctrl.demandeur.reference" class="col-sm-6 col-md-3">
      <div class="form-group">
        <label>Référence Noty</label> <p class="form-control-static">{{$ctrl.demandeur.reference}}</p>
      </div>
    </div>

    <!-- Ancienne référence -->
    <div ng-if="!!$ctrl.demandeur.ancienneReference" class="col-sm-6 col-md-3">
      <div class="form-group">
        <label>Ancienne référence</label> <p class="form-control-static">{{$ctrl.demandeur.ancienneReference}}</p>
      </div>
    </div>
  </div>

  <!-- Commentaires confidentiels -->
  <div class="row">
    <div class="col-sm-12">
      <div class="form-group">
        <label for="bien-comments-confidentiels">Renseignements confidentiels</label>

        <a popover-trigger="'outsideClick'" popover-placement="right" type="button" class="btn btn-o btn-null"
           uib-popover="Ces informations ne sont visibles que par votre étude et à l'impression de la fiche confidentielle.">
          <span class="badge badge-info">?</span>
        </a>

        <app-ng2-textarea (changed)="$ctrl.demandeur.metadata.confidentielles.comments = $event"
                          [text]="$ctrl.demandeur.metadata.confidentielles.comments"></app-ng2-textarea>
      </div>
    </div>
  </div>
</div>
`;
