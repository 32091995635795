export default `
<h3 class="description-title">Loyer, charges et frais</h3>

<div class="description-details">
  <div class="tw:text-center details-item">
    <div class="item-price">
      Loyer

      <span class="text-bold">
        {{'dictionaries.periodicites.' + $ctrl.dossier.periodiciteLoyer.label | translate:'{NB: 1}'}}
      </span>

      de

      <h4 ng-switch="$ctrl.unlockData" class="text-bold">
        <span ng-switch-when="true">
          {{+($ctrl.dossier.loyer.toString().replace(',', '.')) |
          currency:'€':2 * (+($ctrl.dossier.loyer.toString().replace(',', '.')) % 1 !== 0)}}
        </span>

        <span ng-switch-default>
          {{+($ctrl.dossier.loyerAffiche.toString().replace(',', '.')) |
          currency:'€':2 * (+($ctrl.dossier.loyerAffiche.toString().replace(',', '.')) % 1 !== 0)}}
        </span>
      </h4>
    </div>

    <div ng-if="$ctrl.dossier.loyerEncadrement">
      Zone soumise à encadrement des loyers

      <p ng-if="$ctrl.dossier.loyerVersement">Versement du loyer {{$ctrl.dossier.loyerVersement}}</p>
    </div>

    <div ng-if="!$ctrl.dossier.loyerEncadrement">
      <div ng-if="!!$ctrl.dossier.charges" class="item-price">
        {{$ctrl.dossier.chargesIncluses ? 'dont' : '+'}}

        <h4 class="text-bold">
          {{+($ctrl.dossier.charges.toString().replace(',', '.')) |
          currency:'€':2 * (+($ctrl.dossier.charges.toString().replace(',', '.')) % 1 !== 0)}}
        </h4>

        de charges

        <span class="text-bold">
          {{'dictionaries.periodicites.' + $ctrl.dossier.periodiciteCharges.label | translate: '{genre: "female", NB: 2}'}}
        </span>
      </div>

      <p ng-if="$ctrl.dossier.loyerVersement" class="item-price">Versement du loyer {{$ctrl.dossier.loyerVersement}}</p>
    </div>
  </div>

  <div ng-if="$ctrl.dossier.loyerEncadrement" class="details-item">
    <h4>Détails du prix</h4>

    <p class="row" ng-if="$ctrl.dossier.loyerBase">
      <span class="col-xs-6">Loyer de référence majoré</span>

      <span class="col-xs-6 text-right text-bold">
        {{+($ctrl.dossier.loyerReferenceMajore.toString().replace(',', '.')) |
        currency:'€':2 * (+($ctrl.dossier.loyerReferenceMajore.toString().replace(',', '.')) % 1 !== 0)}}
        {{'dictionaries.periodicites.' + $ctrl.dossier.periodiciteLoyer.label | translate:'{NB: 1}'}}
      </span>
    </p>

    <p class="row" ng-if="$ctrl.dossier.loyerBase">
      <span class="col-xs-6">Loyer de base</span>

      <span class="col-xs-6 text-right text-bold">
        {{+($ctrl.dossier.loyerBase.toString().replace(',', '.')) |
        currency:'€':2 * (+($ctrl.dossier.loyerBase.toString().replace(',', '.')) % 1 !== 0)}}
        {{'dictionaries.periodicites.' + $ctrl.dossier.periodiciteLoyer.label | translate:'{NB: 1}'}}
      </span>
    </p>

    <p class="row" ng-if="$ctrl.dossier.loyerComplement">
      <span class="col-xs-6">Complément de loyer</span>

      <span class="col-xs-6 text-right text-bold">
        {{+($ctrl.dossier.loyerComplement.toString().replace(',', '.')) |
        currency:'€':2 * (+($ctrl.dossier.loyerComplement.toString().replace(',', '.')) % 1 !== 0)}}
        {{'dictionaries.periodicites.' + $ctrl.dossier.periodiciteLoyer.label | translate:'{NB: 1}'}}
      </span>
    </p>

    <p class="row" ng-if="$ctrl.dossier.charges">
      <span class="col-xs-6">Charges {{ $ctrl.dossier.chargesIncluses ? 'comprises' : 'en sus' }}</span>

      <span class="col-xs-6 text-right text-bold">
        {{+($ctrl.dossier.charges.toString().replace(',', '.')) |
        currency:'€':2 * (+($ctrl.dossier.charges.toString().replace(',', '.')) % 1 !== 0)}}
        {{'dictionaries.periodicites.' + $ctrl.dossier.periodiciteCharges.label | translate:'{NB: 1}'}}
      </span>
    </p>
  </div>

  <div
    ng-if="!!$ctrl.dossier.honoRedacChargeLocataire || !!$ctrl.dossier.etatLieuxChargeLocataire || !!$ctrl.dossier.depotGarantie || ($ctrl.unlockData && (!!$ctrl.dossier.montantTotalDu || !!$ctrl.dossier.montantVersementLocataire))"
    class="details-item">
    <h4>À la charge du locataire</h4>

    <p class="row" ng-if="$ctrl.dossier.honoRedacChargeLocataire">
      <span class="col-xs-6">Honoraires de rédaction</span>

      <span class="col-xs-6 text-right text-bold">
        {{+($ctrl.dossier.honoRedacChargeLocataire.toString().replace(',', '.')) |
        currency:'€':2 * (+($ctrl.dossier.honoRedacChargeLocataire.toString().replace(',', '.')) % 1 !== 0)}}
      </span>
    </p>

    <p class="row" ng-if="$ctrl.dossier.etatLieuxChargeLocataire">
      <span class="col-xs-6">État des lieux</span>

      <span class="col-xs-6 text-right text-bold">
        {{+($ctrl.dossier.etatLieuxChargeLocataire.toString().replace(',', '.')) |
        currency:'€':2 * (+($ctrl.dossier.etatLieuxChargeLocataire.toString().replace(',', '.')) % 1 !== 0)}}
      </span>
    </p>

    <p class="row" ng-if="$ctrl.dossier.depotGarantie">
      <span class="col-xs-6">Dépôt de garantie</span>

      <span class="col-xs-6 text-right text-bold">
        {{+($ctrl.dossier.depotGarantie.toString().replace(',', '.')) |
        currency:'€':2 * (+($ctrl.dossier.depotGarantie.toString().replace(',', '.')) % 1 !== 0)}}
      </span>
    </p>

    <hr>

    <p class="row" ng-if="$ctrl.dossier.montantTotalDu">
      <span class="col-xs-6">Montant total dû</span>

      <span class="col-xs-6 text-right text-bold">
        {{+($ctrl.dossier.montantTotalDu.toString().replace(',', '.')) |
        currency:'€':2 * (+($ctrl.dossier.montantTotalDu.toString().replace(',', '.')) % 1 !== 0)}}
      </span>
    </p>

    <p class="row margin-top-10" ng-if="$ctrl.dossier.montantVersementLocataire">
      <span class="col-xs-6">Montant dû le premier mois</span>

      <span class="col-xs-6 text-right text-bold">
        {{+($ctrl.dossier.montantVersementLocataire.toString().replace(',', '.')) |
        currency:'€':2 * (+($ctrl.dossier.montantVersementLocataire.toString().replace(',', '.')) % 1 !== 0)}}
      </span>
    </p>
  </div>
  <div ng-if="!!$ctrl.unlockData" class="details-item">
    <div
      ng-if="!!$ctrl.dossier.honoNegoChargeProprietaire || !!$ctrl.dossier.honoRedacChargeProprietaire || !!$ctrl.dossier.etatLieuxChargeProprietaire"
      class="details">
      <h4>À la charge du propriétaire</h4>

      <p class="row" ng-if="$ctrl.dossier.honoNegoChargeProprietaire">
        <span class="col-xs-6">Honoraires de négociation</span>

        <span class="col-xs-6 text-right text-bold">
          {{+($ctrl.dossier.honoNegoChargeProprietaire.toString().replace(',', '.')) |
          currency:'€':2 * (+($ctrl.dossier.honoNegoChargeProprietaire.toString().replace(',', '.')) % 1 !== 0)}}
        </span>
      </p>

      <p class="row" ng-if="$ctrl.dossier.honoRedacChargeProprietaire">
        <span class="col-xs-6">Honoraires de rédaction</span>

        <span class="col-xs-6 text-right text-bold">
          {{+($ctrl.dossier.honoRedacChargeProprietaire.toString().replace(',', '.')) |
          currency:'€':2 * (+($ctrl.dossier.honoRedacChargeProprietaire.toString().replace(',', '.')) % 1 !== 0)}}
        </span>
      </p>

      <p class="row" ng-if="$ctrl.dossier.etatLieuxChargeProprietaire">
        <span class="col-xs-6">État des lieux</span>

        <span class="col-xs-6 text-right text-bold">
          {{+($ctrl.dossier.etatLieuxChargeProprietaire.toString().replace(',', '.')) |
          currency:'€':2 * (+($ctrl.dossier.etatLieuxChargeProprietaire.toString().replace(',', '.')) % 1 !== 0)}}
        </span>
      </p>
    </div>
  </div>

  <div ng-if="!!$ctrl.unlockData && !!$ctrl.dossier.fraisCautionnement" class="details-item">
    <p>Frais de cautionnement</p><p ng-bind-html="$ctrl.dossier.fraisCautionnement"></p>
  </div>
</div>
`;
