@if (!estimation.canEditRapport()) {
  <div class="tw:max-w-[40rem] tw:mx-auto tw:rounded-lg tw:bg-orange-50 tw:p-4 tw:flex tw:flex-col tw:gap-4">
    <h3 class="tw:text-center tw:font-semi-bold tw:text-gray-900">
      Génération {{ estimation.isEvaluation() ? 'du rapport d\'évaluation' : 'de l\'avis de valeur' }}
    </h3>

    <div class="tw:text-base tw:text-gray-600">
      Il manque des informations à votre bien en estimation afin de générer
      {{ estimation.isEvaluation() ? 'le rapport d\'évaluation' : 'l\'avis de valeur' }}.
      <div class="tw:pl-12">
        <ul>
          @if (estimation.isEvaluation() && !estimation.bien.commune) {
            <li>- La commune</li>
          }

          @if (estimation.isEvaluation() && !estimation.bien.codePostal) {
            <li>- Le code postal</li>
          }

          @if (estimation.isEvaluation() && !estimation.bien.hasLocalisation()) {
            <li>- L'adresse</li>
          }

          @if (!estimation.bien.hasSavedCadastres()) {
            <li>- Au moins une référence cadastrale</li>
          }

          @if (!estimation.bien.descriptif) {
            <li>- Le descriptif de l'annonce</li>
          }

          @if (!estimation.hasValeurVenale()) {
            <li>- La valeur vénale</li>
          }
        </ul>
      </div>
    </div>

    <p class="tw:text-center tw:text-sm">
      Complétez les informations dans la partie
      <a [routerLink]="'/app/estimations/' + estimation.id.toString() + '/edit/general'" class="tw:font-semibold">
        "Fiche" de votre bien en estimation
      </a>.
    </p>
  </div>
}
