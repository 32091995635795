<div class="tw:second-nav">
  <div class="tw:second-nav-menus"><h1 class="tw:second-nav-menus-title">Rechercher un utilisateur</h1></div>

  <div class="tw:second-nav-content tw:bg-gray-100">
    <div class="tw:h-full tw:flex">
      <div class="tw:overflow-y-auto tw:h-full tw:w-full tw:p-8">
        <div class="tw:max-w-7xl tw:mx-auto tw:flex tw:flex-col tw:gap-4">
          <form class="tw:rounded-lg tw:bg-white tw:p-4 tw:flex tw:flex-col tw:gap-4">
            <div class="tw:text-lg tw:font-medium tw:leading-6 tw:text-gray-900">Filtres</div>

            <div class="tw:flex tw:flex-col tw:md:flex-row tw:gap-4 tw:sm:gap-0 tw:sm:space-x-4 tw:md:space-x-8">
              <div class="tw:max-w-sm tw:sm:w-80">
                <label class="tw:block tw:mb-2 tw:text-sm tw:text-gray-700">Communes</label>

                <app-communes-select [ids]="filter.queryParameters.commune?.values!"
                                     [options]="{placeholder: 'Rechercher par nom ou code postal'}"
                                     (selected)="communesSelected($event)"/>
              </div>

              <div class="tw:max-w-sm tw:sm:w-80">
                <label class="tw:block tw:mb-2 tw:text-sm tw:text-gray-700">Mots clés</label>

                <app-form-search-input [model]="filter.queryParameters.keywords!"
                                       (searchedText)="searchedText($event)"/>
              </div>
            </div>
          </form>

          <app-users-list [cUsers$]="cUsers$" class="tw:block"/>
        </div>
      </div>
    </div>
  </div>
</div>
