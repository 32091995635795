import angularJS from '@shared/angularJS/global.ng';
import {filter} from 'lodash';
import {IModule, IScope} from 'angular';
import {NgSoqModalesManager} from '@legacy/app/soqrate/soqrate';
import {RouterStateForNgService} from '@shared/angularJS/down-ng2/router-state-for-ng.service';
import {NgEmailManager} from '@legacy/app/managers/managers';
import {NgContact, NgPersonEntreprise} from '@legacy/app/managers/ressources';
import {ModalService} from '@shared/modal/modal.service';
import {switchMap, take, tap} from 'rxjs/operators';
import {of} from 'rxjs';
import {ContactFactory} from '@models/contacts/contact/contact.factory';
import EmailRecipient from '@models/emails/email/recipients/recipient/email-recipient.model';

export default function getContactsList(module: IModule): void {
    (function (angular) {
        'use strict';

        /**
         * @example <esk-contacts-list></esk-contacts-list>
         * @param options : {click(function), loader: {updating(bool)}, nameDossiers(string), select(function), visibleFields(object)}
         * @param options.visibleFields : {edition(bool), select(bool)}
         */
        module.component('eskContactsList', {
            bindings: {contacts: '=', unselectAll: '=?', options: '=',},
            controller: Controller,
            templateUrl: 'src/app/legacy/templates/contacts/components/list.html'
        });

        /**
         * Controller for contacts lists
         *
         * @param $location
         * @param $scope
         * @param SoqModalesManager
         * @param EmailManager
         * @param Ng2RouterStateForNgService
         * @param Ng2ModalService
         * @param Ng2ContactFactory
         */
        Controller.$inject = ['$scope', 'SoqModalesManager', 'EmailManager', 'Ng2RouterStateForNgService', 'Ng2ModalService', 'Ng2ContactFactory'];
        function Controller(this: any,
                            $scope: IScope,
                            soqModalesManager: NgSoqModalesManager,
                            emailManager: NgEmailManager,
                            ng2RouterStateForNgService: RouterStateForNgService,
                            ng2ModalService: ModalService,
                            ng2ContactFactory: ContactFactory) {
            const $ctrl = this;

            $ctrl.$onInit = $onInit;
            $ctrl.onSelectAll = onSelectAll;
            $ctrl.onSelect = onSelect;
            $ctrl.onEdit = onEdit;
            $ctrl.onDelete = onDelete;
            $ctrl.sendMail = sendMail;
            $ctrl.ng2RouterStateForNgService = ng2RouterStateForNgService;

            /**
             * Watch on contacts total items change
             */
            $scope.$watch('$ctrl.contacts', function () {
                if (angular.isObject($ctrl.contacts) && angular.isArray($ctrl.contacts.collection)) processSelectAll();
            });

            /**
             * Initialization method
             */
            function $onInit() {
                var listFields = ['edition', 'select'];

                if (!angular.isObject($ctrl.options)) $ctrl.options = {};
                if (!angular.isObject($ctrl.options.visibleFields)) $ctrl.options.visibleFields = {};

                angular.forEach(listFields, function (field) {
                    if ($ctrl.options.visibleFields[field] !== false) {
                        $ctrl.options.visibleFields[field] = true;
                    }
                });
                $ctrl.unselectAll = unselectAll;
            }

            /**
             * Select all contacts
             */
            function onSelectAll() {
                angular.forEach($ctrl.contacts.collection, function (contact) {
                    contact._esk.select = $ctrl.selectAll;
                    $ctrl.onSelect(contact, true);
                });
            }

            /**
             * Select contact
             *
             * @param contact
             * @param ignoreSelectAll
             */
            function onSelect(contact: NgContact, ignoreSelectAll: boolean) {
                if (!ignoreSelectAll) {
                    if (contact._esk.select) {
                        processSelectAll();
                    } else {
                        $ctrl.selectAll = false;
                    }
                }

                if (angular.isFunction($ctrl.select)) {
                    $ctrl.select(contact);
                }
            }

            /**
             * edit contact
             *
             * @param contact
             */
            function onEdit(contact: NgContact) {
                if (angular.isFunction($ctrl.options.click)) {
                    $ctrl.options.click(contact);
                } else if (angular.isString(contact._esk.routing)) {
                    $ctrl.ng2RouterStateForNgService.navigateByUrl(contact._esk.routing);
                } else {
                    contact._esk.select = !contact._esk.select;
                    $ctrl.onSelect(contact);
                }
            }

            /**
             * delete contact
             *
             * @param ngContact
             */
            function onDelete(ngContact: NgContact) {
                ng2ModalService.openConfirmation$({
                    buttonConfirmationLabel: 'Supprimer définitivement',
                    checkComments: true,
                    comments: 'Je confirme la suppression définitive et irréversible de ce contact. ',
                    question: 'En cliquant sur le bouton "Supprimer", cela entraînera la supression :' +
                        '<ul class="tw:!list-disc tw:!list-inside">' +
                            '<li>du contact et de ses données personnelles</li>' +
                            '<li>de ses liaisons avec les dossiers</li>' +
                            '<li>de ses liens avec les signatures électroniques</li>' +
                            '<li>...</li>' +
                        '</ul>' +
                        '<br/>' +
                        'Voulez-vous vraiment supprimer ce contact ?',
                    title: 'Supprimer un contact',
                    status: ModalService.statuts.DANGER,
                }).pipe(
                    switchMap(confirmation => {
                        if (!confirmation) {
                            return of(undefined);
                        }

                        return ng2ContactFactory.delete$(ng2ContactFactory.ngCreate(ngContact)).pipe(
                            tap(_ => {
                                $ctrl.contacts.collection = $ctrl.contacts.collection.filter(contact => contact.id !== ngContact.id);
                                $ctrl.contacts.total--;
                            }),
                        );
                    }),
                    take(1),
                ).subscribe()
            }

            /**
             * Send email for contact
             *
             * @param contact
             * @returns {*}
             */
            function sendMail(contact: NgContact) {
                soqModalesManager.open('EskModalEmail', {
                    resolve: {
                        email: emailManager.create({
                            recipients: [
                                {
                                    emailAddress: (contact[contact.type] as NgPersonEntreprise).emailAddress,
                                    type: EmailRecipient.types.TO,
                                    name: (contact[contact.type] as NgPersonEntreprise).convivialName,
                                },
                            ],
                        }),
                        currentDossier: {},
                    },
                });
            }

            /**
             * Define selectAll
             */
            function processSelectAll() {
                $ctrl.selectAll = filter($ctrl.contacts.collection, {_esk: {select: true}}).length === $ctrl.contacts.collection.length;
            }

            /**
             * Unselect all selected contacts
             */
            function unselectAll() {
                $ctrl.contacts.collection.forEach(contact => contact._esk.select = false);
                processSelectAll();
            }
        }
    })(angularJS);
}
