export default `
<div class="list-estimations">
  <div
    ng-if="$ctrl.estimations.totalItems > 0 && (!!$ctrl.options.visibleFields.select || !!$ctrl.options.nameDossiers)"
    class="padding-bottom-10 list-infos hidden-print">
    <span ng-if="$ctrl.options.visibleFields.select && $ctrl.options.displayMode!='table'"
          ng-include="'src/app/legacy/templates/eskimmo/components/list/select.html'" class="select-all"></span>

    <span ng-if="!!$ctrl.options.nameDossiers" class="text-justify">
      {{$ctrl.options.nameDossiers | translate:'{NB: ' + $ctrl.estimations.totalItems + '}'}}
    </span>

    <span ng-if="!!$ctrl.filter.nom">
      {{"dans" | translate}}

      <span class="text-emphasis text-bold">{{$ctrl.filter.nom | lowercase}}</span>
    </span>
  </div>

  <ng-switch on="$ctrl.options.loader.updating">
    <p class="tw:text-center text-extra-large" ng-switch-when="true">
      <fa-icon [icon]="'spinner'" animation="spin"></fa-icon> Mise à jour de vos biens en estimation
    </p>

    <ng-switch ng-switch-default on="$ctrl.options.displayMode" ng-if="$ctrl.estimations.totalItems > 0">
      <!-- Tableau -->
      <div ng-switch-default class="table-responsive">
        <table class="table table-hover table-biens">
          <thead>
          <tr>
            <th ng-if="$ctrl.options.visibleFields.select">
              <ng-include src="'src/app/legacy/templates/eskimmo/components/list/select.html'" class="select-all">
              </ng-include>
            </th>

            <th ng-if="$ctrl.options.visibleFields.photo"></th>

            <th class="tw:text-center">
              <esk-button-sort sort-type="{{$ctrl.options.typeFilter}}" sort-code="reference"
                               current-sort="$ctrl.filter.queryParameters.sort"
                               on-change-sort="$ctrl.onChangeSort">
              </esk-button-sort>
            </th>

            <th ng-if="$ctrl.options.visibleFields.statut" class="tw:text-center">{{'Statut' | translate}}</th>

            <th ng-if="$ctrl.options.visibleFields.nomContactsGroup" class="tw:text-center">
              <esk-button-sort sort-type="{{$ctrl.options.typeFilter}}" sort-code="nomProprietaire"
                               current-sort="$ctrl.filter.queryParameters.sort"
                               on-change-sort="$ctrl.onChangeSort">
              </esk-button-sort>
            </th>

            <th ng-if="$ctrl.options.visibleFields.nature" class="tw:text-center">{{'Nature' | translate}}</th>

            <th ng-if="$ctrl.options.visibleFields.localisation" class="tw:text-center">
              {{'Localisation' | translate}}
            </th>

            <th ng-if="$ctrl.options.visibleFields.valeurVenale" class="tw:text-center">
              <esk-button-sort sort-type="{{$ctrl.options.typeFilter}}" sort-code="valeurVenale"
                               current-sort="$ctrl.filter.queryParameters.sort"
                               on-change-sort="$ctrl.onChangeSort">
              </esk-button-sort>
            </th>

            <th ng-if="$ctrl.options.visibleFields.angleRight" class="hidden-print"></th>
          </tr>
          </thead>

          <tr class="line" ng-repeat="estimation in $ctrl.estimations.collection">
            <td ng-if="$ctrl.options.visibleFields.select" class="select hidden-print">
              <div class="checkbox clip-check check-primary">
                <input type="checkbox" id="table-biens-select-{{estimation.id}}"
                       ng-model="estimation._esk.select" ng-change="$ctrl.onSelect(estimation)">

                <label for="table-biens-select-{{estimation.id}}"></label>
              </div>
            </td>

            <td ng-if="$ctrl.options.visibleFields.photo" class="tw:text-center"
                ng-click="$ctrl.onClick(estimation)">
              <esk-biens-wallet-display-photo photo-link="estimation.bien._links.premierePhoto.href"
                                              routing="!!$ctrl.click ? '' : estimation._esk.routing">
              </esk-biens-wallet-display-photo>
            </td>

            <td class="tw:text-center">{{estimation.bien.reference}}</td>

            <td ng-if="$ctrl.options.visibleFields.statut" class="tw:text-center">
              <esk-estimation-label-statut estimation="estimation"></esk-estimation-label-statut>

              <div ng-if="estimation.statut === $ctrl.estimationStatuts.REMIS && !!estimation.dateRemise"
                   title="Remise" class="text-muted padding-top-10">
                <app-ng2-date [date]="estimation.dateRemise" format="shortDate"></app-ng2-date>
              </div>
            </td>

            <td ng-if="$ctrl.options.visibleFields.nomContactsGroup" class="col-nom">
              <div class="text ellipsis">{{estimation.bien.contactsGroup.nom}}</div>
            </td>

            <td ng-if="$ctrl.options.visibleFields.nature" class="tw:text-center">
              {{estimation.bien.nature.label}}
            </td>

            <td ng-if="$ctrl.options.visibleFields.localisation" class="col-adresse tw:text-center">
              <ng-switch on="!!estimation.bien.option.commune.fullname">
                <div ng-swicth-default
                     class="thin-text text-bold ellipsis">{{estimation.bien.option.commune.fullname}}</div>

                <div ng-switch-when="false" class="text-muted">{{'Aucune commune renseignée' | translate}}</div>
              </ng-switch>

              <div ng-show="!!estimation.bien.option.quartier.name">{{estimation.bien.option.quartier.name}}</div>

              <div class="adresse ellipsis">
                <span ng-if="$ctrl.options.visibleFields.preciseLocalisation && !!estimation.bien.option.numeroVoie"
                      class="number">{{estimation.bien.option.numeroVoie}}</span>

                <span ng-if="$ctrl.options.visibleFields.preciseLocalisation && !!estimation.bien.option.nomVoie"
                      class="nom">{{estimation.bien.option.nomVoie}}</span>
              </div>
            </td>

            <td ng-if="$ctrl.options.visibleFields.valeurVenale" class=" tw:text-center">
              <esk-estimation-label-valeur estimation="estimation"></esk-estimation-label-valeur>
            </td>

            <td ng-if="$ctrl.options.visibleFields.angleRight" class="tw:text-center hidden-print angle-right"
                ng-click="$ctrl.onClick(estimation)">
              <a href="{{estimation._esk.routing}}"><div><fa-icon [icon]="'angle-right'"></fa-icon></div></a>
            </td>
          </tr>
        </table>
      </div>

      <!-- Liste -->
      <ul ng-switch-when="table-photo" class="list-group table-photo-biens">
        <li class="list-group-item line" ng-repeat="estimation in $ctrl.estimations.collection">
          <div class="row">
            <div ng-if="$ctrl.options.visibleFields.select" class="col-xs-1 col-sm-1">
              <div class="select">
                <div class="checkbox clip-check check-primary">
                  <input type="checkbox" id="table-photo-biens-select-{{estimation.id}}"
                         ng-model="estimation._esk.select" ng-change="$ctrl.onSelect(estimation)">

                  <label for="table-photo-biens-select-{{estimation.id}}"></label>
                </div>
              </div>
            </div>

            <div
              ng-class="{'col-xs-11 col-sm-11': $ctrl.options.visibleFields.select, 'col-xs-12 col-sm-12': !$ctrl.options.visibleFields.select}">
              <div ng-if="$ctrl.options.visibleFields.photo" class="photo-display" ng-click="$ctrl.onClick(estimation)">
                <esk-biens-wallet-display-photo photo-link="estimation.bien._links.premierePhoto.href"
                                                routing="!!$ctrl.click ? '' : estimation._esk.routing">
                </esk-biens-wallet-display-photo>
              </div>

              <div class="informations">
                <div class="margin-bottom-10 resume">
                  <span ng-if="$ctrl.options.visibleFields.nature" class="label label-nature">
                    {{estimation.bien.nature.label}}
                  </span>

                  <span ng-if="$ctrl.options.visibleFields.statut">
                    <esk-estimation-label-statut estimation="estimation"></esk-estimation-label-statut>
                  </span>

                  <esk-estimation-label-valeur ng-if="$ctrl.options.visibleFields.valeurVenale"
                                               estimation="estimation"></esk-estimation-label-valeur>
                </div>

                <div class="margin-bottom-10">
                  <div class="second-line">
                    <span class="tw:text-center margin-right-5 pull-left">
                      {{estimation.bien.reference}}
                    </span>

                    <span ng-if="$ctrl.options.visibleFields.localisation" class="localisation">
                      <fa-icon [icon]="'map-marker-alt'" size="lg"></fa-icon>

                      <p
                        ng-if="$ctrl.options.visibleFields.preciseLocalisation && !!estimation.bien.option.numeroVoie">{{estimation.bien.option.numeroVoie}}</p>

                      <p ng-if="$ctrl.options.visibleFields.preciseLocalisation && !!estimation.bien.option.nomVoie"
                         class="adresse ellipsis">{{estimation.bien.option.nomVoie}}</p>

                      <ng-switch on="!!estimation.bien.option.commune.fullname">
                        <p ng-swicth-default class="text-bold">{{estimation.bien.option.commune.fullname}}</p>

                        <p ng-switch-when="false" class="text-muted">{{'Aucune commune renseignée' | translate}}</p>
                      </ng-switch>

                      <p ng-show="!!estimation.bien.option.codePostal">{{estimation.bien.option.codePostal}}</p>

                      <p ng-show="!!estimation.bien.option.quartier.name">{{estimation.bien.option.quartier.name}}</p>
                    </span>
                  </div>
                </div>

                <div class="margin-bottom-10">
                  <h5 ng-if="$ctrl.options.visibleFields.nomContactsGroup"
                      class="margin-bottom-10 ellipsis">{{estimation.bien.contactsGroup.nom}}</h5>

                  <div class="margin-bottom-10 ellipsis">{{estimation.bien.titre}}</div>

                  <div class="descriptif margin-bottom-10 ellipsis">{{estimation.bien.descriptif}}</div>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>

      <!-- Vignettes -->
      <div ng-switch-when="collection" class="collection-biens">
        <div class="content"
             ng-class="{private: $ctrl.options.visibleFields.nomContactsGroup, public: !$ctrl.options.visibleFields.nomContactsGroup}"
             ng-repeat="estimation in $ctrl.estimations.collection">
          <div class="thumbnail">
            <div class="content-photo">
              <div class="row photo-header">
                <div class="col-xs-2">
                  <div ng-if="$ctrl.options.visibleFields.select" class="select text-right">
                    <div class="checkbox clip-check check-primary">
                      <input type="checkbox" id="collection-biens-select-{{estimation.id}}"
                             ng-model="estimation._esk.select" ng-change="$ctrl.onSelect(estimation)">

                      <label for="collection-biens-select-{{estimation.id}}"></label>
                    </div>
                  </div>
                </div>
              </div>

              <div class="photo-price">
                <esk-estimation-label-valeur ng-if="$ctrl.options.visibleFields.valeurVenale"
                                             estimation="estimation"></esk-estimation-label-valeur>
              </div>

              <div ng-click="$ctrl.onClick(estimation)">
                <esk-biens-wallet-display-photo ng-if="$ctrl.options.visibleFields.photo"
                                                photo-link="estimation.bien._links.premierePhoto.href"
                                                routing="!!$ctrl.click ? '' : estimation._esk.routing">
                </esk-biens-wallet-display-photo>
              </div>
            </div>

            <div ng-if="$ctrl.options.visibleFields.localisation" ng-switch="!!estimation.bien.option.commune.fullname"
                 class="localisation">
              <div ng-swicth-default
                   class="text-bold tw:text-center text-extra-large commune ellipsis">{{estimation.bien.option.commune.fullname}}</div>

              <div ng-switch-when="false"
                   class="text-muted tw:text-center">{{'Aucune commune renseignée' | translate}}</div>

              <div class="margin-top-10 tw:text-center" ng-show="estimation.bien.option.quartier">
                <p>
                  {{"Quartier" | translate}} : <span class="text-bold">{{estimation.bien.option.quartier.name}}</span>
                </p>
              </div>
            </div>

            <div ng-if="$ctrl.options.visibleFields.nomContactsGroup"
                 class="owners ellipsis">{{estimation.bien.contactsGroup.nom}}</div>

            <div class="last-line">
              <div ng-if="$ctrl.options.visibleFields.statut" class="statut">
                <esk-estimation-label-statut estimation="estimation"></esk-estimation-label-statut>
              </div>

              <div class="dossier">
                <span class="text-bold">{{estimation.bien.reference}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Box -->
      <ul ng-switch-when="box" class="list-group-pimped box-biens">
        <li class="list-pimped list-unstyled line" ng-repeat="estimation in $ctrl.estimations.collection">
          <div class="media">
            <div class="media-left">
              <esk-biens-wallet-display-photo ng-if="$ctrl.options.visibleFields.photo"
                                              photo-link="estimation.bien._links.premierePhoto.href"
                                              routing="!!$ctrl.click ? '' : estimation._esk.routing"
                                              ng-click="$ctrl.onClick(estimation)">
              </esk-biens-wallet-display-photo>

              <div class="tw:text-center">
                <span class="text-bold">{{estimation.bien.reference}}</span>
              </div>
            </div>

            <div class="media-body informations">
              <div class="margin-bottom-10 resume">
                <esk-estimation-label-valeur ng-if="$ctrl.options.visibleFields.valeurVenale"
                                             estimation="estimation"></esk-estimation-label-valeur>

                <span ng-if="$ctrl.options.visibleFields.nature" class="label label-nature">
                  {{estimation.bien.nature.label}}
                </span>

                <span ng-if="$ctrl.options.visibleFields.statut">
                  <esk-estimation-label-statut estimation="estimation"></esk-estimation-label-statut>
                </span>
              </div>

              <div ng-if="$ctrl.options.visibleFields.localisation" class="row margin-bottom-10">
                <div class="col-sm-12 localisation">
                  <fa-icon [icon]="'map-marker-alt'" size="lg"></fa-icon>

                  <p ng-if="$ctrl.options.visibleFields.preciseLocalisation && !!estimation.bien.option.numeroVoie">
                    {{estimation.bien.option.numeroVoie}}
                  </p>

                  <p ng-if="$ctrl.options.visibleFields.preciseLocalisation && !!estimation.bien.option.nomVoie"
                     class="adresse">{{estimation.bien.option.nomVoie}}</p>

                  <ng-switch on="!!estimation.bien.option.commune.fullname">
                    <p ng-swicth-default class="text-bold">
                      {{estimation.bien.option.commune.fullname}}
                    </p>

                    <p ng-switch-when="false" class="text-muted">
                      {{'Aucune commune renseignée' | translate}}
                    </p>
                  </ng-switch>

                  <p ng-show="!!estimation.bien.option.codePostal">
                    {{estimation.bien.option.codePostal}}
                  </p>

                  <p ng-show="!!estimation.bien.option.quartier.name">
                    {{estimation.bien.option.quartier.name}}
                  </p>
                </div>
              </div>

              <div class="row margin-bottom-10">
                <div class="col-sm-12">
                  <div>
                    <h5 ng-if="$ctrl.options.visibleFields.nomContactsGroup"
                        class="margin-bottom-10">{{estimation.bien.contactsGroup.nom}}</h5>

                    <div class="margin-bottom-10">{{estimation.bien.titre}}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </ng-switch>
  </ng-switch>
</div>
`;
