export default `
<div class="container container-footer">
  <div class="row">
    <div class="col-logo col-xs-12 col-sm-6">
      <h6><app-ng2-img [src]="'assets/images/noty/logo.svg'"></app-ng2-img></h6><hr>

      <p>
        <app-noteo-icon-text-noty [src-image]="''"></app-noteo-icon-text-noty> est une solution dédiée à la
        négociation notariale<br>proposée par
        <a href="https://www.septeo.com/fr/offres/notaire-logiciel-negociation-immobiliere-noteo" target="_blank">
          Noteo
        </a>
      </p>
    </div>

    <div class="col-xs-12 col-sm-6">
      <h6 class="text-uppercase font-weight-bold">Nous contacter</h6><hr>

      <div>
        <div class="col-icon"><fa-icon [icon]="'house'"></fa-icon></div>

        <div class="col-info">Noteo Groupe Septeo<br>194 avenue de la Gare Sud de France   34970 LATTES</div>

        <div class="col-icon"><fa-icon [icon]="'envelope'"></fa-icon></div>

        <div class="col-info">
          <a href="mailto:{{$ctrl.COMMERCE.emailAddress}}" target="_blank">{{$ctrl.COMMERCE.emailAddress}}</a>
        </div>

        <div class="col-icon"><fa-icon [icon]="'phone'"></fa-icon></div>

        <div class="col-info">
          <a href="tel:{{$ctrl.COMMERCE.phone}}">
            <fa-icon ng-if="$ctrl.icon" [icon]="'phone'"></fa-icon> {{$ctrl.COMMERCE.phoneConvivial}}
          </a>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="footer-copyright tw:text-center tw:py-3">
  © {{$ctrl.currentYear}} Copyright :
  <a href="https://www.septeo.com/fr/offres/notaire-logiciel-negociation-immobiliere-noteo" target="_blank"> Noteo</a>
</div>
`;
