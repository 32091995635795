export default `
<div ng-if="$ctrl.contactsGroup" class="tw:flex tw:flex-col tw:gap-8">
  <ul class="list-group collection-sortable-ul tw:flex tw:flex-wrap tw:justify-center tw:gap-4"
      ng-model="$ctrl.contactsGroup.members" ui-sortable="$ctrl.uiSortableOptions"
      ng-show="$ctrl.contactsGroup.members.length > 0">
    <li ng-repeat="member in $ctrl.contactsGroup.members"
        class="list-group-item collection-sortable tw:w-[calc(100%_-_1rem)] tw:md:w-[calc(50%_-_1rem)] tw:xl:w-[calc(33%_-_1rem)] tw:2xl:w-[calc(25%_-_1rem)] tw:bg-gray-50 tw:rounded-md"
        style="background-color: rgb(249 250 251/1); border: none; display: flex; margin: 0; padding: 0; user-select: none; -webkit-user-drag: element;">
      <div class="collection-sortable-dragger text-muted drag-handler item-action handler-precise"
           style="align-items: center; display: flex; position: initial">
        <div title="Modifier l'ordre des contacts">:::</div>
      </div>

      <div class="tw:flex-1 tw:flex tw:items-center tw:pr-1 tw:w-px">
        <app-contact-card [contact]="member.contact" class="tw:flex-1 tw:py-2 tw:overflow-hidden"
                          [options]="{enabledClick: false}" (clicked)="$ctrl.editContact($event)"></app-contact-card>

        <button class="tw:btn-secondary-info tw:bg-transparent tw:shadow-none tw:ring-0 tw:h-full" type="button"
                ng-click="$ctrl.actionsMember($event, member)">
          <fa-icon [icon]="'ellipsis-v'" size="xl"></fa-icon>
        </button>
      </div>
    </li>
  </ul>

  <div class="tw:flex tw:justify-center">
    <button type="button" class="tw:btn-primary-info" ng-click="$ctrl.openSearchModal()">
      Rechercher ou créer un contact
    </button>

<!-- En commentaire suite à l'incompréhension pour les utilisateurs, demander à l'équipe si il faut le remettre
    <app-search-open (clicked)="$ctrl.openSearchModal()" class="tw:w-full tw:sm:w-2/3 tw:md:w-1/2 tw:lg:w-1/3"
                     [options]="{placeHolder: 'Rechercher ou créer un contact'}"></app-search-open>
-->
  </div>

  <hr>

  <div class="row">
    <div class="col-xs-12 col-sm-6 col-md-3 col-lg-2"
         ng-class="{'has-error': $ctrl.editForm.$submitted && $ctrl.editForm.contactsGroupNom.$invalid}">
      <label for="edit-contacts-group-nom">Nom du dossier *</label>

      <input id="edit-contacts-group-nom" name="contactsGroupNom" placeholder="Nom du dossier" type="text"
             class="tw:input-text" ng-model="$ctrl.contactsGroup.nom" ng-change="$ctrl.onChangeNom()"
             required maxlength="140">

      <div class="error text-small tw:block" ng-messages="$ctrl.editForm.contactsGroupNom.$error"
           ng-show="$ctrl.editForm.$submitted">
        <ng-messages-include src="messages.html"></ng-messages-include>
      </div>
    </div>

    <div class="col-xs-12 col-sm-6 col-md-3 col-lg-2"
         ng-class="{'has-error': $ctrl.editForm.$submitted && $ctrl.editForm.contactsGroupType.$invalid}">
      <label>Type de dossier *</label>

      <esk-select-dictionary dictionary="{{$ctrl.CONTACTS_GROUP_TYPES}}" ng-model="$ctrl.typeGroup" is-required="true"
                             name="contactsGroupType" on-select="$ctrl.onSelectTypeGroup"></esk-select-dictionary>

      <div class="help-block" ng-messages="$ctrl.editForm.contactsGroupType.$error" ng-if="$ctrl.editForm.$submitted">
        <ng-messages-include src="messages.html"></ng-messages-include>
      </div>
    </div>
  </div>
</div>
`;
