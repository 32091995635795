export default `
<div class="wallet">
  <div class="container-fluid container-fluid-title"><h1>Mon portefeuille de contacts</h1></div>

  <div class="container-fluid container-fullw tw:bg-white current-wallet">
    <div class="noty-container">
      <!-- Actions sur la liste -->
      <div class="margin-bottom-10 hidden-print">
        <div class="tw:flex tw:flex-col tw:md:flex-row tw:justify-between">
            <div>
                <!-- Recherche -->
                <esk-search-keywords help="'src/app/legacy/templates/contacts/keywords.help.html'"
                                     reset-keywords="$ctrl.resetKeywords"
                                     on-change-filter="$ctrl.onChangeFilter"></esk-search-keywords>

        <!-- @todo Il faut un formulaire Angular pour "app-form-search-input" et ensuite eskSearchKeywords pourra être supprimé
                <app-form-search-input (searchedText)="$ctrl.onChangeFilter($event)"></app-form-search-input>

                <soq-popover-template [template]="'src/app/legacy/templates/contacts/keywords.help.html'"
                                      [display]="'default'" [trigger]="'outsideClick'"></soq-popover-template>
        -->

                <div class="checkbox clip-check check-primary checkbox-inline only-mine">
                  <input type="checkbox" id="contacts-with-invalid-email-address"
                         ng-model="$ctrl.contactsWithInvalidEmailAddress" ng-change="$ctrl.onChangeCWIEA()">

                  <label for="contacts-with-invalid-email-address">
                    Seulement les contacts avec une adresse email qui n'existe pas
                  </label>
                </div>
            </div>

            <div>
                <button type"button" class="tw:btn-primary-info" ng-click="$ctrl.createContact()">
                    Créer un contact
                </button>
            </div>
        </div>

        <!-- No results -->
        <h4 ng-if="$ctrl.contacts.totalItems <= 0" class="margin-top-30 tw:text-center">
          Aucun contact à afficher actuellement, vérifiez que les filtres ne sont pas trop restrictifs.
        </h4>
      </div>

      <!-- Liste des contacts -->
      <esk-contacts-list contacts="$ctrl.contacts" unselect-all="$ctrl.unselectAll" options="$ctrl.options">
      </esk-contacts-list>

      <!-- Pagination -->
      <div class="tw:text-center">
        <esk-pagination ng-hide="$ctrl.options.loader.updating" paginator="$ctrl.contacts"
                        loader="$ctrl.options.loader"></esk-pagination>
      </div>
    </div>
  </div>
</div>
`;
