export default `
<div class="container-fluid">
  <div class="container">
    <div class="row">
      <div class="tw:text-center col-sm-offset-2 col-sm-8">
        <h1>Vous utilisez le navigateur <span class="text-bold">{{$ctrl.browserConvivialName}}</span>.</h1>

        <h3 class="text-bold">Ce navigateur n'est pas compatible avec</h3>

        <div class="tw:my-8 tw:bg-septeo-blue-900 tw:rounded-2xl tw:shadow-lg tw:shadow-septeo-blue-900 tw:text-center">
            <app-ng2-img [src]="'assets/images/noty/logo.svg'" [width]="'60%'"></app-ng2-img>
        </div>
      </div>

      <div class="col-sm-offset-3 col-sm-6">
        <div class="padding-bottom-30">
          <div class="margin-top-15 margin-bottom-30 padding-15">
            <div class="browser-current">
              Vous utilisez actuellement <span class="text-bold">{{$ctrl.browserConvivialName}}</span>
              en version <span class="text-bold">{{$ctrl.ng2DeviceDetectorService.browser_version}}</span>.
            </div>

            <div ng-if="$ctrl.ng2DeviceDetectorService.browser === 'IE'">
              Internet Explorer n'est plus mis à jour depuis 2013.<br>Pour des raisons évidentes de sécurité et de
              performances, <app-noteo-icon-text-noty></app-noteo-icon-text-noty> n'est pas compatible avec ce
              navigateur.
            </div>

            <div class="browser-tips">
              <hr>

              <p>
                Nous vous conseillons de télécharger
                <a href="https://www.google.fr/chrome" target="_blank">
                  <fa-icon [icon]="['fab', 'chrome']"></fa-icon> Chrome
                </a>
                ou
                <a href="https://www.mozilla.org/fr/firefox" target="_blank">
                  <fa-icon [icon]="['fab', 'firefox']"></fa-icon> Firefox
                </a>
                pour utiliser <app-noteo-icon-text-noty></app-noteo-icon-text-noty>.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<esk-footer></esk-footer>
`;
