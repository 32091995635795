export default `
<div class="calculator">
  <div class="modal-header">
    <button type="button" class="btn btn-default pull-right" ng-click="$dismiss()" title="Fermer">
      <fa-icon [icon]="'times'"></fa-icon>
    </button>

    <h3 class="modal-title"><fa-icon [icon]="'calculator'"></fa-icon> Calculatrice</h3>
  </div>

  <div class="modal-body">
    <!-- Saisie -->
    <div class="row calculator-form" ng-class="'calculator-' + $ctrl.input.typeVente.code">
      <!-- Type de charges -->
      <div class="col-xs-12 col-md-3">
        <label>{{"Type de vente" | translate}}</label>

        <div class="types-ventes">
          <div class="radio-inline" ng-repeat="typeVente in $ctrl.typesVente">
            <div class="radio clip-radio radio-primary">
              <input type="radio" id="typeVente{{typeVente.id}}" ng-model="$ctrl.input.typeVente" ng-value="typeVente"
                     ng-change="$ctrl.operate()">

              <label for="typeVente{{typeVente.id}}">{{typeVente.label}}</label>
            </div>
          </div>
        </div>
      </div>

      <!-- Prix -->
      <div class="col-xs-12 margin-top-10"
           ng-class="{'col-md-4': $ctrl.input.typeVente.code !== $ctrl.VIAGER, 'col-sm-6 col-md-2': $ctrl.input.typeVente.code === $ctrl.VIAGER}">
        <label
          for="prix">{{$ctrl.input.typeVente.code === $ctrl.VIAGER ? "Valeur du bien" : ($ctrl.input.typeVente.code === $ctrl.INTERACTIVE ? "Prix de réserve" : "Prix de vente") | translate}}</label>

        <div class="input-group input-group-number input-group-currency">
          <input type="text" id="prix" class="form-control" ng-model="$ctrl.input.price" soq-only-digits="{float: true}"
                 ng-change="$ctrl.operate()">

          <span class="input-group-addon">€</span>
        </div>
      </div>

      <!-- Bouquet -->
      <div ng-show="$ctrl.input.typeVente.code === $ctrl.VIAGER" class="col-xs-12 col-sm-6 col-md-2 margin-top-10">
        <label for="bouquet">Bouquet</label>

        <div class="input-group input-group-number input-group-currency">
          <input type="text" id="bouquet" class="form-control" ng-model="$ctrl.input.bouquet"
                 soq-only-digits="{float: true}" ng-change="$ctrl.operate()">

          <span class="input-group-addon">€</span>
        </div>
      </div>

      <!-- Nature -->
      <div class="col-xs-12 col-sm-6 col-md-3 margin-top-10">
        <label>{{'Nature'|translate}}</label>

        <esk-select-dictionary dictionary="{{$ctrl.NATURES}}" ng-model="$ctrl.input.nature" is-required="true"
                               on-select="$ctrl.operate"></esk-select-dictionary>
      </div>

      <!-- Commune -->
      <div class="col-xs-12 col-sm-6 col-md-3 margin-top-10">
        <label>Commune</label>

        <esk-select-commune ng-model="$ctrl.input.commune" is-required="true" on-select="$ctrl.operate">
        </esk-select-commune>
      </div>
    </div>

    <!-- Affichage prix -->
    <div ng-switch="$ctrl.loader.operate" class="margin-top-30 calculator-display">
      <p ng-switch-when="true" class="text-large tw:text-center">
        <fa-icon [icon]="'spinner'" animation="spin"></fa-icon> {{'global.en_cours.DEFAULT' | translate}}
      </p>

      <div ng-switch-default>
        <ng-switch on="!!$ctrl.pricings" ng-if="$ctrl.input.typeVente.code !== $ctrl.INTERACTIVE && $ctrl.input.typeVente.code !== $ctrl.VIAGER">
          <div ng-switch-when="true" class="row">
            <div ng-repeat="pricing in $ctrl.pricings" class="col-sm-6 calculator-col">
              <h4>{{pricing.typeCharges.label | translate}}</h4>

              <div class="calculator-line">
                {{"Prix net vendeur" | translate}}

                <span
                  class="text-bold pull-right">{{pricing.infosPricing.prixNV | currency:'€':2 * (pricing.infosPricing.prixNV % 1 !== 0)}}</span>
              </div>

              <div class="calculator-line">
                {{"Honoraires de négociation" | translate}}

                <span
                  class="text-bold pull-right">{{pricing.infosPricing.honoraires | currency:'€':2 * (pricing.infosPricing.honoraires % 1 !== 0)}}</span>
              </div>

              <div class="calculator-line" title="Prix net vendeur + Honoraires de négociation">
                {{"Prix HNI" | translate}}

                <span
                  class="text-bold pull-right">{{pricing.infosPricing.prixHNI | currency:'€':2 * (pricing.infosPricing.prixHNI % 1 !== 0)}}</span>
              </div>

              <div class="calculator-line" title="Honoraires de négociation / {{'Prix net vendeur' | translate}}">
                {{"Pourcentage des honoraires" | translate}}

                <span
                  class="text-bold pull-right">{{pricing.infosPricing.pourcentageHonoraires | currency:'%':2 * (pricing.infosPricing.pourcentageHonoraires % 1 !== 0)}}</span>
              </div>

              <div class="calculator-line">
                {{"Frais d'acte" | translate}}

                <span
                  class="text-bold pull-right">{{pricing.infosPricing.fraisActe | currency:'€':2 * (pricing.infosPricing.fraisActe % 1 !== 0)}}</span>
              </div>

              <div class="calculator-line" title="Prix honoraires de négociation inclus + Frais d'acte">
                {{"Prix tous frais compris" | translate}}

                <span
                  class="text-bold pull-right">{{pricing.infosPricing.prixTFC | currency:'€':2 * (pricing.infosPricing.prixTFC % 1 !== 0)}}</span>
              </div>
            </div>
          </div>

          <h4 ng-switch-default class="tw:text-center">
            {{"Saisissez un prix pour avoir le détail." | translate}}
          </h4>
        </ng-switch>

        <ng-switch on="!!$ctrl.pricings" ng-if="$ctrl.input.typeVente.code === $ctrl.INTERACTIVE">
          <div ng-switch-when="true" class="row">
            <div ng-repeat="pricing in $ctrl.pricings" class="col-sm-6 calculator-col">
              <h4>{{pricing.typeCharges.label | translate}}</h4>

              <div class="calculator-line">
                {{"Prix de réserve net vendeur" | translate}}

                <span
                  class="text-bold pull-right">{{pricing.infosPricing.prixReserveNV | currency:'€':2 * (pricing.infosPricing.prixReserveNV % 1 !== 0)}}</span>
              </div>

              <div class="calculator-line">
                {{"Honoraires de négociation" | translate}}

                <span
                  class="text-bold pull-right">{{pricing.infosPricing.honoraires | currency:'€':2 * (pricing.infosPricing.honoraires % 1 !== 0)}}</span>
              </div>

              <div class="calculator-line" title="Prix de réserve net vendeur + Honoraires de négociation">
                {{"Prix de réserve HNI" | translate}}

                <span
                  class="text-bold pull-right">{{pricing.infosPricing.prixReserveHNI | currency:'€':2 * (pricing.infosPricing.prixReserveHNI % 1 !== 0)}}</span>
              </div>

              <div class="calculator-line"
                   title="Honoraires de négociation / {{'Prix de réserve net vendeur' | translate}}">
                {{"Pourcentage des honoraires" | translate}}

                <span
                  class="text-bold pull-right">{{pricing.infosPricing.pourcentageHonoraires | currency:'%':2 * (pricing.infosPricing.pourcentageHonoraires % 1 !== 0)}}</span>
              </div>

              <div class="calculator-line">
                {{"Frais d'acte" | translate}}

                <span
                  class="text-bold pull-right">{{pricing.infosPricing.fraisActe | currency:'€':2 * (pricing.infosPricing.fraisActe % 1 !== 0)}}</span>
              </div>

              <div class="calculator-line" title="Prix de réserve honoraires de négociation inclus + Frais d'acte">
                {{"Prix de réserve tous frais compris" | translate}}

                <span
                  class="text-bold pull-right">{{pricing.infosPricing.prixReserveTFC | currency:'€':2 * (pricing.infosPricing.prixReserveTFC % 1 !== 0)}}</span>
              </div>
            </div>
          </div>

          <h4 ng-switch-default class="tw:text-center">
            {{"Saisissez un prix de réserve pour avoir le détail." | translate}}
          </h4>
        </ng-switch>

        <ng-switch on="!!$ctrl.pricings" ng-if="$ctrl.input.typeVente.code === $ctrl.VIAGER">
          <div ng-switch-when="true" class="row">
            <div ng-repeat="pricing in $ctrl.pricings" class="col-sm-6 calculator-col">
              <h4>{{pricing.typeCharges.label | translate}}</h4>

              <div class="calculator-line">
                {{"Bouquet net vendeur" | translate}}

                <span
                  class="text-bold pull-right">{{pricing.infosPricing.bouquetNV | currency:'€':2 * (pricing.infosPricing.bouquetNV % 1 !== 0)}}</span>
              </div>

              <div class="calculator-line">
                {{"Honoraires de négociation" | translate}}

                <span
                  class="text-bold pull-right">{{pricing.infosPricing.honoraires | currency:'€':2 * (pricing.infosPricing.honoraires % 1 !== 0)}}</span>
              </div>

              <div class="calculator-line" title="Bouquet net vendeur + Honoraires de négociation">
                {{"Bouquet HNI" | translate}}

                <span
                  class="text-bold pull-right">{{pricing.infosPricing.bouquetHNI | currency:'€':2 * (pricing.infosPricing.bouquetHNI % 1 !== 0)}}</span>
              </div>

              <div class="calculator-line" title="Honoraires de négociation / {{'Bouquet net vendeur' | translate}}">
                {{"Pourcentage des honoraires" | translate}}

                <span
                  class="text-bold pull-right">{{pricing.infosPricing.pourcentageHonoraires | currency:'%':2 * (pricing.infosPricing.pourcentageHonoraires % 1 !== 0)}}</span>
              </div>

              <div class="calculator-line">
                {{"Frais d'acte" | translate}}

                <span
                  class="text-bold pull-right">{{pricing.infosPricing.fraisActe | currency:'€':2 * (pricing.infosPricing.fraisActe % 1 !== 0)}}</span>
              </div>

              <div class="calculator-line" title="Bouquet honoraires de négociation inclus + Frais d'acte">
                {{"Bouquet tous frais compris" | translate}}

                <span
                  class="text-bold pull-right">{{pricing.infosPricing.bouquetTFC | currency:'€':2 * (pricing.infosPricing.bouquetTFC % 1 !== 0)}}</span>
              </div>
            </div>
          </div>

          <h4 ng-switch-default class="tw:text-center">
            {{"Saisissez une valeur de bien et un bouquet pour avoir le détail." | translate}}
          </h4>
        </ng-switch>
      </div>
    </div>
  </div>

  <div ng-if="!!$ctrl.pricings" class="modal-footer">
    <ng-switch on="$ctrl.loader.print" class="pull-right">
      <p ng-switch-when="true">
        <fa-icon [icon]="'spinner'" animation="spin"></fa-icon> {{'global.en_cours.PRINT' | translate}}
      </p>

      <button ng-switch-default type="button" ng-click="$ctrl.print()" class="btn btn-primary">
        <fa-icon icon="print"></fa-icon> Imprimer
      </button>
    </ng-switch>
  </div>
</div>
`;
