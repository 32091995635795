<div class="modal-without-overflow tw:flex tw:flex-col tw:w-[90vw] tw:max-w-[680px]">
  <div class="tw:p-4 tw:pr-[5.5rem] tw:border-b tw:border-gray-300">
    <form>
      <app-form-search-input (searchedText)="searchDemandeurs($event)" [loading]="displaySearchLoader"
                             [options]="{hasFocus: true, placeholder: 'Rechercher un demandeur'}"
                             [model]="searchText"/>
    </form>
  </div>

  <div class="tw:flex-1 tw:overflow-auto">
    <div class="tw:m-6">
      @if (searchText) {
        @if (cDemandeurs$ | async; as cDemandeurs) {
          <div class="tw:flex tw:flex-col">
            @if (cDemandeurs.total > 0) {
              <div class="tw:flex tw:items-center">
                <span [ngPlural]="cDemandeurs.total" class="tw:flex-1 tw:text-right">
                  <ng-template ngPluralCase="=1">1 résultat trouvé</ng-template>
                  <ng-template ngPluralCase="other">{{ cDemandeurs.total }} résultats trouvés</ng-template>
                </span>
              </div>

              <ul class="tw:w-full">
                @for (demandeur of cDemandeurs.results; track demandeur) {
                  <li class="tw:bg-gray-50 tw:rounded-lg tw:p-2 tw:my-1 tw:flex tw:items-center tw:w-full tw:gap-4">
                    <div class="tw:relative tw:w-12 tw:aspect-square">
                      <div class="tw:flex tw:items-center tw:justify-center tw:h-full tw:w-full tw:px-1 tw:rounded-full tw:bg-white">
                        <fa-icon [icon]="'users'" size="xl"/>
                      </div>
                    </div>

                    <div class="tw:flex-1 tw:overflow-hidden">
                      <app-demandeur-card [demandeur]="demandeur" [options]="{highlight: searchText}"/>
                    </div>

                    <div class="tw:w-20 tw:self-center tw:text-center">
                      <button type="button" (click)="add(demandeur)" class="tw:btn-secondary-info tw:disabled:waiting">
                        <fa-icon [icon]="'plus'" class="tw:sm:hidden"/>

                        <span class="tw:hidden tw:sm:inline-block">Ajouter</span>
                      </button>
                    </div>
                  </li>
                }
              </ul>

              <app-load-more [collection$]="cDemandeurs$" (loadMore)="getMore(cDemandeurs)" [loading]="pendingGetMore"/>
            } @else {
              Aucun demandeur ne correspond à votre recherche.
            }
          </div>
        } @else {
          <app-loader [classHeight]="'tw:h-40'" class="tw:block tw:mt-6"/>
        }
      } @else {
        <div class="tw:my-4 tw:sm:my-8 tw:color-gray-500 tw:font-semibold">
          La recherche dans <app-noteo-icon-text-noty/> s'effectuera sur les champs énumérés ci-dessous :
          <!-- Supprimer les ! dans le cas de la suppression de `ul[class*=tw:]` dans style.scss -->
          <ul class="tw:!list-disc tw:!list-inside">
            <li>Nom(s) et prénom(s) / Raison sociale</li>

            <li>Téléphone(s) et mobile(s)</li>

            <li>Adresse(s) email</li>

            <li>Adresse postale</li>
          </ul>
        </div>
      }
    </div>
  </div>
</div>
