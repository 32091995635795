<div class="container-fluid container-fullw tw:bg-white demandeur-edit">
  <form (ngSubmit)="save(redirect)" #editForm="ngForm">
    <button type="submit" class="btn btn-primary btn-save" [disabled]="saving" (click)="redirect = true">
      @if (saving) {
        <fa-icon [icon]="'spinner'" animation="spin"/><span>Enregistrement en cours ...</span>
      } @else {
        @if (!demandeur.id || !demandeur.recherches[0] || !demandeur.recherches[0].id) {
          <fa-icon [icon]="'check'"/><span>Enregistrer</span>
        }
        @if (!!demandeur.id && !!demandeur.recherches[0] && !!demandeur.recherches[0].id) {
          <fa-icon [icon]="'sliders-h'"/><span>Voir les résultats</span>
        }
      }
    </button>

    @if (!!demandeur.id) {
      <div class="tabs-container">
        <ul class="nav nav-pills">
          <!-- @todo Voir pourquoi ce n'est pas le <a> qui supporte le routerLink -->
          <li [routerLink]="'/app/demandeurs/' + demandeur.id + '/edit/general'" class="nav-item"
              routerLinkActive="active"><a class="nav-link">Informations générales</a></li>

          <li [routerLink]="'/app/demandeurs/' + demandeur.id + '/edit/criteres/general'" class="nav-item"
              routerLinkActive="active"><a class="nav-link">Critères principaux de recherche</a></li>

          <li [routerLink]="'/app/demandeurs/' + demandeur.id + '/edit/criteres/secondaire'" class="nav-item"
              routerLinkActive="active"><a class="nav-link">Critères secondaires de recherche</a></li>
        </ul>
      </div>
    }

    <div class="margin-top-30"><router-outlet/></div>
  </form>
</div>
