import {NgModule} from '@angular/core';
import {AppInotUsersSelectComponent} from '@features/inot/users-select/inot.users-select.component';
import {AppInotRequestErrorComponent} from '@features/inot/request-error/inot.request-error.component';
import {AppInotLoaderComponent} from '@features/inot/loader/inot.loader.component';

@NgModule({
    exports: [AppInotLoaderComponent, AppInotRequestErrorComponent, AppInotUsersSelectComponent],
    imports: [AppInotLoaderComponent, AppInotRequestErrorComponent, AppInotUsersSelectComponent],
})
export class InotModule {
}
