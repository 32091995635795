<div class="tw:sm:px-6">
  @if (cVentePasserelles$ | async; as cVentePasserelles) {
    @if (cVentePasserelles.total > 0) {
      <app-ventes-passerelles-list [cVentePasserelles$]="cVentePasserelles$" (clicked)="clicked($event)"/>
    } @else {
      <div class="tw:mb-4 tw:sm:mb-8 tw:mx-auto tw:w-full tw:pt-6 tw:px-4 tw:text-center">
        <p class="tw:mx-auto tw:mb-4 tw:text-base tw:text-gray-500 tw:sm:text-lg tw:md:text-xl">
          Aucune vente est en cours de diffusion sur cette passerelle.
        </p>
      </div>
    }
  } @else {
    <app-loader [classHeight]="'tw:h-40'" class="tw:block tw:mt-4"/>
  }
</div>
