export default `
<div class="ventes-negociees-etude">
  <!-- Graphique -->
  <esk-ventes-chart-negociees get-statistics="$ctrl.getStatistics"></esk-ventes-chart-negociees>

  <!-- Table -->
  <div ng-if="!$ctrl.loading" class="etude-table">
    <div class="table-responsive">
      <table class="table table-hover">
        <thead>
        <tr>
          <th></th>

          <th ng-repeat="month in $ctrl.months track by $index" class="tw:text-center">{{month}}</th>
        </tr>
        </thead>

        <tr>
          <td colspan="13" class="tw:text-center text-bold text-large">{{$ctrl.years[0]}}</td>
        </tr>

        <tr>
          <td>Solo</td>

          <td ng-repeat="month in $ctrl.months track by $index" class="tw:text-center">
            {{$ctrl.statistics[$ctrl.years[0]].solo[$index]}}
          </td>
        </tr>

        <tr>
          <td>Duo</td>

          <td ng-repeat="month in $ctrl.months track by $index" class="tw:text-center">
            {{$ctrl.statistics[$ctrl.years[0]].duo[$index]}}
          </td>
        </tr>

        <tr>
          <td colspan="13" class="tw:text-center text-bold text-large">{{$ctrl.years[1]}}</td>
        </tr>

        <tr>
          <td>Solo</td>

          <td ng-repeat="month in $ctrl.months track by $index" class="tw:text-center">
            {{$ctrl.statistics[$ctrl.years[1]].solo[$index]}}
          </td>
        </tr>

        <tr>
          <td>Duo</td>

          <td ng-repeat="month in $ctrl.months track by $index" class="tw:text-center">
            {{$ctrl.statistics[$ctrl.years[1]].duo[$index]}}
          </td>
        </tr>

        <tr>
          <td>{{$ctrl.network.name}}</td>

          <td ng-repeat="month in $ctrl.months track by $index" class="tw:text-center">
            {{$ctrl.statistics[$ctrl.years[1]].network[$index]}}
          </td>
        </tr>
      </table>
    </div>

    <p class="text-right text-muted text-small">
      Mise à jour des données : <app-ng2-date [date]="$ctrl.statistics.date" format="shortDate"></app-ng2-date> à
      <app-ng2-date [date]="$ctrl.statistics.date" format="shortTime"></app-ng2-date>
    </p>
  </div>
</div>
`;
