import {inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import Links from '@models/links/links.model';
import {map} from 'rxjs/operators';
import {
    ICCityscanOnsalePropertiesApi, ICCityscanOnsalePropertiesQueryParameters
} from '@models/cityscan-onsale-properties/collection/cityscan-onsale-properties.collection.interfaces';
import {CityscanOnsalePropertiesApiService} from '@models/cityscan-onsale-properties/cityscan-onsale-properties.api.service';
import CCityscanOnsaleProperties from '@models/cityscan-onsale-properties/collection/cityscan-onsale-properties.collection.model';
import {HttpOptions} from '@core/api/api.interfaces';
import SearchCriteria from '@models/search/criteria/search-criteria.model';
import {CityscanOnsalePropertiesFactory} from '@models/cityscan-onsale-properties/cityscan-onsale-properties.factory';

@Injectable({providedIn: 'root'})
export class CCityscanOnsalePropertiesFactory {
    private _cityscanOnsalePropertiesFactory = inject(CityscanOnsalePropertiesFactory);
    private _cityscanOnsalePropertyApiFactory = inject(CityscanOnsalePropertiesApiService);

    create(cCityscanOnsalePropertiesApi: ICCityscanOnsalePropertiesApi): CCityscanOnsaleProperties {
        const cCityscanOnsaleProperties = new CCityscanOnsaleProperties();

        cCityscanOnsaleProperties.links = new Links(cCityscanOnsalePropertiesApi._links);
        cCityscanOnsaleProperties.page = cCityscanOnsalePropertiesApi.page;
        cCityscanOnsaleProperties.pages = cCityscanOnsalePropertiesApi.pages;
        cCityscanOnsaleProperties.perPage = cCityscanOnsalePropertiesApi.limit;
        cCityscanOnsaleProperties.total = cCityscanOnsalePropertiesApi.total;
        if (cCityscanOnsalePropertiesApi._embedded) {
            cCityscanOnsaleProperties.results = this._cityscanOnsalePropertiesFactory.create(cCityscanOnsalePropertiesApi._embedded.items);
        }

        return cCityscanOnsaleProperties;
    }

    get$(queryParameters?: ICCityscanOnsalePropertiesQueryParameters, httpOptions?: HttpOptions): Observable<CCityscanOnsaleProperties> {
        return this._cityscanOnsalePropertyApiFactory.getCollection$(queryParameters, httpOptions).pipe(map(cCityscanOnsalePropertiesApi => this.create(cCityscanOnsalePropertiesApi)));
    }

    getQueryParametersFromSearchCriteria(searchCriteria: SearchCriteria): ICCityscanOnsalePropertiesQueryParameters {
        const queryParameters = {
            tris: searchCriteria.tris,
        } as ICCityscanOnsalePropertiesQueryParameters;

        if (searchCriteria.nombrePiecesMax > 0 || searchCriteria.nombrePiecesMin > 0) {
            queryParameters.nombrePieces = {};
            if (searchCriteria.nombrePiecesMax > 0) {
                queryParameters.nombrePieces.max = searchCriteria.nombrePiecesMax;
            }

            if (searchCriteria.nombrePiecesMin > 0) {
                queryParameters.nombrePieces.min = searchCriteria.nombrePiecesMin;
            }
        }

        if (searchCriteria.archivePrixCessionMax > 0 || searchCriteria.archivePrixCessionMin > 0) {
            queryParameters.prixVente = {};
            if (searchCriteria.archivePrixCessionMax > 0) {
                queryParameters.prixVente.max = searchCriteria.archivePrixCessionMax;
            }

            if (searchCriteria.archivePrixCessionMin > 0) {
                queryParameters.prixVente.min = searchCriteria.archivePrixCessionMin;
            }
        }

        if (searchCriteria.superficieMax > 0 || searchCriteria.superficieMin > 0) {
            queryParameters.superficie = {};
            if (searchCriteria.superficieMax > 0) {
                queryParameters.superficie.max = searchCriteria.superficieMax;
            }

            if (searchCriteria.superficieMin > 0) {
                queryParameters.superficie.min = searchCriteria.superficieMin;
            }
        }

        if (searchCriteria.natures.length > 0) {
            queryParameters.nature = searchCriteria.natures.map(nature => nature.id);
        }

        return queryParameters;
    }
}
