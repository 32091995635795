export default `
<ng-switch on="$ctrl.loadingLocataires">
  <div ng-switch-when="true">
    <p class="tw:text-center text-extra-large">
      <fa-icon [icon]="'spinner'" animation="spin"></fa-icon> Chargement des locataires
    </p>
  </div>

  <div ng-switch-default class="container-fluid container-fullw tw:bg-white mandat margin-bottom-30">
    <form name="$ctrl.editForm" ng-submit="$ctrl.save()">
      <esk-button-save saving="$ctrl.saving"></esk-button-save>

      <div class="row margin-bottom-10">
        <!-- Type de mandat -->
        <div class="col-sm-3">
          <div class="form-group">
            <label>{{"Type de mandat"|translate}}</label>

            <div class="row">
              <div class="col-sm-12">
                <div class="radio-inline padding-left-0" ng-repeat="typeMandatLocation in $ctrl.typesMandatLocation">
                  <div class="radio clip-radio radio-primary">
                    <input type="radio" id="typeMandatLocation{{typeMandatLocation.id}}" ng-model="$ctrl.mandat.type"
                           name="typeMandatLocation" ng-value="typeMandatLocation">

                    <label for="typeMandatLocation{{typeMandatLocation.id}}">{{typeMandatLocation.label}}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Dates de mandat -->
        <div class="col-sm-4">
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <label>{{"Date de mandat" | translate}}</label>

                <esk-select-date form="$ctrl.editForm" form-field-name="mandatDate"
                                 model="$ctrl.mandat.date" options="$ctrl.options.mandatDate"
                                 on-select="$ctrl.setDateOptions()">
                </esk-select-date>
              </div>
            </div>

            <div class="col-sm-6">
              <div class="form-group">
                <label>{{"Date de fin de mandat" | translate}}</label>

                <esk-select-date form="$ctrl.editForm" form-field-name="mandatDateFin"
                                 model="$ctrl.mandat.dateFin" options="$ctrl.options.mandatDateFin"
                                 on-select="$ctrl.setDateOptions()">
                </esk-select-date>
              </div>
            </div>
          </div>
        </div>

        <!-- Génération du mandat -->
        <div class="col-sm-3">
          <div class="form-group">
            <label>Saisie automatique pour le mandat</label>

            <p>Vous pouvez pré-saisir le mandat en cliquant sur le lien ci-dessous.</p>

            <p class="text-warning" ng-show="!!$ctrl.mandat.contenu">
              <span class="text-bold">Attention</span> : la saisie automatique remplacera le mandat actuel.
            </p>

            <button type="button" class="tw:btn-tertiary-info tw:p-0" ng-click="$ctrl.getLocationMandat()"
                    ng-switch="!!$ctrl.mandat.contenu">
              <fa-icon ng-if="$ctrl.initMandat" [icon]="'spinner'" animation="spin"></fa-icon>

              <span ng-switch-when="true">Remplacer le mandat</span>

              <span ng-switch-default>Pré-saisir le mandat</span>
            </button>
          </div>
        </div>

        <!-- Archivage -->
        <div ng-if="!!$ctrl.mandat.id" class="col-sm-2">
          <p class="text-justify">
            En cliquant, vous archiverez ce mandat.
            Vous le retrouverez dans la liste ci-dessus.
          </p>

          <ng-switch on="$ctrl.archiving" class="text-right">
            <p ng-switch-when="true">
              <fa-icon [icon]="'spinner'" animation="spin"></fa-icon> {{'global.en_cours.DEFAULT' | translate}}
            </p>

            <button ng-switch-default type="button" class="btn btn-danger btn-o" ng-click="$ctrl.archive()">
              <fa-icon [icon]="'archive'"></fa-icon> Archiver
            </button>
          </ng-switch>
        </div>
      </div>

      <div class="margin-bottom-10"
           ng-include="'src/app/legacy/templates/locations/controllers/item/mandats/btns.actions.html'"></div>

      <app-ng2-tinymce (change-content)="$ctrl.mandat.contenu = $event" [content]="$ctrl.mandat.contenu"
                       [options]="$ctrl.tinymceOptions"></app-ng2-tinymce>

      <div class="margin-top-10"
           ng-include="'src/app/legacy/templates/locations/controllers/item/mandats/btns.actions.html'"></div>
    </form>
  </div>
</ng-switch>
`;
