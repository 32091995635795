export default `
<div ng-switch="$ctrl.loader" class="suivi-email">
  <h2 ng-switch-when="true" class="no-messages">
    <fa-icon [icon]="'spinner'" animation="spin"></fa-icon> {{'global.en_cours.LOAD' | translate}}
  </h2>

  <div ng-switch-default>
    <div class="tw:flex tw:justify-between tw:items-center tw:mb-4">
      <div class="tw:flex tw:flex-wrap tw:items-center tw:gap-1">
        <app-email-statut [email]="$ctrl.ng2Email" [options]="{useShortLabel: true}" class="tw:w-8 tw:text-center">
        </app-email-statut>

        Envoyé par

        <app-user-cartouche [link]="$ctrl.email._links.creator.href" [options]="{showPhoto: false}"
                            class="tw:max-w-full tw:w-fit"></app-user-cartouche>
      </div>

      <div>
        le <app-ng2-date [date]="$ctrl.email.sendingDate" format="fullDate"></app-ng2-date> à
        <app-ng2-date [date]="$ctrl.email.sendingDate" format="shortTime"></app-ng2-date>
      </div>
    </div>

    <div ng-switch="$ctrl.recipientsExpand" class="email-recipients"
         ng-click="$ctrl.recipientsExpand = !$ctrl.recipientsExpand">
      <div ng-switch-default class="ellipsis">
        <fa-icon [icon]="'users'"></fa-icon>

        <span ng-repeat="recipient in $ctrl.email.recipients">
          {{recipient.name}}<span ng-if="!$last">, </span><span ng-if="$last">.</span>
        </span>
      </div>

      <div ng-switch-when="true">
        <div ng-repeat="recipient in $ctrl.email.recipients" ng-mouseenter="recipient._esk.popoverIsOpen = true"
             ng-mouseleave="recipient._esk.popoverIsOpen = false" class="tw:flex tw:items-center tw:gap-1">
          <app-email-recipient-statut [email-recipient]="$ctrl.getNg2Recipient(recipient)" class="tw:w-8 tw:text-center"
                                      [options]="{useShortLabel: true}"></app-email-recipient-statut>

          {{recipient.name}}
        </div>
      </div>
    </div>

    <div class="email-subject" ng-bind-html="$ctrl.email.subject"></div>

    <div class="email-message" ng-bind-html="$ctrl.email.message"></div>

    <div class="email-attachments">
      <span ng-repeat="attachment in $ctrl.email.attachments" class="email-attachment">
        <button type="button" class="tw:btn-secondary-info" ng-click="$ctrl.viewAttachment(attachment)">
          <fa-icon [icon]="['far', 'file']"></fa-icon><span>{{attachment.media.title}}</span>
        </button>
      </span>
    </div>
  </div>
</div>
`;
