<div class="tw:group tw:relative tw:shadow-2xl">
  <div class="tw:m-px">
    <button type="button" class="tw:btn-{{options.type}}-ia" [disabled]="isLoading" (click)="execute()">
      @if (options.iconEnabled) {
        <fa-icon [icon]="['fal', 'wand-sparkles']"/>
      }

      {{ options.label }}
    </button>
  </div>

  <div class="tw:group-hover:block tw:absolute tw:-inset-3 tw:z-1" [ngClass]="isLoading ? '' : 'tw:hidden'">
    <div class="tw:h-full tw:w-full tw:content-[''] tw:bg-[url('/assets/images/ia-loading.svg')] tw:animate-sparkle">
    </div>
  </div>
</div>
