<div class="tw:text-center">
  @if (etudePasserelle.passerelle.urlSite) {
    <div class="tw:flex tw:flex-col tw:gap-4">
      <img [ngSrc]="etudePasserelle.passerelle.urlLogo || linkMarianne" height="96" width="96"
           class="tw:mx-auto tw:shrink-0">

      <span class="tw:truncate tw:text-sm tw:font-medium tw:text-gray-900">{{ etudePasserelle.passerelle.name }}</span>
    </div>
  } @else {
    <a [href]="etudePasserelle.passerelle.urlSite" target="_blank" class="tw:flex tw:flex-col tw:gap-4">
      <img [ngSrc]="etudePasserelle.passerelle.urlLogo || linkMarianne" height="96" width="96"
           class="tw:mx-auto tw:shrink-0">

      <span class="tw:truncate tw:text-sm tw:font-medium tw:text-gray-900">
        {{ etudePasserelle.passerelle.name }} <fa-icon [icon]="'external-link-alt'"/>
      </span>
    </a>
  }

  @if (etudePasserelle.biensMaxNumber > 0) {
    @if (etudePasserelle.biensMaxNumber > (etudePasserelle.nbVentesActivesEnvoyees + etudePasserelle.nbLocationsActivesEnvoyees)) {
      <span class="tw:mt-2">Contrat de {{ etudePasserelle.biensMaxNumber }} biens diffusables</span>
    } @else {
      <span class="tw:mt-2 tw:text-orange-600">
        {{ etudePasserelle.nbVentesActivesEnvoyees + etudePasserelle.nbLocationsActivesEnvoyees }}
        / {{ etudePasserelle.biensMaxNumber }} biens diffusés
      </span>
    }
  }
</div>
