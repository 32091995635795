<app-form-file-picker [options]="options" [openFilePicker$]="openFilePicker$" (selected)="onSelect($event)"/>

@if (imageSelectedError) {
  <div class="tw:in-error tw:max-w-lg tw:mt-4 tw:mx-auto">
    <div class="tw:in-error-message">
      {{imageSelectedError.message}}

      <div class="tw:max-w-[21rem] tw:mx-auto tw:text-left">
        <br>Essayez de résoudre le problème en suivant ces étapes :

        <ol class="tw:ml-12 tw:list-decimal">
          <li>Ouvrez le fichier dans un éditeur d'image</li>
          <li>Enregistrez-la sous un nouveau nom</li>
          <li>Essayez de l'importer de nouveau</li>
        </ol>

        Si le problème persiste, contactez le support technique.
      </div>
    </div>
  </div>
}
