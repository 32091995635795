import {inject, Injectable} from '@angular/core';
import {BonvisiteFactory} from '@models/bonvisites/bonvisite/bonvisite.factory';
import {IBonvisiteApi} from '@models/bonvisites/bonvisite/bonvisite.interfaces';
import Bonvisite from '@models/bonvisites/bonvisite/bonvisite.model';

@Injectable({providedIn: 'root'})
export class BonvisitesFactory {
    private _bonvisiteFactory = inject(BonvisiteFactory);

    create(bonvisiteApis: IBonvisiteApi[] = []): Bonvisite[] {
        return (bonvisiteApis || []).filter(bonvisiteApi => bonvisiteApi)
            .map(bonvisiteApi => this._bonvisiteFactory.create(bonvisiteApi));
    }
}
