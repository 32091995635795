<div appProvideParentForm class="tw:relative tw:text-sm">
  <div class="tw:absolute tw:inset-y-0 tw:flex tw:items-center tw:left-0 tw:pl-3 tw:pointer-events-none">
    @if (loading) {
      <span><fa-icon [icon]="'spinner'" animation="spin"/></span>
    } @else {
      <span class="tw:text-gray-400"><fa-icon [icon]="'search'"/></span>
    }
  </div>

  <input (input)="onInput()" [(ngModel)]="model" name="search" type="search" autocomplete="off"
         class="tw:input-search" placeholder="{{options.placeholder}}" #formSearchInput>
</div>
