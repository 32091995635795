import {inject, Injectable} from '@angular/core';
import ProcedureSignataire from '@models/procedures/procedure/signataires/signataire/procedure-signataire.model';
import {
    IProcedureSignataireApi
} from '@models/procedures/procedure/signataires/signataire/procedure-signataire.interfaces';
import {
    ProcedureSignataireFactory
} from '@models/procedures/procedure/signataires/signataire/procedure-signataire.factory';

@Injectable({providedIn: 'root'})
export class ProcedureSignatairesFactory {
    private _procedureSignataireFactory = inject(ProcedureSignataireFactory);

    create(procedureSignataireApis: IProcedureSignataireApi[] = []): ProcedureSignataire[] {
        return (procedureSignataireApis || []).filter(procedureSignataireApi => procedureSignataireApi)
            .map(procedureSignataireApi => this._procedureSignataireFactory.create(procedureSignataireApi));
    }
}
