import {inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import Links from '@models/links/links.model';
import {map} from 'rxjs/operators';
import {ICTemplateFootersApi} from '@models/templates/footers/collection/template-footers.collection.interfaces';
import CTemplateFooters from '@models/templates/footers/collection/template-footers.collection.model';
import {TemplateFootersApiService} from '@models/templates/footers/template-footers.api.service';
import {TemplateFootersFactory} from '@models/templates/footers/template-footers.factory';

@Injectable({providedIn: 'root'})
export class CTemplateFootersFactory {
    private _templateFootersApiService = inject(TemplateFootersApiService);
    private _templateFootersFactory = inject(TemplateFootersFactory);

    create(cTemplateFootersApi: ICTemplateFootersApi): CTemplateFooters {
        const cTemplateFooters = new CTemplateFooters();

        cTemplateFooters.links = new Links(cTemplateFootersApi._links);
        cTemplateFooters.page = cTemplateFootersApi.page;
        cTemplateFooters.pages = cTemplateFootersApi.pages;
        cTemplateFooters.perPage = cTemplateFootersApi.limit;
        cTemplateFooters.total = cTemplateFootersApi.total;

        if (cTemplateFootersApi._embedded) {
            cTemplateFooters.results = this._templateFootersFactory.create(cTemplateFootersApi._embedded.items);
        }

        return cTemplateFooters;
    }

    get$(): Observable<CTemplateFooters> {
        return this._templateFootersApiService.getFooters$().pipe(map(cTemplateFootersApi => this.create(cTemplateFootersApi)));
    }
}
