@if (pending$ | appLoadObs | async; as pending) {
  <div [ngClass]="pending.value ? 'tw:waiting' : undefined!" class="tw:flex tw:gap-4">
    <button type="button" class="tw:btn-primary-info" (click)="create()">Créer un bon de visite</button>

    <button type="button" class="tw:btn-secondary-info tw:w-10" #buttonElement (click)="clickDropdown(buttonElement)">
      <fa-icon [icon]="'ellipsis-v'"/>
    </button>
  </div>
}

