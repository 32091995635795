export default `
<div class="list-locations">
  <div ng-if="$ctrl.locations.totalItems > 0 && (!!$ctrl.options.visibleFields.select || !!$ctrl.options.nameDossiers)"
       class="padding-bottom-10 list-infos hidden-print">
    <span ng-if="$ctrl.options.visibleFields.select && $ctrl.options.displayMode!='table'"
          ng-include="'src/app/legacy/templates/eskimmo/components/list/select.html'" class="select-all"></span>

    <span ng-if="!!$ctrl.options.nameDossiers" class="text-justify">
      {{$ctrl.options.nameDossiers | translate:'{NB: ' + $ctrl.locations.totalItems + '}'}}
    </span>

    <span ng-if="!!$ctrl.filter.nom">
      {{"dans" | translate}}

      <span class="text-emphasis text-bold">{{$ctrl.filter.nom | lowercase}}</span>
    </span>

    <span ng-if="$ctrl.options.visibleFields.bullet && $ctrl.options.visibleFields.bullet_help">
      <a uib-popover-template="'src/app/legacy/templates/biens/results.color-bullet.html'"
         popover-trigger="'outsideClick'" popover-placement="bottom" class="btn btn-o btn-null">
        <span class="badge badge-info">?</span>
      </a>
    </span>
  </div>

  <ng-switch on="$ctrl.options.loader.updating">
    <p class="tw:text-center text-extra-large" ng-switch-when="true">
      <fa-icon [icon]="'spinner'" animation="spin"></fa-icon> Mise à jour de vos biens en location
    </p>

    <ng-switch ng-switch-default on="$ctrl.options.displayMode" ng-if="$ctrl.locations.totalItems > 0">
      <!-- Tableau -->
      <div ng-switch-default class="table-responsive">
        <table class="table table-hover table-biens">
          <thead>
          <tr>
            <th
              ng-if="$ctrl.options.visibleFields.select || $ctrl.options.visibleFields.bullet || !!$ctrl.options.deleteResultRemoved">
              <ng-include ng-if="$ctrl.options.visibleFields.select" class="select-all"
                          src="'src/app/legacy/templates/eskimmo/components/list/select.html'"></ng-include>
            </th>

            <th ng-if="$ctrl.options.visibleFields.photo"></th>

            <th class="tw:text-center">
              <esk-button-sort sort-type="{{$ctrl.options.typeFilter}}" sort-code="reference"
                               current-sort="$ctrl.filter.queryParameters.sort"
                               on-change-sort="$ctrl.onChangeSort">
              </esk-button-sort>
            </th>

            <th ng-if="$ctrl.options.visibleFields.statut" class="tw:text-center">{{'Statut' | translate}}</th>

            <th ng-if="$ctrl.options.visibleFields.nomContactsGroup" class="tw:text-center">
              <esk-button-sort sort-type="{{$ctrl.options.typeFilter}}" sort-code="nomProprietaire"
                               current-sort="$ctrl.filter.queryParameters.sort"
                               on-change-sort="$ctrl.onChangeSort">
              </esk-button-sort>
            </th>

            <th ng-if="$ctrl.options.visibleFields.nature" class="tw:text-center">
              <esk-button-sort sort-type="{{$ctrl.options.typeFilter}}" sort-code="nature"
                               current-sort="$ctrl.filter.queryParameters.sort"
                               on-change-sort="$ctrl.onChangeSort">
              </esk-button-sort>
            </th>

            <th ng-if="$ctrl.options.visibleFields.localisation" class="tw:text-center">
              <esk-button-sort sort-type="{{$ctrl.options.typeFilter}}" sort-code="commune"
                               current-sort="$ctrl.filter.queryParameters.sort"
                               on-change-sort="$ctrl.onChangeSort">
              </esk-button-sort>
            </th>

            <th ng-if="$ctrl.options.visibleFields.nombrePieces" class="tw:text-center">
              <esk-button-sort sort-type="{{$ctrl.options.typeFilter}}" sort-code="nombrePieces"
                               current-sort="$ctrl.filter.queryParameters.sort"
                               on-change-sort="$ctrl.onChangeSort">
              </esk-button-sort>
            </th>

            <th ng-if="$ctrl.options.visibleFields.nombreChambres" class="tw:text-center">
              <esk-button-sort sort-type="{{$ctrl.options.typeFilter}}" sort-code="nombreChambres"
                               current-sort="$ctrl.filter.queryParameters.sort"
                               on-change-sort="$ctrl.onChangeSort">
              </esk-button-sort>
            </th>

            <th ng-if="$ctrl.options.visibleFields.loyer" class="tw:text-center">
              <esk-button-sort sort-type="{{$ctrl.options.typeFilter}}" sort-code="loyer"
                               current-sort="$ctrl.filter.queryParameters.sort"
                               on-change-sort="$ctrl.onChangeSort">
              </esk-button-sort>
            </th>

            <th ng-if="$ctrl.options.visibleFields.angleRight" class="hidden-print"></th>
          </tr>
          </thead>

          <tr class="line" ng-repeat="location in $ctrl.locations.collection">
            <td ng-if="$ctrl.options.visibleFields.select || $ctrl.options.visibleFields.bullet || !!$ctrl.options.deleteResultRemoved"
                class="select hidden-print">
              <div ng-if="$ctrl.options.visibleFields.bullet">
                <span ng-if="location._esk.result._esk.colorStatus.classBullet['bullet-green']" class="bullet-green">
                  <fa-icon [icon]="'circle'"></fa-icon>
                </span>

                <span ng-if="location._esk.result._esk.colorStatus.classBullet['bullet-orange']" class="bullet-orange">
                  <fa-icon [icon]="'circle'"></fa-icon>
                </span>

                <span ng-if="location._esk.result._esk.colorStatus.classBullet['bullet-blue']" class="bullet-blue">
                  <fa-icon [icon]="'circle'"></fa-icon>
                </span>

                <span ng-if="location._esk.result._esk.colorStatus.classBullet['compromis bullet-red']"
                      class="compromis bullet-red"><fa-icon [icon]="'bookmark'"></fa-icon></span>

                <span ng-if="location._esk.result._esk.colorStatus.classBullet['bullet-red']" class="bullet-red">
                  <fa-icon [icon]="'circle'"></fa-icon>
                </span>
              </div>

              <ng-switch on="!!$ctrl.options.deleteResultRemoved && location._esk.result.status === 'match_removed'">
                <div ng-switch-when="true" class="btns-interest">
                  <button type="button" class="dislike text-large" ng-click="$ctrl.options.deleteResultRemoved(location)"
                          uib-tooltip="Non intéressé" tooltip-placement="bottom-left">
                    <fa-icon [icon]="'times'"></fa-icon>
                  </button>
                </div>

                <div ng-switch-default>
                  <div ng-if="$ctrl.options.visibleFields.select" class="checkbox clip-check check-primary">
                    <input type="checkbox" id="table-biens-select-{{location.id}}" ng-model="location._esk.select"
                           ng-change="$ctrl.onSelect(location)">

                    <label for="table-biens-select-{{location.id}}"></label>
                  </div>
                </div>
              </ng-switch>
            </td>

            <td ng-if="$ctrl.options.visibleFields.photo" class="tw:text-center confrere-parent-alert"
                ng-click="$ctrl.onClick(location)">
              <div ng-if="!location.bien._esk.interne" class="confrere-alert">
                <span class="label label-confrere">C</span>
              </div>

              <esk-biens-wallet-display-photo photo-link="location.bien._links.premierePhoto.href"
                                              routing="!!$ctrl.click ? '' : location._esk.routing">
              </esk-biens-wallet-display-photo>
            </td>

            <td class="tw:text-center">{{location.bien.reference}}</td>

            <td ng-if="$ctrl.options.visibleFields.statut" class="tw:text-center">
              <esk-location-label-statut location="location"></esk-location-label-statut>

              <div ng-if="location.statut === $ctrl.STATUTS.DISPONIBLE && !!location.bien.metadata.occupation.dateDisponibilite"
                   title="Disponibilité" class="text-muted padding-top-10">
                <app-ng2-date [date]="location.bien.metadata.occupation.dateDisponibilite" format="shortDate">
                </app-ng2-date>
              </div>
            </td>

            <td ng-if="$ctrl.options.visibleFields.nomContactsGroup" class="col-nom">
              <div class="text ellipsis">{{location.bien.contactsGroup.nom}}</div>
            </td>

            <td ng-if="$ctrl.options.visibleFields.nature" class="tw:text-center">
              {{location.bien.nature.label}}
            </td>

            <td ng-if="$ctrl.options.visibleFields.localisation" class="col-adresse tw:text-center">
              <ng-switch on="!!location.bien.option.commune.fullname">
                <div ng-swicth-default
                     class="thin-text text-bold ellipsis">{{location.bien.option.commune.fullname}}</div>

                <div ng-switch-when="false" class="text-muted">{{'Aucune commune renseignée' | translate}}</div>
              </ng-switch>

              <div ng-show="!!location.bien.option.quartier.name">{{location.bien.option.quartier.name}}</div>

              <div class="adresse ellipsis">
                <span ng-if="$ctrl.options.visibleFields.preciseLocalisation && !!location.bien.option.numeroVoie"
                      class="number">{{location.bien.option.numeroVoie}}</span>

                <span ng-if="$ctrl.options.visibleFields.preciseLocalisation && !!location.bien.option.nomVoie"
                      class="nom">{{location.bien.option.nomVoie}}</span>
              </div>
            </td>

            <td ng-if="$ctrl.options.visibleFields.nombrePieces" class="tw:text-center">
              {{location.bien.option.nombrePieces}}
            </td>

            <td ng-if="$ctrl.options.visibleFields.nombreChambres" class="tw:text-center">
              {{location.bien.option.nombreChambres}}
            </td>

            <td ng-if="$ctrl.options.visibleFields.loyer" class=" tw:text-center">
              <esk-location-label-rent location="location"></esk-location-label-rent>
            </td>

            <td ng-if="$ctrl.options.visibleFields.angleRight" class="tw:text-center hidden-print angle-right"
                ng-click="$ctrl.onClick(location)">
              <a href="{{location._esk.routing}}"><div><fa-icon [icon]="'angle-right'"></fa-icon></div></a>
            </td>
          </tr>
        </table>
      </div>

      <!-- Liste -->
      <ul ng-switch-when="table-photo" class="list-group table-photo-biens">
        <li class="list-group-item line" ng-repeat="location in $ctrl.locations.collection">
          <div class="row">
            <div
              ng-if="$ctrl.options.visibleFields.select || $ctrl.options.visibleFields.bullet || !!$ctrl.options.deleteResultRemoved"
              class="col-xs-1 col-sm-1">
              <div ng-if="$ctrl.options.visibleFields.bullet">
                <span ng-if="location._esk.result._esk.colorStatus.classBullet['bullet-green']" class="bullet-green">
                  <fa-icon [icon]="'circle'"></fa-icon>
                </span>

                <span ng-if="location._esk.result._esk.colorStatus.classBullet['bullet-orange']" class="bullet-orange">
                  <fa-icon [icon]="'circle'"></fa-icon>
                </span>

                <span ng-if="location._esk.result._esk.colorStatus.classBullet['bullet-blue']" class="bullet-blue">
                  <fa-icon [icon]="'circle'"></fa-icon>
                </span>

                <span ng-if="location._esk.result._esk.colorStatus.classBullet['compromis bullet-red']"
                      class="compromis bullet-red"><fa-icon [icon]="'bookmark'"></fa-icon></span>

                <span ng-if="location._esk.result._esk.colorStatus.classBullet['bullet-red']" class="bullet-red">
                  <fa-icon [icon]="'circle'"></fa-icon>
                </span>
              </div>

              <ng-switch on="!!$ctrl.options.deleteResultRemoved && location._esk.result.status === 'match_removed'"
                         class="select">
                <div ng-switch-when="true" class="btns-interest">
                  <button type="button" class="dislike text-large" ng-click="$ctrl.options.deleteResultRemoved(location)"
                          uib-tooltip="Non intéressé" tooltip-placement="bottom-left">
                    <fa-icon [icon]="'times'"></fa-icon>
                  </button>
                </div>

                <div ng-switch-default>
                  <div ng-if="$ctrl.options.visibleFields.select" class="checkbox clip-check check-primary">
                    <input type="checkbox" id="table-photo-biens-select-{{location.id}}" ng-model="location._esk.select"
                           ng-change="$ctrl.onSelect(location)">

                    <label for="table-photo-biens-select-{{location.id}}"></label>
                  </div>
                </div>
              </ng-switch>
            </div>

            <div
              ng-class="{'col-xs-11 col-sm-11': $ctrl.options.visibleFields.bullet || $ctrl.options.visibleFields.select, 'col-xs-12 col-sm-12': !$ctrl.options.visibleFields.bullet && !$ctrl.options.visibleFields.select}">
              <div ng-if="$ctrl.options.visibleFields.photo" class="photo-display confrere-parent-alert"
                   ng-click="$ctrl.onClick(location)">
                <div class="confrere-alert" ng-if="!location.bien._esk.interne">
                  <span class="label label-confrere">{{"Confrères" | translate}}</span>
                </div>

                <esk-biens-wallet-display-photo photo-link="location.bien._links.premierePhoto.href"
                                                routing="!!$ctrl.click ? '' : location._esk.routing">
                </esk-biens-wallet-display-photo>
              </div>

              <div class="informations">
                <div class="margin-bottom-10 resume">
                  <span ng-if="$ctrl.options.visibleFields.nature" class="label label-nature">
                    {{location.bien.nature.label}}
                  </span>

                  <span ng-if="$ctrl.options.visibleFields.statut">
                    <esk-location-label-statut location="location"></esk-location-label-statut>
                  </span>

                  <esk-location-label-rent ng-if="$ctrl.options.visibleFields.loyer"
                                           location="location"></esk-location-label-rent>
                </div>

                <div class="margin-bottom-10">
                  <div class="second-line">
                    <span class="tw:text-center margin-right-5 pull-left">
                      {{location.bien.reference}}
                    </span>

                    <span ng-if="$ctrl.options.visibleFields.localisation" class="localisation">
                      <fa-icon [icon]="'map-marker-alt'" size="lg"></fa-icon>

                      <p ng-if="$ctrl.options.visibleFields.preciseLocalisation && !!location.bien.option.numeroVoie">
                        {{location.bien.option.numeroVoie}}
                      </p>

                      <p ng-if="$ctrl.options.visibleFields.preciseLocalisation && !!location.bien.option.nomVoie"
                         class="adresse ellipsis">{{location.bien.option.nomVoie}}</p>

                      <ng-switch on="!!location.bien.option.commune.fullname">
                        <p ng-swicth-default class="text-bold">{{location.bien.option.commune.fullname}}</p>

                        <p ng-switch-when="false" class="text-muted">{{'Aucune commune renseignée' | translate}}</p>
                      </ng-switch>

                      <p ng-show="!!location.bien.option.codePostal">{{location.bien.option.codePostal}}</p>

                      <p ng-show="!!location.bien.option.quartier.name">{{location.bien.option.quartier.name}}</p>
                    </span>
                  </div>
                </div>

                <div class="margin-bottom-10">
                  <h5 ng-if="$ctrl.options.visibleFields.nomContactsGroup"
                      class="margin-bottom-10 ellipsis">{{location.bien.contactsGroup.nom}}</h5>

                  <div class="margin-bottom-10 ellipsis">{{location.bien.titre}}</div>

                  <div class="descriptif margin-bottom-10 ellipsis">{{location.bien.descriptif}}</div>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>

      <!-- Vignettes -->
      <div ng-switch-when="collection" class="collection-biens">
        <div class="content"
             ng-class="{private: $ctrl.options.visibleFields.nomContactsGroup, public: !$ctrl.options.visibleFields.nomContactsGroup}"
             ng-repeat="location in $ctrl.locations.collection">
          <div class="thumbnail">
            <div class="content-photo">
              <div class="row photo-header">
                <div ng-if="$ctrl.options.visibleFields.bullet" class="col-xs-2">
                  <span ng-if="location._esk.result._esk.colorStatus.classBullet['bullet-green']" class="bullet-green">
                    <fa-icon [icon]="'circle'"></fa-icon>
                  </span>

                  <span ng-if="location._esk.result._esk.colorStatus.classBullet['bullet-orange']" class="bullet-orange">
                    <fa-icon [icon]="'circle'"></fa-icon>
                  </span>

                  <span ng-if="location._esk.result._esk.colorStatus.classBullet['bullet-blue']" class="bullet-blue">
                    <fa-icon [icon]="'circle'"></fa-icon>
                  </span>

                  <span ng-if="location._esk.result._esk.colorStatus.classBullet['compromis bullet-red']"
                        class="compromis bullet-red"><fa-icon [icon]="'bookmark'"></fa-icon></span>

                  <span ng-if="location._esk.result._esk.colorStatus.classBullet['bullet-red']" class="bullet-red">
                    <fa-icon [icon]="'circle'"></fa-icon>
                  </span>
                </div>

                <div class="col-xs-2">
                  <ng-switch on="!!$ctrl.options.deleteResultRemoved && location._esk.result.status === 'match_removed'"
                             class="select">
                    <div ng-switch-when="true" class="btns-interest">
                      <button type="button" class="dislike text-large" ng-click="$ctrl.options.deleteResultRemoved(location)"
                              uib-tooltip="Non intéressé" tooltip-placement="bottom-left">
                        <fa-icon [icon]="'times'"></fa-icon>
                      </button>
                    </div>

                    <div ng-switch-default>
                      <div ng-if="$ctrl.options.visibleFields.select" class="checkbox clip-check check-primary">
                        <input type="checkbox" id="collection-biens-select-{{location.id}}"
                               ng-model="location._esk.select" ng-change="$ctrl.onSelect(location)">

                        <label for="collection-biens-select-{{location.id}}"></label>
                      </div>
                    </div>
                  </ng-switch>
                </div>

                <div class="col-xs-8 confrere-parent-alert">
                  <div class="confrere-alert" ng-if="!location.bien._esk.interne">
                    <span class="label label-confrere">{{"Confrères" | translate}}</span>
                  </div>
                </div>
              </div>

              <div class="photo-price">
                <esk-location-label-rent ng-if="$ctrl.options.visibleFields.loyer" location="location">
                </esk-location-label-rent>
              </div>

              <div ng-click="$ctrl.onClick(location)">
                <esk-biens-wallet-display-photo ng-if="$ctrl.options.visibleFields.photo"
                                                photo-link="location.bien._links.premierePhoto.href"
                                                routing="!!$ctrl.click ? '' : location._esk.routing">
                </esk-biens-wallet-display-photo>
              </div>
            </div>

            <div ng-if="$ctrl.options.visibleFields.localisation" ng-switch="!!location.bien.option.commune.fullname"
                 class="localisation">
              <div ng-swicth-default
                   class="text-bold tw:text-center text-extra-large commune ellipsis">{{location.bien.option.commune.fullname}}</div>

              <div ng-switch-when="false"
                   class="text-muted tw:text-center">{{'Aucune commune renseignée' | translate}}</div>

              <div class="margin-top-10 tw:text-center" ng-show="location.bien.option.quartier">
                <p>
                  {{"Quartier" | translate}} : <span class="text-bold">{{location.bien.option.quartier.name}}</span>
                </p>
              </div>
            </div>

            <div ng-if="$ctrl.options.visibleFields.nomContactsGroup"
                 class="owners ellipsis">{{location.bien.contactsGroup.nom}}</div>

            <div class="last-line">
              <div ng-if="$ctrl.options.visibleFields.statut" class="statut">
                <esk-location-label-statut location="location"></esk-location-label-statut>
              </div>

              <div class="dossier">
                <span class="text-bold">{{location.bien.reference}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Box -->
      <ul ng-switch-when="box" class="list-group-pimped box-biens">
        <li class="list-pimped list-unstyled line" ng-repeat="location in $ctrl.locations.collection">
          <div class="media">
            <div class="media-left">
              <esk-biens-wallet-display-photo ng-if="$ctrl.options.visibleFields.photo"
                                              photo-link="location.bien._links.premierePhoto.href"
                                              routing="!!$ctrl.click ? '' : location._esk.routing"
                                              ng-click="$ctrl.onClick(location)">
              </esk-biens-wallet-display-photo>

              <div class="tw:text-center">
                <span class="text-bold">{{location.bien.reference}}</span>
              </div>
            </div>

            <div class="media-body informations">
              <div class="row margin-bottom-10 resume">
                <div ng-if="$ctrl.options.visibleFields.bullet" class="col-xs-1 col-sm-1 tw:text-center">
                  <span ng-if="location._esk.result._esk.colorStatus.classBullet['bullet-green']" class="bullet-green">
                    <fa-icon [icon]="'circle'"></fa-icon>
                  </span>

                  <span ng-if="location._esk.result._esk.colorStatus.classBullet['bullet-orange']" class="bullet-orange">
                    <fa-icon [icon]="'circle'"></fa-icon>
                  </span>

                  <span ng-if="location._esk.result._esk.colorStatus.classBullet['bullet-blue']" class="bullet-blue">
                    <fa-icon [icon]="'circle'"></fa-icon>
                  </span>

                  <span ng-if="location._esk.result._esk.colorStatus.classBullet['compromis bullet-red']"
                        class="compromis bullet-red"><fa-icon [icon]="'bookmark'"></fa-icon></span>

                  <span ng-if="location._esk.result._esk.colorStatus.classBullet['bullet-red']" class="bullet-red">
                    <fa-icon [icon]="'circle'"></fa-icon>
                  </span>
                </div>

                <div class="col-xs-11 col-sm-11">
                  <esk-location-label-rent ng-if="$ctrl.options.visibleFields.loyer"
                                           location="location"></esk-location-label-rent>

                  <span ng-if="$ctrl.options.visibleFields.nature"
                        class="label label-nature">{{location.bien.nature.label}}</span>

                  <span ng-if="$ctrl.options.visibleFields.statut">
                    <esk-location-label-statut location="location"></esk-location-label-statut>
                  </span>

                  <span ng-if="!location.bien._esk.interne">
                    <span class="label label-confrere">Confrères</span>
                  </span>
                </div>
              </div>

              <div ng-if="$ctrl.options.visibleFields.localisation" class="row margin-bottom-10">
                <div class="col-sm-12 localisation">
                  <fa-icon [icon]="'map-marker-alt'" size="lg"></fa-icon>

                  <p ng-if="$ctrl.options.visibleFields.preciseLocalisation && !!location.bien.option.numeroVoie">
                    {{location.bien.option.numeroVoie}}
                  </p>

                  <p ng-if="$ctrl.options.visibleFields.preciseLocalisation && !!location.bien.option.nomVoie"
                     class="adresse">{{location.bien.option.nomVoie}}</p>

                  <ng-switch on="!!location.bien.option.commune.fullname">
                    <p ng-swicth-default class="text-bold">{{location.bien.option.commune.fullname}}</p>

                    <p ng-switch-when="false" class="text-muted">
                      {{'Aucune commune renseignée' | translate}}
                    </p>
                  </ng-switch>

                  <p ng-show="!!location.bien.option.codePostal">
                    {{location.bien.option.codePostal}}
                  </p>

                  <p ng-show="!!location.bien.option.quartier.name">
                    {{location.bien.option.quartier.name}}
                  </p>
                </div>
              </div>

              <div class="row margin-bottom-10">
                <div class="col-sm-12">
                  <div>
                    <h5 ng-if="$ctrl.options.visibleFields.nomContactsGroup"
                        class="margin-bottom-10">{{location.bien.contactsGroup.nom}}</h5>

                    <div class="margin-bottom-10">{{location.bien.titre}}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </ng-switch>
  </ng-switch>
</div>
`;
