import angularJS from '@shared/angularJS/global.ng';
import {IModule, ITemplateCacheService} from 'angular';
import boxLastTemplate from '@legacy/templates/biens/components/box/last';
import detailsActionTemplate from '@legacy/templates/biens/components/item/details/actions';
import diffusionDetailsHelpTemplate from '@legacy/templates/biens/components/item/diffusion/details/help';
import diffusionDetailsTemplate from '@legacy/templates/biens/components/item/diffusion/details';
import editLocalisationCadastreDoubleTemplate from '@legacy/templates/biens/components/item/edit/localisation/cadastre.double';
import editLocalisationCadastreHelpTemplate from '@legacy/templates/biens/components/item/edit/localisation/cadastre.help';
import editLocalisationCommuneHelpTemplate from '@legacy/templates/biens/components/item/edit/localisation/commune.help';
import saisieFromReferentHelpTemplate from '@legacy/templates/biens/components/item/edit/localisation/saisie-from-referent.help';
import editHeaderTemplate from '@legacy/templates/biens/components/item/edit/header';
import editPhotosTemplate from '@legacy/templates/biens/components/item/edit/photos';
import headerLocalisationTemplate from '@legacy/templates/biens/components/item/header/localisation';
import detailsTemplate from '@legacy/templates/biens/components/item/details';
import diffusionTemplate from '@legacy/templates/biens/components/item/diffusion';
import displayPhotosTemplate from '@legacy/templates/biens/components/item/display.photos';
import headerTemplate from '@legacy/templates/biens/components/item/header';
import popoverTemplate from '@legacy/templates/biens/components/item/popover';
import walletSearchTemplate from '@legacy/templates/biens/components/wallet/search';
import walletDisplayPhotoTemplate from '@legacy/templates/biens/components/wallet.display.photo';
import ctrlEditPhotosTemplate from '@legacy/templates/biens/controllers/item/edit/photos';
import editVirtualVisitTemplate from '@legacy/templates/biens/controllers/item/edit/virtual-visit';
import ctrlDetailsTemplate from '@legacy/templates/biens/controllers/item/details';
import modalAdvancedPrintTemplate from '@legacy/templates/biens/controllers/modals/item/advanced-print';
import modalDiffusionTemplate from '@legacy/templates/biens/controllers/modals/item/diffusion';
import modalDisplayPhotosTemplate from '@legacy/templates/biens/controllers/modals/item/display.photos';
import modalShareDocumentsTemplate from '@legacy/templates/biens/controllers/modals/item/share.documents';
import modalVisibilityPhotoTemplate from '@legacy/templates/biens/controllers/modals/item/visibility-photo';
import modalChoicePasserellesTemplate from '@legacy/templates/biens/controllers/modals/choice.passerelles';
import modalFiltersWalletTemplate from '@legacy/templates/biens/controllers/modals/filters.wallet.current';
import modalFormatAffichettesTemplate from '@legacy/templates/biens/controllers/modals/format-affichettes';
import walletDetailsTemplate from '@legacy/templates/biens/controllers/wallet/diffusion/dossier/details';
import walletNetworkTemplate from '@legacy/templates/biens/controllers/wallet/diffusion/dossier/network';
import walletPasserelleTemplate from '@legacy/templates/biens/controllers/wallet/diffusion/dossier/passerelle';
import walletVITemplate from '@legacy/templates/biens/controllers/wallet/diffusion/dossier/vente-interactive';
import walletSearchCriteriaTemplate from '@legacy/templates/biens/controllers/wallet/search/criteria';
import walletCurrentTemplate from '@legacy/templates/biens/controllers/wallet/current';
import walletDiffusionTemplate from '@legacy/templates/biens/controllers/wallet/diffusion';
import lastCriteriaTemplate from '@legacy/templates/biens/controllers/last.criteria';
import resultsColorBulletTemplate from '@legacy/templates/biens/results.color-bullet';
import editRefPubHelpTemplate from '@legacy/templates/biens/controllers/item/edit/reference-publique.help';

export default function getBiensTemplates(module: IModule): void {
    (function (angular) {
        'use strict';

        module.run(templates);

        /**
         * Templates initialization
         *
         * @param $templateCache
         */
        templates.$inject = ['$templateCache'];
        function templates($templateCache: ITemplateCacheService) {
            $templateCache.put('src/app/legacy/templates/biens/components/box/last.html', boxLastTemplate);
            $templateCache.put('src/app/legacy/templates/biens/components/item/details/actions.html', detailsActionTemplate);
            $templateCache.put('src/app/legacy/templates/biens/components/item/diffusion/details/help.html', diffusionDetailsHelpTemplate);
            $templateCache.put('src/app/legacy/templates/biens/components/item/diffusion/details.html', diffusionDetailsTemplate);
            $templateCache.put('src/app/legacy/templates/biens/components/item/edit/localisation/cadastre.double.html', editLocalisationCadastreDoubleTemplate);
            $templateCache.put('src/app/legacy/templates/biens/components/item/edit/localisation/cadastre.help.html', editLocalisationCadastreHelpTemplate);
            $templateCache.put('src/app/legacy/templates/biens/components/item/edit/localisation/commune.help.html', editLocalisationCommuneHelpTemplate);
            $templateCache.put('src/app/legacy/templates/biens/components/item/edit/localisation/saisie-from-referent.help.html', saisieFromReferentHelpTemplate);
            $templateCache.put('src/app/legacy/templates/biens/components/item/edit/header.html', editHeaderTemplate);
            $templateCache.put('src/app/legacy/templates/biens/components/item/edit/photos.html', editPhotosTemplate);
            $templateCache.put('src/app/legacy/templates/biens/components/item/header/localisation.html', headerLocalisationTemplate);
            $templateCache.put('src/app/legacy/templates/biens/components/item/details.html', detailsTemplate);
            $templateCache.put('src/app/legacy/templates/biens/components/item/diffusion.html', diffusionTemplate);
            $templateCache.put('src/app/legacy/templates/biens/components/item/display.photos.html', displayPhotosTemplate);
            $templateCache.put('src/app/legacy/templates/biens/components/item/header.html', headerTemplate);
            $templateCache.put('src/app/legacy/templates/biens/components/item/popover.html', popoverTemplate);
            $templateCache.put('src/app/legacy/templates/biens/components/wallet/search.html', walletSearchTemplate);
            $templateCache.put('src/app/legacy/templates/biens/components/wallet.display.photo.html', walletDisplayPhotoTemplate);
            $templateCache.put('src/app/legacy/templates/biens/controllers/item/edit/photos.html', ctrlEditPhotosTemplate);
            $templateCache.put('src/app/legacy/templates/biens/controllers/item/edit/reference-publique.help.html', editRefPubHelpTemplate);
            $templateCache.put('src/app/legacy/templates/biens/controllers/item/edit/virtual-visit.html', editVirtualVisitTemplate);
            $templateCache.put('src/app/legacy/templates/biens/controllers/item/details.html', ctrlDetailsTemplate);
            $templateCache.put('src/app/legacy/templates/biens/controllers/modals/item/advanced-print.html', modalAdvancedPrintTemplate);
            $templateCache.put('src/app/legacy/templates/biens/controllers/modals/item/diffusion.html', modalDiffusionTemplate);
            $templateCache.put('src/app/legacy/templates/biens/controllers/modals/item/display.photos.html', modalDisplayPhotosTemplate);
            $templateCache.put('src/app/legacy/templates/biens/controllers/modals/item/share.documents.html', modalShareDocumentsTemplate);
            $templateCache.put('src/app/legacy/templates/biens/controllers/modals/item/visibility-photo.html', modalVisibilityPhotoTemplate);
            $templateCache.put('src/app/legacy/templates/biens/controllers/modals/choice.passerelles.html', modalChoicePasserellesTemplate);
            $templateCache.put('src/app/legacy/templates/biens/controllers/modals/filters.wallet.current.html', modalFiltersWalletTemplate);
            $templateCache.put('src/app/legacy/templates/biens/controllers/modals/format-affichettes.html', modalFormatAffichettesTemplate);
            $templateCache.put('src/app/legacy/templates/biens/controllers/wallet/diffusion/dossier/details.html', walletDetailsTemplate);
            $templateCache.put('src/app/legacy/templates/biens/controllers/wallet/diffusion/dossier/network.html', walletNetworkTemplate);
            $templateCache.put('src/app/legacy/templates/biens/controllers/wallet/diffusion/dossier/passerelle.html', walletPasserelleTemplate);
            $templateCache.put('src/app/legacy/templates/biens/controllers/wallet/diffusion/dossier/vente-interactive.html', walletVITemplate);
            $templateCache.put('src/app/legacy/templates/biens/controllers/wallet/search/criteria.html', walletSearchCriteriaTemplate);
            $templateCache.put('src/app/legacy/templates/biens/controllers/wallet/current.html', walletCurrentTemplate);
            $templateCache.put('src/app/legacy/templates/biens/controllers/wallet/diffusion.html', walletDiffusionTemplate);
            $templateCache.put('src/app/legacy/templates/biens/controllers/last.criteria.html', lastCriteriaTemplate);
            $templateCache.put('src/app/legacy/templates/biens/results.color-bullet.html', resultsColorBulletTemplate);
        }
    })(angularJS);
}
