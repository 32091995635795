import {inject, Injectable} from '@angular/core';
import {EtudeNetworkFactory} from '@models/etudes/etude/networks/network/etude-network.factory';
import {IEtudeNetworkApi} from '@models/etudes/etude/networks/network/etude-network.interfaces';
import EtudeNetwork from '@models/etudes/etude/networks/network/etude-network.model';

@Injectable({providedIn: 'root'})
export class EtudeNetworksFactory {
    private _etudeNetworkFactory = inject(EtudeNetworkFactory);

    create(etudeNetworkApis: IEtudeNetworkApi[] = []): EtudeNetwork[] {
        return (etudeNetworkApis || []).filter(etudeNetworkApi => etudeNetworkApi)
            .map(etudeNetworkApi => this._etudeNetworkFactory.create(etudeNetworkApi));
    }
}
