@if (cReportDossiers$ | async; as cReportDossiers) {
  @if (cReportDossiers.total > 0) {
    <div>
      <div class="titre" [ngPlural]="cReportDossiers.total">
        <ng-template ngPluralCase="=1">Dossier attaché</ng-template>
        <ng-template ngPluralCase="other">Dossiers attachés</ng-template>
      </div>

      <ul class="tw:flex tw:flex-col tw:gap-4">
        @for (reportDossier of cReportDossiers.results; track reportDossier) {
          <li class="tw:flex tw:flex-col tw:gap-2">
            <div class="tw:flex tw:justify-between">
              <div>{{ reportDossier[reportDossier.type]?.reference }}</div>

              <div class="tw:italic tw:text-gray-400">{{ reportDossier.type | titlecase }}</div>
            </div>

            <div class="tw:font-bold tw:truncate">{{ reportDossier[reportDossier.type]?.contactsGroup?.nom }}</div>
          </li>
        }
      </ul>
    </div>
  } @else {
    Aucun dossier attaché
  }
} @else {
  <app-loader [classHeight]="'tw:h-20'" class="tw:block"/>
}
