export default `
<ng-switch on="$ctrl.loading" class="barre-search">
  <p class="text-muted tw:text-center" ng-switch-when="true">
    <fa-icon [icon]="'spinner'" animation="spin"></fa-icon> {{'global.en_cours.LOAD' | translate}}
  </p>

  <div ng-switch-default>
    <form ng-submit="$ctrl.updateCurrentFilter()" class="search-keywords">
      <div class="input-group">
        <input type="search" class="form-control" ng-model="$ctrl.filters[0].queryParameters.keywords"
               placeholder="Rechercher ...">

        <span class="input-group-btn">
          <button ng-if="!!$ctrl.filters[0].queryParameters.keywords" class="btn btn-o btn-null search-keywords-remove"
                  type="button" ng-click="$ctrl.removeKeywords()"><fa-icon [icon]="'times'"></fa-icon></button>

          <button ng-if="!!$ctrl.helpKeywordsTemplate" type="button"
                  uib-popover-template="$ctrl.helpKeywordsTemplate" popover-trigger="'outsideClick'"
                  popover-placement="bottom-right" class="btn btn-o btn-null search-keywords-help">
            <span class="badge badge-info">?</span>
          </button>

          <button type="submit" class="btn btn-default"><fa-icon [icon]="'search'"></fa-icon></button>

          <div ng-if="!!$ctrl.filterModale" class="btn-group search-filter" uib-dropdown>
            <button id="filter-split-button" type="button" class="btn btn-default"
                    ng-click="$ctrl.editFilter($ctrl.filters[0])">
              <fa-icon [icon]="'filter'"></fa-icon> Filtre ...
            </button>

            <button type="button" class="btn btn-default filter-caret" uib-dropdown-toggle>
              <span class="caret"></span>
            </button>

            <ul class="dropdown-menu" uib-dropdown-menu role="menu">
              <li ng-repeat="filter in $ctrl.filters" ng-if="!$first" role="menuitem" class="filter-item">
                <a>
                  <div class="filter-update text-muted" ng-click="$ctrl.editFilter(filter)">
                    <fa-icon [icon]="'pencil-alt'"></fa-icon>
                  </div>

                  <div class="filter-select ellipsis" ng-click="$ctrl.selectFilter(filter)">
                    <fa-icon [icon]="'filter'"></fa-icon> {{filter.nom}}
                  </div>
                </a>
              </li>

              <li role="menuitem">
                <button type="button" class="tw:dropdown-link" ng-click="$ctrl.createFilter()">
                  <fa-icon [icon]="'plus'"></fa-icon><span>Créer un nouveau filtre</span>
                </button>
              </li>
            </ul>
          </div>
        </span>
      </div>
    </form>

    <esk-select-sort type="{{$ctrl.type}}" ng-model="$ctrl.filters[0].queryParameters.sort"
                     on-select="$ctrl.updateCurrentFilter"></esk-select-sort>
  </div>
</ng-switch>
`;
