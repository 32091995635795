import {inject, Injectable} from '@angular/core';
import Links from '@models/links/links.model';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {InotUsersApiService} from '@models/inot-users/inot-users.api.service';
import CInotUsers from '@models/inot-users/collection/inot-users.collection.model';
import {ICInotUsersApi} from '@models/inot-users/collection/inot-users.collection.interfaces';
import {InotUsersFactory} from '@models/inot-users/inot-users.factory';

@Injectable({providedIn: 'root'})
export class CInotUsersFactory {
    private _inotUsersApiService = inject(InotUsersApiService);
    private _inotUsersFactory = inject(InotUsersFactory);

    create(cInotUsersApi: ICInotUsersApi): CInotUsers {
        const cInotUsers = new CInotUsers();

        cInotUsers.links = new Links(cInotUsersApi._links);
        cInotUsers.page = cInotUsersApi.page;
        cInotUsers.pages = cInotUsersApi.pages;
        cInotUsers.perPage = cInotUsersApi.limit;
        cInotUsers.total = cInotUsersApi.total;
        if (cInotUsersApi._embedded) {
            cInotUsers.results = this._inotUsersFactory.create(cInotUsersApi._embedded.items);
        }

        return cInotUsers;
    }

    get$(): Observable<CInotUsers> {
        return this._inotUsersApiService.getCollection$().pipe(map(cInotUsersApi => this.create(cInotUsersApi)));
    }

}
