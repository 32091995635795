<div class="tw:second-nav">
  <div class="tw:second-nav-menus"><h1 class="tw:second-nav-menus-title"><app-navigation-toggle/> Diffusions</h1></div>

  <div class="tw:second-nav-content">
    <div class="tw:block tw:h-full">
      @if (section$ | async; as section) {
        <div class="tw:overflow-hidden tw:h-full tw:flex tw:divide-x tw:divide-gray-200 tw:divide-solid">
          <app-navigation-lateral [sections]="[section]"/> <div class="tw:flex-1"><router-outlet/></div>
        </div>
      } @else {
        <app-loader [classHeight]="'tw:h-40'" class="tw:block tw:p-4"/>
      }
    </div>
  </div>
</div>
