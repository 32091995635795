export default `
<ng-switch on="$ctrl.loader.updating">
  <p class="tw:text-center text-extra-large" ng-switch-when="true">
    <fa-icon [icon]="'spinner'" animation="spin"></fa-icon> Mise à jour de vos références
  </p>

  <ng-switch ng-switch-default on="$ctrl.cEstimationReferences && $ctrl.cEstimationReferences.total > 0">
    <div ng-switch-default class="tw:text-center">
      <div class="text-large padding-bottom-20">Aucune référence sélectionnée</div>

      <ng-include src="'src/app/legacy/templates/estimations/components/item/references/crud/actions.html'">
      </ng-include>
    </div>

    <div ng-switch-when="true">
      <div class="text-right tw:mb-4">
        <ng-include src="'src/app/legacy/templates/estimations/components/item/references/crud/actions.html'">
        </ng-include>
      </div>

      <app-estimation-references-list [c-estimation-references$]="$ctrl.cEstimationReferences$"
                                      [options]="$ctrl.options" (reference-action)="$ctrl.referenceAction($event)">
      </app-estimation-references-list>
    </div>
  </ng-switch>
</ng-switch>
`;
