export default `
<ng-switch on="$ctrl.loader.updating">
  <p ng-switch-when="true" class="padding-top-15 tw:text-center text-extra-large">
    <fa-icon [icon]="'spinner'" animation="spin"></fa-icon> Chargement du bien
  </p>

  <div ng-switch-default class="container-fluid container-fullw tw:bg-white">
    <!-- Property Title -->
    <div class="row details-title">
      <div class="col-sm-6">
        <h1 class="text-bold">
          <button type="button" class="title-lock" ng-click="$ctrl.unlockData = !$ctrl.unlockData">
            <span ng-if="!$ctrl.unlockData"><fa-icon [icon]="'lock'"></fa-icon></span>

            <span ng-if="$ctrl.unlockData"><fa-icon [icon]="'lock-open'"></fa-icon></span>
          </button>

          <span ng-if="$ctrl.setInterest && !$ctrl.interestUpdating">
            <span ng-if="$ctrl.dossier._esk.result._esk.colorStatus.classBullet['bullet-green']" class="bullet-green">
              <fa-icon [icon]="'circle'"></fa-icon>
            </span>

            <span ng-if="$ctrl.dossier._esk.result._esk.colorStatus.classBullet['bullet-orange']" class="bullet-orange">
              <fa-icon [icon]="'circle'"></fa-icon>
            </span>

            <span ng-if="$ctrl.dossier._esk.result._esk.colorStatus.classBullet['bullet-blue']" class="bullet-blue">
              <fa-icon [icon]="'circle'"></fa-icon>
            </span>

            <span ng-if="$ctrl.dossier._esk.result._esk.colorStatus.classBullet['compromis bullet-red']"
                  class="compromis bullet-red"><fa-icon [icon]="'bookmark'"></fa-icon></span>

            <span ng-if="$ctrl.dossier._esk.result._esk.colorStatus.classBullet['bullet-red']" class="bullet-red">
              <fa-icon [icon]="'circle'"></fa-icon>
            </span>
          </span>

          {{$ctrl.dossier.bien.titre}}
        </h1>
      </div>

      <div class="col-sm-6 text-right">
        <div ng-include="'src/app/legacy/templates/biens/components/item/details/actions.html'"></div>
      </div>
    </div>

    <!-- Détail -->
    <div class="container">
      <!-- Short Details -->
      <div class="details-short tw:space-x-1">
        <app-vente-price ng-if="$ctrl.dossier._esk.typeDossier === $ctrl.DossierBienTypesConst.VENTE"
                         [ng-vente]="$ctrl.dossier" [options]="{enabledNV: false}"
                         class="tw:inline-block tw:text-xs"></app-vente-price>

        <esk-location-label-rent ng-if="$ctrl.dossier._esk.typeDossier === $ctrl.DossierBienTypesConst.LOCATION"
                                 location="$ctrl.dossier"></esk-location-label-rent>

        <esk-estimation-label-valeur ng-if="$ctrl.dossier._esk.typeDossier === $ctrl.DossierBienTypesConst.ESTIMATION"
                                     estimation="$ctrl.dossier"></esk-estimation-label-valeur>

        <span class="label label-nature labels-line-height-25">{{$ctrl.dossier.bien.nature.label}}</span>

        <span ng-if="($ctrl.unlockData && ($ctrl.dossier.bien.option.numeroVoie || $ctrl.dossier.bien.option.nomVoie || $ctrl.dossier.bien.option.codePostal)) || (!$ctrl.unlockData && $ctrl.dossier.bien.option.adresseReseau) || $ctrl.dossier.bien.option.commune"
              class="label label-localisation labels-line-height-25">
          <fa-icon [icon]="'map-marker-alt'"></fa-icon>

          <span ng-if="$ctrl.unlockData" ng-switch="$ctrl.dossier.bien._esk.interne">
            <span ng-switch-when="true">
              {{$ctrl.dossier.bien.option.numeroVoie}} {{$ctrl.dossier.bien.option.nomVoie}}
              {{$ctrl.dossier.bien.option.codePostal}}
            </span>

            <span ng-switch-default>{{$ctrl.dossier.bien.option.adresseReseau}}</span>
          </span>

          {{$ctrl.dossier.bien.option.commune.fullname}}
        </span>

        <app-vente-statut ng-if="$ctrl.dossier._esk.typeDossier === $ctrl.DossierBienTypesConst.VENTE"
                          [ng-vente]="$ctrl.dossier" class="tw:inline-block tw:text-xs"></app-vente-statut>

        <esk-location-label-statut ng-if="$ctrl.dossier._esk.typeDossier === $ctrl.DossierBienTypesConst.LOCATION"
                                   location="$ctrl.dossier"class="labels-line-height-25"></esk-location-label-statut>

        <esk-estimation-label-statut ng-if="$ctrl.dossier._esk.typeDossier === $ctrl.DossierBienTypesConst.ESTIMATION"
                                     estimation="$ctrl.dossier"class="labels-line-height-25">
        </esk-estimation-label-statut>

        <div ng-if="$ctrl.dossier._esk.typeDossier === $ctrl.DossierBienTypesConst.ESTIMATION && $ctrl.dossier.bien._esk.interne && $ctrl.unlockData && $ctrl.dossier.dateRemise"
             class="inline">
          Date de remise : <app-ng2-date [date]="$ctrl.dossier.dateRemise" format="shortDate"></app-ng2-date>
        </div>

        <span class="labels-line-height-25"
              uib-tooltip="{{ $ctrl.dossier.bien.responsableDossier.etude.raisonSociale + ', ' + $ctrl.dossier.bien.responsableDossier.convivialName}}">
          Réf. : <span class="text-bold">{{$ctrl.dossier.bien.reference}}</span>
        </span>
      </div>

      <div class="row">
        <!-- Colonne gauche -->
        <div class="col-sm-8 property-col-left">
          <!-- Carrousel -->
          <div ng-if="$ctrl.dossier.bien" class="margin-top-30">
            <esk-bien-display-photos bien="$ctrl.dossier.bien"></esk-bien-display-photos>
          </div>

          <!-- Agencements intérieurs -->
          <div ng-if="$ctrl.showPanel('agencementsInterieurs')" class="properties-description">
            <h3 class="description-title">Agencement intérieur</h3>

            <div class="description-details">
              <div class="row details-item">
                <div ng-if="$ctrl.showField('nombrePieces', $ctrl.dossier.bien.option.nombrePieces)"
                     class="col-xs-6 col-sm-3 tw:text-center">
                  <h3>{{$ctrl.dossier.bien.option.nombrePieces}}</h3>

                  <p>{{'bien.field_name.PIECES' | translate:'{NB:' + $ctrl.dossier.bien.option.nombrePieces + '}'}}</p>
                </div>

                <div ng-if="$ctrl.showField('nombreLogements', $ctrl.dossier.bien.option.nombreLogements)"
                     class="col-xs-6 col-sm-3 tw:text-center">
                  <h3>{{$ctrl.dossier.bien.option.nombreLogements}}</h3>

                  <p>
                    {{'bien.field_name.LOGEMENTS' | translate:'{NB:' + $ctrl.dossier.bien.option.nombreLogements + '}'}}
                  </p>
                </div>

                <div ng-if="$ctrl.showField('nombreEtages', $ctrl.dossier.bien.option.nombreEtages)"
                     class="col-xs-6 col-sm-3 tw:text-center">
                  <h3>{{$ctrl.dossier.bien.option.nombreEtages}}</h3> <p>Nombre de niveaux</p>
                </div>

                <div ng-if="$ctrl.showField('numeroEtage', $ctrl.dossier.bien.option.numeroEtage, {accepted0: true})"
                     class="col-xs-6 col-sm-3 tw:text-center">
                  <h3>{{$ctrl.dossier.bien.option.numeroEtage || 'Rez de Chaussée'}}</h3> <p>Numéro d'étage</p>
                </div>

                <div ng-if="$ctrl.showField('nombreChambres', $ctrl.dossier.bien.option.nombreChambres)"
                     class="col-xs-6 col-sm-3 tw:text-center">
                  <h3>{{$ctrl.dossier.bien.option.nombreChambres}}</h3>

                  <p>
                    {{'bien.field_name.CHAMBRES'
                    | translate:'{NB:' + $ctrl.dossier.bien.option.nombreChambres + '}'}}
                  </p>
                </div>

                <div ng-if="$ctrl.showField('surfaceSejour', $ctrl.dossier.bien.option.surfaceSejour)"
                     class="col-xs-6 col-sm-3 tw:text-center">
                  <h3>{{$ctrl.dossier.bien.option.surfaceSejour}} m²</h3>

                  <p>{{"Surface séjour" | translate}}</p>
                </div>

                <div ng-if="$ctrl.showField('hauteurSousPlafond', $ctrl.dossier.bien.option.hauteurSousPlafond)"
                     class="col-xs-6 col-sm-3 tw:text-center">
                  <h3>{{$ctrl.dossier.bien.option.hauteurSousPlafond}} m</h3>

                  <p>{{"Hauteur sous plafond" | translate}}</p>
                </div>

                <div ng-if="$ctrl.showField('nombreSallesBains', $ctrl.dossier.bien.option.nombreSallesBains)"
                     class="col-xs-6 col-sm-3 tw:text-center">
                  <h3>{{$ctrl.dossier.bien.option.nombreSallesBains}}</h3>

                  <p>
                    {{'bien.field_name.SALLES_BAINS'
                    | translate:'{NB:' + $ctrl.dossier.bien.option.nombreSallesBains + '}'}}
                  </p>
                </div>

                <div ng-if="$ctrl.showField('nombreSallesEau', $ctrl.dossier.bien.option.nombreSallesEau)"
                     class="col-xs-6 col-sm-3 tw:text-center">
                  <h3>{{$ctrl.dossier.bien.option.nombreSallesEau}}</h3>

                  <p>
                    {{'bien.field_name.SALLES_EAU'
                    | translate:'{NB:' + $ctrl.dossier.bien.option.nombreSallesEau + '}'}}
                  </p>
                </div>

                <div ng-if="$ctrl.showField('nombreDouches', $ctrl.dossier.bien.option.nombreDouches)"
                     class="col-xs-6 col-sm-3 tw:text-center">
                  <h3>{{$ctrl.dossier.bien.option.nombreDouches}}</h3>

                  <p>
                    {{'bien.field_name.DOUCHES'
                    | translate:'{NB:' + $ctrl.dossier.bien.option.nombreDouches + '}'}}
                  </p>
                </div>

                <div ng-if="$ctrl.showField('nombreBains', $ctrl.dossier.bien.option.nombreBains)"
                     class="col-xs-6 col-sm-3 tw:text-center">
                  <h3>{{$ctrl.dossier.bien.option.nombreBains}}</h3>

                  <p>
                    {{'bien.field_name.BAIGNOIRES'
                    | translate:'{NB:' + $ctrl.dossier.bien.option.nombreBains + '}'}}
                  </p>
                </div>

                <div ng-if="$ctrl.showField('nombreWc', $ctrl.dossier.bien.option.nombreWc)"
                     class="col-xs-6 col-sm-3 tw:text-center">
                  <h3>{{$ctrl.dossier.bien.option.nombreWc}}</h3> <p>WC</p>
                </div>

                <div ng-if="$ctrl.showField('wcBroyeur', $ctrl.dossier.bien.option.wcBroyeur)"
                   class="col-xs-6 col-sm-3 tw:mt-6 tw:text-center" style="height: 61px">
                  <span class="text-success"><fa-icon [icon]="'check'"></fa-icon></span>

                  <span class="text-bold">WC broyeur</span>
                </div>

                <div ng-if="$ctrl.showField('alarmeLocation', $ctrl.dossier.bien.option.alarmeLocation)"
                   class="col-xs-6 col-sm-3 tw:mt-6 tw:text-center" style="height: 61px">
                  <span class="text-success"><fa-icon [icon]="'check'"></fa-icon></span>

                  <span class="text-bold">Alarme en location</span>
                </div>

                <div ng-if="$ctrl.showField('alarmePleinePropriete', $ctrl.dossier.bien.option.alarmePleinePropriete)"
                   class="col-xs-6 col-sm-3 tw:mt-6 tw:text-center" style="height: 61px">
                  <span class="text-success"><fa-icon [icon]="'check'"></fa-icon></span>

                  <span class="text-bold">Alarme en pleine propriété</span>
                </div>

                <div ng-if="$ctrl.showField('detecteurFumee', $ctrl.dossier.bien.option.detecteurFumee)"
                   class="col-xs-6 col-sm-3 tw:mt-6 tw:text-center" style="height: 61px">
                  <span class="text-success"><fa-icon [icon]="'check'"></fa-icon></span>

                  <span class="text-bold">Détecteur de fumée</span>
                </div>

                <div ng-if="$ctrl.showField('connexionsInternet', $ctrl.dossier.bien.connexionsInternet.length)"
                     class="col-xs-6 col-sm-3 tw:text-center">
                  <h4 class="text-bold">
                    <span ng-repeat="connexionInternet in $ctrl.dossier.bien.connexionsInternet">
                      {{($index > 0 ? " / " : "") + connexionInternet.label}}
                    </span>
                  </h4>

                  <p>
                    {{'bien.field_name.CONNEXIONS_INTERNET'
                    | translate:'{NB:' + $ctrl.dossier.bien.connexionsInternet.length + '}'}}
                  </p>
                </div>
              </div>

              <p
                ng-if="$ctrl.showField('commentsAgencementInterieur', $ctrl.dossier.bien.option.commentsAgencementInterieur)"
                class="details-item" ng-bind-html="$ctrl.dossier.bien.option.commentsAgencementInterieur">
              </p>
            </div>
          </div>

          <!-- Pièces -->
          <div ng-if="$ctrl.showField('surfaces', $ctrl.dossier.bien.pieces.length)" class="properties-description">
            <h3 class="description-title">
              {{'bien.field_name.PIECES' | translate:'{NB:' + $ctrl.dossier.bien.pieces.length + '}'}}
            </h3>

            <div class="description-details">
              <table class="table">
                <thead>
                <tr class="row">
                  <th class="col-xs-3 col-sm-2"></th>

                  <th class="col-xs-3 col-sm-2">Surface</th>

                  <th class="col-xs-4 col-sm-2">Étage</th>

                  <th ng-if="$ctrl.dossier.bien._esk.surfacePiecesHabitables > 0 || $ctrl.dossier.bien._esk.surfacePiecesNonHabitables > 0"
                      class="col-xs-2 col-sm-1 hidden-xs">Habitable</th>

                  <th class="col-sm-5 hidden-xs">Commentaire</th>
                </tr>
                </thead>

                <tr class="row" ng-repeat="piece in $ctrl.dossier.bien.pieces" ng-if="!piece.isEmpty()">
                  <td class="col-xs-3 col-sm-2 text-bold">
                    <span ng-if="!!piece.piece">{{piece.piece.label}}</span>

                    <span ng-if="!!piece.otherLabel">{{piece.otherLabel}}</span>
                  </td>

                  <td class="col-xs-3 col-sm-2">
                    {{$ctrl.getNumber(piece.surface) | currency:'m²':2 * ($ctrl.getNumber(piece.surface) % 1 !== 0)}}
                  </td>

                  <td class="col-xs-4 col-sm-2">{{piece.etage.label}}</td>

                  <td ng-if="$ctrl.dossier.bien._esk.surfacePiecesHabitables > 0 || $ctrl.dossier.bien._esk.surfacePiecesNonHabitables > 0"
                      class="col-xs-2 col-sm-1 hidden-xs">{{piece.habitable ? 'Oui' : ''}}</td>

                  <td class="col-sm-5 hidden-xs">
                    <p class="ellipsis pre-wrap" ng-bind-html="piece.comments"></p>
                  </td>
                </tr>
              </table>
            </div>
          </div>

          <!-- Logements -->
          <div ng-if="$ctrl.showField('logements', $ctrl.dossier.bien.logements.length)"
               class="properties-description">
            <h3 class="description-title">
              {{'bien.field_name.LOGEMENTS' |
              translate:'{NB:' + $ctrl.dossier.bien.logements.length + '}'}}
            </h3>

            <div class="description-details">
              <table class="table">
                <thead>
                <tr class="row">
                  <th class="col-xs-4 col-sm-2"></th>

                  <th class="col-xs-4 col-sm-2">{{"Surface" | translate}}</th>

                  <th class="col-xs-4 col-sm-2">{{"Étage" | translate}}</th>

                  <th class="col-sm-6 hidden-xs">{{"Commentaire" | translate}}</th>
                </tr>
                </thead>

                <tr class="row" ng-repeat="logement in $ctrl.dossier.bien.logements" ng-if="!logement.isEmpty()">
                  <td class="col-xs-4 col-sm-2 text-bold">
                    <span ng-if="!!logement.logement">{{logement.logement.label}}</span>

                    <span ng-if="!!logement.otherLabel">{{logement.otherLabel}}</span>
                  </td>

                  <td class="col-xs-4 col-sm-2">
                    {{$ctrl.getNumber(logement.surface) | currency:'m²':2 * ($ctrl.getNumber(logement.surface) % 1 !== 0)}}
                  </td>

                  <td class="col-xs-4 col-sm-2">{{logement.etage.label}}</td>

                  <td class="col-sm-6 hidden-xs">
                    <p class="ellipsis pre-wrap" ng-bind-html="logement.comments"></p>
                  </td>
                </tr>
              </table>
            </div>
          </div>

          <!-- Agencements -->
          <div ng-if="$ctrl.showPanel('agencements')" class="properties-description">
            <h3 class="description-title">{{"Agencements" | translate}}</h3>

            <div class="description-details">
              <div class="row details-item">
                <p ng-if="$ctrl.showField('cuisineEquipee', $ctrl.dossier.bien.option.cuisineEquipee)"
                   class="col-sm-6 col-md-4 tw:text-center">
                  <span class="text-success"><fa-icon [icon]="'check'"></fa-icon></span>

                  <span class="text-bold">{{"Cuisine équipée" | translate}}</span>
                </p>

                <p ng-if="$ctrl.showField('cheminee', $ctrl.dossier.bien.option.cheminee)"
                   class="col-sm-6 col-md-4 tw:text-center">
                  <span class="text-success"><fa-icon [icon]="'check'"></fa-icon></span>

                  <span class="text-bold">
                    {{'bien.field_name.CHEMINEES'
                    | translate:'{NB:' + ($ctrl.dossier.bien.option.nombreCheminees || 1) + '}'}}
                  </span>

                  <span ng-if="$ctrl.dossier.bien.option.chemineeOrnement">
                    {{'Ornementale(s)' | translate}}
                  </span>

                  <span
                    ng-if="$ctrl.dossier.bien.option.chemineeOrnement && $ctrl.dossier.bien.option.chemineeFonctionnelle">
                    /
                  </span>

                  <span ng-if="$ctrl.dossier.bien.option.chemineeFonctionnelle">
                    {{"Fonctionnelle(s)" | translate}}
                  </span>
                </p>

                <p ng-if="$ctrl.showField('ascenseur', $ctrl.dossier.bien.option.ascenseur)"
                   class="col-sm-6 col-md-4 tw:text-center">
                  <span class="text-success"><fa-icon [icon]="'check'"></fa-icon></span>

                  <span class="text-bold">{{"Ascenseur" | translate}}</span>
                </p>

                <p ng-if="$ctrl.showField('dernierEtage', $ctrl.dossier.bien.option.dernierEtage)"
                   class="col-sm-6 col-md-4 tw:text-center">
                  <span class="text-success"><fa-icon [icon]="'check'"></fa-icon></span>

                  <span class="text-bold">{{"Dernier étage" | translate}}</span>
                </p>

                <p ng-if="$ctrl.showField('grenier', $ctrl.dossier.bien.option.grenier)"
                   class="col-sm-6 col-md-4 tw:text-center">
                  <span class="text-success"><fa-icon [icon]="'check'"></fa-icon></span>

                  <span class="text-bold">{{"Grenier" | translate}}</span>
                </p>

                <p ng-if="$ctrl.showField('comblesAmenageables', $ctrl.dossier.bien.option.comblesAmenageables)"
                   class="col-sm-6 col-md-4 tw:text-center">
                  <span class="text-success"><fa-icon [icon]="'check'"></fa-icon></span>

                  <span class="text-bold">{{"Combles" | translate}}</span>
                </p>

                <p ng-if="$ctrl.showField('balcon', $ctrl.dossier.bien.option.balcon)"
                   class="col-sm-6 col-md-4 tw:text-center">
                  <span class="text-success"><fa-icon [icon]="'check'"></fa-icon></span>

                  <span class="text-bold">{{"Balcon" | translate}}</span>

                  <span ng-if="$ctrl.getNumber($ctrl.dossier.bien.option.surfaceBalcon) > 0" class="text-bold">
                    {{$ctrl.getNumber($ctrl.dossier.bien.option.surfaceBalcon) |
                    currency:'m²':2 * ($ctrl.getNumber($ctrl.dossier.bien.option.surfaceBalcon) % 1 !== 0)}}
                  </span>
                </p>

                <p ng-if="$ctrl.showField('cave', $ctrl.dossier.bien.option.cave)"
                   class="col-sm-6 col-md-4 tw:text-center">
                  <span class="text-success"><fa-icon [icon]="'check'"></fa-icon></span>

                  <span class="text-bold">{{"Cave" | translate}}</span>

                  <span ng-if="$ctrl.getNumber($ctrl.dossier.bien.option.surfaceCave) > 0" class="text-bold">
                    {{$ctrl.getNumber($ctrl.dossier.bien.option.surfaceCave) |
                    currency:'m²':2 * ($ctrl.getNumber($ctrl.dossier.bien.option.surfaceCave) % 1 !== 0)}}
                  </span>
                </p>

                <p ng-if="$ctrl.showField('sousSol', $ctrl.dossier.bien.option.sousSol)"
                   class="col-sm-6 col-md-4 tw:text-center">
                  <span class="text-success"><fa-icon [icon]="'check'"></fa-icon></span>

                  <span class="text-bold">{{"Sous-sol" | translate}}</span>
                </p>

                <p ng-if="$ctrl.showField('veranda', $ctrl.dossier.bien.option.veranda)"
                   class="col-sm-6 col-md-4 tw:text-center">
                  <span class="text-success"><fa-icon [icon]="'check'"></fa-icon></span>

                  <span class="text-bold">{{"Véranda" | translate}}</span>
                </p>

                <p ng-if="$ctrl.showField('terrasse', $ctrl.dossier.bien.option.terrasse)"
                   class="col-sm-6 col-md-4 tw:text-center">
                  <span class="text-success"><fa-icon [icon]="'check'"></fa-icon></span>

                  <span class="text-bold">{{"Terrasse" | translate}}</span>

                  <span ng-if="$ctrl.getNumber($ctrl.dossier.bien.option.surfaceTerrasse) > 0" class="text-bold">
                    {{$ctrl.getNumber($ctrl.dossier.bien.option.surfaceTerrasse) |
                    currency:'m²':2 * ($ctrl.getNumber($ctrl.dossier.bien.option.surfaceTerrasse) % 1 !== 0)}}
                  </span>
                </p>

                <p ng-if="$ctrl.showField('cour', $ctrl.dossier.bien.option.cour)"
                   class="col-sm-6 col-md-4 tw:text-center">
                  <span class="text-success"><fa-icon [icon]="'check'"></fa-icon></span>

                  <span class="text-bold">{{"Cour" | translate}}</span>

                  <span ng-if="$ctrl.getNumber($ctrl.dossier.bien.option.surfaceCour) > 0" class="text-bold">
                    {{$ctrl.getNumber($ctrl.dossier.bien.option.surfaceCour) |
                    currency:'m²':2 * ($ctrl.getNumber($ctrl.dossier.bien.option.surfaceCour) % 1 !== 0)}}
                  </span>
                </p>

                <p ng-if="$ctrl.showField('piscine', $ctrl.dossier.bien.option.piscine)"
                   class="col-sm-6 col-md-4 tw:text-center">
                  <span class="text-success"><fa-icon [icon]="'check'"></fa-icon></span>

                  <span class="text-bold">{{"Piscine" | translate}}</span>
                </p>

                <p ng-if="$ctrl.showField('annexe', $ctrl.dossier.bien.option.annexe)"
                   class="col-sm-6 col-md-4 tw:text-center">
                  <span class="text-success"><fa-icon [icon]="'check'"></fa-icon></span>

                  <span class="text-bold">{{"Annexe(s)" | translate}}</span>

                  <span ng-if="$ctrl.getNumber($ctrl.dossier.bien.option.surfaceAnnexe) > 0" class="text-bold">
                    {{$ctrl.getNumber($ctrl.dossier.bien.option.surfaceAnnexe) |
                    currency:'m²':2 * ($ctrl.getNumber($ctrl.dossier.bien.option.surfaceAnnexe) % 1 !== 0)}}
                  </span>
                </p>

                <p ng-if="$ctrl.showField('garage', $ctrl.dossier.bien.option.garage)"
                   class="col-sm-6 col-md-4 tw:text-center">
                  <span class="text-success"><fa-icon [icon]="'check'"></fa-icon></span>

                  <span class="text-bold">{{"Garage" | translate}}</span>

                  <span ng-if="$ctrl.getNumber($ctrl.dossier.bien.option.surfaceGarage) > 0" class="text-bold">
                    {{$ctrl.getNumber($ctrl.dossier.bien.option.surfaceGarage) |
                    currency:'m²':2 * ($ctrl.getNumber($ctrl.dossier.bien.option.surfaceGarage) % 1 !== 0)}}
                  </span>
                </p>

                <p ng-if="$ctrl.showField('parking', $ctrl.dossier.bien.option.nombrePlacesParking)"
                   class="col-sm-6 col-md-4 tw:text-center">
                  <span class="text-success"><fa-icon [icon]="'check'"></fa-icon></span>

                  <span class="text-bold">
                    {{'bien.field_name.PARKINGS'
                    | translate:'{NB:' + $ctrl.dossier.bien.option.nombrePlacesParking + '}'}}
                  </span>
                </p>

                <p ng-if="$ctrl.showField('citerneGazLocation', $ctrl.dossier.bien.option.citerneGazLocation)"
                   class="col-sm-6 col-md-4 tw:text-center">
                  <span class="text-success"><fa-icon [icon]="'check'"></fa-icon></span>

                  <span class="text-bold">Citerne gaz en location</span>
                </p>

                <p ng-if="$ctrl.showField('citerneGazPleinePropriete', $ctrl.dossier.bien.option.citerneGazPleinePropriete)"
                   class="col-sm-6 col-md-4 tw:text-center">
                  <span class="text-success"><fa-icon [icon]="'check'"></fa-icon></span>

                  <span class="text-bold">Citerne gaz en pleine propriété</span>
                </p>

                <p ng-if="$ctrl.showField('cuveFuelAerienne', $ctrl.dossier.bien.option.cuveFuelAerienne)"
                   class="col-sm-6 col-md-4 tw:text-center">
                  <span class="text-success"><fa-icon [icon]="'check'"></fa-icon></span>

                  <span class="text-bold">Cuve à fuel aérienne</span>
                </p>

                <p ng-if="$ctrl.showField('cuveFuelEnterree', $ctrl.dossier.bien.option.cuveFuelEnterree)"
                   class="col-sm-6 col-md-4 tw:text-center">
                  <span class="text-success"><fa-icon [icon]="'check'"></fa-icon></span>

                  <span class="text-bold">Cuve à fuel enterrée</span>
                </p>

                <p ng-if="$ctrl.showField('cuveInactiveDegazee', $ctrl.dossier.bien.option.cuveInactiveDegazee)"
                   class="col-sm-6 col-md-4 tw:text-center">
                  <span class="text-success"><fa-icon [icon]="'check'"></fa-icon></span>

                  <span class="text-bold">Cuve inactive dégazée</span>
                </p>

                <p ng-if="$ctrl.showField('cuveInactiveNeutralizee', $ctrl.dossier.bien.option.cuveInactiveNeutralizee)"
                   class="col-sm-6 col-md-4 tw:text-center">
                  <span class="text-success"><fa-icon [icon]="'check'"></fa-icon></span>

                  <span class="text-bold">Cuve inactive neutralisée</span>
                </p>
              </div>

              <p
                ng-if="$ctrl.showField('commentsAgencementExterieur', $ctrl.dossier.bien.option.commentsAgencementExterieur)"
                class="details-item" ng-bind-html="$ctrl.dossier.bien.option.commentsAgencementExterieur">
              </p>
            </div>
          </div>

          <!-- Bâtiment -->
          <div ng-if="$ctrl.showPanel('batiment')" class="properties-description">
            <h3 class="description-title">{{"Bâtiment" | translate}}</h3>

            <div class="description-details">
              <div
                ng-if="$ctrl.showField('anneeConstruction', $ctrl.dossier.bien.option.anneeConstruction) || $ctrl.showField('epoqueConstruction', $ctrl.dossier.bien.option.epoqueConstruction) || $ctrl.showField('etat', $ctrl.dossier.bien.option.etat) || $ctrl.showField('surfaceCarrez', $ctrl.dossier.bien.option.surfaceCarrez) || $ctrl.showField('exposition', $ctrl.dossier.bien.option.exposition) || $ctrl.showField('mitoyennete', $ctrl.dossier.bien.option.mitoyennete) || $ctrl.showField('nombreEtagesBatiment', $ctrl.dossier.bien.option.nombreEtagesBatiment) || $ctrl.showField('aucunChauffage', $ctrl.dossier.bien.option.aucunChauffage)"
                class="row details-item">
                <div ng-if="$ctrl.showField('anneeConstruction', $ctrl.dossier.bien.option.anneeConstruction)"
                     class="col-xs-6 col-sm-3 tw:text-center">
                  <h3>{{$ctrl.dossier.bien.option.anneeConstruction}}</h3>

                  <p>{{"Année de construction" | translate}}</p>
                </div>

                <div ng-if="$ctrl.showField('epoqueConstruction', $ctrl.dossier.bien.option.epoqueConstruction)"
                     class="col-xs-6 col-sm-3 tw:text-center">
                  <h3>{{$ctrl.dossier.bien.option.epoqueConstruction.label}}</h3>

                  <p>{{"Époque de construction" | translate}}</p>
                </div>

                <div ng-if="$ctrl.showField('etat', $ctrl.dossier.bien.option.etat)"
                     class="col-xs-6 col-sm-3 tw:text-center">
                  <h3>{{$ctrl.dossier.bien.option.etat.label}}</h3>

                  <p>{{"État général" | translate}}</p>
                </div>

                <div ng-if="$ctrl.showField('surfaceCarrez', $ctrl.dossier.bien.option.surfaceCarrez)"
                     class="col-xs-6 col-sm-3 tw:text-center">
                  <h3>
                    {{$ctrl.getNumber($ctrl.dossier.bien.option.surfaceCarrez) |
                    currency:'m²':2 * ($ctrl.getNumber($ctrl.dossier.bien.option.surfaceCarrez) % 1 !== 0)}}
                  </h3>

                  <p>{{"Surface Carrez" | translate}}</p>
                </div>

                <div ng-if="$ctrl.showField('exposition', $ctrl.dossier.bien.option.exposition)"
                     class="col-xs-6 col-sm-3 tw:text-center">
                  <h3>{{$ctrl.dossier.bien.option.exposition.label}}</h3>

                  <p>{{"Exposition" | translate}}</p>
                </div>

                <div ng-if="$ctrl.showField('mitoyennete', $ctrl.dossier.bien.option.mitoyennete)"
                     class="col-xs-6 col-sm-3 tw:text-center">
                  <h3>{{$ctrl.dossier.bien.option.mitoyennete.label}}</h3>
                </div>

                <div ng-if="$ctrl.showField('nombreEtagesBatiment', $ctrl.dossier.bien.option.nombreEtagesBatiment)"
                     class="col-xs-6 col-sm-3 tw:text-center">
                  <h3>{{$ctrl.dossier.bien.option.nombreEtagesBatiment}}</h3>

                  <p>{{"Nombre étages du bâtiment" | translate}}</p>
                </div>

                <p ng-if="$ctrl.showField('aucunChauffage', $ctrl.dossier.bien.option.aucunChauffage)"
                   class="col-xs-6 col-sm-3 tw:text-center">
                  <span class="text-success"><fa-icon [icon]="'check'"></fa-icon></span>

                  <span class="text-bold">{{"Aucun chauffage" | translate}}</span>
                </p>
              </div>

              <div ng-if="!$ctrl.dossier.bien.option.aucunChauffage && $ctrl.showField('chauffage')"
                   class="row details-item">
                <p ng-if="$ctrl.showField('chauffageCollectif', $ctrl.dossier.bien.option.chauffageCollectif)"
                   class="col-sm-6 col-md-4 tw:text-center">
                  <span class="text-success"><fa-icon [icon]="'check'"></fa-icon></span>

                  <span class="text-bold">Chauffage collectif</span>
                </p>

                <div ng-if="$ctrl.showField('chauffages', $ctrl.dossier.bien.chauffages.length)"
                     class="col-sm-6 col-md-4 tw:text-center">
                  <span class="text-success"><fa-icon [icon]="'check'"></fa-icon></span>

                  <span class="text-bold">
                    {{'bien.field_name.CHAUFFAGES'
                    | translate:'{NB:' + $ctrl.dossier.bien.chauffages.length + '}'}}
                  </span>

                  <span ng-repeat="chauffage in $ctrl.dossier.bien.chauffages" class="text-bold">
                    {{($index > 0 ? " / " : " : ") + chauffage.label}}
                  </span>
                </div>
              </div>

              <div class="row details-item">
                <div ng-if="$ctrl.showField('materiauxConstruction', $ctrl.dossier.bien.materiauxConstruction.length)"
                     class="col-md-6 tw:text-center">
                  <div class="row">
                    <p class="col-xs-5 text-right">{{"Matériaux de construction" | translate}}</p>

                    <p class="col-xs-7 text-bold">
                      <span ng-repeat="materiauConstruction in $ctrl.dossier.bien.materiauxConstruction">
                        {{($index > 0 ? " / " : "") + materiauConstruction.label}}
                      </span>
                    </p>
                  </div>
                </div>

                <div ng-if="$ctrl.showField('materiauxCouverture', $ctrl.dossier.bien.materiauxCouverture.length)"
                     class="col-md-6 tw:text-center">
                  <div class="row">
                    <p class="col-xs-5 text-right">{{"Matériaux de toiture" | translate}}</p>

                    <p class="col-xs-7 text-bold">
                      <span ng-repeat="materiauCouverture in $ctrl.dossier.bien.materiauxCouverture">
                        {{($index > 0 ? " / " : "") + materiauCouverture.label}}
                      </span>
                    </p>
                  </div>
                </div>

                <div ng-if="$ctrl.showField('charpentes', $ctrl.dossier.bien.charpentes.length)"
                     class="col-md-6 tw:text-center">
                  <div class="row">
                    <p class="col-xs-5 text-right">{{"Charpente" | translate}}</p>

                    <p class="col-xs-7 text-bold">
                      <span ng-repeat="charpente in $ctrl.dossier.bien.charpentes">
                        {{($index > 0 ? " / " : "") + charpente.label}}
                      </span>
                    </p>
                  </div>
                </div>

                <div ng-if="$ctrl.showField('isolations', $ctrl.dossier.bien.isolations.length)"
                     class="col-md-6 tw:text-center">
                  <div class="row">
                    <p class="col-xs-5 text-right">{{"Isolation" | translate}}</p>

                    <p class="col-xs-7 text-bold">
                      <span ng-repeat="isolation in $ctrl.dossier.bien.isolations">
                        {{($index > 0 ? " / " : "") + isolation.label}}
                      </span>
                    </p>
                  </div>
                </div>

                <div ng-if="$ctrl.showField('huisseries', $ctrl.dossier.bien.huisseries.length)"
                     class="col-md-6 tw:text-center">
                  <div class="row">
                    <p class="col-xs-5 text-right">{{"Huisseries" | translate}}</p>

                    <p class="col-xs-7 text-bold">
                      <span ng-repeat="huisserie in $ctrl.dossier.bien.huisseries">
                        {{($index > 0 ? " / " : "") + huisserie.label}}
                      </span>
                    </p>
                  </div>
                </div>

                <div ng-if="$ctrl.showField('volets', $ctrl.dossier.bien.volets.length)"
                     class="col-md-6 tw:text-center">
                  <div class="row">
                    <p class="col-xs-5 text-right">{{"Volets" | translate}}</p>

                    <p class="col-xs-7 text-bold">
                      <span ng-repeat="volet in $ctrl.dossier.bien.volets">
                        {{($index > 0 ? " / " : "") + volet.label}}
                      </span>
                    </p>
                  </div>
                </div>
              </div>

              <p ng-if="$ctrl.showField('commentsBatiment', $ctrl.dossier.bien.option.commentsBatiment)"
                 class="details-item" ng-bind-html="$ctrl.dossier.bien.option.commentsBatiment">
              </p>
            </div>
          </div>

          <!-- Terrain -->
          <div ng-if="$ctrl.showPanel('terrain')" class="properties-description">
            <h3 class="description-title">{{"Terrain" | translate}}</h3>

            <div class="description-details">
              <div
                ng-if="$ctrl.showField('surfaceCadastrale', $ctrl.dossier.bien.option.surfaceCadastrale) || $ctrl.showField('surfaceBatie', $ctrl.dossier.bien.option.surfaceBatie) || $ctrl.showField('surfaceNonBatie', $ctrl.dossier.bien.option.surfaceNonBatie) || $ctrl.showField('surfacePlancher', $ctrl.dossier.bien.option.surfacePlancher) || $ctrl.showField('longueurFacade', $ctrl.dossier.bien.option.longueurFacade) || $ctrl.showField('declivite', $ctrl.dossier.bien.option.declivite)"
                class="row details-item">
                <div ng-if="$ctrl.showField('surfaceCadastrale', $ctrl.dossier.bien.option.surfaceCadastrale)"
                     class="col-xs-6 col-sm-3 tw:text-center">
                  <h3>
                    {{$ctrl.getNumber($ctrl.dossier.bien.option.surfaceCadastrale) |
                    currency:'m²':2 * ($ctrl.getNumber($ctrl.dossier.bien.option.surfaceCadastrale) % 1 !== 0)}}
                  </h3>

                  <p>{{"Surface cadastrale" | translate}}</p>
                </div>

                <div ng-if="$ctrl.showField('surfaceBatie', $ctrl.dossier.bien.option.surfaceBatie)"
                     class="col-xs-6 col-sm-3 tw:text-center">
                  <h3>
                    {{$ctrl.getNumber($ctrl.dossier.bien.option.surfaceBatie) |
                    currency:'m²':2 * ($ctrl.getNumber($ctrl.dossier.bien.option.surfaceBatie) % 1 !== 0)}}
                  </h3>

                  <p>{{"Surface bâtie au sol" | translate}}</p>
                </div>

                <div ng-if="$ctrl.showField('surfaceNonBatie', $ctrl.dossier.bien.option.surfaceNonBatie)"
                     class="col-xs-6 col-sm-3 tw:text-center">
                  <h3>
                    {{$ctrl.getNumber($ctrl.dossier.bien.option.surfaceNonBatie) |
                    currency:'m²':2 * ($ctrl.getNumber($ctrl.dossier.bien.option.surfaceNonBatie) % 1 !== 0)}}
                  </h3>

                  <p>{{"Surface non bâtie" | translate}}</p>
                </div>

                <div ng-if="$ctrl.showField('surfacePlancher', $ctrl.dossier.bien.option.surfacePlancher)"
                     class="col-xs-6 col-sm-3 tw:text-center">
                  <h3>
                    {{$ctrl.getNumber($ctrl.dossier.bien.option.surfacePlancher) |
                    currency:'m²':2 * ($ctrl.getNumber($ctrl.dossier.bien.option.surfacePlancher) % 1 !== 0)}}
                  </h3>

                  <p>{{"Surface plancher" | translate}}</p>
                </div>

                <div ng-if="$ctrl.showField('longueurFacade', $ctrl.dossier.bien.option.longueurFacade)"
                     class="col-xs-6 col-sm-3 tw:text-center">
                  <h3>
                    {{$ctrl.getNumber($ctrl.dossier.bien.option.longueurFacade) |
                    currency:'m':2 * ($ctrl.getNumber($ctrl.dossier.bien.option.longueurFacade) % 1 !== 0)}}
                  </h3>

                  <p>{{"Longueur de façade" | translate}}</p>
                </div>

                <ng-switch on="$ctrl.dossier.bien.option.degreDeclivite > 0"
                           ng-if="$ctrl.showField('declivite', $ctrl.dossier.bien.option.declivite)"
                           class="col-xs-6 col-sm-3 tw:text-center">
                  <h3 ng-switch-when="false">{{"Déclivité" | translate}}</h3>

                  <div ng-switch-default>
                    <h3>
                      {{$ctrl.getNumber($ctrl.dossier.bien.option.degreDeclivite) |
                      currency:'°':2 * ($ctrl.getNumber($ctrl.dossier.bien.option.degreDeclivite) % 1 !== 0)}}
                    </h3>

                    <p>{{"Déclivité" | translate}}</p>
                  </div>
                </ng-switch>
              </div>

              <div
                ng-if="$ctrl.showField('zoneInondable', $ctrl.dossier.bien.option.zoneInondable) || $ctrl.showField('viabilisable', $ctrl.dossier.bien.option.viabilisable) || $ctrl.showField('pointEauVille', $ctrl.dossier.bien.option.pointEauVille) || $ctrl.showField('pointEauCampagne', $ctrl.dossier.bien.option.pointEauCampagne) || $ctrl.showField('clotures', $ctrl.dossier.bien.clotures.length)"
                class="row details-item">
                <p ng-if="$ctrl.showField('zoneInondable', $ctrl.dossier.bien.option.zoneInondable)"
                   class="col-sm-6 col-md-4 tw:text-center">
                  <span class="text-success"><fa-icon [icon]="'check'"></fa-icon></span>

                  <span class="text-bold">{{"Zone inondable" | translate}}</span>
                </p>

                <p ng-if="$ctrl.showField('pointEauVille', $ctrl.dossier.bien.option.pointEauVille)"
                   class="col-sm-6 col-md-4 tw:text-center">
                  <span class="text-success"><fa-icon [icon]="'check'"></fa-icon></span>

                  <span class="text-bold">Robinet, puits, ...</span>
                </p>

                <p ng-if="$ctrl.showField('pointEauCampagne', $ctrl.dossier.bien.option.pointEauCampagne)"
                   class="col-sm-6 col-md-4 tw:text-center">
                  <span class="text-success"><fa-icon [icon]="'check'"></fa-icon></span>

                  <span class="text-bold">Mare, étang, lac, ...</span>
                </p>

                <p ng-if="$ctrl.showField('panneauxPhotovoltaiques', $ctrl.dossier.bien.option.panneauxPhotovoltaiques)"
                   class="col-sm-6 col-md-4 tw:text-center">
                  <span class="text-success"><fa-icon [icon]="'check'"></fa-icon></span>

                  <span class="text-bold">Panneaux photovoltaïques</span>
                </p>

                <div ng-if="$ctrl.showField('clotures', $ctrl.dossier.bien.clotures.length)"
                     class="col-sm-6 col-md-4 tw:text-center">
                  <span class="text-success"><fa-icon [icon]="'check'"></fa-icon></span>

                  <span class="text-bold">
                    {{'bien.field_name.CLOTURES'
                    | translate:'{NB:' + $ctrl.dossier.bien.clotures.length + '}'}}
                  </span>

                  <span ng-repeat="cloture in $ctrl.dossier.bien.clotures"
                        class="text-bold">
                    {{($index > 0 ? " / " : " : ") + cloture.label}}
                  </span>
                </div>
              </div>

              <div ng-if="$ctrl.showField('servitude', $ctrl.dossier.bien.option.servitude)"
                   class="details-item">
                <p>{{"Servitude(s) connue(s)" | translate}}</p>

                <p ng-bind-html="$ctrl.dossier.bien.option.servitude"></p>
              </div>

              <p ng-if="$ctrl.showField('commentsTerrain', $ctrl.dossier.bien.option.commentsTerrain)"
                 class="details-item" ng-bind-html="$ctrl.dossier.bien.option.commentsTerrain">
              </p>
            </div>
          </div>

          <!-- Viabilisation -->
          <div ng-if="$ctrl.showPanel('viabilisation')" class="properties-description">
            <h3 class="description-title">{{"Viabilisation" | translate}}</h3>

            <div class="description-details">
              <div class="row details-item">
                <div ng-if="$ctrl.showField('viabilisable', $ctrl.dossier.bien.option.viabilisable)"
                     class="col-sm-6 col-md-4 tw:text-center">
                  <p>
                    <span class="text-success"><fa-icon [icon]="'check'"></fa-icon></span>

                    <span class="text-bold">{{"Viabilisable" | translate}}</span>
                  </p>
                </div>

                <div ng-if="$ctrl.showField('viabilisationEau', $ctrl.dossier.bien.option.viabilisationEau)"
                     class="col-sm-6 col-md-4 tw:text-center">
                  <p>
                    <span class="text-success"><fa-icon [icon]="'check'"></fa-icon></span>

                    <span class="text-bold">{{"Raccordé en Eau" | translate}}</span>
                  </p>
                </div>

                <div
                  ng-if="$ctrl.showField('viabilisationElectricite', $ctrl.dossier.bien.option.viabilisationElectricite)"
                  class="col-sm-6 col-md-4 tw:text-center">
                  <p>
                    <span class="text-success"><fa-icon [icon]="'check'"></fa-icon></span>

                    <span class="text-bold">{{"Raccordé à l'Électricité" | translate}}</span>
                  </p>
                </div>

                <div ng-if="$ctrl.showField('viabilisationTelecom', $ctrl.dossier.bien.option.viabilisationTelecom)"
                     class="col-sm-6 col-md-4 tw:text-center">
                  <p>
                    <span class="text-success"><fa-icon [icon]="'check'"></fa-icon></span>

                    <span class="text-bold">{{"Raccordé en Télécom" | translate}}</span>
                  </p>
                </div>

                <div ng-if="$ctrl.showField('viabilisationFibre', $ctrl.dossier.bien.option.viabilisationFibre)"
                     class="col-sm-6 col-md-4 tw:text-center">
                  <p>
                    <span class="text-success"><fa-icon [icon]="'check'"></fa-icon></span>

                    <span class="text-bold">{{"Raccordé à la Fibre optique" | translate}}</span>
                  </p>
                </div>

                <div ng-if="$ctrl.showField('viabilisationVoirie', $ctrl.dossier.bien.option.viabilisationVoirie)"
                     class="col-sm-6 col-md-4 tw:text-center">
                  <p>
                    <span class="text-success"><fa-icon [icon]="'check'"></fa-icon></span>

                    <span class="text-bold">{{"Raccordé à la Voirie" | translate}}</span>
                  </p>
                </div>

                <div ng-if="$ctrl.showField('viabilisationGaz', $ctrl.dossier.bien.option.viabilisationGaz)"
                     class="col-sm-6 col-md-4 tw:text-center">
                  <p>
                    <span class="text-success"><fa-icon [icon]="'check'"></fa-icon></span>

                    <span class="text-bold">{{"Raccordé au Gaz" | translate}}</span>
                  </p>
                </div>

                <div ng-if="$ctrl.showField('aucunAssainissement', $ctrl.dossier.bien.option.aucunAssainissement)"
                     class="col-sm-6 col-md-4 tw:text-center">
                  <p>
                    <span class="text-warning"><fa-icon [icon]="'times'"></fa-icon></span>

                    <span class="text-bold">Aucun assainissement</span>
                  </p>
                </div>

                <div ng-if="$ctrl.showField('assainissementNonConforme', $ctrl.dossier.bien.option.assainissementNonConforme)"
                     class="col-sm-6 col-md-4 tw:text-center">
                  <p>
                    <span class="text-warning"><fa-icon [icon]="'times'"></fa-icon></span>

                    <span class="text-bold">Assainissement non conforme</span>
                  </p>
                </div>

                <div
                  ng-if="$ctrl.showField('viabilisationAssainissement', $ctrl.dossier.bien.option.viabilisationAssainissement)"
                  class="col-sm-6 col-md-4 tw:text-center">
                  <p>
                    <span class="text-success"><fa-icon [icon]="'check'"></fa-icon></span>

                    <span
                      class="text-bold">{{"Assainissement " + $ctrl.dossier.bien.option.viabilisationAssainissement}}</span>

                    <span ng-if="$ctrl.dossier.bien.option.viabilisationAssainissement === 'collectif'"
                          ng-switch="$ctrl.dossier.bien.option.viabilisationAssainissementCollectifRaccorde"
                          class="text-bold">
                      <span ng-switch-when="true">
                        {{"raccordé" | translate}}
                      </span>

                      <span ng-switch-when="false">
                        {{"non raccordé" | translate}}
                      </span>
                    </span>
                  </p>
                </div>

                <div ng-if="$ctrl.showField('assainissements', $ctrl.dossier.bien.assainissements.length)"
                     class="col-sm-6 col-md-4 tw:text-center">
                  <span class="text-success"><fa-icon [icon]="'check'"></fa-icon></span>

                  <span class="text-bold">
                    {{'bien.field_name.ASSAINISSEMENTS'
                    | translate:'{NB:' + $ctrl.dossier.bien.assainissements.length + '}'}}
                  </span>

                  <span ng-repeat="assainissement in $ctrl.dossier.bien.assainissements"
                        class="text-bold">
                    {{($index > 0 ? " / " : " : ") + assainissement.label}}
                  </span>
                </div>
              </div>

              <p ng-if="$ctrl.showField('viabilisationComments', $ctrl.dossier.bien.option.viabilisationComments)"
                 class="details-item" ng-bind-html="$ctrl.dossier.bien.option.viabilisationComments">
              </p>
            </div>
          </div>

          <!-- Accessiblité -->
          <div ng-if="$ctrl.showPanel('accessibilite')" class="properties-description">
            <h3 class="description-title">{{"Accessibilité / Environnement" | translate}}</h3>

            <div class="description-details">
              <ul ng-if="$ctrl.showField('commodites', $ctrl.dossier.bien.commodites.length)"
                  class="list-group row details-item">
                <li ng-if="!commodite.isEmpty()" ng-repeat="commodite in $ctrl.dossier.bien.commodites"
                    class="list-group-item col-sm-6">
                  <div class="details-commodite">
                    <div class="row">
                      <div ng-if="commodite.distance > 0" class="col-xs-4 col-lg-3 commodite-distance">
                        {{$ctrl.getNumber(commodite.distance) | currency:'m':2 * ($ctrl.getNumber(commodite.distance) % 1 !== 0)}}
                      </div>

                      <div class="col-xs-8 col-lg-9 commodite-label"
                           ng-class="commodite.distance > 0 ? '' : 'col-xs-offset-4 col-lg-offset-3'">
                        {{commodite.commodite.label}}
                      </div>
                    </div>

                    <p ng-if="!!commodite.comments" class="commodite-comments"
                       ng-bind-html="commodite.comments"></p>
                  </div>
                </li>
              </ul>

              <div class="row details-item">
                <div ng-if="$ctrl.showField('plainPied', $ctrl.dossier.bien.option.plainPied)"
                     class="col-xs-6 col-sm-3 tw:text-center">
                  <h3>{{$ctrl.dossier.bien.option.plainPied.label}}</h3>
                </div>

                <div ng-if="$ctrl.showField('pieceEauRdc', $ctrl.dossier.bien.option.pieceEauRdc)"
                     class="col-xs-6 col-sm-3 tw:text-center">
                  <p>
                    <span class="text-success"><fa-icon [icon]="'check'"></fa-icon></span>

                    <span class="text-bold"> {{"Pièce(s) d'eau au RDC" | translate}}</span>
                  </p>
                </div>

                <div ng-if="$ctrl.showField('chambreRdc', $ctrl.dossier.bien.option.chambreRdc)"
                     class="col-xs-6 col-sm-3 tw:text-center">
                  <p>
                    <span class="text-success"><fa-icon [icon]="'check'"></fa-icon></span>

                    <span class="text-bold"> {{'Chambre(s) au RDC' | translate}}</span>
                  </p>
                </div>

                <div ng-if="$ctrl.showField('accesHandicape', $ctrl.dossier.bien.option.accesHandicape)"
                     class="col-xs-6 col-sm-3 tw:text-center"
                     title="Accès pour les personnes à mobilité réduite">
                  <p>
                    <span class="text-success"><fa-icon [icon]="'check'"></fa-icon></span>

                    <span class="text-bold"> {{'Accès PMR' | translate}}</span>
                  </p>
                </div>
              </div>

              <div
                ng-if="$ctrl.showField('commentsAccessibiliteEtEnvironnement', $ctrl.dossier.bien.option.commentsAccessibiliteEtEnvironnement)"
                class="details-item">
                <p ng-bind-html="$ctrl.dossier.bien.option.commentsAccessibiliteEtEnvironnement"></p>
              </div>
            </div>
          </div>

          <!-- Diagnostics -->
          <div ng-if="$ctrl.showPanel('diagnostics') && ($ctrl.showField('performanceEnergetique') || $ctrl.showField('diagnostics', $ctrl.dossier.bien.diagnostics.length))"
               class="properties-description">
            <h3 class="description-title">Diagnostics</h3>

            <div class="description-details">
              <div ng-if="$ctrl.showField('performanceEnergetique')" class="row details-item">
                <div ng-if="!$ctrl.isDiagV2 && $ctrl.performanceEnergetiqueStatutDpe" class="col-sm-4 tw:text-center">
                  <h3>{{$ctrl.performanceEnergetiqueStatutDpe.label}}</h3> <p>Statut diagnostics</p>
                </div>

                <div class="col-xs-12 col-md-8">
                  <app-diag-graphes [bien]="$ctrl.bien"></app-diag-graphes>

                  <div ng-if="!$ctrl.isDiagV2 && $ctrl.bien.performanceEnergetique.isDisponible()" class="row tw:text-center">
                    <p class="col-sm-6">
                      {{$ctrl.bien.performanceEnergetique.consommation >= 0 ? 'Consommation énergétique' : ''}}
                    </p>

                    <p class="col-sm-6">
                      {{$ctrl.bien.performanceEnergetique.emissions >= 0 ? 'Gaz à effet de serre' : ''}}
                    </p>
                  </div>
                </div>
              </div>

              <ul ng-if="$ctrl.showField('diagnostics', $ctrl.dossier.bien.diagnostics.length)"
                  class="list-group row details-item">
                <li ng-if="!diagnostic.isEmpty()" class="list-group-item col-sm-6"
                    ng-repeat="diagnostic in $ctrl.dossier.bien.diagnostics">
                  <div class="row">
                    <p class="col-sm-6 text-bold">{{diagnostic.typeDiagnostic.label}}</p>

                    <p class="col-sm-6 text-bold">
                      <app-ng2-date [date]="diagnostic.date" format="shortDate"></app-ng2-date>
                    </p>
                  </div>

                  <p ng-if="!!diagnostic.comments" ng-bind-html="diagnostic.comments"></p>
                </li>
              </ul>
            </div>
          </div>

          <!-- Habitation -->
          <div ng-if="$ctrl.showPanel('habitation')" class="properties-description">
            <h3 class="description-title">Habitation</h3>

            <div class="description-details">
              <div
                ng-if="$ctrl.showField('usage', $ctrl.dossier.bien.option.usage) || $ctrl.showField('residence', $ctrl.dossier.bien.option.residence) || $ctrl.showField('residenceService', $ctrl.dossier.bien.option.residenceService)"
                class="row details-item">
                <div ng-if="$ctrl.showField('usage', $ctrl.dossier.bien.option.usage)"
                     class="col-xs-6 col-sm-3 tw:text-center">
                  <h3>{{$ctrl.dossier.bien.option.usage.label}}</h3>

                  <p>{{"Usage du bien" | translate}}</p>
                </div>

                <div ng-if="$ctrl.showField('residence', $ctrl.dossier.bien.option.residence)"
                     class="col-xs-6 col-sm-3 tw:text-center">
                  <h3>{{$ctrl.dossier.bien.option.residence.label}}</h3>

                  <p>{{"Type de résidence" | translate}}</p>
                </div>

                <div ng-if="$ctrl.showField('residenceService', $ctrl.dossier.bien.option.residenceService)"
                     class="col-xs-6 col-sm-3 tw:text-center">
                  <h3>{{"Résidence service" | translate}}</h3>
                </div>
              </div>

              <div
                ng-if="($ctrl.dossier._esk.typeDossier !== $ctrl.DossierBienTypesConst.LOCATION && $ctrl.showField('occupation', $ctrl.dossier.bien.option.occupation)) || ($ctrl.dossier._esk.typeDossier !== $ctrl.DossierBienTypesConst.LOCATION && $ctrl.showField('dateDisponibilite', $ctrl.dossier.bien.metadata.occupation.dateDisponibilite)) || $ctrl.showField('chargesLocatives', $ctrl.dossier.bien.metadata.occupation.chargesActuelles) || $ctrl.showField('loyerActuel', $ctrl.dossier.bien.metadata.occupation.loyerActuel)"
                class="row details-item">
                <div
                  ng-if="$ctrl.dossier._esk.typeDossier !== $ctrl.DossierBienTypesConst.LOCATION && $ctrl.showField('occupation', $ctrl.dossier.bien.option.occupation)"
                  class="col-xs-6 col-sm-3 tw:text-center">
                  <h3>{{$ctrl.dossier.bien.option.occupation.label}}</h3>

                  <p>{{"Occupation du bien" | translate}}</p>
                </div>

                <div
                  ng-if="$ctrl.dossier._esk.typeDossier !== $ctrl.DossierBienTypesConst.LOCATION && $ctrl.showField('dateDisponibilite', $ctrl.dossier.bien.metadata.occupation.dateDisponibilite)"
                  class="col-xs-6 col-sm-3 tw:text-center">
                  <h3>
                    <app-ng2-date [date]="$ctrl.dossier.bien.metadata.occupation.dateDisponibilite" format="shortDate">
                    </app-ng2-date>
                  </h3>

                  <p>{{"Date de disponibilité" | translate}}</p>
                </div>

                <div
                  ng-if="$ctrl.showField('chargesLocatives', $ctrl.dossier.bien.metadata.occupation.chargesActuelles)"
                  class="col-xs-6 col-sm-3 tw:text-center">
                  <h3>
                    {{$ctrl.getNumber($ctrl.dossier.bien.metadata.occupation.chargesActuelles) |
                    currency:'€':2 * ($ctrl.getNumber($ctrl.dossier.bien.metadata.occupation.chargesActuelles) % 1 !== 0)}}
                  </h3>

                  <p>{{"Charges locatives" | translate}}</p>
                </div>

                <div ng-if="$ctrl.showField('loyerActuel', $ctrl.dossier.bien.metadata.occupation.loyerActuel)"
                     class="col-xs-6 col-sm-3 tw:text-center">
                  <h3>
                    {{$ctrl.getNumber($ctrl.dossier.bien.metadata.occupation.loyerActuel) |
                    currency:'€':2 * ($ctrl.getNumber($ctrl.dossier.bien.metadata.occupation.loyerActuel) % 1 !== 0)}}
                  </h3>

                  <p>{{"Loyer actuel" | translate}}</p>
                </div>
              </div>
            </div>
          </div>

          <!-- Autres -->
          <div ng-if="$ctrl.showPanel('autres')" class="properties-description">
            <h3 class="description-title">{{"Autres" | translate}}</h3>

            <div class="description-details">
              <div class="row">
                <div ng-if="$ctrl.showField('vues', $ctrl.dossier.bien.vues.length)"
                     class="col-md-6">
                  <div class="row">
                    <p class="col-xs-5 text-right">{{"Vues" | translate}}</p>

                    <p class="col-xs-7 text-bold">
                    <span ng-repeat="vue in $ctrl.dossier.bien.vues">
                      {{($index > 0 ? " / " : "") + vue.label}}
                    </span>
                    </p>
                  </div>
                </div>

                <div ng-if="!!$ctrl.dossier.bien.option.commentsVue"
                     class="col-md-6">
                  <p ng-bind-html="$ctrl.dossier.bien.option.commentsVue"></p>
                </div>

                <div ng-if="$ctrl.showField('exceptionnel', $ctrl.dossier.bien.option.exceptionnel)"
                     class="col-sm-6 col-md-4 tw:text-center">
                  <p>
                    <span class="text-success"><fa-icon [icon]="'check'"></fa-icon></span>

                    <span class="text-bold">Bien d'exception</span>
                  </p>
                </div>

                <div ng-if="$ctrl.showField('commentsConstruction', $ctrl.dossier.bien.option.commentsConstruction)"
                     class="details-item">
                  <p ng-bind-html="$ctrl.dossier.bien.option.commentsConstruction"></p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Colonne droite -->
        <div class="col-sm-4 property-col-right">
          <!-- Box Archivage -->
          <div
            ng-if="$ctrl.dossier.statut === $ctrl.STATUT_ARCHIVE && ($ctrl.dossier._esk.typeDossier === $ctrl.DossierBienTypesConst.VENTE || (($ctrl.dossier._esk.typeDossier === $ctrl.DossierBienTypesConst.LOCATION || $ctrl.dossier._esk.typeDossier === $ctrl.DossierBienTypesConst.ESTIMATION) && !!$ctrl.archive.comments))"
            class="properties-description">
            <h3 class="description-title">Informations archivage</h3>

            <div class="description-details">
              <div class="details-item">
                <div ng-if="!!$ctrl.archive.dateVente" class="row">
                  <p class="col-xs-5 text-right">Date de vente</p>

                  <p class="col-xs-7 text-bold">
                    <app-ng2-date [date]="$ctrl.archiveDateVente" format="shortDate"></app-ng2-date>
                  </p>
                </div>

                <div ng-if="!!$ctrl.archive.type" class="row">
                  <p class="col-xs-5 text-right">Type d'archivage</p>

                  <p class="col-xs-7 text-bold">{{$ctrl.archive.type.label}}</p>
                </div>

                <div ng-if="!!$ctrl.archive.raisonVenteNonNegociee" ng-if="$ctrl.archive.type.code === $ctrl.VENTE_ARCHIVE_TYPES_NON_NEGOCIEE"
                     class="row">
                  <p class="col-xs-5 text-right">Raison de la non-négociation</p>

                  <p class="col-xs-7 text-bold">{{$ctrl.archive.raisonVenteNonNegociee.label}}</p>
                </div>

                <div ng-if="!!$ctrl.archive.raisonVenteNegociee" ng-if="$ctrl.archive.type.code === $ctrl.VENTE_ARCHIVE_TYPES_NEGOCIEE" class="row">
                  <p class="col-xs-5 text-right">Provenance de l'acquéreur</p>

                  <p class="col-xs-7 text-bold">{{$ctrl.archive.raisonVenteNegociee.label}}</p>
                </div>

                <div ng-if="$ctrl.archive.type.code === $ctrl.VENTE_ARCHIVE_TYPES_NEGOCIEE">
                  <div ng-if="$ctrl.archive.raisonVenteNegociee.code === $ctrl.CODE_ARCHIVE_NEGOCIEE_RAISONS_PORTEFEUILLE && !!$ctrl.archive.demandeur"
                       class="row">
                    <p class="col-xs-5 text-right">Nom du demandeur</p>

                    <p class="col-xs-7 text-bold">{{$ctrl.archive.demandeur.contactsGroup.nom}}</p>
                  </div>

                  <div ng-if="$ctrl.archive.raisonVenteNegociee.code === $ctrl.CODE_ARCHIVE_NEGOCIEE_RAISONS_RESEAU && !!$ctrl.archive.etudeAcquereur"
                       class="row">
                    <p class="col-xs-5 text-right">Étude de l'acquéreur</p>

                    <p class="col-xs-7 text-bold">{{$ctrl.archive.etudeAcquereur.raisonSociale}}</p>
                  </div>

                  <div ng-if="($ctrl.archive.raisonVenteNegociee.code === $ctrl.CODE_ARCHIVE_NEGOCIEE_RAISONS_RESEAU || $ctrl.archive.raisonVenteNegociee.code === $ctrl.CODE_ARCHIVE_NEGOCIEE_RAISONS_AUTRES) && !!$ctrl.archive.acquereur"
                       class="row">
                    <p class="col-xs-5 text-right">Nom de l'acquéreur</p>

                    <p class="col-xs-7 text-bold">{{$ctrl.archive.acquereur}}</p>
                  </div>
                </div>

                <div ng-if="!!$ctrl.archive.prixCession" class="row">
                  <p class="col-xs-5 text-right">Prix de dossier final</p>

                  <p class="col-xs-7 text-bold">
                    {{$ctrl.getNumber($ctrl.archive.prixCession)
                    | currency:'€':2 * ($ctrl.getNumber($ctrl.archive.prixCession) % 1 !== 0)}}
                  </p>
                </div>

                <div ng-if="!!$ctrl.archive.honorairesNego && $ctrl.archive.type.code === $ctrl.VENTE_ARCHIVE_TYPES_NEGOCIEE" class="row">
                  <p class="col-xs-5 text-right">Honoraires de négociation</p>

                  <p class="col-xs-7 text-bold">
                    {{$ctrl.getNumber($ctrl.archive.honorairesNego)
                    | currency:'€':2 * ($ctrl.getNumber($ctrl.archive.honorairesNego) % 1 !== 0)}}
                  </p>
                </div>
              </div>

              <div ng-if="!!$ctrl.archive.comments" class="details-item">
                <p>Commentaires complémentaires</p>

                <p ng-bind-html="$ctrl.archive.comments"></p>
              </div>
            </div>
          </div>

          <!-- Visite virtuelle -->
          <div ng-if="!!$ctrl.dossier.bien.option.urlVisiteVirtuelle" class="properties-description">
            <button type="button" class="btn btn-lg btn-block description-virtual_visit"
                    ng-click="$ctrl.openVirtualVisit()">
              <fa-icon [icon]="'external-link-alt'"></fa-icon> Visite virtuelle
            </button>
          </div>

          <!-- Description -->
          <div class="properties-description">
            <h3 class="description-title">Description</h3>

            <div class="description-details">
              <div ng-if="$ctrl.showField('descriptif', $ctrl.dossier.bien.descriptif)" class="details-item">
                <p ng-bind-html="$ctrl.dossier.bien.descriptif"></p>
              </div>

              <div class="row details-item">
                <div class="col-xs-6 tw:text-center"><h3>{{$ctrl.dossier.bien.nature.label}}</h3><p>Nature</p></div>

                <div ng-if="$ctrl.showField('superficie', $ctrl.dossier.bien.superficie)"
                     class="col-xs-6 tw:text-center">
                  <h3>
                    {{$ctrl.getNumber($ctrl.dossier.bien.superficie)
                    | currency:'m²':2 * ($ctrl.getNumber($ctrl.dossier.bien.superficie) % 1 !== 0)}}
                  </h3>

                  <p>
                    {{ 'bien.field_label.SUPERFICIE' | translate: '{nature: "' + $ctrl.dossier.bien.nature.code + '"}' }}
                  </p>
                </div>

                <div ng-if="$ctrl.showField('designation', $ctrl.dossier.bien.option.designation)"
                     class="col-xs-6 tw:text-center">
                  <h3>{{$ctrl.dossier.bien.option.designation}}</h3>

                  <p>{{"Désignation" | translate}}</p>
                </div>

                <div
                  ng-if="$ctrl.dossier._esk.typeDossier === $ctrl.DossierBienTypesConst.LOCATION && $ctrl.showField('meuble', $ctrl.dossier.meuble)"
                  class="col-xs-6 tw:text-center">
                  <h3>{{'Meublé' | translate}}</h3>
                </div>
              </div>
            </div>
          </div>

          <!-- Dossier -->
          <div ng-if="$ctrl.dossier.bien._esk.interne || (!$ctrl.dossier.bien._esk.interne && $ctrl.unlockData)"
               class="properties-description">
            <h3 class="description-title">{{"Dossier" | translate}}</h3>

            <div class="description-details">
              <div class="details-item">
                <div class="row details-item cursor-pointer">
                  <p class="col-xs-5 margin-top-5 text-right">Responsable</p>

                  <div class="col-xs-7 text-bold">
                    <app-user-cartouche [link]="$ctrl.dossier.bien._links.responsableDossier.href"
                                        class="tw:block tw:h-8 tw:max-w-full tw:w-fit"></app-user-cartouche>
                  </div>
                </div>

                <div class="row cursor-pointer"
                     ng-click="$ctrl.ng2RouterStateForNgService.navigateByUrl('/app/annuaires/etudes/' + $ctrl.dossier.bien.responsableDossier.etude.id.toString())">
                  <p class="col-xs-5 text-right">Étude</p>

                  <p class="col-xs-7 text-bold">{{$ctrl.dossier.bien.responsableDossier.etude.raisonSociale}}</p>
                </div>

                <div ng-if="$ctrl.dossier.bien.responsableDossier.getPhoneFixe().hasValidNumero()" class="row">
                  <p class="col-xs-5 text-right">Téléphone</p>

                  <app-text-phone [phone]="$ctrl.dossier.bien.responsableDossier.getPhoneFixe().numero"
                                  [options]="{icon: 'phone'}" class="col-xs-7 text-bold"></app-text-phone>
                </div>

                <div ng-if="$ctrl.dossier.bien.responsableDossier.getPhoneMobile().hasValidNumero()" class="row">
                  <p class="col-xs-5 text-right">Mobile</p>

                  <app-text-phone [phone]="$ctrl.dossier.bien.responsableDossier.getPhoneMobile().numero"
                                  [options]="{icon: 'mobile-alt'}" class="col-xs-7 text-bold"></app-text-phone>
                </div>

                <div ng-if="!!$ctrl.dossier.bien.responsableDossier.emailAddress" class="row">
                  <p class="col-xs-5 text-right">Email</p>

                  <a href="mailto:{{$ctrl.dossier.bien.responsableDossier.emailAddress}}"
                     target="_blank" class="col-xs-7 text-bold">
                    {{$ctrl.dossier.bien.responsableDossier.emailAddress}}
                  </a>
                </div>

                <div ng-if="$ctrl.unlockData && $ctrl.dossier.mandatType" class="row">
                  <p class="col-xs-5 text-right">{{"Type de mandat" | translate}}</p>

                  <p class="col-xs-7 text-bold">
                    {{$ctrl.dossier.mandatType.label}}
                  </p>
                </div>

                <div ng-if="$ctrl.unlockData && $ctrl.dossier.mandatDate" class="row">
                  <p class="col-xs-5 text-right">Date de mandat</p>

                  <p class="col-xs-7 text-bold">
                    <app-ng2-date [date]="$ctrl.dossier.mandatDate" format="shortDate"></app-ng2-date>
                  </p>
                </div>

                <div
                  ng-if="$ctrl.unlockData && $ctrl.dossier.bien._esk.interne && $ctrl.showField('causeVente', $ctrl.dossier.bien.option.causeVente)"
                  class="row">
                  <p class="col-xs-5 text-right">{{"Cause de la vente" | translate}}</p>

                  <p class="col-xs-7 text-bold">{{$ctrl.dossier.bien.option.causeVente}}</p>
                </div>

                <div
                  ng-if="$ctrl.unlockData && $ctrl.dossier.bien._esk.interne && $ctrl.showField('motif', $ctrl.dossier.motif)"
                  class="row">
                  <p class="col-xs-5 text-right">{{"Motif" | translate}}</p>

                  <p class="col-xs-7 text-bold">{{$ctrl.dossier.motif}}</p>
                </div>

                <div
                  ng-if="$ctrl.dossier.bien._esk.interne && $ctrl.showField('referenceInterne', $ctrl.dossier.bien.referenceInterne)"
                  class="row">
                  <p class="col-xs-5 text-right">{{"Référence interne" | translate}}</p>

                  <p class="col-xs-7 text-bold">{{$ctrl.dossier.bien.referenceInterne}}</p>
                </div>

                <div class="row">
                  <p class="col-xs-5 text-right">Référence Noty</p>

                  <p class="col-xs-7 text-bold">{{$ctrl.dossier.bien.reference}}</p>
                </div>

                <div ng-if="$ctrl.bienDossier.bien.linkResponsableDossier !== $ctrl.bienDossier.bien.linkNotaire"
                     class="row">
                  <p class="col-xs-5 margin-top-5 text-right">Notaire référent</p>

                  <div class="col-xs-7 text-bold">
                    <app-user-cartouche [link]="$ctrl.bienDossier.bien.linkNotaire"
                                        class="tw:block tw:h-8 tw:max-w-full tw:w-fit"></app-user-cartouche>
                  </div>
                </div>

                <div class="row"
                     ng-if="$ctrl.unlockData && $ctrl.showField('clesVisite', $ctrl.dossier.bien.metadata.confreres.clesVisite)">
                  <p class="col-xs-5 text-right">Clés pour visite</p>

                  <p class="col-xs-7 text-bold">
                    {{$ctrl.dossier.bien.metadata.confreres.clesVisite}}
                  </p>
                </div>

                <div
                  ng-if="$ctrl.unlockData && $ctrl.dossier.bien._esk.interne && $ctrl.showField('numeroCle', $ctrl.dossier.bien.metadata.confidentielles.numeroCle)"
                  class="row">
                  <p class="col-xs-5 text-right">{{"N° clés" | translate}}</p>

                  <p class="col-xs-7 text-bold">
                    {{$ctrl.dossier.bien.metadata.confidentielles.numeroCle}}
                  </p>
                </div>

                <div
                  ng-if="$ctrl.unlockData && $ctrl.dossier.bien._esk.interne && $ctrl.showField('panneaux', $ctrl.dossier.bien.metadata.confidentielles.panneaux)"
                  class="row">
                  <p class="col-xs-5 text-right">{{"Panneaux" | translate}}</p>

                  <p class="col-xs-7 text-bold">
                    {{$ctrl.dossier.bien.metadata.confidentielles.panneaux}}
                  </p>
                </div>
              </div>

              <div
                ng-if="$ctrl.unlockData && $ctrl.showField('commentsConfreres', $ctrl.dossier.bien.metadata.confreres.comments)"
                class="details-item">
                <p>{{"Renseignements confrères" | translate}}</p>

                <p ng-bind-html="$ctrl.dossier.bien.metadata.confreres.comments"></p>
              </div>

              <div
                ng-if="$ctrl.dossier.bien._esk.interne && $ctrl.unlockData && $ctrl.showField('commentsConfidentiels', $ctrl.dossier.bien.metadata.confidentielles.comments)"
                class="details-item">
                <p>{{"Renseignements confidentiels" | translate}}</p>

                <p ng-bind-html="$ctrl.dossier.bien.metadata.confidentielles.comments"></p>
              </div>
            </div>
          </div>

          <!-- Prix -->
          <div class="properties-description">
            <div class="description-price" ng-class="$ctrl.dossier._esk.typeDossier">
              <div ng-if="$ctrl.dossier._esk.typeDossier === $ctrl.DossierBienTypesConst.VENTE">
                <ng-include src="'src/app/legacy/templates/ventes/deprecated_prix.html'"></ng-include>
              </div>

              <div ng-if="$ctrl.dossier._esk.typeDossier === $ctrl.DossierBienTypesConst.LOCATION">
                <ng-include src="'src/app/legacy/templates/locations/deprecated_loyer.html'"></ng-include>
              </div>

              <div ng-if="$ctrl.dossier._esk.typeDossier === $ctrl.DossierBienTypesConst.ESTIMATION">
                <ng-include src="'src/app/legacy/templates/estimations/deprecated_valeur.html'"></ng-include>
              </div>
            </div>
          </div>

          <!-- Localisation -->
          <div ng-if="!!$ctrl.dossier.bien.option.commune" class="properties-description">
            <h3 class="description-title">{{"Localisation" | translate}}</h3>

            <div class="description-details">
              <div class="row">
                <p class="col-xs-3 text-right">
                  {{$ctrl.unlockData ? "Adresse" : "Commune" | translate}}
                </p>

                <p class="col-xs-9">
                  <span ng-if="$ctrl.unlockData" class="text-bold"
                        ng-switch="$ctrl.dossier.bien._esk.interne">
                    <span ng-switch-when="true">
                      {{$ctrl.dossier.bien.option.numeroVoie}}
                      {{$ctrl.dossier.bien.option.nomVoie}}
                      {{$ctrl.dossier.bien.option.codePostal}}
                    </span>

                    <span ng-switch-default>{{$ctrl.dossier.bien.option.adresseReseau}}</span>
                  </span>

                  <span class="text-bold">
                    {{$ctrl.dossier.bien.option.commune.fullname}}
                  </span>

                  <span ng-if="!!$ctrl.dossier.bien.option.commune.communeParent">
                    {{"rattachée à" | translate}}
                    {{$ctrl.dossier.bien.option.commune.communeParent.fullname}}
                  </span>
                </p>
              </div>

              <div ng-if="!!$ctrl.dossier.bien.option.quartier" class="row">
                <p class="col-xs-3 text-right">{{"Quartier" | translate}}</p>

                <p class="col-xs-9 text-bold">{{$ctrl.dossier.bien.option.quartier.name}}</p>
              </div>
            </div>
          </div>

          <!-- Cadastres -->
          <div ng-if="$ctrl.unlockData && $ctrl.showPanel('cadastres', $ctrl.dossier.bien.cadastres.length)"
               class="properties-description">
            <h3 class="description-title">{{"Cadastres" | translate}}</h3>

            <div class="description-details">
              <ul class="list-group row">
                <li class="list-group-item" ng-repeat="cadastre in $ctrl.dossier.bien.cadastres"
                    ng-if="!cadastre.isEmpty()">
                  <p class="text-bold margin-bottom-0">
                    <span title="Parcelle">{{"Parc." | translate }}</span> {{cadastre.parcelle}}

                    - {{"Feuille"| translate }} {{cadastre.prefixe}} {{cadastre.section}} {{cadastre.lot || "" }}
                  </p>

                  <p class="margin-bottom-0">
                    <span class="text-muted">{{cadastre.commune.fullname}}</span>

                    <span ng-if="!!cadastre._esk.doubleMandat" class="text-warning padding-left-20"
                          title="{{cadastre._esk.doubleMandat.title}}">
                      <fa-icon [icon]="'exclamation-triangle'"></fa-icon>
                    </span>

                    <span ng-if="!!cadastre.surface" class="text-muted padding-left-20">
                      {{$ctrl.getNumber(cadastre.surface) | currency:"m²":2 * ($ctrl.getNumber(cadastre.surface) % 1 !== 0)}}
                    </span>
                  </p>
                </li>
              </ul>
            </div>
          </div>

          <!-- Propriètaires -->
          <div ng-if="$ctrl.dossier.bien._esk.interne && $ctrl.unlockData && $ctrl.showPanel('proprietaires')"
               class="properties-description">
            <h3 class="description-title">{{"Propriétaires" | translate}}</h3>

            <div class="description-details">
              <div
                ng-if="$ctrl.showField('nomContactsGroup', $ctrl.dossier.bien.contactsGroup.nom) || $ctrl.showField('typeGroupContactsGroup', $ctrl.dossier.bien.contactsGroup.typeGroup)"
                class="details-item">
                <div ng-if="$ctrl.showField('nomContactsGroup', $ctrl.dossier.bien.contactsGroup.nom)"
                     class="row">
                  <p class="col-xs-4 text-right">{{"Nom du dossier" | translate}}</p>

                  <p class="col-xs-8">
                    <span class="text-bold">{{$ctrl.dossier.bien.contactsGroup.nom}}</span>
                  </p>
                </div>

                <div ng-if="$ctrl.showField('typeGroupContactsGroup', $ctrl.dossier.bien.contactsGroup.typeGroup)"
                     class="row">
                  <p class="col-xs-4 text-right">{{"Type du dossier" | translate}}</p>

                  <p class="col-xs-8">
                    <span class="text-bold">{{$ctrl.dossier.bien.contactsGroup.typeGroup.label}}</span>
                  </p>
                </div>
              </div>

              <div class="details-item">
                <esk-contact-resume ng-repeat="member in $ctrl.dossier.bien.contactsGroup.members"
                                    contact="member.contact"></esk-contact-resume>
              </div>
            </div>
          </div>

          <!-- Coûts -->
          <div ng-if="$ctrl.showPanel('couts')" class="properties-description">
            <h3 class="description-title">Coûts</h3>

            <div class="description-details">
              <div ng-if="$ctrl.showField('charges', $ctrl.dossier.bien.charges.length)" class="details-item">
                <h4>Charges</h4>

                <table class="table">
                  <tr class="row" ng-repeat="charge in $ctrl.dossier.bien.charges" ng-if="!charge.isEmpty()">
                    <td class="col-xs-5"><p>{{charge.charge.label}}</p></td>

                    <td class="col-xs-3">
                      <p>{{$ctrl.getNumber(charge.cout) | currency:'€':2 * ($ctrl.getNumber(charge.cout) % 1 !== 0)}}</p>
                    </td>

                    <td class="col-xs-4"><p>{{charge.periodicite.label}}</p></td>
                  </tr>
                </table>
              </div>

              <div ng-if="$ctrl.showField('commentsCouts', $ctrl.dossier.bien.metadata.couts.comments)"
                   class="details-item">
                <p ng-bind-html="$ctrl.dossier.bien.metadata.couts.comments"></p>
              </div>
            </div>
          </div>

          <!-- Loi ALUR -->
          <div ng-if="$ctrl.showPanel('loiAlur')" class="properties-description">
            <h3 class="description-title">Loi ALUR</h3>

            <div class="description-details">
              <div class="details-item">
                <span class="text-success"><fa-icon [icon]="'check'"></fa-icon></span>

                <span class="text-bold"> Loi ALUR</span>
              </div>

              <div
                ng-if="$ctrl.showField('nombreLotsLoiAlur', $ctrl.dossier.bien.metadata.loiAlur.nombreLots) || $ctrl.showField('nombreCoproprietairesLoiAlur', $ctrl.dossier.bien.metadata.loiAlur.nombreCoproprietaires)"
                class="row details-item">
                <div ng-if="$ctrl.showField('nombreLotsLoiAlur', $ctrl.dossier.bien.metadata.loiAlur.nombreLots)"
                     class="col-xs-6 tw:text-center">
                  <h3>{{$ctrl.dossier.bien.metadata.loiAlur.nombreLots}}</h3>

                  <p>{{"Nombre de lots" | translate}}</p>
                </div>

                <div
                  ng-if="$ctrl.showField('nombreCoproprietairesLoiAlur', $ctrl.dossier.bien.metadata.loiAlur.nombreCoproprietaires)"
                  class="col-xs-6 tw:text-center">
                  <h3>{{$ctrl.dossier.bien.metadata.loiAlur.nombreCoproprietaires}}</h3>

                  <p>{{"Nombre de copropriétaires" | translate}}</p>
                </div>
              </div>

              <div
                ng-if="$ctrl.showField('coordonneesSyndicLoiAlur', $ctrl.dossier.bien.metadata.loiAlur.coordonneesSyndic)"
                class="details-item">
                <p>{{"Coordonnées du Syndic" | translate}}</p>

                <p ng-bind-html="$ctrl.dossier.bien.metadata.loiAlur.coordonneesSyndic"></p>
              </div>

              <div
                ng-if="$ctrl.showField('montantQuotePartLoiAlur', $ctrl.dossier.bien.metadata.loiAlur.montantQuotePart) || $ctrl.showField('montantTravauxLoiAlur', $ctrl.dossier.bien.metadata.loiAlur.montantTravaux)"
                class="row details-item">
                <div
                  ng-if="$ctrl.showField('montantQuotePartLoiAlur', $ctrl.dossier.bien.metadata.loiAlur.montantQuotePart)"
                  class="col-xs-6 tw:text-center">
                  <h3>{{$ctrl.getNumber($ctrl.dossier.bien.metadata.loiAlur.montantQuotePart) |
                    currency:'€':2 * ($ctrl.getNumber($ctrl.dossier.bien.metadata.loiAlur.montantQuotePart) % 1 !== 0)}}</h3>

                  <p>Montant moyen annuel de la quote-part</p>
                </div>

                <div ng-if="$ctrl.showField('montantTravauxLoiAlur', $ctrl.dossier.bien.metadata.loiAlur.montantTravaux)"
                     class="col-xs-6 tw:text-center">
                  <h3>{{$ctrl.getNumber($ctrl.dossier.bien.metadata.loiAlur.montantTravaux) |
                    currency:'€':2 * ($ctrl.getNumber($ctrl.dossier.bien.metadata.loiAlur.montantTravaux) % 1 !== 0)}}</h3>

                  <p>{{"Montant des travaux en cours ou votés" | translate}}</p>
                </div>
              </div>

              <div
                ng-if="$ctrl.showField('planSauvegardeAlur', $ctrl.dossier.bien.metadata.loiAlur.planSauvegarde) || $ctrl.showField('administrationSyndicLoiAlur', $ctrl.dossier.bien.metadata.loiAlur.administrationSyndic)"
                class="row details-item">
                <div ng-if="$ctrl.showField('planSauvegardeAlur', $ctrl.dossier.bien.metadata.loiAlur.planSauvegarde)"
                     class="col-sm-6 tw:text-center">
                  <span class="text-success"><fa-icon [icon]="'check'"></fa-icon></span>

                  <span class="text-bold"> {{"Plan de sauvegarde" | translate}}</span>
                </div>

                <div
                  ng-if="$ctrl.showField('administrationSyndicLoiAlur', $ctrl.dossier.bien.metadata.loiAlur.administrationSyndic)"
                  class="col-sm-6 tw:text-center">
                  <span class="text-success"><fa-icon [icon]="'check'"></fa-icon></span>

                  <span class="text-bold"> {{"Administration provisoire du Syndic" | translate}}</span>
                </div>
              </div>

              <div ng-if="$ctrl.showField('commentsAlur', $ctrl.dossier.bien.metadata.loiAlur.comments)"
                   class="details-item">
                <p>Commentaires procédures loi ALUR</p>

                <p ng-bind-html="$ctrl.dossier.bien.metadata.loiAlur.comments"></p>
              </div>
            </div>
          </div>

          <!-- Risques -->
          <div ng-if="$ctrl.dossier.bien.option.risquesZone" class="properties-description">
            <h3 class="description-title">Risques</h3>

            <div class="description-details">
              <div class="details-item">
                <span class="text-success"><fa-icon [icon]="'check'"></fa-icon></span>

                <span class="text-bold">Bien dans une zone à risques</span>

                <div class="margin-top-15">
                  <p>
                    Les informations sur les risques auxquels ce bien est exposé sont disponibles sur le site
                    <a href="https://www.georisques.gouv.fr" target="_blank">
                      Géorisques <fa-icon [icon]="'external-link-alt'"></fa-icon>
                    </a>.
                  </p>
                </div>
              </div>

              <div ng-if="$ctrl.dossier.bien.option.risquesZone && $ctrl.dossier.bien.option.risquesComments"
                   class="details-item">
                <p>Commentaires sur les risques</p><p ng-bind-html="$ctrl.dossier.bien.option.risquesComments"></p>
              </div>
            </div>
          </div>

          <!-- Urbanisme -->
          <div ng-if="$ctrl.showPanel('urbanisme')" class="properties-description">
            <h3 class="description-title">{{"Urbanisme" | translate}}</h3>

            <div class="description-details">
              <div ng-if="$ctrl.showField('urbanismeZonePLU', $ctrl.dossier.bien.option.urbanismeZonePLU)"
                   class="details-item">
                <p>{{"Zone PLU" | translate}}</p>

                <p ng-bind-html="$ctrl.dossier.bien.option.urbanismeZonePLU"></p>
              </div>

              <div ng-if="$ctrl.showField('urbanismeComments', $ctrl.dossier.bien.option.urbanismeComments)"
                   class="details-item">
                <p>{{"Commentaires sur l'urbanisme" | translate}}</p>

                <p ng-bind-html="$ctrl.dossier.bien.option.urbanismeComments"></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Footer -->
    <div ng-include="'src/app/legacy/templates/biens/components/item/details/actions.html'" class="footer-action"></div>

    <!-- Interest buttons -->
    <div ng-if="$ctrl.setInterest" class="btns-interest tw:flex tw:flex-col tw:text-center">
      <button type="button" ng-if="$ctrl.dossier.statut !== $ctrl.STATUT_ARCHIVE" ng-click="$ctrl.updateInterest('interested')"
              ng-hide="$ctrl.dossier._esk.result.interest === 'interested'" uib-tooltip="Intéressé">
        <div class="like"><fa-icon [icon]="'check'" size="2x"></fa-icon></div>
      </button>

      <button type="button" ng-hide="$ctrl.dossier._esk.result.interest === $ctrl.DISINTERESTED"
         ng-click="$ctrl.updateInterest($ctrl.DISINTERESTED)" uib-tooltip="Non intéressé">
        <div class="dislike"><fa-icon [icon]="'times'" size="2xl"></fa-icon></div>
      </button>

      <button type="button" ng-if="$ctrl.dossier._esk.typeDossier === $ctrl.DossierBienTypesConst.VENTE && $ctrl.dossier.statut !== $ctrl.STATUT_ARCHIVE"
         ng-hide="$ctrl.dossier._esk.result.interest === $ctrl.DISINTERESTED_EXCEPT_PRICE"
         ng-click="$ctrl.updateInterest($ctrl.DISINTERESTED_EXCEPT_PRICE)" uib-tooltip="Intéressé si baisse de prix">
        <div class="not-now"><fa-icon [icon]="'caret-right'" size="2x"></fa-icon></div>
      </button>
    </div>
  </div>
</ng-switch>
`;
