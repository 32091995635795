<div appProvideParentForm class="tw:grid tw:grid-cols-2 tw:md:grid-cols-3 tw:gap-4">
  <div [appFormError]="personSimpleTitre.formInput" class="tw:flex tw:flex-col tw:gap-2">
    <label class="tw:block tw:text-sm tw:text-gray-700">Titre *</label>

    <app-dictionary-select-items [model]="person.titre" [name]="TITRES" (selected)="onSelectTitre($event)"
                                 [options]="{name: 'personSimpleTitre', placeholder: 'Rechercher un titre', required: true}"
                                 class="tw:block" #personSimpleTitre="dictionarySelect"/>
  </div>

  <div [appFormError]="personSimpleNom" class="tw:flex tw:flex-col tw:gap-2">
    <label for="person-simple-nom" class="tw:block tw:text-sm tw:text-gray-700">Nom *</label>

    <input type="text" name="personSimpleNom" id="person-simple-nom" required #personSimpleNom="ngModel"
           [(ngModel)]="person.nom" (blur)="onBlur()" class="tw:input-text tw:block">
  </div>

  <div [appFormError]="personSimplePrenom" class="tw:flex tw:flex-col tw:gap-2">
    <label for="person-simple-prenom" class="tw:block tw:text-sm tw:text-gray-700">Prénom *</label>

    <input type="text" name="personSimplePrenom" id="person-simple-prenom" required #personSimplePrenom="ngModel"
           [(ngModel)]="person.prenom" (blur)="onBlur()" class="tw:input-text tw:block">
  </div>

  <div [appFormError]="personSimpleEmail" class="tw:flex tw:flex-col tw:gap-2">
    <label for="person-simple-email" class="tw:block tw:text-sm tw:text-gray-700">Adresse email</label>

    <input type="email" name="personSimpleEmail" id="person-simple-email" #personSimpleEmail="ngModel"
           [(ngModel)]="person.emailAddress" (blur)="onBlur()" placeholder="exemple@noty.com" appFormEmailValidator
           class="tw:input-email tw:block">
  </div>

  <div [appFormError]="personSimpleMobile" class="tw:flex tw:flex-col tw:gap-2">
    <label for="person-simple-mobile" class="tw:block tw:text-sm tw:text-gray-700">Mobile</label>

    <input type="tel" name="personSimpleMobile" id="person-simple-mobile" #personSimpleMobile="ngModel"
           [(ngModel)]="person.mobile" (blur)="onBlur()" placeholder="06 06 06 06 06" appFormPhoneValidator
           class="tw:input-tel tw:block">
  </div>

  <div [appFormError]="personSimpleFixe" class="tw:flex tw:flex-col tw:gap-2">
    <label for="person-simple-fixe" class="tw:block tw:text-sm tw:text-gray-700">Fixe</label>

    <input type="tel" name="personSimpleFixe" id="person-simple-fixe" #personSimpleFixe="ngModel"
           [(ngModel)]="person.telephone" (blur)="onBlur()" placeholder="02 02 02 02 02" appFormPhoneValidator
           class="tw:input-tel tw:block">
  </div>
</div>
