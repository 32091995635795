<div class="tw:mb-5">
  <h3><fa-icon [icon]="'edit'"/> {{ngBienCadastre.uuid ? 'Édition' : 'Ajout'}} d'une référence cadastrale</h3>
</div>

<form (ngSubmit)="save(editForm)" #editForm="ngForm"
      class="tw:w-[80vw] tw:sm:w-[30rem] tw:md:w-[40rem] tw:lg:w-[50rem]">
  <div class="tw:py-6">
    <div class="row">
      <div class="col-xs-12 col-sm-6">
        <div class="form-group" [appFormError]="section">
          <label for="section">Section *</label>

          <input type="text" id="section" name="section" #section="ngModel" class="form-control" required
                 pattern="^[A-Za-z0-9]{1,2}$" [(ngModel)]="ngBienCadastre.section" style="text-transform: uppercase;">
        </div>
      </div>

      <div class="col-xs-12 col-sm-6">
        <div class="form-group" [appFormError]="parcelle">
          <label for="parcelle">Parcelle *</label>

          <input type="text" id="parcelle" name="parcelle" #parcelle="ngModel" class="form-control" pattern="^[1-9]\d*$"
                 [(ngModel)]="ngBienCadastre.parcelle" required>
        </div>
      </div>

      <div class="col-xs-12 col-sm-6">
        <div class="form-group" [appFormError]="lot">
          <label for="lot">Lot</label>

          <input type="text" id="lot" name="lot" #lot="ngModel" class="form-control" pattern="^[1-9]\d*$"
                 [(ngModel)]="ngBienCadastre.lot">
        </div>
      </div>

      <div class="col-xs-12 col-sm-6">
        <div class="form-group" [appFormError]="surface">
          <label for="surface">Surface</label>

          <div class="input-group input-group-number">
            <input type="text" id="surface" name="surface" #surface="ngModel" class="form-control"
                   pattern="^(?:\d*.)?\d+$" [(ngModel)]="ngBienCadastre.surface">

            <span class="input-group-addon">m<sup>2</sup></span>
          </div>
        </div>
      </div>

      <div class="col-xs-12 col-sm-6 tw:opacity-50">
        <div class="form-group" [appFormError]="cadastreCommune.formInput">
          <label>Commune *</label>

          <app-commune-select #cadastreCommune="communeSelect" [commune]="commune" (selected)="communeSelected($event)"
                              [options]="{required: true}"/>
        </div>
      </div>

      <div class="col-xs-12 col-sm-6 tw:opacity-50">
        <div class="form-group" [appFormError]="prefixe">
          <label for="prefixe">Préfixe</label>

          <input type="text" id="prefixe" name="prefixe" #prefixe="ngModel" class="form-control" pattern="^\d{3}$"
                 [(ngModel)]="ngBienCadastre.prefixe">
        </div>
      </div>
    </div>
  </div>

  <div class="tw:float-right">
    <button type="submit" class="tw:btn-primary-info"><fa-icon [icon]="'check'"/><span>Enregistrer</span></button>
  </div>
</form>
