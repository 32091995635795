import {inject, Injectable} from '@angular/core';
import {NotestimBienFactory} from '@models/notestim/biens/bien/notestim-bien.factory';
import {INotestimBienApi} from '@models/notestim/biens/bien/notestim-bien.interfaces';
import NotestimBien from '@models/notestim/biens/bien/notestim-bien.model';

@Injectable({providedIn: 'root'})
export class NotestimBiensFactory {
    private _notestimBienFactory = inject(NotestimBienFactory);

    create(notestimBienApis: INotestimBienApi[] = []): NotestimBien[] {
        return (notestimBienApis || []).filter(notestimBienApi => notestimBienApi)
            .map(notestimBienApi => this._notestimBienFactory.create(notestimBienApi));
    }
}
