import {Directive, ElementRef, inject, Input, Renderer2, ViewContainerRef} from '@angular/core';
import {CollectionSelectionService} from '@shared/collection/selection/collection-selection.service';
import {CollectionSelectionComponent} from '@shared/collection/selection/collection-selection.component';
import {CollectionSelectionAllComponent} from '@shared/collection/selection/all/collection-selection-all.component';
import {IModel} from '@models/model.interfaces';
import {delay} from 'rxjs';
import {map, startWith, tap} from 'rxjs/operators';
import {ICollectionSelection} from '@shared/collection/selection/collection-selection.interfaces';

@Directive({selector: '[appCollectionSelection]'})
export class AppCollectionSelectionDirective {
    static readonly CLASS_COLLECTION_SELECTION_HEAD = 'tw:collection-selection-head';
    static readonly CLASS_COLLECTION_SELECTION_ROW = 'tw:collection-selection-row';

    private _collectionSelectionService = inject(CollectionSelectionService);
    private _elementRef = inject(ElementRef<HTMLElement>);
    private _renderer2 = inject(Renderer2);
    private _viewContainerRef = inject(ViewContainerRef);
    private _listName!: string;

    @Input()
    set appCollectionSelection(collectionSelection: ICollectionSelection) {
        this._listName = collectionSelection.listName;
        collectionSelection.collection.updated$.pipe(
            startWith(undefined),
            delay(50),
            map(_ => collectionSelection.collection.results),
            tap(_ => this.configHeader()),
            tap(list => this.configChildren(list)),
        ).subscribe(list => this._collectionSelectionService.init(this._listName, list, collectionSelection.options));
    }

    configChildren(list: IModel[]): void {
        const tBody = this._elementRef.nativeElement.getElementsByTagName('tbody')[0];

        tBody.childNodes.forEach((childNode: ChildNode, index: number) => {
            const element = list[index];
            const tr = childNode as HTMLTableRowElement;

            if (!element || !this.checkTbodyTr(tr)) {
                return;
            }

            const idSelectionChildren = 'id-selection-children-' + element.uuid;

            if ((tr.firstChild as HTMLTableCellElement).id === idSelectionChildren) {
                return;
            }

            const td = this._renderer2.createElement('td') as HTMLTableCellElement;

            this._renderer2.addClass(td, AppCollectionSelectionDirective.CLASS_COLLECTION_SELECTION_ROW);
            this._renderer2.setAttribute(td, 'id', idSelectionChildren);

            const componentRef = this._viewContainerRef.createComponent(CollectionSelectionComponent);

            componentRef.instance.item = element;
            componentRef.instance.listName = this._listName;
            this._renderer2.appendChild(td, componentRef.location.nativeElement);
            this._renderer2.insertBefore(tr, td, tr.firstChild);
        });
    }

    configHeader(): void {
        if (!this._elementRef.nativeElement.id) {
            this._renderer2.setAttribute(this._elementRef.nativeElement, 'id', Math.random().toString());
        }

        const trHead = this.getTrHead();

        if (!trHead || trHead.localName !== 'tr') {
            return;
        }

        const thFirst = trHead.firstChild as HTMLTableSectionElement;

        if (!thFirst || thFirst.localName !== 'th') {
            return;
        }

        const idSelection = 'id-selection-parent-' + this._elementRef.nativeElement.id;
        let thSelection: HTMLTableSectionElement;

        if (thFirst.id === idSelection) {
            thSelection = thFirst;
        } else {
            thSelection = this._renderer2.createElement('th') as HTMLTableSectionElement;
            this._renderer2.setAttribute(thSelection, 'id', idSelection);
            this._renderer2.addClass(thSelection, AppCollectionSelectionDirective.CLASS_COLLECTION_SELECTION_HEAD);
            this._renderer2.insertBefore(trHead, thSelection, thFirst);
        }

        let selectionAllElement = thSelection.firstChild as HTMLElement;

        if (!selectionAllElement) {
            const componentRef = this._viewContainerRef.createComponent(CollectionSelectionAllComponent);
            const div = this._renderer2.createElement('div') as HTMLDivElement;

            componentRef.instance.listName = this._listName;
            selectionAllElement = componentRef.location.nativeElement as HTMLElement;
            this._renderer2.appendChild(div, selectionAllElement);
            this._renderer2.appendChild(thSelection, div);
        }

        const selectionAllElementInput = selectionAllElement.firstChild;

        this._renderer2.removeAttribute(selectionAllElementInput, 'disabled');
        this._renderer2.removeAttribute(selectionAllElementInput, 'title');
    }

    getTrHead(): HTMLTableRowElement {
        const thead = this._elementRef.nativeElement.firstChild as HTMLTableSectionElement;

        if (!thead || thead.localName !== 'thead') {
            return undefined!;
        }

        return thead.firstChild as HTMLTableRowElement;
    }

    checkTbodyTr(tr: Element): boolean {
        return tr.localName === 'tr' && (tr.firstChild as HTMLTableCellElement).localName === 'td';
    }
}
