<div class="tw:mb-5">
  <h3 class="tw:text-lg tw:font-medium tw:leading-6 tw:text-gray-700">
    <fa-icon [icon]="'feather-alt'"/> Création d'une signature électronique
  </h3>
</div>

<form (submit)="validChoice()" class="tw:w-[80vw] tw:sm:w-96">
  <div [appFormError]="providersForm.formInput" class="tw:my-8 tw:mx-24">
    <app-form-radio [list]="providers" [model]="provider" [options]="providersFormOptions"
                    (selected)="provider = $event" #providersForm="formRadio"/>
  </div>

  <div class="tw:mt-4 tw:flex tw:justify-end">
    <button type="submit" class="tw:btn-primary-info">Créer la signature</button>
  </div>
</form>
