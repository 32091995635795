import {Component, inject, OnDestroy, OnInit} from '@angular/core';
import {firstValueFrom, from, Observable, of, Subject} from 'rxjs';
import {Router} from '@angular/router';
import {CDossierFilesService} from '@models/dossiers/dossier/files/collection/dossier-files.collection.service';
import {DossierService} from '@core/models/dossiers/dossier/dossier.service';
import CDossierFiles from '@models/dossiers/dossier/files/collection/dossier-files.collection.model';
import {map, switchMap, take, takeUntil, tap} from 'rxjs/operators';
import DossierFile from '@models/dossiers/dossier/files/file/dossier-file.model';
import {CollectionSelectionService} from '@shared/collection/selection/collection-selection.service';
import {CallToActionService} from '@shared/call-to-action/call-to-action.service';
import {
    DossierFilesActionsSelectionComponent
} from '@features/dossiers/dossier/files/actions/dossier-files.actions-selection.component';
import {DossierFileService} from '@models/dossiers/dossier/files/file/dossier-file.service';
import {DossierFilesService} from '@models/dossiers/dossier/files/dossier-files.service';
import ADossier from '@models/dossiers/dossier/dossier.model.abstract';
import TextsSizeFormat from '@shared/texts/size/texts-size.format';
import {NgInjectorService} from '@shared/angularJS/injector.ng.service';
import {NgEskTaskRunner} from '@legacy/app/eskimmo/eskimmo';
import {
    DossierFileDropdownComponent
} from '@features/dossiers/dossier/files/file/dropdown/dossier-file.dropdown.component';
import {IDropdownClicked} from '@shared/dropdown/dropdown.interfaces';
import {IDossierFilesListOptions} from '@features/dossiers/dossiers.interfaces';
import {
    DossierFilesActionsMainComponent
} from '@features/dossiers/dossier/files/actions/dossier-files.actions-main.component';
import {ContactsService} from '@models/contacts/contacts.service';

@Component({
    selector: 'layout-dossier-files',
    standalone: false,
    templateUrl: 'layout-dossier-files.component.html',
})
export class AppLayoutDossierFilesComponent implements OnDestroy, OnInit {
    static readonly LIST_OPTIONS: IDossierFilesListOptions = {nameSelection: 'layout-dossier-files-list-selection'};
    static readonly TYPE_DOCUMENTS = 'document';
    private _callToActionService = inject(CallToActionService);
    private _cDossierFilesService = inject(CDossierFilesService);
    private _collectionSelectionService = inject(CollectionSelectionService);
    private _contactsService = inject(ContactsService);
    private _dossierFileService = inject(DossierFileService);
    private _dossierFilesService = inject(DossierFilesService);
    private _dossierService = inject(DossierService);
    private _ngInjectorService = inject(NgInjectorService);
    private _router = inject(Router);
    private _currentDossier!: ADossier;
    private _isCamillaRendering = false;
    private _listOptions: IDossierFilesListOptions = {...AppLayoutDossierFilesComponent.LIST_OPTIONS};
    private readonly _onDestroy$ = new Subject<void>();
    private _sharingDossierFileUuids: string[] = [];
    private _subTextSelection = '';
    private _typeDossieFileDocument = AppLayoutDossierFilesComponent.TYPE_DOCUMENTS;
    private _updateTasksSource = new Subject<void>();
    private _updateTasks$ = this._updateTasksSource.asObservable();
    private _updateTasksErrorsSource = new Subject<void>();
    private _updateTasksErrors$ = this._updateTasksErrorsSource.asObservable();

    // Supprimer les injections des anciens manager
    private get ngEskTaskRunner(): NgEskTaskRunner {
        return this._ngInjectorService.getService('EskTaskRunner');
    }

    get ACTIONS_MAIN(): string {
        return CallToActionService.MAIN;
    }

    get cDossierFiles$(): Observable<CDossierFiles> {
        return this._cDossierFilesService.current$;
    }

    get CONTENT_ALLOWED_TYPES(): string {
        return DossierFile.CONTENT_ALLOWED_TYPES;
    }

    get currentDossier(): ADossier {
        return this._currentDossier;
    }

    get hasSelectedItems(): boolean {
        return this._collectionSelectionService.hasValues(AppLayoutDossierFilesComponent.LIST_OPTIONS.nameSelection!);
    }

    get isCamillaRendering(): boolean {
        return this._isCamillaRendering;
    }

    get listOptions(): IDossierFilesListOptions {
        return this._listOptions;
    }

    get sharingDossierFileUuids(): string[] {
        return this._sharingDossierFileUuids;
    }

    get subTextSelection(): string {
        return this._subTextSelection;
    }

    get UPLOAD_INFOS_CLIENT_FILTER(): unknown {
        return {type: this._typeDossieFileDocument, idDossier: this.currentDossier?.id};
    }

    get updateTasks$(): Observable<void> {
        return this._updateTasks$;
    }

    get updateTasksErrors$(): Observable<void> {
        return this._updateTasksErrors$;
    }

    ngOnInit(): void {
        this._currentDossier = this._dossierService.getCurrentFromNg(this._router.url);
        this._isCamillaRendering = this._router.url.endsWith('/ged');
        this._listOptions.dropdownOptions = {
            enabledActions: {
                read: true,
                rename: !this.currentDossier.isArchived(),
                share: !this.currentDossier.isArchived(),
                delete: !this.currentDossier.isArchived(),
            },
        };
        this._listOptions.enabledSelection = !this.currentDossier.isArchived();
        this._typeDossieFileDocument = AppLayoutDossierFilesComponent.TYPE_DOCUMENTS + '_' + this.currentDossier.dossierType;
        this._callToActionService.clicked$.pipe(takeUntil(this._onDestroy$)).subscribe(callToActionClicked => {
            let actionExec$: Observable<unknown> = of(undefined!);

            if (callToActionClicked.action === DossierFilesActionsMainComponent.actions.IMPORT) {
                const file = callToActionClicked.value as File;

                actionExec$ = this.importFile$(file);
            } else if (callToActionClicked.action === DossierFilesActionsSelectionComponent.actions.DELETE) {
                const action$ = (dossierFiles: Set<DossierFile>) => this._cDossierFilesService.deleteFromCurrent$(this.currentDossier, Array.from(dossierFiles));

                actionExec$ = this._collectionSelectionService.operateListSelected$(AppLayoutDossierFilesComponent.LIST_OPTIONS.nameSelection!, action$);
            } else if (callToActionClicked.action === DossierFilesActionsSelectionComponent.actions.SHARE) {
                const action$ = (dossierFiles: Set<DossierFile>) => this.share$(this.currentDossier, Array.from(dossierFiles));

                actionExec$ = this._collectionSelectionService.operateListSelected$(AppLayoutDossierFilesComponent.LIST_OPTIONS.nameSelection!, action$);
            }

            this._callToActionService.actionExec$(actionExec$).pipe(take(1)).subscribe();
        });
    }

    ngOnDestroy(): void {
        this._onDestroy$.next();
    }

    clicked(dropdownClicked: IDropdownClicked): void {
        const dossierFile = dropdownClicked.value as DossierFile;

        if (dropdownClicked.action === DossierFileDropdownComponent.actions.DELETE) {
            this._cDossierFilesService.deleteFromCurrent$(this.currentDossier, [dossierFile]).pipe(take(1)).subscribe();
        } else if (dropdownClicked.action === DossierFileDropdownComponent.actions.READ) {
            this._dossierFileService.read$(dossierFile).pipe(take(1)).subscribe();
        } else if (dropdownClicked.action === DossierFileDropdownComponent.actions.RENAME) {
            this._dossierFileService.rename$(dossierFile).pipe(take(1)).subscribe();
        } else if (dropdownClicked.action === DossierFileDropdownComponent.actions.SHARE) {
            this.updateShare$(this.currentDossier, dossierFile).pipe(take(1)).subscribe();
        }
    }

    dossierFilesSelected(dossierFiles: DossierFile[]): void {
        const amountSizeTotal = dossierFiles.map(dossierFile => dossierFile.media.totalBytes).reduce((amountSize, size) => amountSize + size, 0);

        this._subTextSelection = `${dossierFiles.length} ${dossierFiles.length > 1 ? 'documents' : 'document'} (${TextsSizeFormat.convertToConvivial(amountSizeTotal)})`;
    }

    importFile(file: File): void {
        this.importFile$(file).pipe(take(1)).subscribe();
    }

    importFile$(file: File): Observable<boolean> {
        const task = this.ngEskTaskRunner.createTaskDocumentFromFile(this.currentDossier, file);

        this._updateTasksSource.next();
        task.promise = task.promise
            .then(dossierFile => dossierFile as DossierFile)
            .then(
                dossierFile => firstValueFrom(this.cDossierFiles$.pipe(
                    take(1),
                    tap(cDossierFiles => cDossierFiles?.addResult(dossierFile)),
                )),
                () => this._updateTasksErrorsSource.next(),
            )
            .finally(() => this._updateTasksSource.next());

        return from(task.promise).pipe(map(cDossierFiles => !!cDossierFiles));
    }

    share$(dossier: ADossier, dossierFiles: DossierFile[]): Observable<boolean> {
        return this._contactsService.selectFromDossierFiles$(dossierFiles).pipe(
            switchMap(contacts => {
                if (!contacts) {
                    return of(undefined as unknown as boolean);
                }

                this._sharingDossierFileUuids = dossierFiles.map(dossierFile => dossierFile.uuid);

                return this._dossierFilesService.share$(dossier, dossierFiles, contacts).pipe(map(_ => true));
            }),
            tap(_ => this._sharingDossierFileUuids = []),
        );
    }

    updateShare$(dossier: ADossier, dossierFile: DossierFile): Observable<boolean> {
        return this._contactsService.selectFromDossierFiles$([dossierFile]).pipe(
            switchMap(contacts => {
                if (!contacts) {
                    return of(undefined as unknown as boolean);
                }

                this._sharingDossierFileUuids = [dossierFile.uuid];

                return this._dossierFileService.updateShare$(dossier, dossierFile, contacts).pipe(map(_ => true));
            }),
            tap(_ => this._sharingDossierFileUuids = []),
        );
    }
}
