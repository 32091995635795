@if (cBienPhotos$ | async; as cBienPhotos) {
  <div class="tw:hidden tw:sm:flex tw:gap-4">
    <div class="tw:w-3/4 tw:lg:w-3/5"
         [ngClass]="{'tw:mx-[17%] tw:lg:mx-[20%]': cBienPhotos.total <= 1, 'tw:lg:ml-[10%]': 1 < cBienPhotos.total && cBienPhotos.total <= 3}">
      <ng-container *ngTemplateOutlet="photo;context:{bienPhoto: cBienPhotos.results?.[0], priority: true, size: ImageSize.D16_BY_9}"/>
    </div>

    @if (cBienPhotos.total > 1) {
      <div class="tw:hidden tw:lg:grid tw:gap-4"
           [ngClass]="{'tw:grid-cols-1 tw:w-1/5': 1 < cBienPhotos.total && cBienPhotos.total <= 3, 'tw:grid-cols-2 tw:w-2/5': 3 < cBienPhotos.total}">
        @for (bienPhoto of cBienPhotos.results.slice(1, cBienPhotos.total <= 5 ? cBienPhotos.total : 4); track bienPhoto) {
          <div class="tw:aspect-square">
            <ng-container *ngTemplateOutlet="photo;context:{bienPhoto, size: ImageSize.THUMBNAIL}"/>
          </div>
        }

        <div class="tw:aspect-square"
             [ngClass]="{'tw:hidden': cBienPhotos.total <= 5, 'tw:block': cBienPhotos.total > 5}">
          <ng-container *ngTemplateOutlet="morePhoto;context:{nbMorePhotos: cBienPhotos.total - 4}"/>
        </div>

        @if (cBienPhotos.total === 2) {
          <div class="tw:aspect-square"></div>
        }
      </div>

      <div class="tw:lg:hidden tw:w-1/4 tw:flex tw:flex-col tw:gap-4">
        @for (bienPhoto of cBienPhotos.results.slice(1, cBienPhotos.total <= 3 ? cBienPhotos.total : 2); track bienPhoto) {
          <div class="tw:aspect-square">
            <ng-container *ngTemplateOutlet="photo;context:{bienPhoto, size: ImageSize.THUMBNAIL}"/>
          </div>
        }

        <div class="tw:aspect-square"
             [ngClass]="{'tw:hidden': cBienPhotos.total <= 3, 'tw:block': cBienPhotos.total > 3}">
          <ng-container *ngTemplateOutlet="morePhoto;context:{nbMorePhotos: cBienPhotos.total - 2}"/>
        </div>

        @if (cBienPhotos.total === 2) {
          <div class="tw:aspect-square"></div>
        }
      </div>
    }
  </div>

  <div class="tw:flex tw:sm:hidden tw:flex-col tw:gap-2 tw:aspect-photo">
    <ng-container *ngTemplateOutlet="photo;context:{bienPhoto: cBienPhotos.results?.[0], size: ImageSize.D4_BY_3}"/>

    <div class="tw:flex tw:justify-end">
      @if (cBienPhotos.total > 1) {
        <button type="button" class="tw:btn-tertiary-info" (click)="clicked.emit({cBienPhotos})">
          Voir les photos supplémentaires
        </button>
      }
    </div>
  </div>

  <ng-template #photo let-bienPhoto="bienPhoto" let-priority="priority" let-size="size">
    <div class="tw:h-full tw:overflow-hidden tw:rounded-xl tw:shadow-xl tw:hover:ring-2 tw:hover:ring-offset-2 tw:hover:ring-blue-400">
      @if ($any(bienPhoto)?.linkFile) {
        <div class="tw:relative tw:h-full tw:w-full">
          <img [ngSrc]="$any(bienPhoto).linkFile + '?size=' + size" (click)="clicked.emit({bienPhoto, cBienPhotos})"
               class="tw:mx-auto tw:object-cover tw:cursor-pointer" [priority]="priority" fill>
        </div>
      } @else {
        @if (currentEtude$ | async; as currentEtude) {
          <div class="tw:relative tw:h-full tw:w-full">
            <img [ngSrc]="dossierBien.interne ? currentEtude.linkLogo : currentEtude.linkMarianne" [priority]="priority"
                 class="tw:mx-auto tw:object-cover" fill>
          </div>
        }
      }
    </div>
  </ng-template>

  <ng-template #morePhoto let-nbMorePhotos="nbMorePhotos">
    <div class="tw:h-full tw:overflow-hidden tw:flex tw:flex-col tw:items-center tw:justify-center tw:gap-4 tw:rounded-xl tw:border-4 tw:border-gray-200 tw:shadow-xl tw:cursor-pointer tw:hover:ring-2 tw:hover:ring-blue-400"
         (click)="clicked.emit({cBienPhotos})">
      <fa-icon [icon]="['fal', 'images']" size="2x"/>

      <div class="tw:px-4 tw:text-center">{{nbMorePhotos}} photos supplémentaires</div>
    </div>
  </ng-template>
} @else {
  <div class="tw:hidden tw:sm:flex tw:gap-4">
    <app-loader [classHeight]="'tw:h-40'" class="tw:block tw:w-3/4 tw:lg:w-3/5"/>

    <app-loader [classHeight]="'tw:h-40'" class="tw:block tw:w-1/4 tw:lg:w-2/5"/>
  </div>

  <app-loader [classHeight]="'tw:h-40'" class="tw:block tw:sm:hidden"/>
}
