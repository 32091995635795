import {inject, Injectable} from '@angular/core';
import {BonvisiteVenteFactory} from '@models/bonvisites/bonvisite/ventes/vente/bonvisite-vente.factory';
import {IBonvisiteVenteApi} from '@models/bonvisites/bonvisite/ventes/vente/bonvisite-vente.interfaces';
import BonvisiteVente from '@models/bonvisites/bonvisite/ventes/vente/bonvisite-vente.model';

@Injectable({providedIn: 'root'})
export class BonvisiteVentesFactory {
    private _bonvisiteVenteFactory = inject(BonvisiteVenteFactory);

    create(bonvisiteVenteApis: IBonvisiteVenteApi[] = []): BonvisiteVente[] {
        return (bonvisiteVenteApis || []).filter(bonvisiteVenteApi => bonvisiteVenteApi)
            .map(bonvisiteVenteApi => this._bonvisiteVenteFactory.create(bonvisiteVenteApi));
    }
}
