@if (cLocationPasserelles$ | async; as cLocationPasserelles) {
  <div class="tw:overflow-hidden">
    @if (cLocationPasserelles.total > 0) {
      <table id="LocationPasserelles" class="tw:table-head-separate-body"
             [appCollectionSelection]="{collection: cLocationPasserelles, listName: options.nameSelection!, options: {initSelectAll: options.initSelectAll}}">
        <thead>
        <tr>
          <th class="tw:hidden tw:md:table-cell tw:w-[5.5rem]"><div>Photo</div></th>

          <th class="tw:w-40"><div>Référence</div></th>

          <th><div class="tw:truncate">Nom du dossier</div></th>

          <th class="tw:hidden tw:sm:table-cell tw:md:hidden tw:lg:table-cell tw:w-36"><div>Date de diffusion</div></th>

          <th class="tw:w-16 tw:lg:w-20 tw:print:hidden"><div><div class="tw:h-5"></div></div></th>
        </tr>
        </thead>

        <tbody>
          @for (locationPasserelle of cLocationPasserelles.results; track locationPasserelle) {
            <tr>
              <td class="tw:table-head-separate-body-img tw:hidden tw:md:table-cell">
                <app-bien-photo [linkDossierBien]="locationPasserelle.linkLocation"
                                class="tw:flex tw:h-14 tw:w-14 tw:object-contain"/>
              </td>

              <td><app-dossier-reference [link]="locationPasserelle.linkLocation"/></td>

              <td><app-contacts-group-convivial-name [linkDossier]="locationPasserelle.linkLocation"/></td>

              <td class="tw:hidden tw:sm:table-cell tw:md:hidden tw:lg:table-cell">
                {{ locationPasserelle.dateDiffusion | date }}
              </td>

              <td class="tw:table-head-separate-body-actions">
                <button class="tw:btn-secondary-info tw:bg-transparent tw:shadow-none tw:ring-0 tw:w-10" type="button"
                        #buttonElement (click)="clickOnLocationPasserelle(buttonElement, locationPasserelle)">
                  <fa-icon [icon]="'ellipsis-v'"/>
                </button>
              </td>
            </tr>
          }
        </tbody>
      </table>
    } @else {
      <div class="tw:mb-4 tw:sm:mb-8 tw:mx-auto tw:w-full tw:pt-6 tw:px-4 tw:text-center">
        <p class="tw:mx-auto tw:mb-4 tw:text-base tw:text-gray-500 tw:sm:text-lg tw:md:text-xl">
          Aucun bien en location n'est diffusé sur cette passerelle.
        </p>
      </div>
    }

    <app-load-more [collection$]="cLocationPasserelles$" (loadMore)="getMore(cLocationPasserelles)"
                   [loading]="pendingGetMore"/>
  </div>
} @else {
  <app-loader [classHeight]="'tw:h-40'" class="tw:block"/>
}
