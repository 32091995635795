<div class="tw:flex tw:flex-col tw:gap-2">
  <h4 class="tw:px-3 tw:lg:px-4">Risques</h4>

  <div class="tw:bg-gray-100 tw:rounded-xl tw:flex tw:flex-col tw:gap-4 tw:p-3 tw:lg:p-4">
    <ul class="tw:grid tw:gap-2 tw:text-sm tw:text-gray-700 tw:font-medium">
      @if (isZoneRisquesVisible) {
        <li>Bien dans une zone à risques</li>
      }
    </ul>

    @if (isCommentsRisquesVisible) {
      <div class="tw:text-sm tw:text-gray-700">{{ dossierBien.bien.commentsRisques }}</div>
    }

    <div class="tw:text-2xs tw:text-gray-500">
      Les informations sur les risques auxquels ce bien est exposé sont disponibles sur le site
      <a href="https://www.georisques.gouv.fr" target="_blank">
        www.georisques.gouv.fr <fa-icon [icon]="'external-link-alt'"/>
      </a>.
    </div>
  </div>
</div>
