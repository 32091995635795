export default `
<div class="container-fluid container-fullw tw:bg-white">
  <div class="noty-container">
    <div class="row documents-statuts">
      <div ng-class="!!$ctrl.statutDocuments ? 'col-sm-3' : 'col-sm-offset-4 col-sm-4'">
        <app-ng2-img [src]="'assets/images/full-room.jpg'"></app-ng2-img>
      </div>

      <ng-switch on="!!$ctrl.statutDocuments" class="statuts-explanation"
                 ng-class="!!$ctrl.statutDocuments ? 'col-sm-9' : 'col-sm-12'">
        <div ng-switch-default class="tw:text-center margin-top-30">
          <h4>
            Sur <app-noteo-icon-text-noty></app-noteo-icon-text-noty>, vous disposez d'une capacité de stockage
            globale à l'étude.
          </h4>
        </div>

        <div ng-switch-when="true" class="explanation-overload">
          <h3>Vous avez atteint la capacité maximale de stockage de vos documents !</h3>

          <h4>
            <div class="margin-bottom-15">
              Vous disposez de {{$ctrl.statutDocuments.quota / 1024 / 1024 | number:2}} Mo et vous utilisez
              déjà {{$ctrl.statutDocuments.used / 1024 / 1024 | number:2}} Mo.
            </div>

            Vous voulez ajouter un document {{$ctrl.statutDocuments.size / 1024 / 1024 | number:2}} Mo ce qui
            entraînera le dépassement de la capacité de stockage de l'étude.
          </h4>
        </div>
      </ng-switch>
    </div>

    <h3 class="tw:text-center">
      <esk-contact type="commerce" email-content="documents.advertisement.request"></esk-contact>

      <span>pour obtenir plus d’informations sur l'augmentation de la capacité de stockage de l'étude.</span>
    </h3>
  </div>
</div>
`;
