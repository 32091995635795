import {inject, Injectable} from '@angular/core';
import {EtudePasserelleFactory} from '@models/etudes/etude/passerelles/passerelle/etude-passerelle.factory';
import {IEtudePasserelleApi} from '@models/etudes/etude/passerelles/passerelle/etude-passerelle.interfaces';
import EtudePasserelle from '@models/etudes/etude/passerelles/passerelle/etude-passerelle.model';

@Injectable({providedIn: 'root'})
export class EtudePasserellesFactory {
    private _etudePasserelleFactory = inject(EtudePasserelleFactory);

    create(etudePasserelleApis: IEtudePasserelleApi[] = []): EtudePasserelle[] {
        return (etudePasserelleApis || []).filter(etudePasserelleApi => etudePasserelleApi)
            .map(etudePasserelleApi => this._etudePasserelleFactory.create(etudePasserelleApi));
    }
}
