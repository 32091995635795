export default `
<div class="modal-header">
  <button type="button" class="btn btn-default pull-right" ng-click="$dismiss()"
          title="Fermer sans imprimer"><fa-icon [icon]="'times'"></fa-icon></button>

  <h3 class="modal-title"><fa-icon icon="print"></fa-icon> Impression avancée</h3>
</div>

<div class="modal-body">
  <div ng-if="$ctrl.affichetteAuthorized" class="form-group margin-bottom-10 tw:text-center">
    <div class="radio clip-radio radio-primary radio-inline">
      <input type="radio" id="type-document-fiche" name="type-document" value="fiche" ng-model="$ctrl.typeDocument">

      <label for="type-document-fiche">Fiche</label>
    </div>

    <div class="radio clip-radio radio-primary radio-inline">
      <input type="radio" id="type-document-affichette" name="type-document" value="affichette"
             ng-model="$ctrl.typeDocument">

      <label for="type-document-affichette">Affichette</label>
    </div>
  </div>

  <div ng-if="$ctrl.typeDocument === 'fiche'">
    <div ng-if="$ctrl.codesTemplates.fiche.courrierCodes.length > 0" class="form-group margin-bottom-10">
      <label class="margin-bottom-10">Courrier introductif</label>

      <esk-select-template ng-model="$ctrl.selectedTemplates.ficheCourrierTemplate"
                           codes="$ctrl.codesTemplates.fiche.courrierCodes"
                           options="{placeholder: 'Sélectionner un modèle de courrier'}"></esk-select-template>
    </div>

    <div class="form-group margin-bottom-10">
      <label class="margin-bottom-10">Modèle de fiche</label>

      <esk-select-template ng-model="$ctrl.selectedTemplates.ficheTemplate" options="{required: true}"
                           codes="$ctrl.codesTemplates.fiche.codes"></esk-select-template>
    </div>
  </div>

  <div ng-if="$ctrl.typeDocument === 'affichette'">
    <div class="form-group margin-bottom-10">
      <esk-select-template-thumbnails codes="$ctrl.codesTemplates.affichette.codes"
                                      ng-model="$ctrl.selectedTemplates.affichetteTemplate"
                                      groups="$ctrl.codesTemplates.affichette.groups">
      </esk-select-template-thumbnails>
    </div>
  </div>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-primary" ng-click="$ctrl.print()">
    <fa-icon icon="print"></fa-icon> Imprimer
  </button>
</div>
`;
