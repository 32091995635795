<div class="tw:flex tw:items-center tw:h-full tw:bg-blue-100 tw:px-4 tw:py-3">
  <div class="tw:mr-auto tw:overflow-x-hidden">
    <button (click)="reset()" type="button" class="tw:p-2 tw:mr-2"><fa-icon [icon]="'times'"/></button>

    <span class="tw:text-sm tw:truncate" [ngPlural]="numberSelected">
      <ng-template ngPluralCase="=1">{{ numberSelected }} élément sélectionné</ng-template>

      <ng-template ngPluralCase="other">{{ numberSelected }} éléments sélectionnés</ng-template>

      @if (subText) {
         {{ subText }}
      }
    </span>
  </div>

  <div class="tw:flex tw:flex-nowrap"><app-call-to-action [type]="ACTIONS_SELECTION"/></div>
</div>
