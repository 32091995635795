<span class="tw:mx-auto tw:shrink-0 tw:flex tw:items-center tw:justify-center tw:h-12 tw:w-12 tw:rounded-full tw:sm:mx-0 tw:sm:h-10 tw:sm:w-10"
      [ngClass]="{'tw:bg-red-100 tw:text-red-700': ModalStatus.DANGER === status,
                  'tw:bg-green-100 tw:text-green-700': ModalStatus.SUCCESS === status,
                  'tw:bg-orange-100 tw:text-orange-700': ModalStatus.WARNING === status,
                  'tw:bg-blue-100 tw:text-blue-700': ![ModalStatus.DANGER, ModalStatus.SUCCESS, ModalStatus.WARNING].includes(status)}">
  @switch (status) {
    @case (ModalStatus.DANGER) {
      <fa-icon [icon]="'exclamation-circle'"/>
    }

    @case (ModalStatus.SUCCESS) {
      <fa-icon [icon]="'check-circle'"/>
    }

    @case (ModalStatus.WARNING) {
      <fa-icon [icon]="'exclamation-triangle'"/>
    }

    @default {
      <fa-icon [icon]="'info-circle'"/>
    }
  }
</span>
