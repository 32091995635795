import {Component, inject, Input} from '@angular/core';
import Report from '@models/reports/report/report.model';
import {Observable} from 'rxjs';
import CReportDossiers from '@models/reports/report/dossiers/collection/report-dossiers.collection.model';
import {CReportDossiersFactory} from '@models/reports/report/dossiers/collection/report-dossiers.collection.factory';
import {AsyncPipe, NgPlural, NgPluralCase, TitleCasePipe} from '@angular/common';
import {AppLoaderComponent} from '@shared/load/loader/loader.component';

@Component({
    imports: [AppLoaderComponent, AsyncPipe, NgPlural, NgPluralCase, TitleCasePipe],
    selector: 'app-report-popover',
    templateUrl: 'report.popover.component.html',
})
export class AppReportPopoverComponent {
    private _cReportDossiersFactory = inject(CReportDossiersFactory);
    private _cReportDossiers$!: Observable<CReportDossiers>;

    get cReportDossiers$(): Observable<CReportDossiers> {
        return this._cReportDossiers$;
    }

    @Input()
    set report(value: Report) {
        this._cReportDossiers$ = this._cReportDossiersFactory.get$(value);
    }
}
