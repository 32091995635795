<div class="tw:mb-5">
  <h3 class="tw:text-purple-500"><fa-icon [icon]="['fal', 'wand-sparkles']"/> {{ titre }}</h3>
</div>

<form class="tw:flex tw:flex-col tw:h-[25rem] tw:max-h-[60vh] tw:min-w-[80vw] tw:sm:min-w-lg tw:md:min-w-xl tw:lg:min-w-2xl tw:xl:min-w-[50rem]"
      (submit)="save()" #iaTextareaForm="ngForm">
  @let iaText = iaText$ | async;

  @if (iaText) {
    <div class="tw:flex-1" [appFormError]="generatedText">
      <textarea name="generatedText" [ngModel]="iaText" required #generatedText="ngModel"
                placeholder="Cliquez sur 'Générer' pour avoir la proposition de l'IA"
                class="tw:h-full tw:w-full tw:bg-white tw:rounded-md tw:border-purple-300 tw:px-3 tw:py-1.5 tw:focus:ring-purple-400 tw:text-gray-700 tw:placeholder:text-purple-400"></textarea>
    </div>
  } @else {
    <app-loader [classHeight]="'tw:h-full'" class="tw:flex-1 tw:block" [options]="{classList: 'tw:bg-purple-200'}"/>
  }

  <div class="tw:flex tw:justify-end tw:gap-4 tw:mt-4">
    <app-ia-button [options]="iaButtonOptions"/>

    <button type="submit" class="tw:btn-primary-info" [disabled]="!iaText"><fa-icon [icon]="'check'"/> Valider</button>
  </div>
</form>
