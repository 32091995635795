@if (pending$ | appLoadObs | async; as pending) {
  <div [ngClass]="pending.value ? 'tw:waiting' : undefined!" class="tw:flex tw:gap-4">
    <button type="button" class="tw:btn-primary-info" (click)="write()" [disabled]="pending.value">Rédiger</button>

    @if (showDropdown) {
      <button type="button" class="tw:btn-secondary-info tw:w-10" #buttonElement (click)="clickDropdown(buttonElement)">
        <fa-icon [icon]="'ellipsis-v'"/>
      </button>
    }
  </div>
}
