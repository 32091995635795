import {AppDictionaryRadioComponent} from '@features/dictionaries/dictionary/radio/dictionary-radio.component';
import {NgModule} from '@angular/core';
import {AppDictionarySelectItemsComponent} from '@features/dictionaries/dictionary/items/select/dictionary-select-items.component';
import {AppDictionaryCheckboxComponent} from '@features/dictionaries/dictionary/checkbox/dictionary-checkbox.component';
import {
    AppDictionaryItemConvivialNameComponent
} from '@features/dictionaries/dictionary/items/item/convivial-name/dictionary-item.convivial-name.component';

@NgModule({
    declarations: [],
    imports: [
        AppDictionaryCheckboxComponent,
        AppDictionaryItemConvivialNameComponent,
        AppDictionaryRadioComponent,
        AppDictionarySelectItemsComponent,
    ],
    exports: [
        AppDictionaryCheckboxComponent,
        AppDictionaryItemConvivialNameComponent,
        AppDictionaryRadioComponent,
        AppDictionarySelectItemsComponent,
    ],
})
export class DictionariesModule {
}
