<div class="tw:flex tw:flex-col tw:gap-2">
  <h4 class="tw:px-3 tw:lg:px-4">Urbanisme</h4>

  <div class="tw:bg-gray-100 tw:rounded-xl tw:flex tw:flex-col tw:gap-4 tw:p-3 tw:lg:p-4">
    <ul class="tw:grid tw:gap-2 tw:text-sm tw:text-gray-700 tw:font-medium">
      @if (isZonePLUVisible) {
        <li>
          <span class="tw:text-gray-500 tw:text-xs tw:font-normal">Zone PLU</span>

          <div class="tw:text-gray-700 tw:font-normal">{{dossierBien.bien.zonePLU}}</div>
        </li>
      }
    </ul>

    @if (isCommentsUrbanismeVisible) {
      <div class="tw:text-sm tw:text-gray-700">{{dossierBien.bien.commentsUrbanisme}}</div>
    }
  </div>
</div>
