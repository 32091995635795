@if (isOn$ | appLoadObs | async; as isOn) {
  <div class="tw:bg-red-600 tw:px-6 tw:sm:px-3.5 tw:text-center" [class]="isOn.value ? 'tw:hidden' : ''">
    <div class="tw:py-2.5 tw:text-sm/6 tw:text-white">
      Vous êtes hors ligne, vous n'avez aucune connexion Internet.

      <button type="button" class="tw:btn-tertiary-default tw:font-normal tw:text-white tw:underline"
              (click)="openMoreInfos()">En savoir plus</button>
    </div>
  </div>
}
