import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Inject, inject, Injectable} from '@angular/core';
import {AuthService} from '@core/auth/core/auth.service';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {catchError, filter, map, switchMap, take} from 'rxjs/operators';
import {UrlService} from '@shared/texts/url/url.service';
import {DOCUMENT} from '@angular/common';

@Injectable({providedIn: 'root'})
export class AuthInterceptor implements HttpInterceptor {
    private _authService = inject(AuthService);
    private _document: Document;
    private _urlService = inject(UrlService);
    private _isRefreshing = false;
    private _refreshTokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null!);

    constructor(@Inject(DOCUMENT) document: Document) {
        this._document = document;
    }

    intercept<T = unknown>(httpRequest: HttpRequest<T>, httpHandler: HttpHandler): Observable<HttpEvent<T>> {
        if (this._urlService.isExcluded(httpRequest, this._authService.excludedUrls)) {
            return httpHandler.handle(httpRequest);
        }

        httpRequest = this._setHeaders(httpRequest, this._authService.getAccessToken());
        httpRequest = this.setXDebugParams(httpRequest);

        return httpHandler.handle(httpRequest).pipe(catchError((error: unknown) => {
            if (error instanceof HttpErrorResponse && error.status === 401) {
                return this._handle401Error(httpRequest, httpHandler);
            }

            return throwError(() => error);
        }));
    }

    // @todo Supprimer la notion de private
    private _setHeaders<T>(request: HttpRequest<T>, accessToken: string | null): HttpRequest<T> {
        const headers: Record<string, string> = {};

        if (accessToken) {
            headers.Authorization = `Bearer ${accessToken}`;
        }

        return request.clone({setHeaders: headers});
    }

    getXDebugSession(): string | undefined {
        const value = `; ${this._document.cookie}`;
        const parts = value.split(`; XDEBUG_SESSION=`);
        let xdebug: string | undefined;

        if (parts.length === 2) {
            xdebug = parts?.pop()?.split(';')?.shift();
        }

        return xdebug;
    }

    setXDebugParams<T>(request: HttpRequest<T>): HttpRequest<T> {
        const xdebug = this.getXDebugSession();
        const params: Record<string, string> = {};

        if (xdebug) {
            params.XDEBUG_SESSION = xdebug;
        }

        return request.clone({setParams: params});
    }

    // @todo Supprimer la notion de private
    private _handle401Error<T>(httpRequest: HttpRequest<T>, httpHandler: HttpHandler): Observable<HttpEvent<T>> {
        if (!this._isRefreshing) {
            this._isRefreshing = true;
            this._refreshTokenSubject.next(null!);
            this._authService.refreshAccessToken$().pipe(map(response => response.token), take(1)).subscribe({
                next: accessToken => this._refreshTokenSubject.next(accessToken),
                complete: () => this._isRefreshing = false,
            });
        }

        return this._refreshTokenSubject.pipe(
            filter(accessToken => !!accessToken),
            take(1),
            switchMap(accessToken => httpHandler.handle(this._setHeaders(httpRequest, accessToken))),
        );
    }
}
