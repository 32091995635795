@if (cSignataires$ | async; as cSignataires) {
  <div appProvideParentForm>
    @if (options.enabledSignatairesAdding) {
      <div class="tw:flex tw:justify-center tw:gap-4 tw:mb-4">
        <div class="tw:relative tw:w-80">
          <app-participants-select [options]="{loadingText: 'Chargement des contacts / utilisateurs', notFoundText: 'Aucun contact / utilisateur trouvé', placeholder: 'Rechercher un contact / utilisateur'}"
                                   (selected)="addParticipant(cSignataires, $event)"/>

          @if (!amSignataire) {
            <button class="tw:absolute tw:right-0 tw:btn-tertiary-info tw:mt-px tw:p-0 tw:text-xs tw:font-normal"
                    type="button" (click)="addMe(cSignataires)">M'ajouter en tant que signataire</button>
          }
        </div>

        <button class="tw:btn-secondary-info" type="button" (click)="add(cSignataires)">
          <fa-icon [icon]="'plus'"/>
        </button>
      </div>
    }

    @if (cSignataires.total > 0) {
      <div class="tw:p-4 tw:md:px-0">
        <span class="tw:font-bold">{{ cSignataires.total }}</span>
        {{ cSignataires.total > 1 ? 'signataires' : 'signataire' }}
      </div>

      <table class="tw:table-head-separate-body">
        <thead>
        <tr>
          <th><div>Prénom</div></th>

          <th><div>Nom</div></th>

          @if (options.enabledEmail) {
            <th><div>E-mail</div></th>
          }

          @if (options.enabledPhone) {
            <th class="tw:w-36 tw:lg:w-[12.5rem]"><div>Téléphone</div></th>
          }

          @if (options.enabledStatut) {
            <th class="tw:w-28 tw:lg:w-32 tw:2xl:w-36"><div>Statut</div></th>
          }

          <th class="tw:w-16 tw:lg:w-20"><div><div class="tw:h-5"></div></div></th>
        </tr>
        </thead>

        <tbody>
          @for (signataire of cSignataires.results; track signataire; let idx = $index) {
            <tr>
              <td>
                <div class="tw:h-10 tw:lg:mr-4 tw:2xl:mr-8" [appFormError]="prenom">
                  <input class="tw:input-text tw:block" type="text" [(ngModel)]="signataire.prenom" placeholder="Prénom"
                         required maxlength="140" #prenom="ngModel" [name]="'prenom' + idx + options.tableId"
                         (blur)="edit(cSignataires, signataire)"
                         pattern="[a-zA-Z0-9àáâãäåæçèéêëìíîïñòóôõöùúûüýÿÀÁÂÃÄÅÇÈÉÊËÌÍÎÏÑÒÓÔÕÖÙÚÛÜÝ ()\-'’ß]{1,140}">
                </div>
              </td>

              <td>
                <div class="tw:h-10 tw:lg:mr-4 tw:2xl:mr-8" [appFormError]="nom">
                  <input class="tw:input-text tw:block" type="text" [(ngModel)]="signataire.nom" placeholder="Nom"
                         maxlength="140" #nom="ngModel" [name]="'nom' + idx + options.tableId"
                         required (blur)="edit(cSignataires, signataire)"
                         pattern="[a-zA-Z0-9àáâãäåæçèéêëìíîïñòóôõöùúûüýÿÀÁÂÃÄÅÇÈÉÊËÌÍÎÏÑÒÓÔÕÖÙÚÛÜÝ ()\-'’ß]{1,140}">
                </div>
              </td>

              @if (options.enabledEmail) {
                <td>
                  <div class="tw:h-10 tw:lg:mr-4 tw:2xl:mr-8" [appFormError]="email">
                    <input class="tw:input-text tw:block" type="text" [(ngModel)]="signataire.email"
                           placeholder="exemple@noty.com" required #email="ngModel" appFormEmailValidator
                           [name]="'email' + idx + options.tableId" (blur)="edit(cSignataires, signataire)">
                  </div>
                </td>
              }

              @if (options.enabledPhone) {
                <td>
                  <div class="tw:h-10" [appFormError]="phone">
                    <div class="tw:lg:mr-4 tw:2xl:mr-8">
                      <input class="tw:input-tel tw:block" type="tel" [(ngModel)]="signataire.telephone"
                             placeholder="06 06 06 06 06" required #phone="ngModel" appFormPhoneValidator
                             [name]="'phone' + idx + options.tableId" (blur)="edit(cSignataires, signataire)">
                    </div>
                  </div>
                </td>
              }

              @if (options.enabledStatut) {
                <td>
                  <div class="tw:h-10 tw:flex tw:items-center">
                    <div class="tw:lg:mr-4 tw:2xl:mr-8">
                      <app-procedure-signataire-statut [signataire]="signataire" class="tw:text-xs"/>
                    </div>
                  </div>
                </td>
              }

              <td class="tw:table-head-separate-body-actions">
                @if (showRemove) {
                  <button class="tw:btn-secondary-info tw:bg-transparent tw:shadow-none tw:ring-0 tw:w-10"
                          type="button" (click)="remove(cSignataires, signataire)"><fa-icon [icon]="'trash'"/></button>
                }

                @if (!signataire.isNewOrDraft() && showDropdown) {
                  <button class="tw:btn-secondary-info tw:bg-transparent tw:shadow-none tw:ring-0 tw:w-10"
                          #buttonElement (click)="clickDropdown(buttonElement, cSignataires, signataire)"
                          type="button"><fa-icon [icon]="'ellipsis-v'"/></button>
                }
              </td>
            </tr>
          }
        </tbody>
      </table>
    } @else {
      <div class="tw:my-4 tw:sm:my-8 tw:mx-auto tw:w-full tw:pt-6 tw:px-4 tw:text-center">
        <p class="tw:mx-auto tw:mb-4 tw:text-base tw:text-gray-500 tw:sm:text-lg tw:md:text-xl">{{ NO_SIGNATAIRES }}</p>
      </div>
    }
  </div>
} @else {
  <app-loader [classHeight]="'tw:h-40'" class="tw:block tw:mb-8"/>
}
