@if (cSites$ | async; as cSites) {
  <div>
    @if (cSites.total > 0) {
      <div class="tw:p-4 tw:md:px-0">
        <span class="tw:font-bold">{{ cSites.total }}</span> {{ cSites.total > 1 ? 'études' : 'étude' }}
      </div>

      <div class="tw:overflow-hidden tw:mb-8">
        <div class="tw:grid tw:grid-cols-1 tw:gap-y-4 tw:sm:grid-cols-2 tw:sm:gap-6 tw:lg:grid-cols-1 tw:xl:grid-cols-2 tw:xl:gap-x-8 tw:2xl:grid-cols-3">
          @for (site of cSites.results; track site) {
            <app-etude-site-card [site]="site" (mouseenter)="hovered.emit(site)"
                                 (mouseleave)="hovered.emit(undefined!)"/>
          }
        </div>
      </div>
    } @else {
      <div class="tw:my-4 tw:sm:my-8 tw:mx-auto tw:w-full tw:pt-6 tw:px-4 tw:text-center tw:flex tw:flex-col tw:gap-4">
        <p class="tw:text-base tw:text-gray-500 tw:sm:text-lg tw:md:text-xl">
          Aucune étude ne correspond à votre recherche.
        </p>

        <div class="tw:text-sm tw:text-left">
          <ul class="list-disc">
            <li>
              Vous avez recherché par mots clés, celle-ci se fait sur le nom du (ou des) notaire(s) de l'étude,
              la raison sociale et le CRPCEN.
            </li>

            <li>Dézoomez pour voir des études proches de votre recherche.</li>
          </ul>
        </div>

        <button type="button" class="tw:btn-primary-default" (click)="actionWhenNoResult.emit()">
          Effacer la recherche
        </button>
      </div>
    }
  </div>
} @else {
  <app-loader [classHeight]="'tw:h-40'" class="tw:block tw:mt-8"/>
}

<app-load-more [collection$]="cSites$" (loadMore)="getMore()" [loading]="pendingGetMore"/>
