import CommuneVoie from '@models/communes/commune/voies/voie/commune-voie.model';
import {ICommuneVoieApi} from '@models/communes/commune/voies/voie/commune-voie.interfaces';
import CCommuneVoies
    from '@models/communes/commune/voies/collection/commune-voies.collection.model';
import {getMockLinks} from '@models/links/links.fixtures';
import {getMockCollectionApi} from '@models/collection/collections.fixtures';
import {ICCommuneVoiesApi} from '@models/communes/commune/voies/collection/commune-voies.collection.interfaces';
import {getMockQuartier, getMockQuartierApi} from '@models/quartiers/quartiers.fixtures';

export const getMockCCommuneVoies = (): CCommuneVoies => {
    const cCommuneVoies = new CCommuneVoies();

    cCommuneVoies.links = getMockLinks();
    cCommuneVoies.page = 4;
    cCommuneVoies.pages = 8;
    cCommuneVoies.perPage = 2;
    cCommuneVoies.results = [getMockCommuneVoie(), getMockCommuneVoie()];
    cCommuneVoies.total = 5;

    return cCommuneVoies;
};

export const getMockCCommuneVoiesApi = (): ICCommuneVoiesApi => getMockCollectionApi([
    getMockCommuneVoieApi(),
    getMockCommuneVoieApi(),
]);

export const getMockCommuneVoie = (): CommuneVoie => {
    const suffix = 'commune-voie';
    const communeVoie = new CommuneVoie('uuid-' + suffix, 6);

    communeVoie.code = 'code-' + suffix;
    communeVoie.nom = 'nom-' + suffix;
    communeVoie.quartier = getMockQuartier();

    return communeVoie;
};

export const getMockCommuneVoieApi = (): ICommuneVoieApi => {
    const suffix = 'commune-voie-api';

    return {_embedded: {quartier: getMockQuartierApi()}, code: 'code-' + suffix, id: 6, nom: 'nom-' + suffix};
};

export class MockCCommuneVoiesFactory {
    create(): void {
    }

    get$(): void {
    }
}

export class MockCCommuneVoiesService {
    search$(): void {
    }
}

export class MockCommuneVoieFactory {
    create(): void {
    }

    ngCreate(): void {
    }
}

export class MockCommuneVoiesApiService {
    getCollection$(): void {
    }

    getCollectionByLink$(): void {
    }
}

export class MockCommuneVoiesFactory {
    create(): void {
    }
}
