@let isOnLine = isOnLine$ | async;

<div appProvideParentForm class="row">
  <!-- Informations générales -->
  <div class="col-xs-12 col-lg-7">
    @if (localisationEditable) {
      @if (isOnLine) {
        <div class="row">
          <!-- Commune editable -->
          <div class="col-xs-12 col-sm-8 col-md-7 col-lg-6">
            <div class="form-group" [appFormError]="bienCommune.formInput">
              <label>Commune *</label>

              <!-- Exclusion de Paris en attendant https://gitlab.soqrate.com/soqrate/noty/noty/-/issues/1417 -->
              <app-commune-select #bienCommune="communeSelect" [commune]="commune" (selected)="communeSelected($event)"
                                  [options]="{excludedCodeInsees: ['75056'], required: ngBienDossier.statut !== DOSSIER_BIEN_BROUILLON}"/>
            </div>
          </div>

          <!-- Récupération de la localisation du référent -->
          @if (ngBienDossier.bien.contactsGroup.members.length > 0) {
            <div class="form-group col-xs-12 col-sm-4 col-md-5 col-lg-6">
              <label>Saisie de la localisation</label>

              <soq-popover-template [display]="'default'" [placement]="'left'" [trigger]="'outsideClick'"
                                    [template]="'src/app/legacy/templates/biens/components/item/edit/localisation/saisie-from-referent.help.html'"/>

              <button type="button" class="btn btn-link tw:!block padding-top-0 padding-left-0"
                      (click)="getReferentLocalisation()">Récupérer l'adresse du référent</button>
            </div>
          }
        </div>
      } @else {
        <div class="tw:relative tw:min-h-12 tw:w-fit">
          <div class="tw:text-lg tw:pt-1 tw:pr-12">{{ commune ? commune.fullname : 'Pas de commune associée' }}</div>

          <app-browser-online-indicator/>
        </div>
      }
    }

    <div class="row">
      <!-- Commune blocked -->
      @if (!localisationEditable && ngBienDossier.bien.option.commune) {
        <div class="bien-commune-blocked">
          <div class="margin-top-5 pull-left">{{ngBienDossier.bien.option.commune.fullname}}</div>

          <div class="bien-commune-edit-help">
            <soq-popover-template [template]="'src/app/legacy/templates/biens/components/item/edit/localisation/commune.help.html'"
                                  [display]="'default'" [trigger]="'outsideClick'"/>
          </div>
        </div>
      }

      <!-- Code postal -->
      @if (!!ngBienDossier.bien.option.commune) {
        <div class="col-xs-12 col-sm-4 col-md-3">
          <div class="form-group" [appFormError]="codePostal">
            <label for="bien-adresse-code-postal">
              Code postal
              {{ngBienDossier._esk.typeDossier === DOSSIER_BIEN_TYPES.ESTIMATION && ngEstimation.type.code === ESTIMATION_TYPE_EVALUATION ? '*' : ''}}
            </label>

            @if ([DOSSIER_BIEN_TYPES.LOCATION, DOSSIER_BIEN_TYPES.VENTE].includes(ngBienDossier.bien._esk.type!)) {
              <div class="bien-codepostal-edit-help">
                <soq-popover-text [display]="'default'" [placement]="'left-top'" [trigger]="'outsideClick'"
                                  [text]="'Le code postal de votre bien est envoyé aux diffusions.'"/>
              </div>
            }
            <input id="bien-adresse-code-postal" type="text" class="form-control text-right" #codePostal="ngModel"
                   [(ngModel)]="ngBienDossier.bien.option.codePostal" name="{{FIELDS.codePostal}}" pattern="[0-9]{5}"
                   [required]="ngBienDossier._esk.typeDossier === DOSSIER_BIEN_TYPES.ESTIMATION && ngEstimation.type.code === ESTIMATION_TYPE_EVALUATION && !!ngEstimation.rapportId">
          </div>
        </div>

        <div class="col-xs-12">
          <div class="row">
            <!-- Numéro -->
            <div class="form-group col-sm-3 col-md-2" [appFormError]="numeroVoie">
              <label for="bien-adresse-num">N°</label>

              <input type="text" id="bien-adresse-num" class="form-control text-right" pattern="^[0-9]{0,6}$"
                     [(ngModel)]="ngBienDossier.bien.option.numeroVoie" #numeroVoie="ngModel" name="numeroVoie"
                     (change)="ngBienDossier.bien.updateNetworkAddress()">
            </div>

            <!-- Adresse -->
            <div class="col-sm-9 col-md-10 col-lg-7">
              @if (ngBienDossier.bien.option.commune.hasVoies) {
                @if (isOnLine) {
                  <div class="form-group" [appBienEditConstraints]="bienVoie.formInput">
                    <label>
                      Adresse
                      {{ngBienDossier._esk.typeDossier === DOSSIER_BIEN_TYPES.ESTIMATION && ngEstimation.type.code === ESTIMATION_TYPE_EVALUATION ? '*' : ''}}
                    </label>

                    <app-commune-voies-select #bienVoie="communeVoiesSelect" [commune]="commune"
                                              [options]="{name: 'nomVoie', required: ngBienDossier._esk.typeDossier === DOSSIER_BIEN_TYPES.ESTIMATION && ngEstimation.type.code === ESTIMATION_TYPE_EVALUATION && !!ngEstimation.rapportId}"
                                              [communeVoie]="communeVoie" (selected)="communeVoieSelected($event)"/>
                  </div>
                } @else {
                  <div class="form-group">
                    <label>
                      Adresse
                      {{ngBienDossier._esk.typeDossier === DOSSIER_BIEN_TYPES.ESTIMATION && ngEstimation.type.code === ESTIMATION_TYPE_EVALUATION ? '*' : ''}}
                    </label>

                    <p class="form-control-static">{{communeVoie ? communeVoie.nom : 'Pas de voie associée'}}</p>

                    <app-browser-online-indicator/>
                  </div>
                }
              } @else {
                <div class="form-group" [appBienEditConstraints]="nomVoie">
                  <label for="bien-adresse">
                    Adresse
                    {{ngBienDossier._esk.typeDossier === DOSSIER_BIEN_TYPES.ESTIMATION && ngEstimation.type.code === ESTIMATION_TYPE_EVALUATION ? '*' : ''}}
                  </label>

                  <input type="text" #nomVoie="ngModel" name="nomVoie" [(ngModel)]="ngBienDossier.bien.option.nomVoie"
                         id="bien-adresse" (change)="ngBienDossier.bien.updateNetworkAddress()" class="form-control"
                         [required]="ngBienDossier._esk.typeDossier === DOSSIER_BIEN_TYPES.ESTIMATION && ngEstimation.type.code === ESTIMATION_TYPE_EVALUATION && !!ngEstimation.rapportId">
                </div>
              }
            </div>

            <!-- Quartier -->
            @if (ngBienDossier.bien.option.commune.hasQuartiers) {
              @if (isOnLine) {
                <div class="col-sm-12 col-lg-3 form-group" [appBienEditConstraints]="bienQuartier.formInput">
                  <label>Quartier</label>

                  <app-commune-quartiers-select #bienQuartier="communeQuartiersSelect" [commune]="commune"
                                                [quartier]="quartier" (selected)="communeQuartiersSelected($event)"
                                                [options]="{name: 'quartier'}"/>
                </div>
              } @else {
                <div class="col-sm-12 col-lg-3 form-group">
                  <div class="tw:text-lg tw:pt-1 tw:pr-12">{{ quartier ? quartier.nom : 'Pas de quartier associé' }}</div>

                  <app-browser-online-indicator/>
                </div>
              }
            }
          </div>

          <!-- Complément d'adresse -->
          <div class="form-group">
            <label for="bien-adresse-complement">Complément d'adresse</label>

            <textarea id="bien-adresse-complement" class="form-control" name="complementAdresse"
                      [(ngModel)]="ngBienDossier.bien.option.complementAdresse1"></textarea>
          </div>

          <!-- Adresse envoyée aux confrères -->
          @if (currentEtude$ | async; as currentEtude) {
            @if ([DOSSIER_BIEN_TYPES.LOCATION, DOSSIER_BIEN_TYPES.VENTE].includes(ngBienDossier.bien._esk.type!) && currentEtude.hasNetwork() && currentEtude.sendingAddressesBiensToConfreres) {
              <div class="form-group">
                <label for="bien-adresse-reseau">Adresse envoyée aux confrères</label>

                <input type="text" id="bien-adresse-reseau" class="form-control" name="adresseReseau"
                       [(ngModel)]="ngBienDossier.bien.option.adresseReseau">
              </div>
            }
          }
        </div>
      }
    </div>
  </div>

  <!-- Cadastres -->
  @if (!!ngBienDossier.bien.option.commune) {
    <div class="col-xs-12 col-lg-5 edit-collections cadastres">
      <label>
        Cadastres
        <soq-popover-template [cadastreEditable]="cadastreEditable" [display]="'default'" [placement]="'left'"
                              [template]="'src/app/legacy/templates/biens/components/item/edit/localisation/cadastre.help.html'"
                              [trigger]="'outsideClick'"/>
      </label>

      @if (isOnLine && cadastreEditable) {
        @if (cadastresReady$ | async) {
          @if (ngBienDossier.bien.cadastres.length > 0) {
            <table class="table table-collection">
              <thead><tr><th>Section *</th><th>Parcelle *</th><th>Lot</th><th>Surface</th><th></th></tr></thead>
              <tbody>
                @for (bienCadastre of ngBienDossier.bien.cadastres; track bienCadastre; let idx = $index) {
                  <tr class="item"
                      [appCollectionSortable]="[AppBienEditLocalisationCadastresName, ngBienDossier.bien.cadastres, bienCadastre.uuid]">
                    <td class="col-xs-2" [appCollectionItemError]="[bienCadastre, section]">
                      <input type="text" pattern="^[A-Za-z0-9]{1,2}$" [name]="'section' + bienCadastre.uuid" #section="ngModel"
                             class="form-control" [(ngModel)]="ngBienDossier.bien.cadastres[idx].section"
                             (blur)="saveCadastre(bienCadastre)" style="text-transform: uppercase;">
                    </td>

                    <td class="col-xs-2" [appCollectionItemError]="[bienCadastre, parcelle]">
                      <input type="text" pattern="(?=.*[1-9])\d*" [name]="'parcelle' + bienCadastre.uuid" #parcelle="ngModel"
                             class="form-control number" [(ngModel)]="ngBienDossier.bien.cadastres[idx].parcelle"
                             (blur)="saveCadastre(bienCadastre)">
                    </td>

                    <td class="col-xs-2" [appCollectionItemError]="[bienCadastre, lot]">
                      <input type="text" pattern="[0-9]+" [name]="'lot' + bienCadastre.uuid" #lot="ngModel"
                             class="form-control number" [(ngModel)]="ngBienDossier.bien.cadastres[idx].lot"
                             (blur)="saveCadastre(bienCadastre)">
                    </td>

                    <td class="col-xs-2" [appCollectionItemError]="[bienCadastre, surface]">
                      <span class="num-icon">m<sup>2</sup></span>
                      <input type="text" pattern="(?=.*[0-9])\d*(?:,\d*)?\s*" [name]="'surface' + bienCadastre.uuid"
                             #surface="ngModel" class="form-control number" (blur)="saveCadastre(bienCadastre)"
                             [(ngModel)]="ngBienDossier.bien.cadastres[idx].surface">
                    </td>

                    <td class="col-xs-3 other tw:text-center">
                      @if (!!bienCadastre.uuid) {
                        @if (bienCadastre._esk.loading) {
                          <fa-icon [icon]="'spinner'" animation="spin"/>
                        } @else {
                          <button [ngClass]="{'has-error': bienCadastre._esk.errors?.commune}" type="button"
                                  class="btn btn-o btn-null" (click)="editCadastre(bienCadastre)">
                            <fa-icon [icon]="'edit'"/>

                            @if (bienCadastre._esk.errors?.commune) {
                              <span class="error"><fa-icon [icon]="'exclamation-triangle'"/></span>
                            }
                          </button>

                          <button type="button" title="Supprimer le cadastre" class="btn btn-o text-large"
                                  (click)="removeCadastre(bienCadastre)"><fa-icon [icon]="'trash-alt'"/></button>

                          @if (!!bienCadastre._esk.alreadyExists) {
                            <soq-popover-text [text]="'Ce cadastre est déjà saisi pour ce bien. Modifiez la référence cadastrale avant de continuer.'"
                                              [display]="'warning'" [placement]="'left'" [trigger]="'outsideClick'"/>
                          }

                          @if (!!bienCadastre._esk.doubleMandat) {
                            <soq-popover-template [display]="'warning'" [placement]="'left'" [trigger]="'outsideClick'"
                                                  [template]="'src/app/legacy/templates/biens/components/item/edit/localisation/cadastre.double.html'"
                                                  title="{{bienCadastre._esk.doubleMandat.title}}"
                                                  class="tw:inline-block tw:w-8"/>
                          }
                        }
                      }
                    </td>
                  </tr>
                }
              </tbody>
            </table>
          }
        }

        @if (ngBienDossier.bien.cadastres.length <= 0) {
          <div class="tw:py-4 tw:text-center">Aucune référence cadastrale saisie</div>
        }

        <div class="tw:flex tw:justify-center tw:py-4">
          @if (ngBienDossier.uuid || ngBienDossier.bien.cadastres.length === 0) {
            <button type="button" class="tw:btn-secondary-info" (click)="addNewCadastre()">
              <fa-icon [icon]="'plus'"/><span>Ajouter une référence cadastrale</span>
            </button>
          } @else {
            Enregistrer votre bien une première fois afin de pouvoir saisir plus de références cadastrales.
          }
        </div>
      } @else {
        <table class="table table-responsive">
          <tbody>
            @for (bienCadastre of ngBienDossier.bien.cadastres | appCollectionForFilter: filterBienCadastre; track bienCadastre) {
              <tr
                class="row">
                <td>
                  Parc. {{bienCadastre.parcelle}} - Feuille {{bienCadastre.prefixe}} {{bienCadastre.section}}
                  {{bienCadastre.lot || ""}}<br>
                  @if (ngBienDossier.bien.option.commune.id !== bienCadastre.commune.id) {
                    <span class="text-muted">{{bienCadastre.commune.fullname}}</span>
                  }
                  @if (!!bienCadastre._esk.doubleMandat) {
                    <span class="text-warning padding-left-20" title="Double mandat">
                      <fa-icon [icon]="'exclamation-triangle'"/>
                    </span>
                  }
                </td>

                <td>
                  @if (!!bienCadastre.surface) {
                    <span>{{bienCadastre.surface | textCurrency:"m²"}}</span>
                  }
                </td>
              </tr>
            }
          </tbody>
        </table>

        <app-browser-online-indicator/>
      }
    </div>
  }
</div>
