import {Component, Input, output} from '@angular/core';
import {IInotRequestErrorOptions} from '@features/inot/inot.interfaces';
import {HttpErrorResponse} from '@angular/common/http';
import {AppNoteoIconTextInotComponent} from '@shared/noteo/icon-text/inot/noteo-icon-text.inot.component';

@Component({
    imports: [AppNoteoIconTextInotComponent],
    selector: 'app-inot-request-error',
    templateUrl: 'inot.request-error.component.html',
})
export class AppInotRequestErrorComponent {
    static readonly initInotRequestErrorOptions: IInotRequestErrorOptions = {type: 'contacts'};
    readonly action = output<void>();
    private _httpErrorResponse!: HttpErrorResponse;
    private _options: IInotRequestErrorOptions = {...AppInotRequestErrorComponent.initInotRequestErrorOptions};

    get httpErrorResponse(): HttpErrorResponse {
        return this._httpErrorResponse;
    }

    @Input()
    set httpErrorResponse(value: HttpErrorResponse) {
        this._httpErrorResponse = value;
    }

    get options(): IInotRequestErrorOptions {
        return this._options;
    }

    @Input()
    set options(value: IInotRequestErrorOptions) {
        this._options = {...AppInotRequestErrorComponent.initInotRequestErrorOptions, ...value};
    }
}
