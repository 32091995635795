@if (location$ | async; as location) {
  <div class="tw:flex tw:h-full tw:flex-col tw:divide-y tw:divide-gray-200 tw:bg-white">
    <div class="tw:bg-gray-50 tw:px-4 tw:py-6 tw:sm:px-6">
      <!-- ! pour contrer le margin:0 sur le h2 -->
      <h2 class="tw:!mr-8 tw:text-base tw:font-semibold tw:text-gray-900 tw:truncate">{{ location.bien.titre }}</h2>
    </div>

    <div class="tw:flex tw:min-h-0 tw:flex-1 tw:flex-col tw:overflow-y-auto">
      <div class="tw:relative">
        <app-bien-photo [dossierBien]="location" [options]="{linkPhotoAttribute: 'linkPremierePhoto16by9'}"
                        class="tw:block tw:aspect-photo"/>

        <span class="tw:absolute tw:top-0 tw:right-0 tw:font-semibold tw:bg-white tw:rounded-lg tw:px-2 tw:py-1 tw:m-2">
          {{ location.reference }}
        </span>
      </div>

      <div class="tw:p-8 tw:divide-y tw:divide-gray-200 tw:flex tw:flex-col tw:gap-6">
        <div [innerHTML]="location.bien.descriptif | textNl2br"></div>

        <div class="tw:pt-6"><app-location-pricing [location]="location" class="tw:leading-relaxed"/></div>

        @if (location.bien.nombrePieces || location.bien.nombreChambres || location.meuble) {
          <div class="tw:flex tw:pt-6 tw:space-x-4 tw:sm:space-x-8">
            @if (location.bien.nombrePieces) {
              <span [ngPlural]="location.bien.nombrePieces">
                {{ location.bien.nombrePieces }}
                <ng-template ngPluralCase="=1">Pièce</ng-template>
                <ng-template ngPluralCase="other">Pièces</ng-template>
              </span>
            }

            @if (location.bien.nombreChambres) {
              <span [ngPlural]="location.bien.nombreChambres">
                {{ location.bien.nombreChambres }}
                <ng-template ngPluralCase="=1">Chambre</ng-template>
                <ng-template ngPluralCase="other">Chambres</ng-template>
              </span>
            }

            @if (location.meuble) {
              <span>Meublé</span>
            }
          </div>
        }
        <div class="tw:flex tw:flex-col tw:gap-4 tw:pt-6">
          @if (location.bien.superficie) {
            <div class="tw:sm:grid tw:sm:grid-cols-3 tw:sm:gap-4">
              <div>{{ superficieLabel }}</div>

              <div class="tw:sm:col-span-2 tw:mt-1 tw:sm:mt-0 tw:text-base tw:text-gray-700">
                {{ location.bien.superficie | textCurrency:'m²' }}
              </div>
            </div>
          }

          @if (location.bien.performanceEnergetique) {
            @if (location.bien.performanceEnergetique.consommationClasse) {
              <div class="tw:sm:grid tw:sm:grid-cols-3 tw:sm:gap-4">
                <div>Consommation énergétique</div>

                <div class="tw:sm:col-span-2 tw:mt-1 tw:sm:mt-0 tw:text-base tw:text-gray-700">
                  <app-diagnostic-letter-consommation [bien]="location.bien"
                                                      class="tw:block tw:text-2xl tw:font-bold tw:h-8 tw:w-8 tw:rounded-lg tw:overflow-hidden"/>
                </div>
              </div>
            }

            @if (location.bien.performanceEnergetique.emissionsClasse) {
              <div class="tw:sm:grid tw:sm:grid-cols-3 tw:sm:gap-4">
                <div>Émissions de gaz à effet de serre</div>

                <div class="tw:sm:col-span-2 tw:mt-1 tw:sm:mt-0 tw:text-base tw:text-gray-700">
                  <app-diagnostic-letter-emissions [bien]="location.bien"
                                                   class="tw:block tw:text-2xl tw:font-bold tw:h-8 tw:w-8 tw:rounded-lg tw:overflow-hidden"/>
                </div>
              </div>
            }
          }
        </div>

        <!-- @todo Utilité de ces class ? -->
        <div class="tw:flex tw:flex-col tw:gap-4 tw:pt-6">
          <div class="tw:sm:grid tw:sm:grid-cols-3 tw:sm:gap-4">
            <div>Responsable</div>

            <div class="tw:sm:col-span-2 tw:mt-1 tw:sm:mt-0 tw:text-base tw:text-gray-700">
              <app-user-cartouche [link]="location.bien.linkResponsableDossier"
                                  class="tw:block tw:h-6 tw:max-w-full tw:w-fit"/>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="tw:flex tw:shrink-0 tw:justify-end tw:px-4 tw:py-4">
      <button type="button" class="tw:btn-primary-info" (click)="redirectToDetails(location)">
        Voir le bien détaillé
      </button>
    </div>
  </div>
} @else {
  <div class="tw:px-4 tw:py-6 tw:sm:px-6">
    <div class="tw:mb-10 tw:pr-12"><app-loader [classHeight]="'tw:h-6'"/></div>

    <app-loader [classHeight]="'tw:h-40'" class="tw:block"/>
  </div>
}
