<!-- @todo Voir possibilité d'utiliser AppNoteoStatutComponent ? -->
<!-- Obliger de spécifier "print!" pour surcharger bootstrap et son "_print.scss" -->
<div class="tw:group tw:relative tw:px-3 tw:py-1 tw:rounded-full tw:first-letter:uppercase" [ngClass]="{
  'tw:bg-blue-100 tw:text-blue-800 tw:print:!bg-blue-100 tw:print:!text-blue-800': emailRecipient.isSent(),
  'tw:bg-green-100 tw:text-green-800 tw:print:!bg-green-100 tw:print:!text-green-800': emailRecipient.isDelivered(),
  'tw:bg-orange-100 tw:text-orange-800 tw:print:!bg-orange-100 tw:print:!text-orange-800': emailRecipient.isSoftBounced(),
  'tw:bg-red-600 tw:text-red-100 tw:print:!bg-red-600 tw:print:!text-red-100': emailRecipient.isHardBounced(),
  'tw:bg-stone-600 tw:text-stone-100 tw:print:!bg-stone-600 tw:print:!text-stone-100': emailRecipient.isEditable(),
  'tw:font-medium': !options.useShortLabel, 'tw:font-bold': options.useShortLabel}">
  {{ options.useShortLabel ? emailRecipient.shortLabelStatut : emailRecipient.labelStatut }}

  <div class="tw:absolute tw:left-0 tw:top-[100%] tw:print:!hidden tw:z-10">
    <div class="tw:hidden tw:group-hover:block tw:w-80 tw:rounded-md tw:border tw:shadow-lg tw:p-4 tw:text-left tw:font-normal"
      [ngClass]="{'tw:bg-blue-100 tw:border-blue-300': emailRecipient.isSent(),
         'tw:bg-green-100 tw:border-green-300': emailRecipient.isDelivered(),
         'tw:bg-orange-100 tw:border-orange-300': emailRecipient.isSoftBounced(),
         'tw:bg-red-600 tw:border-red-900': emailRecipient.isHardBounced(),
         'tw:bg-stone-50 tw:border-stone-200': emailRecipient.isEditable()}">
      @if (emailRecipient.isEditable()) {
        <h4 class="tw:pb-2">Brouillon</h4>
        <h5 class="tw:font-bold">L'email n'a pas été envoyé.</h5>
      }

      @if (emailRecipient.isSent()) {
        <h4 class="tw:pb-2">Envoyé</h4>
        <h5 class="tw:pb-1 tw:font-bold">L'email a été envoyé.</h5>
        {{ emailRecipient.name }} va recevoir l'email dans quelques instants.
      }

      @if (emailRecipient.isDelivered()) {
        <h4 class="tw:pb-2">Délivré</h4><h5 class="tw:font-bold">L'email a été reçu par {{ emailRecipient.name }}.</h5>
      }

      @if (emailRecipient.isSoftBounced()) {
        <h4 class="tw:pb-2">Rejeté</h4>
        <h5 class="tw:pb-1 tw:font-bold">L'email a été temporairement rejeté pour {{ emailRecipient.name }}.</h5>
        Boîte email pleine ou inaccessible par exemple.
      }

      @if (emailRecipient.isHardBounced()) {
        <h4 class="tw:pb-2">Rejeté</h4>
        <h5 class="tw:pb-1 tw:font-bold">L'email a été rejeté pour {{ emailRecipient.name }}.</h5>
        {{ emailRecipient.emailAddress }} inexistante par exemple.
      }
    </div>
  </div>
</div>
