export default `
<div ng-switch="$ctrl.loading">
  <p ng-switch-when="true" class="tw:text-center text-extra-large">
    <fa-icon [icon]="'spinner'" animation="spin"></fa-icon> {{'global.en_cours.LOAD' | translate}}
  </p>

  <div ng-switch-default>
    <div class="negociees-legend">
      <div class="legend-bars" ng-class="!!$ctrl.network ? '' : 'without-network'">
        <div class="legend-bar-year"><div>{{$ctrl.years[0]}}</div></div>

        <div class="legend-bar-previous"><div class="bar-duo"></div><div class="bar-solo"></div></div>

        <div class="legend-bar-text"><div class="bar-duo">Duo</div><div class="bar-solo">Solo</div></div>

        <div class="legend-bar-current"><div class="bar-duo"></div><div class="bar-solo"></div></div>

        <div class="legend-bar-year"><div>{{$ctrl.years[1]}}</div></div>
      </div>

      <div ng-if="!!$ctrl.network" class="legend-line">
        <div class="line-circle"></div>

        <div>{{$ctrl.network.name}}</div>

        <a uib-popover="Moyenne du cumul des ventes de chaque étude pour {{$ctrl.years[1]}} sur {{$ctrl.network.name}}"
           popover-trigger="'outsideClick'" popover-placement="bottom-right" class="btn btn-o btn-null hidden-print">
          <span class="badge badge-info">?</span>
        </a>
      </div>
    </div>

    <div class="negociees-canvas"><canvas soq-chart="$ctrl.barVentesNegociees"></canvas></div>

    <p class="text-right text-muted text-small">
      Mise à jour des données :
      <app-ng2-date [date]="$ctrl.barVentesNegociees.date" format="shortDate"></app-ng2-date> à
      <app-ng2-date [date]="$ctrl.barVentesNegociees.date" format="shortTime"></app-ng2-date>
    </p>
  </div>
</div>
`;
