<canvas #signatureCanvas class="tw:h-full tw:w-full" [ngClass]="hasSignature ? '' : 'tw:bg-gray-100'"></canvas>

@if (!hasSignature) {
  <div class="tw:absolute tw:inset-1/2 tw:flex tw:justify-center tw:items-center tw:cursor-default tw:pointer-events-none">
    <div class="tw:text-xl tw:text-gray-400">Signer dans ce cadre</div>
  </div>
}

@if (canErase) {
  <div class="tw:absolute tw:bottom-0 tw:left-0 tw:p-2">
    <button type="button" (click)="clear()" class="tw:h-8 tw:w-8 tw:text-blue-500 tw:hover:text-blue-400">
      <fa-icon [icon]="'eraser'" size="2x"/>
    </button>
  </div>
}
