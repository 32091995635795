import {inject, Injectable} from '@angular/core';
import {CommuneVoiesApiService} from '@models/communes/commune/voies/commune-voies.api.service';
import {ICCommuneVoiesApi} from '@models/communes/commune/voies/collection/commune-voies.collection.interfaces';
import CCommuneVoies from '@models/communes/commune/voies/collection/commune-voies.collection.model';
import Links from '@models/links/links.model';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {IQueryParameters} from '@shared/texts/texts.interfaces';
import {CommuneVoiesFactory} from '@models/communes/commune/voies/commune-voies.factory';

@Injectable({providedIn: 'root'})
export class CCommuneVoiesFactory {
    private _communeVoiesApiService = inject(CommuneVoiesApiService);
    private _communeVoiesFactory = inject(CommuneVoiesFactory);

    create(cCommuneVoiesApi: ICCommuneVoiesApi): CCommuneVoies {
        const cCommuneVoies = new CCommuneVoies();

        cCommuneVoies.links = new Links(cCommuneVoiesApi._links);
        cCommuneVoies.page = cCommuneVoiesApi.page;
        cCommuneVoies.pages = cCommuneVoiesApi.pages;
        cCommuneVoies.perPage = cCommuneVoiesApi.limit;
        cCommuneVoies.total = cCommuneVoiesApi.total;
        if (cCommuneVoiesApi._embedded) {
            cCommuneVoies.results = this._communeVoiesFactory.create(cCommuneVoiesApi._embedded.items);
        }

        return cCommuneVoies;
    }

    get$(communeUuid: string, queryParameters?: IQueryParameters): Observable<CCommuneVoies> {
        return this._communeVoiesApiService.getCollection$(communeUuid, queryParameters).pipe(map(cCommuneVoiesApi => this.create(cCommuneVoiesApi)));
    }
}
