<div class="tw:py-1">
  @if (options.enabledActions?.save) {
    <button type="button" class="tw:dropdown-link" (click)="save()">
      <fa-icon [icon]="'check'"/><span>Enregistrer</span>
    </button>
  }

  @if (options.enabledActions?.send) {
    <button type="button" class="tw:dropdown-link" (click)="send()">
      <fa-icon [icon]="'envelope'"/><span>Envoyer</span>
    </button>
  }

  @if (options.enabledActions?.print) {
    <button type="button" class="tw:dropdown-link" (click)="print()">
      <fa-icon icon="print"/><span>Imprimer</span>
    </button>
  }

  @if (options.enabledActions?.generate) {
    <button type="button" class="tw:dropdown-link" (click)="generate()">
      <fa-icon [icon]="'rotate'"/><span>Générer</span>
    </button>
  }
</div>
