export default `
<p class="tw:text-center text-bold">Traitement de la photo de profil.</p>

<p class="text-justify">
  <span class="text-bold"><fa-icon [icon]="'exclamation-triangle'"></fa-icon> Format minimum de 300px X 300px</span>
</p>

<p class="text-justify">
  <br>Une photo carré va être créée pour un affichage optimal dans
  <app-noteo-icon-text-noty></app-noteo-icon-text-noty>.
</p>
`;
