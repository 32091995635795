import {Component, inject, ViewChild} from '@angular/core';
import {ModalService} from '@shared/modal/modal.service';
import {IDynamicComponent} from '@shared/shared.interfaces';
import {IIAButtonOptions, IIAModalData} from '@shared/ia/ia.interfaces';
import {FormsModule, NgForm} from '@angular/forms';
import {Observable, Subject} from 'rxjs';
import {take, tap} from 'rxjs/operators';
import {FaIconComponent} from '@fortawesome/angular-fontawesome';
import {AsyncPipe} from '@angular/common';
import {AppLoaderComponent} from '@shared/load/loader/loader.component';
import {AppIAButtonComponent} from '@shared/ia/button/ia-button.component';
import {AppFormErrorDirective} from '@shared/form/error/form-error.directive';
import {IAService} from '@shared/ia/ia.service';

@Component({
    imports: [AppFormErrorDirective, AppIAButtonComponent, AppLoaderComponent, AsyncPipe, FaIconComponent, FormsModule],
    selector: 'ia-modale-text',
    templateUrl: 'ia-modale.text.component.html',
})
export class IAModaleTextComponent implements IDynamicComponent {
    @ViewChild('iaTextForm') iaTextForm!: NgForm;
    private _modalService = inject(ModalService);
    private _iaButtonOptions: IIAButtonOptions = {callback$: () => this.generateText$(), type: 'secondary'};
    private _iaModalData!: IIAModalData<string>;
    private _iaTextSource = new Subject<string>();
    private _iaText$ = this._iaTextSource.asObservable();
    private _maxLength!: number;
    private _titre = IAService.MODAL_TITLE_DEFAULT;

    set data(iaModalData: IIAModalData<string>) {
        this._iaModalData = iaModalData;
        this._maxLength = this._iaModalData.maxLength!;
        this._titre = this._iaModalData.titre || IAService.MODAL_TITLE_DEFAULT;
        this.generateText$().pipe(take(1)).subscribe();
    }

    get iaButtonOptions(): IIAButtonOptions {
        return this._iaButtonOptions;
    }

    get iaText$(): Observable<string> {
        return this._iaText$;
    }

    get maxLength(): number {
        return this._maxLength;
    }

    get titre(): string {
        return this._titre;
    }

    generateText$(): Observable<unknown> {
        this._iaTextSource.next(undefined!);

        return this._iaModalData.callback$().pipe(tap(iaText => this._iaTextSource.next(iaText)));
    }

    save(): void {
        if (this.iaTextForm.valid) {
            this._modalService.setResponse(this.iaTextForm.controls.generatedText.value);
        }
    }
}
