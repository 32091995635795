<div class="tw:flex tw:flex-col tw:gap-2">
  <h4 class="tw:px-3 tw:lg:px-4">Compromis en cours</h4>

  @if (venteCompromis$ | async; as venteCompromis) {
    <div class="tw:bg-red-100 tw:rounded-xl tw:flex tw:flex-col tw:gap-4 tw:p-3 tw:lg:p-4">
      <ul class="tw:grid tw:gap-2 tw:text-sm tw:text-gray-700 tw:font-medium">
        <li>
          <span class="tw:text-gray-500 tw:text-xs tw:font-normal">Vente</span>
          {{ venteCompromis.negocie ? '' : 'non' }} négociée par l'étude
        </li>

        @if (venteCompromis.date || venteCompromis.dateFin) {
          <li>
            <span class="tw:text-gray-500 tw:text-xs tw:font-normal">Compromis</span>

            @if (!venteCompromis.negocie && venteCompromis.raisonNonNegocie) {
              {{ venteCompromis.raisonNonNegocie.label | lowercase }}
            }

            <span class="tw:text-gray-500 tw:text-xs tw:font-normal"> du</span> {{ venteCompromis.date | date }}
            <span class="tw:text-gray-500 tw:text-xs tw:font-normal"> au</span> {{ venteCompromis.dateFin | date }}
          </li>
        }

        @if (venteCompromis.negocie) {
          @if (venteCompromis.isNegocieReseau()) {
            <li>
              <span class="tw:text-gray-500 tw:text-xs tw:font-normal">À</span>

              @if (venteCompromis.acquereur) {
                <span class="tw:text-gray-500 tw:text-xs tw:font-normal"> l'acquéreur</span>
                {{ venteCompromis.acquereur }}
              } @else {
                <span class="tw:text-gray-500 tw:text-xs tw:font-normal"> un acquéreur</span>
              }

              @if (etude$ | appLoadObs | async; as etude) {
                @if (etude.value) {
                  <span class="tw:text-gray-500 tw:text-xs tw:font-normal"> apporté par</span>
                  {{ etude.value.raisonSociale }}
                } @else {
                  <span class="tw:text-gray-500 tw:text-xs tw:font-normal"> du réseau</span>
                }
              } @else {
                <app-loader [classHeight]="'tw:h-20'" class="tw:block"/>
              }
            </li>
          } @else {
            <li>
              <span class="tw:text-gray-500 tw:text-xs tw:font-normal">À</span>

              @if (venteCompromis.acquereur) {
                <span class="tw:text-gray-500 tw:text-xs tw:font-normal"> l'acquéreur</span>
                {{ venteCompromis.acquereur }}
              } @else {
                @if (demandeur$ | appLoadObs | async; as demandeur) {
                  @if (demandeur.value) {
                    @if (demandeur.value.contactsGroup.hasNom()) {
                      {{ demandeur.value.contactsGroup.nom }}
                      <span class="tw:text-gray-500 tw:font-normal tw:whitespace-nowrap">
                        ({{ demandeur.value.reference }})
                      </span>
                    } @else {
                      <span class="tw:text-gray-500 tw:text-xs tw:font-normal"> l'acquéreur</span>
                      {{ demandeur.value.reference }}
                    }
                  } @else {
                    <span class="tw:text-gray-500 tw:text-xs tw:font-normal"> un acquéreur </span>
                  }
                } @else {
                  <app-loader [classHeight]="'tw:h-20'" class="tw:block"/>
                }

                <span class="tw:text-gray-500 tw:text-xs tw:font-normal">issu de votre propre portefeuille</span>
              }
            </li>
          }
        }

        @if (venteCompromis.prixCession) {
          <li>
            <span class="tw:text-gray-500 tw:text-xs tw:font-normal">Au prix de</span>
            {{ venteCompromis.prixCession | textCurrency }}
          </li>
        }

        @if (venteCompromis.negocie) {
          @if (venteCompromis.isNegocieReseau() && venteCompromis.honorairesNegoPercus > 0) {
            <li>
              <span class="tw:text-gray-500 tw:text-xs tw:font-normal">Avec des honoraires de négociation à</span>
              {{ venteCompromis.honorairesNegoPercus | textCurrency }}
              <span class="tw:text-gray-500 tw:text-xs tw:font-normal">revenant à l'étude</span>

              @if (venteCompromis.honorairesNego > 0) {
                <span class="tw:text-gray-500 tw:text-xs tw:font-normal"> sur un total de</span>
                {{ venteCompromis.honorairesNego | textCurrency }}
              }
            </li>
          } @else {
            @if (venteCompromis.honorairesNego > 0) {
              <li>
                <span class="tw:text-gray-500 tw:text-xs tw:font-normal">Avec des honoraires de négociation à</span>
                {{ venteCompromis.honorairesNego | textCurrency }}
              </li>
            }
          }
        }

        @if (venteCompromis.dateVente) {
          <li>
            <span class="tw:text-gray-500 tw:text-xs tw:font-normal">Date de vente le</span>
            {{ venteCompromis.dateVente | date }}
          </li>
        }
      </ul>

      @if (venteCompromis.comments) {
        <div class="tw:text-sm tw:text-gray-700">{{ venteCompromis.comments }}</div>
      }
    </div>
  } @else {
    <app-loader [classHeight]="'tw:h-20'" class="tw:block"/>
  }
</div>
