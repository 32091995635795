export default `
<ng-switch on="$ctrl.loading">
  <p ng-switch-when="true" class="tw:text-center text-extra-large">
    <fa-icon [icon]="'spinner'" animation="spin"></fa-icon> Chargement de la météo ...
  </p>

  <ng-switch ng-switch-default on="!!$ctrl.meteo">
    <div ng-switch-when="true" class="row">
      <div class="col-xs-3 col-lg-2">
        <img ng-src="{{'assets/images/icons/meteo/'+ $ctrl.meteo.icon +'.svg'}}" ng-if="!!$ctrl.meteo.icon"
             class="img-responsive" loading="lazy">
      </div>

      <div class="col-xs-9 col-lg-10">
        <div class="col-md-6">
          <h4>{{$ctrl.commune.fullname}} : <span class="text-bold">{{$ctrl.meteo.temperature | currency:'°C':0}}</span>
          </h4>

          <p class="text-bold text-capitalize">{{$ctrl.meteo.description}}</p>
        </div>

        <div class="col-md-6">
          <p>Nuages : {{$ctrl.meteo.cloudCover | currency:'%':0}}</p>

          <p>Vent : {{$ctrl.meteo.windSpeed | currency:'km/h':0}}</p>
        </div>
      </div>
    </div>

    <div ng-switch-default>
      Le service de météo est indisponible, consulter le site du fournisseur :

      <a target="_blank" href="https://openweathermap.org/">OpenWeatherMap</a>
    </div>
  </ng-switch>
</ng-switch>
`;
