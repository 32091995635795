<div class="tw:flex tw:flex-col tw:gap-2">
  <h4 class="tw:px-3 tw:lg:px-4">Agencement intérieur</h4>

  <div class="tw:bg-gray-100 tw:rounded-xl tw:flex tw:flex-col tw:gap-4 tw:p-3 tw:lg:p-4">
    <ul class="tw:grid tw:grid-cols-1 tw:sm:grid-cols-2 tw:xl:grid-cols-3 tw:gap-2 tw:text-sm tw:text-gray-700 tw:font-medium">
      @if (isCaveVisible) {
        <li>
          Cave
          @if (dossierBien.bien.surfaceCave > 0) {
            <span class="tw:text-gray-500 tw:text-xs tw:font-normal">de</span>
            {{dossierBien.bien.surfaceCave | textCurrency:'m²'}}
          }
        </li>
      }

      @if (isSousSolVisible) {
        <li>Sous-sol</li>
      }

      @if (isGrenierVisible) {
        <li>Grenier</li>
      }

      @if (isComblesVisible) {
        <li>Combles</li>
      }

      @if (isCuisineEquipeeVisible) {
        <li>Cuisine équipée</li>
      }

      @if (isAscenseurVisible) {
        <li>Ascenseur</li>
      }

      @if (isDernierEtageVisible) {
        <li>Dernier étage</li>
      }

      @if (isNombreChambresVisible) {
        <li>
          {{dossierBien.bien.nombreChambres}} Chambre{{dossierBien.bien.nombreChambres > 1 ? 's' : ''}}
        </li>
      }

      @if (isNombreLogementsVisible) {
        <li>
          {{dossierBien.bien.nombreLogements}} Logement{{dossierBien.bien.nombreLogements > 1 ? 's' : ''}}
        </li>
      }

      @if (isNombrePiecesVisible) {
        <li>
          {{dossierBien.bien.nombrePieces}} Pièce{{dossierBien.bien.nombrePieces > 1 ? 's' : ''}}
        </li>
      }

      @if (isSurfaceSejourVisible) {
        <li>
          Séjour <span class="tw:text-gray-500 tw:text-xs tw:font-normal">de</span>
          {{dossierBien.bien.surfaceSejour | textCurrency:'m²'}}
        </li>
      }

      @if (isHauteurSousPlafondVisible) {
        <li>
          Hauteur sous plafond <span class="tw:text-gray-500 tw:text-xs tw:font-normal">de</span>
          {{dossierBien.bien.surfaceSejour | textCurrency:'m'}}
        </li>
      }

      @if (isNombreBainsVisible) {
        <li>
          {{dossierBien.bien.nombreBains}} Baignoire{{dossierBien.bien.nombreBains > 1 ? 's' : ''}}
        </li>
      }

      @if (isNombreSallesEauVisible) {
        <li>
          {{dossierBien.bien.nombreSallesEau}} Salle{{dossierBien.bien.nombreSallesEau > 1 ? 's' : ''}} d'eau
        </li>
      }

      @if (isNombreDouchesVisible) {
        <li>
          {{dossierBien.bien.nombreDouches}} Douche{{dossierBien.bien.nombreDouches > 1 ? 's' : ''}}
        </li>
      }

      @if (isNombreSallesBainsVisible) {
        <li>
          {{dossierBien.bien.nombreBains}} Salle{{dossierBien.bien.nombreBains > 1 ? 's' : ''}} de bains
        </li>
      }

      @if (isNombreWcVisible) {
        <li>{{dossierBien.bien.nombreWc}} WC</li>
      }

      @if (isWcBroyeurVisible) {
        <li>WC broyeur</li>
      }

      @if (isNombreEtagesVisible) {
        <li>
          {{dossierBien.bien.nombreEtages}} niveau{{dossierBien.bien.nombreEtages > 1 ? 'x' : ''}}
        </li>
      }

      @if (isNumeroEtageVisible && true) {
        <li>
          @if (dossierBien.bien.numeroEtage < -2) {
            {{-dossierBien.bien.numeroEtage}}<sup>ème</sup> sous-sol
          }
          @if (dossierBien.bien.numeroEtage === -2) {
            2<sup>nd</sup> sous-sol
          }
          @if (dossierBien.bien.numeroEtage === -1) {
            1<sup>er</sup> sous-sol
          }
          @if (dossierBien.bien.numeroEtage === 0) {
            Rez-de-chaussée
          }
          @if (dossierBien.bien.numeroEtage === 1) {
            1<sup>er</sup> étage
          }
          @if (dossierBien.bien.numeroEtage === 2) {
            2<sup>nd</sup> étage
          }
          @if (dossierBien.bien.numeroEtage > 2) {
            {{dossierBien.bien.numeroEtage}}<sup>ème</sup> étage
          }
        </li>
      }

      @if (isAlarmeLocationVisible) {
        <li>Alarme en location</li>
      }

      @if (isAlarmePleineProprieteVisible) {
        <li>Alarme en pleine propriété</li>
      }

      @if (isDetecteurFumeeVisible) {
        <li>Détecteur de fumée</li>
      }

      @if (isChemineeVisible) {
        <li [ngClass]="{'tw:xl:col-span-2': dossierBien.bien.chemineeOrnement && dossierBien.bien.chemineeFonctionnelle}">
          @if (dossierBien.bien.nombreCheminees > 1) {
            {{dossierBien.bien.nombreCheminees}}
          }

          Cheminée{{dossierBien.bien.nombreCheminees > 1 ? 's' : ''}}

          @if (dossierBien.bien.chemineeOrnement) {
            d'ornement
          }

          @if (dossierBien.bien.chemineeFonctionnelle) {
            fonctionnelle{{dossierBien.bien.nombreCheminees > 1 ? 's' : ''}}
          }
        </li>
      }

      @if (isConnexionsInternetVisible) {
        <li>
          Connexion{{dossierBien.bien.connexionsInternet.length > 1 ? 's' : ''}} Internet
          <span class="tw:text-gray-500 tw:text-xs tw:font-normal">par</span>

          @for (connexionInternet of dossierBien.bien.connexionsInternet; track connexionInternet; let last = $last) {
            {{connexionInternet.label}}{{last ? '' : ','}}
          }
        </li>
      }
    </ul>

    @if (isCommentsAgencementInterieurVisible) {
      <div class="tw:text-sm tw:text-gray-700">{{dossierBien.bien.commentsAgencementInterieur}}</div>
    }
  </div>
</div>
