export default `
<soq-panel box="$ctrl.box">
  <tools-slot>
    <button type="button" class="tw:btn-tertiary-info tw:py-1 tw:px-2" ng-click="$ctrl.openConfig()">
      <fa-icon [icon]="'cogs'"></fa-icon>
    </button>

    <button type="button" class="tw:btn-tertiary-info tw:py-1 tw:px-2" ng-click="$ctrl.updateVentes()">
      <fa-icon [icon]="'sync-alt'"></fa-icon>
    </button>
  </tools-slot>

  <content-slot>
    <esk-ventes-list ventes="$ctrl.ventes" options="$ctrl.options"></esk-ventes-list>

    <div ng-if="$ctrl.ventes.totalItems <= 0" class="tw:text-center margin-top-15 margin-bottom-15">
      <p class="text-muted">{{'dashboard.box.ventes-mandats.NONE' | translate:'{type: "' + $ctrl.type + '"}'}}</p>
    </div>
  </content-slot>

  <footer-slot ng-if="$ctrl.ventes.totalItems > 0">
    <a ng-click="$ctrl.goToVentes()">
      <fa-icon [icon]="'arrow-circle-right'"></fa-icon>

      {{'dashboard.box.ventes-mandats.LINK_TO_LIST' | translate:'{type: "' + $ctrl.type + '"}'}}
    </a>
  </footer-slot>
</soq-panel>
`;
