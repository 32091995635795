<div class="tw:py-1">
  @if (options.enabledActions.read) {
    <button type="button" class="tw:dropdown-link" (click)="read()">
      <fa-icon [icon]="['fal', 'eye']"/><span>Voir</span>
    </button>
  }

  @if (options.enabledActions.rename) {
    <button type="button" class="tw:dropdown-link" (click)="rename()">
      <fa-icon [icon]="['fal', 'edit']"/><span>Renommer</span>
    </button>
  }

  @if (options.enabledActions.share) {
    <button type="button" class="tw:dropdown-link" (click)="share()">
      <fa-icon [icon]="['fal', 'share-alt']"/><span>Partager</span>
    </button>
  }

  @if (options.enabledActions.delete) {
    <button type="button" class="tw:dropdown-link" (click)="delete()">
      <fa-icon [icon]="['fal', 'trash-alt']"/><span>Supprimer</span>
    </button>
  }
</div>
