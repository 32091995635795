<div class="tw:flex tw:flex-col tw:gap-2">
  <h4 class="tw:px-3 tw:lg:px-4">Logement{{ bienLogements.length > 1 ? 's' : '' }}</h4>

  <div class="tw:w-full tw:md:grid tw:md:grid-cols-2 tw:xl:grid-cols-3 tw:md:gap-4 tw:flex tw:flex-col tw:gap-2 tw:sm:gap-4 tw:md:gap-0 tw:bg-gray-100 tw:rounded-xl tw:p-2 tw:sm:p-4">
    @for (bienLogement of bienLogements; track bienLogement) {
      <div class="tw:flex tw:flex-col tw:gap-1">
        <div>
          <span class="tw:text-sm tw:text-gray-700 tw:font-medium">
            {{ bienLogement.otherLabel || (bienLogement.logement ? bienLogement.logement.label : 'Type de pièce indéterminé') }}
          </span>

          @if (bienLogement.surface > 0) {
            <span>
              de
              <span class="tw:text-gray-700 tw:font-medium">
                {{ bienLogement.surface | textCurrency:'m²' }}
              </span>
            </span>
          }

          @if (bienLogement.etage) {
            <span>au <span class="tw:text-gray-700 tw:font-medium">{{ bienLogement.etage.label }}</span></span>
          }
        </div>

        @if (bienLogement.comments) {
          <div class="tw:pl-2 tw:sm:pl-4">{{ bienLogement.comments }}</div>
        }
      </div>
    }
  </div>
</div>
