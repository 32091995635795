export default `
<soq-panel box="$ctrl.box">
  <tools-slot>
    <a ng-click="$ctrl.ng2RouterStateForNgService.navigateByUrl('/app/' + $ctrl.typeDossier + 's/last/criteres')"
       class="btn btn-transparent"><fa-icon [icon]="'cogs'"></fa-icon></a>

    <button type="button" class="tw:btn-tertiary-info tw:py-1 tw:px-2" ng-click="$ctrl.box.refresh()">
      <fa-icon [icon]="'sync-alt'"></fa-icon>
    </button>
  </tools-slot>

  <content-slot>
    <esk-locations-list ng-if="$ctrl.typeDossier === $ctrl.DossierBienTypesConst.LOCATION" locations="$ctrl.dossierBiens" options="$ctrl.options"
                        on-click="$ctrl.onClickDossierBien"></esk-locations-list>

    <esk-ventes-list ng-if="$ctrl.typeDossier === $ctrl.DossierBienTypesConst.VENTE" ventes="$ctrl.dossierBiens" options="$ctrl.options"
                     on-click="$ctrl.onClickDossierBien"></esk-ventes-list>

    <div ng-if="$ctrl.dossierBiens.totalItems <= 0" class="tw:text-center margin-top-15 margin-bottom-15">
      <p class="text-muted">Aucun bien en {{$ctrl.typeDossier}} à afficher.</p>

      <button ng-click="$ctrl.ng2RouterStateForNgService.navigateByUrl('/app/' + $ctrl.typeDossier + 's/last/criteres')"
              type="button" class="btn btn-default">Modifier vos critères</button>
    </div>
  </content-slot>

  <footer-slot>
    <a ng-click="$ctrl.goToDossierBiens()">
      <fa-icon [icon]="'arrow-circle-right'"></fa-icon> Toutes les dernières mises en {{$ctrl.typeDossier}}
    </a>
  </footer-slot>
</soq-panel>
`;
