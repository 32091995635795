<div class="tw:flex">
  <button type="button" [disabled]="loading" (click)="click()" [ngClass]="model ? 'tw:bg-blue-500' : 'tw:bg-gray-200'"
          class="tw:relative tw:inline-flex tw:shrink-0 tw:h-6 tw:w-12 tw:border-2 tw:border-transparent tw:rounded-full tw:transition-colors tw:ease-in-out tw:duration-200 tw:focus:outline-hidden tw:focus:ring-2 tw:focus:ring-offset-2 tw:focus:ring-blue-100">
    <span [ngClass]="model ? 'tw:translate-x-6' : 'tw:translate-x-0'"
          class="tw:relative tw:inline-block tw:h-5 tw:w-5 tw:rounded-full tw:bg-white tw:shadow-sm tw:transform ring-0 tw:transition tw:ease-in-out tw:duration-200">
      <span [ngClass]="model ? 'tw:opacity-0 tw:ease-out tw:duration-100' : 'tw:opacity-100 tw:ease-in tw:duration-200'"
            class="tw:absolute tw:inset-0 tw:h-full tw:w-full tw:flex tw:items-center tw:justify-center tw:transition-opacity tw:text-gray-400 tw:text-xs">
        <fa-icon [icon]="'times'"/>
      </span>

      <span [ngClass]="model ? 'tw:opacity-100 tw:ease-in tw:duration-200' : 'tw:opacity-0 tw:ease-out tw:duration-100'"
            class="tw:absolute tw:inset-0 tw:h-full tw:w-full tw:flex tw:items-center tw:justify-center tw:transition-opacity tw:text-blue-500 tw:text-xs">
        <fa-icon [icon]="'check'"/>
      </span>
    </span>
  </button>

  @if (loading) {
    <span class="tw:mx-2"><fa-icon [icon]="'spinner'" animation="spin"/></span>
  }
</div>
