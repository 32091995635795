import angularJS from '@shared/angularJS/global.ng';
import {filter, find, map as lmap, remove} from 'lodash';
import {IModule, IPromise, IQService} from 'angular';
import {
    NgCommuneManager, NgDictionariesManager, NgEstimationManager, NgQuartierManager
} from '@legacy/app/managers/managers';
import {NgDemandeurUtils} from '@legacy/app/demandeurs/demandeurs';
import {EtudeService} from '@models/etudes/etude/etude.service';
import {NgSearch} from '@legacy/app/eskimmo/eskimmo';
import {NgArea, NgCommune, NgCommuneVoie, NgQuartier} from '@legacy/app/managers/ressources';
import {NgCommunesCollection} from '@legacy/app/managers/collections';
import {UserService} from '@models/users/user/user.service';
import Etude from '@core/models/etudes/etude/etude.model';
import {ModalService} from '@shared/modal/modal.service';
import {map, switchMap, take} from 'rxjs/operators';
import {AppNoteoContactComponent} from '@shared/noteo/contact/noteo-contact.component';
import DictionaryItem from '@models/dictionaries/dictionary/items/item/dictionary-item.model';
import {IFormRangeSliderInput} from '@shared/form/form.interfaces';
import {DictionaryItemService} from '@models/dictionaries/dictionary/items/item/dictionary-item.service';
import {RouterStateForNgService} from '@shared/angularJS/down-ng2/router-state-for-ng.service';
import Dictionary from '@models/dictionaries/dictionary/dictionary.model';
import {TextsService} from '@shared/texts/texts.service';
import DateFormat from '@shared/date/date.format';
import {CommuneFactory} from '@models/communes/commune/commune.factory';
import Quartier from '@models/quartiers/quartier/quartier.model';
import {QuartierFactory} from '@models/quartiers/quartier/quartier.factory';
import Bien from '@models/bien/bien.model';
import {DossierTypesConst} from '@models/dossiers/dossiers.constants';
import {DossierBiensSourcesConst, DossierBienTypesConst} from '@models/dossiers/biens/dossier-biens.constants';
import {SearchCriteriaSourceConst} from '@models/search/criteria/search-criteria.constants';

export default function getEskSearchCriteria(module: IModule): void {
    (angular => {
        'use strict';

        /**
         * @example <esk-search-criteria></esk-search-criteria>
         * @param options : {disabledFields{object}, disableSecondaryCriteria{bool}, onChangeType{function}, routing{string}, user{object}}
         * @param options.disabledFields : {fieldsetBudget{bool}, fieldsetCession{bool}, fieldsetConstruction{bool}, fieldsetConstructionCityscan{bool}, fieldsetLocalisationCityscan{bool}, fieldsetSource{bool}, nombreChambres{bool}, searchByAdresse(bool), sourceReferences(bool), sourceCityscan(bool), sourceToutes(bool),surfaceCadastraleMin(bool), uniquementBiensNotaire{bool}}
         */
        module.component('eskSearchCriteria', {
            bindings: {search: '=', updateSearchLocalisations: '=', options: '=?'},
            controller: Controller,
            templateUrl: 'src/app/legacy/templates/eskimmo/components/search/criteria.html'
        });

        /**
         * Controller to edit search criteria
         *
         * @param DictionariesManager
         * @param CommuneManager
         * @param $q
         * @param $translate
         * @param ArrondissementTools
         * @param DemandeursUtils
         * @param Ng2EtudeService
         * @param Ng2UserService
         * @param Ng2ModalService
         * @param EstimationManager
         * @param Ng2DictionaryItemService
         * @param Ng2RouterStateForNgService
         * @param Ng2TextsService
         * @param Ng2CommuneFactory
         * @param Ng2QuartierFactory
         * @param QuartierManager
         */
        Controller.$inject = ['DictionariesManager', 'CommuneManager', '$q', '$translate', 'DemandeursUtils', 'Ng2EtudeService', 'Ng2UserService', 'Ng2ModalService', 'EstimationManager', 'Ng2DictionaryItemService', 'Ng2RouterStateForNgService', 'Ng2TextsService', 'Ng2CommuneFactory', 'Ng2QuartierFactory', 'QuartierManager'];
        function Controller(this: any,
                            dictionariesManager: NgDictionariesManager,
                            communeManager: NgCommuneManager,
                            $q: IQService,
                            $translate: angular.translate.ITranslateService,
                            demandeursUtils: NgDemandeurUtils,
                            ng2EtudeService: EtudeService,
                            ng2UserService: UserService,
                            ng2ModalService: ModalService,
                            estimationManager: NgEstimationManager,
                            ng2DictionaryItemService: DictionaryItemService,
                            ng2RouterStateForNgService: RouterStateForNgService,
                            ng2TextsService: TextsService,
                            ng2CommuneFactory: CommuneFactory,
                            ng2QuartierFactory: QuartierFactory,
                            quartierManager: NgQuartierManager) {
            const $ctrl = this;
            let currentEtude: Etude;

            $ctrl.$onInit = $onInit;
            $ctrl.changeStationnement = changeStationnement;
            $ctrl.changeTerrasseOuBalcon = changeTerrasseOuBalcon;
            $ctrl.getLabelSuperficie = getLabelSuperficie;
            $ctrl.isDisabled = isDisabled;
            $ctrl.onChangeRechercheBudgetHni = onChangeRechercheBudgetHni;
            $ctrl.onChangeType = onChangeType;
            $ctrl.onSelectEtats = onSelectEtats;
            $ctrl.onSelectMitoyennetes = onSelectMitoyennetes;
            $ctrl.onSelectOccupations = onSelectOccupations;
            $ctrl.onSelectPlainPieds = onSelectPlainPieds;
            $ctrl.onSelectChambresRange = onSelectChambresRange;
            $ctrl.onSelectLogementsRange = onSelectLogementsRange;
            $ctrl.onSelectPiecesRange = onSelectPiecesRange;
            $ctrl.onSelectTypesReferencesArchivees = onSelectTypesReferencesArchivees;
            $ctrl.sourceSelectionCityscan = sourceSelectionCityscan;
            $ctrl.CITYSCAN_CODE = Etude.enablableModules.CITYSCAN.code;
            $ctrl.CONSTRUCTION_EPOQUES = Dictionary.names.CONSTRUCTION_EPOQUES;
            $ctrl.CriteriaSourceConst = SearchCriteriaSourceConst;
            $ctrl.DossierBiensSourcesConst = DossierBiensSourcesConst;
            $ctrl.DossierTypesConst = DossierTypesConst;
            $ctrl.ETATS = Dictionary.names.ETATS;
            $ctrl.MITOYENNETES = Dictionary.names.MITOYENNETES;
            $ctrl.NATURES = Dictionary.names.NATURES;
            $ctrl.OCCUPATIONS = Dictionary.names.OCCUPATIONS;
            $ctrl.PLAIN_PIEDS = Dictionary.names.PLAIN_PIEDS;
            $ctrl.REFERENCES_ARCHIVEES_TYPES = Dictionary.names.REFERENCES_ARCHIVEES_TYPES;
            $ctrl.VUES = Dictionary.names.VUES;
            $ctrl.naturesAppartement = ng2DictionaryItemService.getByCode(Dictionary.names.NATURES, Bien.natures.APPARTEMENT);
            $ctrl.naturesMaison = ng2DictionaryItemService.getByCode(Dictionary.names.NATURES, Bien.natures.MAISON);

            /**
             * Initialization method
             */
            function $onInit() {
                ng2UserService.getCurrentCommune$().pipe(
                    map(commune => communeManager.createFromNg2(commune)),
                    switchMap(currentUserCommune => ng2UserService.last$.pipe(
                        map(currentUser => ({currentUser, currentUserCommune})),
                    )),
                    take(1),
                ).subscribe(({currentUser, currentUserCommune}) => {
                    ng2EtudeService.last$.subscribe(data => {
                        currentEtude = data;
                        $ctrl.mainNetwork = currentEtude.mainNetwork;
                        if (!$ctrl.options) {
                            $ctrl.options = {};
                        }

                        if (!$ctrl.search) {
                            $ctrl.search = {};
                        }

                        if (!$ctrl.search.archive) {
                            $ctrl.search.archive = [];
                        }

                        if (!$ctrl.search.areas) {
                            $ctrl.search.areas = [];
                        }

                        if (!$ctrl.search.communes) {
                            $ctrl.search.communes = [];
                        }

                        if (!$ctrl.search.quartiers) {
                            $ctrl.search.quartiers = [];
                        }

                        if (!$ctrl.search.type) {
                            $ctrl.search.type = DossierBienTypesConst.VENTE;
                        }

                        if (!$ctrl.search.vente) {
                            $ctrl.search.vente = {};
                        }

                        $ctrl.search.vente.budgetHni = $ctrl.search.vente.budgetHni !== false;

                        if (!$ctrl.search.location) {
                            $ctrl.search.location = {};
                        }

                        if (!$ctrl.search.option) {
                            $ctrl.search.option = {};
                        }

                        if (!$ctrl.search.option.source) {
                            $ctrl.search.option.source = $ctrl.mainNetwork ? SearchCriteriaSourceConst.ALL : SearchCriteriaSourceConst.ETUDE;
                        }

                        if (!angular.isArray($ctrl.search.natures) || $ctrl.search.natures.length === 0) {
                            let nature: DictionaryItem;

                            // @todo Utiliser DossierService.getTypeFromUrl()
                            if (ng2RouterStateForNgService.current.url.startsWith('/app/demandeurs')) {
                                nature = currentUser.settings.demandeurs.defaultNature;
                            } else if (ng2RouterStateForNgService.current.url.startsWith('/app/ventes')) {
                                nature = currentUser.settings.ventes.defaultNature;
                            } else if (ng2RouterStateForNgService.current.url.startsWith('/app/locations')) {
                                nature = currentUser.settings.locations.defaultNature;
                            } else if (ng2RouterStateForNgService.current.url.startsWith('/app/estimations')) {
                                nature = currentUser.settings.estimations.defaultNature;
                            } else {
                                nature = ng2DictionaryItemService.getByCode(Dictionary.names.NATURES, Bien.natures.MAISON);
                            }

                            $ctrl.search.natures = [dictionariesManager.createFromNg2(nature)];
                        }

                        $ctrl.tabs = [
                            {value: 'Informations générales', key: 'general'},
                            {value: 'Informations secondaires', key: 'secondary'}
                        ];
                        $ctrl.currentTab = find($ctrl.tabs, {key: $ctrl.options.routing});
                        if (!angular.isObject($ctrl.currentTab)) {
                            $ctrl.currentTab = $ctrl.tabs[0];
                        }

                        $ctrl.demandeursUtils = demandeursUtils;
                        $ctrl.typeSearch = $ctrl.search.type;
                        $ctrl.localisations = (initCommune => {
                            const localisations = {
                                collection: [],
                                onChangeMode: mode => {
                                    // @ts-ignore
                                    localisations.mode = mode;
                                    // @ts-ignore
                                    if (mode === 'adresse') localisations.searchByAdresse = initSearchByAdresse();

                                    /**
                                     * Init search by adresse
                                     *
                                     * @return {Object}
                                     */
                                    function initSearchByAdresse() {
                                        const searchByAdresse = {
                                            commune: null!,
                                            voie: $ctrl.search.option.voie,
                                            nomVoie: $ctrl.search.option.nomVoie,
                                            onSelectCommune: () => {
                                                searchByAdresse.voie = undefined!;
                                                searchByAdresse.nomVoie = undefined!;
                                            }
                                        } as { commune: NgCommune; nomVoie: string; voie: NgCommuneVoie };

                                        (localisations.collection as {
                                            commune: NgCommune;
                                            type: string
                                        }[]).forEach(localisation => {
                                            if (angular.isObject(searchByAdresse.commune)) {
                                                return;
                                            }

                                            if (['commune', 'quartier'].includes(localisation.type)) {
                                                searchByAdresse.commune = localisation.commune;
                                            }
                                        });

                                        return searchByAdresse;
                                    }
                                },
                                addQuartier: (quartier: NgQuartier) => {
                                    localisations.removeCommune(quartier.commune!);
                                    if (localisations.isQuartierSelected(quartier)) {
                                        updateFields(localisations.getQuartierSelected(quartier), quartier.commune!);
                                    } else {
                                        localisations.add({
                                            type: 'quartier',
                                            data: quartier,
                                            title: "Code(s) postal(aux) de la commune associée : " + quartier.commune!.codesPostaux.join(', '),
                                            name: quartier.name + " (" + quartier.commune!.fullname + ")",
                                            convivialName: quartier.name + " (" + quartier.commune!.fullname + ")",
                                            commune: quartier.commune,
                                        });
                                    }

                                    return localisations.getQuartierSelected(quartier);
                                },
                                addCommune: (commune: NgCommune) => {
                                    // @todo supprimer ce code après 04/2023 si jamais utilisé (erreur dans Sentry), sinon modifié et mettre à jour la version
                                    if (!commune) {
                                        throw new Error(JSON.stringify({commune}));
                                    }

                                    localisations.removeArea(commune.departement);
                                    localisations.removeCommune(commune.communeParent!);
                                    if (localisations.isCommuneSelected(commune)) {
                                        updateFields(localisations.getCommuneSelected(commune), commune);
                                    } else {
                                        localisations.add({
                                            type: 'commune',
                                            data: commune,
                                            title: "Code(s) postal(aux) : " + commune.codesPostaux.join(', '),
                                            name: commune.fullname + ' - ' + (commune.codesPostaux.length === 1 ? commune.codesPostaux[0] : "Tous codes postaux"),
                                            convivialName: commune.fullname,
                                            commune: commune
                                        });
                                    }

                                    return localisations.getCommuneSelected(commune);
                                },
                                addArea: (area: NgArea) => {
                                    if (!localisations.isAreaSelected(area)) {
                                        localisations.add({
                                            type: 'area',
                                            data: area,
                                            title: "Dept : " + area.code,
                                            name: area.fullname,
                                            convivialName: area.fullname
                                        });
                                    }

                                    return localisations.getAreaSelected(area);
                                },
                                add: localisation => {
                                    localisation.uuid = ng2TextsService.uuid();
                                    localisations.collection.unshift(localisation);
                                    updateFields(localisation, localisation.commune);

                                    return localisation;
                                },
                                getQuartiersSelected: () => filter(localisations.collection, {type: 'quartier'}),
                                getQuartierSelected: (quartier: NgQuartier) => filter(localisations.getQuartiersSelected(), {data: {code: quartier.code}})[0],
                                getCommuneSelected: (commune: NgCommune) => filter(filter(localisations.collection, {type: 'commune'}), {data: {id: commune?.id}})[0],
                                getAreaSelected: (area: NgArea) => filter(filter(localisations.collection, {type: 'area'}), {data: {code: area.code}})[0],
                                isQuartierSelected: (quartier: NgQuartier) => angular.isObject(localisations.getQuartierSelected(quartier)),
                                isCommuneSelected: (commune: NgCommune) => angular.isObject(localisations.getCommuneSelected(commune)),
                                isAreaSelected: (area: NgArea) => angular.isObject(localisations.getAreaSelected(area)),
                                isSelected: (localisation: { data: unknown; type: string }) => {
                                    switch (localisation.type) {
                                        case 'quartier' :
                                            return localisations.isQuartierSelected(localisation.data as NgQuartier);
                                        case 'commune' :
                                            return localisations.isCommuneSelected(localisation.data as NgCommune);
                                        case 'area' :
                                            return localisations.isAreaSelected(localisation.data as NgArea);
                                    }

                                    return false;
                                },
                                onClick: (localisation: { commune: NgCommune }) => {
                                    if (!angular.isObject(localisation.commune)) {
                                        return;
                                    }

                                    updateFields(localisation, localisation.commune);
                                },
                                removeQuartier: (quartier: NgQuartier) => localisations.remove(localisations.getQuartierSelected(quartier)),
                                removeCommune: (commune: NgCommune) => localisations.remove(localisations.getCommuneSelected(commune)),
                                removeArea: (area: NgArea) => localisations.remove(localisations.getAreaSelected(area)),
                                remove: (localisation: { uuid: string }) => {
                                    if (!angular.isObject(localisation)) {
                                        return;
                                    }

                                    remove(localisations.collection, {uuid: localisation.uuid});
                                    // @ts-ignore
                                    localisations.search.update();
                                    // @ts-ignore
                                    localisations.quartiersSelection.update();
                                    // @ts-ignore
                                    localisations.carteQuartiers.update();
                                },
                                removeAll: () => {
                                    localisations.collection = [];
                                    // @ts-ignore
                                    localisations.search.update();
                                    // @ts-ignore
                                    localisations.quartiersSelection.update();
                                    // @ts-ignore
                                    localisations.carteQuartiers.update();
                                }
                            } as {
                                collection: unknown[];
                                add(localisation: {
                                    data?: unknown;
                                    commune?: NgCommune;
                                    convivialName?: string;
                                    name?: string;
                                    title?: string;
                                    type?: string;
                                    uuid?: string;
                                }): void;
                                addArea(area: NgArea): void;
                                addCommune(commune: NgCommune): void;
                                addQuartier(quartier: NgQuartier): void;
                                getAreaSelected(area: NgArea): NgArea;
                                getCommuneSelected(commune: NgCommune): NgCommune;
                                getQuartierSelected(quartier: NgQuartier): NgQuartier;
                                getQuartiersSelected(): NgQuartier[];
                                isAreaSelected(area: NgArea): boolean;
                                isCommuneSelected(commune: NgCommune): boolean;
                                isQuartierSelected(quartier: NgQuartier): boolean;
                                isSelected(localisation: { data: unknown; type: string }): boolean;
                                onChangeMode(mode: string): void;
                                remove(localisation: { uuid: string }): void;
                                removeArea(area: NgArea): void;
                                removeCommune(commune: NgCommune): void;
                                removeQuartier(quartier: NgQuartier): void;
                            };
                            let initLocalisation: unknown = null;

                            // @ts-ignore
                            localisations.search = initSearch();
                            // @ts-ignore
                            localisations.quartiersSelection = initQuartiersSelection();
                            // @ts-ignore
                            localisations.carteGmap = initCarteGmap();
                            // @ts-ignore
                            localisations.carteQuartiers = initCarteQuartiers();
                            $ctrl.search.quartiers.filter(quartier => quartier?.commune).forEach((quartier: NgQuartier) => localisations.addQuartier(quartier));
                            $ctrl.search.communes.filter(commune => commune).forEach((commune: NgCommune) => localisations.addCommune(commune));
                            $ctrl.search.areas.filter(area => area).forEach((area: NgArea) => localisations.addArea(area));
                            initLocalisation = localisations.getCommuneSelected(initCommune);
                            updateFields(initLocalisation, initCommune);
                            if (!angular.isObject(initLocalisation)) {
                                // @ts-ignore
                                localisations.search.keywordsLocalisation = undefined;
                            }

                            if ($ctrl.search.option.source !== Etude.enablableModules.CITYSCAN.code &&
                                (angular.isObject($ctrl.search.option.voie) || (angular.isString($ctrl.search.option.nomVoie) && $ctrl.search.option.nomVoie !== ''))) {
                                localisations.onChangeMode('adresse');
                            } else {
                                localisations.onChangeMode('localites');
                            }

                            return localisations;

                            /**
                             * Update fields for select localisation
                             *
                             * @param localisation
                             * @param commune
                             */
                            function updateFields(localisation: unknown, commune: unknown) {
                                if (initLocalisation === null) {
                                    return;
                                }

                                // @ts-ignore
                                localisations.search.update(localisation);
                                // @ts-ignore
                                localisations.quartiersSelection.changeCommune(commune);
                                // @ts-ignore
                                localisations.carteGmap.changeCenter(commune);
                                // @ts-ignore
                                localisations.carteQuartiers.changeCommune(commune);
                            }

                            /**
                             * Init search
                             *
                             * @return {Object}
                             */
                            function initSearch() {
                                const search = {
                                    update: (localisation: unknown) => {
                                        if (angular.isObject(localisation)) {
                                            // @ts-ignore
                                            search.keywordsLocalisation = localisation;
                                        } else {
                                            // @ts-ignore
                                            if (angular.isObject(search.keywordsLocalisation) && !localisations.isSelected(search.keywordsLocalisation)) {
                                                // @ts-ignore
                                                search.keywordsLocalisation = undefined;
                                            }
                                        }
                                    },
                                    add: (searchLocalisation: { data: unknown; type: string }) => {
                                        if (!searchLocalisation) {
                                            return;
                                        }

                                        switch (searchLocalisation.type) {
                                            case 'quartier' :
                                                localisations.addQuartier(searchLocalisation.data as NgQuartier);
                                                break;
                                            case 'commune' :
                                                localisations.addCommune(searchLocalisation.data as NgCommune);
                                                break;
                                            case 'communes' :
                                                (searchLocalisation.data as NgCommune[]).forEach(commune => localisations.addCommune(commune));
                                                break;
                                            case 'area' :
                                                localisations.addArea(searchLocalisation.data as NgArea);
                                                break;
                                        }
                                    }
                                };

                                return search;
                            }

                            /**
                             * Init quartiers selection
                             *
                             * @return {Object}
                             */
                            function initQuartiersSelection() {
                                const quartiersSelection = {
                                    commune: undefined!,
                                    ngCommune: undefined!,
                                    selectedNgQuartiers: [],
                                    changeCommune: (commune: NgCommune) => {
                                        if (!angular.isObject(commune)) {
                                            return;
                                        }

                                        if (quartiersSelection.commune?.id === commune?.id) {
                                            quartiersSelection.update();
                                            return;
                                        }

                                        // @ts-ignore
                                        quartiersSelection.commune = commune;
                                        // @ts-ignore
                                        quartiersSelection.ngCommune = ng2CommuneFactory.ngCreate(commune);
                                    },
                                    selectQuartiers: (selectedNgQuartiers: Quartier[]) => {
                                        selectedNgQuartiers
                                            .filter(selectedNgQuartier => !quartiersSelection.selectedNgQuartiers
                                                .map(quartiersSelectionSelectedNgQuartier => quartiersSelectionSelectedNgQuartier.id)
                                                .includes(selectedNgQuartier.id))
                                            .map(ngQuartier => quartierManager.createFromNg2(quartiersSelection.commune.id, ngQuartier))
                                            .forEach(quartier => {
                                                quartier.commune = quartiersSelection.commune;
                                                updateLocalisations(quartier, true);
                                            });
                                        quartiersSelection.selectedNgQuartiers
                                            .filter(quartiersSelectionSelectedNgQuartier => quartiersSelectionSelectedNgQuartier.communeId === quartiersSelection.commune.id)
                                            .filter(quartiersSelectionSelectedNgQuartier => !selectedNgQuartiers
                                                .map(selectedNgQuartier => selectedNgQuartier.id)
                                                .includes(quartiersSelectionSelectedNgQuartier.id))
                                            .map(ngQuartier => quartierManager.createFromNg2(quartiersSelection.commune.id, ngQuartier))
                                            .forEach(quartier => {
                                                updateLocalisations(quartier, false);
                                            });
                                    },
                                    update: () => {
                                        // @ts-ignore
                                        quartiersSelection.selectedNgQuartiers = localisations.getQuartiersSelected().map(quartier => ng2QuartierFactory.ngCreate(quartier.data))
                                    }
                                } as {
                                    commune: NgCommune;
                                    selectedNgQuartiers: Quartier[];
                                    selectQuartiers: (selectedQuartiers: Quartier[]) => void;
                                    update: () => void;
                                };

                                return quartiersSelection;

                                /**
                                 * Update localisations
                                 *
                                 * @param quartier
                                 */
                                function updateLocalisations(quartier: NgQuartier, isSelected: boolean) {
                                    if (isSelected) {
                                        localisations.addQuartier(quartier);
                                    } else {
                                        localisations.removeQuartier(quartier);
                                    }
                                }
                            }

                            /**
                             * Init carte GMap
                             *
                             * @return {Object}
                             */
                            function initCarteGmap() {
                                const carteGmap = {
                                    center: {},
                                    changeCenter: (commune: NgCommune) => {
                                        if (!angular.isObject(commune) || !angular.isArray(commune.coordonneesGps)) {
                                            return;
                                        }

                                        // @ts-ignore
                                        carteGmap.center.latitude = commune.coordonneesGps[0];
                                        // @ts-ignore
                                        carteGmap.center.longitude = commune.coordonneesGps[1];
                                        // @ts-ignore
                                        carteGmap.zoom = 10;
                                    },
                                    onGetPolygons: (polygons: {
                                        getPath: () => {
                                            getArray: () => { toJSON: () => { lat: number; lng: number } }[]
                                        }
                                    }[]) => {
                                        const promises: IPromise<NgCommunesCollection>[] = [];

                                        polygons.forEach(polygon => {
                                            const paths = polygon.getPath().getArray();

                                            if (paths.length > 2) {
                                                const coordinates: Record<string, unknown> = {};

                                                for (let i = 0, c = paths.length; i < c; i++) {
                                                    const latLngObject = paths[i].toJSON();

                                                    coordinates[i] = [latLngObject.lat.toFixed(5), latLngObject.lng.toFixed(5)];
                                                }

                                                promises.push(communeManager.getAll({polygon: {coordinates: coordinates}}).then(
                                                    paginator => paginator.collection.forEach((commune: NgCommune) => localisations.addCommune(commune))
                                                ));
                                            }
                                        });

                                        return $q.all(promises);
                                    }
                                };

                                return carteGmap;
                            }

                            /**
                             * Init carte quartiers
                             *
                             * @return {Object}
                             */
                            function initCarteQuartiers() {
                                const carteQuartiers = {
                                    codesSelected: [],
                                    commune: undefined!,
                                    quartiers: [],
                                    changeCommune: (commune: NgCommune) => {
                                        if (!angular.isObject(commune)) {
                                            return;
                                        }

                                        // @ts-ignore
                                        carteQuartiers.loader = true;
                                        // @ts-ignore
                                        carteQuartiers.commune = commune;
                                        communeManager.create(commune).getAllQuartiers().then(quartiers => {
                                            carteQuartiers.quartiers = quartiers.collection;
                                            carteQuartiers.update();
                                        }).finally(() => {
                                            // @ts-ignore
                                            carteQuartiers.loader = false;
                                        });
                                    },
                                    update: () => carteQuartiers.codesSelected = lmap(lmap(localisations.getQuartiersSelected(), 'data'), 'code'),
                                    onSelect: (quartierSelected: NgQuartier, isSelected: boolean) => {
                                        const quartier = find(carteQuartiers.quartiers, {code: quartierSelected.id}) as NgQuartier;

                                        if (isSelected) {
                                            localisations.addQuartier(quartier);
                                        } else {
                                            localisations.removeQuartier(quartier);
                                        }
                                    }
                                } as {
                                    codesSelected: string[];
                                    commune: NgCommune;
                                    quartiers: NgQuartier[];
                                    update: () => void
                                };

                                return carteQuartiers;
                            }
                        })(currentUserCommune);

                        if ($ctrl.localisations.collection.length === 0) {
                            $ctrl.localisations.addCommune(currentUserCommune);
                        }

                        if ($ctrl.search.archive.types?.length >= 0) {
                            $ctrl.typesReferencesArchivees = $ctrl.search.archive.types.map(dictionaryItem => ng2DictionaryItemService.getByCode(Dictionary.names.REFERENCES_ARCHIVEES_TYPES, dictionaryItem.code));
                        } else {
                            $ctrl.typesReferencesArchivees = [];
                        }

                        if ($ctrl.search.etats?.length >= 0) {
                            $ctrl.etats = $ctrl.search.etats.map(etat => ng2DictionaryItemService.getById(Dictionary.names.ETATS, etat.id));
                        } else {
                            $ctrl.etats = [];
                        }

                        if ($ctrl.search.mitoyennetes?.length >= 0) {
                            $ctrl.mitoyennetes = $ctrl.search.mitoyennetes.map(mitoyennete => ng2DictionaryItemService.getById(Dictionary.names.MITOYENNETES, mitoyennete.id));
                        } else {
                            $ctrl.mitoyennetes = [];
                        }

                        if ($ctrl.search.occupations?.length >= 0) {
                            $ctrl.occupations = $ctrl.search.occupations.map(occupation => ng2DictionaryItemService.getById(Dictionary.names.OCCUPATIONS, occupation.id));
                        } else {
                            $ctrl.occupations = [];
                        }

                        if ($ctrl.search.plainPieds?.length >= 0) {
                            $ctrl.plainPieds = $ctrl.search.plainPieds.map(plainPied => ng2DictionaryItemService.getById(Dictionary.names.PLAIN_PIEDS, plainPied.id));
                        } else {
                            $ctrl.plainPieds = [];
                        }

                        if ($ctrl.search.archive.dateVenteMax) {
                            $ctrl.anneeCessionMax = DateFormat.getYear($ctrl.search.archive.dateVenteMax);
                        }

                        if ($ctrl.search.archive.dateVenteMin) {
                            $ctrl.anneeCessionMin = DateFormat.getYear($ctrl.search.archive.dateVenteMin);
                        }

                        $ctrl.updateSearchLocalisations = updateSearchLocalisations;
                        $ctrl.changeTerrasseOuBalcon();
                        $ctrl.changeStationnement();
                    });
                });
            }

            /**
             * On change stationnement
             */
            function changeStationnement() {
                $ctrl.search.option.stationnement = $ctrl.search.option.stationnement === true ? true : undefined;
            }

            /**
             * On change terrasseOuBalcon
             */
            function changeTerrasseOuBalcon() {
                $ctrl.search.option.terrasseOuBalcon = $ctrl.search.option.terrasseOuBalcon === true ? true : undefined;
            }

            /**
             * check if field is disabled
             *
             * @param field
             * @param fieldRequired
             * @return boolean
             */
            function isDisabled(field: string, fieldRequired: string) {
                if (!field || !fieldRequired || !angular.isObject($ctrl.search.option) || !angular.isDefined($ctrl.search.option[fieldRequired]) || $ctrl.search.option[fieldRequired] === null) {
                    return false;
                }

                $ctrl.search.option[field] = null;

                return true;
            }

            /**
             * Add label for superficie
             *
             * @return {string}
             */
            function getLabelSuperficie() {
                let nature;

                if (angular.isObject(find($ctrl.search.natures, {code: Bien.natures.MAISON})) ||
                    angular.isObject(find($ctrl.search.natures, {code: Bien.natures.APPARTEMENT})) ||
                    angular.isObject(find($ctrl.search.natures, {code: Bien.natures.IMMEUBLE}))) {
                    nature = Bien.natures.MAISON;
                } else if (angular.isObject(find($ctrl.search.natures, {code: Bien.natures.BUREAUX})) ||
                    angular.isObject(find($ctrl.search.natures, {code: Bien.natures.LOCAUX_COMMERCIAUX}))) {
                    nature = Bien.natures.BUREAUX;
                } else if (angular.isObject(find($ctrl.search.natures, {code: Bien.natures.TERRAIN_A_BATIR})) ||
                    angular.isObject(find($ctrl.search.natures, {code: Bien.natures.AUTRE_TERRAIN}))) {
                    nature = Bien.natures.TERRAIN_A_BATIR;
                }

                return $translate.instant('bien.field_label.SUPERFICIE', {nature});
            }

            /**
             * On type change
             */
            function onChangeRechercheBudgetHni() {
                if ($ctrl.search.budgetHni) {
                    return;
                }

                $ctrl.search.vente.excludeVenteInteractive = true;
            }

            /**
             * On type change
             */
            function onChangeType() {
                let promise = $q.resolve();

                if (angular.isFunction($ctrl.options.onChangeType)) {
                    promise = $ctrl.options.onChangeType($ctrl.search.type, $ctrl.typeSearch);
                }

                promise.then(() => $ctrl.search.type = $ctrl.typeSearch, () => $ctrl.typeSearch = $ctrl.search.type);
            }

            function onSelectEtats(etats: DictionaryItem[]) {
                $ctrl.etats = etats;
                $ctrl.search.etats = etats.map(etat => dictionariesManager.createFromNg2(etat));
            }

            function onSelectMitoyennetes(mitoyennetes: DictionaryItem[]) {
                $ctrl.mitoyennetes = mitoyennetes;
                $ctrl.search.mitoyennetes = mitoyennetes.map(mitoyennete => dictionariesManager.createFromNg2(mitoyennete));
            }

            function onSelectOccupations(occupations: DictionaryItem[]) {
                $ctrl.occupations = occupations;
                $ctrl.search.occupations = occupations.map(occupation => dictionariesManager.createFromNg2(occupation));
            }

            function onSelectPlainPieds(plainPieds: DictionaryItem[]) {
                $ctrl.plainPieds = plainPieds;
                $ctrl.search.plainPieds = plainPieds.map(plainPied => dictionariesManager.createFromNg2(plainPied));
            }

            function onSelectChambresRange(chambresRange: IFormRangeSliderInput) {
                $ctrl.search.option.nombreChambresMax = chambresRange.max;
                $ctrl.search.option.nombreChambresMin = chambresRange.min;
            }

            function onSelectLogementsRange(logementsRange: IFormRangeSliderInput) {
                $ctrl.search.option.nombreLogementsMax = logementsRange.max;
                $ctrl.search.option.nombreLogementsMin = logementsRange.min;
            }

            function onSelectPiecesRange(piecesRange: IFormRangeSliderInput) {
                $ctrl.search.option.nombrePiecesMax = piecesRange.max;
                $ctrl.search.option.nombrePiecesMin = piecesRange.min;
            }

            function onSelectTypesReferencesArchivees(referencesArchiveesTypes: DictionaryItem[]) {
                $ctrl.typesReferencesArchivees = referencesArchiveesTypes;
                $ctrl.search.archive.types = referencesArchiveesTypes.map(referencesArchiveesType => dictionariesManager.createFromNg2(referencesArchiveesType));
            }

            function sourceSelectionCityscan() {
                estimationManager.current$.pipe(take(1)).subscribe(estimation => {
                    if (!estimation.bien.option.commune || (
                        (estimation.bien.option.commune.hasVoies && !estimation.bien.option.voie) ||
                        (!estimation.bien.option.commune.hasVoies && !estimation.bien.option.nomVoie)
                    )) {
                        $ctrl.search.option.source = SearchCriteriaSourceConst.ETUDE;

                        return ng2ModalService.openInformation({
                            comments: 'Pour pouvoir insérer des références ' +
                                '<img src="' + Etude.enablableModules.CITYSCAN.srcImage + '" class="tw:w-[25px] tw:inline-block" loading="lazy"> ' +
                                Etude.enablableModules.CITYSCAN.label + ' à votre bien en évaluation, il faut lui saisir une ' +
                                (estimation.bien.option.commune ? 'adresse' : 'commune et une adresse') + '.',
                            status: ModalService.statuts.WARNING,
                            title: 'Références ' + Etude.enablableModules.CITYSCAN.label,
                        });
                    }

                    if (currentEtude.settingsCityscan.enabled) {
                        return;
                    }

                    $ctrl.search.option.source = SearchCriteriaSourceConst.ETUDE

                    return ng2ModalService.openInformation({
                        comments: 'Découvrez comment rechercher des références de biens vendus depuis ' +
                            '<img src="' + Etude.enablableModules.CITYSCAN.srcImage + '" class="tw:w-[25px] tw:inline-block" loading="lazy"> ' +
                            Etude.enablableModules.CITYSCAN.label,
                        moduleContact: AppNoteoContactComponent.modules.CITYSCAN,
                        status: ModalService.statuts.INFO,
                        title: 'Références ' + Etude.enablableModules.CITYSCAN.label,
                    });
                });
            }

            /**
             * Allow parent to update to localisations
             *
             * @param search
             * @returns {Promise}
             */
            function updateSearchLocalisations(search: NgSearch) {
                let promise = $q.resolve();

                if ($ctrl.search.option.source === $ctrl.CITYSCAN_CODE) {
                    if ($ctrl.anneeCessionMax > 0) {
                        if ($ctrl.anneeCessionMax < 100) {
                            $ctrl.anneeCessionMax += 2000;
                        }

                        $ctrl.search.archive.dateVenteMax = DateFormat.toAPI($ctrl.anneeCessionMax.toString() + '-12-31')
                    } else {
                        $ctrl.search.archive.dateVenteMax = null!;
                    }

                    if ($ctrl.anneeCessionMin > 0) {
                        if ($ctrl.anneeCessionMin < 100) {
                            $ctrl.anneeCessionMin += 2000;
                        }

                        $ctrl.search.archive.dateVenteMin = DateFormat.toAPI($ctrl.anneeCessionMin.toString() + '-01-01')
                    } else {
                        $ctrl.search.archive.dateVenteMin = null!;
                    }
                }

                if (search.option.source === Etude.enablableModules.CITYSCAN.code) {
                    search.communes = [estimationManager.current.bien.option.commune];
                    search.option.voie = estimationManager.current.bien.option.voie;
                    search.option.nomVoie = estimationManager.current.bien.option.nomVoie;
                    search.option.numeroVoie = estimationManager.current.bien.option.numeroVoie;
                } else if ($ctrl.localisations.mode === 'adresse') {
                    search.communes = [$ctrl.localisations.searchByAdresse.commune];
                    search.option.voie = $ctrl.localisations.searchByAdresse.voie;
                    search.option.nomVoie = $ctrl.localisations.searchByAdresse.nomVoie;
                } else {
                    if (angular.isFunction($ctrl.localisations.carteGmap.getPolygons)) promise = $ctrl.localisations.carteGmap.getPolygons();

                    search.option.voie = undefined!;
                    search.option.nomVoie = undefined!;
                    promise.then(() => {
                        search.quartiers = lmap(filter($ctrl.localisations.collection, {type: 'quartier'}), 'data');
                        search.communes = lmap(filter($ctrl.localisations.collection, {type: 'commune'}), 'data');
                        search.areas = lmap(filter($ctrl.localisations.collection, {type: 'area'}), 'data');
                    });
                }

                return promise.then(() => search);
            }
        }
    })(angularJS);
}
