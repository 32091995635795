@if (dossierBien$ | async; as dossierBien) {
  @if (dossierBien[options.linkPhotoAttribute!]) {
    <div class="tw:relative tw:h-full tw:w-full">
      <img class="tw:min-h-full tw:min-w-full tw:object-cover" [ngSrc]="dossierBien[options.linkPhotoAttribute!]" fill>
    </div>
  } @else {
    @if (currentEtude$ | async; as currentEtude) {
      <div class="tw:relative tw:h-full tw:w-full">
        <img class="tw:min-h-full tw:min-w-full tw:object-cover"
             [ngSrc]="dossierBien.interne ? currentEtude.linkLogo : currentEtude.linkMarianne" fill>
      </div>
    }
  }
} @else {
  <app-loader class="tw:block"/>
}
