<div class="tw:mb-5">
  <h3><fa-icon [icon]="'edit'"/> Mise à jour des données</h3>
</div>

<form (ngSubmit)="update(updateForm)" #updateForm="ngForm">
  <div class="tw:divide-y tw:divide-gray-200">
    <div class="tw:sm:grid tw:sm:grid-cols-2 tw:divide-y tw:sm:divide-y-0 tw:sm:divide-x tw:divide-gray-200 tw:sm:pb-4">
      <div class="tw:sm:pr-4">
        <div class="tw:py-4 tw:flex tw:flex-col tw:gap-4">
          <!-- 16px et mb -3px pour 20px -->
          <div class="tw:text-center"><app-noteo-logo-noty class="tw:h-[16px] tw:-mb-[3px]"/></div>

          <ng-container *ngTemplateOutlet="dataValeurVenale;context:{data: notyData}"/>
        </div>
      </div>

      <div class="tw:sm:pl-4">
        <div class="tw:py-4 tw:flex tw:flex-col tw:gap-4">
          <!-- 14px pour 20px -->
          <div class="tw:text-center"><app-noteo-logo-notestim class="tw:h-[14px]"/></div>

          <ng-container *ngTemplateOutlet="dataValeurVenale;context:{data: notestimData}"/>
        </div>
      </div>

      <ng-template #dataValeurVenale let-data="data">
        <p class="tw:p-0 tw:flex tw:flex-col tw:gap-2">
          @if (data.valeurVenale > 0) {
            Valeur vénale de

            <span class="tw:text-base tw:font-semibold tw:text-gray-700">
              {{data.valeurVenale | textCurrency}}
            </span>
          } @else {
            Pas de valeur vénale saisie<span class="tw:text-base"> </span>
          }
        </p>
      </ng-template>
    </div>

    <div class="tw:flex tw:justify-center tw:py-4 tw:flex-col tw:gap-4">
<!-- En cas de données supplémentaires à valider, l'utilisation de ce titre sera utile
    <div class="tw:flex tw:flex-col tw:gap-4 tw:py-4">
      <div class="tw:text-center">Données retenues</div>
-->

      <div [appFormError]="valeurVenale">
        <label for="valeur-venale">Valeur vénale retenue</label>

        <div class="tw:input-currency">
          <input type="number" class="tw:input-number" id="valeur-venale" step="0.01" min="0" name="valeurVenale"
                 #valeurVenale="ngModel" [(ngModel)]="updatedData.valeurVenale" pattern="^(?=.*[1-9])\d*(?:.\d*)?\s*$"
                 required>

          <span>€</span>
        </div>
      </div>
    </div>
  </div>

  <div class="tw:sm:flex tw:sm:flex-row-reverse tw:gap-4 tw:mt-5 tw:sm:mt-4">
    <button class="tw:btn-primary-info" type="submit">Enregistrer</button>
  </div>
</form>
