<div class="tw:mb-5">
  <h3><fa-icon [icon]="'edit'"/> {{ngBienDiagnostic.uuid ? 'Édition' : 'Ajout'}} d'un diagnostic</h3>
</div>

<form (ngSubmit)="save(editForm)" #editForm="ngForm"
      class="tw:w-[80vw] tw:sm:w-[30rem] tw:md:w-[40rem] tw:lg:w-[50rem]">
  <div class="tw:py-6">
    <div class="row">
      <div class="col-xs-12 col-sm-6">
        <div class="form-group" [appFormError]="bienDiagnosticType.formInput">
          <label>Type de diagnostic *</label>

          <app-dictionary-select-items #bienDiagnosticType="dictionarySelect" [ngItem]="ngBienDiagnostic.typeDiagnostic"
                                       [name]="DICTIONARY_NAME_DIAGNOSTIC_TYPES" (selected)="onSelectDiagnostic($event)"
                                       [options]="{name: 'diagnostic' + ngBienDiagnostic.uuid, required: true}"/>
        </div>
      </div>

      <div class="col-xs-12 col-sm-6">
        <div class="form-group" [appFormError]="bienDiagnosticDate.formInput">
          <label>Date</label>

          <app-form-select-date #bienDiagnosticDate="formSelectDate" [model]="ngBienDiagnostic.date"
                                [options]="optionsDate" (selected)="onSelectDate($event)"/>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-xs-12">
        <div class="form-group">
          <label for="diagnostic-comments">Commentaires</label>

          <textarea id="diagnostic-comments" class="form-control" name="comments" [minHeight]="100"
                    [(ngModel)]="ngBienDiagnostic.comments"></textarea>
        </div>
      </div>
    </div>
  </div>

  <div class="tw:float-right">
    <button type="submit" class="tw:btn-primary-info"><fa-icon [icon]="'check'"/><span>Enregistrer</span></button>
  </div>
</form>
