<div class="tw:overflow-y-auto tw:h-full">
  <div class="tw:sticky tw:py-6 tw:sm:p-6 tw:top-0 tw:bg-gray-50">
    @if (etudePasserelle$ | async; as etudePasserelle) {
      <div class="tw:h-12 tw:flex tw:items-center tw:justify-between ">
        @if (hasSelectedItems) {
          <app-collection-selection-bar class="tw:block tw:overflow-hidden tw:w-full tw:md:rounded-lg"
                                        [listName]="nameSelection"/>
        } @else {
          <div class="tw:flex tw:items-center tw:gap-8 tw:mx-6 tw:sm:mx-0 tw:truncate">
            <a class="tw:border-r-2 tw:border-gray-200 tw:pr-8 tw:text-gray-500"
               [routerLink]="'/app/diffusions/passerelles'"><fa-icon [icon]="'arrow-left'" size="xl"/></a>

            <div class="tw:flex tw:text-gray-700 tw:text-lg tw:truncate">
              <span class="tw:flex tw:items-center tw:mr-2">
                <img [ngSrc]="etudePasserelle.passerelle.urlLogo || linkMarianne" height="24" width="24"
                     class="tw:object-contain" alt="{{etudePasserelle.passerelle.name}}"/>
              </span>

              <span class="tw:flex-1 tw:truncate">{{ etudePasserelle.passerelle.name }} - {{ title }}</span>
            </div>
          </div>
        }
      </div>
    } @else {
      <app-loader [classHeight]="'tw:h-10'" class="tw:block tw:p-4"/>
    }
  </div>

  <div class="tw:overflow-y-auto"><router-outlet/></div>
</div>
