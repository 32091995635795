import {inject, Injectable} from '@angular/core';
import {
    DossierFileContactFactory
} from '@models/dossiers/dossier/files/file/contacts/contact/dossier-file-contact.factory';
import {
    IDossierFileContactApi
} from '@models/dossiers/dossier/files/file/contacts/contact/dossier-file-contact.interfaces';
import DossierFileContact
    from '@models/dossiers/dossier/files/file/contacts/contact/dossier-file-contact.model';

@Injectable({providedIn: 'root'})
export class DossierFileContactsFactory {
    private _dossierFileContactFactory = inject(DossierFileContactFactory);

    create(dossierFileContactApis: IDossierFileContactApi[] = []): DossierFileContact[] {
        return (dossierFileContactApis || []).filter(dossierFileContactApi => dossierFileContactApi)
            .map(dossierFileContactApi => this._dossierFileContactFactory.create(dossierFileContactApi));
    }
}
