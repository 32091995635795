import {inject, Injectable} from '@angular/core';
import Links from '@models/links/links.model';
import {map} from 'rxjs/operators';
import {ReportDossiersApiService} from '@models/reports/report/dossiers/report-dossiers.api.service';
import {ICReportDossiersApi} from '@models/reports/report/dossiers/collection/report-dossiers.collection.interfaces';
import CReportDossiers from '@models/reports/report/dossiers/collection/report-dossiers.collection.model';
import {Observable} from 'rxjs';
import Report from '@models/reports/report/report.model';
import {ReportDossiersFactory} from '@models/reports/report/dossiers/report-dossiers.factory';

@Injectable({providedIn: 'root'})
export class CReportDossiersFactory {
    private _reportDossiersApiService = inject(ReportDossiersApiService);
    private _reportDossiersFactory = inject(ReportDossiersFactory);

    create(cReportDossiersApi: ICReportDossiersApi): CReportDossiers {
        const cReportDossiers = new CReportDossiers();

        cReportDossiers.links = new Links(cReportDossiersApi._links);
        cReportDossiers.page = cReportDossiersApi.page;
        cReportDossiers.pages = cReportDossiersApi.pages;
        cReportDossiers.perPage = cReportDossiersApi.limit;
        cReportDossiers.total = cReportDossiersApi.total;
        if (cReportDossiersApi._embedded) {
            cReportDossiers.results = this._reportDossiersFactory.create(cReportDossiersApi._embedded.items);
        }

        return cReportDossiers;
    }

    get$(report: Report): Observable<CReportDossiers> {
        return this._reportDossiersApiService.getCollection$(report.id.toString()).pipe(map(cReportDossiersApi => this.create(cReportDossiersApi)));
    }
}
