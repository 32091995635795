@if (filter$ | async; as filter) {
  <div class="tw:second-nav">
    <div class="tw:second-nav-menus"><h1 class="tw:second-nav-menus-title">Rechercher une étude</h1></div>

    <div class="tw:second-nav-content tw:bg-gray-100">
      <div class="tw:float-left tw:hidden tw:h-full tw:lg:block tw:lg:w-1/2 tw:xl:w-1/3">
        <app-etudes-sites-map [data$]="etudesSiteMapData$" [mapBox$]="etudesSiteMapBox$"
                              (boxChanged)="mapChanged(filter, $event)"
                              (siteClicked)="siteClicked($event)"/>
      </div>

      <div class="tw:float-left tw:overflow-y-auto tw:w-full tw:h-full tw:lg:w-1/2 tw:xl:w-2/3">
        <div class="tw:p-8">
          <form class="tw:rounded-lg tw:bg-white tw:p-4 tw:flex tw:flex-col tw:gap-4">
            <div class="tw:text-lg tw:font-medium tw:leading-6 tw:text-gray-900">Filtres</div>

            <div class="tw:flex tw:flex-col tw:sm:flex-row tw:gap-4 tw:sm:gap-0 tw:space-x-0 tw:lg:space-x-4">
              <div class="tw:hidden tw:lg:block tw:max-w-sm tw:sm:w-80">
                <label class="tw:block tw:mb-2 tw:text-sm tw:text-gray-700">Commune</label>

                <app-commune-select [id]="filter.queryParameters.commune?.values![0]"
                                    (selected)="communeSelected(filter, $event)"
                                    [options]="{placeholder: 'Rechercher par nom ou code postal'}"/>
              </div>

              <div class="tw:max-w-sm tw:sm:w-80">
                <label class="tw:block tw:mb-2 tw:text-sm tw:text-gray-700">Mots clés</label>

                <app-form-search-input [model]="filter.queryParameters.keywords!"
                                       (searchedText)="searchedText(filter, $event)"/>
              </div>
            </div>
          </form>

          <app-etudes-sites-list [cSites$]="cSites$" (actionWhenNoResult)="actionWhenNoResult(filter)"
                                 (hovered)="siteHovered($event)"/>
        </div>
      </div>
    </div>
  </div>
} @else {
  <app-loader [classHeight]="'tw:h-40'" class="tw:block"/>
}
