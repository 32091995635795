@if (leafletMarkerClusterLayers) {
  <div leaflet [leafletOptions]="leafletOptions" [leafletCenter]="leafletCenter" [leafletLayers]="leafletLayers"
       [leafletZoom]="leafletZoom" [leafletFitBounds]="leafletFitBounds"
       [leafletMarkerCluster]="leafletMarkerClusterLayers" [leafletMarkerClusterOptions]="leafletMarkerClusterOptions"
       (leafletMapReady)="mapReady($event)" (leafletMouseDown)="mouseDown()" (leafletMapMoveEnd)="moveEnd($event)"
       (leafletMapZoomEnd)="zoomEnd()" class="tw:w-full tw:h-full"></div>
} @else {
  <div leaflet [leafletOptions]="leafletOptions" [leafletCenter]="leafletCenter" [leafletLayers]="leafletLayers"
       [leafletZoom]="leafletZoom" [leafletFitBounds]="leafletFitBounds" (leafletMapReady)="mapReady($event)"
       (leafletMouseDown)="mouseDown()" (leafletMapMoveEnd)="moveEnd($event)" (leafletMapZoomEnd)="zoomEnd()"
       class="tw:w-full tw:h-full"></div>
}
