export default `
<div ng-if="$ctrl.options" class="wallet">
  <div class="container-fluid container-fluid-title"><h1>Mon portefeuille de demandeurs</h1></div>

  <div class="container-fluid container-fullw tw:bg-white">
    <!-- Actions sur la liste -->
    <div class="row hidden-print">
      <esk-search-barre type="{{$ctrl.options.typeFilter}}" on-change="$ctrl.onChangeFilter"
                        class="col-xs-12 col-md-8 col-lg-9 margin-bottom-15"></esk-search-barre>

      <div class="col-xs-12 col-md-4 col-lg-3 tw:text-center margin-bottom-15">
        <div class="row">
          <app-dossiers-view-choice ng-show="$ctrl.demandeurs.totalItems > 0" class="col-xs-6 tw:text-center"
                                    [route]="$ctrl.filterRoute" [code]="$ctrl.filterCode"
                                    [options]="{showTablePhoto: false}" (changed)="$ctrl.changeDisplayMode($event)">
          </app-dossiers-view-choice>

          <div class="col-xs-6 tw:text-center">
            <a class="btn btn-azure margin-left-10"
               ng-click="$ctrl.ng2RouterStateForNgService.navigateByUrl('/app/demandeurs/item/edit/general')">
              <fa-icon [icon]="'plus'"></fa-icon> Nouveau
            </a>
          </div>
        </div>
      </div>

      <!-- No results -->
      <h4 ng-if="$ctrl.demandeurs.totalItems <= 0" class="col-xs-12 margin-top-30 tw:text-center">
        {{"Aucun demandeur à afficher actuellement, vérifiez que les filtres ne sont pas trop restrictifs." | translate}}
      </h4>
    </div>

    <!-- Liste des demandeurs -->
    <esk-demandeurs-list filter="$ctrl.filter" demandeurs="$ctrl.demandeurs" options="$ctrl.options">
    </esk-demandeurs-list>

    <!-- Pagination -->
    <div class="tw:text-center">
      <esk-pagination ng-hide="$ctrl.options.loader.updating" paginator="$ctrl.demandeurs"
                      loader="$ctrl.options.loader" on-change="$ctrl.onChangePagination"></esk-pagination>
    </div>

    <!-- Barre de faire -->
    <div ng-if="$ctrl.getSelectedDemandeurs().length > 0" class="barre-faire">
      <div class="dossiers-select-label">
        {{'demandeur.grouped_action.selection.MESSAGE' | translate:'{nbDossiers: ' + $ctrl.getSelectedDemandeurs().length + '}'}}
      </div>

      <div ng-switch="!!$ctrl.barreFaireLoader" class="dossiers-select-actions">
        <p ng-switch-when="true" class="loading text-large">
          <fa-icon [icon]="'spinner'" animation="spin"></fa-icon> {{$ctrl.barreFaireLoader | translate}}
        </p>

        <div ng-switch-default class="actions">
          <div class="btn-group dropup" uib-dropdown>
            <button type="button" class="btn btn-dropdown" ng-click="$ctrl.send()">
              <fa-icon [icon]="['far', 'envelope']"></fa-icon>

              <span class="btn-label">{{'mail.action.TITLE' | translate}}</span>
            </button>

            <button type="button" class="btn" uib-dropdown-toggle><span class="caret"></span></button>

            <ul class="dropdown-menu" uib-dropdown-menu role="menu">
              <li role="menuitem">
                <button type="button" class="tw:dropdown-link" ng-click="$ctrl.send(true)">
                  <fa-icon [icon]="['far', 'envelope']"></fa-icon>

                  <span>{{'mail.action.TITLE_RELANCE' | translate}}</span>
                </button>
              </li>

              <li class="divider"></li>

              <li role="menuitem">
                <button type="button" class="tw:dropdown-link" ng-click="$ctrl.groupArchive()">
                  <fa-icon [icon]="'archive'"></fa-icon><span>Archiver</span>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
`;
