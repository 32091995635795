<div class="tw:flex tw:flex-col tw:gap-2">
  <h4 class="tw:px-3 tw:lg:px-4">Habitation</h4>

  <div class="tw:bg-gray-100 tw:rounded-xl tw:flex tw:flex-col tw:gap-4 tw:p-3 tw:lg:p-4">
    <ul class="tw:grid tw:gap-2 tw:text-sm tw:text-gray-700 tw:font-medium">
      @if (isUsageVisible) {
        <li>
          <span class="tw:text-gray-500 tw:text-xs tw:font-normal">Usage du bien</span>
          {{ dossierBien.bien.usage.label }}
        </li>
      }

      @if (isResidenceVisible) {
        <li>
          Résidence
          @if (dossierBien.bien.residenceService) {
            service
          }

          @if (dossierBien.bien.residence) {
            {{ dossierBien.bien.residence.label | lowercase }}
          }
        </li>
      }

      @if (isInfosLocativesVisible) {
        <li>
          <span class="tw:text-gray-500 tw:text-xs tw:font-normal">Bien</span>

          @if (dossierBien.bien.occupation) {
            {{ dossierBien.bien.occupation.label | lowercase }}
          }

          @if (dossierBien.bien.dateDisponibilite) {
            <span class="tw:text-gray-500 tw:text-xs tw:font-normal">disponible le</span>
            {{ dossierBien.bien.dateDisponibilite | date:'longDate' }}
          }

          @if (dossierBien.bien.chargesLocatives > 0 || dossierBien.bien.loyerActuel > 0) {
            <span class="tw:text-gray-500 tw:text-xs tw:font-normal">avec</span>

            @if (dossierBien.bien.loyerActuel > 0) {
              <span class="tw:text-gray-500 tw:text-xs tw:font-normal"> un loyer actuel de</span>
              {{ dossierBien.bien.loyerActuel | textCurrency:'€' }}
            }

            @if (dossierBien.bien.chargesLocatives > 0 && dossierBien.bien.loyerActuel > 0) {
              <span class="tw:text-gray-500 tw:text-xs tw:font-normal"> et</span>
            }

            @if (dossierBien.bien.chargesLocatives > 0) {
              <span class="tw:text-gray-500 tw:text-xs tw:font-normal"> des charges de</span>
              {{ dossierBien.bien.chargesLocatives | textCurrency:'€' }}
            }
          }
        </li>
      }
    </ul>
  </div>
</div>
