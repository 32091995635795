export default `
<div ng-switch on="!!$ctrl.dossier">
  <div ng-switch-when="true">
    <div ng-if="$ctrl.hasNoValue()">Aucune information complémentaire à afficher.</div>

    <div ng-if="!!$ctrl.dossier.bien.titre" class="info-line"><h4>{{$ctrl.dossier.bien.titre}}</h4></div>

    <div ng-if="!!$ctrl.dossier.bien.descriptif" class="info-line">
      <p class="text-bold pre-wrap" ng-bind-html="$ctrl.dossier.bien.descriptif"></p>
    </div>

    <!-- Prix au mandat -->
    <div ng-if="!!$ctrl.dossier._esk.infosPricing && !$ctrl.dossier.enabledCompromis" class="info-line">
      <div class="prices-info">
        <div ng-if="$ctrl.dossier.type.code !== $ctrl.INTERACTIVE && $ctrl.dossier.type.code !== $ctrl.VIAGER">
          <div ng-if="$ctrl.dossier.typeCharges.code !== $ctrl.DICTIONARY_CODE_HONO_NEGO_INCLUS">
            <h4 class="text-bold price-text">
              {{$ctrl.dossier._esk.infosPricing.prixHNI | currency:'€':2 * ($ctrl.dossier._esk.infosPricing.prixHNI % 1 !== 0)}}
            </h4>

            <div class="price-group">
              Dont
              <span class="text-bold price-text">
                {{$ctrl.dossier._esk.infosPricing.prixNV | currency:'€':2 * ($ctrl.dossier._esk.infosPricing.prixNV % 1 !== 0)}}
              </span>
              net vendeur
            </div>

            <div class="price-group">
              Dont
              <span class="text-bold price-text">
                {{$ctrl.dossier._esk.infosPricing.honoraires | currency:'€':2 * ($ctrl.dossier._esk.infosPricing.honoraires % 1 !== 0)}}
              </span>
              honoraires de négociation (soit
              <span class="text-bold price-text">
                {{$ctrl.dossier._esk.infosPricing.pourcentageHonoraires | currency:'%':2 * ($ctrl.dossier._esk.infosPricing.pourcentageHonoraires % 1 !== 0)}}
              </span>
              T.T.C. du prix net vendeur)
            </div>
          </div>

          <div ng-if="$ctrl.dossier.typeCharges.code === $ctrl.DICTIONARY_CODE_HONO_NEGO_INCLUS" class="price-group">
            <h4 class="text-bold price-text">
              {{$ctrl.dossier._esk.infosPricing.prixHNI | currency:'€':2 * ($ctrl.dossier._esk.infosPricing.prixHNI % 1 !== 0)}}
            </h4>

            <p>Charge Vendeur</p>
          </div>

          <div ng-if="!!$ctrl.dossier._esk.infosPricing.fraisActe">
            <div class="price-group">
              +
              <span class="text-bold price-text">
                {{$ctrl.dossier._esk.infosPricing.fraisActe | currency:'€':2 * ($ctrl.dossier._esk.infosPricing.fraisActe % 1 !== 0)}}
              </span>
              Frais d'acte
            </div>
          </div>

          <div class="price-group" ng-if="$ctrl.dossier.typeCharges.code !== $ctrl.DICTIONARY_CODE_HONO_NEGO_INCLUS">
            <p>Charge Acquéreur</p>
          </div>
        </div>

        <div ng-if="$ctrl.dossier.type.code === $ctrl.INTERACTIVE">
          <ng-switch on="$ctrl.dossier.typeCharges.code">
            <h4 class="text-bold price-text">
              {{$ctrl.dossier._esk.infosPricing.interactivePremiereOffre | currency:'€':2 * ($ctrl.dossier._esk.infosPricing.interactivePremiereOffre % 1 !== 0)}}
            </h4>

            <div ng-if="!!$ctrl.dossier._esk.infosPricing.fraisActe">
              <div class="price-group"> +
                <span class="text-bold price-text">
                  {{$ctrl.dossier._esk.infosPricing.fraisActe | currency:'€':2 * ($ctrl.dossier._esk.infosPricing.fraisActe % 1 !== 0)}}
                </span>
                Frais d'acte
              </div>
            </div>
          </ng-switch>
        </div>

        <div ng-if="$ctrl.dossier.type.code === $ctrl.VIAGER">
          <div ng-if="$ctrl.dossier.typeCharges.code !== $ctrl.DICTIONARY_CODE_HONO_NEGO_INCLUS" class="price-group">
            <p>Montant</p>

            <h4 class="text-bold price-text">
              {{$ctrl.dossier._esk.infosPricing.bouquetHNI | currency:'€':2 * ($ctrl.dossier._esk.infosPricing.bouquetHNI % 1 !== 0)}}
            </h4>

            <p>
              Dont
              <span class="text-bold price-text">
                {{$ctrl.dossier._esk.infosPricing.bouquetNV | currency:'€':2 * ($ctrl.dossier._esk.infosPricing.bouquetNV % 1 !== 0)}}
              </span>
              bouquet
            </p>

            <div class="price-group">
              Dont
              <span class="text-bold price-text">
                {{$ctrl.dossier._esk.infosPricing.honoraires | currency:'€':2 * ($ctrl.dossier._esk.infosPricing.honoraires % 1 !== 0)}}
              </span>
              honoraires de négociation (soit
              <span class="text-bold price-text">
                {{$ctrl.dossier._esk.infosPricing.pourcentageHonoraires | currency:'%':2 * ($ctrl.dossier._esk.infosPricing.pourcentageHonoraires % 1 !== 0)}}
              </span>
              T.T.C. du bouquet)
            </div>
          </div>

          <div ng-if="$ctrl.dossier.typeCharges.code === $ctrl.DICTIONARY_CODE_HONO_NEGO_INCLUS" class="price-group">
            <p>Montant</p>

            <h4 class="text-bold price-text">
              {{$ctrl.dossier._esk.infosPricing.bouquetHNI | currency:'€':2 * ($ctrl.dossier._esk.infosPricing.bouquetHNI % 1 !== 0)}}
            </h4>
          </div>

          <div class="price-group" ng-if="!!$ctrl.dossier._esk.infosPricing.fraisActe">
            +
            <span class="text-bold price-text">
              {{$ctrl.dossier._esk.infosPricing.fraisActe | currency:'€':2 * ($ctrl.dossier._esk.infosPricing.fraisActe % 1 !== 0)}}
            </span>
            frais d'acte<br>+
            <span class="text-bold price-text">
              {{$ctrl.dossier.viagerRente | currency:'€':2 * ($ctrl.dossier.viagerRente % 1 !== 0)}}
            </span>
            Rente {{$ctrl.dossier.viagerPeriodiciteRente.label | lowercase}}
            <span ng-if="$ctrl.dossier.viagerIndexation">indexée</span>
          </div>

          <div ng-if="$ctrl.dossier.typeCharges.code !== $ctrl.DICTIONARY_CODE_HONO_NEGO_INCLUS" class="price-group">
            <p>Charge Acquéreur</p>
          </div>

          <div ng-if="$ctrl.dossier.typeCharges.code === $ctrl.DICTIONARY_CODE_HONO_NEGO_INCLUS" class="price-group">
            <p>Charge Vendeur</p>
          </div>
        </div>
      </div>
    </div>

    <!-- Prix au compromis -->
    <div ng-if="!!$ctrl.dossier.enabledCompromis" class="info-line">
      <div class="prices-info">
        <div>Bien en compromis
          : {{$ctrl.dossier.enabledCompromis.raisonNegocie ? $ctrl.dossier.enabledCompromis.raisonNegocie.label : ($ctrl.dossier.enabledCompromis.raisonNonNegocie ? $ctrl.dossier.enabledCompromis.raisonNonNegocie.label : 'Indéfini')}}</div>

        <div>
          Prix de cession

          <h4 class="inline-block text-bold price-text">{{$ctrl.dossier.enabledCompromis.prixCession |
            currency:'€':2 * ($ctrl.dossier.enabledCompromis.prixCession % 1 !== 0)}}</h4>
        </div>

        <div ng-if="$ctrl.dossier.enabledCompromis.raisonNegocie">
          Honoraires de négociation

          <h4 class="inline-block text-bold price-text">{{$ctrl.dossier.enabledCompromis.honorairesNego || 0 |
            currency:'€':2 * ($ctrl.dossier.enabledCompromis.honorairesNego % 1 !== 0)}}</h4>
        </div>

        <div
          ng-if="$ctrl.dossier.enabledCompromis.raisonNegocie && $ctrl.dossier.enabledCompromis.honorairesNegoPercus > 0">
          Honoraires de négociation perçus

          <h4 class="inline-block text-bold price-text">{{$ctrl.dossier.enabledCompromis.honorairesNegoPercus |
            currency:'€':2 * ($ctrl.dossier.enabledCompromis.honorairesNegoPercus % 1 !== 0)}}</h4>
        </div>
      </div>
    </div>

    <hr ng-if="$ctrl.showBottomInformations()">
    <div class="row">
      <div ng-if="!!$ctrl.dossier.bien.superficie" class="col-sm-6 col-lg-4">
        <div class="info-line">
          {{ 'bien.field_label.SUPERFICIE' | translate: '{nature: "' + $ctrl.dossier.bien.nature.code + '"}' }} :

          {{$ctrl.dossier.bien.superficie}} m<sup>2</sup>
        </div>
      </div>

      <div ng-if="!!$ctrl.dossier.bien.option.surfaceCadastrale" class="col-sm-6 col-lg-4">
        <div class="info-line">
          Surface cadastrale : {{$ctrl.dossier.bien.option.surfaceCadastrale}} m<sup>2</sup>
        </div>
      </div>

      <div ng-if="!!$ctrl.dossier.bien.option.nombreChambres" class="col-sm-6 col-lg-4">
        <div class="info-line">
          <fa-icon [icon]="'bed'"></fa-icon>

          {{'bien.popover.CHAMBRES' | translate:'{nbChambres: ' + $ctrl.dossier.bien.option.nombreChambres + '}'}}
        </div>
      </div>

      <div ng-if="!!$ctrl.dossier.bien.option.nombrePieces" class="col-sm-6 col-lg-4">
        <div class="info-line">
          <fa-icon [icon]="'house'"></fa-icon>

          {{'bien.popover.PIECES' | translate:'{nbPieces: ' + $ctrl.dossier.bien.option.nombrePieces + '}'}}
        </div>
      </div>

      <div ng-if="!!$ctrl.dossier.bien.option.exposition" class="col-sm-6 col-lg-4">
        <div class="info-line">
          <fa-icon [icon]="['far', 'sun']"></fa-icon> Exposé {{$ctrl.dossier.bien.option.exposition.label}}
        </div>
      </div>

      <div ng-if="!!$ctrl.dossier.bien.option.anneeConstruction" class="col-sm-6 col-lg-4">
        <div class="info-line">
          <fa-icon [icon]="'university'"></fa-icon> Construit en {{$ctrl.dossier.bien.option.anneeConstruction}}
        </div>
      </div>
      <div ng-if="!!$ctrl.dossier.bien.option.chambreRdc" class="col-sm-6 col-lg-4">
        <div class="info-line"><fa-icon [icon]="'check'"></fa-icon> Chambre(s) au RDC</div>
      </div>

      <div ng-if="$ctrl.dossier.bien.performanceEnergetique.statutDpe === $ctrl.DIAG_DISPO && $ctrl.dossier.bien.performanceEnergetique.consommation >= 0"
           class="col-sm-6 col-lg-4">
        <div class="info-line tw:flex tw:items-center">
          <fa-icon [icon]="['far', 'chart-bar']"></fa-icon> DPE : 

          <app-diagnostic-letter-consommation class="tw:inline-block tw:font-bold tw:h-6 tw:w-6 tw:rounded-lg tw:overflow-hidden"
                                              [bien]="$ctrl.ng2Dossier.bien"></app-diagnostic-letter-consommation>
        </div>
      </div>

      <div ng-if="$ctrl.dossier.bien.responsableDossier" class="col-sm-6 col-lg-4">
        <div class="info-line">
          <app-user-cartouche [link]="$ctrl.dossier.bien._links.responsableDossier.href"
                              class="tw:block tw:h-4 tw:max-w-full tw:w-fit"></app-user-cartouche>
        </div>
      </div>
    </div>
  </div>

  <div ng-switch-default><fa-icon [icon]="'spinner'" animation="spin" size="2x"></fa-icon></div>
</div>
`;
