// @todo Mieux ranger dans des sous-répertoires

class ClassCSS {
    static readonly FLEX = 'tw:flex';
    static readonly OVERFLOW_HIDDEN = 'tw:overflow-hidden';
}

export const ClassCSSConst = ClassCSS;

class Condition {
    static readonly CONTAINS = 'CONTAINS';
    static readonly CONTAINS_SOME = 'CONTAINS_SOME';
    static readonly IN = 'IN';
    static readonly NOT_IN = 'NOT_IN';
}

export const ConditionConst = Condition;
export type ConditionDefinition = 'CONTAINS' | 'CONTAINS_SOME' | 'IN' | 'NOT_IN';

class HTMLElementTagName {
    static readonly ANCHOR = 'A';
}

export const HTMLElementTagNameConst = HTMLElementTagName;

class ImageSize {
    static readonly D4_BY_3 = '4by3';
    static readonly D16_BY_9 = '16by9';
    static readonly INSTAGRAM = 'instagram';
    static readonly OPTIMIZED = 'optimized';
    static readonly THUMBNAIL = 'thumbnail';
}

export const ImageSizeConst = ImageSize;
export type ImageSizeDefinition = '4by3' | '16by9' | 'instagram' | 'optimized' | 'thumbnail';

export const LOCALE_FR_FR = 'fr-FR';

class Sort {
    static readonly ASCENDING: SortDefinition = 'ASC';
    static readonly DESCENDING: SortDefinition = 'DESC';
}

export const SortConst = Sort;
export type SortDefinition = 'ASC' | 'DESC';

// @todo Ne pas utiliser d'enum
export enum DisplayMode {
    Table = 'table',
    TablePhoto = 'table-photo',
    Collection = 'collection',
}
