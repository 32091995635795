export default `
<!-- Loader message -->
<div ng-if="$ctrl.loading" class="margin-top-10 tw:text-center">
  <fa-icon [icon]="'spinner'" animation="spin"></fa-icon> {{'global.en_cours.LOAD' | translate}}

  <p class="text-bold margin-top-10">Merci de patienter</p>
</div>

<div class="esk-cropper-container" ng-class="{'esk-cropper-hidden': $ctrl.loading}">
  <img ng-src="{{$ctrl.url}}" loading="lazy"> <canvas ng-if="$ctrl.canvas"></canvas>
</div>
`;
