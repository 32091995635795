<div class="configuration tw:second-nav">
  <div class="tw:second-nav-menus">
    <h1 class="tw:second-nav-menus-title">
      <!-- @todo Supprimer class quand la configuration sera disponible en dessous de 1024px -->
      <app-navigation-toggle class="tw:hidden"/> Configuration
    </h1>

    <app-call-to-action [type]="ACTION_MAIN" class="tw:mr-2"/>
  </div>

  <div class="tw:second-nav-content">
    <div class="tw:hidden tw:lg:block tw:h-full">
      @if (sections$ | async; as sections) {
        <div class="tw:overflow-hidden tw:h-full tw:flex tw:divide-x tw:divide-gray-200 tw:divide-solid">
          <app-navigation-lateral [sections]="sections"/>

          <div class="tw:overflow-y-auto tw:py-4 tw:sm:p-4 tw:flex-1"><router-outlet/></div>
        </div>
      } @else {
        <app-loader [classHeight]="'tw:h-40'" class="tw:block tw:p-4"/>
      }
    </div>

    <div class="tw:lg:hidden tw:h-full tw:p-4 tw:text-xl tw:text-justify tw:text-orange-700">
      La configuration n'est disponible que sur les écrans d'au moins 1024 pixels de largeur.
    </div>
  </div>
</div>
