<div class="tw:flex tw:flex-col tw:lg:flex-row-reverse tw:lg:flex-row tw:h-full tw:overflow-auto tw:lg:overflow-hidden">
  <div class="tw:lg:flex-1 tw:p-4 tw:lg:overflow-auto">
    <div class="tw:xl:w-[22rem] tw:2xl:w-96">
      @if (cSignataires$ | async; as cSignataires) {
        <div class="tw:grid tw:grid-cols-2 tw:md:grid-cols-3 tw:lg:grid-cols-none tw:gap-4">
          @for (signataire of cSignataires.results; track signataire) {
            <div [ngClass]="signataire.isEditable() ? 'tw:focus-within:ring-2 tw:hover:ring-2 tw:hover:ring-blue-400' : ''"
                 class="tw:relative tw:bg-white tw:rounded-lg tw:shadow-sm tw:group tw:ring-offset-4 tw:ring-offset-gray-100 tw:ring-blue-500">
              <div class="tw:p-4 tw:flex tw:flex-col tw:gap-2">
                <h3 class="tw:text-gray-800 tw:text-sm tw:font-medium tw:line-clamp-2 tw:xl:line-clamp-1"
                    [ngClass]="signataire.isEditable() ? 'tw:group-hover:text-gray-700 tw:group-focus:text-gray-800 tw:group-active:text-gray-900' : ''">
                  {{ signataire.convivialName }}
                </h3>

                <div class="tw:flex tw:justify-between tw:items-center">
                  <!-- @todo Pourquoi ne pas utiliser AppProcedureSignataireStatutComponent ? -->
                  <span class="tw:px-2 tw:py-0.5 tw:rounded-full tw:text-xs tw:font-medium tw:capitalize tw:cursor-default"
                        [ngClass]="{'tw:bg-orange-100 tw:text-orange-800': signataire.isEditable(),
                        'tw:bg-green-100 tw:text-green-800': signataire.isFinished(),
                        'tw:bg-red-100 tw:text-red-800': signataire.isRefused()}">{{ signataire.labelStatut }}</span>

                  @if (signataire.isEditable()) {
                    <span class="tw:text-blue-500 tw:group-hover:text-blue-400 tw:group-focus:text-blue-500 tw:group-active:text-blue-600 tw:text-xl">
                      <fa-icon [icon]="'feather-alt'"/>
                    </span>
                  }
                </div>
              </div>

              @if (signataire.isEditable()) {
                <div class="tw:absolute tw:inset-0">
                  <button type="button" class="tw:h-full tw:w-full tw:focus:outline-hidden" #focusButton
                          (click)="onSelectProcedureSignataire(signataire, focusButton)"></button>
                </div>
              }
            </div>
          }
        </div>
      } @else {
        <app-loader [classHeight]="'tw:h-40'" class="tw:block"/>
      }
    </div>
  </div>

  <div class="tw:flex-1 tw:lg:flex-none tw:lg:w-[calc(21cm_+_15px_+_2rem)] tw:xl:w-[calc((100%_-_22rem_+_21cm_+_15px)_/_2)] tw:2xl:w-[calc((100%_-_24rem_+_21cm_+_15px)_/_2)] tw:lg:h-full tw:pt-4 tw:px-4 tw:border-0 tw:border-t tw:lg:border-0 tw:lg:border-r tw:border-gray-200">
    <div class="tw:flex tw:lg:flex-row-reverse tw:h-full">
      <app-pdf [pdfPath$]="pdfPath$" class="tw:w-full tw:xl:w-auto"/>
    </div>
  </div>
</div>
