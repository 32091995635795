export default `
<div class="wallet">
  <div class="container-fluid container-fluid-title"><h1>Diffusion du portefeuille</h1></div>

  <div class="diffusions container-fluid container-fullw tw:bg-white row">
    <ng-switch on="$ctrl.loading" class="col-sm-12 margin-bottom-30">
      <p ng-switch-when="LOADING" class="tw:text-center text-extra-large">
        <fa-icon [icon]="'spinner'" animation="spin"></fa-icon> Mise à jour de vos biens en
        {{$ctrl.typeDossier | translate}}
      </p>

      <p ng-switch-when="NO_DOSSIER" class="tw:text-center text-extra-large">
        {{"Aucun bien en " + $ctrl.typeDossier + " ne possède une date de mandat." | translate}}
      </p>

      <div ng-switch-default class="table-responsive">
        <div class="checkbox clip-check check-primary checkbox-inline only-mine">
          <input type="checkbox" id="mes-dossiers" ng-model="$ctrl.responsableDossier"
                 ng-change="$ctrl.onChangeFilter()">

          <label for="mes-dossiers">{{'Seulement mes dossiers'|translate}}</label>
        </div>

        <table class="table table-hover">
          <thead>
          <tr class="row diffusions-information">
            <th></th>

            <th class="col-sm-3"></th>

            <th ng-if="!!$ctrl.network" class="col-sm-1 tw:text-center">{{$ctrl.network.name}}</th>

            <th ng-repeat="passerelleWebsiteSN in $ctrl.passerellesWebsiteSN track by $index" class="col-sm-1 tw:text-center">
              <a ng-if="passerelleWebsiteSN.passerelle.urlSite"
                 ng-href="{{passerelleWebsiteSN.passerelle.urlSite}}" target="_blank">
                {{passerelleWebsiteSN.passerelle.name}}
              </a>

              <div ng-if="!passerelleWebsiteSN.passerelle.urlSite">{{passerelleWebsiteSN.passerelle.name}}</div>
            </th>

            <th></th>

            <th class="lastColumn"
                ng-class="'col-sm-' + (9 - !!$ctrl.network - $ctrl.passerellesWebsiteSN.length)"></th>
          </tr>

          <tr class="row diffusions-biens-sended">
            <th><div ng-include="'src/app/legacy/templates/eskimmo/components/list/select.html'"></div></th>

            <th class="col-sm-3"></th>

            <th ng-if="!!$ctrl.network" class="col-sm-1 tw:text-center">
              <esk-passerelle-contrat passerelle="$ctrl.network"></esk-passerelle-contrat>
            </th>

            <th ng-repeat="passerelleWebsiteSN in $ctrl.passerellesWebsiteSN track by $index" class="col-sm-1 tw:text-center">
              <esk-passerelle-contrat passerelle="passerelleWebsiteSN"></esk-passerelle-contrat>
            </th>

            <th></th>

            <th class="lastColumn"
                ng-class="'col-sm-' + (9 - !!$ctrl.network - $ctrl.passerellesWebsiteSN.length)"></th>
          </tr>
          </thead>

          <tr ng-repeat="dossier in $ctrl.dossiers track by $index" class="row">
            <td class="select hidden-print">
              <div class="checkbox clip-check check-primary">
                <input type="checkbox" id="table-biens-select-{{dossier.id}}" ng-model="dossier._esk.select"
                       ng-change="$ctrl.onSelect(dossier)">

                <label for="table-biens-select-{{dossier.id}}"></label>
              </div>
            </td>

            <td ng-click="$ctrl.onClick(dossier)" class="col-sm-3 dossier">
              <div
                uib-popover-template="'src/app/legacy/templates/biens/controllers/wallet/diffusion/dossier/details.html'"
                popover-is-open="dossier._esk.popoverIsOpen" ng-mouseenter="dossier._esk.popoverIsOpen=true"
                ng-mouseleave="dossier._esk.popoverIsOpen=false">
                <div class="reference">{{dossier.reference}}</div>

                <div class="ellipsis">{{dossier.nomProprietairesGroup}}</div>
              </div>
            </td>

            <td ng-if="!!$ctrl.network" class="col-sm-1 tw:text-center diffusion">
              <button type="button" class="btn" ng-click="$ctrl.networkDiffuse(dossier)"
                      uib-popover-template="'src/app/legacy/templates/biens/controllers/wallet/diffusion/dossier/network.html'"
                      popover-is-open="dossier._esk.network.popoverIsOpen"
                      ng-mouseenter="dossier._esk.network.popoverIsOpen=true"
                      ng-mouseleave="dossier._esk.network.popoverIsOpen=false" ng-switch="dossier._esk.etat">
                <span ng-switch-when="loading"><fa-icon [icon]="'spinner'" animation="spin"></fa-icon></span>

                <span ng-switch-when="diffused" class="darker"><fa-icon [icon]="'check'"></fa-icon></span>

                <span ng-switch-when="suspended" class="text-muted"><fa-icon [icon]="'times'"></fa-icon></span>

                <span ng-switch-default class="text-muted"><fa-icon [icon]="'rss'"></fa-icon></span>
              </button>
            </td>

            <td ng-repeat="passerelle in dossier.passerelles track by $index" class="col-sm-1 tw:text-center diffusion">
              <button ng-if="dossier.type !== $ctrl.INTERACTIVE || passerelle.passerelle.venteInteractive !== 'none'"
                      type="button" class="btn" ng-click="$ctrl.passerelleAction(passerelle)"
                      uib-popover-template="'src/app/legacy/templates/biens/controllers/wallet/diffusion/dossier/passerelle.html'"
                      popover-is-open="passerelle._esk.popoverIsOpen"
                      ng-mouseenter="passerelle._esk.popoverIsOpen=true"
                      ng-mouseleave="passerelle._esk.popoverIsOpen=false" ng-switch="passerelle._esk.etat">
                <span ng-switch-when="loading"><fa-icon [icon]="'spinner'" animation="spin"></fa-icon></span>

                <span ng-switch-when="diffused" class="darker"><fa-icon [icon]="'check'"></fa-icon></span>

                <span ng-switch-when="suspended" class="text-muted"><fa-icon [icon]="'times'"></fa-icon></span>

                <span ng-switch-default class="text-muted"><fa-icon [icon]="'rss'"></fa-icon></span>
              </button>

              <span ng-if="dossier.type === $ctrl.INTERACTIVE && passerelle.passerelle.venteInteractive === 'none'"
                    uib-popover-template="'src/app/legacy/templates/biens/controllers/wallet/diffusion/dossier/vente-interactive.html'"
                    popover-is-open="passerelle._esk.popoverIsOpen" ng-mouseenter="passerelle._esk.popoverIsOpen=true"
                    ng-mouseleave="passerelle._esk.popoverIsOpen=false"><fa-icon [icon]="'times'"></fa-icon></span>
            </td>

            <td ng-click="$ctrl.onClick(dossier)" class="tw:text-center cursor-pointer">
              <fa-icon [icon]="'angle-right'"></fa-icon>
            </td>

            <td class="lastColumn"
                ng-class="'col-sm-' + (9 - !!$ctrl.network - $ctrl.passerellesWebsiteSN.length)"></td>
          </tr>
        </table>
      </div>
    </ng-switch>

    <div ng-if="$ctrl.getDossiers().length > 0" class="barre-faire">
      <div class="dossiers-select-label">
        {{'bien.grouped_action.selection.MESSAGE' | translate:'{nbDossiers: ' + $ctrl.getDossiers().length + '}'}}
      </div>

      <div ng-switch="!!$ctrl.barreFaireLoader" class="dossiers-select-actions">
        <p ng-switch-when="true" class="loading text-large">
          <fa-icon [icon]="'spinner'" animation="spin"></fa-icon>
          {{$ctrl.barreFaireLoader | translate:'{nbDossiers: ' + $ctrl.getDossiers().length + ', nbPasserelles: ' + $ctrl.nbPasserellesSelected + '}'}}
        </p>

        <div ng-switch-default class="actions">
          <button type="button" class="btn" ng-click="$ctrl.printAffichettes()">
            <fa-icon icon="print"></fa-icon> <span>{{'bien.grouped_action.print.LABEL' | translate}}</span>
          </button>

          <button ng-if="$ctrl.currentEtude.passerellesSocialNetwork.length > 0" type="button" class="btn"
                  ng-click="$ctrl.sendTo('social_network')">
            <fa-icon [icon]="['fab', 'facebook-square']"></fa-icon>

            <span>
              {{'bien.grouped_action.send.LABEL' | translate}}
              {{'bien.grouped_action.send.social_network.BUTTON' | translate:'{nbDossiers: ' + $ctrl.currentEtude.passerellesSocialNetwork.length + '}'}}
            </span>
          </button>

          <button ng-if="$ctrl.currentEtude.passerellesWebsite.length > 0" type="button" class="btn"
                  ng-click="$ctrl.sendTo('website')">
            <fa-icon [icon]="'globe'"></fa-icon>

            <span>
              {{'bien.grouped_action.send.LABEL' | translate}}
              {{'bien.grouped_action.send.website.BUTTON' | translate:'{nbDossiers: ' + $ctrl.currentEtude.passerellesWebsite.length + '}'}}
            </span>
          </button>

          <button ng-if="$ctrl.currentEtude.passerellesNewspaper.length > 0" type="button" class="btn"
                  ng-click="$ctrl.sendTo('newspaper')">
            <fa-icon [icon]="['far', 'newspaper']"></fa-icon>

            <span>
              {{'bien.grouped_action.send.LABEL' | translate}}
              {{'bien.grouped_action.send.newspaper.BUTTON' | translate:'{nbDossiers: ' + $ctrl.currentEtude.passerellesNewspaper.length + '}'}}
            </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
`;
