@if (estimation$ | async; as estimation) {
  @if (estimation.isArchived()) {
    <app-pdf [pdfPath$]="documentPath$" class="tw:h-full tw:w-full tw:mx-auto"/>
  } @else {
    @if (document$ | async; as document) {
      <form class="tw:pb-8">
        @if (estimation.courrierIntroductifRapportId) {
          <div class="tw:pt-4 tw:flex tw:flex-col tw:gap-8">
            <div class="tw:flex tw:flex-col tw:gap-4">
              <div class="tw:flex tw:justify-between tw:items-end">
                <h3 class="tw:text-lg tw:font-medium tw:leading-6 tw:text-gray-900">
                  {{ estimation.isEvaluation() ? 'Rapport d\'évaluation' : 'Avis de valeur' }}
                </h3>

                <a [routerLink]="'.'" fragment="courrier" class="tw:text-right">Éditer le courrier introductif</a>
              </div>

              <ng-container [ngTemplateOutlet]="editorDocument"/>
            </div>

            @if (courrier$ | async; as courrier) {
              <div [appRoutesAnchorScroll]="'courrier'" class="tw:pt-8 tw:flex tw:flex-col tw:gap-4">
                <div class="tw:flex tw:flex-col tw:gap-2">
                  <h3 class="tw:text-lg tw:font-medium tw:leading-6 tw:text-gray-900">Courrier introductif</h3>

                  <p class="tw:mt-1 tw:text-sm tw:text-gray-600">
                    À l'impression ou à l'envoi par mail, le courrier sera placé avant
                    {{ estimation.isEvaluation() ? 'le rapport d\'évaluation' : 'l\'avis de valeur' }}.
                  </p>
                </div>

                <editor [(ngModel)]="courrier.contenu" [init]="editorCourrierOptions" ngDefaultControl
                        [ngModelOptions]="{standalone: true}"/>
              </div>
            } @else {
              <app-loader [classHeight]="'tw:h-40'"/>
            }
          </div>
        } @else {
          <editor [(ngModel)]="document.contenu" [init]="editorDocumentOptions" ngDefaultControl
                  [ngModelOptions]="{standalone: true}"/>
        }

        <ng-template #editorDocument>
          <editor [(ngModel)]="document.contenu" [init]="editorDocumentOptions" ngDefaultControl
                  [ngModelOptions]="{standalone: true}"/>
        </ng-template>
      </form>
    } @else {
      <app-loader [classHeight]="'tw:h-40'" class="tw:block tw:pt-4"/>
    }
  }
} @else {
  <app-loader [classHeight]="'tw:h-40'" class="tw:block tw:pt-4"/>
}
