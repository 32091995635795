<div class="tw:relative">
  @if (codeStatutDpe !== STATUT_DISPONIBLE) {
    <div class="tw:z-10 tw:bg-black/50 tw:absolute tw:inset-0">
      <div class="tw:h-full tw:w-full">
        <div class="tw:flex tw:items-center tw:justify-center tw:h-full tw:w-full tw:transform tw:-rotate-25">
          <div class="tw:bg-black tw:py-4 tw:px-8 tw:text-3xl tw:text-center tw:font-bold tw:text-white">
            DPE {{ labelStatut }}
          </div>
        </div>
      </div>
    </div>
  }

  <div class="tw:flex">
    <app-diag-graphe-v2-consommation [codeStatutDpe]="codeStatutDpe" [consommationClasse]="consommationClasse"
                                     [consommation]="consommation" [emissions]="emissions"
                                     [ngClass]="options.grapheEmissionsVisibility ? 'tw:w-3/5' : 'tw:w-full'"/>

    @if (options.grapheEmissionsVisibility) {
      <app-diag-graphe-v2-emissions [codeStatutDpe]="codeStatutDpe" [emissionsClasse]="emissionsClasse"
                                    [emissions]="emissions" class="tw:w-2/5"/>
    }
  </div>
</div>

@if (options.estimationCoutsVisibility && (coutMin || coutMax)) {
  <div class="tw:w-full tw:text-center">
    <div class="tw:mb-4">
      Coûts estimés d’énergie
      @if (coutMin && !coutMax) {
        à partir de <span class="tw:px-4 tw:text-3xl tw:font-bold">{{ coutMin | textCurrency }}</span>
      }

      @if (coutMin && coutMax) {
        entre <span class="tw:px-4 tw:text-3xl tw:font-bold">{{ coutMin | textCurrency }}</span> et
        <span class="tw:px-4 tw:text-3xl tw:font-bold">{{ coutMax | textCurrency }}</span>
      }

      @if (!coutMin && coutMax) {
        jusqu'à <span class="tw:px-4 tw:text-3xl tw:font-bold">{{ coutMax | textCurrency }}</span>
      }

      par an

      @if (dateReference) {
        indexés au {{ dateReference | date: 'longDate' }} (abonnements compris)
      }
    </div>
  </div>
}
