<div class="tw:h-full tw:xl:mx-28">
  <form #inotExportForm="ngForm" class="tw:h-full tw:-mx-[15px]" (submit)="send(inotExportForm)">
    <div class="tw:h-12 tw:flex tw:items-center tw:justify-between tw:px-4">
      <div class="tw:flex tw:items-center tw:gap-8">
        <a [routerLink]="redirectionRoute" class="tw:border-r-2 tw:border-gray-200 tw:pr-8 tw:text-gray-500">
          <fa-icon [icon]="'arrow-left'" size="xl"/>
        </a>

        <div class="tw:text-gray-700 tw:text-lg"><app-noteo-icon-text-inot [label]="'Envoi à ' + INOT_LABEL"/></div>
      </div>

      <div class="tw:flex tw:items-center"><app-call-to-action [type]="CALL_TO_ACTION_MAIN"/></div>
    </div>

    <div class="tw:h-[calc(100%_-_3rem)] tw:py-4 tw:md:p-4">
      <div class="tw:form-2col">
        <div class="tw:form-2col-part">
          <div class="tw:form-left-col">
            <h3 class="tw:form-left-col-title">Propriétaires</h3>

            <p class="tw:form-left-col-explain">
              Ces données sont récupérées à partir de votre bien en {{ currentDossier.dossierType }}.<br/>Vous pouvez
              les modifier depuis la partie
              <a [routerLink]="redirectionRouteFiche">"Fiche" de votre bien en {{ currentDossier.dossierType }}</a>.
            </p>
          </div>

          <div class="tw:form-right-col">
            @if (cProprietaires$ | async; as cProprietaires) {
              @if (cProprietaires.total > 0) {
                <app-dossier-members-list (dossierMembersSelected)="dossierProprietairesSelected = $event"
                                          [options]="{initSelectAll: true, nameSelection: 'proprietaires'}"
                                          [cContactsGroupMembers$]="cProprietaires$"/>
              } @else {
                Vous n'avez pas de propriétaire à envoyer à <app-noteo-icon-text-inot/>.
              }
            } @else {
              <app-loader [classHeight]="'tw:h-40'" class="tw:block tw:mt-4"/>
            }
          </div>
        </div>

        @if (currentDossier.isVente()) {
          <div class="tw:form-2col-part">
            <div class="tw:form-left-col">
              <h3 class="tw:form-left-col-title">Acquéreurs</h3>

              <p class="tw:form-left-col-explain">
                Rechercher un demandeur ou laisser vide si les acquéreurs ne font pas partie de votre étude.
              </p>
            </div>

            <div class="tw:form-right-col tw:flex tw:flex-col tw:gap-4">
              <app-search-open (clicked)="openSearchDemandeurModal()"
                               class="tw:w-full tw:sm:w-2/3 tw:md:w-1/2 tw:2xl:w-1/3"
                               [options]="{placeHolder: 'Rechercher un demandeur'}"/>

              @if (demandeur) {
                <hr/>

                @if (cAcquereurs$ | async; as cAcquereurs) {
                  @if (cAcquereurs.results.length > 0) {
                    <app-dossier-members-list (dossierMembersSelected)="dossierAcquereursSelected = $event"
                                              [options]="{initSelectAll: true, nameSelection: 'acquereurs'}"
                                              [cContactsGroupMembers$]="cAcquereurs$"/>
                  } @else {
                    Vous n'avez aucun acquéreur à envoyer à <app-noteo-icon-text-inot/>.
                  }
                } @else {
                  <app-loader [classHeight]="'tw:h-40'" class="tw:block tw:mt-4"/>
                }
              }
            </div>
          </div>
        }

        <div class="tw:form-2col-part">
          <div class="tw:form-left-col">
            <h3 class="tw:form-left-col-title">Documents</h3>

            <div class="tw:form-left-col-explain">
              <p>
                Ces données sont récupérées à partir de votre bien en {{ currentDossier.dossierType }}.<br/>Vous pouvez
                les modifier depuis la partie <a [routerLink]="redirectionRouteDocument">"Documents" de votre bien
                en {{ currentDossier.dossierType }}</a>.
              </p>

              <p>
                En plus de votre sélection, la fiche confidentielle de votre bien en {{ currentDossier.dossierType }}
                @if (currentDossier.isVente()) {
                  sera automatiquement envoyée.
                }

                @if (currentDossier.isEstimation()) {
                  et
                  @if (currentEstimation.isEvaluation()) {
                    le rapport d'évaluation
                  } @else {
                    l'avis de valeur
                  }
                  seront automatiquement envoyés.
                }
              </p>
            </div>
          </div>

          <div class="tw:form-right-col">
            @if (cDossierFiles$ | async; as cDossierFiles) {
              @if (cDossierFiles.total > 0) {
                <app-dossier-files-list (dossierFilesSelected)="dossierFilesSelected = $event"
                                        [options]="{displayColumnShare: false, displayColumnAction: false, filterEnabled: filesSelectFilterEnabled, initSelectAll: true, titleDisabled: filesSelectTitleDisabled}"
                                        [cDossierFiles$]="cDossierFiles$"/>
              } @else {
                Vous n'avez pas de document à envoyer à <app-noteo-icon-text-inot/>.<br>

                @if (currentDossier.isVente()) {
                  Seule la fiche confidentielle sera envoyée.
                }

                @if (currentDossier.isEstimation()) {
                  La fiche confidentielle et
                  @if (currentEstimation.isEvaluation()) {
                    le rapport d'évaluation
                  } @else {
                    l'avis de valeur
                  }
                  seront envoyés.
                }
              }
            } @else {
              <app-loader [classHeight]="'tw:h-40'" class="tw:block tw:mt-4"/>
            }
          </div>
        </div>

        <div class="tw:form-2col-part">
          <div class="tw:form-left-col">
            <h3 class="tw:form-left-col-title">Bien</h3>

            <p class="tw:form-left-col-explain">
              Ces données sont récupérées à partir de votre bien en {{ currentDossier.dossierType }}.<br/>Vous pouvez
              les modifier depuis la partie
              <a [routerLink]="redirectionRouteFiche">"Fiche" de votre bien en {{ currentDossier.dossierType }}</a>.
            </p>
          </div>

          <div class="tw:form-right-col">
            @if (dossierBien) {
              <dl class="tw:m-0 tw:sm:grid tw:sm:divide-y tw:sm:divide-gray-200 tw:sm:gap-4">
                @if (dossierBien.bien.nature) {
                  <div class="tw:sm:grid tw:sm:grid-cols-3">
                    <dt class="tw:text-sm tw:font-medium tw:text-gray-500">Nature</dt>

                    <dd class="tw:mt-1 tw:text-sm tw:text-gray-900 tw:sm:col-span-2 tw:sm:mt-0">
                      {{ dossierBien.bien.nature.label }}
                    </dd>
                  </div>
                }

                <div class="tw:pt-4 tw:sm:grid tw:sm:grid-cols-3">
                  <dt class="tw:text-sm tw:font-medium tw:text-gray-500">Adresse</dt>

                  <dd class="tw:mt-1 tw:text-sm tw:text-gray-900 tw:sm:col-span-2 tw:sm:mt-0">
                    {{ dossierBien.bien.fullInlineAdresse }}
                  </dd>
                </div>

                <div class="tw:pt-4 tw:sm:grid tw:sm:grid-cols-3">
                  <dt class="tw:text-sm tw:font-medium tw:text-gray-500">Description</dt>

                  <dd class="tw:mt-1 tw:text-sm tw:text-gray-900 tw:sm:col-span-2 tw:sm:mt-0">{{ descriptif }}</dd>
                </div>
              </dl>
            } @else {
              Aucun bien sélectionné.
            }
          </div>
        </div>

        <div class="tw:form-2col-part">
          <div class="tw:form-left-col">
            <h3 class="tw:form-left-col-title">Dossier</h3>

            <p class="tw:form-left-col-explain">
              La liste des utilisateurs est recupérée depuis <app-noteo-icon-text-inot/>.<br>Si vous ne trouvez pas
              votre utilisateur, ajoutez le depuis <app-noteo-icon-text-inot/>.
            </p>
          </div>

          <div class="tw:form-right-col">
            <dl class="tw:m-0 tw:sm:grid tw:sm:divide-y tw:sm:divide-gray-200 tw:sm:gap-4">
              <div class="tw:sm:grid tw:sm:grid-cols-3">
                <dt class="tw:text-sm tw:font-medium tw:text-gray-500">Nom</dt>

                <dd class="tw:mt-1 tw:text-sm tw:text-gray-900 tw:sm:col-span-2 tw:sm:mt-0 tw:first-letter:uppercase">
                  {{ currentDossier.contactsGroup.nom || (currentDossier.dossierType + ' ' + currentDossier.reference) }}
                </dd>
              </div>

              <div class="tw:pt-4 tw:sm:grid tw:sm:grid-cols-3">
                <dt class="tw:text-sm tw:font-medium tw:text-gray-500">
                  Utilisateur <app-noteo-icon-text-inot/> à notifier
                </dt>

                <dd class="tw:w-60 tw:mt-1 tw:text-sm tw:text-gray-900 tw:sm:col-span-2 tw:sm:mt-0">
                  <app-inot-users-select (selected)="inotUser = $event" [inotUser]="inotUser"
                                         [options]="{searchable: false, selectFirst: true}"
                                         class="tw:w-full tw:sm:w-2/3 tw:md:w-1/2 tw:2xl:w-1/3"/>
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </div>
    </div>

    <button type="submit" class="tw:hidden"></button>
  </form>
</div>
