@if (pending$ | appLoadObs | async; as pending) {
  @if (canSave$ | async; as canSave) {
    <div [ngClass]="pending.value ? 'tw:waiting' : undefined!" class="tw:flex tw:gap-4">
      <button type="button" class="tw:btn-primary-info" (click)="save()" [disabled]="pending.value">Enregistrer</button>

      @if (showDropdown) {
        <button type="button" class="tw:btn-secondary-info tw:w-10" #buttonElement
                (click)="clickDropdown(buttonElement)"><fa-icon [icon]="'ellipsis-v'"/></button>
      }
    </div>
  }
}
