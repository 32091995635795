import {LOCALE_ID, NgModule} from '@angular/core';
import {CommonModule, NgOptimizedImage, registerLocaleData} from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import localeFrExtra from '@angular/common/locales/extra/fr';
import {ToastrModule} from 'ngx-toastr';
import {FormsModule} from '@angular/forms';
import {AppFormModule} from '@shared/form/form.module';
import {DiagnosticModule} from '@shared/diagnostic/diagnostic.module';
import {CollectionModule} from '@shared/collection/collection.module';
import {ModalModule} from '@shared/modal/modal.module';
import {TinymceModule} from '@shared/tinymce/tinymce.module';
import {IframeModule} from '@shared/iframe/iframe.module';
import {CallToActionModule} from '@shared/call-to-action/call-to-action.module';
import {PdfModule} from '@shared/pdf/pdf.module';
import {LoadModule} from '@shared/load/load.module';
import {SignatureModule} from '@shared/signature/signature.module';
import {DropdownModule} from '@shared/dropdown/dropdown.module';
import {TextsModule} from '@shared/texts/texts.module';
import {SearchModule} from '@shared/search/search.module';
import {NoteoModule} from '@shared/noteo/noteo.module';
import {NavigationModule} from '@shared/navigation/navigation.module';
import {RoutesModule} from '@shared/routes/routes.module';
import {FaModule} from '@shared/fontawesome/fa.module';
import {ImageModule} from '@shared/image/image.module';
import {SlideOverModule} from '@shared/slide-over/slide-over.module';
import {MapModule} from '@shared/map/map.module';
import {LOCALE_FR_FR} from '@shared/constants';
import {IAModule} from '@shared/ia/ia.module';

registerLocaleData(localeFr, LOCALE_FR_FR, localeFrExtra);

@NgModule({
    exports: [
        AppFormModule,
        CallToActionModule,
        CollectionModule,
        DiagnosticModule,
        DropdownModule,
        FaModule,
        FormsModule,
        IAModule,
        IframeModule,
        ImageModule,
        LoadModule,
        MapModule,
        ModalModule,
        NavigationModule,
        NgOptimizedImage,
        PdfModule,
        RoutesModule,
        SearchModule,
        SignatureModule,
        SlideOverModule,
        NoteoModule,
        TinymceModule,
        TextsModule,
    ],
    imports: [
        AppFormModule,
        CallToActionModule,
        CollectionModule,
        CommonModule,
        DiagnosticModule,
        DropdownModule,
        FaModule,
        FormsModule,
        IAModule,
        IframeModule,
        ImageModule,
        LoadModule,
        MapModule,
        ModalModule,
        NavigationModule,
        NgOptimizedImage,
        PdfModule,
        RoutesModule,
        SearchModule,
        SignatureModule,
        SlideOverModule,
        NoteoModule,
        TinymceModule,
        // @todo Créer ToasterModule ou intégrer dans un module existant
        ToastrModule.forRoot({closeButton: true, countDuplicates: true, maxOpened: 5, preventDuplicates: true}),
        TextsModule,
    ],
    providers: [{provide: LOCALE_ID, useValue: LOCALE_FR_FR}],
})
export class SharedModule {
}
