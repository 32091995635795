import AModel from '@models/model.abstract';
import ContactsGroupMember from '@models/contacts-group/members/member/contacts-group-member.model';
import DictionaryItem from '@models/dictionaries/dictionary/items/item/dictionary-item.model';
import Contact from '@models/contacts/contact/contact.model';

export default class ContactsGroup extends AModel {
    static readonly default = {FORMULE_INTRODUCTION: 'Madame, Monsieur', FORMULE_POLITESSE: 'Madame, Monsieur'};
    static readonly NO_NAME = 'Sans nom';
    static readonly statuts = {NEW: AModel.STATUT_NEW};
    private _formuleAdressePostale!: string;
    private _formuleIntroduction!: string;
    private _formulePolitesse!: string;
    private readonly _id: number;
    private _members: ContactsGroupMember[] = [];
    private _nom = ContactsGroup.NO_NAME;
    private _typeGroup!: DictionaryItem;

    constructor(uuid: string, id?: number) {
        super(uuid);
        this._id = id!;
    }

    get formuleAdressePostale(): string {
        return this._formuleAdressePostale;
    }

    set formuleAdressePostale(value: string) {
        this._formuleAdressePostale = value;
    }

    get formuleIntroduction(): string {
        return this._formuleIntroduction;
    }

    set formuleIntroduction(value: string) {
        this._formuleIntroduction = value;
    }

    get formulePolitesse(): string {
        return this._formulePolitesse;
    }

    set formulePolitesse(value: string) {
        this._formulePolitesse = value;
    }

    get id(): number {
        return this._id;
    }

    get members(): ContactsGroupMember[] {
        return this._members;
    }

    set members(value: ContactsGroupMember[]) {
        this._members = value;
    }

    get nom(): string {
        return this._nom;
    }

    set nom(value: string) {
        let nom = value?.substring(0, 140);

        if (nom?.startsWith(ContactsGroup.NO_NAME) && (nom.trim()).length > ContactsGroup.NO_NAME.length) {
            nom = nom.substring(ContactsGroup.NO_NAME.length).trim();
        }

        this._nom = nom;
    }

    get typeGroup(): DictionaryItem {
        return this._typeGroup;
    }

    set typeGroup(value: DictionaryItem) {
        this._typeGroup = value;
    }

    getReferent(): Contact {
        return this.members?.[0]?.contact;
    }

    hasNom(): boolean {
        return !!this.nom && this.nom !== ContactsGroup.NO_NAME;
    }

    isNew(): boolean {
        return this.uuid === ContactsGroup.statuts.NEW;
    }
}
