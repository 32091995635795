import {Component, inject, OnDestroy, OnInit} from '@angular/core';
import {Observable, of, ReplaySubject, Subject, throwError} from 'rxjs';
import ADossier from '@models/dossiers/dossier/dossier.model.abstract';
import {DossierService} from '@models/dossiers/dossier/dossier.service';
import {Router} from '@angular/router';
import {CallToActionService} from '@shared/call-to-action/call-to-action.service';
import {map, take, takeUntil, switchMap, catchError} from 'rxjs/operators';
import ADossierBien from '@models/dossiers/biens/bien/dossier-bien.model.abstract';
import CDossierFiles from '@models/dossiers/dossier/files/collection/dossier-files.collection.model';
import {CDossierFilesService} from '@models/dossiers/dossier/files/collection/dossier-files.collection.service';
import {
    DossierInotExportActionsMainComponent
} from '@features/dossiers/dossier/inot/exports/export/actions/dossier-inot-export.actions-main.component';
import DossierFile from '@models/dossiers/dossier/files/file/dossier-file.model';
import CContactsGroupMembers from '@models/contacts-group/members/collection/contacts-group-members.collection.model';
import {
    CContactsGroupMembersService
} from '@models/contacts-group/members/collection/contacts-group-members.collection.service';
import ContactsGroupMember from '@models/contacts-group/members/member/contacts-group-member.model';
import Bien from '@models/bien/bien.model';
import {ModalService} from '@shared/modal/modal.service';
import {
    DCDemandeurSelectComponent
} from '@features/demandeurs/demandeur/select/dynamic-components/demandeur-select.component';
import Demandeur from '@models/demandeurs/demandeur/demandeur.model';
import InotUser from '@models/inot-users/user/inot-user.model';
import {NgForm} from '@angular/forms';
import {DossierExportSaveFactory} from '@models/dossiers/dossier/exports/export/save/dossier-export-save.factory';
import {
    EtudeDossierQualifiedContactsService
} from '@models/etude-dossiers/etude-dossier/qualified-contacts/etude-dossier.qualified-contacts.service';
import EtudeDossierQualifiedContact
    from '@models/etude-dossiers/etude-dossier/qualified-contacts/qualified-contact/etude-dossier.qualified-contact.model';
import DossierExportFichier
    from '@models/dossiers/dossier/exports/export/medias/media/dossier-export.media.model';
import TextsSizeFormat from '@shared/texts/size/texts-size.format';
import Etude from '@models/etudes/etude/etude.model';
import Estimation from '@models/estimations/estimation/estimation.model';

@Component({
    selector: 'layout-dossier-inot-export',
    standalone: false,
    templateUrl: 'layout-dossier-inot-export.component.html',
})
export class AppLayoutDossierInotExportComponent implements OnDestroy, OnInit {
    // @todo Faire un tour pour factoriser les messages
    static readonly errorMessages = {
        CONTACTS: 'Vous devez renseigner au moins un propriétaire ou un acquéreur.',
        OTHER: 'Certains champs n\'ont pas de valeurs correctes.<br/>Ils sont indiqués en rouge.',
        TITLE: 'Données incomplètes',
    };
    readonly CALL_TO_ACTION_MAIN = CallToActionService.MAIN;
    private _callToActionService = inject(CallToActionService);
    private _cContactsGroupMembersService = inject(CContactsGroupMembersService);
    private _cDossierFilesService = inject(CDossierFilesService);
    private _dossierExportSaveFactory = inject(DossierExportSaveFactory);
    private _dossierService = inject(DossierService);
    private _etudeDossierQualifiedContactsService = inject(EtudeDossierQualifiedContactsService);
    private _modalService = inject(ModalService);
    private _router = inject(Router);
    private _cAcquereursSource = new ReplaySubject<CContactsGroupMembers>(1);
    private _cAcquereurs$ = this._cAcquereursSource.asObservable();
    private _cDossierFiles$!: Observable<CDossierFiles>;
    private _cProprietaires$!: Observable<CContactsGroupMembers>;
    private _currentDossier!: ADossier;
    private _demandeur!: Demandeur;
    private _descriptif!: string;
    private _dossierBien!: ADossierBien;
    private _dossierFilesSelected: DossierFile[] = [];
    private _dossierAcquereursSelected: ContactsGroupMember[] = [];
    private _dossierProprietairesSelected: ContactsGroupMember[] = [];
    private _inotUser!: InotUser;
    private readonly _onDestroy$ = new Subject<void>();
    private _redirectionRoute!: string;
    private _redirectionRouteDocument!: string;
    private _redirectionRouteFiche!: string;
    private _sendSource = new Subject<boolean>();

    get cAcquereurs$(): Observable<CContactsGroupMembers> {
        return this._cAcquereurs$;
    }

    get cDossierFiles$(): Observable<CDossierFiles> {
        return this._cDossierFiles$;
    }

    get cProprietaires$(): Observable<CContactsGroupMembers> {
        return this._cProprietaires$;
    }

    get currentDossier(): ADossier {
        return this._currentDossier;
    }

    get currentEstimation(): Estimation {
        return this._currentDossier as Estimation;
    }

    get demandeur(): Demandeur {
        return this._demandeur;
    }


    get descriptif(): string {
        return this._descriptif;
    }

    get dossierBien(): ADossierBien {
        return this._dossierBien;
    }

    set dossierAcquereursSelected(value: ContactsGroupMember[]) {
        this._dossierAcquereursSelected = value;
    }

    set dossierFilesSelected(value: DossierFile[]) {
        this._dossierFilesSelected = value;
    }

    set dossierProprietairesSelected(value: ContactsGroupMember[]) {
        this._dossierProprietairesSelected = value;
    }

    get inotUser(): InotUser {
        return this._inotUser;
    }

    set inotUser(value: InotUser) {
        this._inotUser = value;
    }

    get INOT_LABEL(): string {
        return Etude.enablableModules.INOT.label;
    }

    get redirectionRoute(): string {
        return this._redirectionRoute;
    }

    get redirectionRouteDocument(): string {
        return this._redirectionRouteDocument;
    }

    get redirectionRouteFiche(): string {
        return this._redirectionRouteFiche;
    }

    ngOnInit(): void {
        this._currentDossier = this._dossierService.getCurrentFromNg(this._router.url);
        this._cProprietaires$ = this._cContactsGroupMembersService.getFromContactsGroupLink$(this._currentDossier.linkContactsGroup);
        this._cDossierFiles$ = this._cDossierFilesService.getAll$(this._currentDossier);
        this._redirectionRoute = '/app/' + this._currentDossier.dossierType + 's/' + this._currentDossier.id.toString() + '/lifetime';
        this._redirectionRouteDocument = '/app/' + this._currentDossier.dossierType + 's/' + this._currentDossier.id.toString() + '/documents';
        this._redirectionRouteFiche = '/app/' + this._currentDossier.dossierType + 's/' + this._currentDossier.id.toString() + '/edit/general';
        if (!this._currentDossier.isDemandeur()) {
            this._dossierBien = this._currentDossier as ADossierBien;
            this._descriptif = this._dossierBien.bien.getOneDescriptif(Bien.descriptifs.MANDAT);
        }

        this._callToActionService.clicked$.pipe(
            switchMap(callToActionClicked => {
                if (callToActionClicked.action === DossierInotExportActionsMainComponent.actions.SEND) {
                    return this._callToActionService.actionExec$(this.send$());
                }

                return of(undefined!);
            }),
            takeUntil(this._onDestroy$),
        ).subscribe(url => {
            if (url) {
                this._router.navigateByUrl(url);
            }
        });
    }

    ngOnDestroy(): void {
        this._onDestroy$.next();
    }

    filesSelectFilterEnabled(item: unknown): boolean {
        const dossierFile = item as DossierFile;

        if (dossierFile.media.totalBytes > DossierExportFichier.INOT_MAX_SIZE) {
            return false;
        }

        return DossierExportFichier.MEDIA_ALLOWED_MIME_TYPES.some(dossierFileMediaAllowedMimeType => dossierFile.media.mediaType === dossierFileMediaAllowedMimeType.mime_type);
    }

    filesSelectTitleDisabled(item: unknown): string {
        const dossierFile = item as DossierFile;

        if (dossierFile.media.totalBytes > DossierExportFichier.INOT_MAX_SIZE) {
            return 'Les documents envoyés à ' + Etude.enablableModules.INOT.label + ' ne doivent pas dépasser ' + TextsSizeFormat.convertToConvivial(DossierExportFichier.INOT_MAX_SIZE) + '.';
        }

        if (DossierExportFichier.MEDIA_ALLOWED_MIME_TYPES.some(dossierFileMediaAllowedMimeType => dossierFile.media.mediaType === dossierFileMediaAllowedMimeType.mime_type)) {
            return '';
        }

        return 'Les documents envoyés à ' + Etude.enablableModules.INOT.label + ' doivent être de type ' + DossierExportFichier.MEDIA_ALLOWED_CONVIVIAL_MIME_TYPES + '.';
    }

    openSearchDemandeurModal(): void {
        this._modalService.open$<Demandeur>(
            DCDemandeurSelectComponent,
            {},
            {align: 'top', button: 'ok', withPadding: false},
        ).pipe(switchMap(demandeur => {
            if (!demandeur) {
                return of(undefined!);
            }

            this._demandeur = demandeur;

            return this._cContactsGroupMembersService.getFromContactsGroupLink$(demandeur.linkContactsGroup);
        })).subscribe(cContactsGroupMembers => {
            if (cContactsGroupMembers) {
                this._cAcquereursSource.next(cContactsGroupMembers);
            }
        });
    }

    send$(): Observable<string> {
        setTimeout(() => document.querySelector<HTMLButtonElement>('[type=submit]')!.click(), 1);

        return this._sendSource.asObservable().pipe(map(sendSource => sendSource ? this._redirectionRoute : undefined!));
    }

    send(form: NgForm): void {
        let create$: Observable<void>;

        if (!form.valid) {
            create$ = throwError(() => AppLayoutDossierInotExportComponent.errorMessages.OTHER);
        } else if (this._dossierAcquereursSelected.length === 0 && this._dossierProprietairesSelected.length === 0) {
            create$ = throwError(() => AppLayoutDossierInotExportComponent.errorMessages.CONTACTS);
        } else {
            const dossierExportSave = this._dossierExportSaveFactory.createVirgin(this._currentDossier.dossierType, this._currentDossier.uuid);
            const acquereurQualificatedContacts = this._etudeDossierQualifiedContactsService.createFromContactsGroupMembers(
                this._dossierAcquereursSelected,
                EtudeDossierQualifiedContact.qualifications.ACQUEREUR
            );
            const proprietaireQualificatedContacts = this._etudeDossierQualifiedContactsService.createFromContactsGroupMembers(
                this._dossierProprietairesSelected,
                EtudeDossierQualifiedContact.qualifications.PROPRIETAIRE
            );

            dossierExportSave.contacts = [acquereurQualificatedContacts, proprietaireQualificatedContacts].reduce((accumulator, currentValue) => accumulator.concat(currentValue));
            dossierExportSave.destinataire = this._inotUser;
            dossierExportSave.mediaIds = this._dossierFilesSelected.map(dossierFile => dossierFile.media.id);
            create$ = this._dossierExportSaveFactory.create$(dossierExportSave).pipe(
                catchError(error => throwError(() => JSON.stringify(error))),
            );
        }

        create$.pipe(take(1)).subscribe({
            next: () => this._sendSource.next(true),
            error: (comments: string) => {
                this._modalService.openInformation({
                    comments,
                    title: AppLayoutDossierInotExportComponent.errorMessages.TITLE,
                    status: ModalService.statuts.WARNING,
                });
                this._sendSource.next(false);
            }
        });
    }
}
