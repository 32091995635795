import {Component, inject, OnInit} from '@angular/core';
import User from '@models/users/user/user.model';
import {combineLatest, Observable, of, ReplaySubject, Subject, switchMap} from 'rxjs';
import {UserFactory} from '@models/users/user/user.factory';
import {map, take, tap} from 'rxjs/operators';
import {IDynamicComponent} from '@shared/shared.interfaces';
import {EtudeFactory} from '@models/etudes/etude/etude.factory';
import Etude from '@models/etudes/etude/etude.model';
import Site from '@models/sites/site/site.model';
import {CSitesService} from '@models/sites/collection/sites.collection.service';
import {EmailEditService} from '@models/emails/email/email.edit.service';
import {IUserSlideOverData} from '@features/dynamic-components/dc.interfaces';
import {CVentesFactory} from '@models/ventes/collection/ventes.collection.factory';
import CVentes from '@models/ventes/collection/ventes.collection.model';
import {AsyncPipe, NgOptimizedImage} from '@angular/common';
import {TextPhonesComponent} from '@shared/texts/phones/text-phones.component';
import {FaIconComponent} from '@fortawesome/angular-fontawesome';
import {RouterLink} from '@angular/router';
import {AppLoaderComponent} from '@shared/load/loader/loader.component';
import {
    AppDossiersBiensStackedImagesComponent
} from '@features/dossiers/biens/stacked-images/dossiers-biens.stacked-images.component';
import {
    AppCommuneConvivialNameComponent
} from '@features/communes/commune/convivial-name/commune.convivial-name.component';

// @todo Ranger dans le répertoire src/app/features/users
@Component({
    imports: [
        AppCommuneConvivialNameComponent,
        AppDossiersBiensStackedImagesComponent,
        AppLoaderComponent,
        AsyncPipe,
        FaIconComponent,
        NgOptimizedImage,
        RouterLink,
        TextPhonesComponent,
    ],
    selector: 'dc-user-slide-over',
    templateUrl: 'dc-user.slide-over.component.html',
})
export class DCUserSlideOverComponent implements IDynamicComponent, OnInit {
    private _cSitesService = inject(CSitesService);
    private _cVentesFactory = inject(CVentesFactory);
    private _emailEditService = inject(EmailEditService);
    private _etudeFactory = inject(EtudeFactory);
    private _userFactory = inject(UserFactory);
    private _cVentesSource = new Subject<CVentes>();
    private _cVentes$ = this._cVentesSource.asObservable();
    private _etudeSource = new Subject<Etude>();
    private _etude$ = this._etudeSource.asObservable();
    private _sitePrincipalSource = new Subject<Site>();
    private _sitePrincipal$ = this._sitePrincipalSource.asObservable();
    private _userSource = new ReplaySubject<User>(1);
    private _user$ = this._userSource.asObservable();

    get cVentes$(): Observable<CVentes> {
        return this._cVentes$;
    }

    set data(dynamicComponentData: IUserSlideOverData) {
        let user$ = of(dynamicComponentData.user!);

        if (dynamicComponentData.link) {
            user$ = this._userFactory.getByLink$(dynamicComponentData.link);
        }

        user$.pipe(take(1)).subscribe(user => this._userSource.next(user));
    }

    get etude$(): Observable<Etude> {
        return this._etude$;
    }

    get sitePrincipal$(): Observable<Site> {
        return this._sitePrincipal$;
    }

    get user$(): Observable<User> {
        return this._user$;
    }

    ngOnInit(): void {
        this._user$.pipe(
            switchMap(user => this._etudeFactory.getByLink$(user.linkEtude).pipe(map(etude => ({etude, user})))),
            tap(({etude}) => this._etudeSource.next(etude)),
            switchMap(({etude, user}) => combineLatest([
                this._cSitesService.getSitePrincipal$(etude.linkSites).pipe(tap(sitePrincipal => this._sitePrincipalSource.next(sitePrincipal))),
                this._cVentesFactory.getByLink$(user.linkVentes).pipe(tap(cDossierBiens => this._cVentesSource.next(cDossierBiens))),
            ])),
            take(1),
        ).subscribe();
    }

    sendMail(user: User): void {
        this._emailEditService.fromUser$(user).pipe(take(1)).subscribe();
    }
}
