export default `
<div class="container-fluid bg-triangle app-start">
  <div class="container">
    <div class="row">
      <div class="col-xs-8 col-xs-offset-2 col-sm-6 col-sm-offset-3">
        <h2>Bienvenue sur</h2>

        <div class="tw:my-8 tw:bg-septeo-blue-900 tw:rounded-2xl tw:shadow-lg tw:shadow-septeo-blue-900 tw:text-center">
            <app-ng2-img [src]="'assets/images/noty/logo.svg'" [width]="'90%'"></app-ng2-img>
        </div>
      </div>

      <div class="col-xs-12 col-sm-6 col-sm-offset-3">
        <h4 ng-if="!$ctrl.error.message">
          <fa-icon [icon]="'spinner'" animation="spin"></fa-icon>

          Merci de patienter pendant le chargement des données ...
        </h4>

        <p ng-if="$ctrl.error.message">
          <span class="text-danger">{{$ctrl.error.message}}</span><br>
          <span class="text-muted">{{$ctrl.error.details}}</span>
        </p>

        <hr>

        <h4 ng-class="{'text-success': $ctrl.loaded.authentication}">
          <fa-icon [icon]="['far', 'check-circle']"></fa-icon> Authentification
        </h4>

        <h4 ng-class="{'text-success': $ctrl.loaded.currentProfile}">
          <fa-icon [icon]="['far', 'check-circle']"></fa-icon> Récupération des informations de votre profil
        </h4>

        <h4 ng-class="{'text-success': $ctrl.loaded.dictionaries}">
          <fa-icon [icon]="['far', 'check-circle']"></fa-icon> Récupération des dictionnaires de données
        </h4>
      </div>
    </div>
  </div>
</div>
`;
