<div class="tw:grid tw:grid-cols-1 tw:sm:grid-cols-2 tw:lg:grid-cols-3 tw:grid-flow-dense tw:gap-8">
  @if (isPartAgencementInterieurVisible) {
    <div class="tw:sm:col-span-2"><app-dossier-bien-overview-agencement-interieur [dossierBien]="dossierBien"/></div>
  }

  @if (isPartBatimentVisible) {
    <div class="tw:sm:col-span-2"><app-dossier-bien-overview-batiment [dossierBien]="dossierBien"/></div>
  }

  @if (isPartPerformanceEnergetiqueVisible) {
    <div class="tw:sm:col-span-2"><app-dossier-bien-overview-performance-energetique [dossierBien]="dossierBien"/></div>
  }

  @if (isPartTerrainVisible) {
    <div class="tw:sm:col-span-2"><app-dossier-bien-overview-terrain [dossierBien]="dossierBien"/></div>
  }

  @if (isPartAccessibiliteEnvironnementVisible) {
    <div class="tw:sm:col-span-2">
      <app-dossier-bien-overview-accessibilite-environnement [dossierBien]="dossierBien"/>
    </div>
  }

  @if (isPartAgencementExterieurVisible) {
    <div><app-dossier-bien-overview-agencement-exterieur [dossierBien]="dossierBien"/></div>
  }

  @if (isPartViabilisationVisible) {
    <div><app-dossier-bien-overview-viabilisation [dossierBien]="dossierBien"/></div>
  }

  @if (isPartDiagnosticsVisible) {
    <div><app-dossier-bien-overview-diagnostics [dossierBien]="dossierBien"/></div>
  }

  @if (isPartLoiAlurVisible) {
    <div><app-dossier-bien-overview-loi-alur [dossierBien]="dossierBien"/></div>
  }

  @if (isPartHabitationVisible) {
    <div><app-dossier-bien-overview-habitation [dossierBien]="dossierBien"/></div>
  }

  @if (isPartCoutsVisible) {
    <div><app-dossier-bien-overview-couts [dossierBien]="dossierBien"/></div>
  }

  @if (isPartRisquesVisible) {
    <div><app-dossier-bien-overview-risques [dossierBien]="dossierBien"/></div>
  }

  @if (isPartUrbanismeVisible) {
    <div><app-dossier-bien-overview-urbanisme [dossierBien]="dossierBien"/></div>
  }

  @if (dossierBien.bien.commentsConstruction) {
    <div class="tw:sm:col-span-2 tw:flex tw:flex-col tw:gap-2">
      <h4 class="tw:px-3 tw:lg:px-4">Autres</h4>

      <div class="tw:flex-1 tw:w-full tw:bg-gray-100 tw:rounded-xl tw:p-3 tw:lg:p-4">
        {{ dossierBien.bien.commentsConstruction }}
      </div>
    </div>
  }
</div>
