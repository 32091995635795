import {inject, Injectable} from '@angular/core';
import Links from '@models/links/links.model';
import {ICEtudeNetworksApi} from '@models/etudes/etude/networks/collection/etude-networks.collection.interfaces';
import CEtudeNetworks from '@models/etudes/etude/networks/collection/etude-networks.collection.model';
import {EtudeNetworksApiService} from '@models/etudes/etude/networks/etude-networks.api.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {EtudeNetworksFactory} from '@models/etudes/etude/networks/etude-networks.factory';

@Injectable({providedIn: 'root'})
export class CEtudeNetworksFactory {
    private _etudeNetworksApiService = inject(EtudeNetworksApiService);
    private _etudeNetworksFactory = inject(EtudeNetworksFactory);

    create(cEtudeNetworksApi: ICEtudeNetworksApi): CEtudeNetworks {
        const cEtudeNetworks = new CEtudeNetworks();

        cEtudeNetworks.links = new Links(cEtudeNetworksApi._links);
        cEtudeNetworks.page = cEtudeNetworksApi.page;
        cEtudeNetworks.pages = cEtudeNetworksApi.pages;
        cEtudeNetworks.perPage = cEtudeNetworksApi.limit;
        cEtudeNetworks.total = cEtudeNetworksApi.total;
        if (cEtudeNetworksApi._embedded) {
            cEtudeNetworks.results = this._etudeNetworksFactory.create(cEtudeNetworksApi._embedded.items);
        }

        return cEtudeNetworks;
    }

    get$(): Observable<CEtudeNetworks> {
        return this._etudeNetworksApiService.getCollection$().pipe(map(cEtudeNetworksApi => this.create(cEtudeNetworksApi)));
    }
}
