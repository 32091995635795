export default `
<div class="references-import">
  <div class="modal-header">
    <button type="button" class="btn btn-default pull-right" ng-click="$dismiss()"
            title="Fermer sans importer des références"><fa-icon [icon]="'times'"></fa-icon></button>

    <h3 class="modal-title"><fa-icon [icon]="'house'"></fa-icon> Importer des références</h3>
  </div>

  <div class="modal-body">
    <ng-switch on="$ctrl.references.total > 0">
      <ng-switch on="$ctrl.file._esk.status" ng-switch-default>
        <div ng-switch-default>
          <div class="form-group">
            <label>Nature avec laquelle les références vont être créées</label>

            <esk-select-dictionary dictionary="{{$ctrl.NATURES}}" ng-model="$ctrl.nature" is-required="true">
            </esk-select-dictionary>
          </div>

          <soq-input-file on-change="$ctrl.handleFileInput" id="input_file" options="{multiple: false}"
                          id-dnd-field="input_file" file-type="classeur" files-max-size="1048576">
          </soq-input-file>

          <ng-include
            src="'src/app/legacy/templates/estimations/controllers/modals/item/references.import/contact.html'">
          </ng-include>
        </div>

        <div ng-switch-when="IN_PROGRESS">
          <label>
            <fa-icon [icon]="'spinner'" animation="spin"></fa-icon> Envoi en cours

            <span class="text-muted">{{$ctrl.file.size / 1024 | number:2}} ko</span>
          </label>

          <uib-progressbar class="progress-striped" value="$ctrl.file._esk.progress">
            {{$ctrl.file._esk.progress}} %
          </uib-progressbar>
        </div>

        <div ng-switch-when="ERROR">
          <label>Erreur</label>

          <p class="text-danger">
            {{$ctrl.file._esk.error.errors[0].message}}

            <span class="text-danger cursor-pointer" ng-click="$ctrl.file = undefined">
              <fa-icon [icon]="'times'"></fa-icon>
            </span>
          </p>

          <ng-include
            src="'src/app/legacy/templates/estimations/controllers/modals/item/references.import/contact.html'">
          </ng-include>
        </div>
      </ng-switch>

      <div ng-switch-when="true" class="text-extra-large tw:text-center text-success">
        {{'estimation.references.import.MESSAGE' | translate:'{nbReferences: ' + $ctrl.references.total + '}'}}
      </div>
    </ng-switch>
  </div>

  <div class="modal-footer">
    <div ng-if="$ctrl.references.total > 0">
      <button ng-if="$ctrl.references.total > 1" type="button" class="btn btn-primary btn-o"
              ng-click="$ctrl.selectReferences()">
        <fa-icon [icon]="'check'"></fa-icon> {{'estimation.references.import.CHOICE' | translate}}
      </button>

      <button type="button" class="btn btn-primary" ng-click="$ctrl.linkReferences()">
        <fa-icon [icon]="'link'"></fa-icon>

        {{'estimation.references.import.LINK' | translate:'{nbReferences: ' + $ctrl.references.total + '}'}}
      </button>
    </div>
  </div>
</div>
`;
