@if (cEmailDossiers$ | async; as cEmailDossiers) {
  @if (cEmailDossiers.total > 0) {
    <div>
      <div class="titre" [ngPlural]="cEmailDossiers.total">
        <ng-template ngPluralCase="=1">Dossier attaché</ng-template>
        <ng-template ngPluralCase="other">Dossiers attachés</ng-template>
      </div>

      <ul class="tw:flex tw:flex-col tw:gap-4">
        @for (emailDossier of cEmailDossiers.results; track emailDossier) {
          <li class="tw:flex tw:flex-col tw:gap-2">
            <div class="tw:flex tw:justify-between">
              <div>{{ emailDossier[emailDossier.type]?.reference }}</div>

              <div class="tw:italic tw:text-gray-400">{{ emailDossier.type | titlecase }}</div>
            </div>

            <div class="tw:font-bold tw:truncate">{{ emailDossier[emailDossier.type]?.contactsGroup?.nom }}</div>
          </li>
        }
      </ul>
    </div>
  } @else {
    Aucun dossier attaché
  }
} @else {
  <app-loader [classHeight]="'tw:h-20'" class="tw:block"/>
}
