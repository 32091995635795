import {inject, Injectable} from '@angular/core';
import {EmailDossierFactory} from '@models/emails/email/dossiers/dossier/email-dossier.factory';
import {IEmailDossierApi} from '@models/emails/email/dossiers/dossier/email-dossier.interfaces';
import EmailDossier from '@models/emails/email/dossiers/dossier/email-dossier.model';

@Injectable({providedIn: 'root'})
export class EmailDossiersFactory {
    private _emailDossierFactory = inject(EmailDossierFactory);

    create(emailDossierApis: IEmailDossierApi[] = []): EmailDossier[] {
        return (emailDossierApis || []).filter(emailDossierApi => emailDossierApi)
            .map(emailDossierApi => this._emailDossierFactory.create(emailDossierApi));
    }
}
