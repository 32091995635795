import {NgModule} from '@angular/core';
import {AppUsersSelectComponent} from '@features/users/select/users-select.component';
import {AppUsersCheckboxComponent} from '@features/users/checkbox/users-checkbox.component';
import {AppUsersStackedImagesComponent} from '@features/users/stacked-images/users.stacked-images.component';
import {AppUsersListComponent} from '@features/users/list/users.list.component';
import {AppUserCardComponent} from '@features/users/user/card/user.card.component';
import {AppUserCartoucheComponent} from '@features/users/user/cartouche/user.cartouche.component';

@NgModule({
    exports: [
        AppUserCardComponent,
        AppUserCartoucheComponent,
        AppUsersCheckboxComponent,
        AppUsersListComponent,
        AppUsersSelectComponent,
        AppUsersStackedImagesComponent,
    ],
    imports: [
        AppUserCardComponent,
        AppUserCartoucheComponent,
        AppUsersCheckboxComponent,
        AppUsersListComponent,
        AppUsersSelectComponent,
        AppUsersStackedImagesComponent,
    ],
})
export class UsersModule {
}
