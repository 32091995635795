import {inject, Injectable} from '@angular/core';
import Links from '@models/links/links.model';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {
    ICLocationPasserellesApi
} from '@models/locations/location/passerelles/collection/location-passerelles.collection.interfaces';
import CLocationPasserelles from '@models/locations/location/passerelles/collection/location-passerelles.collection.model';
import {LocationPasserellesApiService} from '@models/locations/location/passerelles/location-passerelles.api.service';
import Passerelle from '@models/passerelle/passerelle.model';
import {LocationPasserellesFactory} from '@models/locations/location/passerelles/location-passerelles.factory';

@Injectable({providedIn: 'root'})
export class CLocationPasserellesFactory {
    private _locationPasserellesApiService = inject(LocationPasserellesApiService);
    private _locationPasserellesFactory = inject(LocationPasserellesFactory);

    create(cLocationPasserellesApi: ICLocationPasserellesApi): CLocationPasserelles {
        const cLocationPasserelles = new CLocationPasserelles();

        cLocationPasserelles.links = new Links(cLocationPasserellesApi._links);
        cLocationPasserelles.page = cLocationPasserellesApi.page;
        cLocationPasserelles.pages = cLocationPasserellesApi.pages;
        cLocationPasserelles.perPage = cLocationPasserellesApi.limit;
        cLocationPasserelles.total = cLocationPasserellesApi.total;
        if (cLocationPasserellesApi._embedded) {
            cLocationPasserelles.results = this._locationPasserellesFactory.create(cLocationPasserellesApi._embedded.items);
        }

        return cLocationPasserelles;
    }

    getFromPasserelle$(passerelle: Passerelle): Observable<CLocationPasserelles> {
        return this._locationPasserellesApiService.getCollectionFromPasserelle$(passerelle.id.toString()).pipe(map(cLocationPasserellesApi => this.create(cLocationPasserellesApi)));
    }

    getNext$(cLocationPasserelles: CLocationPasserelles): Observable<CLocationPasserelles> {
        return this._locationPasserellesApiService.getCollectionByLink$(cLocationPasserelles.links.next)
            .pipe(map(cLocationPasserellesApi => this.create(cLocationPasserellesApi)));
    }
}
