export default `
<div ng-switch="$ctrl.options.loader.updating" class="container-fluid container-fullw tw:bg-white">
  <p class="tw:text-center text-extra-large" ng-switch-when="true">
    <fa-icon [icon]="'spinner'" animation="spin"></fa-icon> Chargement des résultats ...
  </p>

  <div ng-switch-default>
    <!-- Actions sur la liste -->
    <div class="row margin-bottom-10">
      <div class="col-xs-12 col-sm-offset-7 col-sm-5 col-md-offset-4 col-md-3 col-lg-offset-5">
        <esk-select-sort ng-if="!$ctrl.options.loader.filter" type="{{$ctrl.options.typeSort}}"
                         ng-model="$ctrl.filter.queryParameters.sort" on-select="$ctrl.sortDemandeurs">
        </esk-select-sort>
      </div>

      <!-- No results -->
      <h4 ng-if="$ctrl.demandeurs.totalItems <= 0" class="col-sm-12 margin-top-30 tw:text-center">
        Aucun demandeur correspondant à afficher.
      </h4>
    </div>

    <!-- Results -->
    <div ng-if="$ctrl.demandeurs.totalItems > 0">
      <!-- Liste des demandeurs -->
      <esk-demandeurs-list filter="$ctrl.filter" demandeurs="$ctrl.demandeurs" on-click="$ctrl.onClick"
                           unselect-all="$ctrl.unselectAll" options="$ctrl.options"></esk-demandeurs-list>

      <!-- Actions et Pagination -->
      <div class="row">
        <!-- Pagination -->
        <esk-pagination paginator="$ctrl.demandeurs" loader="$ctrl.options.loader"
                        class="col-sm-offset-4 col-sm-4 tw:text-center"></esk-pagination>
      </div>

      <!--Barre de faire-->
      <div ng-if="$ctrl.getSelectedDemandeurs().length > 0" class="barre-faire">
        <div class="dossiers-select-label">
          {{'demandeur.grouped_action.selection.MESSAGE' | translate:'{nbDossiers: ' +
        $ctrl.getSelectedDemandeurs().length + '}'}}
        </div>

        <div ng-switch="!!$ctrl.barreFaireLoader" class="dossiers-select-actions">
          <p ng-switch-when="true" class="loading text-large">
            <fa-icon [icon]="'spinner'" animation="spin"></fa-icon> {{$ctrl.barreFaireLoader | translate}}
          </p>

          <div ng-switch-default class="actions">
            <div class="btn-group dropup" uib-dropdown>
              <button type="button" class="btn btn-dropdown" ng-click="$ctrl.setInterest('interested')">
                <fa-icon [icon]="'check'"></fa-icon>

                <span class="btn-label">Marquer comme intéressants</span>
              </button>

              <button type="button" class="btn" uib-dropdown-toggle><span class="caret"></span></button>

              <ul class="dropdown-menu" uib-dropdown-menu role="menu">
                <li role="menuitem">
                  <button type="button" class="tw:dropdown-link" ng-click="$ctrl.setInterest($ctrl.DISINTERESTED)">
                    <fa-icon [icon]="'times'"></fa-icon><span>Marquer comme inintéressants</span>
                  </button>
                </li>

                <li class="divider"></li>

                <li role="menuitem">
                  <button type="button" ng-click="$ctrl.setInterest($ctrl.DISINTERESTED_EXCEPT_PRICE)"
                          class="tw:dropdown-link">
                    <fa-icon [icon]="'caret-right'" size="lg"></fa-icon>

                    <span>Marquer comme intéressants si baisse de prix</span>
                  </button>
                </li>
              </ul>
            </div>

            <button type="button" class="btn" ng-click="$ctrl.send()" title="Envoyer la fiche du bien aux demandeurs">
              <fa-icon [icon]="['far', 'envelope']"></fa-icon> <span class="btn-label">Envoyer la fiche du bien</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
`;
