export default `
<div class="modal-header">
  <button type="button" class="btn btn-default pull-right" ng-click="$dismiss()"
          title="Fermer sans prendre en compte les modifications"><fa-icon [icon]="'times'"></fa-icon></button>

  <h3 class="modal-title"><fa-icon [icon]="'filter'"></fa-icon> Filtrer votre liste de demandeurs</h3>
</div>

<form name="$ctrl.editForm" ng-submit="$ctrl.save()" novalidate>
  <div class="modal-body">
    <!-- Nom -->
    <div ng-if="!$ctrl.filter.current" class="row">
      <div class="col-xs-offset-3 col-xs-6 form-group"
           ng-class="{'has-error': $ctrl.editForm.$submitted && $ctrl.editForm.nom.$invalid}">
        <input type="text" name="nom" class="form-control text-large" ng-model="$ctrl.filter.nom"
               placeholder="Saisir un nom pour le filtre" required style="text-align: center;">

        <div class="error text-small tw:block" ng-messages="$ctrl.editForm.nom.$error"
             ng-show="$ctrl.editForm.$submitted">
          <ng-messages-include src="messages.html"></ng-messages-include>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-xs-12 col-md-6">
        <div class="row">
          <!-- Type recherche -->
          <div ng-if="$ctrl.hasRoleLocation" class="col-sm-6 form-group">
            <p>Type de transaction</p>

            <app-dictionary-checkbox [model]="$ctrl.transactionTypes" [name]="$ctrl.TRANSACTION_TYPES"
                                     (selected)="$ctrl.onSelectTransactionTypes($event)"></app-dictionary-checkbox>
          </div>

          <!-- Statut -->
          <div class="col-sm-6 form-group">
            <p>{{"Statut des dossiers"|translate}}</p>

            <div class="radio clip-radio radio-primary">
              <input type="radio" id="statutDemandeurs{{indifferent}}" name="statutDemandeurs"
                     ng-model="$ctrl.filter.queryParameters.statut.values[0]" ng-value="null">

              <label for="statutDemandeurs{{indifferent}}">{{"Indifférent" | translate}}</label>
            </div>

            <div class="radio clip-radio radio-primary"
                 ng-repeat="statutDemandeurs in $ctrl.options.statutsDemandeurs">
              <input type="radio" id="statutDemandeurs{{statutDemandeurs.id}}"
                     ng-model="$ctrl.filter.queryParameters.statut.values[0]" name="statutDemandeurs"
                     ng-value="statutDemandeurs.code">

              <label for="statutDemandeurs{{statutDemandeurs.id}}">{{statutDemandeurs.label}}</label>
            </div>

            <div ng-if="$ctrl.filter.queryParameters.statut.values[0] === $ctrl.CODE_STATUTS_EN_RECHERCHE" class="padding-left-15">
              <app-dictionary-checkbox [model]="$ctrl.demandeursRecherchesStatuts"
                                       (selected)="$ctrl.onSelectDemandeursRecherchesStatuts($event)"
                                       [name]="$ctrl.DEMANDEUR_RECHERCHE_STATUTS"></app-dictionary-checkbox>
            </div>
          </div>
        </div>

        <div class="row">
          <!-- Date de création -->
          <div class="col-sm-6">
            <p>{{"Date de création"|translate}}</p>

            <div class="radio clip-radio radio-primary">
              <input type="radio" id="date-creation-null" name="date-creation" ng-value="null"
                     ng-model="$ctrl.filter.queryParameters.dateCreation.value">

              <label for="date-creation-null">{{"Indifférent" | translate}}</label>
            </div>

            <div class="radio clip-radio radio-primary">
              <input type="radio" id="date-creation-min-3" name="date-creation" ng-value="0"
                     ng-model="$ctrl.filter.queryParameters.dateCreation.value">

              <label for="date-creation-min-3">{{"- de 3 mois" | translate}}</label>
            </div>

            <div class="radio clip-radio radio-primary">
              <input type="radio" id="date-creation-3-6" name="date-creation" ng-value="1"
                     ng-model="$ctrl.filter.queryParameters.dateCreation.value">

              <label for="date-creation-3-6">{{"3 - 6 mois" | translate}}</label>
            </div>

            <div class="radio clip-radio radio-primary">
              <input type="radio" id="date-creation-plus-6" name="date-creation" ng-value="2"
                     ng-model="$ctrl.filter.queryParameters.dateCreation.value">

              <label for="date-creation-plus-6">{{"+ de 6 mois" | translate}}</label>
            </div>
          </div>

          <!-- Date de modification -->
          <div class="col-sm-6">
            <p>{{"Date de modification"|translate}}</p>

            <div class="radio clip-radio radio-primary">
              <input type="radio" id="date-modification-null" name="date-modification" ng-value="null"
                     ng-model="$ctrl.filter.queryParameters.recherches.dateModification.value">

              <label for="date-modification-null">{{"Indifférent" | translate}}</label>
            </div>

            <div class="radio clip-radio radio-primary">
              <input type="radio" id="date-modification-min-3" name="date-modification" ng-value="0"
                     ng-model="$ctrl.filter.queryParameters.recherches.dateModification.value">

              <label for="date-modification-min-3">{{"- de 3 mois" | translate}}</label>
            </div>

            <div class="radio clip-radio radio-primary">
              <input type="radio" id="date-modification-3-6" name="date-modification" ng-value="1"
                     ng-model="$ctrl.filter.queryParameters.recherches.dateModification.value">

              <label for="date-modification-3-6">{{"3 - 6 mois" | translate}}</label>
            </div>

            <div class="radio clip-radio radio-primary">
              <input type="radio" id="date-modification-plus-6" name="date-modification" ng-value="2"
                     ng-model="$ctrl.filter.queryParameters.recherches.dateModification.value">

              <label for="date-modification-plus-6">{{"+ de 6 mois" | translate}}</label>
            </div>
          </div>

          <!-- Budget -->
          <div class="col-xs-12">
            <div class="form-group">
              <p>{{"Budget"|translate}}</p>

              <div class="input-group input-group-number pull-left margin-bottom-10">
                <input type="text" class="form-control" placeholder="{{'Minimum'|translate}}"
                       ng-model="$ctrl.filter.queryParameters.recherches.prixVenteMax.min" soq-only-digits>

                <span class="input-group-addon">€</span>
              </div>

              <div class="input-group input-group-number pull-left margin-bottom-10">
                <input type="text" class="form-control" placeholder="{{'Maximum'|translate}}"
                       ng-model="$ctrl.filter.queryParameters.recherches.prixVenteMin.max" soq-only-digits>

                <span class="input-group-addon">€</span>
              </div>
            </div>
          </div>

          <!-- Type de demandeurs -->
          <div class="col-xs-12">
            <p>Type de demandeur</p>

            <esk-select-dictionary ng-model="$ctrl.filter.queryParameters.typeDemandeur.values" is-multiple
                                   dictionary="{{$ctrl.DEMANDEUR_TYPES}}"></esk-select-dictionary>
          </div>
        </div>
      </div>

      <!-- Filtre sur les natures -->
      <div class="col-xs-12 col-md-3">
        <p>Natures</p>

        <app-dictionary-checkbox [model]="$ctrl.natures" [name]="$ctrl.NATURES"
                                 (selected)="$ctrl.onSelectNatures($event)"></app-dictionary-checkbox>
      </div>

      <!--Filtres sur les responsable de dossier-->
      <div ng-if="$ctrl.currentEtude.id > 0" class="col-xs-12 col-md-3">
        <p>Responsables dossier</p>

        <app-users-checkbox [model]="$ctrl.responsableDossiers" [roles]="$ctrl.usersRoles"
                            [etudes]="[$ctrl.currentEtude]" (selected)="$ctrl.onSelectResponsables($event)">
        </app-users-checkbox>
      </div>
    </div>
  </div>

  <!-- Actions -->
  <div class="modal-footer" ng-switch="!!$ctrl.loading">
    <p class="text-muted" ng-switch-when="true">
      <fa-icon [icon]="'spinner'" animation="spin"></fa-icon> {{'global.en_cours.' + $ctrl.loading | translate}}
    </p>

    <ng-switch on="$ctrl.filter.current || !$ctrl.filter.id" ng-switch-default>
      <button ng-switch-when="false" type="button" class="btn btn-o btn-danger pull-left" ng-click="$ctrl.remove()">
        <fa-icon [icon]="'trash-alt'"></fa-icon> Supprimer
      </button>

      <button ng-switch-default type="button" class="btn btn-o btn-danger pull-left" ng-click="$ctrl.filter.raz()">
        Réinitialiser
      </button>

      <button class="btn btn-primary" type="submit"><fa-icon [icon]="'check'"></fa-icon> Appliquer</button>
    </ng-switch>
  </div>
</form>
`;
