export default `
<div class="configuration-passerelles">
  <div class="row">
    <div ng-repeat="passerelle in $ctrl.passerelles" class="col-xs-12 col-sm-6">
      <div class="panel panel-white passerelle">
        <div class="panel-heading border-light">
          <div class="row media">
            <div ng-if="!!passerelle.urlLogo" class="col-sm-2 media-left media-middle">
              <div class="media-object"><img ng-src="{{passerelle.urlLogo}}" loading="lazy"></div>
            </div>

            <div class="media-body tw:text-center">
              <h4 class="media-heading panel-title">
                <div>
                  <span class="text-bold">{{passerelle.passerelle.name}}</span>

                  <a ng-if="passerelle.urlSite" ng-href="{{passerelle.urlSite}}" target="_blank">
                    <fa-icon [icon]="'external-link-alt'"></fa-icon>
                  </a>
                </div>

                <esk-passerelle-contrat passerelle="passerelle"
                                        ng-if="(passerelle.type === 'social_network' && !passerelle.instantDiffusion) || passerelle.type === 'website'">
                </esk-passerelle-contrat>
              </h4>
            </div>
          </div>
        </div>

        <div class="panel-body">
          <ng-switch on="passerelle.configurator">
            <esk-configuration-facebook ng-switch-when="facebook"
                                        etude-passerelle="passerelle"></esk-configuration-facebook>

            <esk-configuration-facebook ng-switch-when="instagram"
                                        etude-passerelle="passerelle"></esk-configuration-facebook>
          </ng-switch>
        </div>
      </div>
    </div>
  </div>
</div>
`;
