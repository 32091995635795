import {inject, Injectable} from '@angular/core';
import {DossierFileFactory} from '@models/dossiers/dossier/files/file/dossier-file.factory';
import {IDossierFileApi} from '@models/dossiers/dossier/files/file/dossier-file.interfaces';
import DossierFile from '@models/dossiers/dossier/files/file/dossier-file.model';

@Injectable({providedIn: 'root'})
export class DossierFilesFactory {
    private _dossierFileFactory = inject(DossierFileFactory);

    create(dossierFileApis: IDossierFileApi[] = []): DossierFile[] {
        return (dossierFileApis || []).filter(dossierFileApi => dossierFileApi)
            .map(dossierFileApi => this._dossierFileFactory.create(dossierFileApi));
    }
}
