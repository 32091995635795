@if (user$ | async; as user) {
  <form class="tw:form-2col" #dossiersForm="ngForm" (submit)="save()">
    @if (user.hasRoleGrpVente()) {
      <div class="tw:form-2col-part">
        <div class="tw:form-left-col">
          <h3 class="tw:form-left-col-title">Biens à vendre</h3>

          <p class="tw:form-left-col-explain">
            Lors de la création d'un bien en vente ou quand il y a besoin d'une nature, <app-noteo-icon-text-noty/>
            sélectionnera automatiquement celle paramétrée.
          </p>
        </div>

        <div class="tw:form-right-col">
          <div [appFormError]="ventesNatureForm.formInput" class="tw:w-48">
            <label>Nature *</label>

            <app-dictionary-select-items #ventesNatureForm="dictionarySelect" [name]="DICTIONARY_NAME_NATURES"
                                         [model]="user.settings.ventes.defaultNature"
                                         [options]="{name: 'nature-ventes', required: true}"
                                         (selected)="selectDefaultNature('ventes', $event)"/>
          </div>
        </div>
      </div>
    }

    @if (user.hasRoleGrpLocation()) {
      <div class="tw:form-2col-part">
        <div class="tw:form-left-col">
          <h3 class="tw:form-left-col-title">Locations</h3>

          <p class="tw:form-left-col-explain">
            Lors de la création d'un bien en location ou quand il y a besoin d'une nature, <app-noteo-icon-text-noty/>
            sélectionnera automatiquement celle paramétrée.
          </p>
        </div>

        <div class="tw:form-right-col">
          <div [appFormError]="locationsNatureForm.formInput" class="tw:w-48">
            <label>Nature *</label>

            <app-dictionary-select-items #locationsNatureForm="dictionarySelect" [name]="DICTIONARY_NAME_NATURES"
                                         [options]="{name: 'nature-locations', required: true}"
                                         (selected)="selectDefaultNature('locations', $event)"
                                         [model]="user.settings.locations.defaultNature"/>
          </div>
        </div>
      </div>
    }

    @if (user.hasRoleGrpEstimation()) {
      <div class="tw:form-2col-part">
        <div class="tw:form-left-col">
          <h3 class="tw:form-left-col-title">Estimations</h3>

          <p class="tw:form-left-col-explain">
            Lors de la création d'un bien en estimation ou quand il y a besoin d'une nature, <app-noteo-icon-text-noty/>
            sélectionnera automatiquement celle paramétrée.
          </p>
        </div>

        <div class="tw:form-right-col">
          <div [appFormError]="estimationsNatureForm.formInput" class="tw:w-48">
            <label>Nature *</label>

            <app-dictionary-select-items #estimationsNatureForm="dictionarySelect" [name]="DICTIONARY_NAME_NATURES"
                                         [model]="user.settings.estimations.defaultNature"
                                         (selected)="selectDefaultNature('estimations', $event)"
                                         [options]="{name: 'nature-estimations', required: true}"/>
          </div>
        </div>
      </div>
    }

    @if (user.hasRoleDemandeur()) {
      <div class="tw:form-2col-part">
        <div class="tw:form-left-col">
          <h3 class="tw:form-left-col-title">Demandes</h3>

          <p class="tw:form-left-col-explain">
            Lors de la création d'une recherche ou quand il y a besoin d'une nature, <app-noteo-icon-text-noty/>
            sélectionnera automatiquement celle paramétrée.
          </p>
        </div>

        <div class="tw:form-right-col">
          <div [appFormError]="demandeursNatureForm.formInput" class="tw:w-48">
            <label>Nature *</label>

            <app-dictionary-select-items #demandeursNatureForm="dictionarySelect" [name]="DICTIONARY_NAME_NATURES"
                                         [model]="user.settings.demandeurs.defaultNature"
                                         (selected)="selectDefaultNature('demandeurs', $event)"
                                         [options]="{name: 'nature-demandeurs', required: true}"/>
          </div>
        </div>
      </div>
    }

    <button type="submit" class="tw:hidden"></button>
  </form>
} @else {
  <app-loader [classHeight]="'tw:h-40'" class="tw:block"/>
}
