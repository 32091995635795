<div class="tw:flex tw:flex-col tw:gap-2">
  <h4 class="tw:px-3 tw:lg:px-4">Coût{{ dossierBien.bien.charges.length > 0 ? 's' : '' }}</h4>

  <div class="tw:bg-gray-100 tw:rounded-xl tw:flex tw:flex-col tw:gap-4 tw:p-3 tw:lg:p-4">
    @if (isChargesVisible) {
      <ul class="tw:grid tw:gap-2 tw:text-sm tw:text-gray-700 tw:font-medium">
        @for (bienCharge of dossierBien.bien.charges; track bienCharge) {
          <li>
            {{ bienCharge.charge.label }}

            @if (bienCharge.cout > 0) {
              <span class="tw:text-gray-500 tw:text-xs tw:font-normal">de</span> {{ bienCharge.cout | textCurrency }}

              @if (bienCharge.periodicite) {
                <span class="tw:text-gray-500 tw:text-xs tw:font-normal">par</span>
                {{ bienCharge.periodicite.duree | lowercase }}
              }
            } @else {
              @if (bienCharge.periodicite) {
                {{ bienCharge.periodicite.label | lowercase }}
              }
            }
            @if (bienCharge.comments) {
              <div class="tw:text-gray-500 tw:font-normal">{{ bienCharge.comments }}</div>
            }
          </li>
        }
      </ul>
    }

    @if (isCommentsCoutsVisible) {
      <div class="tw:text-sm tw:text-gray-700">{{ dossierBien.bien.commentsCouts }}</div>
    }
  </div>
</div>
