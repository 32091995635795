@if (procedure$ | async; as procedure) {
  <div class="tw:h-full">
    @if (procedure.isNewOrDraft() || procedure.isDistanciel()) {
      <form class="tw:pb-8"
            (submit)="save(signatairesButtonSubmit)" #signatairesForm="ngForm">
        <app-procedure-signataires-edit [currentDossier]="currentDossier" [options]="procedureSignatairesEditOptions"
                                        (deletedSignataires)="deletedSignataires = $event" [procedure]="procedure"
                                        (editedSignataires)="editedSignataires = $event"
                                        [cSignataires$]="cProcedureSignataires$"/>

        <button type="submit" class="tw:float-left" #signatairesButtonSubmit></button>
      </form>
    } @else {
      <app-procedure-presentiel-sign [cSignataires$]="cProcedureSignataires$" [procedure]="procedure"
                                     (signed)="signed()"/>
    }
  </div>
} @else {
  <app-loader [classHeight]="'tw:h-40'" class="tw:block tw:mb-8"/>
}
