<div class="tw:mb-5"><h3><fa-icon [icon]="'edit'"/> Édition des sanitaires</h3></div>

<form (ngSubmit)="save(editForm)" #editForm="ngForm"
  class="tw:w-[80vw] tw:sm:w-[30rem] tw:md:w-[40rem] tw:lg:w-[50rem]">
  <div class="tw:py-6">
    <div class="row">
      <!-- Nombre de WC -->
      @if (showFieldForBien('nombreWc')) {
        <div class="col-xs-12 col-sm-6">
          <div class="form-group" [appFormError]="nombreWc">
            <label for="bien-nombre-wc">Nombre de WC</label>

            <input type="text" id="bien-nombre-wc" class="form-control text-right" pattern="[0-9]+" min="0"
                   name="nombreWc" #nombreWc="ngModel" [(ngModel)]="dossierBien.bien.nombreWc">
          </div>
        </div>
      }

      <!-- WC broyeur -->
      @if (showFieldForBien('wcBroyeur')) {
        <div class="col-xs-12 col-sm-6">
          <label>WC broyeur</label>

          <app-form-radio-ynn [model]="dossierBien.bien.wcBroyeur" [options]="{name: 'wcBroyeur'}"
                              (selected)="dossierBien.bien.wcBroyeur = $event"/>
        </div>
      }
    </div>

    <div class="row">
      <!-- Nombre de salles de bains -->
      @if (showFieldForBien('nombreSallesBains')) {
        <div class="col-xs-12 col-sm-6">
          <div class="form-group" [appFormError]="nombreSallesBains">
            <label for="bien-nombre-salle-bains">Nombre de salles de bains</label>

            <input type="text" id="bien-nombre-salle-bains" class="form-control text-right" pattern="[0-9]+" min="0"
                   name="nombreSallesBains" #nombreSallesBains="ngModel" [(ngModel)]="dossierBien.bien.nombreSallesBains">
          </div>
        </div>
      }

      <!-- Nombre de salles d'eau -->
      @if (showFieldForBien('nombreSallesEau')) {
        <div class="col-xs-12 col-sm-6">
          <div class="form-group" [appFormError]="nombreSallesEau">
            <label for="bien-nombre-salle-eau">Nombre de salles d'eau</label>

            <input type="text" id="bien-nombre-salle-eau" class="form-control text-right" pattern="[0-9]+" min="0"
                   name="nombreSallesEau" #nombreSallesEau="ngModel" [(ngModel)]="dossierBien.bien.nombreSallesEau">
          </div>
        </div>
      }
    </div>

    <div class="row">
      <!-- Nombre de douches -->
      @if (showFieldForBien('nombreDouches')) {
        <div class="col-xs-12 col-sm-6">
          <div class="form-group" [appFormError]="nombreDouches">
            <label for="bien-nombre-douches">Nombre de douches</label>

            <input type="text" id="bien-nombre-douches" class="form-control text-right" pattern="[0-9]+" min="0"
                   name="nombreDouches" #nombreDouches="ngModel" [(ngModel)]="dossierBien.bien.nombreDouches">
          </div>
        </div>
      }

      <!-- Nombre de bains -->
      @if (showFieldForBien('nombreBains')) {
        <div class="col-xs-12 col-sm-6">
          <div class="form-group" [appFormError]="nombreBains">
            <label for="bien-nombre-bains">Nombre de baignoires</label>

            <input type="text" id="bien-nombre-bains" class="form-control text-right" pattern="[0-9]+" min="0"
                   name="nombreBains" #nombreBains="ngModel" [(ngModel)]="dossierBien.bien.nombreBains">
          </div>
        </div>
      }
    </div>
  </div>

  <div class="tw:float-right">
    <button type="submit" class="tw:btn-primary-info"><fa-icon [icon]="'check'"/><span>Enregistrer</span></button>
  </div>
</form>
