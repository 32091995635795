<div class="tw:sm:flex tw:sm:items-start">
  <app-modal-status-icon [status]="status"/>

  <div class="tw:mt-3 tw:sm:mt-0 tw:sm:mx-4 tw:text-left">
    <h3 class="tw:text-lg tw:text-center tw:sm:text-left tw:leading-6 tw:font-medium tw:text-gray-900">{{ title }}</h3>

    <div class="tw:mt-2 tw:text-gray-600" [ngClass]="moduleContact ? 'tw:text-base tw:text-center' : 'tw:text-sm'">
      <p [innerHTML]="comments"></p>

      @if (moduleContact) {
        <app-noteo-contact [module]="moduleContact" class="tw:block tw:mt-4"/>
      }
    </div>
  </div>
</div>

@if (!moduleContact) {
  <div class="tw:mt-5 tw:sm:mt-4 tw:sm:flex tw:sm:flex-row-reverse">
    <button [ngClass]="{'tw:btn-primary-danger': status === ModalStatus.DANGER,
                        'tw:btn-primary-info': status === ModalStatus.INFO,
                        'tw:btn-primary-success': status === ModalStatus.SUCCESS,
                        'tw:btn-primary-warning': status === ModalStatus.WARNING}"
            type="button" class="tw:mt-3 tw:sm:mt-0 tw:sm:w-auto tw:sm:text-sm" (click)="response()">Ok</button>
  </div>
}
