import {inject, Injectable} from '@angular/core';
import {DemandeurRechercheFactory} from '@models/demandeurs/demandeur/recherches/recherche/demandeur-recherche.factory';
import {IDemandeurRechercheApi} from '@models/demandeurs/demandeur/recherches/recherche/demandeur-recherche.interfaces';
import DemandeurRecherche from '@models/demandeurs/demandeur/recherches/recherche/demandeur-recherche.model';

@Injectable({providedIn: 'root'})
export class DemandeurRecherchesFactory {
    private _demandeurRechercheFactory = inject(DemandeurRechercheFactory);

    create(demandeurRechercheApis: IDemandeurRechercheApi[] = []): DemandeurRecherche[] {
        return (demandeurRechercheApis || []).filter(demandeurRechercheApi => demandeurRechercheApi)
            .map(demandeurRechercheApi => this._demandeurRechercheFactory.create(demandeurRechercheApi));
    }
}
