<div class="tw:py-1">
  @if (options.enabledActions?.remove) {
    <button type="button" class="tw:dropdown-link" (click)="remove()">
      <fa-icon [icon]="'trash-alt'"/><span>Supprimer</span>
    </button>
  }

  @if (options.enabledActions?.copyLinkInterface) {
    <button type="button" class="tw:dropdown-link" (click)="copyLinkInterface()">
      <fa-icon [icon]="['far', 'copy']"/><span>Copier le lien de signature</span>
    </button>
  }

  @if (options.enabledActions?.sendLinkInterface) {
    <button type="button" class="tw:dropdown-link" (click)="sendLinkInterface()">
      <fa-icon [icon]="['far', 'envelope']"/><span>Envoyer le lien de signature</span>
    </button>
  }
</div>
