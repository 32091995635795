import {AfterViewChecked, Directive, ElementRef, inject, Input} from '@angular/core';

@Directive({selector: 'textarea'})
export class AppFormTextareaAutoResizeDirective implements AfterViewChecked {
    private _htmlTextAreaElement = inject(ElementRef<HTMLTextAreaElement>).nativeElement;
    private _minHeight = 0;
    private _currentValue!: unknown;

    @Input()
    set minHeight(value: number) {
        this._minHeight = value;
    }

    ngAfterViewChecked(): void {
        if (this._htmlTextAreaElement.value !== this._currentValue) {
            this._currentValue = this._htmlTextAreaElement.value;
            this.adjustCustom();
        }
    }

    adjustCustom(): void {
        this._htmlTextAreaElement.style.minHeight = this._minHeight.toString() + 'px';
        // Permet de réduire éventuellement le champ si on enlève du contenu
        this._htmlTextAreaElement.style.height = 'auto';
        this._htmlTextAreaElement.style.height = (this._htmlTextAreaElement.scrollHeight + 2).toString() + 'px';
    }
}
