<div class="tw:h-full tw:xl:mx-28">
  <div class="tw:h-full tw:-mx-[15px] tw:md:mx-0">
    @if (estimation$ | async; as estimation) {
      <div class="tw:flex tw:gap-4 tw:flex-col">
        <div class="tw:flex tw:items-center tw:h-14 tw:md:px-0" [ngClass]="{'tw:px-4': !hasSelectedItems}">
          @if (hasSelectedItems) {
            <app-collection-selection-bar [listName]="cityscanOnsalePropertiesListOptions.nameSelection!"
                                          class="tw:block tw:overflow-hidden tw:w-full tw:md:rounded-lg"/>
          } @else {
            <div class="tw:w-full tw:flex tw:justify-between tw:items-center">
              <div class="tw:flex tw:items-center tw:gap-8">
                <a [routerLink]="redirectionRoute" class="tw:border-r-2 tw:border-gray-200 tw:pr-8 tw:text-gray-500">
                  <fa-icon [icon]="'arrow-left'" size="xl"/>
                </a>

                <div class="tw:text-gray-700 tw:text-lg">Recherche de références en vente</div>
              </div>

              <app-call-to-action type="{{CALL_TO_ACTION_MAIN}}"/>
            </div>
          }
        </div>

        @if (errorCityscanLocalizable) {
          <div class="tw:text-lg tw:text-center">
            L'adresse <span class="tw:italic">"{{ estimation.bien.fullInlineAdresse }}"</span> de votre bien en
            estimation n'a pas été trouvée par <app-noteo-icon-text-cityscan/>.<br><br>Modifiez la dans la partie
            <a [routerLink]="'/app/estimations/' + estimation.id + '/edit/general'">
              "Fiche" de votre bien en estimation
            </a>
            afin que <app-noteo-icon-text-cityscan/> vous retourne des références.
          </div>
        } @else {
          @if (cCityscanOnsaleProperties$ | async; as cCityscanOnsaleProperties) {
            @if (cCityscanOnsaleProperties.total > 0) {
              <app-cityscan-onsale-properties-list [cCityscanOnsaleProperties$]="cCityscanOnsaleProperties$"
                                                   [options]="cityscanOnsalePropertiesListOptions"
                                                   (changedTri)="changedTri($event)"/>
            } @else {
              <div class="tw:mb-4 tw:sm:mb-8 tw:mx-auto tw:w-full tw:pt-6 tw:px-4 tw:text-center">
                <p class="tw:mx-auto tw:mb-4 tw:text-base tw:text-gray-500 tw:sm:text-lg tw:md:text-xl">
                  Aucune référence en vente ne correspond avec les critères saisis.<br><br>Élargissez-les afin de
                  pouvoir ajouter des références en vente à votre bien en estimation.
                </p>
              </div>
            }
          } @else {
            <app-loader [classHeight]="'tw:h-40'" class="tw:block tw:mt-4"/>
          }
        }
      </div>
    } @else {
      <app-loader [classHeight]="'tw:h-40'" class="tw:block tw:mt-4"/>
    }
  </div>
</div>
