import {Component, inject, Input} from '@angular/core';
import {IDynamicComponent} from '@shared/shared.interfaces';
import {DropdownService} from '@shared/dropdown/dropdown.service';
import Reference from '@models/references/reference/reference.model';
import {IReferenceDropdownData} from '@features/references/references.interfaces';
import {FaIconComponent} from '@fortawesome/angular-fontawesome';

@Component({
    imports: [FaIconComponent],
    selector: 'reference-dropdown',
    templateUrl: 'reference.dropdown.component.html',
})
export class ReferenceDropdownComponent implements IDynamicComponent {
    static readonly actions = {LINK: 'reference_link', SEE: 'reference_see'};
    private _dropdownService = inject(DropdownService);
    private _reference!: Reference;

    @Input()
    set data(value: IReferenceDropdownData) {
        this._reference = value.reference!;
    }

    link(): void {
        this._dropdownService.clicked(ReferenceDropdownComponent.actions.LINK, {reference: this._reference});
    }

    see(): void {
        this._dropdownService.clicked(ReferenceDropdownComponent.actions.SEE, {reference: this._reference});
    }
}
