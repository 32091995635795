<div class="bg-modules-fond tw:bg-cover">
  <div class="tw:min-h-screen tw:h-full tw:w-full tw:px-6">
    <div class="tw:pt-24 tw:pb-32 tw:flex tw:flex-col tw:gap-4 tw:text-center">
      <h3 class="tw:text-septeo-blue-900 tw:font-bold">Utilisez <app-noteo-icon-text-noty/></h3>

      @if (publicite) {
        <h4 class="tw:text-septeo-blue-900 tw:font-bold">et</h4>

        <h1 class="tw:text-septeo-blue-900 tw:font-bold">{{ publicite.titre }}</h1>
      }
    </div>

    @if (publicite) {
      <ul class="tw:max-w-3.5xl tw:lg:max-w-10xl tw:m-auto tw:mt-8 tw:px-4 tw:pb-32 tw:grid tw:grid-cols-1 tw:gap-12 tw:lg:gap-6 tw:gap-y-24 tw:sm:grid-cols-2 tw:lg:grid-cols-4">
        @for (argument of publicite.arguments; track $index) {
          <li class="tw:relative tw:text-center tw:bg-white tw:rounded-lg tw:shadow-sm tw:border tw:border-gray-200 tw:border-solid">
            <div class="tw:absolute tw:inset-x-0 tw:-top-10 tw:flex tw:justify-center">
              <div class="tw:flex tw:items-center tw:justify-center tw:h-20 tw:w-20 tw:bg-noty-green tw:rounded-full tw:text-white">
                <fa-icon [icon]="argument.icon" size="2x"/>
              </div>
            </div>

            <div class="tw:mt-10">
              <h3 class="tw:flex tw:items-center tw:justify-center tw:m-0 tw:h-56 tw:py-12 tw:px-8 tw:text-gray-500 tw:font-bold">
                {{ argument.titre }}
              </h3>

              <div class="tw:bg-gray-200 tw:h-44 tw:sm:h-60 tw:lg:h-[22rem] tw:xl:h-64 tw:p-6 tw:text-xl tw:2xl:text-2xl tw:text-left"
                   [innerHTML]="argument.description"></div>
            </div>
          </li>
        }
      </ul>
    }

    @if (publicite) {
      <div class="tw:hidden tw:md:block tw:xl:mt-12 tw:pt-24 tw:pb-32 tw:px-32">
        <div class="tw:relative tw:w-full tw:aspect-double">
          <img [ngSrc]="publicite.imgList" class="tw:max-w-full tw:rounded-3xl tw:shadow-2xl" fill>
        </div>
      </div>
    }

    <div class="tw:mt-12 tw:pb-40 tw:sm:pb-28 tw:md:pb-40 tw:lg:pb-24 tw:xl:pb-32 tw:2xl:pb-36 tw:lg:ml-36 tw:xl:ml-[29rem] tw:2xl:ml-[39rem] tw:text-xl tw:sm:text-2xl tw:md:text-3xl tw:lg:text-4xl">
      <div class="tw:mb-4 tw:font-bold">Besoin d'information ?</div>

      <esk-contact type="commerce" class="tw:block tw:sm:pr-40 tw:md:pr-0"/>
    </div>
  </div>
</div>
