<!-- @todo Utilité de ces class ? -->
<div class="modal-without-overflow tw:flex tw:flex-col">
  <div class="tw:p-4 tw:pr-[5.5rem] tw:border-b tw:border-gray-300">
    <form>
      <app-form-search-input (searchedText)="searchReferences($event)" [loading]="displaySearchLoader"
                             [options]="{hasFocus: true, placeholder: 'Rechercher la référence d\'un bien'}"
                             [model]="searchText"/>
    </form>
  </div>

  <div class="tw:flex-1 tw:overflow-auto">
    <div class="tw:m-6 tw:mt-8">
      @if (searchText) {
        <div class="tw:md:w-[650px]">
          @if (cNotestimBiens$ | async; as cNotestimBiens) {
            <div class="tw:flex tw:items-center">
              <div class="tw:flex tw:items-center tw:font-bold tw:text-base">
                <!-- 14px pour 20px -->
                Depuis <app-noteo-logo-notestim class="tw:h-[14px]"/>
              </div>

              <span [ngPlural]="cNotestimBiens.total" class="tw:flex-1 tw:text-right">
                <ng-template ngPluralCase="=0">Aucun résultat</ng-template>
                <ng-template ngPluralCase="=1">1 résultat trouvé</ng-template>
                <ng-template ngPluralCase="other">{{ cNotestimBiens.total }} résultats trouvés</ng-template>
              </span>
            </div>

            @if (cNotestimBiens.results.length > 0) {
              <div class="tw:mt-4">
                <ul>
                  @for (notestimBien of cNotestimBiens.results; track $index) {
                    <li class="tw:bg-gray-50 tw:rounded-lg tw:p-2 tw:my-1 tw:flex tw:items-center tw:justify-between tw:w-full tw:space-x-8">
                      <span class="tw:block tw:flex-1 tw:sm:min-w-[15rem] tw:md:min-w-[20rem]" [highlight]="searchText">
                        {{ notestimBien.libelle }}
                      </span>

                      <div class="tw:w-20 tw:text-center">
                        <button type="button" (click)="referenceToImport(notestimBien)"
                                class="tw:btn-secondary-info tw:disabled:waiting">
                          <fa-icon [icon]="['fal','arrow-down-to-bracket']" class="tw:sm:hidden"/>
                          <span class="tw:hidden tw:sm:inline-block">Importer</span>
                        </button>
                      </div>
                    </li>
                  }
                </ul>
              </div>
            } @else {
              <div class="tw:my-8 tw:text-center tw:text-gray-500 tw:font-semibold">
                Aucun résultat trouvé. Veuillez vérifier la référence saisie.
              </div>
            }
          } @else {
            <app-loader [classHeight]="'tw:h-40'" class="tw:block tw:mt-6"/>
          }
        </div>
      } @else {
        <div class="tw:my-4 tw:sm:my-8 tw:color-gray-500 tw:font-semibold">
          <!-- 11px pour 16px -->
          La recherche dans <app-noteo-logo-notestim class="tw:h-[11px]"/> s'effectuera sur le champ référence.
        </div>
      }
    </div>
  </div>
</div>
