import angularJS from '@shared/angularJS/global.ng';
import {map} from "lodash";
import {IModule} from 'angular';
import {NgResource} from '@legacy/app/managers/ressources';
import {Subject} from 'rxjs';
import {TextsService} from '@shared/texts/texts.service';
import {ImageSizeConst} from '@shared/constants';

export default function getManagersUtils(module: IModule): void {
    (function (angular) {
        'use strict';

        module.factory('UtilsManager', manager);

        /**
         * Manager utils
         *
         * @param Ng2TextsService
         */
        manager.$inject = ['Ng2TextsService'];
        function manager(ng2TextsService: TextsService) {
            /**
             * UtilsManager object
             *
             * @constructor
             */
            UtilsManager.prototype.eskManager = {name: 'UtilsManager'};
            function UtilsManager() {
            }

            /**
             * Remove all attributes in object recursively
             *
             * @param object
             * @param attribute
             * @returns sanitizeObject
             */
            UtilsManager.prototype.removeAttributeRecursively = function (object: any, attribute: string): any {
                const self = this;

                if (angular.isObject(object)) {
                    if (angular.isDefined(object[attribute])) {
                        delete object[attribute];
                    }
                    angular.forEach(object, function (value, key) {
                        object[key] = self.removeAttributeRecursively(value, attribute);
                    });
                }

                return object;
            };

            /**
             * Remove attribute from object and get attribute.uuid in attributeUuid in object
             *
             * @param object
             * @param attribute
             */
            UtilsManager.prototype.flattenUuidForAttribute = function (object: any, attribute: string): any {
                if (angular.isObject(object[attribute]) && angular.isDefined(object[attribute].uuid)) {
                    object[attribute + 'Uuid'] = object[attribute].uuid;
                    delete object[attribute];
                }

                return object;
            };

            /**
             * Remove attribute from object and get attribute.id in attributeId in object
             *
             * @param object
             * @param attribute
             */
            UtilsManager.prototype.flattenIdForAttribute = function (object: any, attribute: string): any {
                if (angular.isObject(object[attribute]) && angular.isDefined(object[attribute].id)) {
                    object[attribute + 'Id'] = object[attribute].id;
                    delete object[attribute];
                }

                return object;
            };

            /**
             * Get an array of Ids from an array of objects
             *
             * @param object
             * @param attribute
             */
            UtilsManager.prototype.flattenIdsForAttribute = function (object: any, attribute: string): any {
                if (angular.isArray(object[attribute])) {
                    object[attribute] = map(object[attribute], 'id');
                }

                return object;
            };

            /**
             * Get a string from an object
             *
             * @param object
             * @param attribute
             * @returns {*}
             */
            UtilsManager.prototype.flattenAttributeWithCode = function (object: any, attribute: string): any {
                if (angular.isObject(object[attribute])) {
                    object[attribute] = object[attribute].code;
                }

                return object;
            };

            /**
             * Extend data to resource and add suivi attributes
             *
             * @param self
             * @param data
             * @returns {self}
             */
            UtilsManager.prototype.extendDataToResource = function (self: NgResource, data: any): NgResource {
                angular.extend(self, data);
                if (!angular.isObject(self._esk)) {
                    self._esk = {};
                }

                if (!angular.isString(self._esk.uuid)) {
                    self._esk.uuid = ng2TextsService.uuid();
                }

                self._esk.persist = angular.isDefined(self.id);
                self._esk.flush = self._esk.persist;

                if (['cadastres', 'charges', 'commodites', 'diagnostics', 'logements', 'pieces'].includes(self.__proto__.eskManager.prefixRoute)) {
                    self._esk.savedAskSource = new Subject<void>();
                    self._esk.savedAsk$ = self._esk.savedAskSource.asObservable();
                }

                return self;
            };

            /**
             * Create photo href
             *
             * @param photo
             * @param refresh
             * @returns {{thumbnail: string}}
             */
            UtilsManager.prototype.createPhotoHref = function (photo: any, refresh: boolean): {thumbnail: string} {
                // @todo Créer une méthode timestamp dans DateFormat ?
                const timestamp = (new Date()).getTime();
                let thumbnail: string = null!;

                if (angular.isObject(photo._links)) {
                    refresh = angular.isDefined(refresh) ? refresh : false;
                    thumbnail = photo._links.file.href + '?size=' + ImageSizeConst.THUMBNAIL + (refresh ? '&timestamp=' + timestamp : '');
                }

                return {thumbnail};
            };

            // @ts-ignore
            return new UtilsManager();
        }
    })(angularJS);
}
