import {inject, Injectable} from '@angular/core';
import {
    EstimationOnsaleReferenceFactory
} from '@models/estimations/estimation/onsale-references/onsale-reference/estimation-onsale-reference.factory';
import {
    IEstimationOnsaleReferenceApi
} from '@models/estimations/estimation/onsale-references/onsale-reference/estimation-onsale-reference.interfaces';
import EstimationOnsaleReference
    from '@models/estimations/estimation/onsale-references/onsale-reference/estimation-onsale-reference.model';

@Injectable({providedIn: 'root'})
export class EstimationOnsaleReferencesFactory {
    private _estimationOnsaleReferenceFactory = inject(EstimationOnsaleReferenceFactory);

    create(estimationOnsaleReferenceApis: IEstimationOnsaleReferenceApi[] = []): EstimationOnsaleReference[] {
        return (estimationOnsaleReferenceApis || []).filter(estimationOnsaleReferenceApi => estimationOnsaleReferenceApi)
            .map(estimationOnsaleReferenceApi => this._estimationOnsaleReferenceFactory.create(estimationOnsaleReferenceApi));
    }
}
