import {inject, Injectable} from '@angular/core';
import {BonvisiteLocationFactory} from '@models/bonvisites/bonvisite/locations/location/bonvisite-location.factory';
import {IBonvisiteLocationApi} from '@models/bonvisites/bonvisite/locations/location/bonvisite-location.interfaces';
import BonvisiteLocation from '@models/bonvisites/bonvisite/locations/location/bonvisite-location.model';

@Injectable({providedIn: 'root'})
export class BonvisiteLocationsFactory {
    private _bonvisiteLocationFactory = inject(BonvisiteLocationFactory);

    create(bonvisiteLocationApis: IBonvisiteLocationApi[] = []): BonvisiteLocation[] {
        return (bonvisiteLocationApis || []).filter(bonvisiteLocationApi => bonvisiteLocationApi)
            .map(bonvisiteLocationApi => this._bonvisiteLocationFactory.create(bonvisiteLocationApi));
    }
}
