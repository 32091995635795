import {Component, inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {EstimationService} from '@models/estimations/estimation/estimation.service';
import {combineLatest, from, Observable, of, ReplaySubject, Subject, switchMap, throwError} from 'rxjs';
import {catchError, map, take, takeUntil, tap} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import TemplateCategory from '@models/templates/template/category/template-category.model';
import Template from '@models/templates/template/template.model';
import {NgForm} from '@angular/forms';
import {EtudeService} from '@models/etudes/etude/etude.service';
import {AppNoteoContactComponent} from '@shared/noteo/contact/noteo-contact.component';
import {IFormCheckboxListOptions, IFormFieldCheckbox} from '@shared/form/form.interfaces';
import {EstimationIndicatorsService} from '@models/estimations/estimation/indicators/estimation-indicators.service';
import {IEstimationIndicator} from '@models/estimations/estimation/indicators/estimation-indicators.interfaces';
import {CallToActionService} from '@shared/call-to-action/call-to-action.service';
import {ModalService} from '@shared/modal/modal.service';
import Estimation from '@models/estimations/estimation/estimation.model';
import {HttpErrorResponse} from '@angular/common/http';
import {DocumentFactory} from '@models/documents/document/document.factory';
import {DocumentService} from '@models/documents/document/document.service';
import {NgEstimationManager} from '@legacy/app/managers/managers';
import {NgInjectorService} from '@shared/angularJS/injector.ng.service';
import EtudeSettingsCityscan from '@models/etudes/etude/settings/cityscan/etude-settings-cityscan.model';
import {
    DCEstimationRapportErrorCityscanLocationComponent
} from '@features/estimations/estimation/rapport/error-cityscan-location/estimation-rapport.error-cityscan-location.component';
import EstimationIndicatorsSection
    from '@models/estimations/estimation/indicators/section/estimation-indicators-section.model';
import {EstimationRapportService} from '@features/estimations/estimation/rapport/estimation-rapport.service';
import {
    EstimationRapportActionsMainComponent
} from '@features/estimations/estimation/rapport/actions/estimation-rapport.actions-main.component';

@Component({
    selector: 'layout-estimation-rapport-edit',
    standalone: false,
    templateUrl: 'layout-estimation.rapport-edit.component.html',
})
export class AppLayoutEstimationRapportEditComponent implements OnDestroy, OnInit {
    @ViewChild('rapportForm') rapportForm!: NgForm;

    // @todo Faire un tour pour factoriser les messages
    static readonly errorMessages = {
        OTHER: 'Certains champs n\'ont pas de valeurs correctes.<br/>Ils sont indiqués en rouge.',
        TITLE: 'Données incomplètes',
    };
    private _activatedRoute = inject(ActivatedRoute);
    private _callToActionService = inject(CallToActionService);
    private _documentFactory = inject(DocumentFactory);
    private _documentService = inject(DocumentService);
    private _estimationIndicatorsService = inject(EstimationIndicatorsService);
    private _estimationRapportService = inject(EstimationRapportService);
    private _estimationService = inject(EstimationService);
    private _etudeService = inject(EtudeService);
    private _modalService = inject(ModalService);
    private _ngInjectorService = inject(NgInjectorService);
    private _router = inject(Router);
    private _advertising!: string;
    private _courrierCategories: string [] = [];
    private _courrierTemplate!: Template;
    private _createSource = new Subject<string>();
    private _estimation$!: Observable<Estimation>;
    private _indicatorsOptions: IFormCheckboxListOptions = {};
    private _indicatorsSectionsSource = new ReplaySubject<EstimationIndicatorsSection[]>();
    private _indicatorsSections$ = this._indicatorsSectionsSource.asObservable().pipe(
        tap(indicatorsSections => {
            this._indicatorsOptions = {};
            this._selectedIndicators = {};
            if (!indicatorsSections) {
                return;
            }

            const indicators = indicatorsSections.reduce<IEstimationIndicator[]>((previous, current) => previous.concat(current.indicators), []);

            this._indicatorsOptions.disabledUuids = indicators.filter(indicator => indicator.disabled).map(indicator => indicator.uuid);
            indicatorsSections.forEach(indicatorsSection => this.selectIndicators(indicatorsSection, indicatorsSection.getEnabledIndicators()));
        }),
    );
    private readonly _onDestroy$ = new Subject<void>();
    private _rapportCategories: string [] = [];
    private _rapportTemplate!: Template;
    private _selectedIndicators!: Record<string, IEstimationIndicator[]>;

    // Supprimer les injections des anciens manager
    private get ngEstimationManager(): NgEstimationManager {
        return this._ngInjectorService.getService('EstimationManager');
    }

    get advertising(): string {
        return this._advertising;
    }

    get codeCategories(): Record<string, string> {
        return TemplateCategory.codes;
    }

    get courrierCategories(): string[] {
        return this._courrierCategories;
    }

    set courrierTemplate(value: Template) {
        this._courrierTemplate = value;
    }

    get estimation$(): Observable<Estimation> {
        return this._estimation$;
    }

    get indicatorsOptions(): IFormCheckboxListOptions {
        return this._indicatorsOptions;
    }

    get indicatorsSections$(): Observable<EstimationIndicatorsSection[]> {
        return this._indicatorsSections$;
    }

    get moduleCityscan(): string {
        return AppNoteoContactComponent.modules.CITYSCAN;
    }

    get rapportCategories(): string[] {
        return this._rapportCategories;
    }

    get rapportTemplate(): Template {
        return this._rapportTemplate;
    }

    get selectedIndicators(): Record<string, IEstimationIndicator[]> {
        return this._selectedIndicators;
    }

    ngOnInit(): void {
        this._estimation$ = of(this._estimationService.getCurrentFromNg()).pipe(
            tap(estimation => {
                if (estimation.isArchived()) {
                    return;
                }

                this._callToActionService.setDynamicComponentLoading({
                    component: EstimationRapportActionsMainComponent,
                    data: {options: {enabledActions: {generate: true, print: false, save: false, send: false}}},
                });
            }),
            switchMap(estimation => this._estimationRapportService.redirect$(estimation, this._router.url, this._activatedRoute).pipe(
                tap(isRedirect => {
                    if (isRedirect) {
                        return;
                    }

                    if (estimation.isEvaluation()) {
                        this._courrierCategories = [TemplateCategory.codes.COURRIER_ESTIMATION_EVALUATION];
                        this._rapportCategories = [TemplateCategory.codes.ESTIMATION_EVALUATION_CITYSCAN, TemplateCategory.codes.ESTIMATION_EVALUATION];
                    } else {
                        this._courrierCategories = [TemplateCategory.codes.COURRIER_ESTIMATION_AVIS_VALEUR];
                        this._rapportCategories = [TemplateCategory.codes.ESTIMATION_AVIS_VALEUR];
                    }
                }),
                map(() => estimation),
            )),
        );
        this._callToActionService.clicked$.pipe(
            switchMap(callToActionClicked => {
                if (callToActionClicked.action === EstimationRapportActionsMainComponent.actions.GENERATE) {
                    return this._callToActionService.actionExec$(this.generate$());
                }

                return of(undefined!);
            }),
            takeUntil(this._onDestroy$),
        ).subscribe(url => {
            if (url) {
                this._router.navigateByUrl(url);
            }
        });
    }

    ngOnDestroy(): void {
        this._onDestroy$.next();
    }

    createRapport(estimation: Estimation): void {
        let createRapport$: Observable<string>;

        if (this.rapportForm.valid) {
            const rapportDocument = this._documentFactory.createVirgin();
            const createDocuments$: Observable<unknown>[] = [];

            rapportDocument.templateId = this._rapportTemplate.id;
            rapportDocument.titre = (estimation.isEvaluation() ? 'Rapport d\'évaluation ' : 'Avis de valeur ') + estimation.reference;
            rapportDocument.variables = {estimationId: estimation.id};
            createDocuments$.push(this._etudeService.last$.pipe(
                tap(etude => {
                    if (etude.settingsCityscan.enabled && this._rapportTemplate.category.isEstimationEvaluationCityscan()) {
                        const selectedIndicators = Object.values(this._selectedIndicators).reduce((previous, current) => current.concat(previous), []);

                        rapportDocument.variables.cityscanIndicators = this._estimationIndicatorsService.getCityscanCodes(selectedIndicators);
                    }
                }),
                switchMap(_ => this._documentService.save$(rapportDocument)),
                tap(document => estimation.rapportId = document.id),
            ));
            if (this._courrierTemplate) {
                const courrierDocument = this._documentFactory.createVirgin();

                courrierDocument.templateId = this._courrierTemplate.id;
                courrierDocument.titre = 'Courrier introductif ' + (estimation.isEvaluation() ? 'au rapport d\'évaluation ' : 'à l\'avis de valeur ') + estimation.reference;
                courrierDocument.variables = {estimationId: estimation.id};
                createDocuments$.push(this._documentService.save$(courrierDocument).pipe(
                    tap(document => estimation.courrierIntroductifRapportId = document.id),
                ));
            } else {
                estimation.courrierIntroductifRapportId = undefined!;
            }

            // Les models ne prennent pas encore tous les attributs des managers d'où le passage par les managers
            createRapport$ = from(this.ngEstimationManager.current.save()).pipe(
                switchMap(_ => combineLatest(createDocuments$)),
                // Les models ne prennent pas encore tous les attributs des managers d'où le passage par les managers
                switchMap(_ => {
                    const ngEstimation = this.ngEstimationManager.current;

                    ngEstimation.courrierIntroductifRapportId = estimation.courrierIntroductifRapportId;
                    ngEstimation.rapportId = estimation.rapportId;

                    return from(ngEstimation.save()).pipe(map(() => this._router.createUrlTree(
                        [`../${EstimationRapportService.ROUTING[EstimationRapportService.STATUTS.WRITE]}`],
                        {relativeTo: this._activatedRoute},
                    ).toString()));
                }),
                catchError((httpErrorResponse: HttpErrorResponse) => {
                    const error = httpErrorResponse?.error as { type?: string };

                    if (httpErrorResponse.status === 400 && error?.type) {
                        if (error.type.includes(EtudeSettingsCityscan.REQUEST_ERRORS.cityscan_not_localizable)) {
                            // Pour tomber dans ce cas, saisir "Kourou" dans la commune et "Mont Blanc" dans l'adresse de l'estimation
                            return this._modalService.open$<boolean>(DCEstimationRapportErrorCityscanLocationComponent, {estimation}).pipe(
                                map(redirect => redirect ? this._router.createUrlTree(['../../edit/general'], {relativeTo: this._activatedRoute}).toString() : undefined!),
                            );
                        }
                    }

                    return throwError(() => JSON.stringify(httpErrorResponse));
                }),
            );
        } else {
            createRapport$ = throwError(() => AppLayoutEstimationRapportEditComponent.errorMessages.OTHER);
        }

        createRapport$.pipe(take(1)).subscribe({
            next: redirection => this._createSource.next(redirection),
            error: (comments: string) => {
                this._modalService.openInformation({
                    comments,
                    status: ModalService.statuts.WARNING,
                    title: AppLayoutEstimationRapportEditComponent.errorMessages.TITLE,
                });
                this._createSource.next(undefined!);
            }
        });
    }

    generate$(): Observable<string> {
        setTimeout(() => document.querySelector<HTMLButtonElement>('[type=submit]')?.click(), 1);

        return this._createSource.asObservable();
    }

    selectIndicators(indicatorsSection: EstimationIndicatorsSection, selectedIndicators: IFormFieldCheckbox[]): void {
        this._selectedIndicators[indicatorsSection.uuid] = selectedIndicators as IEstimationIndicator[];
        indicatorsSection.setStatut(this._selectedIndicators[indicatorsSection.uuid]);
    }

    selectRapportTemplate(rapportTemplate: Template): void {
        this._rapportTemplate = rapportTemplate;
        this._indicatorsSectionsSource.next(undefined!);
        this._etudeService.last$.pipe(
            switchMap(etude => {
                if (this._rapportTemplate.needAd(etude)) {
                    this._indicatorsSectionsSource.next([]);

                    return of(this._rapportTemplate.category.code);
                }

                return (this._rapportTemplate.category.isEstimationEvaluationCityscan() ? this._estimationIndicatorsService.getCityscanIndicatorsSections$() : of([])).pipe(
                    tap(indicatorsSections => this._indicatorsSectionsSource.next(indicatorsSections)),
                    map(_ => undefined!),
                );
            }),
            take(1),
        ).subscribe(advertising => this._advertising = advertising);
    }

    updateIndicatorsSectionStatut(indicatorsSection: EstimationIndicatorsSection): void {
        this.selectIndicators(indicatorsSection, indicatorsSection.toggleSelectedIndicators());
    }
}
