import {inject, Injectable} from '@angular/core';
import {ReportDossierFactory} from '@models/reports/report/dossiers/dossier/report-dossier.factory';
import {IReportDossierApi} from '@models/reports/report/dossiers/dossier/report-dossier.interfaces';
import ReportDossier from '@models/reports/report/dossiers/dossier/report-dossier.model';

@Injectable({providedIn: 'root'})
export class ReportDossiersFactory {
    private _reportDossierFactory = inject(ReportDossierFactory);

    create(reportDossierApis: IReportDossierApi[] = []): ReportDossier[] {
        return (reportDossierApis || []).filter(reportDossierApi => reportDossierApi)
            .map(reportDossierApi => this._reportDossierFactory.create(reportDossierApi));
    }
}
