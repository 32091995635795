import {Component, inject, Input, output} from '@angular/core';
import {take} from 'rxjs/operators';
import {Observable} from 'rxjs';
import Contact from '@models/contacts/contact/contact.model';
import {CEtudeDossiersService} from '@models/etude-dossiers/collection/etude-dossiers.collection.service';
import CEtudeDossiers from '@models/etude-dossiers/collection/etude-dossiers.collection.model';
import EtudeDossier from '@models/etude-dossiers/etude-dossier/etude-dossier.model';
import Etude from '@models/etudes/etude/etude.model';
import {AsyncPipe, NgClass, NgPlural, NgPluralCase} from '@angular/common';
import {AppNoteoIconTextInotComponent} from '@shared/noteo/icon-text/inot/noteo-icon-text.inot.component';
import {FaIconComponent} from '@fortawesome/angular-fontawesome';
import {SearchHighlightDirective} from '@shared/search/highlight/search.highlight.directive';
import {AppContactCardComponent} from '@features/contacts/contact/card/contact.card.component';
import {
    AppCommuneConvivialNameComponent
} from '@features/communes/commune/convivial-name/commune.convivial-name.component';
import {TextNl2brPipe} from '@shared/texts/nl2br/text-nl2br.pipe';
import {AppLoadMoreComponent} from '@shared/load/more/load-more.component';
import {AppLoaderComponent} from '@shared/load/loader/loader.component';

@Component({
    imports: [
        AppCommuneConvivialNameComponent,
        AppContactCardComponent,
        AppLoaderComponent,
        AppLoadMoreComponent,
        AppNoteoIconTextInotComponent,
        AsyncPipe,
        FaIconComponent,
        NgClass,
        NgPlural,
        NgPluralCase,
        SearchHighlightDirective,
        TextNl2brPipe,
    ],
    selector: 'app-etude-dossiers',
    templateUrl: 'etude-dossiers.component.html',
})
export class AppEtudeDossiersComponent {
    readonly addedContact = output<Contact>();
    private _cEtudeDossiersService = inject(CEtudeDossiersService);
    private _cEtudeDossiers$!: Observable<CEtudeDossiers>;
    private _dossierToDisplay!: EtudeDossier;
    private _enableAddingContact = true;
    private _keyword!: string;
    private _pendingGetMore = false;
    private _selectedContacts: Contact[] = [];

    get cEtudeDossiers$(): Observable<CEtudeDossiers> {
        return this._cEtudeDossiers$;
    }

    @Input()
    set cEtudeDossiers$(value: Observable<CEtudeDossiers>) {
        this._cEtudeDossiers$ = value;
    }

    get dossierToDisplay(): EtudeDossier {
        return this._dossierToDisplay;
    }

    get enableAddingContact(): boolean {
        return this._enableAddingContact;
    }

    @Input()
    set enableAddingContact(value: boolean) {
        this._enableAddingContact = value;
    }

    get INOT_LABEL(): string {
        return Etude.enablableModules.INOT.label;
    }

    get keyword(): string {
        return this._keyword;
    }

    @Input()
    set keyword(value: string) {
        this._keyword = value;
    }

    get pendingGetMore(): boolean {
        return this._pendingGetMore;
    }

    get selectedContacts(): Contact[] {
        return this._selectedContacts;
    }

    @Input()
    set selectedContacts(value: Contact[]) {
        this._selectedContacts = value;
    }

    addContact(contact: Contact): void {
        this.addedContact.emit(contact);
    }

    getMore(cEtudeDossiers: CEtudeDossiers): void {
        this._pendingGetMore = true;
        this._cEtudeDossiersService.addNext$(cEtudeDossiers).pipe(take(1)).subscribe({complete: () => this._pendingGetMore = false});
    }

    isAlreadySelected(contact: Contact): boolean {
        return !!this._selectedContacts.find(selectedContact => selectedContact.externalSource === contact.externalSource && selectedContact.externalId === contact.externalId);
    }

    show(etudeDossier: EtudeDossier): void {
        if (etudeDossier.qualifiedContacts.length === 0 || etudeDossier === this._dossierToDisplay) {
            return;
        }

        this._dossierToDisplay = etudeDossier;
    }
}
