import {Component, inject, Input} from '@angular/core';
import {Observable, of} from 'rxjs';
import CADossierBiens from '@models/dossiers/biens/collection/dossier-biens.collection.model.abstract';
import {ICollectionStackedImagesOptions} from '@shared/collection/collection.interfaces';
import {EtudeService} from '@models/etudes/etude/etude.service';
import {tap} from 'rxjs/operators';
import {
    AppCollectionStackedImagesComponent
} from '@shared/collection/stacked-images/collection.stacked-images.component';

@Component({
    imports: [AppCollectionStackedImagesComponent],
    selector: 'app-dossiers-biens-stacked-images',
    templateUrl: 'dossiers-biens.stacked-images.component.html',
})
export class AppDossiersBiensStackedImagesComponent {
    static readonly initDossiersBiensStackedImagesOptions: ICollectionStackedImagesOptions = {
        labelSingular: 'bien',
        labelPlural: 'biens',
        linkImageAttribute: 'linkPremierePhotoThumbnail',
    };

    private _etudeService = inject(EtudeService);
    private _cDossierBiens$!: Observable<CADossierBiens>;
    private _options: ICollectionStackedImagesOptions = {...AppDossiersBiensStackedImagesComponent.initDossiersBiensStackedImagesOptions};

    get cDossierBiens$(): Observable<CADossierBiens> {
        return this._cDossierBiens$;
    }

    @Input()
    set cDossierBiens(value: CADossierBiens) {
        this._cDossierBiens$ = of(value);
    }

    get options(): ICollectionStackedImagesOptions {
        return this._options;
    }

    @Input()
    set options(value: ICollectionStackedImagesOptions) {
        this._etudeService.last$.pipe(
            tap(_ => this._options = {...AppDossiersBiensStackedImagesComponent.initDossiersBiensStackedImagesOptions, ...value})
        ).subscribe(etude => this._options.linkDefault = this._options.linkDefault ?? etude.linkLogo);
    }
}
