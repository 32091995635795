@if (currentUser$ | async; as currentUser) {
  @if (currentUser.hasRoleGrpEstimationEvaluation()) {
    @if (estimation$ | async; as estimation) {
      @if (estimation.type.code === ESTIMATION_TYPE_EVALUATION) {
        <router-outlet/>
      } @else {
        <app-publicites [module]="PUBLICITES_ESTIMATION_EVALUATION_NOTESTIM"/>
      }
    } @else {
      <app-loader [classHeight]="'tw:h-40'" class="tw:block tw:mt-4"/>
    }
  } @else {
    <app-publicites [module]="PUBLICITES_ESTIMATION_EVALUATION_NOTESTIM"/>
  }
} @else {
  <app-loader [classHeight]="'tw:h-40'" class="tw:block tw:mt-4"/>
}
