@if (isInfosConfidentialVisible) {
  <div [appRoutesAnchorScroll]="'confidential'"
       class="tw:bg-red-50 tw:rounded-xl tw:p-3 tw:lg:p-4 tw:flex tw:flex-col tw:gap-4">
    <div class="tw:flex tw:items-center">
      <h3 class="tw:flex-1 tw:text-center tw:text-red-400 tw:uppercase">Informations confidentielles</h3>

      <button type="button" class="tw:btn-secondary-danger" (click)="isInfosConfidentialVisible = false">
        Cacher les informations confidentielles
      </button>
    </div>

    <div class="tw:grid tw:grid-cols-1 tw:sm:grid-cols-2 tw:lg:grid-cols-3 tw:grid-flow-dense tw:gap-8">
      @if (isPartProprietairesVisible) {
        <div [ngClass]="dossierBien.contactsGroup.members.length > 1 ? 'tw:sm:col-span-2' : ''">
          <app-dossier-bien-overview-proprietaires [dossierBien]="dossierBien"/>
        </div>
      }

      @if (isPartDescriptifsVisible) {
        <div class="tw:sm:col-span-2">
          <app-dossier-bien-overview-descriptifs [dossierBien]="dossierBien"/>
        </div>
      }

      @if (isPartLocalisationVisible) {
        <div><app-dossier-bien-overview-localisation [dossierBien]="dossierBien"/></div>
      }

      <div><app-dossier-bien-overview-dossier [dossierBien]="dossierBien"/></div>

      @if (isPartCompromisVisible) {
        <div><app-dossier-bien-overview-compromis [vente]="vente"/></div>
      }

      @if (isPartArchiveVisible) {
        <div><app-dossier-bien-overview-archive [dossierBien]="dossierBien"/></div>
      }

      @if (isCommentsConfidentielVisible || isCommentsConfreresVisible) {
        <div class="tw:sm:col-span-2 tw:flex tw:flex-col tw:gap-2">
          <h4 class="tw:px-3 tw:lg:px-4">Renseignements</h4>

          <div class="tw:bg-red-100 tw:rounded-xl tw:flex tw:flex-col tw:gap-4 tw:p-3 tw:lg:p-4">
            <ul class="tw:grid tw:gap-2 tw:text-sm tw:text-gray-700 tw:font-medium">
              @if (isCommentsConfreresVisible) {
                <li class="tw:overflow-hidden">
                  <div class="tw:text-gray-500 tw:text-xs tw:font-normal">Confrères</div>

                  <div class="tw:text-gray-700 tw:font-normal">{{ dossierBien.bien.commentsConfreres }}</div>
                </li>
              }

              @if (isCommentsConfidentielVisible) {
                <li class="tw:overflow-hidden">
                  <div class="tw:text-gray-500 tw:text-xs tw:font-normal">Confidentiels</div>

                  <div class="tw:text-gray-700 tw:font-normal">{{ dossierBien.bien.commentsConfidentiel }}</div>
                </li>
              }
            </ul>
          </div>
        </div>
      }
    </div>
  </div>
} @else {
  <div class="tw:flex tw:justify-end">
    <a #seeInfosConfidentialVisibleLink [routerLink]="'.'" fragment="confidential" class="tw:btn-tertiary-danger"
       (click)="isInfosConfidentialVisible = true">Voir les informations confidentielles</a>
  </div>
}
