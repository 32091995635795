import {Component, inject, OnInit} from '@angular/core';
import Reference from '@models/references/reference/reference.model';
import {Observable, ReplaySubject} from 'rxjs';
import {IDynamicComponent} from '@shared/shared.interfaces';
import {IDCReferenceData} from '@features/references/references.interfaces';
import {DictionaryItemService} from '@models/dictionaries/dictionary/items/item/dictionary-item.service';
import {take} from 'rxjs/operators';
import Dictionary from '@models/dictionaries/dictionary/dictionary.model';
import {AsyncPipe, DatePipe, NgPlural, NgPluralCase} from '@angular/common';
import {
    AppDictionaryItemConvivialNameComponent
} from '@features/dictionaries/dictionary/items/item/convivial-name/dictionary-item.convivial-name.component';
import {
    AppCommuneConvivialNameComponent
} from '@features/communes/commune/convivial-name/commune.convivial-name.component';
import {TextCurrencyPipe} from '@shared/texts/currency/text-currency.pipe';
import {AppLoaderComponent} from '@shared/load/loader/loader.component';

@Component({
    imports: [
        AppCommuneConvivialNameComponent,
        AppDictionaryItemConvivialNameComponent,
        AppLoaderComponent,
        AsyncPipe,
        DatePipe,
        NgPlural,
        NgPluralCase,
        TextCurrencyPipe,
    ],
    selector: 'dc-reference-slide-over',
    templateUrl: 'reference.slide-over.component.html',
})
export class DCReferenceSlideOverComponent implements IDynamicComponent, OnInit {
    private _dictionaryItemService = inject(DictionaryItemService);
    private _referenceSource = new ReplaySubject<Reference>(1);
    private _reference$ = this._referenceSource.asObservable();
    private _superficieLabel!: string;

    set data(dynamicComponentData: IDCReferenceData) {
        this._referenceSource.next(dynamicComponentData.reference!);
    }

    get DICTIONARY_NAME_CONSTRUCTION_EPOQUES(): string {
        return Dictionary.names.CONSTRUCTION_EPOQUES;
    }

    get DICTIONARY_NAME_NATURES(): string {
        return Dictionary.names.NATURES;
    }

    get reference$(): Observable<Reference> {
        return this._reference$;
    }

    get superficieLabel(): string {
        return this._superficieLabel;
    }

    ngOnInit(): void {
        this._reference$.pipe(take(1))
            .subscribe(reference => this._superficieLabel = this._dictionaryItemService.getOneSuperficieLabelById(reference.natureId));
    }
}
