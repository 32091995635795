<div>
  @if (location.loyer > 0) {
    Loyer
    <span class="tw:font-semibold tw:text-gray-700">
      {{location.periodiciteLoyer.label.replace('elle', 'el') | lowercase}}
    </span>
    de <span class="tw:font-semibold tw:text-gray-700 tw:text-lg">{{location.loyer | textCurrency}}</span>

    @if (!isPublic && location.chargesIncluses) {
      <span class="tw:font-semibold tw:text-gray-700">charges comprises</span>
    }
  } @else {
    Loyer non saisi
  }
</div>

<div>
  @if (location.loyerEncadrement) {
    Zone soumise à encadrement des loyers
  } @else if (location.charges > 0) {
    {{location.chargesIncluses ? 'dont' : '+'}}
    <span class="tw:font-semibold tw:text-gray-700">{{location.charges | textCurrency}}</span> de charges
    <span class="tw:font-semibold tw:text-gray-700">{{location.periodiciteCharges.label | lowercase}}s</span>
  }
</div>

@if (location.loyerVersement) {
  <div>Versement du loyer <span class="tw:font-semibold tw:text-gray-700">{{location.loyerVersement}}</span></div>
}

@if (isPublic) {
  @if (location.loyerEncadrement) {
    @if (location.loyerReferenceMajore > 0) {
      <div>
        Loyer de référence majoré
        <span class="tw:font-semibold tw:text-gray-700">
          {{location.periodiciteLoyer.label.replace('elle', 'el') | lowercase}}
        </span>
        de <span class="tw:font-semibold tw:text-gray-700">{{location.loyerReferenceMajore | textCurrency}}</span>
      </div>
    }

    @if (location.loyerBase > 0) {
      <div>
        Loyer de base
        <span class="tw:font-semibold tw:text-gray-700">
          {{location.periodiciteLoyer.label.replace('elle', 'el') | lowercase}}
        </span>
        de <span class="tw:font-semibold tw:text-gray-700">{{location.loyerBase | textCurrency}}</span>
      </div>
    }

    @if (location.loyerComplement > 0) {
      <div>
        Complément de loyer
        <span class="tw:font-semibold tw:text-gray-700">
          {{location.periodiciteLoyer.label.replace('elle', 'el') | lowercase}}
        </span>
        de <span class="tw:font-semibold tw:text-gray-700">{{location.loyerComplement | textCurrency}}</span>
      </div>
    }

    @if (location.charges > 0) {
      <div>
        Charges <span class="tw:font-semibold tw:text-gray-700">{{location.periodiciteCharges.label | lowercase}}s</span>
        {{location.chargesIncluses ? 'comprises' : 'en sus'}} de
        <span class="tw:font-semibold tw:text-gray-700">{{location.charges | textCurrency}}</span>
      </div>
    }
  }

  @if (location.honoRedacChargeLocataire > 0 || location.etatLieuxChargeLocataire > 0 || location.depotGarantie > 0
    || (isPrive && (location.montantTotalDu > 0 || location.montantVersementLocataire > 0))) {
    <div class="tw:mt-2 tw:font-semibold">À la charge du locataire</div>

    @if (location.honoRedacChargeLocataire > 0) {
      <div>
        Honoraires de rédaction de
        <span class="tw:font-semibold tw:text-gray-700">{{location.honoRedacChargeLocataire | textCurrency}}</span>
      </div>
    }

    @if (location.etatLieuxChargeLocataire > 0) {
      <div>
        État des lieux de
        <span class="tw:font-semibold tw:text-gray-700">{{location.etatLieuxChargeLocataire | textCurrency}}</span>
      </div>
    }

    @if (location.depotGarantie > 0) {
      <div>
        Dépôt de garantie de
        <span class="tw:font-semibold tw:text-gray-700">{{location.depotGarantie | textCurrency}}</span>
      </div>
    }

    @if (isPrive && location.montantTotalDu > 0) {
      <div>
        Montant total dû de
        <span class="tw:font-semibold tw:text-gray-700">{{location.montantTotalDu | textCurrency}}</span>
      </div>
    }

    @if (isPrive && location.montantVersementLocataire > 0) {
      <div>
        Montant dû le premier mois
        <span class="tw:font-semibold tw:text-gray-700">{{location.montantVersementLocataire | textCurrency}}</span>
      </div>
    }
  }

  @if (isPrive && (location.honoNegoChargeProprietaire > 0 || location.honoRedacChargeProprietaire > 0
    || location.etatLieuxChargeProprietaire > 0 || !!location.fraisCautionnement)) {
    <div class="tw:mt-2 tw:font-semibold">À la charge du propriétaire</div>

    @if (location.honoNegoChargeProprietaire > 0) {
      <div>
        Honoraires de négociation de
        <span class="tw:font-semibold tw:text-gray-700">{{location.honoNegoChargeProprietaire | textCurrency}}</span>
      </div>
    }

    @if (location.honoRedacChargeProprietaire > 0) {
      <div>
        Honoraires de rédaction de
        <span class="tw:font-semibold tw:text-gray-700">{{location.honoRedacChargeProprietaire | textCurrency}}</span>
      </div>
    }

    @if (location.etatLieuxChargeProprietaire > 0) {
      <div>
        État des lieux de
        <span class="tw:font-semibold tw:text-gray-700">{{location.etatLieuxChargeProprietaire | textCurrency}}</span>
      </div>
    }

    @if (!!location.fraisCautionnement) {
      <div class="tw:mt-2 tw:font-semibold">Frais de cautionnement</div>
      <div class="tw:text-gray-700" [innerHTML]="location.fraisCautionnement | textNl2br"></div>
    }
  }
}
