@if (cEtudePasserelles$ | async; as cEtudePasserelles) {
  @if (cEtudePasserelles.total > 0) {
    <ul class="tw:grid tw:grid-cols-1 tw:gap-8 tw:!mx-6 sm:tw:!mx-0 tw:md:grid-cols-2 tw:lg:grid-cols-3 tw:xl:grid-cols-4 tw:2xl:flex tw:2xl:flex-wrap tw:2xl:justify-center">
      @for (etudePasserelle of cEtudePasserelles.results; track etudePasserelle) {
        <li class="tw:col-span-1 tw:flex tw:flex-col tw:p-4 tw:gap-4 tw:divide-y tw:divide-gray-200 tw:divide-solid tw:rounded-lg tw:bg-white tw:text-center tw:shadow-sm tw:2xl:w-80">
          <app-etude-passerelle [etudePasserelle]="etudePasserelle" class="tw:flex tw:flex-1 tw:flex-col"/>

          <div class="tw:pt-4">
            @if (hasRoleVente$ | appLoadObs | async; as hasRoleVente) {
              <button type="button" class="tw:m-2" (click)="redirectToVentes(etudePasserelle)"
                      [ngClass]="{'tw:btn-tertiary-info': etudePasserelle.nbVentesActivesEnvoyees > 0, 'tw:btn-tertiary-default ': etudePasserelle.nbVentesActivesEnvoyees === 0}"
                      [disabled]="!hasRoleVente.value || etudePasserelle.nbVentesActivesEnvoyees === 0">
                <span [ngPlural]="etudePasserelle.nbVentesActivesEnvoyees" class="tw:text-sm">
                  <ng-template ngPluralCase="=0">Aucune vente envoyée</ng-template>
                  <ng-template ngPluralCase="=1">Voir la vente</ng-template>
                  <ng-template ngPluralCase="other">
                    Voir les {{ etudePasserelle.nbVentesActivesEnvoyees }} ventes
                  </ng-template>
                </span>
              </button>
            } @else {
              <app-loader [classHeight]="'tw:h-5'" class="tw:block tw:mt-2"/>
            }

            @if (hasRoleLocation$ | appLoadObs | async; as hasRoleLocation) {
              <button type="button" class="tw:m-2" (click)="redirectToLocations(etudePasserelle)"
                      [ngClass]="{'tw:btn-tertiary-info': etudePasserelle.nbLocationsActivesEnvoyees > 0, 'tw:btn-tertiary-default ': etudePasserelle.nbLocationsActivesEnvoyees === 0}"
                      [disabled]="!hasRoleLocation.value || etudePasserelle.nbLocationsActivesEnvoyees === 0">
                <span [ngPlural]="etudePasserelle.nbLocationsActivesEnvoyees" class="tw:text-sm">
                  <ng-template ngPluralCase="=0">Aucune location envoyée</ng-template>
                  <ng-template ngPluralCase="=1">Voir la location</ng-template>
                  <ng-template ngPluralCase="other">
                    Voir les {{ etudePasserelle.nbLocationsActivesEnvoyees }} locations
                  </ng-template>
                </span>
              </button>
            } @else {
              <app-loader [classHeight]="'tw:h-5'" class="tw:block tw:mt-2"/>
            }
          </div>
        </li>
      }
    </ul>
  } @else {
    Aucune passerelle n'est paramétrée.
  }
} @else {
  <app-loader [classHeight]="'tw:h-40'" class="tw:block tw:mt-4"/>
}
