import {inject, Injectable} from '@angular/core';
import {OffreachatFactory} from '@models/offreachats/offreachat/offreachat.factory';
import {IOffreachatApi} from '@models/offreachats/offreachat/offreachat.interfaces';
import Offreachat from '@models/offreachats/offreachat/offreachat.model';

@Injectable({providedIn: 'root'})
export class OffreachatsFactory {
    private _offreachatFactory = inject(OffreachatFactory);

    create(offreachatApis: IOffreachatApi[] = []): Offreachat[] {
        return (offreachatApis || []).filter(offreachatApi => offreachatApi)
            .map(offreachatApi => this._offreachatFactory.create(offreachatApi));
    }
}
