import {inject, Injectable} from '@angular/core';
import {ModalService} from '@shared/modal/modal.service';
import {Observable} from 'rxjs';
import {IAModaleTextareaComponent} from '@shared/ia/modales/textarea/ia-modale.textarea.component';
import {IIAModalData} from '@shared/ia/ia.interfaces';
import {IAModaleTextComponent} from '@shared/ia/modales/text/ia-modale.text.component';

@Injectable({providedIn: 'root'})
export class IAService {
    static MODAL_TITLE_DEFAULT = 'Texte généré par IA';
    private _modalService = inject(ModalService);

    openText$(iaModalData: IIAModalData<string>): Observable<string> {
        return this._modalService.open$(IAModaleTextComponent, iaModalData);
    }

    openTextarea$(iaModalData: IIAModalData<string>): Observable<string> {
        return this._modalService.open$(IAModaleTextareaComponent, iaModalData);
    }
}
