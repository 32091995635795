import {Component, Input, OnDestroy, OnInit, output} from '@angular/core';
import {IFormSelectImageInput, IFormSelectImageInputOptions} from '@shared/form/form.interfaces';
import {BehaviorSubject, Observable, of, Subject, switchMap} from 'rxjs';
import {filter, map, take, takeUntil} from 'rxjs/operators';
import {AsyncPipe, NgClass, NgOptimizedImage} from '@angular/common';
import {AppLoaderComponent} from '@shared/load/loader/loader.component';

@Component({
    imports: [AppLoaderComponent, AsyncPipe, NgClass, NgOptimizedImage],
    selector: 'app-form-select-image',
    templateUrl: 'form.select-image.component.html',
})
export class AppFormSelectImageComponent implements OnDestroy, OnInit {
    static readonly initFormSelectImageInputOptions: IFormSelectImageInputOptions = {
        aspectRatio: 'tw:aspect-video',
        selectionAccepted$: () => of(true),
    };
    readonly selected = output<string>();
    private _formSelectImage!: IFormSelectImageInput;
    private _listSubject = new BehaviorSubject<IFormSelectImageInput[]>(undefined!);
    private _list$ = this._listSubject.asObservable();
    private _modelSubject = new BehaviorSubject<string>(undefined!);
    private readonly _onDestroy$ = new Subject<void>();
    private _options: IFormSelectImageInputOptions = {...AppFormSelectImageComponent.initFormSelectImageInputOptions};

    get formSelectImage(): IFormSelectImageInput {
        return this._formSelectImage;
    }

    get list$(): Observable<IFormSelectImageInput[]> {
        return this._list$;
    }

    @Input()
    set list(value: IFormSelectImageInput[]) {
        this._listSubject.next(value);
    }

    @Input()
    set model(value: string) {
        this._modelSubject.next(value);
    }

    get options(): IFormSelectImageInputOptions {
        return this._options;
    }

    @Input()
    set options(value: IFormSelectImageInputOptions) {
        this._options = {...AppFormSelectImageComponent.initFormSelectImageInputOptions, ...value};
    }

    ngOnInit(): void {
        this._modelSubject.asObservable().pipe(
            switchMap(model => this.list$.pipe(
                filter(list => !!list),
                map(list => list.find(item => item.code === model) ?? list[0]),
            )),
            takeUntil(this._onDestroy$),
        ).subscribe(formSelectImage => setTimeout(() => this.onSelect(formSelectImage, undefined!, true)));
    }

    ngOnDestroy(): void {
        this._onDestroy$.next();
    }

    onSelect(formSelectImageInput: IFormSelectImageInput, focusButton: HTMLButtonElement, init = false): void {
        let selectionAccepted$ = of(true);

        if (!init) {
            selectionAccepted$ = this.options.selectionAccepted$(formSelectImageInput);
        }

        selectionAccepted$.pipe(take(1)).subscribe(isAccepted => {
            if (isAccepted) {
                this._formSelectImage = formSelectImageInput;
                this.selected.emit(this._formSelectImage?.code);
            } else {
                focusButton.blur();
            }
        });
    }
}
