<!-- Obliger de spécifier "print!" pour surcharger bootstrap et son "_print.scss" -->
<div class="tw:flex tw:justify-center">
  <div [ngClass]="{
      'tw:bg-blue-100 tw:text-blue-800 tw:print:!bg-blue-100 tw:print:!text-blue-800': color === STATUT_COLORS.BLUE,
      'tw:bg-green-100 tw:text-green-800 tw:print:!bg-green-100 tw:print:!text-green-800': color === STATUT_COLORS.GREEN,
      'tw:bg-orange-100 tw:text-orange-600 tw:print:!bg-orange-100 tw:print:!text-orange-600': color === STATUT_COLORS.ORANGE,
      'tw:bg-red-50 tw:text-red-500 tw:print:!bg-red-50 tw:print:!text-red-500': color === STATUT_COLORS.RED,
      'tw:bg-rose-50 tw:text-rose-600 tw:print:!bg-rose-50 tw:print:!text-rose-600': color === STATUT_COLORS.ROSE,
      'tw:bg-stone-600 tw:text-stone-100 tw:print:!bg-stone-600 tw:print:!text-stone-100': color === STATUT_COLORS.STONE
      }"
       class="tw:rounded-full tw:px-3 tw:py-1 tw:font-semibold tw:whitespace-nowrap tw:first-letter:uppercase">
    {{label}}
  </div>
</div>
