import {NgModule} from '@angular/core';
import {AppSiteCardComponent} from '@features/sites/site/card/site.card.component';
import {AppSiteConvivialNameComponent} from '@features/sites/site/convivial-name/site.convivial-name.component';

@NgModule({
    exports: [AppSiteCardComponent, AppSiteConvivialNameComponent],
    imports: [AppSiteCardComponent, AppSiteConvivialNameComponent],
})
export class SitesModule {
}
