import {inject, Injectable} from '@angular/core';
import Links from '@models/links/links.model';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {TemplateBalisesApiService} from '@models/templates/balises/template-balises.api.service';
import {ICTemplateBalisesApi} from '@models/templates/balises/collection/template-balises.collection.interfaces';
import CTemplateBalises from '@models/templates/balises/collection/template-balises.collection.model';
import {TemplateBalisesFactory} from '@models/templates/balises/template-balises.factory';

@Injectable({providedIn: 'root'})
export class CTemplateBalisesFactory {
    private _templateBalisesApiService = inject(TemplateBalisesApiService);
    private _templateBalisesFactory = inject(TemplateBalisesFactory);

    create(cTemplateBalisesApi: ICTemplateBalisesApi): CTemplateBalises {
        const cTemplateBalises = this.createVirgin();

        cTemplateBalises.links = new Links(cTemplateBalisesApi._links);
        cTemplateBalises.page = cTemplateBalisesApi.page;
        cTemplateBalises.pages = cTemplateBalisesApi.pages;
        cTemplateBalises.perPage = cTemplateBalisesApi.limit;
        cTemplateBalises.total = cTemplateBalisesApi.total;

        if (cTemplateBalisesApi._embedded) {
            cTemplateBalises.results = this._templateBalisesFactory.create(cTemplateBalisesApi._embedded.items);
        }

        return cTemplateBalises;
    }

    createVirgin(): CTemplateBalises {
        const cTemplateBalises = new CTemplateBalises();

        cTemplateBalises.links = new Links();

        return cTemplateBalises;
    }

    getEtude$(): Observable<CTemplateBalises> {
        return this._templateBalisesApiService.getCollection$({model: CTemplateBalises.models.etude.CODE})
            .pipe(map(cTemplateBalisesApi => this.create(cTemplateBalisesApi)));
    }

    getPage$(): Observable<CTemplateBalises> {
        return this._templateBalisesApiService.getCollection$({model: CTemplateBalises.models.page.CODE})
            .pipe(map(cTemplateBalisesApi => this.create(cTemplateBalisesApi)));
    }

    getSitePrincipal$(): Observable<CTemplateBalises> {
        return this._templateBalisesApiService.getCollection$({model: CTemplateBalises.models.sitePrincipal.CODE})
            .pipe(map(cTemplateBalisesApi => this.create(cTemplateBalisesApi)));
    }

    getUser$(): Observable<CTemplateBalises> {
        return this._templateBalisesApiService.getCollection$({model: CTemplateBalises.models.currentUser.CODE})
            .pipe(map(cTemplateBalisesApi => this.create(cTemplateBalisesApi)));
    }
}
