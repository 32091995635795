@if (bonvisite$ | async; as bonvisite) {
  <div>
    <!-- Utile pour valider le premier formulaire de AppBonvisiteEditComponent -->
    <button type="button" form="demandeurCreationForm" class="tw:hidden" (click)="saveDemandeur()"></button>

    <!-- Utile pour valider le second formulaire de AppBonvisiteEditComponent -->
    <button type="button" form="visiteForm" class="tw:hidden" (click)="saveVisite()"></button>

    <app-bonvisite-edit [bonvisite]="bonvisite" class="tw:block tw:-mx-[15px] tw:disabled:waiting"
                        (locations)="locations = $event" (ventes)="ventes = $event" [options]="bonvisiteEditOptions"
                        (forms)="forms = $event" (demandeur)="demandeur = $event"/>
  </div>
} @else {
  <app-loader [classHeight]="'tw:h-40'" class="tw:block tw:mt-4"/>
}
