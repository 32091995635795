import {Component, inject, Input} from '@angular/core';
import User from '@models/users/user/user.model';
import {SlideOverService} from '@shared/slide-over/slide-over.service';
import {Observable, ReplaySubject} from 'rxjs';
import {UserFactory} from '@models/users/user/user.factory';
import {take} from 'rxjs/operators';
import {IUserCartoucheOptions} from '@features/users/users.interfaces';
import {DCUserSlideOverComponent} from '@features/dynamic-components/user-slide-over/dc-user.slide-over.component';
import {AppLoadObsPipe} from '@shared/load/obs/load-obs.pipe';
import {AsyncPipe, NgClass, NgOptimizedImage} from '@angular/common';
import {AppLoaderComponent} from '@shared/load/loader/loader.component';

@Component({
    imports: [AppLoaderComponent, AppLoadObsPipe, AsyncPipe, NgClass, NgOptimizedImage],
    selector: 'app-user-cartouche',
    templateUrl: 'user.cartouche.component.html',
})
export class AppUserCartoucheComponent {
    static readonly initUserCartoucheOptions: IUserCartoucheOptions = {showConvivialName: true, showPhoto: true};
    private _slideOverService = inject(SlideOverService);
    private _userFactory = inject(UserFactory);
    private _options: IUserCartoucheOptions = {...AppUserCartoucheComponent.initUserCartoucheOptions};
    private _userSource = new ReplaySubject<User>(1);
    private _user$ = this._userSource.asObservable();

    get user$(): Observable<User> {
        return this._user$;
    }

    @Input()
    set link(value: string) {
        if (value) {
            this._userFactory.getByLink$(value).pipe(take(1)).subscribe(user => this._userSource.next(user));
        } else {
            this._userSource.next(undefined!);
        }
    }

    get options(): IUserCartoucheOptions {
        return this._options;
    }

    @Input()
    set options(value: IUserCartoucheOptions) {
        this._options = {...AppUserCartoucheComponent.initUserCartoucheOptions, ...value};
    }

    @Input()
    set user(value: User) {
        this._userSource.next(value);
    }

    openSlideOver(user: User): void {
        this._slideOverService.open$(DCUserSlideOverComponent, {user});
    }
}
