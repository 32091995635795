<div class="tw:sm:flex tw:sm:items-start">
  <app-modal-status-icon [status]="STATUS_WARNING"/>

  <div class="tw:mt-3 tw:sm:mt-0 tw:sm:mx-4">
    <h3 class="tw:text-lg tw:text-center tw:leading-6 tw:font-medium tw:text-gray-900 tw:sm:text-left">
      Génération du rapport d'évaluation
    </h3>

    <p class="tw:w-[35rem] tw:mt-4 tw:text-sm">
      L'adresse <span class="tw:italic">"{{estimation.bien.fullInlineAdresse}}"</span> de votre bien en estimation n'a
      pas été trouvée par <app-noteo-icon-text-cityscan/>.<br>Modifiez la dans la partie
      <button type="button" class="tw:btn-tertiary-info tw:p-0" (click)="redirection(true)">
        "Fiche" de votre bien en estimation
      </button>
      afin que le rapport avec les données provenant de <app-noteo-icon-text-cityscan/> puisse être créé.
    </p>
  </div>
</div>

<div class="tw:mt-5 tw:sm:mt-4 tw:sm:flex tw:sm:flex-row-reverse">
  <button type="button" class="tw:btn-primary-warning tw:w-full tw:sm:ml-3 tw:sm:w-auto"
          (click)="redirection(true)">Aller dans la fiche</button>

  <button type="button" class="tw:btn-secondary-default tw:mt-3 tw:sm:mt-0 tw:sm:ml-3 tw:w-full tw:sm:w-auto"
          (click)="redirection(false)">Annuler</button>
</div>
