import {Component, Input} from '@angular/core';
import {NgClass} from '@angular/common';
import {IIAButtonOptions} from '@shared/ia/ia.interfaces';
import {of} from 'rxjs';
import {take} from 'rxjs/operators';
import {FaIconComponent} from '@fortawesome/angular-fontawesome';

@Component({imports: [FaIconComponent, NgClass], selector: 'app-ia-button', templateUrl: 'ia-button.component.html'})
export class AppIAButtonComponent {
    static readonly initIAButtonOptions: IIAButtonOptions = {
        callback$: () => of(undefined),
        iconEnabled: true,
        label: 'Générer',
        type: 'primary',
    };
    private _isLoading = false;
    private _options: IIAButtonOptions = {...AppIAButtonComponent.initIAButtonOptions};

    get isLoading(): boolean {
        return this._isLoading;
    }

    get options(): IIAButtonOptions {
        return this._options;
    }

    @Input({required: true})
    set options(value: IIAButtonOptions) {
        this._options = {...AppIAButtonComponent.initIAButtonOptions, ...value};
    }

    execute(): void {
        this._isLoading = true;
        this._options.callback$().pipe(take(1)).subscribe({complete: () => this._isLoading = false});
    }
}
