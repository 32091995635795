@if (vente$ | async; as vente) {
  <span class="tw:mr-2 tw:font-semibold">{{ vente.reference }}</span>
  @if (vente.interne) {
    {{ vente.contactsGroup.nom }}
  } @else {
    <app-etude-raison-sociale [link]="vente.linkEtude" class="tw:inline-block tw:w-40"/>
  }
} @else {
  <app-loader [classHeight]="'tw:h-5'"/>
}
