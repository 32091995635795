import {Component, inject, OnInit} from '@angular/core';
import {IDynamicComponent} from '@shared/shared.interfaces';
import {take} from 'rxjs/operators';
import {ModalService} from '@shared/modal/modal.service';
import NotestimBien from '@models/notestim/biens/bien/notestim-bien.model';
import {NotestimBienFactory} from '@models/notestim/biens/bien/notestim-bien.factory';
import ADossierBien from '@models/dossiers/biens/bien/dossier-bien.model.abstract';
import {HttpErrorResponse} from '@angular/common/http';
import {IDCNotestimBienImportData} from '@features/notestim/notestim.interfaces';
import {AppNoteoLogoNotestimComponent} from '@shared/noteo/logo/notestim/noteo-logo.notestim.component';
import {FaIconComponent} from '@fortawesome/angular-fontawesome';

@Component({
    imports: [AppNoteoLogoNotestimComponent, FaIconComponent],
    selector: 'dc-notestim-bien-import',
    templateUrl: 'notestim-bien-import.component.html',
})
export class DCNotestimBienImportComponent implements IDynamicComponent, OnInit {
    private _modalService = inject(ModalService);
    private _notestimBienFactory = inject(NotestimBienFactory);
    private _errorMessage!: string;
    private _notestimBien!: NotestimBien;

    set data(dynamicComponentData: IDCNotestimBienImportData) {
        this._notestimBien = dynamicComponentData.notestimBien;
    }

    get errorMessage(): string {
        return this._errorMessage;
    }

    get notestimBien(): NotestimBien {
        return this._notestimBien;
    }

    ngOnInit(): void {
        this._notestimBienFactory.import$(this._notestimBien).pipe(take(1)).subscribe({
            next: vente => this._modalService.setResponse(vente as ADossierBien),
            error: (errorResponse: HttpErrorResponse) => {
                if (errorResponse.status === 422) {
                    this._errorMessage = (errorResponse.error as { errors: { message: string }[] }).errors[0].message;
                }
            }
        });
    }

    closeModal(): void {
        this._modalService.setResponse();
    }
}
