export default `
<div class="crud-actions">
  <button type="button" class="btn btn-primary btn-o" ng-click="$ctrl.searchReferences()">
    <fa-icon [icon]="'search'"></fa-icon> Rechercher des références
  </button>

  <div class="btn-group" uib-dropdown>
    <button type="button" class="btn btn-primary btn-o" ng-click="$ctrl.editReference()">
      <fa-icon [icon]="'plus'"></fa-icon> Ajouter une référence
    </button>

    <button type="button" class="btn btn-primary btn-o" uib-dropdown-toggle><span class="caret"></span></button>

    <ul class="dropdown-menu" uib-dropdown-menu role="menu">
      <li role="menuitem">
        <button type="button" class="tw:dropdown-link" ng-click="$ctrl.importReferences()">
          <fa-icon [icon]="'file-import'"></fa-icon><span>Importer des références</span>
        </button>
      </li>
    </ul>
  </div>
</div>
`;
