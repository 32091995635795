import {
    ComponentRef, Directive, ElementRef, inject, Input, OnDestroy, OnInit, Renderer2, ViewContainerRef
} from '@angular/core';
import {AppIAButtonComponent} from '@shared/ia/button/ia-button.component';
import {IIAButtonOptions} from '@shared/ia/ia.interfaces';

@Directive({selector: '[appIAButton]'})
export class AppIAButtonDirective implements OnDestroy, OnInit {
    static readonly CLASS = {ABSOLUTE: 'tw:absolute', RIGHT0: 'tw:right-0', RELATIVE: 'tw:relative'};
    private _elementRef = inject(ElementRef<HTMLElement>);
    private _renderer2 = inject(Renderer2);
    private _viewContainerRef = inject(ViewContainerRef);
    private _appIAButtonOptions: IIAButtonOptions = {...AppIAButtonComponent.initIAButtonOptions};
    private _appIAButtonComponentRef!: ComponentRef<AppIAButtonComponent>;
    private _parentElement!: HTMLElement;
    private _parentElementResizeObserver!: ResizeObserver;

    @Input()
    set appIAButton(value: IIAButtonOptions) {
        this._appIAButtonOptions = {...AppIAButtonComponent.initIAButtonOptions, ...value};
    }

    ngOnInit(): void {
        this.initParent();
        this.initIAButton();
    }

    ngOnDestroy(): void {
        this._parentElementResizeObserver.disconnect();
    }

    initParent(): void {
        this._parentElement = this._elementRef.nativeElement.parentElement!;
        this._renderer2.addClass(this._parentElement, AppIAButtonDirective.CLASS.RELATIVE);
        this._parentElementResizeObserver = new ResizeObserver(_ => this.positionIAButton());
        this._parentElementResizeObserver.observe(this._parentElement);
    }

    initIAButton(): void {
        this._appIAButtonComponentRef = this._viewContainerRef.createComponent(AppIAButtonComponent);
        this._appIAButtonComponentRef.instance.options = this._appIAButtonOptions;
        this._renderer2.addClass(this._appIAButtonComponentRef.location.nativeElement, AppIAButtonDirective.CLASS.ABSOLUTE);
        this._renderer2.addClass(this._appIAButtonComponentRef.location.nativeElement, AppIAButtonDirective.CLASS.RIGHT0);
        this._renderer2.appendChild(this._parentElement, this._appIAButtonComponentRef.location.nativeElement);
    }

    positionIAButton(): void {
        const elementBoundingClientRect = this._elementRef.nativeElement.getBoundingClientRect();
        const parentBoundingClientRect = this._parentElement.getBoundingClientRect();
        const appIAButtonBoundingClientRect = this._appIAButtonComponentRef.location.nativeElement.getBoundingClientRect();
        const elementYHeight = (elementBoundingClientRect.y - parentBoundingClientRect.y) + elementBoundingClientRect.height;
        let top = elementYHeight - appIAButtonBoundingClientRect.height;

        if (elementBoundingClientRect.height <= appIAButtonBoundingClientRect.height) {
            // Bouton avec une plus grande hauteur que le champ
            top += (appIAButtonBoundingClientRect.height - elementBoundingClientRect.height) / 2;
        }

        this._renderer2.setStyle(this._appIAButtonComponentRef.location.nativeElement, 'top', top.toString() + 'px');
    }
}
