import {NgModule} from '@angular/core';
import {SharedModule} from '@shared/shared.module';
import {CommonModule} from '@angular/common';
import {BienEditModule} from '@features/bien-edit/bien-edit.module';
import {CommunesModule} from '@features/communes/communes.module';
import {AppPublicitesComponent} from '@features/publicites/publicites.component';
import {EskimmoComponentsModule} from '@shared/angularJS/up-ng/eskimmo-components.module';
import {UsersModule} from '@features/users/users.module';
import {ConfigurationModule} from '@features/configuration/configuration.module';
import {ProceduresModule} from '@features/procedures/procedures.module';
import {DocumentsModule} from '@features/documents/documents.module';
import {EtudesModule} from '@features/etudes/etudes.module';
import {BonvisitesModule} from '@features/bonvisites/bonvisites.module';
import {DemandeursModule} from '@features/demandeurs/demandeurs.module';
import {ContactsModule} from '@features/contacts/contacts.module';
import {LocationsModule} from '@features/locations/locations.module';
import {VentesModule} from '@features/ventes/ventes.module';
import {ParticipantsModule} from '@features/participants/participants.module';
import {OffreachatsModule} from '@features/offreachats/offreachats.module';
import {MediasModule} from '@features/medias/medias.module';
import {DossiersModule} from '@features/dossiers/dossiers.module';
import {TemplatesModule} from '@features/templates/templates.module';
import {ContactsGroupModule} from '@features/contacts-group/contactsGroup.module';
import {InotModule} from '@features/inot/inot.module';
import {DictionariesModule} from '@features/dictionaries/dictionaries.module';
import {EstimationsModule} from '@features/estimations/estimations.module';
import {EmailsModule} from '@features/emails/emails.module';
import {ReferencesModule} from '@features/references/references.module';
import {TransfersModule} from '@features/transfers/transfers.module';
import {SitesModule} from '@features/sites/sites.module';
import {QuartiersModule} from '@features/quartiers/quartiers.module';
import {CityscanModule} from '@features/cityscan/cityscan.module';
import {BiensModule} from '@features/biens/biens.module';
import {ActualitesModule} from '@features/actualites/actualites.module';
import {ReportsModule} from '@features/reports/reports.module';

@NgModule({
    exports: [
        ActualitesModule,
        AppPublicitesComponent,
        BienEditModule,
        BiensModule,
        BonvisitesModule,
        CityscanModule,
        ContactsModule,
        CommunesModule,
        DemandeursModule,
        DictionariesModule,
        DossiersModule,
        EmailsModule,
        EstimationsModule,
        EtudesModule,
        InotModule,
        LocationsModule,
        MediasModule,
        OffreachatsModule,
        ProceduresModule,
        ReferencesModule,
        ReportsModule,
        SitesModule,
        TransfersModule,
        TemplatesModule,
        UsersModule,
        VentesModule,
    ],
    imports: [
        ActualitesModule,
        AppPublicitesComponent,
        BienEditModule,
        BiensModule,
        BonvisitesModule,
        CityscanModule,
        CommonModule,
        CommunesModule,
        ConfigurationModule,
        ContactsModule,
        ContactsGroupModule,
        DemandeursModule,
        DictionariesModule,
        DocumentsModule,
        DossiersModule,
        EmailsModule,
        EskimmoComponentsModule,
        EstimationsModule,
        EtudesModule,
        InotModule,
        LocationsModule,
        MediasModule,
        OffreachatsModule,
        ParticipantsModule,
        ProceduresModule,
        QuartiersModule,
        ReferencesModule,
        ReportsModule,
        SharedModule,
        SitesModule,
        TemplatesModule,
        TransfersModule,
        UsersModule,
        VentesModule,
    ],
})
export class FeaturesModule {
}
