import angularJS from '@shared/angularJS/global.ng';
import {IModule, IPromise, IQService} from 'angular';
import {NgClientService} from '@legacy/app/client/client';
import {NgDemandeurRecherche, NgLocation, NgLocationMandat} from '@legacy/app/managers/ressources';
import Ng2Template from '@models/templates/template/template.model';

export default function getManagersTemplate(module: IModule): void {
    (function (angular) {
        'use strict';

        module.service('TemplateManager', Service);

        /**
         * Manager template
         *
         * @param ClientService
         * @param $q
         * @constructor
         */
        Service.$inject = ['ClientService', '$q'];
        function Service(this: any, clientService: NgClientService, $q: IQService) {
            this.createFromNg2 = createFromNg2;
            this.getLocationMandat = getLocationMandat;
            this.getRechercheResume = getRechercheResume;

            /**
             * Routing
             */
            clientService.addRoute('template.location-mandat', {
                path: '/toolbox/templates/locations/mandat.{_format}',
                method: 'GET'
            });
            clientService.addRoute('template.recherche-resume', {
                path: '/toolbox/templates/recherches/resume.{_format}',
                method: 'GET'
            });

            function createFromNg2(ng2Template: Ng2Template) {
                if (!ng2Template) {
                    return $q.resolve(undefined!);
                }

                return $q.resolve({
                    category: {code: ng2Template.category.code, id: ng2Template.category.id},
                    id: ng2Template.id,
                    name: ng2Template.name,
                    orientation: ng2Template.orientation,
                    signatureElectronique: ng2Template.signatureElectronique,
                    size: ng2Template.size,
                    stylesheets: ng2Template.stylesheets,
                    tags: ng2Template.tags,
                    thumbnailUrl: ng2Template.thumbnailUrl,
                });
            };

            /**
             * @todo https://gitlab.soqrate.com/soqrate/noty/noty/issues/177 : A supprimer
             * Get mandat of Location
             *
             * @param location
             * @param mandat
             * @returns {Promise}
             */
            function getLocationMandat(location: NgLocation, mandat: NgLocationMandat) {
                return getFilledTemplate('location-mandat', {
                    _format: 'html',
                    locationId: location.id,
                    locationMandatId: mandat.id
                });
            }

            /**
             * @todo https://gitlab.soqrate.com/soqrate/noty/noty/issues/168 : A supprimer
             * Get resume of Recherche
             *
             * @param recherche
             * @returns {Promise}
             */
            function getRechercheResume(recherche: NgDemandeurRecherche) {
                return getFilledTemplate('recherche-resume', {_format: 'txt', rechercheId: recherche.id});
            }

            /**
             * Get filled template
             *
             * @param suffixTemplate
             * @param params
             * @returns {Promise}
             */
            function getFilledTemplate(suffixTemplate: string, params: unknown): IPromise<string> {
                return clientService.execRoute('template.' + suffixTemplate, params);
            }
        }
    })(angularJS);
}
