import {Component, inject, OnInit} from '@angular/core';
import {ModalService} from '@shared/modal/modal.service';
import {IFormFieldInput, IFormRadioOptions} from '@shared/form/form.interfaces';
import Procedure from '@models/procedures/procedure/procedure.model';
import {UserService} from '@models/users/user/user.service';
import {take, tap} from 'rxjs/operators';
import {delay} from 'rxjs';

@Component({
    selector: 'dc-procedure-providers-choice',
    standalone: false,
    templateUrl: 'procedure.providers-choice.component.html',
})
export class DCProcedureProvidersChoiceComponent implements OnInit {
    static readonly PROVIDERS_FORM_OPTIONS: IFormRadioOptions = {
        classInput: 'tw:mt-0',
        classLabel: 'tw:ml-3 tw:block tw:text-sm tw:font-medium tw:first-letter:uppercase',
        classList: 'tw:flex tw:items-center tw:mb-4',
        name: 'providers',
    };
    private _modalService = inject(ModalService);
    private _userService = inject(UserService);
    private _provider!: IFormFieldInput;
    private _providers: IFormFieldInput[] = [];

    get provider(): IFormFieldInput {
        return this._provider;
    }

    set provider(value: IFormFieldInput) {
        this._provider = value;
    }

    get providers(): IFormFieldInput[] {
        return this._providers;
    }

    get providersFormOptions(): IFormRadioOptions {
        return DCProcedureProvidersChoiceComponent.PROVIDERS_FORM_OPTIONS;
    }

    ngOnInit(): void {
        this._userService.last$.pipe(
            tap(currentUser => {
                if (currentUser.hasRoleSignatureElectroniqueYousign()) {
                    this._providers.push(Procedure.providers.yousign);
                }

                if (currentUser.hasRoleSignatureElectroniqueSoqrate()) {
                    this._providers.push(Procedure.providers.soqrate);
                }
            }),
            tap(_ => this.provider = this.providers[0]),
            delay(1),
            take(1),
        ).subscribe(() => {
            if (this.providers.length <= 1) {
                this.validChoice();
            }
        });
    }

    validChoice(): void {
        this._modalService.setResponse(this.provider.code);
    }
}
