import {inject, Injectable} from '@angular/core';
import {ContactFactory} from '@models/contacts/contact/contact.factory';
import {IContactApi} from '@models/contacts/contacts.interfaces';
import Contact from '@models/contacts/contact/contact.model';

@Injectable({providedIn: 'root'})
export class ContactsFactory {
    private _contactFactory = inject(ContactFactory);

    create(contactApis: IContactApi[] = []): Contact[] {
        return (contactApis || []).filter(contactApi => contactApi)
            .map(contactApi => this._contactFactory.create(contactApi));
    }
}
