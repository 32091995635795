export default `
<div ng-switch="$ctrl.loading">
  <p ng-switch-when="true" class="tw:text-center text-extra-large">
    <fa-icon [icon]="'spinner'" animation="spin"></fa-icon> {{'global.en_cours.LOAD' | translate}}
  </p>

  <div ng-switch-default>
    <div class="row negociation-revenus-header">
      <div ng-if="!!$ctrl.network" class="col-xs-12 col-md-5 header-legend">
        <div class="legend-bars">
          <div class="legend-bar-carre"><div class="bar-duo"></div><div class="bar-solo"></div></div>

          <div class="legend-bar-text"><div class="bar-duo">Duo</div><div class="bar-solo">Solo</div></div>
        </div>

        <a uib-popover="Dans les ventes en Duo (grâce au réseau) sont compris les biens en vente ainsi que les demandeurs ayant été archivés en Duo."
           popover-trigger="'outsideClick'" popover-placement="bottom-left" class="btn btn-o btn-null hidden-print">
          <span class="badge badge-info">?</span>
        </a>
      </div>

      <div class="col-xs-12 col-md-7 header-values">
        <div class="values-honoraires-total">
          <div class="values-label">Honoraires total</div>

          <div class="values-value">
            {{$ctrl.barNegociationRevenus.data.noty.honorairesTotal |
            currency:'€':2 * ($ctrl.barNegociationRevenus.data.noty.honorairesTotal % 1 !== 0)}}
          </div>
        </div>

        <div class="values-nombre-ventes">
          <div class="values-label">Nombre de ventes</div>

          <div class="values-value">{{$ctrl.barNegociationRevenus.data.noty.nombreVentes}}</div>
        </div>

        <div class="values-honoraires-moyen">
          <div class="values-label">Honoraires moyen</div>

          <div class="values-value">
            {{$ctrl.barNegociationRevenus.data.noty.honorairesMoyen |
            currency:'€':2 * ($ctrl.barNegociationRevenus.data.noty.honorairesMoyen % 1 !== 0)}}
          </div>
        </div>
      </div>
    </div>

    <div class="negociation-revenus-canvas"><canvas soq-chart="$ctrl.barNegociationRevenus"></canvas></div>

    <p class="text-right text-muted text-small">
      Mise à jour des données :
      <app-ng2-date [date]="$ctrl.barNegociationRevenus.date" format="shortDate"></app-ng2-date> à
      <app-ng2-date [date]="$ctrl.barNegociationRevenus.date" format="shortTime"></app-ng2-date>
    </p>
  </div>
</div>
`;
