export default `
<ng-switch on="$ctrl.etude.previsite" class="container-fluid tw:bg-white virtual-visit">
  <div ng-switch-default class="noty-container">
    <h2 class="tw:text-center virtual-visit-logo">
      <a href="http://www.previsite.fr" target="_blank">
        <app-ng2-img [src]="'assets/images/previsite/logo.svg'"></app-ng2-img>
      </a>
    </h2>

    <h3 class="tw:text-center margin-bottom-30">{{'bien.virtual_visit.disabled.LABEL' | translate}}</h3>

    <div class="row margin-top-15 margin-bottom-15">
      <div class="col-xs 12 col-sm-4">
        <h2 class="text-bold">Les + propriétaire</h2>

        <ul class="list-unstyled">
          <li>
            <h4>
              <span class="text-success"><fa-icon [icon]="['far', 'check-circle']"></fa-icon></span>
              Présenter de manière innovante
            </h4>
          </li>

          <li>
            <h4>
              <span class="text-success"><fa-icon [icon]="['far', 'check-circle']"></fa-icon></span>
              Valoriser le bien
            </h4>
          </li>

          <li>
            <h4>
              <span class="text-success"><fa-icon [icon]="['far', 'check-circle']"></fa-icon></span>
              Visiter sans déranger
            </h4>
          </li>
        </ul>
      </div>

      <div class="col-xs 12 col-sm-4">
        <h2 class="text-bold">Les + acquéreur</h2>

        <ul class="list-unstyled">
          <li>
            <h4>
              <span class="text-success"><fa-icon [icon]="['far', 'check-circle']"></fa-icon></span>
              Effectuer une première sélection
            </h4>
          </li>

          <li>
            <h4>
              <span class="text-success"><fa-icon [icon]="['far', 'check-circle']"></fa-icon></span>
              Se projeter facilement
            </h4>
          </li>

          <li>
            <h4>
              <span class="text-success"><fa-icon [icon]="['far', 'check-circle']"></fa-icon></span>
              Visiter un bien éloigné géographiquement
            </h4>
          </li>
        </ul>
      </div>

      <div class="col-xs 12 col-sm-4">
        <h2 class="text-bold">Les + négociateur</h2>

        <ul class="list-unstyled">
          <li>
            <h4>
              <span class="text-success"><fa-icon [icon]="['far', 'check-circle']"></fa-icon></span>
              Faciliter la prise de mandats exclusifs
            </h4>
          </li>

          <li>
            <h4>
              <span class="text-success"><fa-icon [icon]="['far', 'check-circle']"></fa-icon></span>
              Éviter les visites infructeuses
            </h4>
          </li>

          <li>
            <h4>
              <span class="text-success"><fa-icon [icon]="['far', 'check-circle']"></fa-icon></span>
              Se différencier des autres acteurs du marché
            </h4>
          </li>
        </ul>
      </div>
    </div>

    <h3 class="tw:text-center margin-top-30">
      <esk-contact type="commerce" email-content="bien.virtual_visit.disabled"></esk-contact>

      <span>pour obtenir plus d’informations sur la mise à disposition du module Prévisite.</span>
    </h3>
  </div>

  <ng-switch ng-switch-when="true" on="!!$ctrl.dossier.id">
    <h2 ng-switch-default class="tw:text-center">
      {{'bien.virtual_visit.NO_BIEN_ID' | translate}}
    </h2>

    <div ng-switch-when="true">
      <ng-switch on="$ctrl.loader.dossierUpdating" class="margin-top-5 tw:text-center">
        <div ng-switch-when="true">
          <h3 class="margin-bottom-20">
            <fa-icon [icon]="'spinner'" animation="spin"></fa-icon> {{'bien.virtual_visit.get.LABEL' | translate}}
          </h3>
        </div>

        <div ng-switch-default class="row">
          <ng-switch on="$ctrl.loader.visitVirtualEditing" class="virtual-visit-edit col-xs-12"
                     ng-class="!$ctrl.dossier.bien.option.urlVisiteVirtuelle ? '' : 'col-md-5 col-lg-6'">
            <h2 class="margin-top-20 tw:text-center virtual-visit-logo">
              <a href="http://www.previsite.fr" target="_blank">
                <app-ng2-img [src]="'assets/images/previsite/logo.svg'"></app-ng2-img>
              </a>
            </h2>

            <div ng-switch-default>
              <h3 class="margin-bottom-20">
                {{'bien.virtual_visit.open.LABEL' | translate:'{creation: ' + !$ctrl.dossier.bien.option.urlVisiteVirtuelle + '}'}}
              </h3>

              <p class="margin-bottom-10" ng-bind-html="'bien.virtual_visit.open.TEXT' | translate"></p>

              <button class="btn btn-primary" type="button" ng-click="$ctrl.openVirtualVisit()">
                <fa-icon [icon]="'house'"></fa-icon>

                {{'bien.virtual_visit.open.BTN' | translate:'{creation: ' + !$ctrl.dossier.bien.option.urlVisiteVirtuelle + '}'}}
              </button>

              <button ng-if="!!$ctrl.dossier.bien.option.urlVisiteVirtuelle" type="button"
                      class="btn btn-danger btn-o" ng-click="$ctrl.removeVirtualVisit()">
                <fa-icon [icon]="['far', 'file']"></fa-icon> {{'global.label.REMOVE' | translate}}
              </button>
            </div>

            <div ng-switch-when="true">
              <h3 class="margin-bottom-20">
                <fa-icon [icon]="'spinner'" animation="spin"></fa-icon> {{'bien.virtual_visit.edit.LABEL' | translate}}
              </h3>

              <p class="margin-bottom-10" ng-bind-html="'bien.virtual_visit.edit.TEXT' | translate"></p>

              <button class="btn btn-primary" type="button" ng-click="$ctrl.openVirtualVisit()">
                <fa-icon [icon]="'house'"></fa-icon> {{'bien.virtual_visit.edit.BTN' | translate}}
              </button>
            </div>

            <div class="margin-top-30">
              <esk-contact></esk-contact>

              <span>{{"pour toutes questions et ou informations sur Previsite." | translate}}</span>
            </div>
          </ng-switch>

          <div ng-if="!!$ctrl.dossier.bien.option.urlVisiteVirtuelle"
               class="col-xs-12 col-md-7 col-lg-6 virtual-visit-video">
            <div class="video">
              <iframe id="video" ng-src="{{$ctrl.urlVisiteVirtuelleTrusted}}" allow="fullscreen"
                      sandbox="allow-scripts"></iframe>

              <a ng-href="{{$ctrl.dossier.bien.option.urlVisiteVirtuelle}}" target="_blank">
                <fa-icon [icon]="'external-link-alt'"></fa-icon>
              </a>
            </div>
          </div>
        </div>
      </ng-switch>
    </div>
  </ng-switch>
</ng-switch>
`;
