import angularJS from '@shared/angularJS/global.ng';
import {IModule} from 'angular';
import {NgContact} from '@legacy/app/managers/ressources';
import {NgContactManager} from '@legacy/app/managers/managers';
import {ModalService} from '@shared/modal/modal.service';
import {from, of} from 'rxjs';
import {switchMap, take} from 'rxjs/operators';
import {ToasterService} from '@shared/toaster/toaster.service';
import {PersonFactory} from '@models/contacts/person/person.factory';
import Contact from '@models/contacts/contact/contact.model';
import {ContactFactory} from '@models/contacts/contact/contact.factory';

export default function getContactsWalletCurrentCtrl(module: IModule): void {
    (function (angular) {
        'use strict';

        module.component('eskContactsWalletCurrentCtrl', {
            controller: Controller,
            templateUrl: 'src/app/legacy/templates/contacts/controllers/wallet.current.html',
        });

        /**
         * Controller for list of contacts
         *
         * @param ContactManager
         * @param Ng2ModalService
         * @param Ng2ToasterService
         * @param Ng2PersonFactory
         * @param Ng2ContactFactory
         */
        Controller.$inject = ['ContactManager', 'Ng2ModalService', 'Ng2ToasterService', 'Ng2PersonFactory', 'Ng2ContactFactory'];
        function Controller(this: any,
                            contactManager: NgContactManager,
                            ng2ModalService: ModalService,
                            ng2ToasterService: ToasterService,
                            ng2PersonFactory: PersonFactory,
                            ng2ContactFactory: ContactFactory) {
            const $ctrl = this;

            $ctrl.createContact = createContact;
            $ctrl.onChangeFilter = onChangeFilter;
            $ctrl.onChangeCWIEA = onChangeCWIEA;
            $ctrl.options = {
                click: openModaleEdition,
                nameDossiers: 'CONTACTS_LIST',
                loader: {updating: true},
                visibleFields: {select: false},
            };
            $ctrl.onChangeFilter();

            function createContact() {
                const person = ng2PersonFactory.createVirgin();

                return ng2ModalService.ngOpen$<Contact>('ContactModalEdit', {
                    resolve: {contact: person, forceSubmit: false},
                }).subscribe(contact => {
                    if (contact) {
                        ng2ToasterService.success('Création d\'un contact', contact.convivialName + ' est créé.');
                        $ctrl.onChangeCWIEA();
                    }
                });
            }

            /**
             * Update contacts list
             *
             * @param keywords
             */
            function onChangeFilter(keywords: string) {
                $ctrl.options.loader.updating = true;
                contactManager.getAllForEtude({limit: 50, keywords, invalidEmailAddress: $ctrl.contactsWithInvalidEmailAddress})
                    .then(contacts => $ctrl.contacts = contacts)
                    .finally(() => $ctrl.options.loader.updating = false);
            }

            /**
             * On change contacts with invalid email address
             */
            function onChangeCWIEA() {
                if ($ctrl.contactsWithInvalidEmailAddress !== true) {
                    $ctrl.contactsWithInvalidEmailAddress = undefined;
                }

                $ctrl.resetKeywords();
                $ctrl.onChangeFilter();
            }

            /**
             * Open modale
             *
             * @param selectedContact
             */
            function openModaleEdition(selectedContact: NgContact) {
                ng2ContactFactory.getByLink$('/contacts/' + selectedContact.id.toString()).pipe(
                    switchMap(contact => ng2ModalService.ngOpen$<Contact>('ContactModalEdit', {
                        resolve: {contact, forceSubmit: false},
                    })),
                    switchMap(contact => {
                        if (!contact) {
                            return of(null);
                        }

                        return from(contactManager.createFromNg2(contact));
                    }),
                    take(1),
                ).subscribe(ngContact => {
                    if (!ngContact) {
                        return;
                    }

                    const idx = $ctrl.contacts.collection.findIndex(c => c.id === ngContact.id);

                    $ctrl.contacts.collection[idx] = ngContact;
                });
            }
        }
    })(angularJS);
}
