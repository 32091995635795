import {inject, Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {IBrowserOnline} from '@shared/browser/browser.interfaces';
import {BrowserWindowService} from '@shared/browser/window/browser.window.service';
import {map, tap} from 'rxjs/operators';
import {ModalService} from '@shared/modal/modal.service';
import {ToasterService} from '@shared/toaster/toaster.service';

@Injectable({providedIn: 'root'})
export class BrowserOnlineService {
    static readonly messages = {
        offline: {
            TITLE: 'Noty - Hors ligne',
            MESSAGE: 'Vous êtes actuellement hors ligne. Veuillez vérifier votre connexion Internet.<br>Tant que ' +
                'vous serez hors ligne, certaines fonctionnalités peuvent être indisponibles.<br><br>Pour plus ' +
                'd\'informations, contactez le support technique.',
        },
    };
    private _browserWindowService = inject(BrowserWindowService);
    private _modalService = inject(ModalService);
    private _toasterService = inject(ToasterService);
    private _isOnlineSource = new BehaviorSubject<IBrowserOnline>({isOn: false});
    private _isOn$ = this._isOnlineSource.asObservable().pipe(map(isOnline => isOnline.isOn));

    constructor() {
        this._isOnlineSource.next({isOn: this._browserWindowService.getNavigator().onLine});
        this._browserWindowService.addEventListener('online', () => this._isOnlineSource.next({isOn: true}));
        this._browserWindowService.addEventListener('offline', () => this._isOnlineSource.next({isOn: false}));
    }

    get isOn$(): Observable<boolean> {
        return this._isOn$;
    }

    checkAndPrevent$(withToast = true): Observable<boolean> {
        return this.isOn$.pipe(tap(isOn => {
            if (!isOn) {
                if (withToast) {
                    this._toasterService.error('Vous êtes hors ligne',
                        'Cliquez sur <span class="tw:underline">En savoir plus</span> dans la bannière pour plus d\'informations.',
                        {enableHtml: true});
                } else {
                    this.openInfosOffLine();
                }
            }
        }));
    }

    openInfosOffLine(): void {
        this._modalService.openInformation({
            comments: BrowserOnlineService.messages.offline.MESSAGE,
            title: BrowserOnlineService.messages.offline.TITLE,
            status: ModalService.statuts.WARNING,
        });
    }
}
