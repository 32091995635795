export default `
<div class="list-ventes">
  <div ng-if="$ctrl.ventes.totalItems > 0 && (!!$ctrl.options.visibleFields.select || !!$ctrl.options.nameDossiers)"
       class="padding-bottom-10 list-infos hidden-print">
    <span ng-if="$ctrl.options.visibleFields.select && $ctrl.options.displayMode!='table'"
          ng-include="'src/app/legacy/templates/eskimmo/components/list/select.html'" class="select-all"></span>

    <span ng-if="!!$ctrl.options.nameDossiers" class="text-justify">
      {{$ctrl.options.nameDossiers | translate:'{NB: ' + $ctrl.ventes.totalItems + '}'}}
    </span>

    <span ng-if="!!$ctrl.filter.nom">
      {{"dans" | translate}}

      <span class="text-emphasis text-bold">{{$ctrl.filter.nom | lowercase}}</span>
    </span>

    <span ng-if="$ctrl.options.visibleFields.bullet && $ctrl.options.visibleFields.bullet_help">
      <a uib-popover-template="'src/app/legacy/templates/biens/results.color-bullet.html'"
         popover-trigger="'outsideClick'" popover-placement="bottom" class="btn btn-o btn-null">
        <span class="badge badge-info">?</span>
      </a>
    </span>
  </div>

  <ng-switch on="$ctrl.options.loader.updating">
    <p class="tw:text-center text-extra-large" ng-switch-when="true">
      <fa-icon [icon]="'spinner'" animation="spin"></fa-icon> Mise à jour de vos biens en vente
    </p>

    <ng-switch ng-switch-default on="$ctrl.options.displayMode" ng-if="$ctrl.ventes.totalItems > 0">
      <!-- Tableau -->
      <div ng-switch-default class="table-responsive">
        <table class="table table-hover table-biens">
          <thead>
          <tr>
            <th
              ng-if="$ctrl.options.visibleFields.select || $ctrl.options.visibleFields.bullet || !!$ctrl.options.deleteResultRemoved">
              <ng-include ng-if="$ctrl.options.visibleFields.select" class="select-all"
                          src="'src/app/legacy/templates/eskimmo/components/list/select.html'"></ng-include>
            </th>

            <th ng-if="$ctrl.options.visibleFields.photo"></th>

            <th class="tw:text-center">
              <esk-button-sort sort-type="{{$ctrl.options.typeFilter}}" sort-code="reference"
                               current-sort="$ctrl.filter.queryParameters.sort"
                               on-change-sort="$ctrl.onChangeSort">
              </esk-button-sort>
            </th>

            <th ng-if="$ctrl.options.visibleFields.statut" class="tw:text-center">{{'Statut' | translate}}</th>

            <th ng-if="$ctrl.options.visibleFields.archiveDateVente" class="tw:text-center">
              {{"Date de vente" | translate}}
            </th>

            <th ng-if="$ctrl.options.visibleFields.nomContactsGroup" class="tw:text-center">
              <esk-button-sort sort-type="{{$ctrl.options.typeFilter}}" sort-code="nomProprietaire"
                               current-sort="$ctrl.filter.queryParameters.sort"
                               on-change-sort="$ctrl.onChangeSort">
              </esk-button-sort>
            </th>

            <th ng-if="$ctrl.options.visibleFields.nature" class="tw:text-center">
              <esk-button-sort sort-type="{{$ctrl.options.typeFilter}}" sort-code="nature"
                               current-sort="$ctrl.filter.queryParameters.sort"
                               on-change-sort="$ctrl.onChangeSort">
              </esk-button-sort>
            </th>

            <th ng-if="$ctrl.options.visibleFields.localisation" class="tw:text-center">
              <esk-button-sort sort-type="{{$ctrl.options.typeFilter}}" sort-code="commune"
                               current-sort="$ctrl.filter.queryParameters.sort"
                               on-change-sort="$ctrl.onChangeSort">
              </esk-button-sort>
            </th>

            <th ng-if="$ctrl.options.visibleFields.nombrePieces" class="tw:text-center">
              <esk-button-sort sort-type="{{$ctrl.options.typeFilter}}" sort-code="nombrePieces"
                               current-sort="$ctrl.filter.queryParameters.sort"
                               on-change-sort="$ctrl.onChangeSort">
              </esk-button-sort>
            </th>

            <th ng-if="$ctrl.options.visibleFields.nombreChambres" class="tw:text-center">
              <esk-button-sort sort-type="{{$ctrl.options.typeFilter}}" sort-code="nombreChambres"
                               current-sort="$ctrl.filter.queryParameters.sort"
                               on-change-sort="$ctrl.onChangeSort">
              </esk-button-sort>
            </th>

            <th ng-if="$ctrl.options.visibleFields.price" class="tw:text-center">
              <esk-button-sort sort-type="{{$ctrl.options.typeFilter}}" sort-code="prixHni"
                               current-sort="$ctrl.filter.queryParameters.sort"
                               on-change-sort="$ctrl.onChangeSort">
              </esk-button-sort>
            </th>

            <th ng-if="$ctrl.options.visibleFields.priceM2" class="tw:text-center">Prix au m²</th>

            <th ng-if="$ctrl.options.visibleFields.priceCession" class="tw:text-center">Prix de cession</th>

            <th ng-if="$ctrl.options.visibleFields.angleRight" class="hidden-print"></th>
          </tr>
          </thead>

          <tr class="line" ng-repeat="vente in $ctrl.ventes.collection">
            <td class="select hidden-print"
                ng-if="$ctrl.options.visibleFields.select || $ctrl.options.visibleFields.bullet || !!$ctrl.options.deleteResultRemoved">
              <div ng-if="$ctrl.options.visibleFields.bullet">
                <span ng-if="vente._esk.result._esk.colorStatus.classBullet['bullet-green']" class="bullet-green">
                  <fa-icon [icon]="'circle'"></fa-icon>
                </span>

                <span ng-if="vente._esk.result._esk.colorStatus.classBullet['bullet-orange']" class="bullet-orange">
                  <fa-icon [icon]="'circle'"></fa-icon>
                </span>

                <span ng-if="vente._esk.result._esk.colorStatus.classBullet['bullet-blue']" class="bullet-blue">
                  <fa-icon [icon]="'circle'"></fa-icon>
                </span>

                <span ng-if="vente._esk.result._esk.colorStatus.classBullet['compromis bullet-red']"
                      class="compromis bullet-red"><fa-icon [icon]="'bookmark'"></fa-icon></span>

                <span ng-if="vente._esk.result._esk.colorStatus.classBullet['bullet-red']" class="bullet-red">
                  <fa-icon [icon]="'circle'"></fa-icon>
                </span>
              </div>

              <ng-switch on="!!$ctrl.options.deleteResultRemoved && vente._esk.result.status === 'match_removed'">
                <div ng-switch-when="true" class="btns-interest">
                  <button type="button" class="dislike text-large" ng-click="$ctrl.options.deleteResultRemoved(vente)"
                          uib-tooltip="Non intéressé" tooltip-placement="bottom-left">
                    <fa-icon [icon]="'times'"></fa-icon>
                  </button>
                </div>

                <div ng-switch-default>
                  <div ng-if="$ctrl.options.visibleFields.select" class="checkbox clip-check check-primary">
                    <input type="checkbox" id="table-biens-select-{{vente.id}}" ng-model="vente._esk.select"
                           ng-change="$ctrl.onSelect(vente)">

                    <label for="table-biens-select-{{vente.id}}"></label>
                  </div>
                </div>
              </ng-switch>
            </td>

            <td ng-if="$ctrl.options.visibleFields.photo" class="tw:text-center confrere-parent-alert"
                ng-click="$ctrl.onClick(vente)">
              <div ng-if="!vente.bien._esk.interne" class="confrere-alert">
                <span class="label label-confrere">C</span>
              </div>

              <esk-biens-wallet-display-photo popover-popup-delay="800" popover-append-to-body="true"
                                              uib-popover-template="'src/app/legacy/templates/ventes/components/list/details.html'"
                                              popover-trigger="'mouseenter'" popover-placement="right"
                                              popover-class="dossier-popover"
                                              popover-enable="{{$ctrl.options.visibleFields.popover}}"
                                              photo-link="vente.bien._links.premierePhoto.href"
                                              routing="!!$ctrl.click ? '' : vente._esk.routing">
              </esk-biens-wallet-display-photo>
            </td>

            <td class="tw:text-center">{{vente.bien.reference}}</td>

            <td ng-if="$ctrl.options.visibleFields.statut" class="tw:text-center">
              <app-vente-statut [ng-vente]="vente"></app-vente-statut>

              <div ng-if="[$ctrl.STATUTS.BROUILLON, $ctrl.STATUTS.COMPROMIS, $ctrl.STATUTS.DIFFUSE, $ctrl.STATUTS.MANDAT].includes(vente.statut)"
                   class="text-muted padding-top-10">
                <div ng-if="vente.statut === $ctrl.STATUTS.BROUILLON && !!vente.mandatType.label" title="Type de mandat">
                  {{vente.mandatType.label}}
                </div>

                <div ng-if="(vente.statut === $ctrl.STATUTS.MANDAT || vente.statut === $ctrl.STATUTS.DIFFUSE) && !!vente.mandatDate"
                     title="Date de mandat">
                  <app-ng2-date [date]="vente.mandatDate" format="shortDate"></app-ng2-date>
                </div>

                <div ng-if="vente.statut === $ctrl.STATUTS.COMPROMIS && !!vente.dateCompromis" title="Date de compromis">
                  <app-ng2-date [date]="vente.dateCompromis" format="shortDate"></app-ng2-date>
                </div>
              </div>
            </td>

            <td ng-if="$ctrl.options.visibleFields.archiveDateVente" class="tw:text-center">
              <div ng-if="!!vente.archiveDateVente" class="text-capitalize">
                <app-ng2-date [date]="vente.archiveDateVente" format="shortDate"></app-ng2-date>
              </div>
            </td>

            <td ng-if="$ctrl.options.visibleFields.nomContactsGroup" class="col-nom">
              <div class="text ellipsis">{{vente.bien.contactsGroup.nom}}</div>
            </td>

            <td ng-if="$ctrl.options.visibleFields.nature" class="tw:text-center">
              {{vente.bien.nature.label}}
            </td>

            <td ng-if="$ctrl.options.visibleFields.localisation" class="col-adresse tw:text-center">
              <ng-switch on="!!vente.bien.option.commune.fullname">
                <div ng-swicth-default class="thin-text text-bold ellipsis">{{vente.bien.option.commune.fullname}}</div>

                <div ng-switch-when="false" class="text-muted">{{'Aucune commune renseignée' | translate}}</div>
              </ng-switch>

              <div ng-show="!!vente.bien.option.quartier.name">{{vente.bien.option.quartier.name}}</div>

              <div ng-if="$ctrl.options.visibleFields.preciseLocalisation" class="adresse ellipsis">
                <span ng-if="vente.bien._esk.interne && !!vente.bien.option.numeroVoie" class="number">
                  {{vente.bien.option.numeroVoie}}
                </span>

                <span ng-if="vente.bien._esk.interne && !!vente.bien.option.nomVoie" class="nom">
                  {{vente.bien.option.nomVoie}}
                </span>

                <span ng-if="!vente.bien._esk.interne && !!vente.bien.option.adresseReseau" class="nom">
                  {{vente.bien.option.adresseReseau}}
                </span>
              </div>
            </td>

            <td ng-if="$ctrl.options.visibleFields.nombrePieces" class="tw:text-center">
              {{vente.bien.option.nombrePieces}}
            </td>

            <td ng-if="$ctrl.options.visibleFields.nombreChambres" class="tw:text-center">
              {{vente.bien.option.nombreChambres}}
            </td>

            <td ng-if="$ctrl.options.visibleFields.price" class=" tw:text-center">
              <app-vente-price [ng-vente]="vente" [options]="{enabledPriceM2: !$ctrl.options.visibleFields.priceM2}">
              </app-vente-price>
            </td>

            <td ng-if="$ctrl.options.visibleFields.priceM2" class=" tw:text-center">
              <app-vente-price-m2 [ng-vente]="vente"></app-vente-price-m2>
            </td>

            <td ng-if="$ctrl.options.visibleFields.priceCession" class="tw:text-center">
              <div class="vente-price label label-price">
                {{vente.archivePrixCession | currency:'€':2 * (vente.archivePrixCession % 1 !== 0)}}
              </div>
            </td>

            <td ng-if="$ctrl.options.visibleFields.angleRight" class="tw:text-center hidden-print angle-right"
                ng-click="$ctrl.onClick(vente)">
              <a href="{{vente._esk.routing}}"><div><fa-icon [icon]="'angle-right'"></fa-icon></div></a>
            </td>
          </tr>
        </table>
      </div>

      <!-- Liste -->
      <ul ng-switch-when="table-photo" class="list-group table-photo-biens">
        <li class="list-group-item line" ng-repeat="vente in $ctrl.ventes.collection">
          <div class="row">
            <div
              ng-if="$ctrl.options.visibleFields.select || $ctrl.options.visibleFields.bullet || !!$ctrl.options.deleteResultRemoved"
              class="col-xs-1 col-sm-1">
              <div ng-if="$ctrl.options.visibleFields.bullet">
                <span ng-if="vente._esk.result._esk.colorStatus.classBullet['bullet-green']" class="bullet-green">
                  <fa-icon [icon]="'circle'"></fa-icon>
                </span>

                <span ng-if="vente._esk.result._esk.colorStatus.classBullet['bullet-orange']" class="bullet-orange">
                  <fa-icon [icon]="'circle'"></fa-icon>
                </span>

                <span ng-if="vente._esk.result._esk.colorStatus.classBullet['bullet-blue']" class="bullet-blue">
                  <fa-icon [icon]="'circle'"></fa-icon>
                </span>

                <span ng-if="vente._esk.result._esk.colorStatus.classBullet['compromis bullet-red']"
                      class="compromis bullet-red"><fa-icon [icon]="'bookmark'"></fa-icon></span>

                <span ng-if="vente._esk.result._esk.colorStatus.classBullet['bullet-red']" class="bullet-red">
                  <fa-icon [icon]="'circle'"></fa-icon>
                </span>
              </div>

              <ng-switch on="!!$ctrl.options.deleteResultRemoved && vente._esk.result.status === 'match_removed'"
                         class="select">
                <div ng-switch-when="true" class="btns-interest">
                  <button type="button" class="dislike text-large" ng-click="$ctrl.options.deleteResultRemoved(vente)"
                          uib-tooltip="Non intéressé" tooltip-placement="bottom-left">
                    <fa-icon [icon]="'times'"></fa-icon>
                  </button>
                </div>

                <div ng-switch-default>
                  <div ng-if="$ctrl.options.visibleFields.select" class="checkbox clip-check check-primary">
                    <input type="checkbox" id="table-photo-biens-select-{{vente.id}}" ng-model="vente._esk.select"
                           ng-change="$ctrl.onSelect(vente)">

                    <label for="table-photo-biens-select-{{vente.id}}"></label>
                  </div>
                </div>
              </ng-switch>
            </div>

            <div
              ng-class="{'col-xs-11 col-sm-11': $ctrl.options.visibleFields.bullet || $ctrl.options.visibleFields.select, 'col-xs-12 col-sm-12': !$ctrl.options.visibleFields.bullet && !$ctrl.options.visibleFields.select}">
              <div ng-if="$ctrl.options.visibleFields.photo" class="photo-display confrere-parent-alert"
                   ng-click="$ctrl.onClick(vente)">
                <div class="confrere-alert" ng-if="!vente.bien._esk.interne">
                  <span class="label label-confrere">{{"Confrères" | translate}}</span>
                </div>

                <esk-biens-wallet-display-photo photo-link="vente.bien._links.premierePhoto.href"
                                                routing="!!$ctrl.click ? '' : vente._esk.routing">
                </esk-biens-wallet-display-photo>
              </div>

              <div class="informations">
                <div class="margin-bottom-10 resume">
                  <span ng-if="$ctrl.options.visibleFields.nature" class="label label-nature">
                    {{vente.bien.nature.label}}
                  </span>

                  <span ng-if="$ctrl.options.visibleFields.statut" class="tw:mx-2">
                    <app-vente-statut [ng-vente]="vente" class="tw:inline-block tw:text-xs"></app-vente-statut>
                  </span>

                  <app-vente-price ng-if="$ctrl.options.visibleFields.price" class="tw:inline-block tw:text-xs"
                                   [options]="{enabledPriceM2: false, inlineNV: true}" [ng-vente]="vente">
                  </app-vente-price>

                  <div ng-if="$ctrl.options.visibleFields.price && vente.bien.superficie"
                       class="tw:inline-block tw:text-xs">
                    Soit <app-vente-price-m2 [ng-vente]="vente"></app-vente-price-m2> du m²
                  </div>

                  <div ng-if="$ctrl.options.visibleFields.priceCession" class="tw:inline-block vente-price label label-price">
                    {{vente.archivePrixCession | currency:'€':2 * (vente.archivePrixCession % 1 !== 0)}}
                  </div>
                </div>

                <div class="margin-bottom-10">
                  <div class="second-line">
                    <span class="tw:text-center margin-right-5 pull-left">{{vente.bien.reference}}</span>

                    <span ng-if="$ctrl.options.visibleFields.localisation" class="localisation">
                      <fa-icon [icon]="'map-marker-alt'" size="lg"></fa-icon>

                      <p ng-if="$ctrl.options.visibleFields.preciseLocalisation && vente.bien._esk.interne && !!vente.bien.option.numeroVoie">
                        {{vente.bien.option.numeroVoie}}
                      </p>

                      <p ng-if="$ctrl.options.visibleFields.preciseLocalisation && vente.bien._esk.interne && !!vente.bien.option.nomVoie"
                         class="adresse ellipsis">{{vente.bien.option.nomVoie}}</p>

                      <p ng-if="$ctrl.options.visibleFields.preciseLocalisation && !vente.bien._esk.interne && !!vente.bien.option.adresseReseau"
                         class="adresse ellipsis">{{vente.bien.option.adresseReseau}}</p>

                      <ng-switch on="!!vente.bien.option.commune.fullname">
                        <p ng-swicth-default class="text-bold">{{vente.bien.option.commune.fullname}}</p>

                        <p ng-switch-when="false" class="text-muted">{{'Aucune commune renseignée' | translate}}</p>
                      </ng-switch>

                      <p ng-show="!!vente.bien.option.codePostal">{{vente.bien.option.codePostal}}</p>

                      <p ng-show="!!vente.bien.option.quartier.name">{{vente.bien.option.quartier.name}}</p>
                    </span>
                  </div>
                </div>

                <div class="margin-bottom-10">
                  <h5 ng-if="$ctrl.options.visibleFields.nomContactsGroup"
                      class="margin-bottom-10 ellipsis">{{vente.bien.contactsGroup.nom}}</h5>

                  <div class="margin-bottom-10 ellipsis">{{vente.bien.titre}}</div>

                  <div class="descriptif margin-bottom-10 ellipsis">{{vente.bien.descriptif}}</div>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>

      <!-- Vignettes -->
      <div ng-switch-when="collection" class="collection-biens">
        <div class="content"
             ng-class="{private: $ctrl.options.visibleFields.nomContactsGroup, public: !$ctrl.options.visibleFields.nomContactsGroup}"
             ng-repeat="vente in $ctrl.ventes.collection">
          <div class="thumbnail">
            <div class="content-photo">
              <div class="row photo-header">
                <div ng-if="$ctrl.options.visibleFields.bullet" class="col-xs-2">
                  <span ng-if="vente._esk.result._esk.colorStatus.classBullet['bullet-green']" class="bullet-green">
                    <fa-icon [icon]="'circle'"></fa-icon>
                  </span>

                  <span ng-if="vente._esk.result._esk.colorStatus.classBullet['bullet-orange']" class="bullet-orange">
                    <fa-icon [icon]="'circle'"></fa-icon>
                  </span>

                  <span ng-if="vente._esk.result._esk.colorStatus.classBullet['bullet-blue']" class="bullet-blue">
                    <fa-icon [icon]="'circle'"></fa-icon>
                  </span>

                  <span ng-if="vente._esk.result._esk.colorStatus.classBullet['compromis bullet-red']"
                        class="compromis bullet-red"><fa-icon [icon]="'bookmark'"></fa-icon></span>

                  <span ng-if="vente._esk.result._esk.colorStatus.classBullet['bullet-red']" class="bullet-red">
                    <fa-icon [icon]="'circle'"></fa-icon>
                  </span>
                </div>

                <div class="col-xs-2">
                  <ng-switch on="!!$ctrl.options.deleteResultRemoved && vente._esk.result.status === 'match_removed'"
                             class="select">
                    <div ng-switch-when="true" class="btns-interest">
                      <button type="button" class="dislike text-large" ng-click="$ctrl.options.deleteResultRemoved(vente)"
                              uib-tooltip="Non intéressé" tooltip-placement="bottom-left">
                        <fa-icon [icon]="'times'"></fa-icon>
                      </button>
                    </div>

                    <div ng-switch-default>
                      <div ng-if="$ctrl.options.visibleFields.select" class="checkbox clip-check check-primary">
                        <input type="checkbox" id="collection-biens-select-{{vente.id}}" ng-model="vente._esk.select"
                               ng-change="$ctrl.onSelect(vente)">

                        <label for="collection-biens-select-{{vente.id}}"></label>
                      </div>
                    </div>
                  </ng-switch>
                </div>

                <div class="col-xs-8 confrere-parent-alert">
                  <div class="confrere-alert" ng-if="!vente.bien._esk.interne">
                    <span class="label label-confrere">{{"Confrères" | translate}}</span>
                  </div>
                </div>
              </div>

              <div class="photo-price">
                <app-vente-price ng-if="$ctrl.options.visibleFields.price" [ng-vente]="vente"
                                 [options]="{inlineNV: true}" class="tw:inline-block tw:text-xs"></app-vente-price>

                <div ng-if="$ctrl.options.visibleFields.priceCession" class="tw:inline-block vente-price label label-price">
                  {{vente.archivePrixCession | currency:'€':2 * (vente.archivePrixCession % 1 !== 0)}}
                </div>
              </div>

              <div ng-click="$ctrl.onClick(vente)">
                <esk-biens-wallet-display-photo ng-if="$ctrl.options.visibleFields.photo"
                                                photo-link="vente.bien._links.premierePhoto.href"
                                                routing="!!$ctrl.click ? '' : vente._esk.routing">
                </esk-biens-wallet-display-photo>
              </div>
            </div>

            <div ng-if="$ctrl.options.visibleFields.localisation" class="localisation">
              <div ng-if="!!vente.bien.option.commune.fullname"
                   class="text-bold tw:text-center text-extra-large commune ellipsis">{{vente.bien.option.commune.fullname}}</div>

              <div ng-if="!vente.bien.option.commune.fullname"
                   class="text-muted tw:text-center">{{'Aucune commune renseignée' | translate}}</div>

              <div class="margin-top-10 tw:text-center" ng-show="vente.bien.option.quartier">
                <p>
                  {{"Quartier" | translate}} : <span class="text-bold">{{vente.bien.option.quartier.name}}</span>
                </p>
              </div>
            </div>

            <div ng-if="$ctrl.options.visibleFields.nomContactsGroup"
                 class="owners ellipsis">{{vente.bien.contactsGroup.nom}}</div>

            <div class="last-line">
              <div ng-if="$ctrl.options.visibleFields.statut" class="statut">
                <app-vente-statut [ng-vente]="vente" class="tw:absolute tw:-top-2 tw:w-max"></app-vente-statut>
              </div>

              <div class="dossier"><span class="text-bold">{{vente.bien.reference}}</span></div>
            </div>
          </div>
        </div>
      </div>

      <!-- Box -->
      <ul ng-switch-when="box" class="list-group-pimped box-biens">
        <li class="list-pimped list-unstyled line" ng-repeat="vente in $ctrl.ventes.collection">
          <div class="media">
            <div class="media-left">
              <esk-biens-wallet-display-photo ng-if="$ctrl.options.visibleFields.photo"
                                              photo-link="vente.bien._links.premierePhoto.href"
                                              routing="!!$ctrl.click ? '' : vente._esk.routing"
                                              ng-click="$ctrl.onClick(vente)">
              </esk-biens-wallet-display-photo>

              <div class="tw:text-center">
                <span class="text-bold">{{vente.bien.reference}}</span>
              </div>
            </div>

            <div class="media-body informations">
              <div class="row margin-bottom-10 resume">
                <div ng-if="$ctrl.options.visibleFields.bullet" class="col-xs-1 col-sm-1 tw:text-center">
                  <span ng-if="vente._esk.result._esk.colorStatus.classBullet['bullet-green']" class="bullet-green">
                    <fa-icon [icon]="'circle'"></fa-icon>
                  </span>

                  <span ng-if="vente._esk.result._esk.colorStatus.classBullet['bullet-orange']" class="bullet-orange">
                    <fa-icon [icon]="'circle'"></fa-icon>
                  </span>

                  <span ng-if="vente._esk.result._esk.colorStatus.classBullet['bullet-blue']" class="bullet-blue">
                    <fa-icon [icon]="'circle'"></fa-icon>
                  </span>

                  <span ng-if="vente._esk.result._esk.colorStatus.classBullet['compromis bullet-red']"
                        class="compromis bullet-red"><fa-icon [icon]="'bookmark'"></fa-icon></span>

                  <span ng-if="vente._esk.result._esk.colorStatus.classBullet['bullet-red']" class="bullet-red">
                    <fa-icon [icon]="'circle'"></fa-icon>
                  </span>
                </div>

                <div class="col-xs-11 col-sm-11 tw:space-x-2">
                  <app-vente-price ng-if="$ctrl.options.visibleFields.price" class="tw:inline-block tw:text-xs"
                                   [options]="{enabledNV: false, enabledPriceM2: false}" [ng-vente]="vente">
                  </app-vente-price>

                  <div ng-if="$ctrl.options.visibleFields.price && vente.bien.superficie"
                       class="tw:inline-block tw:text-xs">
                    <app-vente-price-m2 [ng-vente]="vente" [options]="{enableSlashM2: true}"></app-vente-price-m2>
                  </div>

                  <div ng-if="$ctrl.options.visibleFields.priceCession" class="tw:inline-block vente-price label label-price">
                    {{vente.archivePrixCession | currency:'€':2 * (vente.archivePrixCession % 1 !== 0)}}
                  </div>

                  <span ng-if="$ctrl.options.visibleFields.nature"
                        class="label label-nature">{{vente.bien.nature.label}}</span>

                  <span ng-if="$ctrl.options.visibleFields.statut">
                    <app-vente-statut [ng-vente]="vente" class="tw:inline-block tw:text-xs"></app-vente-statut>
                  </span>

                  <span ng-if="!vente.bien._esk.interne">
                    <span class="label label-confrere">Confrères</span>
                  </span>

                  <span ng-if="$ctrl.options.visibleFields.mandatDateFin" class="padding-left-5">
                    Fin de mandat : <app-ng2-date [date]="vente.mandatDateFin" format="shortDate"></app-ng2-date>
                  </span>
                </div>
              </div>

              <div ng-if="$ctrl.options.visibleFields.localisation" class="row margin-bottom-10">
                <div class="col-sm-12 localisation">
                  <fa-icon [icon]="'map-marker-alt'" size="lg"></fa-icon>

                  <p ng-if="$ctrl.options.visibleFields.preciseLocalisation && vente.bien._esk.interne && !!vente.bien.option.numeroVoie">
                    {{vente.bien.option.numeroVoie}}
                  </p>

                  <p ng-if="$ctrl.options.visibleFields.preciseLocalisation && vente.bien._esk.interne && !!vente.bien.option.nomVoie"
                     class="adresse ellipsis">{{vente.bien.option.nomVoie}}</p>

                  <p ng-if="$ctrl.options.visibleFields.preciseLocalisation && !vente.bien._esk.interne && !!vente.bien.option.adresseReseau"
                     class="adresse ellipsis">{{vente.bien.option.adresseReseau}}</p>

                  <ng-switch on="!!vente.bien.option.commune.fullname">
                    <p ng-swicth-default class="text-bold">{{vente.bien.option.commune.fullname}}</p>

                    <p ng-switch-when="false" class="text-muted">{{'Aucune commune renseignée' | translate}}</p>
                  </ng-switch>

                  <p ng-show="!!vente.bien.option.codePostal">{{vente.bien.option.codePostal}}</p>

                  <p ng-show="!!vente.bien.option.quartier.name">{{vente.bien.option.quartier.name}}</p>
                </div>
              </div>

              <div class="row margin-bottom-10">
                <div class="col-sm-12">
                  <div>
                    <h5 ng-if="$ctrl.options.visibleFields.nomContactsGroup"
                        class="margin-bottom-10">{{vente.bien.contactsGroup.nom}}</h5>

                    <div class="margin-bottom-10">{{vente.bien.titre}}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </ng-switch>
  </ng-switch>
</div>
`;
