export default `
<table class="margin-top-15 table table-bordered table-hover">
  <thead><tr><th>Pièces jointes</th><th></th></tr></thead>

  <tbody ng-switch="$ctrl.attachments.length > 0">
  <tr ng-switch-when="false"><td colspan="2" class="tw:text-center text-muted">Aucune pièce-jointe</td></tr>

  <tr ng-switch-default ng-repeat="attachment in $ctrl.attachments">
    <td class="col-sm-8">
      <span ng-switch="!!attachment.media.file._esk.uploadXhr">
        <span ng-switch-when="true">
          {{attachment.media.file.name}}

          <span class="text-muted">{{attachment.media.file.size / 1024 / 1024|number:2}} Mo</span>
        </span>

        <span ng-switch-default>
          {{attachment.media.title}}

          <span class="text-muted">{{attachment.media.totalBytes / 1024 / 1024|number:2}} Mo</span>
        </span>
      </span>

      <span class="pull-right" ng-switch="!!attachment.media._esk.error">
        <span ng-switch-default ng-switch="!!attachment.id">
          <span ng-switch-when="true" class="text-success"><fa-icon [icon]="'check'"></fa-icon></span>

          <span ng-switch-default ng-if="attachment.media.file._esk.status === 'ERROR'" class="text-warning">
            <fa-icon [icon]="'exclamation-triangle'"></fa-icon>
          </span>
        </span>

        <span ng-switch-when="true" class="text-danger">
          Type de fichier irrecevable <fa-icon [icon]="'times-circle'"></fa-icon>
        </span>
      </span>
    </td>

    <td class="col-sm-4 tw:text-center">
      <div ng-if="attachment.media.id">
        <button class="tw:btn-tertiary-info tw:py-0 tw:px-2" ng-click="$ctrl.downloadMedia(attachment.media)"
                type="button"><fa-icon [icon]="['far', 'eye']"></fa-icon><span>Voir</span></button>

        <button type="button" class="tw:btn-tertiary-info tw:py-0 tw:px-2" ng-click="$ctrl.remove(attachment)">
          <fa-icon [icon]="'trash-alt'"></fa-icon><span>Retirer</span>
        </button>
      </div>

      <uib-progressbar class="progress-striped margin-bottom-0" value="attachment.media.file._esk.progress"
                       ng-show="!!attachment.media.file._esk.uploadXhr && attachment.media.file._esk.progress < 100">
        {{attachment.media.file._esk.progress}} %
      </uib-progressbar>
    </td>
  </tr>
  </tbody>
</table>

<soq-input-file on-change="$ctrl.upload" id="input_file" id-dnd-field="input_file" files-max-size="$ctrl.filesMaxSize">
</soq-input-file>

<div ng-if="$ctrl.currentDossier && $ctrl.cDossierFiles.total > 0" class="margin-bottom-20 padding-top-15">
  <div class="tw:text-center">
    <button ng-if="!$ctrl.showGED" type="button" class="btn btn-primary btn-o" ng-click="$ctrl.showGED = true"
            style="white-space: inherit;">Documents liés au dossier</button>
  </div>

  <div ng-if="$ctrl.showGED">
    <div class="text-large">
      {{$ctrl.cDossierFiles.total > 1 ? 'Documents liés au dossier' : 'Document lié au dossier'}}
    </div>

    <table class="table table-hover">
      <tbody>
      <tr ng-repeat="dossierFile in $ctrl.cDossierFiles.results">
        <td class="col-sm-8">
          {{dossierFile.media.title}}

          <span class="text-muted">{{dossierFile.media.totalBytes / 1024 / 1024 | number:2}} Mo</span>
        </td>

        <td class="col-sm-4 tw:text-center">
          <button class="tw:btn-tertiary-info tw:py-0 tw:px-2" ng-click="$ctrl.downloadMedia(dossierFile.media)"
                  type="button"><fa-icon [icon]="['far', 'eye']"></fa-icon><span>Voir</span></button>

          <button type="button" class="tw:btn-tertiary-info tw:py-0 tw:px-2" ng-click="$ctrl.addFromGed(dossierFile)">
            <fa-icon [icon]="'plus'"></fa-icon><span>Ajouter</span>
          </button>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</div>
`;
