export default `
<div class="col-list" ng-hide="$ctrl.ng2RouterStateForNgService.current.name.includes('reports.details')">
  <div class="navigation">
    <div class="tabs-container">
      <ul class="nav nav-pills">
        <li ng-repeat="tab in $ctrl.tabs" ng-class="tab.id === $ctrl.currentTab.id ? 'active' : ''"
            class="nav-item">
          <a ng-click="$ctrl.onChangeTab(tab)" class="nav-link">{{tab.value}}</a>
        </li>
      </ul>
    </div>
  </div>

  <div ng-switch="$ctrl.currentTab.id" class="wrap-list">
    <!-- Reports -->
    <div ng-switch-default>
      <div class="row">
        <ng-switch on="!!$ctrl.loader.reports.operating" class="col-xs-12 text-right padding-right-25">
          <span ng-switch-when="true" class="margin-left-20">
            <fa-icon [icon]="'spinner'" animation="spin"></fa-icon>
            {{'global.en_cours.' + $ctrl.loader.reports.operating | translate}}
          </span>

          <div ng-switch-default class="btn-group" uib-dropdown class="pull-right">
            <button type="button" class="btn btn-primary" ng-click="$ctrl.addReport()">
              <fa-icon [icon]="'plus'"></fa-icon> Nouveau
            </button>

            <button type="button" class="btn btn-primary button-caret" uib-dropdown-toggle>
              <span class="caret"></span>
            </button>

            <ul class="dropdown-menu" uib-dropdown-menu role="menu">
              <li role="menuitem">
                <button type="button" class="tw:dropdown-link" ng-click="$ctrl.sendReports()">
                  <fa-icon [icon]="['far', 'envelope']"></fa-icon><span>Envoyer la liste</span>
                </button>
              </li>

              <li class="divider"></li>

              <li role="menuitem">
                <button type="button" class="tw:dropdown-link" ng-click="$ctrl.printReports()">
                  <fa-icon icon="print"></fa-icon><span>Imprimer la liste</span>
                </button>
              </li>
            </ul>
          </div>
        </ng-switch>
      </div>

      <ng-switch on="$ctrl.loader.reports.getting">
        <div ng-switch-when="true" class="tw:text-center text-large margin-top-15">
          <fa-icon [icon]="'spinner'" animation="spin"></fa-icon> {{'global.en_cours.LOAD' | translate}}
        </div>

        <div ng-switch-default>
          <ul class="messages-list">
            <li ng-if="$ctrl.reports.length === 0" class="padding-top-30 padding-bottom-30">
              <p class="text-muted tw:text-center">
                Il n'y a pas encore de comptes-rendus saisis.
              </p>
            </li>

            <li class="messages-item" ng-repeat="report in $ctrl.reports">
              <a ng-click="$ctrl.onSelectReport(report)" popover-popup-delay="800"
                 popover-append-to-body="true" popover-class="popover-report"
                 uib-popover-template="'src/app/legacy/templates/eskimmo/controllers/dossier/suivi/report-popover.html'"
                 popover-trigger="'mouseenter'" popover-placement="right">
                <p class="text-bold">{{report.subject}}</p>

                <p class="text-muted">
                  <fa-icon [icon]="['far', 'calendar-alt']"></fa-icon>

                  <app-ng2-date [date]="report.date" format="shortDate"></app-ng2-date>
                </p>
              </a>
            </li>
          </ul>
        </div>
      </ng-switch>
    </div>

    <!-- Emails -->
    <div ng-switch-when="emails">
      <div class="row">
        <div class="col-xs-12 col-sm-5">
          <!-- Rechercher dans les mails -->
        </div>

        <ng-switch on="!!$ctrl.loader.emails.operating" class="col-xs-12 col-sm-7 text-right padding-right-25">
          <span ng-switch-when="true" class="margin-left-20">
            <fa-icon [icon]="'spinner'" animation="spin"></fa-icon>

            {{'global.en_cours.' + $ctrl.loader.emails.operating | translate}}
          </span>

          <div ng-switch-default class="btn-group">
            <button type="button" class="btn btn-primary" ng-click="$ctrl.sendEmail()">
              <fa-icon [icon]="'envelope'"></fa-icon> Nouvel email
            </button>
          </div>
        </ng-switch>
      </div>

      <ng-switch on="$ctrl.loader.emails.getting">
        <div ng-switch-when="true" class="tw:text-center text-large margin-top-15">
          <fa-icon [icon]="'spinner'" animation="spin"></fa-icon> {{'global.en_cours.LOAD' | translate}}
        </div>

        <div ng-switch-default>
          <ul class="messages-list">
            <li ng-if="$ctrl.emails.length === 0" class="padding-top-30 padding-bottom-30">
              <p class="text-muted tw:text-center">
                Il n'y a pas encore d'emails envoyés attachés à ce dossier.
              </p>
            </li>

            <li class="messages-item" ng-repeat="email in $ctrl.emails">
              <a ng-click="$ctrl.onSelectEmail(email)" popover-popup-delay="800" popover-append-to-body="true"
                 uib-popover-template="'src/app/legacy/templates/eskimmo/controllers/dossier/suivi/email-popover.html'"
                 popover-class="popover-email" popover-trigger="'mouseenter'" popover-placement="right">
                <div class="tw:flex tw:items-center tw:gap-1 tw:mb-4 tw:font-bold">
                  <app-email-statut [email]="email" [options]="{useShortLabel: true}" class="tw:w-8 tw:text-center">
                  </app-email-statut>

                  {{email.subject}}
                </div>

                <p class="text-muted">
                  <fa-icon [icon]="['far', 'calendar-alt']"></fa-icon>

                  <app-ng2-date [date]="email.sendingDate" format="shortDate"></app-ng2-date>
                </p>
              </a>
            </li>
          </ul>
        </div>
      </ng-switch>
    </div>
  </div>
</div>
`;
