<div class="dossier-suivi">
  <div class="list-details" id="inbox">
    <esk-dossier-suivi/>

    @if (isOnSuiviItem$ | appLoadObs | async; as isOnSuiviItem) {
      <div class="details">
        @if (isOnSuiviItem.value) {
          <router-outlet/>
        } @else {
          @if (isOnSuiviItem.value === false) {
            <div class="no-messages"><h2>Sélectionner dans la liste pour visualiser</h2></div>
          } @else {
            <app-loader [classHeight]="'tw:h-40'" class="tw:block tw:p-4"/>
          }
        }
      </div>
    } @else {
      <app-loader [classHeight]="'tw:h-40'" class="tw:block tw:p-4"/>
    }
  </div>
</div>
