<div class="tw:flex tw:flex-col tw:gap-2">
  <h4 class="tw:px-3 tw:lg:px-4">Données d'archivage</h4>

  <div class="tw:bg-red-100 tw:rounded-xl tw:flex tw:flex-col tw:gap-4 tw:p-3 tw:lg:p-4">
    @if (dossierBien.bien.dateArchive || dossierBien.isVente()) {
      <ul class="tw:grid tw:gap-2 tw:text-sm tw:text-gray-700 tw:font-medium">
        @if (dossierBien.isVente()) {
          @if (vente.archiveDateVente || vente.archiveType) {
            <li>
              <span class="tw:text-gray-500 tw:text-xs tw:font-normal">Vente</span>
              @if (vente.archiveType) {
                {{ vente.archiveType.label | lowercase }}
              }

              @if (vente.archiveDateVente) {
                <span class="tw:text-gray-500 tw:text-xs tw:font-normal">le</span> {{ vente.archiveDateVente | date }}
              }
            </li>
          }

          @if (vente.isVenteNegociee() || vente.isVenteNonNegociee()) {
            @if (vente.isVenteNegociee()) {
              @if (vente.isVenteNegocieePortefeuille()) {
                <li>
                  <span class="tw:text-gray-500 tw:text-xs tw:font-normal">À</span>
                  @if (vente.archiveAcquereur) {
                    <span class="tw:text-gray-500 tw:text-xs tw:font-normal"> l'acquéreur</span>
                    {{ vente.archiveAcquereur }}
                  } @else {
                    @if (archiveDemandeur$ | appLoadObs | async; as archiveDemandeur) {
                      @if (archiveDemandeur.value) {
                        @if (archiveDemandeur.value.contactsGroup.hasNom()) {
                          {{ archiveDemandeur.value.contactsGroup.nom }}
                          <span class="tw:text-gray-500 tw:font-normal tw:whitespace-nowrap">
                            ({{ archiveDemandeur.value.reference }})
                          </span>
                        } @else {
                          <span class="tw:text-gray-500 tw:text-xs tw:font-normal"> l'acquéreur</span>
                          {{ archiveDemandeur.value.reference }}
                        }
                      } @else {
                        <span class="tw:text-gray-500 tw:text-xs tw:font-normal"> un acquéreur </span>
                      }
                    } @else {
                      <app-loader class="tw:inline-flex tw:w-2/5"/>
                    }

                    <span class="tw:text-gray-500 tw:text-xs tw:font-normal">issu de votre propre portefeuille</span>
                  }
                </li>
              }
              @if (vente.isVenteNegocieeReseau()) {
                <li>
                  <span class="tw:text-gray-500 tw:text-xs tw:font-normal">À</span>

                  @if (vente.archiveAcquereur) {
                    <span class="tw:text-gray-500 tw:text-xs tw:font-normal"> l'acquéreur</span>
                    {{ vente.archiveAcquereur }}
                  } @else {
                    <span class="tw:text-gray-500 tw:text-xs tw:font-normal"> un acquéreur</span>
                  }

                  @if (archiveEtude$ | appLoadObs | async; as archiveEtude) {
                    @if (archiveEtude.value) {
                      <span class="tw:text-gray-500 tw:text-xs tw:font-normal"> apporté par</span>
                      {{ archiveEtude.value.raisonSociale }}
                    } @else {
                      <span class="tw:text-gray-500 tw:text-xs tw:font-normal"> du réseau</span>
                    }
                  } @else {
                    <app-loader class="tw:inline-flex tw:w-2/5"/>
                  }
                </li>
              }
            }

            @if (vente.isVenteNonNegociee() && vente.archiveRaisonVenteNonNegociee) {
              <li>{{ vente.archiveRaisonVenteNonNegociee.label }}</li>
            }

            @if (vente.archivePrixCession > 0) {
              <li>
                <span class="tw:text-gray-500 tw:text-xs tw:font-normal">Au prix de vente final de</span>
                {{ vente.archivePrixCession | textCurrency }}
              </li>
            }

            @if (vente.isVenteNegociee()) {
              @if (vente.isVenteNegocieeReseau() && vente.archiveHonorairesNegoPercus > 0) {
                <li>
                  <span class="tw:text-gray-500 tw:text-xs tw:font-normal">Avec des honoraires de négociation à</span>
                  {{ vente.archiveHonorairesNegoPercus | textCurrency }}
                  <span class="tw:text-gray-500 tw:text-xs tw:font-normal">revenant à l'étude</span>

                  @if (vente.archiveHonorairesNego > 0) {
                    <span class="tw:text-gray-500 tw:text-xs tw:font-normal"> sur un total de</span>
                    {{ vente.archiveHonorairesNego | textCurrency }}
                  }
                </li>
              } @else {
                @if (vente.archiveHonorairesNego > 0) {
                  <li>
                    <span class="tw:text-gray-500 tw:text-xs tw:font-normal">Avec des honoraires de négociation à</span>
                    {{ vente.archiveHonorairesNego | textCurrency }}
                  </li>
                }
              }
            }
          }
        }

        @if (dossierBien.bien.dateArchive) {
          <li>
            <span class="tw:text-gray-500 tw:text-xs tw:font-normal">Bien archivé le</span>
            {{ dossierBien.bien.dateArchive | date }}
          </li>
        }
      </ul>
    }

    @if (dossierBien.archiveComments) {
      <div class="tw:text-sm tw:text-gray-700">{{ dossierBien.archiveComments }}</div>
    }
  </div>
</div>
