import {inject, Injectable} from '@angular/core';
import {
    DemandeurRechercheResultatFactory
} from '@models/demandeurs/demandeur/recherches/recherche/resultats/resultat/demandeur-recherche-resultat.factory';
import {
    IDemandeurRechercheResultatApi
} from '@models/demandeurs/demandeur/recherches/recherche/resultats/resultat/demandeur-recherche-resultat.interfaces';
import DemandeurRechercheResultat
    from '@models/demandeurs/demandeur/recherches/recherche/resultats/resultat/demandeur-recherche-resultat.model';

@Injectable({providedIn: 'root'})
export class DemandeurRechercheResultatsFactory {
    private _demandeurRechercheResultatFactory = inject(DemandeurRechercheResultatFactory);

    create(demandeurRechercheResultatApis: IDemandeurRechercheResultatApi[] = []): DemandeurRechercheResultat[] {
        return (demandeurRechercheResultatApis || []).filter(demandeurRechercheResultatApi => demandeurRechercheResultatApi)
            .map(demandeurRechercheResultatApi => this._demandeurRechercheResultatFactory.create(demandeurRechercheResultatApi));
    }
}
