import {inject, Injectable} from '@angular/core';
import {LocationPasserelleFactory} from '@models/locations/location/passerelles/passerelle/location-passerelle.factory';
import {ILocationPasserelleApi} from '@models/locations/location/passerelles/passerelle/location-passerelle.interfaces';
import LocationPasserelle from '@models/locations/location/passerelles/passerelle/location-passerelle.model';

@Injectable({providedIn: 'root'})
export class LocationPasserellesFactory {
    private _locationPasserelleFactory = inject(LocationPasserelleFactory);

    create(locationPasserelleApis: ILocationPasserelleApi[] = []): LocationPasserelle[] {
        return (locationPasserelleApis || []).filter(locationPasserelleApi => locationPasserelleApi)
            .map(locationPasserelleApi => this._locationPasserelleFactory.create(locationPasserelleApi));
    }
}
