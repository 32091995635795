<div class="tw:h-full tw:xl:mx-28">
  @if (title$ | async; as title) {
    <div class="tw:h-full">
      <div class="tw:h-12 tw:flex tw:items-center tw:justify-between">
        <div class="tw:flex tw:items-center tw:gap-8">
          <a [routerLink]="redirectionRoute" class="tw:border-r-2 tw:border-gray-200 tw:pr-8 tw:text-gray-500">
            <fa-icon [icon]="'arrow-left'" size="xl"/>
          </a>

          <div class="tw:text-gray-700 tw:text-lg">{{ title }}</div>
        </div>

        <div class="tw:flex tw:items-center"><app-call-to-action [type]="CALL_TO_ACTION_MAIN"/></div>
      </div>

      <div class="tw:h-[calc(100%_-_3rem)]"><router-outlet/></div>
    </div>
  } @else {
    <app-loader [classHeight]="'tw:h-40'" class="tw:block tw:mt-4"/>
  }
</div>
