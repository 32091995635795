<div class="tw:second-nav">
  <div class="tw:second-nav-menus"><h1 class="tw:second-nav-menus-title">Actualités</h1></div>

  <div class="tw:second-nav-content">
    <!-- Impossible de comprendre pourquoi il faut tw:h-[calc(100%_-_5px)] pour éviter un ascenseur -->
    <div class="tw:h-[calc(100%_-_5px)] tw:overflow-hidden">
      <div class="tw:h-full tw:overflow-y-auto">
        <div class="tw:xl:mx-28 tw:my-4 tw:md:m-4">
          @if (cActualites$ | async; as cActualites) {
            <div class="tw:w-full">
              @if (cActualites.total > 0) {
                <app-actualites-list [cActualites$]="cActualites$" (clicked)="clickOnActualite($event)"/>
              } @else {
                <div class="tw:mb-4 tw:sm:mb-8 tw:mx-auto tw:w-full tw:pt-6 tw:px-4 tw:text-center">
                  <p class="tw:mx-auto tw:mb-4 tw:text-base tw:text-gray-500 tw:sm:text-lg tw:md:text-xl">
                    Il n'y a aucune actualité à lire.
                  </p>
                </div>
              }
            </div>
          } @else {
            <app-loader [classHeight]="'tw:h-40'" class="tw:block"/>
          }
        </div>
      </div>
    </div>
  </div>
</div>
