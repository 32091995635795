export default `
<div class="modal-header">
  <button type="button" class="btn btn-default pull-right" ng-click="$dismiss()"
          title="Fermer sans prendre en compte les modifications"><fa-icon [icon]="'times'"></fa-icon></button>

  <h3 class="modal-title">
    <fa-icon [icon]="'filter'"></fa-icon>

    {{'bien.filters.wallet.current.TITLE' | translate:'{route: "' + $ctrl.filter.route + '"}'}}
  </h3>
</div>

<form name="$ctrl.editForm" ng-submit="$ctrl.save()" novalidate>
  <div class="modal-body">
    <!-- Nom -->
    <div ng-if="!$ctrl.filter.current" class="row">
      <div class="col-xs-offset-3 col-xs-6 form-group"
           ng-class="{'has-error': $ctrl.editForm.$submitted && $ctrl.editForm.nom.$invalid}">
        <input type="text" name="nom" class="form-control text-large" ng-model="$ctrl.filter.nom"
               placeholder="Saisir un nom pour le filtre" required style="text-align: center;">

        <div class="error text-small tw:block" ng-messages="$ctrl.editForm.nom.$error"
             ng-show="$ctrl.editForm.$submitted"><ng-messages-include src="messages.html"></ng-messages-include></div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-4">
        <!--Filtres par statuts-->
        <div>
          <p>Statuts</p>

          <app-dictionary-checkbox ng-if="$ctrl.filter.route === $ctrl.FILTER_ROUTES.GET_ETUDE_VENTES" [model]="$ctrl.venteStatuts"
                                   [name]="$ctrl.VENTE_STATUTS" (selected)="$ctrl.onSelectVenteStatuts($event)">
          </app-dictionary-checkbox>

          <app-dictionary-checkbox ng-if="$ctrl.filter.route === $ctrl.FILTER_ROUTES.GET_ETUDE_LOCATIONS" [model]="$ctrl.locationStatuts"
                                   [name]="$ctrl.LOCATIONS_STATUTS" (selected)="$ctrl.onSelectLocationStatuts($event)">
          </app-dictionary-checkbox>

          <app-dictionary-checkbox ng-if="$ctrl.filter.route === $ctrl.FILTER_ROUTES.GET_ETUDE_ESTIMATIONS"
                                   [model]="$ctrl.estimationStatuts" [name]="$ctrl.ESTIMATIONS_STATUTS"
                                   (selected)="$ctrl.onSelectEstimationStatuts($event)"></app-dictionary-checkbox>
        </div>

        <!-- Filtres sur le type de mandat -->
        <div ng-if="$ctrl.filter.route === $ctrl.FILTER_ROUTES.GET_ETUDE_VENTES" class="padding-top-10">
          <label>Type de mandat</label>

          <app-dictionary-checkbox [model]="$ctrl.mandatVenteTypes" [name]="$ctrl.VENTE_MANDAT_TYPES"
                                   (selected)="$ctrl.onSelectMandatVenteTypes($event)"></app-dictionary-checkbox>
        </div>

        <!-- Filtres sur l'expiration des mandats -->
        <div ng-if="$ctrl.filter.route === $ctrl.FILTER_ROUTES.GET_ETUDE_VENTES" class="padding-top-10">
          <label>Échéance du mandat</label>

          <esk-select ng-model="$ctrl.filter.queryParameters.mandat.echeance" list="$ctrl.listMandatsEcheance"
                      options="$ctrl.options.mandatsEcheance"></esk-select>
        </div>
      </div>

      <div class="col-sm-4">
        <!-- Filtre sur les types de vente -->
        <div ng-if="$ctrl.filter.route === $ctrl.FILTER_ROUTES.GET_ETUDE_VENTES" class="padding-bottom-10">
          <p>Type de vente</p>

          <app-dictionary-checkbox [model]="$ctrl.venteTypes" [name]="$ctrl.VENTE_TYPES"
                                   (selected)="$ctrl.onSelectVenteTypes($event)"></app-dictionary-checkbox>
        </div>

        <!-- Filtre sur les types d'estimation -->
        <div ng-if="$ctrl.filter.route === $ctrl.FILTER_ROUTES.GET_ETUDE_ESTIMATIONS && $ctrl.hasRoleEvaluation" class="padding-bottom-10">
          <p>Type d'estimation</p>

          <app-dictionary-checkbox [model]="$ctrl.estimationTypes" [name]="$ctrl.ESTIMATION_TYPES"
                                   (selected)="$ctrl.onSelectEstimationTypes($event)"></app-dictionary-checkbox>
        </div>

        <!--Filtres sur les natures-->
        <div>
          <p>Natures</p>

          <app-dictionary-checkbox [model]="$ctrl.natures" [name]="$ctrl.NATURES"
                                   (selected)="$ctrl.onSelectNatures($event)"></app-dictionary-checkbox>
        </div>
      </div>

      <div class="col-sm-4">
        <!-- Filtres sur les responsables de dossier -->
        <div class="padding-bottom-10">
          <p>Responsables dossier</p>

          <app-users-checkbox [model]="$ctrl.responsableDossiers" [roles]="$ctrl.usersRoles"
                              [options]="{name: 'responsableDossiers'}" [etudes]="[$ctrl.currentEtude]"
                              (selected)="$ctrl.onSelectResponsables($event)"></app-users-checkbox>
        </div>

        <!-- Filtres sur les notaires référents -->
        <div ng-if="$ctrl.filter.route === $ctrl.FILTER_ROUTES.GET_ETUDE_VENTES">
          <p>Notaires</p>

          <app-users-checkbox [model]="$ctrl.notaires" [roles]="[$ctrl.roleNotaire]"
                              [etudes]="[$ctrl.currentEtude]" (selected)="$ctrl.onSelectNotaires($event)"
                              [options]="{name: 'notaires'}"></app-users-checkbox>
        </div>
      </div>
    </div>
  </div>

  <!-- Actions -->
  <div class="modal-footer" ng-switch="!!$ctrl.loading">
    <p class="text-muted" ng-switch-when="true">
      <fa-icon [icon]="'spinner'" animation="spin"></fa-icon> {{'global.en_cours.' + $ctrl.loading | translate}}
    </p>

    <ng-switch on="$ctrl.filter.current || !$ctrl.filter.id" ng-switch-default>
      <button ng-switch-when="false" type="button" class="btn btn-o btn-danger pull-left"
              ng-click="$ctrl.remove()"><fa-icon [icon]="'trash-alt'"></fa-icon> Supprimer</button>

      <button ng-switch-default type="button" class="btn btn-o btn-danger pull-left"
              ng-click="$ctrl.filter.raz()">Réinitialiser</button>

      <button class="btn btn-primary" type="submit"><fa-icon [icon]="'check'"></fa-icon> Appliquer</button>
    </ng-switch>
  </div>
</form>
`;
