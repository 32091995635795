@if (cEstimationOnsaleReferences$ | async; as cEstimationOnsaleReferences) {
  <div class="tw:overflow-hidden">
    @if (cEstimationOnsaleReferences.total > 0) {
      <table id="estimationOnsaleReferences" class="tw:table-head-separate-body">
        <thead>
        <tr>
          <th class="tw:hidden tw:md:table-cell tw:w-[5.5rem]"><div>Photo</div></th>

          <th class="tw:w-40 tw:2xl:w-44"><div>Publication</div></th>

          <th><div>Adresse</div></th>

          <th class="tw:w-32 tw:2xl:w-36 tw:text-right">
            <div>
              <span class="tw:2xl:hidden">{{ superficieLabel.replace('Surface', 'Surf.') }}</span>
              <span class="tw:hidden tw:2xl:inline">{{ superficieLabel }}</span>
            </div>
          </th>

          <th class="tw:hidden tw:2xl:table-cell tw:w-40 tw:text-right">
            <div>
              <span class="tw:2xl:hidden">Surf. cadastrale</span>
              <span class="tw:hidden tw:2xl:inline">Surface cadastrale</span>
            </div>
          </th>

          <th class="tw:hidden tw:sm:table-cell tw:w-32 tw:2xl:w-36 tw:text-right"><div>Prix</div></th>

          <th class="tw:hidden tw:lg:table-cell tw:w-28 tw:2xl:w-32 tw:text-right">
            <div>
              <span class="tw:2xl:hidden">Prix m²</span>
              <span class="tw:hidden tw:2xl:inline">Prix au m²</span>
            </div>
          </th>

          <th class="tw:w-16 tw:lg:w-20 tw:print:hidden"><div><div class="tw:h-5"></div></div></th>
        </tr>
        </thead>

        <tbody>
          @for (estimationOnsaleReference of cEstimationOnsaleReferences.results; track estimationOnsaleReference) {
            <tr [ngClass]="isEnabled(estimationOnsaleReference) ? '' : 'tw:opacity-75'"
                [appCollectionSortable]="[AppEstimationOnsaleReferencesListName, cEstimationOnsaleReferences.results, estimationOnsaleReference.uuid]"
                [appCollectionSortableOptions]="{name: AppEstimationOnsaleReferencesListName, tHeadWithDiv: true}">
              <td class="tw:table-head-separate-body-img tw:hidden tw:md:table-cell">
                <div class="tw:h-16 tw:w-16">
                  <app-estimation-onsale-reference-photo [estimationOnsaleReference]="estimationOnsaleReference"/>
                </div>
              </td>

              <td>{{ estimationOnsaleReference.publishedAt | date }}</td>

              <td>
                {{ estimationOnsaleReference.adresse }}
                <app-commune-convivial-name [linkCommune]="estimationOnsaleReference.linkCommune"
                                            class="tw:block tw:text-gray-400"/>
              </td>

              <td class="tw:text-right">{{ estimationOnsaleReference.superficie | textCurrency:'m²' }}</td>

              <td class="tw:hidden tw:2xl:table-cell tw:text-right">
                {{ estimationOnsaleReference.surfaceCadastrale | textCurrency:'m²' }}
              </td>

              <td class="tw:hidden tw:sm:table-cell tw:text-right">
                {{ estimationOnsaleReference.prixVente | textCurrency }}
              </td>

              <td class="tw:hidden tw:lg:table-cell tw:text-right">
                {{ estimationOnsaleReference.prixM2 > 0 ? (estimationOnsaleReference.prixM2 | textCurrency) : null }}
              </td>

              <td class="tw:table-head-separate-body-actions">
                <button class="tw:btn-secondary-info tw:bg-transparent tw:shadow-none tw:ring-0 tw:w-10" type="button"
                        (click)="clickOnEstimationOnsaleReference(buttonElement, estimationOnsaleReference)"
                        #buttonElement><fa-icon [icon]="'ellipsis-v'"/></button>
              </td>
            </tr>
          }
        </tbody>
      </table>
    } @else {
      <div class="tw:mb-4 tw:sm:mb-8 tw:mx-auto tw:w-full tw:pt-6 tw:px-4 tw:text-center">
        <p class="tw:mx-auto tw:mb-4 tw:text-base tw:text-gray-500 tw:sm:text-lg tw:md:text-xl">
          Aucune référence en vente n'est sélectionnée.
        </p>
      </div>
    }

    <app-load-more [collection$]="cEstimationOnsaleReferences$" (loadMore)="getMore(cEstimationOnsaleReferences)"
                   [loading]="pendingGetMore"/>
  </div>
} @else {
  <app-loader [classHeight]="'tw:h-40'" class="tw:block"/>
}
