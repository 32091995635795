import {AfterViewInit, Component, HostListener, inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NgInjectorService} from '@shared/angularJS/injector.ng.service';
import {NgContactsGroupManager, NgVenteManager} from '@legacy/app/managers/managers';
import {NgItemDictionary, NgVente} from '@legacy/app/managers/ressources';
import {NgForm} from '@angular/forms';
import {NavigationEnd, Router, RouterEvent} from '@angular/router';
import {delay, firstValueFrom, from, Observable, of, Subject, switchMap} from 'rxjs';
import {filter, map, takeUntil, tap} from 'rxjs/operators';
import {NgSoqSweetAlert} from '@legacy/app/soqrate/soqrate';
import {ToasterService} from '@shared/toaster/toaster.service';
import {VenteService} from '@models/ventes/vente/vente.service';
import {NgBienDossierCurrent} from '@legacy/app/biens/biens';
import {IPromise} from 'angular';
import {ContactsGroupFactory} from '@models/contacts-group/contacts-group.factory';
import {ApiImpersonationService} from '@core/api/api-impersonation.service';
import {EtudeService} from '@models/etudes/etude/etude.service';
import Etude from '@models/etudes/etude/etude.model';
import {BrowserOnlineService} from '@shared/browser/online/browser.online.service';

@Component({selector: 'layout-vente-edit', standalone: false, templateUrl: 'layout-vente-edit.component.html'})
export class AppLayoutVenteEditComponent implements AfterViewInit, OnDestroy, OnInit {
    @ViewChild('editForm') editForm!: NgForm;

    // @todo Faire un tour pour factoriser les messages
    static readonly errorMessages = {
        OFFLINE: 'OFFLINE',
        OTHER: 'Certains champs n\'ont pas de valeurs correctes.<br><br>Pensez à compléter les données obligatoires ' +
            'dans "Général", "Photos", "Construction" et/ou "Formalités et Coûts"',
        TITLE: 'Données incomplètes',
    };
    private _apiImpersonationService = inject(ApiImpersonationService);
    private _browserOnlineService = inject(BrowserOnlineService);
    private _contactsGroupFactory = inject(ContactsGroupFactory);
    private _etudeService = inject(EtudeService);
    private _ngInjectorService = inject(NgInjectorService);
    private _router = inject(Router);
    private _toasterService = inject(ToasterService);
    private _venteService = inject(VenteService);
    private _initNgVente!: NgVente;
    private _initNgVenteForDiffusion!: NgVente;
    private _hasConstraintOnPhotos = false;
    private _ngVente!: NgVente;
    private readonly _onDestroy$ = new Subject<void>();
    private _saveIsDone = false;
    private _saving = false;
    private _showSaveButton = true;

    // Supprimer les injections des anciens manager
    private get ngBienDossierCurrent(): NgBienDossierCurrent {
        return this._ngInjectorService.getService('BienDossierCurrent');
    }

    // Supprimer les injections des anciens manager
    private get ngContactsGroupManager(): NgContactsGroupManager {
        return this._ngInjectorService.getService('ContactsGroupManager');
    }

    // Supprimer les injections des anciens manager
    private get ngSoqSweetAlert(): NgSoqSweetAlert {
        return this._ngInjectorService.getService('SoqSweetAlert');
    }

    // Supprimer les injections des anciens manager
    private get ngVenteManager(): NgVenteManager {
        return this._ngInjectorService.getService('VenteManager');
    }

    get currentEtude$(): Observable<Etude> {
        return this._etudeService.last$;
    }

    get hasConstraintOnPhotos(): boolean {
        return this._hasConstraintOnPhotos;
    }

    get saving(): boolean {
        return this._saving;
    }

    get showSaveButton(): boolean {
        return this._showSaveButton;
    }

    get ngVente(): NgVente {
        return this._ngVente;
    }

    canDeactivate(): Observable<boolean> {
        if (this._apiImpersonationService.isImpersonate()) {
            return of(true);
        }

        if (this._saveIsDone) {
            return of(true);
        }

        if (this.ngVente?.mandatPremiereDate) {
            this.editForm.onSubmit(undefined!);
            if (this.ngBienDossierCurrent.hasFieldsInvalid(this.editForm, true)) {
                this._formVenteNotValid();

                return of(false);
            }

            return from(this.savePromise().then(_ => true, _ => false));
        }

        return this._browserOnlineService.checkAndPrevent$();
    }

    ngOnInit(): void {
        this.ngVenteManager.current$.pipe(
            tap(_ => this._saveIsDone = false),
            tap(ngVente => {
                const data = {...ngVente};

                data.typeCharges = ngVente.typeCharges.code as unknown as NgItemDictionary;
                this._initNgVente = this.ngVenteManager.create(data);
            }),
            tap(ngVente => this._initNgVenteForDiffusion = {...ngVente.getCloneForDiffusion()}),
            tap(_ => this._hasConstraintOnPhotos = this.ngBienDossierCurrent.hasConstraints('photos')),
            takeUntil(this._onDestroy$),
        ).subscribe(ngVente => this._ngVente = ngVente);
        this._router.events.pipe(
            map(event => event as RouterEvent),
            filter(event => event instanceof NavigationEnd),
            map(event => !event.url.endsWith('/edit/photos') && !event.url.endsWith('/edit/virtual_visit')),
            takeUntil(this._onDestroy$),
        ).subscribe(showSaveButton => this._showSaveButton = showSaveButton);
    }

    ngAfterViewInit(): void {
        this._venteService.needToBeSaved$
            .pipe(filter(_ => !!this.ngVente), takeUntil(this._onDestroy$))
            .subscribe(() => this.save(true));
    }

    ngOnDestroy(): void {
        this._onDestroy$.next();
        if (!this._apiImpersonationService.isImpersonate() && !this._saveIsDone && this.ngVente?.id) {
            this.save();
        }
    }

    @HostListener('window:beforeunload', ['$event'])
    beforeUnloadHandler(event: BeforeUnloadEvent): void {
        event.preventDefault();
    }

    save(ignoreSubmitted = false): void {
        const initNgVenteForDiffusion = {...this._initNgVenteForDiffusion};
        const isVenteNew = !this.ngVente.id;

        if (this._saving) {
            return;
        }

        // Nouveau mais bouton Enregistrer pas cliqué
        if (isVenteNew && !this.editForm.submitted) {
            return;
        }

        // Pas nouveau, bouton Enregistrer cliqué mais pas valide
        if (!!this.ngVente.mandatPremiereDate && (this.editForm.submitted || ignoreSubmitted) && (this.ngBienDossierCurrent.hasFieldsInvalid(this.editForm, true) || !this.editForm.valid)) {
            this._formVenteNotValid();
            return;
        }

        /*
                If (this.ngVente.statut === Vente.statuts.BROUILLON && this.editForm.submitted && !this.editForm.valid) {
                    return this._formVenteNotValid();
                }
        */

        this.savePromise().then(_ => {
            if (isVenteNew) {
                return this._router.navigateByUrl('/app/ventes/' + this.ngVente.id.toString(), {replaceUrl: true}) as unknown;
            }

            return this.ngVenteManager.updateIfModified(initNgVenteForDiffusion, this.ngVente);
        }, () => {
        });
    }

    savePromise(): IPromise<void> {
        this._saveIsDone = true;
        this._saving = true;

        return firstValueFrom(this._browserOnlineService.checkAndPrevent$(false)).then(isOn => {
            if (!isOn) {
                return Promise.reject(AppLayoutVenteEditComponent.errorMessages.OFFLINE);
            }

            // Nécessaire pour que la récupération du nom du CG soit réalisé correctement car il y a un "debounceTime(500)" sur la frappe
            return firstValueFrom(of(undefined).pipe(
                delay(600),
                switchMap(_ => this._contactsGroupFactory.save$(this._contactsGroupFactory.ngCreate(this.ngVente.bien.contactsGroup))),
            )).then(contactsGroupSaved => this.ngContactsGroupManager.createFromNg2(contactsGroupSaved))
                .then(contactsGroup => this.ngVente.bien.contactsGroup = contactsGroup)
                .then(_ => firstValueFrom(this._venteService.ngSave$(this.ngVente, this._initNgVente).pipe(switchMap(vente => {
                    if (!vente) {
                        return of(this.ngVente);
                    }

                    return of(vente);
                }))))
                .then(vente => this.ngVenteManager.getOneById(vente.id))
                .then(ngVente => this.ngVenteManager.emitCurrent(ngVente))
                .catch((error: string) => {
                    if (!['backdrop', 'cancel'].includes(error)) {
                        this._toasterService.error('Oups !', 'Une erreur est survenue lors de la mise à jour de votre bien en vente.');
                    }

                    return Promise.reject(error);
                });
        }).finally(() => this._saving = false);
    }

    private _formVenteNotValid(): void {
        this.ngSoqSweetAlert.warningMessage(AppLayoutVenteEditComponent.errorMessages.TITLE, AppLayoutVenteEditComponent.errorMessages.OTHER);

        return;
    }
}
