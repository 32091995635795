import {inject, Injectable} from '@angular/core';
import {TemplateHeaderFactory} from '@models/templates/headers/header/template-header.factory';
import {ITemplateHeaderApi} from '@models/templates/headers/header/template-header.interfaces';
import TemplateHeader from '@models/templates/headers/header/template-header.model';

@Injectable({providedIn: 'root'})
export class TemplateHeadersFactory {
    private _templateHeaderFactory = inject(TemplateHeaderFactory);

    create(templateHeaderApis: ITemplateHeaderApi[] = []): TemplateHeader[] {
        return (templateHeaderApis || []).filter(templateHeaderApi => templateHeaderApi)
            .map(templateHeaderApi => this._templateHeaderFactory.create(templateHeaderApi));
    }
}
