export default `
<div class="main tw:text-center">
  <img class="photo" loading="lazy"
       ng-src="{{$ctrl.bienPhotos[$ctrl.currentIndex].linkFile || $ctrl.bienPhotos[$ctrl.currentIndex]._links.file.href}}?size=optimized">
</div>

<div class="photo-number text-large text-bold">{{$ctrl.currentIndex + 1}} / {{$ctrl.bienPhotos.length}}</div>

<button type="button" class="btn arrow left" ng-click="$ctrl.selectPhoto($ctrl.currentIndex - 1)">
  <fa-icon [icon]="'chevron-left'" size="3x"></fa-icon>
</button>

<button type="button" class="btn arrow right" ng-click="$ctrl.selectPhoto($ctrl.currentIndex + 1)">
  <fa-icon [icon]="'chevron-right'" size="3x"></fa-icon>
</button>

<button type="button" class="btn btn-default btn-danger" ng-click="$close()" title="Fermer la visionneuse de photo">
  <fa-icon [icon]="'times'"></fa-icon>
</button>
`;
