import {Component, inject, Input} from '@angular/core';
import Vente from '@models/ventes/vente/vente.model';
import {NgVente} from '@legacy/app/managers/ressources';
import {VenteFactory} from '@models/ventes/vente/vente.factory';
import DictionaryItem from '@models/dictionaries/dictionary/items/item/dictionary-item.model';
import {DictionaryItemService} from '@models/dictionaries/dictionary/items/item/dictionary-item.service';
import Dictionary from '@models/dictionaries/dictionary/dictionary.model';

// @todo Supprimer l'utilisation de ce composant au profit de AppDossierBienStatutComponent
@Component({selector: 'app-vente-statut', standalone: false, templateUrl: 'vente-statut.component.html'})
/**
 * @deprecated, Utiliser AppDossierBienStatutComponent
 */
export class AppVenteStatutComponent {
    private _dictionaryItemService = inject(DictionaryItemService);
    private _venteFactory = inject(VenteFactory);
    private _statut!: DictionaryItem;
    private _vente!: Vente;

    @Input()
    set ngVente(value: NgVente) {
        this._vente = this._venteFactory.ngCreate(value);
        this.setStatut();
    }

    get statut(): DictionaryItem {
        return this._statut;
    }

    @Input()
    set vente(value: Vente) {
        this._vente = value;
        this.setStatut();
    }

    get vente(): Vente {
        return this._vente;
    }

    setStatut(): void {
        this._statut = this._dictionaryItemService.getByCode(Dictionary.names.VENTE_STATUTS, this.vente.statut, true);
        if (!this._statut) {
            throw new Error(`Statut "${this.vente.statut}" pas trouvé dans "${Dictionary.names.VENTE_STATUTS}" pour ${this.vente.reference}`);
        }
    }
}
