import {AfterViewInit, Component, HostListener, inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NgInjectorService} from '@shared/angularJS/injector.ng.service';
import {NgContactsGroupManager, NgLocationManager} from '@legacy/app/managers/managers';
import {NgLocation} from '@legacy/app/managers/ressources';
import {NgForm} from '@angular/forms';
import {NavigationEnd, Router, RouterEvent} from '@angular/router';
import {delay, firstValueFrom, Observable, of, Subject, switchMap} from 'rxjs';
import {filter, map, takeUntil, tap} from 'rxjs/operators';
import {NgSoqSweetAlert} from '@legacy/app/soqrate/soqrate';
import {ToasterService} from '@shared/toaster/toaster.service';
import {LocationService} from '@models/locations/location/location.service';
import {NgBienDossierCurrent} from '@legacy/app/biens/biens';
import {ContactsGroupFactory} from '@models/contacts-group/contacts-group.factory';
import Location from '@models/locations/location/location.model';
import {ApiImpersonationService} from '@core/api/api-impersonation.service';
import Etude from '@models/etudes/etude/etude.model';
import {EtudeService} from '@models/etudes/etude/etude.service';
import {BrowserOnlineService} from '@shared/browser/online/browser.online.service';

@Component({selector: 'layout-location-edit', standalone: false, templateUrl: 'layout-location-edit.component.html'})
export class AppLayoutLocationEditComponent implements AfterViewInit, OnDestroy, OnInit {
    @ViewChild('editForm') editForm!: NgForm;

    // @todo Faire un tour pour factoriser les messages
    static readonly errorMessages = {OFFLINE: 'OFFLINE'};
    private _apiImpersonationService = inject(ApiImpersonationService);
    private _browserOnlineService = inject(BrowserOnlineService);
    private _contactsGroupFactory = inject(ContactsGroupFactory);
    private _etudeService = inject(EtudeService);
    private _locationService = inject(LocationService);
    private _ngInjectorService = inject(NgInjectorService);
    private _toasterService = inject(ToasterService);
    private _router = inject(Router);
    private _hasConstraintOnPhotos = false;
    private _initNgLocation!: NgLocation;
    private _ngLocation!: NgLocation;
    private readonly _onDestroy$ = new Subject<void>();
    private _saveIsDone = false;
    private _saving = false;
    private _showSaveButton = true;

    // Supprimer les injections des anciens manager
    private get ngBienDossierCurrent(): NgBienDossierCurrent {
        return this._ngInjectorService.getService('BienDossierCurrent');
    }

    // Supprimer les injections des anciens manager
    private get ngContactsGroupManager(): NgContactsGroupManager {
        return this._ngInjectorService.getService('ContactsGroupManager');
    }

    // Supprimer les injections des anciens manager
    private get ngLocationManager(): NgLocationManager {
        return this._ngInjectorService.getService('LocationManager');
    }

    // Supprimer les injections des anciens manager
    private get ngSoqSweetAlert(): NgSoqSweetAlert {
        return this._ngInjectorService.getService('SoqSweetAlert');
    }

    get currentEtude$(): Observable<Etude> {
        return this._etudeService.last$;
    }

    get ngLocation(): NgLocation {
        return this._ngLocation;
    }

    get hasConstraintOnPhotos(): boolean {
        return this._hasConstraintOnPhotos;
    }

    get saving(): boolean {
        return this._saving;
    }

    get showSaveButton(): boolean {
        return this._showSaveButton;
    }

    canDeactivate(): Observable<boolean> {
        if (this._apiImpersonationService.isImpersonate()) {
            return of(true);
        }

        if (this._saveIsDone) {
            return of(true);
        }

        if (this.ngLocation?.statut !== Location.statuts.BROUILLON && !this.editForm.valid) {
            this.editForm.onSubmit(undefined!);
            this._formLocationNotValid();

            return of(false);
        }

        return this._browserOnlineService.checkAndPrevent$();
    }

    ngOnInit(): void {
        this.ngLocationManager.current$.pipe(
            tap(_ => this._saveIsDone = false),
            tap(ngLocation => this._initNgLocation = {...ngLocation.getCloneForDiffusion()}),
            tap(_ => this._hasConstraintOnPhotos = this.ngBienDossierCurrent.hasConstraints('photos')),
            takeUntil(this._onDestroy$),
        ).subscribe(ngLocation => this._ngLocation = ngLocation);
        this._router.events.pipe(
            map(event => event as RouterEvent),
            filter(event => event instanceof NavigationEnd),
            map(event => !event.url.endsWith('/edit/photos') && !event.url.endsWith('/edit/virtual_visit')),
            takeUntil(this._onDestroy$),
        ).subscribe(showSaveButton => this._showSaveButton = showSaveButton);
    }

    ngAfterViewInit(): void {
        this._locationService.needToBeSaved$
            .pipe(filter(_ => !!this.ngLocation), takeUntil(this._onDestroy$))
            .subscribe(() => this.save(true));
    }

    ngOnDestroy(): void {
        this._onDestroy$.next();
        if (!this._apiImpersonationService.isImpersonate() && !this._saveIsDone && this.ngLocation?.id) {
            this.save();
        }
    }

    @HostListener('window:beforeunload', ['$event'])
    beforeUnloadHandler(event: BeforeUnloadEvent): void {
        event.preventDefault();
    }

    save(ignoreSubmitted = false): void {
        const initLocation = {...this._initNgLocation};
        const isLocationNew = !this.ngLocation.id;

        if (this._saving) {
            return;
        }

        // Nouveau mais bouton Enregistrer pas cliqué
        if (isLocationNew && !this.editForm.submitted) {
            return;
        }

        // Pas nouveau, bouton Enregistrer cliqué mais pas valide
        if (this.ngLocation.statut !== Location.statuts.BROUILLON && (this.editForm.submitted || ignoreSubmitted) && !this.editForm.valid) {
            this._formLocationNotValid();
            return;
        }

        /*
                If (this.location.statut === Location.statuts.BROUILLON && this.editForm.submitted && !this.editForm.valid) {
                    return this._formLocationNotValid();
                }
        */

        this._saveIsDone = true;
        this._saving = true;

        firstValueFrom(this._browserOnlineService.checkAndPrevent$(false)).then(isOn => {
            if (!isOn) {
                return Promise.reject(AppLayoutLocationEditComponent.errorMessages.OFFLINE);
            }

            // Nécessaire pour que la récupération du nom du CG soit réalisé correctement car il y a un "debounceTime(500)" sur la frappe
            return firstValueFrom(of(undefined).pipe(
                delay(600),
                switchMap(_ => this._contactsGroupFactory.save$(this._contactsGroupFactory.ngCreate(this.ngLocation.bien.contactsGroup))),
            )).then(contactsGroupSaved => this.ngContactsGroupManager.createFromNg2(contactsGroupSaved))
                .then(contactsGroup => this.ngLocation.bien.contactsGroup = contactsGroup)
                .then(_ => firstValueFrom(this._locationService.ngSave$(this.ngLocation)))
                .then(location => this.ngLocationManager.getOneById(location.id))
                .then(ngLocation => {
                    this.ngLocationManager.emitCurrent(ngLocation);
                    if (isLocationNew) {
                        return this._router.navigateByUrl('/app/locations/' + this.ngLocation.id.toString(), {replaceUrl: true}) as unknown;
                    }

                    return this.ngLocationManager.updateIfModified(initLocation, this.ngLocation);
                }, _ => this._toasterService.error('Oups !', 'Une erreur est survenue lors de la mise à jour de votre bien en location.'));
        }).finally(() => this._saving = false);
    }

    private _formLocationNotValid(): void {
        this.ngSoqSweetAlert.warningMessage('Saisie des champs', 'Certains champs n\'ont pas de valeurs correctes.');

        return;
    }
}
