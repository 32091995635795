@if (pending$ | appLoadObs | async; as pending) {
  <div [ngClass]="pending.value ? 'tw:waiting' : undefined!" class="tw:flex tw:gap-4">
    @if (options.sign) {
      <button type="button" class="tw:btn-primary-info" (click)="action(actions.SIGN)" [disabled]="pending.value">
        Lancer la signature
      </button>
    } @else if (options.cancelSign) {
      <button type="button" class="tw:btn-secondary-info" (click)="action(actions.CANCEL_SIGN)"
              [disabled]="pending.value">Annuler la signature</button>
    }

    <button type="button" class="tw:btn-secondary-info tw:w-10" #buttonElement (click)="clickDropdown(buttonElement)">
      <fa-icon [icon]="'ellipsis-v'"/>
    </button>
  </div>
}
