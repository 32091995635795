import {inject, Injectable} from '@angular/core';
import {CommuneVoieFactory} from '@models/communes/commune/voies/voie/commune-voie.factory';
import {ICommuneVoieApi} from '@models/communes/commune/voies/voie/commune-voie.interfaces';
import CommuneVoie from '@models/communes/commune/voies/voie/commune-voie.model';

@Injectable({providedIn: 'root'})
export class CommuneVoiesFactory {
    private _communeVoieFactory = inject(CommuneVoieFactory);

    create(communeVoieApis: ICommuneVoieApi[] = []): CommuneVoie[] {
        return (communeVoieApis || []).filter(communeVoieApi => communeVoieApi)
            .map(communeVoieApi => this._communeVoieFactory.create(communeVoieApi));
    }
}
