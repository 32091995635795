import {inject, Injectable} from '@angular/core';
import {DossierExportFactory} from '@models/dossiers/dossier/exports/export/dossier-export.factory';
import {IDossierExportApi} from '@models/dossiers/dossier/exports/export/dossier-export.interfaces';
import DossierExport from '@models/dossiers/dossier/exports/export/dossier-export.model';

@Injectable({providedIn: 'root'})
export class DossierExportsFactory {
    private _dossierExportFactory = inject(DossierExportFactory);

    create(dossierExportApis: IDossierExportApi[] = []): DossierExport[] {
        return (dossierExportApis || []).filter(dossierExportApi => dossierExportApi)
            .map(dossierExportApi => this._dossierExportFactory.create(dossierExportApi));
    }
}
