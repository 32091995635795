@if (etude$ | async; as etude) {
  <div class="tw:bg-gray-50 tw:px-4 tw:py-6 tw:sm:px-6">
    <h2 class="tw:mr-8 tw:text-base tw:font-semibold tw:text-gray-900">
      {{ etude.raisonSociale }} ({{ etude.crpcen }})
    </h2>
  </div>

  <div class="tw:flex tw:min-h-0 tw:flex-col tw:overflow-y-auto">
    <!-- @todo Utilité de ces class ? -->
    <div class="tw:flex tw:flex-col">
      <div class="tw:relative"><app-image-preview [options]="{priority: true}" [link]="etude.linkBanner"/></div>

      <div class="tw:flex tw:flex-row tw:space-x-2 tw:items-center tw:z-10">
        <div class="tw:min-w-[10rem] tw:w-1/4 tw:aspect-square tw:mx-2 tw:rounded-lg tw:shadow-sm tw:bg-white tw:p-2 tw:-mt-12">
          <div class="tw:relative tw:h-full tw:w-full">
            <img class="tw:object-contain" [ngSrc]="etude.linkLogo" fill>
          </div>
        </div>

        <div class="tw:flex-1">
          @if (sitePrincipal$ | async; as sitePrincipal) {
            <!-- @todo Utilité de ces class ? -->
            <div class="tw:flex tw:flex-col tw:flex-1 tw:w-full tw:items-start tw:pt-4 tw:pr-2">
              <app-site-card [etude]="etude" [site]="sitePrincipal" [options]="{enabledActions: false}"
                             class="tw:block tw:w-full tw:bg-gray-100 tw:rounded-lg tw:shadow-sm tw:p-2"/>
            </div>
          } @else {
            <app-loader [classHeight]="'tw:h-10'" class="tw:block tw:mr-2"/>
          }
        </div>
      </div>
    </div>

    <div class="tw:p-8 tw:divide-y tw:divide-gray-200">
      @if (cVentes$ | async; as cVentes) {
        @if (cVentes.total > 0) {
          <div class="tw:py-6">
            <app-dossiers-biens-stacked-images [cDossierBiens]="cVentes" class="tw:block tw:h-12"
                                               [options]="{labelSingular: 'bien en vente', labelPlural: 'biens en vente'}"/>
          </div>
        }
      } @else {
        <app-loader [classHeight]="'tw:h-40'" class="tw:block"/>
      }

      <div class="tw:py-6 tw:flex tw:flex-col tw:gap-12">
        <app-users-stacked-images [linkUsers]="etude.linkUsers" class="tw:h-12 tw:block"/>

        <div class="tw:text-center">
          <a [routerLink]="'/app/annuaires/etudes/' + etude.id.toString()" class="tw:btn-secondary-default">
            <fa-icon [icon]="['fal', 'building-columns']"/>
            <span class="tw:hidden tw:sm:inline-block">Voir le détail de l'étude</span>
          </a>
        </div>
      </div>
    </div>
  </div>
} @else {
  <div class="tw:px-4 tw:py-6 tw:sm:px-6">
    <div class="tw:mb-10 tw:pr-12"><app-loader [classHeight]="'tw:h-6'"/></div>

    <app-loader [classHeight]="'tw:h-40'" class="tw:block"/>
  </div>
}
