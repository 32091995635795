import {inject, Injectable} from '@angular/core';
import Links from '@models/links/links.model';
import {
    ICEtudePasserellesApi
} from '@models/etudes/etude/passerelles/collection/etude-passerelles.collection.interfaces';
import CEtudePasserelles from '@models/etudes/etude/passerelles/collection/etude-passerelles.collection.model';
import {EtudePasserellesApiService} from '@models/etudes/etude/passerelles/etude-passerelles.api.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {EtudePasserellesFactory} from '@models/etudes/etude/passerelles/etude-passerelles.factory';

@Injectable({providedIn: 'root'})
export class CEtudePasserellesFactory {
    private _etudePasserellesApiService = inject(EtudePasserellesApiService);
    private _etudePasserellesFactory = inject(EtudePasserellesFactory);

    create(cEtudePasserellesApi: ICEtudePasserellesApi): CEtudePasserelles {
        const cEtudePasserelles = new CEtudePasserelles();

        cEtudePasserelles.links = new Links(cEtudePasserellesApi._links);
        cEtudePasserelles.page = cEtudePasserellesApi.page;
        cEtudePasserelles.pages = cEtudePasserellesApi.pages;
        cEtudePasserelles.perPage = cEtudePasserellesApi.limit;
        cEtudePasserelles.total = cEtudePasserellesApi.total;
        if (cEtudePasserellesApi._embedded) {
            cEtudePasserelles.results = this._etudePasserellesFactory.create(cEtudePasserellesApi._embedded.items);
        }

        return cEtudePasserelles;
    }

    get$(): Observable<CEtudePasserelles> {
        return this._etudePasserellesApiService.getCollection$().pipe(map(cEtudePasserellesApi => this.create(cEtudePasserellesApi)));
    }
}
