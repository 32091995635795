import {inject, Injectable} from '@angular/core';
import {InotUserFactory} from '@models/inot-users/user/inot-user.factory';
import {IInotUserApi} from '@models/inot-users/user/inot-user.interfaces';
import InotUser from '@models/inot-users/user/inot-user.model';

@Injectable({providedIn: 'root'})
export class InotUsersFactory {
    private _inotUserFactory = inject(InotUserFactory);

    create(inotUserApis: IInotUserApi[] = []): InotUser[] {
        return (inotUserApis || []).filter(inotUserApi => inotUserApi)
            .map(inotUserApi => this._inotUserFactory.create(inotUserApi));
    }
}
