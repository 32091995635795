import Commune from '@models/communes/commune/commune.model';
import {ICommuneApi} from '@models/communes/commune/commune.interfaces';
import CCommunes from '@models/communes/collection/communes.collection.model';
import {getMockLinks} from '@models/links/links.fixtures';
import {ICCommunesApi} from '@models/communes/collection/communes.collection.interfaces';
import {getMockCollectionApi} from '@models/collection/collections.fixtures';
import {getMockDepartement, getMockDepartementApi} from '@models/departements/departements.fixtures';

export const getMockCCommunes = (): CCommunes => {
    const cCommunes = new CCommunes();

    cCommunes.links = getMockLinks();
    cCommunes.page = 4;
    cCommunes.pages = 8;
    cCommunes.perPage = 2;
    cCommunes.results = [getMockCommune(), getMockCommune()];
    cCommunes.total = 5;

    return cCommunes;
};

export const getMockCCommunesApi = (): ICCommunesApi => getMockCollectionApi([
    getMockCommuneApi(),
    getMockCommuneApi(),
]);

export const getMockCommune = (addCommuneParent = true): Commune => {
    const suffix = 'commune';
    const commune = new Commune('uuid-' + suffix, 6);

    commune.codeInsee = 'codeInsee-' + suffix;
    commune.codesPostaux = ['code-postal-1-' + suffix, 'code-postal-2-' + suffix];
    commune.coordonneesGps = [7, 4];
    commune.departement = getMockDepartement();
    commune.fullname = 'fullname-' + suffix;
    commune.hasQuartiers = true;
    commune.hasVoies = true;
    commune.linkSelf = 'linkSelf-' + suffix;
    if (addCommuneParent) {
        commune.communeParent = getMockCommune(false);
    }

    return commune;
};

export const getMockCommuneApi = (addCommuneParent = true): ICommuneApi => {
    const suffix = 'commune-api';
    const communeApi: ICommuneApi = {
        _embedded: {communeParent: undefined!, departement: getMockDepartementApi()},
        codeInsee: 'codeInsee-' + suffix,
        codesPostaux: ['code-postal-1-' + suffix, 'code-postal-2-' + suffix],
        coordonneesGps: [7, 4],
        fullname: 'fullname-' + suffix,
        hasQuartiers: true,
        hasVoies: true,
        id: 6,
    };

    if (addCommuneParent) {
        communeApi._embedded.communeParent = getMockCommuneApi(false);
    }

    return communeApi;
};

export class MockCCommunesFactory {
    create(): void {
    }

    get$(): void {
    }
}

export class MockCCommunesService {
    search$(): void {
    }
}

export class MockCommuneFactory {
    create(): void {
    }

    get$(): void {
    }

    getByLink$(): void {
    }

    ngCreate(): void {
    }
}

export class MockCommunesApiService {
    get$(): void {
    }

    getByLink$(): void {
    }

    getCollection$(): void {
    }

    getCollectionByLink$(): void {
    }
}

export class MockCommunesFactory {
    create(): void {
    }
}

export class MockCommunesService {
    get$(): void {
    }
}
