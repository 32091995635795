export default `
<div class="row hidden-print results-information">
  <div class="col-xs-12 col-sm-7 col-md-4 col-lg-5 text-bold ellipsis">{{$ctrl.search._esk.resume}}</div>

  <div class="col-xs-12 col-sm-5 col-md-3">
    <esk-select-sort ng-if="!$ctrl.options.loader.filter" type="{{$ctrl.options.typeSort}}"
                     ng-model="$ctrl.filter.queryParameters.sort" on-select="$ctrl.sortResults">
    </esk-select-sort>
  </div>

  <div class="col-xs-12 col-md-5 col-lg-4 tw:text-center">
    <div class="row">
      <app-dossiers-view-choice ng-show="$ctrl.search._esk.dossiersCorrespondants.totalItems > 0"
                                [route]="$ctrl.filterRoute" [code]="$ctrl.filterCode" class="col-xs-5 col-sm-6"
                                (changed)="$ctrl.changeDisplayMode($event)"></app-dossiers-view-choice>

      <div class="col-xs-7 col-sm-6">
        <button type="button" class="btn btn-primary"
                ng-click="$ctrl.ng2RouterStateForNgService.navigateByUrl('/app/demandeurs/' + $ctrl.demandeur.id.toString() + '/edit/criteres')">
          <fa-icon [icon]="'sliders-h'"></fa-icon> Critères de recherche
        </button>
      </div>
    </div>
  </div>

  <h4 ng-if="$ctrl.search._esk.dossiersCorrespondants.totalItems <= 0 || $ctrl.search.status === $ctrl.DEMANDEUR_RECHERCHE_BROUILLON"
      class="col-xs-12 margin-top-30 tw:text-center text-warning">
    {{$ctrl.search.status === $ctrl.DEMANDEUR_RECHERCHE_BROUILLON? "Saisissez vos critères de recherche." : "Aucun résultat correspondant à votre recherche. Élargissez vos critères."}}

    <br>

    <button type="button" class="btn btn-primary margin-top-20"
            ng-click="$ctrl.ng2RouterStateForNgService.navigateByUrl('/app/demandeurs/' + $ctrl.demandeur.id.toString() + '/edit/criteres')">
      <fa-icon [icon]="'sliders-h'"></fa-icon> Critères de recherche
    </button>
  </h4>
</div>

<!-- Liste des dossiers -->
<div>
  <esk-ventes-list ng-if="$ctrl.search.type === $ctrl.DossierBienTypesConst.VENTE"
                   ventes="$ctrl.search._esk.dossiersCorrespondants" filter="$ctrl.filter"
                   unselect-all="$ctrl.unselectAll" options="$ctrl.options"></esk-ventes-list>

  <esk-locations-list ng-if="$ctrl.search.type === $ctrl.DossierBienTypesConst.LOCATION"
                      locations="$ctrl.search._esk.dossiersCorrespondants" filter="$ctrl.filter"
                      unselect-all="$ctrl.unselectAll" options="$ctrl.options"></esk-locations-list>
</div>

<!-- Actions et Pagination -->
<div class="row">
  <!-- Pagination -->
  <div class="col-sm-offset-4 col-sm-4 tw:text-center">
    <esk-pagination ng-if="$ctrl.search._esk.dossiersCorrespondants.totalItems > 0"
                    paginator="$ctrl.search._esk.dossiersCorrespondants"
                    loader="$ctrl.options.loader" on-change="$ctrl.onChange"
                    ng-hide="$ctrl.options.loader.updating"></esk-pagination>
  </div>

  <div ng-if="$ctrl.search.status !== $ctrl.DEMANDEUR_RECHERCHE_BROUILLON"
       class="col-xs-12 col-sm-4 text-right text-muted hidden-print">
    <a ng-click="$ctrl.ng2RouterStateForNgService.navigateByUrl('/app/demandeurs/' + $ctrl.demandeur.id.toString() + '/resultats/historique')">
      Historique des biens correspondants
    </a>
  </div>
</div>

<!-- Barre de faire -->
<div ng-if="$ctrl.getSelectedDossiers().length > 0" class="barre-faire">
  <div class="dossiers-select-label">
    {{'bien.grouped_action.selection.MESSAGE' | translate:'{nbDossiers: ' + $ctrl.getSelectedDossiers().length + '}'}}
  </div>

  <div ng-switch="!!$ctrl.barreFaireLoader" class="dossiers-select-actions">
    <p ng-switch-when="true" class="loading text-large">
      <fa-icon [icon]="'spinner'" animation="spin"></fa-icon> {{$ctrl.barreFaireLoader | translate}}
    </p>

    <div ng-switch-default class="actions">
      <div class="btn-group dropup" uib-dropdown>
        <button type="button" class="btn btn-dropdown" ng-click="$ctrl.setInterest('interested')">
          <fa-icon [icon]="'check'"></fa-icon>

          <span class="btn-label">
            {{'bien.grouped_action.selection.INTERESTED' |
            translate:'{nbDossiers: ' + $ctrl.getSelectedDossiers().length + '}'}}
          </span>
        </button>

        <button type="button" class="btn" uib-dropdown-toggle><span class="caret"></span></button>

        <ul class="dropdown-menu" uib-dropdown-menu role="menu">
          <li role="menuitem">
            <button type="button" class="tw:dropdown-link" ng-click="$ctrl.setInterest($ctrl.DISINTERESTED)">
              <fa-icon [icon]="'times'"></fa-icon>

              <span>
                {{'bien.grouped_action.selection.DISINTERESTED'
                | translate:'{nbDossiers: ' + $ctrl.getSelectedDossiers().length + '}'}}
              </span>
            </button>
          </li>

          <li ng-if="$ctrl.search.type === $ctrl.DossierBienTypesConst.VENTE" class="divider"></li>

          <li role="menuitem" ng-if="$ctrl.search.type === $ctrl.DossierBienTypesConst.VENTE">
            <button type="button" class="tw:dropdown-link" ng-click="$ctrl.setInterest($ctrl.DISINTERESTED_EXCEPT_PRICE)">
              <fa-icon [icon]="'caret-right'" size="lg"></fa-icon>

              <span>
                {{'bien.grouped_action.selection.DISINTERESTED_EXCEPT_PRICE'
                | translate:'{nbDossiers: ' + $ctrl.getSelectedDossiers().length + '}'}}
              </span>
            </button>
          </li>
        </ul>
      </div>

      <button type="button" class="btn" ng-click="$ctrl.send()">
        <fa-icon [icon]="['far', 'envelope']"></fa-icon>

        <span class="btn-label">
          {{'files.send_cards' | translate:'{nbCards: ' + $ctrl.getSelectedDossiers().length + '}' }}
        </span>
      </button>

      <button type="button" class="btn" ng-click="$ctrl.createBonVisite()">
        <fa-icon [icon]="['far', 'file-alt']"></fa-icon> <span class="btn-label">Créer un bon de visite</span>
      </button>
    </div>
  </div>
</div>
`;
