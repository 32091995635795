@if (vente$ | async; as vente) {
  <div class="tw:flex tw:h-full tw:flex-col tw:divide-y tw:divide-gray-200 tw:bg-white">
    <div class="tw:bg-gray-50 tw:px-4 tw:py-6 tw:sm:px-6">
      <!-- ! pour contrer le margin:0 sur le h2 -->
      <h2 class="tw:!mr-8 tw:text-base tw:font-semibold tw:text-gray-900 tw:truncate">{{ vente.bien.titre }}</h2>
    </div>

    <div class="tw:flex tw:min-h-0 tw:flex-1 tw:flex-col tw:overflow-y-auto">
      <div class="tw:relative">
        <app-bien-photo [dossierBien]="vente" [options]="{linkPhotoAttribute: 'linkPremierePhoto16by9'}"
                        class="tw:block tw:aspect-photo"/>

        <span class="tw:absolute tw:top-0 tw:right-0 tw:font-semibold tw:bg-white tw:rounded-lg tw:px-2 tw:py-1 tw:m-2">
          {{ vente.reference }}
        </span>
      </div>

      <div class="tw:p-8 tw:divide-y tw:divide-gray-200 tw:flex tw:flex-col tw:gap-6">
        <div [innerHTML]="vente.bien.descriptif | textNl2br"></div>

        <div class="tw:pt-6"><app-vente-pricing [vente]="vente" class="tw:leading-relaxed"/></div>

        <div class="tw:flex tw:flex-col tw:gap-4 tw:pt-6">
          @if (vente.bien.surfaceCadastrale) {
            <div class="tw:sm:grid tw:sm:grid-cols-3 tw:sm:gap-4">
              <div>Surface cadastrale</div>

              <div class="tw:sm:col-span-2 tw:mt-1 tw:sm:mt-0 tw:text-base tw:text-gray-700">
                {{ vente.bien.surfaceCadastrale | textCurrency:'m²' }}
              </div>
            </div>
          }

          @if (vente.bien.superficie) {
            <div class="tw:sm:grid tw:sm:grid-cols-3 tw:sm:gap-4">
              <div>{{ superficieLabel }}</div>

              <div class="tw:sm:col-span-2 tw:mt-1 tw:sm:mt-0 tw:text-base tw:text-gray-700">
                {{ vente.bien.superficie | textCurrency:'m²' }}
              </div>
            </div>
          }

          @if (vente.bien.performanceEnergetique && vente.bien.performanceEnergetique.consommationClasse) {
            <div class="tw:sm:grid tw:sm:grid-cols-3 tw:sm:gap-4">
              <div>Consommation énergétique</div>

              <div class="tw:sm:col-span-2 tw:mt-1 tw:sm:mt-0 tw:text-base tw:text-gray-700">
                <app-diagnostic-letter-consommation [bien]="vente.bien"
                                                    class="tw:block tw:text-2xl tw:font-bold tw:h-8 tw:w-8 tw:rounded-lg tw:overflow-hidden"/>
              </div>
            </div>
          }

          @if (vente.bien.performanceEnergetique && vente.bien.performanceEnergetique.emissionsClasse) {
            <div class="tw:sm:grid tw:sm:grid-cols-3 tw:sm:gap-4">
              <div>Émissions de gaz à effet de serre</div>

              <div class="tw:sm:col-span-2 tw:mt-1 tw:sm:mt-0 tw:text-base tw:text-gray-700">
                <app-diagnostic-letter-emissions [bien]="vente.bien"
                                                 class="tw:block tw:text-2xl tw:font-bold tw:h-8 tw:w-8 tw:rounded-lg tw:overflow-hidden"/>
              </div>
            </div>
          }
        </div>

        <!-- @todo Utilité de ces class ? -->
        <div class="tw:flex tw:flex-col tw:gap-4 tw:pt-6">
          <div class="tw:sm:grid tw:sm:grid-cols-3 tw:sm:gap-4">
            <div>Responsable</div>

            <div class="tw:sm:col-span-2 tw:mt-1 tw:sm:mt-0 tw:text-base tw:text-gray-700">
              <app-user-cartouche [link]="vente.bien.linkResponsableDossier"
                                  class="tw:block tw:h-6 tw:max-w-full tw:w-fit"/>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="tw:flex tw:shrink-0 tw:justify-end tw:px-4 tw:py-4">
      <button type="button" class="tw:btn-primary-info" (click)="redirectToDetails(vente)">
        Voir le bien détaillé
      </button>
    </div>
  </div>
} @else {
  <div class="tw:px-4 tw:py-6 tw:sm:px-6">
    <div class="tw:mb-10 tw:pr-12"><app-loader [classHeight]="'tw:h-6'"/></div>

    <app-loader [classHeight]="'tw:h-40'" class="tw:block"/>
  </div>
}
