<div class="tw:mb-5">
  <h4><fa-icon [icon]="['fal', 'edit']"/> Modification du nom</h4>
</div>

<form (ngSubmit)="save(editForm)" #editForm="ngForm" class="tw:w-[80vw] tw:sm:w-80 tw:flex tw:flex-col tw:gap-6">
  <div [appFormError]="section">
    <label for="title" class="tw:block tw:text-sm tw:text-gray-700">Nom *</label>

    <input type="text" id="title" name="title" #section="ngModel" required [(ngModel)]="media.title" class="tw:input-text tw:block">
  </div>

  <button type="submit" class="tw:float-right tw:btn-primary-info">Enregistrer</button>
</form>
