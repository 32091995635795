export default `
<div class="container-fluid container-fullw tw:bg-white vente-contrats">
  <!-- Options des mandats -->
  <fieldset class="row contrats-mandats">
    <legend>Options des mandats</legend>

    <!-- Type de mandat -->
    <div class="col-sm-4 mandats-type">
      <div ng-if="$ctrl.vente.type.code !== $ctrl.INTERACTIVE" class="form-group">
        <label>Type de mandat</label>

        <div class="row">
          <div class="col-sm-12">
            <div class="radio-inline" ng-repeat="typeMandatVente in $ctrl.typesMandatVente">
              <div class="radio clip-radio radio-primary">
                <input type="radio" id="mandat{{typeMandatVente.id}}" ng-model="$ctrl.vente.mandatType"
                       name="typeMandatVente" ng-value="typeMandatVente" ng-change="$ctrl.saveVente()">

                <label for="mandat{{typeMandatVente.id}}">{{typeMandatVente.label}}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Date -->
    <div class="col-sm-8 col-md-6 col-lg-4">
      <ng-switch on="$ctrl.vente.canEditMandat()">
        <div ng-switch-when="true">
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group" ng-class="{'has-error': $ctrl.editForm.mandatDate.error}">
                <label>{{"Date de mandat" | translate}}</label>

                <esk-select-date on-select="$ctrl.onChangeMandatDate()" is-required="!!$ctrl.vente.mandatPremiereDate"
                                 model="$ctrl.vente.mandatDate" options="$ctrl.options.mandatDate"></esk-select-date>

                <span class="error text-small tw:block" ng-messages="$ctrl.editForm.mandatDate.error">
                  <ng-messages-include src="messages.html"></ng-messages-include>
                </span>
              </div>
            </div>

            <div class="col-sm-6">
              <div class="form-group" ng-class="{'has-error': $ctrl.editForm.mandatDateFin.error}">
                <label>{{"Date de fin de mandat" | translate}}</label>

                <esk-select-date model="$ctrl.vente.mandatDateFin" on-select="$ctrl.onChangeMandatDateFin()"
                                 options="$ctrl.options.mandatDateFin"></esk-select-date>

                <span class="error text-small tw:block" ng-messages="$ctrl.editForm.mandatDateFin.error">
                  <ng-messages-include src="messages.html"></ng-messages-include>
                </span>
              </div>
            </div>
          </div>

          <ng-switch on="!!$ctrl.doubleMandat">
            <div ng-switch-default>
              <ng-include src="'src/app/legacy/templates/ventes/days-before-auto-send-to-network.html'"
                          ng-if="$ctrl.hasNetwork && $ctrl.vente._esk.nbDaysBeforeAutoSendToNetwork !== null && $ctrl.vente._esk.nbDaysBeforeAutoSendToNetwork >= 0"
                          class="text-justify text-muted"></ng-include>
            </div>

            <p ng-switch-when="true" class="text-warning">
              Votre bien possède au moins une référence cadastrale identique au bien à vendre
              <span class="text-bold">{{$ctrl.doubleMandat.bienReference}}</span>
              <span ng-if="$ctrl.doubleMandat.type === 'doubleMandat'">
                de l'étude <span class="text-bold">{{$ctrl.doubleMandat.etudeRaisonSociale}}</span>.
                <br>Conformément à la charte, votre bien à vendre ne sera pas envoyé sur le réseau.
              </span>
              <span ng-if="$ctrl.doubleMandat.type === 'doublon'">de votre étude.</span>
              <br>
              <span ng-if="!$ctrl.vente.mandatPremiereDate">
                Vous pourrez tout de même en faire la publicité<span ng-if="$ctrl.doubleMandat.type !== 'doubleMandat'">.</span>
                <span ng-if="$ctrl.doubleMandat.type === 'doubleMandat'">
                  et un mail sera envoyé au responsable
                  <span class="text-bold">{{$ctrl.doubleMandat.responsable}}</span>.
                </span>
              </span>
              <span ng-if="!!$ctrl.vente.mandatPremiereDate">Vous pouvez tout de même en faire la publicité.</span>
            </p>
          </ng-switch>
        </div>

        <div ng-switch-default>
          <h4 class="text-warning">
            <fa-icon [icon]="'exclamation-triangle'"></fa-icon> Données manquantes pour saisir la date de mandat
          </h4>

          <p ng-include="'src/app/legacy/templates/ventes/mandat.missing-information.html'" class="text-warning"></p>
        </div>
      </ng-switch>
    </div>
  </fieldset>

  <!-- Gestion des contrats à éditer -->
  <fieldset class="row">
    <legend>Édition des mandats / avenants</legend>

    <ng-switch on="$ctrl.options.loader.updating">
      <p ng-switch-when="true" class="tw:text-center text-extra-large">
        <fa-icon [icon]="'spinner'" animation="spin"></fa-icon> {{'global.en_cours.LOAD' | translate}}
      </p>

      <div ng-switch-default ng-switch="$ctrl.contractors.hasContrats">
        <!-- Pas de contrat créé -->
        <ng-switch on="$ctrl.contractors.totalItems > 0" ng-switch-default
                   class="tw:text-center text-large contrats-none">
          <p ng-switch-when="false">
            Aucun contact saisi pour votre bien à vendre.<br>Vous pouvez en sélectionner depuis la partie

            <a ng-click="$ctrl.ng2RouterStateForNgService.navigateByUrl('/app/ventes/' + $ctrl.vente.id.toString() + '/edit/general')">
              "Fiche" de votre bien en vente
            </a>.
          </p>

          <div ng-switch-default>
            <ng-include src="'src/app/legacy/templates/ventes/controllers/item/mandats/btn-new.html'"></ng-include>

            afin de pouvoir les modifier.
          </div>
        </ng-switch>

        <!-- Contrats -->
        <div ng-switch-when="true" class="row">
          <div class="col-xs-12 col-sm-4 col-md-3 contractors-list">
            <!-- Nouveau document -->
            <div ng-include="'src/app/legacy/templates/ventes/controllers/item/mandats/btn-new.html'"></div>

            <!-- Liste des contrats à éditer -->
            <ul class="list-group">
              <li ng-repeat="contractor in $ctrl.contractors.collection"
                  class="list-group-item contractors-item">
                <div class="text-bold ellipsis" ng-click="$ctrl.selectContrat(contractor)">
                  <span ng-if="contractor.type === 'contacts-group'"><fa-icon [icon]="'users'"></fa-icon></span>

                  <span ng-if="contractor.type === 'contact'"><fa-icon [icon]="'user'"></fa-icon></span>

                  {{contractor.convivialName}}
                </div>

                <button ng-if="contractor.contrats.length > 0" ng-switch="contractor.moreContrats"
                        type="button" class="btn btn-link" ng-click="$ctrl.loadContrats(contractor)">
                  <span ng-switch-default><fa-icon [icon]="'angle-down'"></fa-icon></span>

                  <span ng-switch-when="in_progress"><fa-icon [icon]="'spinner'" animation="spin"></fa-icon></span>

                  <span ng-switch-when="display"><fa-icon [icon]="'angle-up'"></fa-icon></span>
                </button>

                <ul class="list-group contractor-contrats">
                  <li ng-if="contractor.contrats.length <= 0" class="list-group-item contrats-none">
                    Aucun mandat ou avenant associé
                  </li>

                  <li
                    ng-if="contractor.contrats.length > 0 && ($index === 0 || (contractor.displayedMoreContrats && $index > 0))"
                    ng-repeat="venteContrat in contractor.contrats" class="list-group-item contrats-item"
                    ng-class="{'active': $ctrl.currentContrat.contratUuid === venteContrat.contratUuid}"
                    ng-click="$ctrl.selectContrat(contractor, venteContrat)">
                    <span class="text-capitalize">{{venteContrat.type | translate}}</span>

                    du <app-ng2-date [date]="venteContrat.contrat.createdAt" format="shortDate"></app-ng2-date>

                    <div
                      ng-if="venteContrat.contrat.documentCourrierIntroductifId > 0 || venteContrat._esk.documents.procedure"
                      class="clearfix">
                      <div ng-if="venteContrat.contrat.documentCourrierIntroductifId > 0"
                           class="padding-left-20 pull-left">
                        <fa-icon [icon]="['far', 'envelope']"></fa-icon> Avec courrier introductif
                      </div>

                      <div ng-if="venteContrat._esk.documents.procedure" class="padding-left-20 pull-left">
                        <fa-icon [icon]="'feather-alt'"></fa-icon>

                        Procédure {{venteContrat._esk.documents.procedure.labelStatut}}
                      </div>
                    </div>
                  </li>

                  <li ng-if="contractor.contrats.length > 0 && contractor.displayedMoreContrats"
                      class="list-group-item contrats-total">
                    {{'vente.contrats.list.TOTAL' | translate:'{nbContrats: ' + contractor.contrats.length + '}'}}
                  </li>
                </ul>
              </li>
            </ul>

            <!-- Actions groupées -->
            <ng-switch on="$ctrl.options.loader.printing" class="pull-right">
              <p ng-switch-when="true" class="text-primary padding-top-5">
                <fa-icon [icon]="'spinner'" animation="spin"></fa-icon> {{'global.en_cours.PRINT' | translate}}
              </p>

              <button ng-switch-default type="button" class="btn btn-primary btn-o" ng-click="$ctrl.printContrats()"
                      title="{{'vente.contrats.list.print.TITLE' | translate}}">
                <fa-icon icon="print"></fa-icon> {{'global.label.PRINT' | translate}}
              </button>
            </ng-switch>
          </div>

          <!-- Edition d'un contrat -->
          <ng-switch on="!!$ctrl.currentContrat" class="col-xs-12 col-sm-8 col-md-9 contrats-edition">
            <div ng-switch-when="false" class="margin-top-30 padding-top-30 text-extra-large tw:text-center">
              Sélectionner un mandat / avenant dans la liste afin de le modifier.
            </div>

            <ng-switch on="$ctrl.currentContrat._esk.loading" ng-switch-default>
              <p ng-switch-when="true" class="tw:text-center text-extra-large">
                <fa-icon [icon]="'spinner'" animation="spin"></fa-icon> {{'global.en_cours.LOAD' | translate}}
              </p>

              <div ng-switch-default>
                <div class="row edition-actions">
                  <div class="col-xs-12 col-lg-3">
                    <uib-tabset ng-if="$ctrl.currentContrat.contrat.documentCourrierIntroductifId > 0" class="tabbable"
                                type="pills">
                      <uib-tab heading="{{$ctrl.currentContrat.type === 'avenant' ? 'Avenant' : 'Mandat'}}"
                               ng-click="$ctrl.editDoc = 'MAIN'"></uib-tab>

                      <uib-tab heading="Courrier" ng-click="$ctrl.editDoc = 'COURRIER'"></uib-tab>
                    </uib-tabset>
                  </div>

                  <div class="col-xs-12 col-md-8 col-lg-6 padding-top-5">
                    <button ng-if="$ctrl.currentContrat._esk.documents.procedure" type="button"
                            class="btn btn-link text-extra-large" ng-click="$ctrl.editProcedure()">
                      <fa-icon [icon]="'feather-alt'"></fa-icon>

                      <span ng-if="!$ctrl.currentContrat._esk.documents.procedure.isClosed()">
                        {{$ctrl.currentContrat._esk.documents.procedure.cSignataires.total}}
                        {{$ctrl.currentContrat._esk.documents.procedure.cSignataires.total > 1 ? 'signataires' : 'signataire'}}
                        pour la
                      </span>

                      {{$ctrl.currentContrat._esk.documents.procedure.isClosed() ? 'Procédure' : 'procédure'}}
                      {{$ctrl.currentContrat._esk.documents.procedure.labelStatut}}
                    </button>

                    <div ng-if="!$ctrl.currentContrat._esk.documents.procedure">
                      <div class="pull-left margin-top-10 margin-right-5">Date de signature</div>

                      <div class="pull-left">
                        <esk-select-date model="$ctrl.currentContrat.contrat.dateSignature"
                                         options="$ctrl.options.dateSignature"></esk-select-date>
                      </div>
                    </div>
                  </div>

                  <ng-switch on="!$ctrl.currentContrat._esk.inProgress"
                             class="col-xs-12 col-md-4 col-lg-3 text-right padding-top-5 padding-bottom-5">
                    <p ng-switch-when="false" class="text-primary padding-top-5">
                      <fa-icon [icon]="'spinner'" animation="spin"></fa-icon>

                      {{'global.en_cours.' + $ctrl.currentContrat._esk.inProgress | translate}}
                    </p>

                    <div ng-switch-default>
                      <div class="btn-group dropdown" uib-dropdown>
                        <button type="button" class="btn btn-primary btn-o btn-dropdown"
                                ng-click="$ctrl.saveCurrentContrat()">
                          <fa-icon [icon]="'check'"></fa-icon> {{'global.label.SAVE' | translate}}
                        </button>

                        <button type="button" class="btn btn-primary btn-o" uib-dropdown-toggle>
                          <span class="caret"></span>
                        </button>

                        <ul class="dropdown-menu" uib-dropdown-menu role="menu">
                          <li ng-if="$ctrl.couldSignCurrentContrat()" role="menuitem">
                            <button type="button" class="tw:dropdown-link" ng-click="$ctrl.signCurrentContrat()">
                              <fa-icon [icon]="'feather-alt'"></fa-icon><span>Signer</span>
                            </button>
                          </li>

                          <li ng-if="$ctrl.couldSignCurrentContrat()" class="divider"></li>

                          <li ng-if="$ctrl.currentContractor.recipients.length > 0" role="menuitem">
                            <button type="button" class="tw:dropdown-link" ng-click="$ctrl.sendCurrentContrat()">
                              <fa-icon [icon]="['far', 'envelope']"></fa-icon><span>Envoyer</span>
                            </button>
                          </li>

                          <li ng-if="$ctrl.currentContractor.recipients.length > 0" class="divider"></li>

                          <li role="menuitem">
                            <button type="button" class="tw:dropdown-link" ng-click="$ctrl.printCurrentContrat()">
                              <fa-icon icon="print"></fa-icon><span>Imprimer</span>
                            </button>
                          </li>

                          <li class="divider"></li>

                          <li role="menuitem">
                            <button type="button" class="tw:dropdown-link"
                                    ng-click="$ctrl.recreateCurrentContratDocuments()">
                              <fa-icon [icon]="'sync-alt'"></fa-icon>

                              <span>{{'global.label.RECREATE' | translate}}</span>
                            </button>
                          </li>

                          <li class="divider" ng-if="$ctrl.ng2CurrentContratIsCancelable"></li>

                          <li role="menuitem" ng-if="$ctrl.ng2CurrentContratIsCancelable">
                            <button type="button" class="tw:dropdown-link" ng-click="$ctrl.cancelCurrentContrat()">
                              <fa-icon [icon]="'times'"></fa-icon><span>Annuler</span>
                            </button>
                          </li>

                          <li class="divider" ng-if="!$ctrl.currentContrat._esk.documents.procedure"></li>

                          <li role="menuitem" ng-if="!$ctrl.currentContrat._esk.documents.procedure">
                            <button type="button" class="tw:dropdown-link" ng-click="$ctrl.removeCurrentContrat()">
                              <fa-icon [icon]="'trash-alt'"></fa-icon><span>{{'global.label.REMOVE' | translate}}</span>
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </ng-switch>
                </div>

                <div ng-if="$ctrl.editDoc === 'MAIN'">
                  <app-ng2-tinymce (change-content)="$ctrl.currentContrat._esk.documents.main.contenu = $event"
                                   [content]="$ctrl.currentContrat._esk.documents.main.contenu"
                                   [options]="$ctrl.currentContrat._esk.tinymceOptions.main"></app-ng2-tinymce>
                </div>

                <div ng-if="$ctrl.editDoc === 'COURRIER'">
                  <app-ng2-tinymce (change-content)="$ctrl.currentContrat._esk.documents.courrier.contenu = $event"
                                   [content]="$ctrl.currentContrat._esk.documents.courrier.contenu"
                                   [options]="$ctrl.currentContrat._esk.tinymceOptions.courrier"></app-ng2-tinymce>
                </div>
              </div>
            </ng-switch>
          </ng-switch>
        </div>
      </div>
    </ng-switch>
  </fieldset>
</div>
`;
