<!-- tw:text-inherit tw:font-inherit pour contrer Bootstrap -->
<label class="tw:flex tw:flex-col tw:items-center tw:justify-center tw:gap-2 tw:h-full tw:w-full tw:cursor-pointer tw:text-inherit tw:font-inherit"
       [class]="options.labelPadding" (click)="onClick()">
  @if (options.withIcon) {
    <div><fa-icon [icon]="['fal', 'cloud-arrow-up']"/></div>
  }

  <!-- tw:m-0 pour contrer Bootstrap -->
  <p class="tw:m-0 tw:text-center">{{ options.label }}</p>
</label>

<!-- tw:!hidden pour contrer Bootstrap -->
<input type="file" class="tw:!hidden" (change)="onSelect($event)" [multiple]="true" #filePicker
       accept="{{options.allowedTypes}}">
