export default `
<!-- Civilité -->
<div class="row">
  <div class="col-sm-4 form-group"
       ng-class="{'has-error': $ctrl.editForm.$submitted && $ctrl.editForm.personTitre.$invalid}">
    <label>Civilité *</label>

    <esk-select-dictionary dictionary="{{$ctrl.TITRES}}" ng-model="$ctrl.contact.person.titre" name="personTitre"
                           is-required="true"></esk-select-dictionary>

    <div class="help-block" ng-messages="$ctrl.editForm.personTitre.$error"
         ng-if="$ctrl.editForm.$submitted && $ctrl.editForm.personTitre.$invalid">
      <ng-messages-include src="messages.html"></ng-messages-include>
    </div>
  </div>
</div>

<!-- Nom, prénom -->
<div class="row">
  <div class="col-sm-4 form-group"
       ng-class="{'has-error': $ctrl.editForm.$submitted && $ctrl.editForm.personNom.$invalid}">
    <label for="edit-contact-person-nom">{{'Nom' | translate}} *</label>

    <input soq-capitalize-input="1" type="text" name="personNom" id="edit-contact-person-nom" required  maxlength="140"
           class="form-control" ng-model="$ctrl.contact.person.getNom" ng-model-options="{getterSetter: true}">

    <div class="help-block" ng-messages="$ctrl.editForm.personNom.$error"
         ng-if="$ctrl.editForm.$submitted && $ctrl.editForm.personNom.$invalid">
      <ng-messages-include src="messages.html"></ng-messages-include>
    </div>
  </div>

  <div class="col-sm-4 form-group"
       ng-class="{'has-error': $ctrl.editForm.$submitted && $ctrl.editForm.personPrenom.$invalid}">
    <label for="edit-contact-person-prenom">{{'Prénom' | translate}} *</label>

    <input soq-capitalize-input="1" type="text" name="personPrenom" id="edit-contact-person-prenom" required
           class="form-control" ng-model="$ctrl.contact.person.getPrenom" ng-model-options="{getterSetter: true}">

    <div class="help-block" ng-messages="$ctrl.editForm.personPrenom.$error"
         ng-if="$ctrl.editForm.$submitted && $ctrl.editForm.personPrenom.$invalid">
      <ng-messages-include src="messages.html"></ng-messages-include>
    </div>
  </div>
</div>

<!-- Téléphone, mobile, email -->
<div class="row">
  <div class="col-sm-4 form-group"
       ng-class="{'has-error': $ctrl.editForm.$submitted && !$ctrl.contact.person.isPhoneValid($ctrl.contact.person.telephone)}">
    <label for="edit-contact-person-phone">Téléphone</label>

    <input type="text" id="edit-contact-person-phone" class="form-control" ng-model="$ctrl.contact.person.telephone">

    <div class="error text-small tw:block" ng-messages="{pattern: true}"
         ng-if="$ctrl.editForm.$submitted && !$ctrl.contact.person.isPhoneValid($ctrl.contact.person.telephone)">
      <ng-messages-include src="messages.html"></ng-messages-include>
    </div>
  </div>

  <div class="col-sm-4 form-group"
       ng-class="{'has-error': $ctrl.editForm.$submitted && !$ctrl.contact.person.isPhoneValid($ctrl.contact.person.mobile)}">
    <label for="edit-contact-person-mobile">Mobile</label>

    <input type="text" id="edit-contact-person-mobile" class="form-control" ng-model="$ctrl.contact.person.mobile">

    <div class="error text-small tw:block" ng-messages="{pattern: true}"
         ng-if="$ctrl.editForm.$submitted && !$ctrl.contact.person.isPhoneValid($ctrl.contact.person.mobile)">
      <ng-messages-include src="messages.html"></ng-messages-include>
    </div>
  </div>

  <div class="col-sm-4 form-group"
       ng-class="{'has-error': ($ctrl.editForm.$submitted && $ctrl.editForm.personEmailAddress.$invalid) || $ctrl.contact.invalidEmailAddress}">
    <label for="edit-contact-person-email">{{'Email' | translate}}</label>

    <div ng-if="$ctrl.contact.invalidEmailAddress" class="help-email">
      <a uib-popover="{{'mail.invalid_recipient.contact.HELP' | translate: '{type: \\'address\\'}'}}"
         popover-trigger="'outsideClick'" popover-placement="left-top" type="button"
         class="btn btn-o btn-null">
        <span class="badge badge-info">?</span>
      </a>
    </div>

    <input type="email" name="personEmailAddress" id="edit-contact-person-email" class="form-control"
           ng-model="$ctrl.contact.person.emailAddress">

    <div class="help-block" ng-messages="$ctrl.editForm.personEmailAddress.$error"
         ng-if="$ctrl.editForm.$submitted && $ctrl.editForm.personEmailAddress.$invalid">
      <ng-messages-include src="messages.html"></ng-messages-include>
    </div>
  </div>
</div>

<!-- Adresse -->
<div class="row">
  <div class="col-sm-12">
    <label for="edit-contact-person-etranger">
      <input type="checkbox" id="edit-contact-person-etranger" ng-model="$ctrl.contact.person.etranger">

      {{"Réside à l'étranger" | translate}}
    </label>
  </div>

  <div ng-if="!$ctrl.contact.person.etranger" class="col-sm-8 form-group">
    <label>{{"Commune" | translate}}</label>

    <esk-select-commune ng-model="$ctrl.contact.person.commune" name="commune" on-select="$ctrl.onSelectCommune"
                        placeholder="Rechercher par commune ou par le code postal de celle-ci ...">
    </esk-select-commune>
  </div>

  <div ng-if="!!$ctrl.contact.person.commune && !$ctrl.contact.person.etranger" class="col-sm-4 form-group">
    <label for="edit-contact-person-codePostal">{{"Code postal" | translate}}</label>

    <input id="edit-contact-person-codePostal" type="text" class="form-control text-right"
           ng-model="$ctrl.contact.person.codePostal" maxlength="5" minlength="5" name="codePostal">
  </div>

  <div ng-if="!!$ctrl.contact.person.commune || $ctrl.contact.person.etranger" class="col-sm-12 form-group">
    <label for="edit-contact-person-adresse">{{"Adresse"|translate}}</label>

    <textarea id="edit-contact-person-adresse" class="form-control" ng-model="$ctrl.contact.person.adresse">
        </textarea>
  </div>
</div>

<!-- Autres informations -->
<div class="panel panel-default">
  <div class="panel-body">
    <div class="margin-bottom-10">
      <button type="button" class="tw:btn-tertiary-info tw:p-2 tw:text-xs" ng-click="$ctrl.showAll = !$ctrl.showAll">
        <fa-icon ng-if="$ctrl.showAll" [icon]="'caret-down'"></fa-icon>

        <fa-icon ng-if="!$ctrl.showAll" [icon]="'caret-right'"></fa-icon>

        <span ng-if="$ctrl.showAll" >Masquer les autres renseignements</span>

        <span ng-if="!$ctrl.showAll" >Afficher les autres renseignements</span>
      </button>
    </div>

    <div ng-if="$ctrl.showAll">
      <!-- Autre prénoms, nom de naissance, nationalité -->
      <div class="row">
        <div class="col-sm-4 form-group">
          <label for="edit-contact-person-prenoms">{{'Autres prénoms'|translate}}</label>

          <input type="text" id="edit-contact-person-prenoms" class="form-control"
                 ng-model="$ctrl.contact.person.autresPrenoms">
        </div>

        <div class="col-sm-4 form-group">
          <label for="edit-contact-person-nom-fille">{{'Nom de naissance' | translate}}</label>

          <input type="text" id="edit-contact-person-nom-fille" class="form-control"
                 ng-model="$ctrl.contact.person.nomJeuneFille">
        </div>

        <div class="col-sm-4 form-group">
          <label for="edit-contact-person-nationalite">{{"Nationalité" | translate}}</label>

          <input type="text" id="edit-contact-person-nationalite" class="form-control"
                 ng-model="$ctrl.contact.person.nationalite">
        </div>
      </div>

      <!-- Date de naissance, commune de naissance, profession -->
      <div class="row">
        <div class="col-sm-4 form-group"
             ng-class="{'has-error': $ctrl.editForm.$submitted && ($ctrl.editForm.dateNaissance.$error | json) !== '{}'}">
          <label>{{"Date de naissance"|translate}}</label>

          <esk-select-date form="$ctrl.editForm" form-field-name="dateNaissance"
                           model="$ctrl.contact.person.dateNaissance"></esk-select-date>

          <span class="error text-small tw:block" ng-messages="$ctrl.editForm.dateNaissance.$error"
                ng-if="$ctrl.editForm.$submitted && ($ctrl.editForm.dateNaissance.$error | json) !== '{}'">
            <ng-messages-include src="messages.html"></ng-messages-include>
          </span>
        </div>

        <div class="col-sm-4 form-group">
          <label for="edit-contact-person-communeNaissance">{{"Commune de naissance" | translate}}</label>

          <input type="text" id="edit-contact-person-communeNaissance" class="form-control"
                 ng-model="$ctrl.contact.person.communeNaissance">
        </div>

        <div class="col-sm-4 form-group">
          <label for="edit-contact-person-profession">{{"Profession" | translate}}</label>

          <input type="text" id="edit-contact-person-profession" class="form-control"
                 ng-model="$ctrl.contact.person.profession">
        </div>
      </div>

      <!-- Représentant -->
      <div ng-if="!!$ctrl.editRepresentant" class="form-group">
        <div ng-if="!$ctrl.contact.representant.id">
          <label>{{"Représentant" | translate}}</label>

          <div class="row">
            <esk-contacts-search on-select="$ctrl.editRepresentant" contacts-selected="[$ctrl.contact]"
                                 class="col-xs-9 col-md-10 padding-right-0"></esk-contacts-search>

            <div class="col-xs-3 col-md-2 padding-left-0">
              <button type="button" class="btn btn-link" ng-click="$ctrl.editRepresentant()">
                <fa-icon [icon]="'plus'"></fa-icon> Créer
              </button>
            </div>
          </div>
        </div>

        <div ng-if="!!$ctrl.contact.representant.id" class="row margin-top-10">
          <div class="col-xs-12 col-md-7">
            {{'contact.edit.representant.PAR' | translate: '{genre: "' + ($ctrl.contact.person.titre.code === $ctrl.PERSON_TITRE_MONSIEUR ? 'male' : 'female') + '", convivialName: "' +
          $ctrl.contact.representant[$ctrl.contact.representant.type].convivialName.replace('\\'', '\\\'') + '"}'}}
          </div>

          <div class="col-xs-12 col-md-5">
            <button type="button" class="btn btn-link pull-left padding-top-0"
                    ng-click="$ctrl.editRepresentant($ctrl.contact.representant)">
              <fa-icon [icon]="'edit'"></fa-icon> Éditer
            </button>

            <button type="button" class="btn btn-link pull-left padding-top-0" ng-click="$ctrl.editRepresentant()">
              <fa-icon [icon]="'trash-alt'"></fa-icon> Supprimer
            </button>
          </div>
        </div>
      </div>

      <!-- Commentaires -->
      <div class="row">
        <div class="col-sm-12">
          <label for="edit-contact-person-comment">{{'Commentaires confidentiels'|translate}}</label>

          <textarea id="edit-contact-person-comment" ng-model="$ctrl.contact.person.comments"
                    class="form-control"></textarea>
        </div>
      </div>
    </div>
  </div>
</div>
`;
