<div class="tw:mb-5">
  <h3><fa-icon [icon]="'edit'"/> {{ngBienCommodite.uuid ? 'Édition' : 'Ajout'}} d'une commodité</h3>
</div>

<form (ngSubmit)="save(editForm)" #editForm="ngForm"
      class="tw:w-[80vw] tw:sm:w-[30rem] tw:md:w-[40rem] tw:lg:w-[50rem]">
  <div class="tw:py-6">
    <div class="row">
      <div class="col-xs-12 col-sm-6">
        <div class="form-group" [appFormError]="bienCommoditeType.formInput">
          <label>Type de commodités *</label>

          <app-dictionary-select-items #bienCommoditeType="dictionarySelect" [ngItem]="ngBienCommodite.commodite"
                                       [name]="DICTIONARY_NAMES_COMMODITES" (selected)="onSelectCommodite($event)"
                                       [options]="{name: 'commodite' + ngBienCommodite.uuid, required: true}"/>
        </div>
      </div>

      <div class="col-xs-12 col-sm-6">
        <div class="form-group" [appFormError]="distance">
          <label for="distance">Distance</label>

          <div class="input-group input-group-number">
            <input type="text" id="distance" name="distance" #distance="ngModel" [(ngModel)]="ngBienCommodite.distance"
                   class="form-control" pattern="^(?:\d*.)?\d+$">

            <span class="input-group-addon">m</span>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-xs-12">
        <div class="form-group">
          <label for="commodite-comments">Commentaires</label>

          <textarea id="commodite-comments" class="form-control" name="comments" [minHeight]="100"
                    [(ngModel)]="ngBienCommodite.comments"></textarea>
        </div>
      </div>
    </div>
  </div>

  <div class="tw:float-right">
    <button type="submit" class="tw:btn-primary-info"><fa-icon [icon]="'check'"/><span>Enregistrer</span></button>
  </div>
</form>
