import {inject, Injectable} from '@angular/core';
import {TemplateFooterFactory} from '@models/templates/footers/footer/template-footer.factory';
import {ITemplateFooterApi} from '@models/templates/footers/footer/template-footer.interfaces';
import TemplateFooter from '@models/templates/footers/footer/template-footer.model';

@Injectable({providedIn: 'root'})
export class TemplateFootersFactory {
    private _templateFooterFactory = inject(TemplateFooterFactory);

    create(templateFooterApis: ITemplateFooterApi[] = []): TemplateFooter[] {
        return (templateFooterApis || []).filter(templateFooterApi => templateFooterApi)
            .map(templateFooterApi => this._templateFooterFactory.create(templateFooterApi));
    }
}
