export default `
<div class="row details-item">
  <div class="col-sm-6 text-right">
    <h4 class="text-bold">
      {{$ctrl.dossier._esk.infosPricing.prixHNI |
      currency:'€':2 * ($ctrl.dossier._esk.infosPricing.prixHNI % 1 !== 0)}}
    </h4>

    <p>{{"Prix honoraires de négociation inclus" | translate}}</p>
  </div>

  <div class="col-sm-6">
    <h5 class="text-bold">
      {{$ctrl.dossier._esk.infosPricing.fraisActe |
      currency:'€':2 * ($ctrl.dossier._esk.infosPricing.fraisActe % 1 !== 0)}}
    </h5>

    <p>{{"Frais d’acte" | translate}}</p>
  </div>
</div>

<div class="tw:text-center details-item">
  <h4 class="text-bold">
    {{$ctrl.dossier._esk.infosPricing.prixTFC | currency:'€':2 * ($ctrl.dossier._esk.infosPricing.prixTFC % 1 !== 0)}}
  </h4>

  <p>{{"Prix tous frais compris" | translate}}</p>
</div>
`;
