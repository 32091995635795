export default `
<div ng-switch="$ctrl.isAllBoxesClosed" class="container-fluid container-fullw bg-triangle dashboard">
  <div ng-switch-default>
    <div ng-repeat="row in $ctrl.dashboardConfig.rows" class="row display-flex">
      <div ng-repeat="col in row" ui-sortable="$ctrl.sortableOptions" ng-model="col.collection" class="col-xs-12"
           ng-class="[col.class, col.collection.length === 0 ? 'empty' : '' , col.noDrag ? ''  : 'col-dnd']">
        <div ng-repeat="box in col.collection track by $index">
          <ng-include ng-if="!box.closed" src="box.templateUrl"></ng-include>
        </div>
      </div>
    </div>
  </div>

  <h3 ng-switch-when="true" class="tw:text-center padding-top-20">
    Pour gérer l'affichage de votre tableau de bord, rendez-vous

    <a ng-click="$ctrl.ng2RouterStateForNgService.navigateByUrl('/app/my-account')">sur mon profil</a>.
  </h3>
</div>
`;
