@if (cityscanOnsaleProperty$ | async; as cityscanOnsaleProperty) {
  <div class="tw:flex tw:h-full tw:flex-col tw:divide-y tw:divide-gray-200 tw:bg-white">
    <div class="tw:bg-gray-50 tw:px-4 tw:py-6 tw:sm:px-6">
      <h2 class="tw:mr-8 tw:text-base tw:font-semibold tw:text-gray-900">
        En vente depuis le {{cityscanOnsaleProperty.publishedAt | date:'longDate'}}
      </h2>
    </div>

    <div class="tw:min-h-0 tw:flex-1 tw:overflow-y-auto">
      <app-cityscan-onsale-property-photo [cityscanOnsaleProperty]="cityscanOnsaleProperty"
                                          class="tw:block tw:aspect-photo"/>

      <div class="tw:flex tw:flex-col tw:gap-8 tw:p-8 tw:divide-y tw:divide-gray-200 tw:text-sm">
        <div class="tw:flex tw:flex-col tw:gap-4 tw:text-base tw:text-gray-700">
          <div>
            <span class="tw:font-medium tw:text-gray-900">{{nature.label}}</span> à
            <span class="tw:font-medium tw:text-gray-900">
              <app-commune-convivial-name [linkCommune]="cityscanOnsaleProperty.linkCommune"/>
            </span>
          </div>

          <div>{{cityscanOnsaleProperty.adresse}}</div>

          <div class="tw:text-sm">
            Annonce présente sur
            <a href="{{cityscanOnsaleProperty.url}}" target="_blank">
              {{hostname}}<fa-icon [icon]="'external-link-alt'"/>
            </a>
          </div>
        </div>

        @if (cityscanOnsaleProperty.nombrePieces) {
          <div class="tw:flex tw:pt-8 tw:text-gray-700 tw:space-x-4 tw:sm:space-x-8">
            @if (cityscanOnsaleProperty.nombrePieces) {
              <div [ngPlural]="cityscanOnsaleProperty.nombrePieces">
                {{cityscanOnsaleProperty.nombrePieces}}
                <ng-template ngPluralCase="=1">Pièce</ng-template>
                <ng-template ngPluralCase="other">Pièces</ng-template>
              </div>
            }
          </div>
        }

        <div class="tw:flex tw:flex-col tw:gap-4 tw:pt-8">
          @if (cityscanOnsaleProperty.surfaceCadastrale) {
            <div class="tw:sm:grid tw:sm:grid-cols-3 tw:sm:gap-4">
              <div>Surface cadastrale</div>

              <div class="tw:sm:col-span-2 tw:mt-1 tw:sm:mt-0 tw:text-base tw:text-gray-700">
                {{cityscanOnsaleProperty.surfaceCadastrale | textCurrency:'m²'}}
              </div>
            </div>
          }

          @if (cityscanOnsaleProperty.superficie) {
            <div class="tw:sm:grid tw:sm:grid-cols-3 tw:sm:gap-4">
              <div>{{superficieLabel}}</div>

              <div class="tw:sm:col-span-2 tw:mt-1 tw:sm:mt-0 tw:text-base tw:text-gray-700">
                {{cityscanOnsaleProperty.superficie | textCurrency:'m²'}}
              </div>
            </div>
          }

          @if (cityscanOnsaleProperty.prixVente) {
            <div class="tw:sm:grid tw:sm:grid-cols-3 tw:sm:gap-4">
              <div>Prix de vente</div>

              <div class="tw:sm:col-span-2 tw:mt-1 tw:sm:mt-0">
                <div class="tw:text-base tw:text-gray-700">{{cityscanOnsaleProperty.prixVente | textCurrency}}</div>

                <div>soit {{cityscanOnsaleProperty.prixM2 | textCurrency}} du m²</div>
              </div>
            </div>
          }
        </div>

        <div [innerHTML]="cityscanOnsaleProperty.description | textNl2br" class="tw:pt-8"></div>
      </div>
    </div>
  </div>
} @else {
  <div class="tw:px-4 tw:py-6 tw:sm:px-6">
    <div class="tw:mb-10 tw:pr-12"><app-loader [classHeight]="'tw:h-6'"/></div>

    <app-loader [classHeight]="'tw:h-40'" class="tw:block"/>
  </div>
}
