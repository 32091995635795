<div class="tw:flex tw:flex-col tw:gap-2">
  @if (options.label) {
    <label class="tw:text-sm tw:text-gray-700">{{ options.label }}</label>
  }

  @if (progress$ | async; as progress) {
    <div class="tw:w-full tw:bg-gray-200 tw:rounded-full">
      <div class="tw:bg-blue-600 tw:min-w-max tw:rounded-full tw:py-1 tw:px-2 tw:text-blue-100 tw:text-center tw:leading-none tw:transition-[width] tw:ease-in-out tw:delay-0 tw:duration-1000"
           [style.width]="progress.toString() + '%'">{{ progress }} %</div>
    </div>
  } @else {
    <app-loader class="tw:block tw:h-full" [classHeight]="'tw:h-full'" [options]="{label: options.waitingLabel!}"/>
  }
</div>
